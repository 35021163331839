import { Button, Form, Input, Modal, notification, Spin } from "antd";
import React from "react";
import ProductService from "../../Services/ProductService";
import { notificationContext } from "../../Common/PageRoute";
import { LoadingOutlined } from "@ant-design/icons";

interface props {
  onCancel: any;
  isModelOpen: boolean;
  setRefresh: any;
  productId: string | undefined;
}
const AddProduct = ({
  onCancel,
  isModelOpen,
  setRefresh,
  productId,
}: props) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(notificationContext);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const saveData = () => {
    form.validateFields().then(async (val: any) => {
      setBtnLoading(true);
      let res = await ProductService?.postProductDetails(productId, val);
      if (res?.status) {
        console.log("res", res);
        setRefresh((x: boolean) => !x);
        setBtnLoading(false);
        notification.success({
          message: "Success",
          description: productId ? "Edit successfully." : "Add Successfully",
        });
      } else {
        notification.error({
          message: "Error",
          description: res?.message,
        });
        setBtnLoading(false);
      }
    });
  };

  React.useEffect(() => {
    if (productId) {
      const getDataById = async () => {
        setLoading(true);
        let res = await ProductService?.getDataById(productId);
        if (res) {
          console.log("res", res);
          form?.setFieldsValue({
            name: res?.result?.name,
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
      getDataById();
    }
  }, []);

  return (
    <div>
      <Modal
        title="Add Product"
        onCancel={() => onCancel(false)}
        open={isModelOpen}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => onCancel(false)}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={saveData} loading={btnLoading}>
              {productId ? "Edit" : "Save"}
            </Button>
          </div>
        }
      >
        <Spin indicator={<LoadingOutlined />} spinning={loading}>
          <Form form={form}>
            <Form.Item label="Product Name" name="name">
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default AddProduct;
