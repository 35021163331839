import { Button, Card, Col, Input, Row, Skeleton, Table, Tooltip } from "antd";
import React from "react";
import { useTheme } from "../../../Context/ThemeContext";
import { useClient } from "../../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { LedgerStatus } from "../../../Services/LedgerService";
import ChartofAccountServicesNew from "../../../Services/ChartofAccountServicesNew";
import DepttModel from "./AddDepartmentModel";
// import UnitModel from "./UnitModel";

interface DepttDataType {
  key: string;
  // companyId: string;
  id: number;
  depttName: string;
  status: LedgerStatus;
}

const Departments = () => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [depttList, setDepttList] = React.useState<any[]>([]);
  const [searchDeptt, setSearchDeptt] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [depttData, setDepttData] = React.useState<any>({});
  const [trigger, setTrigger] = React.useState<boolean>(false);

  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getDepttList(
        currentClient?.id,
        searchDeptt,
        filter.sortCol,
        filter.sortDir
      );
    } else if (currentClient?.id === null) {
      console.error("No client found.");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, searchDeptt, filter.sortCol, filter.sortDir]);

  // --------- Get Units TableData API ---------
  const getDepttList = async (
    clientId: string,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: LedgerStatus
  ) => {
    await ChartofAccountServicesNew.getDepttList(
      clientId,
      search,
      sortCol,
      sortDir,
      status
    )
      .then((res: any) => {
        if (res) {
          setDepttList(
            res?.map((v: any) => {
              return {
                // companyId: v?.companyId,
                depttName: v?.depttName,
                id: v?.id,
                status: v?.status,
              };
            })
          );
        } else {
          setDepttList([]);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setDepttList([]);
      });
  };

  // --------- Units Table Columns  ---------
  const itemColumns: ColumnsType<DepttDataType> = [
    {
      title: "S. No.",
      dataIndex: "",
      key: "id",
      width: "5%",
      render: (_, record, index: number) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {index + 1}
        </Skeleton>
      ),
    },
    {
      title: "Department Name",
      dataIndex: "depttName",
      key: "depttName",
      width: "80%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.depttName}
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "",
      render: (record: DepttDataType) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Row gutter={18}>
            <Col>
              <Tooltip title="Edit" placement="top" color="#3D4998">
                <EditOutlined
                  // className="ca-edit-btn"
                  style={{
                    color: "#444653",

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenModel(true);
                    setDepttData({
                      id: record?.id,
                      depttName: record?.depttName,
                    });
                  }}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Delete" placement="top" color="red">
                <DeleteOutlined
                  // className="ca-edit-btn"
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Skeleton>
      ),
    },
  ];

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <div>
        <Row justify="space-between">
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setOpenModel(true)}
            >
              Department
            </Button>
          </Col>
          <Col>
            <Input
              allowClear
              style={{ width: "220px" }}
              placeholder="Search Department"
              onChange={(e: any) => {
                setLoading(true);
                if (!e.cancelable) {
                  //console.log("typeing user............");
                  const searchItemGroup = setTimeout(() => {
                    setSearchDeptt(e.target.value);
                  }, 1000);
                  return () => clearTimeout(searchItemGroup);
                } else {
                  // console.log("Click cancle............");
                  setSearchDeptt(e.target.value);
                }
              }}
              suffix={loading || searchDeptt ? "" : <SearchOutlined />}
            />
          </Col>
        </Row>
        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          // loading={tableLoading}
          columns={itemColumns}
          dataSource={
            loading
              ? (Array.from({ length: depttList.length }, (_, index) => ({
                  key: `loading-${index}`,
                })) as DepttDataType[])
              : depttList
          }
        />
        {openModel && (
          <DepttModel
            open={true}
            data={depttData}
            onCancel={() => setOpenModel(false)}
            setTrigger={setTrigger}
          />
        )}
      </div>
    </Card>
  );
};

export default Departments;
