import { createSlice } from "@reduxjs/toolkit";
import { CompanyGSTTypes } from "../../Types/CommonConstants";
import { IClient } from "../Context/ClientContext";
import { RootState } from "./Store";

const initialState: IClient = {
  addresses: [],
  appStartDate: "",
  autoNumber: 0,
  businessStart: "",
  //clientType: "" | null;
  gstType: CompanyGSTTypes.Unregistered,
  createdBy: null,
  clientId: null,
  defaultContact: null,
  email: "",
  id: "",
  isShippingSame: false,
  businessName: "",
  note: "",
  panNo: "",
  phone: "",
  practiceId: 0,
  recordStatus: 0,
  state: 0,
  registeredAddress: null,
  registrationNo: "",
  shippingAddress: null,
  status: 0,
  type: 0,
  updatedBy: null,
  yearEnd: null,
};

const ClientReduxSlice = createSlice({
  name: "currentClient",
  initialState,
  reducers: {
    setCurrentClientData: (state: IClient, action) => {
      state.addresses = action.payload?.addresses;
      state.appStartDate = action.payload?.appStartDate;
      state.autoNumber = action.payload?.autoNumber;
      state.businessStart = action.payload?.businessStart;
      state.gstType = action.payload?.gstType;
      state.createdBy = action.payload?.createdBy;
      state.clientId = action.payload?.clientId;
      state.defaultContact = action.payload?.defaultContact;
      state.email = action.payload?.email;
      state.id = action.payload?.id;
      state.isShippingSame = action.payload?.isShippingSame;
      state.businessName = action.payload?.businessName;
      state.note = action.payload?.note;
      state.panNo = action.payload?.panNo;
      state.phone = action.payload?.phone;
      state.practiceId = action.payload?.practiceId;
      state.recordStatus = action.payload?.recordStatus;
      state.state = action.payload?.state;
      state.registeredAddress = action.payload?.registeredAddress;
      state.registrationNo = action.payload?.registrationNo;
      state.shippingAddress = action.payload?.shippingAddress;
      state.status = action.payload?.status;
      state.type = action.payload?.type;
      state.updatedBy = action.payload?.updatedBy;
      state.yearEnd = action.payload?.yearEnd;
    },
    resetCurrentClient: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setCurrentClientData, resetCurrentClient } =
  ClientReduxSlice.actions;

// export const clientAddress = (state: RootState) => state.user;
// export const userToken = (state: RootState) => state.user.token;
export default ClientReduxSlice.reducer;
