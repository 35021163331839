import { DatePicker, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { useTheme } from "../../../Context/ThemeContext";
import PartyService from "../../../Services/PartyService";
import { useClient } from "../../../Context/ClientContext";
import { Utils } from "../../../../Utilities/Utils";
import { dayjs } from "../../../../Utilities/dayjs";
import ViewDebitNote from "../../Purchase/ViewDebitNote";
import ViewPurchaseInvoice from "../../Purchase/ViewPurchaseInvoice";
import ViewPaymentIOut from "../../Purchase/ViewPaymentOut";
import ViewSalesInvoice from "../../Sales/ViewSalesInvoice";
import ViewCreditNote from "../../Sales/ViewCreditNote";
import ViewPaymentIn from "../../Sales/ViewPaymentIn";

interface DataType {
  key: string;
  sno: number;
  id: string;
  date: string;
  refNo: { id: string; name: string };
  description: string;
  receiptAmount: number;
  balance: number;
  invAmount: number;
}

const { RangePicker } = DatePicker;

const PartyBalanceListTable: React.FC<{
  partyId: string | undefined;
  type: number;
}> = (props) => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [balanceList, setBalanceList] = React.useState<any[]>([]);
  const [balanceDue, setBalanceDue] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs().startOf("month").format("DD/MM/YYYY"),
    to: dayjs().format("DD/MM/YYYY"),
  });
  const [view, setView] = React.useState<any>({ open: "", id: "" });

  React.useEffect(() => {
    setLoading(true);
    getList(
      currentClient?.id!,
      props.partyId,
      (page - 1) * pageSize,
      pageSize,
      props.type,
      filterDate.from,
      filterDate.to
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.type,
    props.partyId,
    page,
    pageSize,
    currentClient?.id,
    filterDate,
  ]);

  const getList = async (
    clientId: string,
    partyId: string | undefined,
    start: number,
    length: number,
    type: number,
    from: string,
    to: string
  ) => {
    await PartyService.getPartyBalanceList(
      clientId,
      partyId,
      start,
      length,
      type,
      filterDate.from,
      filterDate.to
    )
      .then((res: any) => {
        if (res?.items.length > 0) {
          setBalanceList(
            res?.items.map((v: any, i: any) => {
              return {
                date: Utils.getFormattedDate(v?.date),
                refNo: { id: v.refNo?.id, name: v.refNo?.name },
                description:
                  props.type === 1
                    ? v?.type === 0
                      ? "Payment In"
                      : v?.type === 1
                      ? "Sales Invoice"
                      : "Credite Note"
                    : v?.type === 0
                    ? "Payment Out"
                    : v?.type === 1
                    ? "Purchase Invoice"
                    : "Debit Note",
                invAmount: Utils.getFormattedNumber(v.amount?.netPayable ?? 0),
                receiptAmount: Utils.getFormattedNumber(v.receiptAmount),
                balance: Utils.getFormattedNumber(v.amount?.balance),
              };
            })
          );
          setBalanceDue(Utils.getFormattedNumber(res?.total?.totalBalance));
          setLoading(false);
        } else {
          setBalanceList([]);
          setBalanceDue("0");
          setLoading(false);
        }
      })
      .catch((ex) => {
        console.error(ex);
      });
    // setLoading(false);
  };

  //  React.useEffect(() => {
  //    fetchData(page);
  //  }, [page]);
  //  const fetchData = (page) => {
  //    const newItems = [];
  //    for (let i = 0; i < 100; i++) {
  //      newItems.push(i);
  //    }
  //    if (page === 100) {
  //      setHasMore(false);
  //    }
  //    setItems([...items, ...newItems]);
  //  };

  //  const onScroll = () => {
  //    const scrollTop = document.documentElement.scrollTop;
  //    const scrollHeight = document.documentElement.scrollHeight;
  //    const clientHeight = document.documentElement.clientHeight;
  //    if (scrollTop + clientHeight >= scrollHeight) {
  //      setPage(page + 1);
  //    }
  //  };

  //  React.useEffect(() => {
  //    window.addEventListener("scroll", onScroll);
  //    return () => window.removeEventListener("scroll", onScroll);
  //  }, [items]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "name",
      width: "14%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Reference",
      dataIndex: "refNo",
      key: "number",
      width: "14%",
      // render: (_, record) =>
      //   record.description === "Sales Invoice" ? (
      //     <div
      //       onClick={() => {
      //         setView({ ...view, open: "salesInvoice", id: record.refNo.id });
      //       }}
      //     >
      //       {record.refNo.name}
      //     </div>
      //   ) : (
      //     <div>{record.refNo.name}</div>
      //   ),
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              record?.description === "Sales Invoice"
                ? setView({
                    ...view,
                    open: "salesInvoice",
                    id: record?.refNo?.id,
                  })
                : record?.description === "Payment In"
                ? setView({ ...view, open: "paymentIn", id: record?.refNo?.id })
                : record?.description === "Credite Note"
                ? setView({ ...view, open: "crediteNote", id: record?.refNo?.id })
                : record?.description === "Purchase Invoice"
                ? setView({
                    ...view,
                    open: "purchaseInvoice",
                    id: record?.refNo?.id,
                  })
                : record?.description === "Debit Note"
                ? setView({ ...view, open: "debitNote", id: record?.refNo?.id })
                : setView({ ...view, open: "paymentOut", id: record?.refNo?.id });
            }}
          >
            {record?.refNo?.name}
          </div>
        </Skeleton>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      // width: "22%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.description}
        </Skeleton>
      ),
    },
    {
      title: "Invoice Amount",
      dataIndex: "invAmount",
      key: "accountHead",
      width: "16%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.invAmount}
        </Skeleton>
      ),
    },
    {
      title: "Receipt / Refund",
      dataIndex: "receiptAmount",
      width: "16%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.receiptAmount}
        </Skeleton>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "taxRate",
      width: "16%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.balance}
        </Skeleton>
      ),
    },
  ];

  return (
    <div>
      <RangePicker
        format="DD-MMM-YYYY"
        defaultValue={[dayjs().date(1), dayjs()]}
        allowClear={false}
        style={{ marginTop: "10px" }}
        onChange={(e: any) => {
          e === null
            ? setFilterDate({})
            : setFilterDate({
                ...filterDate,
                from: dayjs(e[0]).format("DD/MM/YYYY"),
                to: dayjs(e[1]).format("DD/MM/YYYY"),
              });
        }}
        presets={[
          {
            label: "Today",
            value: [dayjs().add(0, "d"), dayjs()],
          },
          {
            label: "Last 7 Days",
            value: [dayjs().add(-7, "d"), dayjs()],
          },
          {
            label: "Last 15 Days",
            value: [dayjs().add(-15, "d"), dayjs()],
          },
          {
            label: "This Month",
            value: [dayjs().startOf("month"), dayjs()],
          },
          {
            label: "Last Month",
            value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
          },
          {
            label: "Quarter 1",
            value: [
              dayjs().month(3).startOf("month"),
              dayjs().month(5).endOf("month"),
            ],
          },
          {
            label: "Quarter 2",
            value: [
              dayjs().month(6).startOf("month"),
              dayjs().month(8).endOf("month"),
            ],
          },
          {
            label: "Quarter 3",
            value: [
              dayjs().month(9).startOf("month"),
              dayjs().month(11).endOf("month"),
            ],
          },
          {
            label: "Quarter 4",
            value: [
              dayjs().month(0).startOf("month"),
              dayjs().month(2).endOf("month"),
            ],
          },
          {
            label: "This Year",
            //           dayjs().month(0).startOf("month"),
            // dayjs(),
            value: [dayjs().month(0).startOf("month"), dayjs()],
          },
          {
            label: "Last Year",
            value: [
              dayjs().month(-12).startOf("month"),
              dayjs().month(-1).endOf("month"),
            ],
          },
        ]}
      />
      <Table
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as DataType[])
            : balanceList
        }
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        pagination={false}
        loading={false}
        // rowSelection={rowSelection}
        // pagination={{
        //   total: totalRecords,
        //   current: page,
        //   pageSize: pageSize,
        //   showSizeChanger: true,
        //   onChange: (page: number, pageSize: number) => {
        //     setPage(page);
        //     setPageSize(pageSize);
        //   },
        //   pageSizeOptions: ["10", "15", "25", "50", "100"],
        //   showTotal: (totalRecords, page) =>
        //     `${page[0]}-${page[1]} of ${totalRecords} items`,
        // }}
        footer={() => (
          <>
            <table>
              <tbody>
                <tr>
                  <th className="textEnd">Balance Due</th>
                  <th style={{ width: "12%", textAlign: "end" }}>
                    {balanceDue}
                  </th>
                </tr>
              </tbody>
            </table>
          </>
        )}
        scroll={{ x: 600 }}
      />
      {view.open === "salesInvoice" && (
        <ViewSalesInvoice
          open={view.open === "salesInvoice"}
          onCancel={() => setView({})}
          invoiceId={view.id}
        />
      )}
      {view.open === "crediteNote" && (
        <ViewCreditNote
          open={view.open === "crediteNote"}
          onCancel={() => setView({})}
          creditNoteId={view.id}
        />
      )}
      {view.open === "paymentIn" && (
        <ViewPaymentIn
          open={view.open === "paymentIn"}
          onCancel={() => setView({})}
          paymentId={view.id}
        />
      )}
      {view.open === "purchaseInvoice" && (
        <ViewPurchaseInvoice
          open={view.open === "purchaseInvoice"}
          onCancel={() => setView({})}
          invoiceId={view.id}
        />
      )}
      {view.open === "debitNote" && (
        <ViewDebitNote
          open={view.open === "debitNote"}
          onCancel={() => setView({})}
          debitNoteId={view.id}
        />
      )}
      {view.open === "paymentOut" && (
        <ViewPaymentIOut
          open={view.open === "paymentOut"}
          onCancel={() => setView({})}
          paymentId={view.id}
        />
      )}
    </div>
  );
};

export default PartyBalanceListTable;
