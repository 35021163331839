import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Tag,
  Tooltip,
  Popover,
  Card,
  Skeleton,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  DownloadOutlined,
  FileTextOutlined,
  RetweetOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import noteContext from "../../Context/StyleContext";
import {
  DBRecordStatus,
  EstimateStatus,
  InvoiceStatus,
  InvoiceTypes,
} from "../../Services/SalesService";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import ViewPurchaseOrderModel from "./ViewPurchaseOrderModel";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import PurchaseService from "../../Services/PurchaseService";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";

export interface IPurchaseOrderDataType {
  key: React.Key;
  id: string;
  tPartner: any;
  number: { no: string; name: string };
  invoices: { id: string; name: string };
  name: string;
  date: string;
  duedate: string;
  totalInvoiceAmount: number;
  dueBalance: number;
  deleteType: number;
  supplierName: string;
}

const PurchaseOrder: React.FC<{ partyId?: string }> = (props) => {
  const { RangePicker } = DatePicker;
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const navigate = useNavigate();
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [purchaseOrderList, setPurchaseOrderList] = useState<any>([]);
  const [searchSupplier, setSearchSupplier] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>({ from: "", to: "" });
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [estimateStatus, setEstimateStatus] = React.useState<number>(0);
  const [deleteEstimate, setDeleteEstimate] = React.useState<any>({
    status: DBRecordStatus.Active,
    open: false,
    name: "",
  });
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [estimateId, setEstimateId] = React.useState<string>("");
  const [confirmationModel, setConfirmationModel] =
    React.useState<boolean>(false);
  // const [isVisible, setIsVisible] = useState(false);
  // const [conditionMet, setConditionMet] = useState(false);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // useEffect(() => {
  //   if (conditionMet) {
  //     setIsVisible(false);
  //   }
  // }, [conditionMet]);

  //-------- Delete Purchase Order--------
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  React.useEffect(() => {
    if (deleteEstimate.open) {
      PurchaseService.deletePurchaseOrder(
        currentClient?.id!,
        estimateId,
        deleteEstimate.status
      )
        .then((res: any) => {
          if (res.result) {
            setTrigger((x) => !x);
            console.log(res.result);
            openNotification(
              "success",
              `${
                deleteEstimate.status === 1 ? "Delete" : "Active"
              } PurchaseOrder`
            );
            setDeleteEstimate({});
          }
        })
        .catch((ex: any) => {
          setDeleteEstimate({});
          console.error("Delete Api Error", ex);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEstimate.open]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<IPurchaseOrderDataType> = [
    {
      title: "PO. No.",
      dataIndex: "number",
      sorter: true,
      key: "AutoNumber",
      render: (x: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
              setInvoiceId(x?.id);
            }}
          >
            {x?.no}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      key: "date",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Expiry Date",
      dataIndex: "dueDate",
      sorter: true,
      key: "dueDate",
      width: width <= 1024 ? "15%" : "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {dayjs(record?.dueDate).format("DD/MM/YYYY")}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "totalPurchaseOrderAmount",
      sorter: true,
      align: "right",
      width: width <= 1024 ? "10%" : "",
      key: "totalPurchaseOrderAmount",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹{Utils.getFormattedNumber(record?.totalPurchaseOrderAmount)}
        </Skeleton>
      ),
    },

    {
      title: "Status",
      dataIndex: "address",
      width: "7%",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.invoices?.id ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="success"
                style={{
                  textAlign: "center",
                  width: 65,
                }}
              >
                Accepted
              </Tag>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="error"
                style={{
                  textAlign: "center",
                  width: 67,
                }}
              >
                Pending
              </Tag>
            </Skeleton>
          )}
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "icon",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          <FiDownload style={{ color: "#395cd2" }} />
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "icon2",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.invoices?.id || record.deleteType === 1 ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
              className="cursor"
            >
              <Tooltip title="Not Editable" placement="top" color="gray">
                <EditOutlined
                  // className="ca-edit-btn"

                  style={{ color: "rgb(216 207 207)" }}
                />
              </Tooltip>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
              className="cursor"
            >
              <Tooltip title="Edit" placement="top" color="#3D4998">
                <Link
                  to={`/accounts/clients/${currentClient?.id}/purchase/editPurchaseOrder/${record.id}`}
                >
                  <EditOutlined
                    className="ca-edit-btn"
                    onClick={() =>
                      navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/editPurchaseOrder/${record.id}`
                      )
                    }
                    style={{ color: "#444653" }}
                  />
                </Link>
              </Tooltip>
            </Skeleton>
          )}
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.invoices?.id || record.deleteType === 1 ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
              className="cursor"
            >
              <Tooltip title="Create Invoice" placement="top" color="gray">
                <FileTextOutlined style={{ color: "rgb(216 207 207)" }} />
              </Tooltip>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
              className="cursor"
            >
              <Tooltip title="Create Invoice" placement="top" color="#64CA31">
                <Link
                  to={`/accounts/clients/${currentClient?.id}/purchase/addPurchaseBill/order/${record.id}`}
                >
                  <FileTextOutlined
                    // className="ca-invoice-btn"
                    onClick={() => {
                      navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/addPurchaseBill/order/${record.id}`
                      );
                    }}
                    style={{ color: "#868ea5" }}
                  />
                </Link>
              </Tooltip>
            </Skeleton>
          )}
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div>
          {!record?.invoices?.id && record.deleteType === 0 && (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
              className="cursor"
            >
              <Tooltip title="Delete" placement="top" color="red">
                <Popover
                  // popupVisible={isVisible}
                  arrow={{ pointAtCenter: true }}
                  placement="topRight"
                  title={
                    <span
                      onClick={() => {
                        setConfirmationModel(true);
                        setEstimateId(record.id);
                        setDeleteEstimate({
                          ...deleteEstimate,
                          status: DBRecordStatus.Deleted,
                          name: record.number?.no,
                        });
                      }}
                    >
                      <Button>Delete</Button>
                    </span>
                  }
                  trigger="click"
                >
                  <HiDotsHorizontal
                    // className="ca-delete-btn"
                    // twoToneColor="#ff879d"
                    style={{ color: "#395cd2", cursor: "pointer" }}
                  />
                </Popover>
              </Tooltip>
            </Skeleton>
          )}
          {!record?.invoices?.id && record.deleteType === 1 && (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
              className="cursor"
            >
              <Tooltip
                title="Revive Purchase Order"
                placement="top"
                color="green"
              >
                <RetweetOutlined
                  className="ca-invoice-btn"
                  style={{ color: "#64CA31" }}
                  onClick={() => {
                    setConfirmationModel(true);
                    setEstimateId(record.id);
                    setDeleteEstimate({
                      ...deleteEstimate,
                      status: DBRecordStatus.Active,
                      name: record.number?.no,
                    });
                  }}
                />
              </Tooltip>
            </Skeleton>
          )}
        </div>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Supplier Name",
      dataIndex: "supplierName",
      width: width <= 1024 ? "20%" : "30%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          {record?.supplierName}
        </Skeleton>
      ),
    });
  }

  // --------- Get PurchaseOrder TableData API ---------
  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getPurchaseOrderList(
        currentClient?.id,
        estimateStatus,
        InvoiceTypes.Invoice,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchSupplier,
        filterDate.from,
        filterDate.to,
        props?.partyId
      );
    } else if (currentClient?.id === null) {
      console.error("InValid Client");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    estimateStatus,
    filter.sortCol,
    filter.sortDir,
    searchSupplier,
    filterDate.from,
    filterDate.to,
    trigger,
    props?.partyId,
  ]);

  const getPurchaseOrderList = async (
    clientId: string,
    status: InvoiceStatus.All,
    type: InvoiceTypes.Invoice,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    from?: any,
    to?: any,
    partyId?: string
  ) => {
    await PurchaseService.getPurchaseOrderList(
      clientId,
      status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId
    )
      .then((res: any) => {
        console.log(res);
        if (res?.items?.length > 0) {
          setPurchaseOrderList(
            res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                id: v?.id,
                name: v?.tPartner.name,
                number: { no: v?.number, id: v?.id },
                date: v?.date,
                invoices: { id: v?.invoices?.id, name: v?.invoices?.name },
                dueDate: v?.dueDate,
                dueBalance: v?.dueBalance,
                totalPurchaseOrderAmount: v?.totalPurchaseOrderAmount,
                supplierName: v?.tPartner.name,
                deleteType: v?._s,
                // itemType: v?.isService,
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else if (res?.items?.length === 0) {
          setPurchaseOrderList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setPurchaseOrderList([]);
      });
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "AutoNumber",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        padding: "48px 0px 38px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <Row
        justify="space-between"
        gutter={windowWidth === 1024 ? 65 : 0}
        style={{
          // paddingTop: props.partyId ? "10px" : "0px"
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space size={width <= 768 ? 408 : 5}>
            <Button
              type="primary"
              id="gfg"
              icon={<PlusOutlined />}
              onClick={() => {
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addOrder/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addOrder`
                    );
              }}
            >
              Purchase Order
            </Button>
            <Button type="default" icon={<DownloadOutlined />}>
              Export
            </Button>
          </Space>
        </Col>

        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 60 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search..."
                allowClear
                onChange={(e: any) => {
                  setLoading(true);
                  if (!e.cancelable) {
                    const data = setTimeout(() => {
                      setPage(1);
                      setSearchSupplier(e.target.value);
                    }, 1000);
                    return () => clearTimeout(data);
                  } else {
                    setSearchSupplier(e.target.value);
                  }
                }}
                suffix={
                  loading || searchSupplier ? (
                    ""
                  ) : (
                    <img alt="search" src={search} />
                  )
                }
              />
            </Col>
            <Col>
              <RangePicker
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                style={{ width: windowWidth === 1024 ? 80 : 120 }}
                value={estimateStatus}
                onSelect={(e) => {
                  setPage(1);
                  setEstimateStatus(e);
                }}
                options={[
                  { value: EstimateStatus.Active, label: "Active" },
                  { value: EstimateStatus.Pending, label: "Pending" },
                  { value: EstimateStatus.Accpected, label: "Accpected" },
                  { value: EstimateStatus.Deleted, label: "Deleted" },
                  { value: EstimateStatus.All, label: "All" },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IPurchaseOrderDataType[])
            : purchaseOrderList
        }
        onChange={tableSort}
        scroll={
          pageSize > 10
            ? { x: width <= 768 ? 1150 : 700, y: 370 }
            : { x: width <= 768 ? 1150 : 600 }
        }
        // style={{ overflowX: "auto" }}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {open && (
        <ViewPurchaseOrderModel
          open={open}
          onCancel={() => setIsOpen(false)}
          invoiceId={invoiceId}
        />
      )}
      {confirmationModel && (
        <ConfirmationModal
          open={confirmationModel}
          text={`Do you really want to ${
            deleteEstimate.status === 1 ? "Delete" : "Active"
          }  this PurchaseOrder"${deleteEstimate.name}"?`}
          onNo={() => setConfirmationModel(false)}
          onYes={() => {
            setConfirmationModel(false);
            setDeleteEstimate({ ...deleteEstimate, open: true });
          }}
        />
      )}
    </Card>
  );
};
export default PurchaseOrder;
