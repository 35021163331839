import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { DBRecordStatus } from "./SalesService";

export enum ExpensesIncomeTypes {
  Expenses = 1,
  ExpRecurring = 2,
  Income = 3,
  IncRecurring = 4,
}

export enum ExpensesIncomeTradesType {
  Unknown = 0,
  Expenses = 1,
  Income = 2,
}

export enum EICategoryStatus {
  All,
  Active,
  Due,
  Paid,
  Deleted,
}
export enum InvoicePaymentMethodType {
  credit,
  cash,
}

class ExpensesAndIncomeService {
  private route = "/v1/API/ExpensesIncome/";
  //https://localhost:7142/v1/API/ExpensesIncome/646487e05c05d60415fa6462 'post'
  // "https://localhost:7142/v1/API/ExpensesIncome/646487e05c05d60415fa6462/64cca5088d1800f2a99e2e01" 'post with id'

  PostExpensesInvoice = (clientId: string, params: any, id?: string) =>
    ApiUtility.postForm(
      `${this.route}${clientId}${id ? "/" + id : ""}`,
      params
    );

  //https://localhost:7142/v1/API/ExpensesIncome/646487e05c05d60415fa6462/Initial?type=1

  initialInvoice = (clientId: string, type: number) => {
    return ApiUtility.get(`${this.route}${clientId}/Initial?type=${type}`);
  };

  // https: //localhost:7142/v1/API/ExpensesIncome/646487e05c05d60415fa6462?EIType=1&status=0&start=0&length=15

  getExpensesList = (
    clientId: any,
    eIType: ExpensesIncomeTypes,
    status: EICategoryStatus,
    start: number,
    length: number,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    from?: any,
    to?: any
  ) => {
    return ApiUtility.getResult(`${this.route}${clientId}`, {
      eIType,
      status,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
    });
  };

  //https: //localhost:7142/v1/API/ExpensesIncome/646487e05c05d60415fa6462/64c8bc1cbd0a6b585e0ce9d9 'getwithid'

  getExpensesInvoice = (clientId: string, id?: string) => {
    return ApiUtility.get(`${this.route}${clientId}/${id}`);
  };

  //https: //localhost:7142/v1/API/ExpensesIncome/646487e05c05d60415fa6462/653a1dab6b5746ff9ea1f296/Delete?recordStatus=1&type=1

  deleteExpensesIncome = (
    clientId: string,
    id: string,
    recordStatus: DBRecordStatus,
    type: ExpensesIncomeTypes
  ) =>
    ApiUtility.post(
      `${this.route}${clientId}/${id}/Delete?recordStatus=${recordStatus}&type=${type}`,
      ""
    );
}
export default new ExpensesAndIncomeService();
