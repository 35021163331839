export const dep = [
  {
    Id: "I",
    Title: "Buildings [NESD]",
    Children: [
      {
        Id: "I-a",
        Title: "Building (other than factory buildings) RCC Frame Structure",
        Children: [],
        EffDate: "1-1-1900",
        Life: 60,
        SLMRate: 1.58,
        WDVRate: 4.87,
      },
      {
        Id: "I-b",
        Title:
          "Building (other than factory buildings) other than RCC Frame Structure",
        Children: [],
        EffDate: "1-1-1900",
        Life: 30,
        SLMRate: 3.17,
        WDVRate: 9.5,
      },
      {
        Id: "I-c",
        Title: "Factory buildings",
        Children: [],
        EffDate: "1-1-1900",
        Life: 30,
        SLMRate: 3.17,
        WDVRate: 9.5,
      },
      {
        Id: "I-d",
        Title: "Fences, wells, tube wells",
        Children: [],
        EffDate: "1-1-1900",
        Life: 5,
        SLMRate: 19,
        WDVRate: 45.07,
      },
      {
        Id: "I-e",
        Title: "Other (including temporary structure, etc.)",
        Children: [],
        EffDate: "1-1-1900",
        Life: 3,
        SLMRate: 31.67,
        WDVRate: 63.16,
      },
    ],
    EffDate: "1-1-1900",
    Life: 0,
    SLMRate: 0,
    WDVRate: 0,
  },
  {
    Id: "II",
    Title: "Bridges, Culverts, Bunkers, Etc. [NESD]",
    Children: [],
    EffDate: "1-1-1900",
    Life: 30,
    SLMRate: 3.17,
    WDVRate: 9.5,
  },
  {
    Id: "III",
    Title: "Roads [NESD]",
    Children: [
      {
        Id: "III-a",
        Title: "Carpeted Roads",
        Children: [
          {
            Id: "III-a-i",
            Title: "Carpeted Roads – RCC",
            Children: [],
            EffDate: "1-1-1900",
            Life: 10,
            SLMRate: 9.5,
            WDVRate: 25.89,
          },
          {
            Id: "III-a-ii",
            Title: "Carpeted Roads – other than RCC",
            Children: [],
            EffDate: "1-1-1900",
            Life: 5,
            SLMRate: 19,
            WDVRate: 45.07,
          },
        ],
        EffDate: "1-1-1900",
        Life: 0,
        SLMRate: 0,
        WDVRate: 0,
      },
      {
        Id: "III-b",
        Title: "Non-carpeted roads",
        Children: [],
        EffDate: "1-1-1900",
        Life: 3,
        SLMRate: 31.67,
        WDVRate: 63.16,
      },
    ],
    EffDate: "1-1-1900",
    Life: 0,
    SLMRate: 0,
    WDVRate: 0,
  },
  {
    Id: "IV",
    Title: "Plant and Machinery",
    Children: [
      {
        Id: "IV-a",
        Title:
          "General rate applicable to Plant and Machinery not covered under Special Plant and Machinery",
        Children: [
          {
            Id: "IV-a-i",
            Title:
              "Plant and Machinery other than continuous process plant not covered under specific",
            Children: [],
            EffDate: "1-1-1900",
            Life: 15,
            SLMRate: 6.33,
            WDVRate: 18.1,
          },
          {
            Id: "IV-a-ii",
            Title:
              "Continuous process plant for which no special rate has been prescribed under (ii) below",
            Children: [],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
        ],
        EffDate: "1-1-1900",
        Life: 0,
        SLMRate: 0,
        WDVRate: 0,
      },
      {
        Id: "IV-b",
        Title: "Special Plant and Machinery",
        Children: [
          {
            Id: "IV-b-i",
            Title:
              "Plant and Machinery other than continuous process plant not covered under specific",
            Children: [
              {
                Id: "IV-b-i-1",
                Title:
                  "Cinematograph films – Machinery used in the production and exhibition of cinematograph films, recording and reproducing equipments, developing machines, printing machines, editing machines, synchronizers and studio lights",
                Children: [],
                EffDate: "1-1-1900",
                Life: 13,
                SLMRate: 7.31,
                WDVRate: 20.58,
              },
              {
                Id: "IV-b-i-2",
                Title: "Projecting equipment for exhibition of films",
                Children: [],
                EffDate: "1-1-1900",
                Life: 13,
                SLMRate: 7.31,
                WDVRate: 20.58,
              },
            ],
            EffDate: "1-1-1900",
            Life: 15,
            SLMRate: 6.33,
            WDVRate: 18.1,
          },
          {
            Id: "IV-b-ii",
            Title: "Plant and Machinery used in glass",
            Children: [
              {
                Id: "IV-b-ii-1",
                Title:
                  "Plant and Machinery except direct fire glass melting furnaces – Recuperative and regenerative glass melting furnaces",
                Children: [],
                EffDate: "1-1-1900",
                Life: 13,
                SLMRate: 7.31,
                WDVRate: 20.58,
              },
              {
                Id: "IV-b-ii-2",
                Title:
                  "Plant and Machinery except direct fire glass melting furnaces – Moulds [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 8,
                SLMRate: 11.88,
                WDVRate: 31.23,
              },
              {
                Id: "IV-b-ii-3",
                Title: "Float Glass Melting Furnaces [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 10,
                SLMRate: 9.5,
                WDVRate: 25.89,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-iii",
            Title:
              "Plant and Machinery used in mines and quarries Portable underground machinery and earth moving machinery used in open cast mining",
            Children: [],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-iv",
            Title: "Plant and Machinery used in Telecommunications [NESD]",
            Children: [
              {
                Id: "IV-b-iv-1",
                Title: "Towers",
                Children: [],
                EffDate: "1-1-1900",
                Life: 18,
                SLMRate: 5.28,
                WDVRate: 15.33,
              },
              {
                Id: "IV-b-iv-2",
                Title:
                  "Telecom transceivers, switching centres, transmission and other network equipment",
                Children: [],
                EffDate: "1-1-1900",
                Life: 13,
                SLMRate: 7.31,
                WDVRate: 20.58,
              },
              {
                Id: "IV-b-iv-3",
                Title: "Telecom – Ducts, Cables and optical fibre",
                Children: [],
                EffDate: "1-1-1900",
                Life: 18,
                SLMRate: 5.28,
                WDVRate: 15.33,
              },
              {
                Id: "IV-b-iv-4",
                Title: "Satellites",
                Children: [],
                EffDate: "1-1-1900",
                Life: 18,
                SLMRate: 5.28,
                WDVRate: 15.33,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-v",
            Title:
              "Plant and Machinery used in exploration, production and refining oil and gas [NESD]",
            Children: [
              {
                Id: "IV-b-v-1",
                Title: "Refineries",
                Children: [],
                EffDate: "1-1-1900",
                Life: 25,
                SLMRate: 3.8,
                WDVRate: 11.29,
              },
              {
                Id: "IV-b-v-2",
                Title:
                  "Oil and gas assets (including wells), processing plant and facilities",
                Children: [],
                EffDate: "1-1-1900",
                Life: 25,
                SLMRate: 3.8,
                WDVRate: 11.29,
              },
              {
                Id: "IV-b-v-3",
                Title: "Petrochemical Plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 25,
                SLMRate: 3.8,
                WDVRate: 11.29,
              },
              {
                Id: "IV-b-v-4",
                Title: "Storage tanks and related equipment",
                Children: [],
                EffDate: "1-1-1900",
                Life: 25,
                SLMRate: 3.8,
                WDVRate: 11.29,
              },
              {
                Id: "IV-b-v-5",
                Title: "Pipelines",
                Children: [],
                EffDate: "1-1-1900",
                Life: 30,
                SLMRate: 3.17,
                WDVRate: 9.5,
              },
              {
                Id: "IV-b-v-6",
                Title: "Drilling Rig",
                Children: [],
                EffDate: "1-1-1900",
                Life: 30,
                SLMRate: 3.17,
                WDVRate: 9.5,
              },
              {
                Id: "IV-b-v-7",
                Title:
                  "Field operations (above ground) Portable boilers, drilling tools, well-head tanks, etc.",
                Children: [],
                EffDate: "1-1-1900",
                Life: 8,
                SLMRate: 11.88,
                WDVRate: 31.23,
              },
              {
                Id: "IV-b-v-8",
                Title: "Loggers",
                Children: [],
                EffDate: "1-1-1900",
                Life: 8,
                SLMRate: 11.88,
                WDVRate: 31.23,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-vi",
            Title:
              "Plant and Machinery used in generation, transmission and distribution of power [NESD]",
            Children: [
              {
                Id: "IV-b-vi-1",
                Title: "Thermal / Gas / Combined Cycle Power Generation Plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-vi-2",
                Title: "Hydro Power Generation Plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-vi-3",
                Title: "Nuclear Power Generation Plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-vi-4",
                Title: "Transmission lines, cables and other network assets",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-vi-5",
                Title: "Wind Power Generation Plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 22,
                SLMRate: 4.32,
                WDVRate: 12.73,
              },
              {
                Id: "IV-b-vi-6",
                Title: "Electric Distribution Plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 35,
                SLMRate: 2.71,
                WDVRate: 8.2,
              },
              {
                Id: "IV-b-vi-7",
                Title: "Gas Storage and Distribution Plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 30,
                SLMRate: 3.17,
                WDVRate: 9.5,
              },
              {
                Id: "IV-b-vi-8",
                Title: "Water Distribution Plant including pipelines",
                Children: [],
                EffDate: "1-1-1900",
                Life: 30,
                SLMRate: 3.17,
                WDVRate: 9.5,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-vii",
            Title: "Plant and Machinery used in manufacture of",
            Children: [
              {
                Id: "IV-b-vii-1",
                Title: "Sinter Plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
              {
                Id: "IV-b-vii-2",
                Title: "Blast Furnace",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
              {
                Id: "IV-b-vii-3",
                Title: "Coke Ovens",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
              {
                Id: "IV-b-vii-4",
                Title: "Rolling mill in steel plant",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
              {
                Id: "IV-b-vii-5",
                Title: "Basic Oxygen Furnace Converter",
                Children: [],
                EffDate: "1-1-1900",
                Life: 25,
                SLMRate: 3.8,
                WDVRate: 11.29,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-viii",
            Title:
              "Plant and Machinery used in manufacture of non ferrous metals",
            Children: [
              {
                Id: "IV-b-viii-1",
                Title: "Metal pot line [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-viii-2",
                Title: "Bauxite crushing and grinding section",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-viii-3",
                Title: "Digester Section [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-viii-4",
                Title: "Turbine [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-viii-5",
                Title: "Equipments for Calcinations [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-viii-6",
                Title: "Copper Smelter [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-viii-7",
                Title: "Roll Grinder",
                Children: [],
                EffDate: "1-1-1900",
                Life: 40,
                SLMRate: 2.38,
                WDVRate: 7.22,
              },
              {
                Id: "IV-b-viii-8",
                Title: "Soaking Pit",
                Children: [],
                EffDate: "1-1-1900",
                Life: 30,
                SLMRate: 3.17,
                WDVRate: 9.5,
              },
              {
                Id: "IV-b-viii-9",
                Title: "Annealing Furnace",
                Children: [],
                EffDate: "1-1-1900",
                Life: 30,
                SLMRate: 3.17,
                WDVRate: 9.5,
              },
              {
                Id: "IV-b-viii-10",
                Title: "Rolling Mills",
                Children: [],
                EffDate: "1-1-1900",
                Life: 30,
                SLMRate: 3.17,
                WDVRate: 9.5,
              },
              {
                Id: "IV-b-viii-11",
                Title: "Equipments for Scalping, Slitting, etc. [NSED]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 30,
                SLMRate: 3.17,
                WDVRate: 9.5,
              },
              {
                Id: "IV-b-viii-12",
                Title: "Surface Miner, Ripper Dozer, etc. used in mines",
                Children: [],
                EffDate: "1-1-1900",
                Life: 25,
                SLMRate: 3.8,
                WDVRate: 11.29,
              },
              {
                Id: "IV-b-viii-13",
                Title: "Copper refining plant [NSED]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 25,
                SLMRate: 3.8,
                WDVRate: 11.29,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-ix",
            Title:
              "Plant and Machinery used in medical and surgical operations [NESD]",
            Children: [
              {
                Id: "IV-b-ix-1",
                Title:
                  "Electrical Machinery, X-ray and electrotherapeutic apparatus and accessories thereto, medical, diagnostic equipments, namely, Cat-scan, Ultrasound Machines, ECG Monitors, etc.",
                Children: [],
                EffDate: "1-1-1900",
                Life: 13,
                SLMRate: 7.31,
                WDVRate: 20.58,
              },
              {
                Id: "IV-b-ix-2",
                Title: "Other Equipments",
                Children: [],
                EffDate: "1-1-1900",
                Life: 15,
                SLMRate: 6.33,
                WDVRate: 18.1,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-x",
            Title:
              "Plant and Machinery used in manufacture of pharmaceuticals and chemicals [NESD]",
            Children: [
              {
                Id: "IV-b-x-1",
                Title: "Reactors",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
              {
                Id: "IV-b-x-2",
                Title: "Distillation Columns",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
              {
                Id: "IV-b-x-3",
                Title: "Drying equipments / Centrifuges and Decanters",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
              {
                Id: "IV-b-x-4",
                Title: "Vessel / Storage tanks",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-xi",
            Title: "Plant and Machinery used in civil construction",
            Children: [
              {
                Id: "IV-b-xi-1",
                Title:
                  "Concreting, Crushing, Piling Equipments and Road Making Equipments",
                Children: [],
                EffDate: "1-1-1900",
                Life: 12,
                SLMRate: 7.92,
                WDVRate: 22.09,
              },
              {
                Id: "IV-b-xi-2-1",
                Title:
                  "Heavy Lift Equipments – Cranes with capacity more than 100 tons",
                Children: [],
                EffDate: "1-1-1900",
                Life: 20,
                SLMRate: 4.75,
                WDVRate: 13.91,
              },
              {
                Id: "IV-b-xi-2-2",
                Title:
                  "Heavy Lift Equipments – Cranes with capacity less than 100 tons",
                Children: [],
                EffDate: "1-1-1900",
                Life: 15,
                SLMRate: 6.33,
                WDVRate: 18.1,
              },
              {
                Id: "IV-b-xi-3",
                Title: "Transmission line, Tunnelling Equipments [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 10,
                SLMRate: 9.5,
                WDVRate: 25.89,
              },
              {
                Id: "IV-b-xi-4",
                Title: "Earth-moving equipments",
                Children: [],
                EffDate: "1-1-1900",
                Life: 9,
                SLMRate: 10.56,
                WDVRate: 28.31,
              },
              {
                Id: "IV-b-xi-5",
                Title:
                  "Others including Material Handling / Pipeline / Welding Equipments [NESD]",
                Children: [],
                EffDate: "1-1-1900",
                Life: 12,
                SLMRate: 7.92,
                WDVRate: 22.09,
              },
            ],
            EffDate: "1-1-1900",
            Life: 8,
            SLMRate: 11.88,
            WDVRate: 31.23,
          },
          {
            Id: "IV-b-xii",
            Title: "Plant and Machinery used in salt works [NESD]",
            Children: [],
            EffDate: "1-1-1900",
            Life: 15,
            SLMRate: 6.33,
            WDVRate: 18.1,
          },
        ],
        EffDate: "1-1-1900",
        Life: 0,
        SLMRate: 0,
        WDVRate: 0,
      },
    ],
    EffDate: "1-1-1900",
    Life: 0,
    SLMRate: 0,
    WDVRate: 0,
  },
  {
    Id: "V",
    Title: "Furniture and fittings [NESD]",
    Children: [
      {
        Id: "V-a",
        Title: "General furniture and fittings",
        Children: [],
        EffDate: "1-1-1900",
        Life: 10,
        SLMRate: 9.5,
        WDVRate: 25.89,
      },
      {
        Id: "V-b",
        Title:
          "Furniture and fittings used in hotels, restaurants and boarding houses, schools, colleges and other education institutions, libraries, welfare centres, meeting halls, cinema houses, theatres and circuses and furniture and fittings let out on hire for used on occasion of marriages and similar functions",
        Children: [],
        EffDate: "1-1-1900",
        Life: 8,
        SLMRate: 11.88,
        WDVRate: 31.23,
      },
    ],
    EffDate: "1-1-1900",
    Life: 0,
    SLMRate: 0,
    WDVRate: 0,
  },
  {
    Id: "VI",
    Title: "Motor Vehicles [NESD]",
    Children: [
      {
        Id: "VI-a",
        Title: "Motor cycles, scooters and other mopeds",
        Children: [],
        EffDate: "1-1-1900",
        Life: 10,
        SLMRate: 9.5,
        WDVRate: 25.89,
      },
      {
        Id: "VI-b",
        Title:
          "Motor buses, motor lorries, motor cars and motor taxies used in a business of running them on hire",
        Children: [],
        EffDate: "1-1-1900",
        Life: 6,
        SLMRate: 15.83,
        WDVRate: 39.3,
      },
      {
        Id: "VI-c",
        Title:
          "Motor buses, motor lorries, motor cars and motor taxies other than those used in a business of running them on",
        Children: [],
        EffDate: "1-1-1900",
        Life: 8,
        SLMRate: 11.88,
        WDVRate: 31.23,
      },
      {
        Id: "VI-d",
        Title: "Motor tractors, harvesting combines and heavy vehicles",
        Children: [],
        EffDate: "1-1-1900",
        Life: 8,
        SLMRate: 11.88,
        WDVRate: 31.23,
      },
      {
        Id: "VI-e",
        Title: "Electrically operated vehicles",
        Children: [],
        EffDate: "1-1-1900",
        Life: 8,
        SLMRate: 11.88,
        WDVRate: 31.23,
      },
    ],
    EffDate: "1-1-1900",
    Life: 0,
    SLMRate: 0,
    WDVRate: 0,
  },
  {
    Id: "VII",
    Title: "Ships [NESD]",
    Children: [
      {
        Id: "VII-a",
        Title: "Ocean-going ships",
        Children: [
          {
            Id: "VII-a-i",
            Title: "Bulk Carriers and liner vessels",
            Children: [],
            EffDate: "1-1-1900",
            Life: 25,
            SLMRate: 3.8,
            WDVRate: 11.29,
          },
          {
            Id: "VII-a-ii",
            Title:
              "Crude tankers, product carriers and easy chemical carriers with or without conventional",
            Children: [],
            EffDate: "1-1-1900",
            Life: 20,
            SLMRate: 4.75,
            WDVRate: 13.91,
          },
          {
            Id: "VII-a-iii-1",
            Title: "Chemicals and Acid Carriers - With Stainless steel tanks",
            Children: [],
            EffDate: "1-1-1900",
            Life: 25,
            SLMRate: 3.8,
            WDVRate: 11.29,
          },
          {
            Id: "VII-a-iii-2",
            Title: "Chemicals and Acid Carriers - With other tanks",
            Children: [],
            EffDate: "1-1-1900",
            Life: 20,
            SLMRate: 4.75,
            WDVRate: 13.91,
          },
          {
            Id: "VII-a-iv",
            Title: "Liquefied gas carriers",
            Children: [],
            EffDate: "1-1-1900",
            Life: 30,
            SLMRate: 3.17,
            WDVRate: 9.5,
          },
          {
            Id: "VII-a-v",
            Title:
              "Conventional large passenger vessels which are used for cruise purpose also",
            Children: [],
            EffDate: "1-1-1900",
            Life: 30,
            SLMRate: 3.17,
            WDVRate: 9.5,
          },
          {
            Id: "VII-a-vi",
            Title: "Coastal service ships of all categories",
            Children: [],
            EffDate: "1-1-1900",
            Life: 30,
            SLMRate: 3.17,
            WDVRate: 9.5,
          },
          {
            Id: "VII-a-vii",
            Title: "Offshore supply and support vessels",
            Children: [],
            EffDate: "1-1-1900",
            Life: 20,
            SLMRate: 4.75,
            WDVRate: 13.91,
          },
          {
            Id: "VII-a-viii",
            Title:
              "Catamarans and other high speed passenger for ships or boats",
            Children: [],
            EffDate: "1-1-1900",
            Life: 20,
            SLMRate: 4.75,
            WDVRate: 13.91,
          },
          {
            Id: "VII-a-ix",
            Title: "Drill ships",
            Children: [],
            EffDate: "1-1-1900",
            Life: 25,
            SLMRate: 3.8,
            WDVRate: 11.29,
          },
          {
            Id: "VII-a-x",
            Title: "Hovercrafts",
            Children: [],
            EffDate: "1-1-1900",
            Life: 15,
            SLMRate: 6.33,
            WDVRate: 18.1,
          },
          {
            Id: "VII-a-xi",
            Title: "Fishing vessels with wooden hull",
            Children: [],
            EffDate: "1-1-1900",
            Life: 10,
            SLMRate: 9.5,
            WDVRate: 25.89,
          },
          {
            Id: "VII-a-xii",
            Title:
              "Dredgers, tugs, barges, survey launches and other similar ships used mainly for dredging",
            Children: [],
            EffDate: "1-1-1900",
            Life: 14,
            SLMRate: 6.79,
            WDVRate: 19.26,
          },
        ],
        EffDate: "1-1-1900",
        Life: 0,
        SLMRate: 0,
        WDVRate: 0,
      },
      {
        Id: "VII-b",
        Title: "Vessels ordinarily operating on inland waters",
        Children: [
          {
            Id: "VII-b-i",
            Title: "Speed boats",
            Children: [],
            EffDate: "1-1-1900",
            Life: 13,
            SLMRate: 7.31,
            WDVRate: 20.58,
          },
          {
            Id: "VII-b-ii",
            Title: "Other vessels",
            Children: [],
            EffDate: "1-1-1900",
            Life: 28,
            SLMRate: 3.39,
            WDVRate: 10.15,
          },
        ],
        EffDate: "1-1-1900",
        Life: 0,
        SLMRate: 0,
        WDVRate: 0,
      },
    ],
    EffDate: "1-1-1900",
    Life: 0,
    SLMRate: 0,
    WDVRate: 0,
  },
  {
    Id: "VIII",
    Title: "Aircrafts or Helicopters [NESD]",
    Children: [],
    EffDate: "1-1-1900",
    Life: 20,
    SLMRate: 4.75,
    WDVRate: 13.91,
  },
  {
    Id: "IX",
    Title:
      "Railway siding, locomotives, rolling stocks, tramways and railway used by concerns, excluding railway",
    Children: [],
    EffDate: "1-1-1900",
    Life: 15,
    SLMRate: 6.33,
    WDVRate: 18.1,
  },
  {
    Id: "X",
    Title: "Ropeway structures [NESD]",
    Children: [],
    EffDate: "1-1-1900",
    Life: 15,
    SLMRate: 6.33,
    WDVRate: 18.1,
  },
  {
    Id: "XI",
    Title: "Office equipments [NESD]",
    Children: [],
    EffDate: "1-1-1900",
    Life: 5,
    SLMRate: 19,
    WDVRate: 45.07,
  },
  {
    Id: "XII",
    Title: "Computers and data processing units [NESD]",
    Children: [
      {
        Id: "XII-a",
        Title: "Servers and networks",
        Children: [],
        EffDate: "1-1-1900",
        Life: 6,
        SLMRate: 15.83,
        WDVRate: 39.3,
      },
      {
        Id: "XII-b",
        Title: "End user devices, such as, desktops, laptops, etc.",
        Children: [],
        EffDate: "1-1-1900",
        Life: 3,
        SLMRate: 31.67,
        WDVRate: 63.16,
      },
    ],
    EffDate: "1-1-1900",
    Life: 0,
    SLMRate: 0,
    WDVRate: 0,
  },
  {
    Id: "XIII",
    Title: "Laboratory equipment [NESD]",
    Children: [
      {
        Id: "XIII-a",
        Title: "General laboratory equipment",
        Children: [],
        EffDate: "1-1-1900",
        Life: 10,
        SLMRate: 9.5,
        WDVRate: 25.89,
      },
      {
        Id: "XIII-b",
        Title: "Laboratory equipments used in education institutions",
        Children: [],
        EffDate: "1-1-1900",
        Life: 5,
        SLMRate: 19,
        WDVRate: 45.07,
      },
    ],
    EffDate: "1-1-1900",
    Life: 0,
    SLMRate: 0,
    WDVRate: 0,
  },
  {
    Id: "XIV",
    Title: "Electrical Installations and Equipment [NESD]",
    Children: [],
    EffDate: "1-1-1900",
    Life: 10,
    SLMRate: 9.5,
    WDVRate: 25.89,
  },
  {
    Id: "XV",
    Title: "Hydraulic woks, pipelines and sluices [NESD]",
    Children: [],
    EffDate: "1-1-1900",
    Life: 15,
    SLMRate: 6.33,
    WDVRate: 18.1,
  },
];
