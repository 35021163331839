import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ColorPicker,
  Radio,
  Row,
  Select,
  Slider,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { SliderMarks } from "antd/es/slider";
import React, { useEffect } from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import Profiletabs from "./Profiletabs";
import { useNavigate } from "react-router-dom";
import ThemeContextProvider from "../Context/ThemeContext";

const UserProfileContent = (props: any) => {
  const { Text } = Typography;
  // const { setTrue1 } = props;
  const navigate = useNavigate();
  const [onChange, setOnChange] = React.useState<any>({});
  const [data, setData] = React.useState<any>(onChange);
  const [loading, setLoading] = React.useState<any>(false);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [true1, setTrue1] = React.useState<boolean>();
  const fontSizeMarks: SliderMarks = {
    10: "Small",
    14: "Medium",
    18: "Large",
  };
  const borderSizeMarks: SliderMarks = {
    1: "Small",
    6: "Medium",
    12: "Large",
  };
  React.useEffect(() => {
    // console.log(width);
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  React.useEffect(() => {
    if (localStorage.getItem("ci-config")) {
      let x: any = localStorage.getItem("ci-config");
      console.log("useEffect", x);
      setOnChange(JSON.parse(x));
      setData(JSON.parse(x));
      setLoading(true);
    } else {
      setOnChange({
        darkMode: false,
        themeColor: "#3a5dd3",
        fontSize: 14,
        borderRadius: 6,
        componentSize: "middle",
        tablePageSize: 15,
        silderSize: true,
      });
      setData({
        darkMode: false,
        themeColor: "#3a5dd3",
        fontSize: 14,
        borderRadius: 6,
        componentSize: "middle",
        tablePageSize: 15,
        silderSize: true,
      });
      setLoading(true);
    }
  }, []);

  return (
    <div>
      {/* <div style={{ marginBottom: "16px" }}>
                <Profiletabs />
            </div> */}
      <Row>
        <Col xl={16} sm={14} xs={15}>
          <div>
            {/* --------- Dark Mode ---------*/}
            <Row>
              <Col xl={8} sm={14} xs={15}>
                <Text>Dark Mode</Text>
              </Col>
              <Col xs={7} style={{ textAlign: "end" }}>
                <Switch
                  checked={onChange.darkMode}
                  checkedChildren={<MdDarkMode />}
                  unCheckedChildren={<MdLightMode />}
                  onChange={(value) =>
                    setOnChange({ ...onChange, darkMode: value })
                  }
                />
              </Col>
            </Row>

            {/* --------- Font Size ---------*/}
            <Row>
              <Col lg={20} style={{ paddingTop: "15px" }}>
                <Text>
                  Font Size
                  <Tooltip title="You can change font size for whole application.">
                    <InfoCircleOutlined style={{ paddingLeft: "5px" }} />
                  </Tooltip>
                </Text>
              </Col>
            </Row>

            <Col xl={15} sm={15} xs={18}>
              <Slider
                style={{ marginLeft: "15px" }}
                tooltip={{ open: false }}
                marks={fontSizeMarks}
                min={10}
                max={18}
                value={onChange.fontSize}
                onChange={(value: number) =>
                  setOnChange({ ...onChange, fontSize: value })
                }
              />
            </Col>

            {/*--------- Border Redius ---------*/}
            <Col lg={24} style={{ paddingTop: "5px" }}>
              <Text>
                Border Redius
                <Tooltip title="You can change Border size for whole application.">
                  <InfoCircleOutlined style={{ paddingLeft: "5px" }} />
                </Tooltip>
              </Text>
            </Col>
            <Col xl={15} sm={15} xs={18}>
              <Slider
                style={{ marginLeft: "15px" }}
                tooltip={{ open: false }}
                marks={borderSizeMarks}
                min={1}
                max={12}
                value={onChange.borderRadius}
                onChange={(value: number) =>
                  setOnChange({ ...onChange, borderRadius: value })
                }
              />
            </Col>

            {/*---------  Table Page Size(length) ---------*/}
            <Row style={{ paddingTop: "10px" }} align="middle">
              <Col lg={12} sm={14} xs={15}>
                <Text>
                  Default page length
                  <Tooltip title="You can change how many records are display in your table at one time.">
                    <InfoCircleOutlined style={{ paddingLeft: "5px" }} />
                  </Tooltip>
                </Text>
              </Col>
              <Col lg={12} xs={1}>
                <Select
                  style={{ width: "70px" }}
                  value={onChange.tablePageSize}
                  onChange={(value) =>
                    setOnChange({ ...onChange, tablePageSize: value })
                  }
                  options={[
                    { value: 15, label: "15" },
                    { value: 25, label: "25" },
                    { value: 50, label: "50" },
                    { value: 100, label: "100" },
                  ]}
                />
              </Col>
            </Row>

            {/*--------- Theme Color ---------*/}
            <Col style={{ padding: "15px 0px 12px 0px" }}>
              <Text>
                Theme Color
                <Tooltip title="You can change color for whole application.">
                  <InfoCircleOutlined style={{ paddingLeft: "5px" }} />
                </Tooltip>
              </Text>
            </Col>
            <ColorPicker
              style={{ width: "117px", height: "96px" }}
              className="ca-theme-colorPicker"
              presets={[
                {
                  label: "Recommended",
                  colors: [
                    "#3D4998",
                    "#0078D4",
                    "#212121",
                    "#1F3170",
                    "#570000",
                    "#380000",
                    "#432158",
                    "#D43A1C",
                    "#004B8B",
                    "#8D398F",
                    "#008299",
                    "#911844",
                    "#0055FF",
                    "#4285F4",
                    "#5B57D4",
                    "#757575",
                    "#13A8A84D",
                    "#1677FF4D",
                    "#2F54EB4D",
                    "#722ED14D",
                  ],
                },
              ]}
              value={onChange.themeColor}
              onChange={(value) =>
                setOnChange({
                  ...onChange,
                  themeColor:
                    typeof value === "string" ? value : value.toHexString(),
                })
              }
            />

            {/*--------- Silder Menu ---------*/}
            <Col style={{ paddingTop: "15px" }}>
              <Text>
                Default mode for the left Menu
                <Tooltip title="Select between viewing the left menu as a flyout or docked in a larger sized windows.">
                  <InfoCircleOutlined style={{ paddingLeft: "5px" }} />
                </Tooltip>
              </Text>
            </Col>

            <Radio.Group
              disabled={width <= 1024 ? true : false}
              value={onChange.silderSize}
              onChange={(e: any) =>
                setOnChange({ ...onChange, silderSize: e.target.value })
              }
            >
              <Row gutter={12}>
                <Col>
                  <Tooltip
                    open={width <= 1024 ? true : false}
                    title="Can be only enabled on large screen"
                    color={"black"}
                  >
                    <Card className="ca-card-theme">
                      <Radio value={true} style={{ padding: "21px 19px" }}>
                        <div>
                          <ArrowRightOutlined style={{ fontSize: "30px" }} />
                          <p>Deckot</p>
                        </div>
                      </Radio>
                    </Card>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip
                    open={width <= 1024 ? true : false}
                    title="Can be only enabled on large screen"
                  >
                    <Card className="ca-card-theme">
                      <Radio value={false} style={{ padding: "21px 18px" }}>
                        <div>
                          <ArrowLeftOutlined style={{ fontSize: "30px" }} />
                          <p>Flyout</p>
                        </div>
                      </Radio>
                    </Card>
                  </Tooltip>
                </Col>
              </Row>
            </Radio.Group>

            {/*---------  Component Size ---------*/}
            <Col style={{ paddingTop: "15px" }}>
              <Text>
                Component Size
                <Tooltip title="You can change Component size for whole application.">
                  <InfoCircleOutlined style={{ paddingLeft: "5px" }} />
                </Tooltip>
              </Text>
            </Col>

            <Radio.Group
              value={onChange.componentSize}
              onChange={(e: any) =>
                setOnChange({ ...onChange, componentSize: e.target.value })
              }
            >
              <Row gutter={10}>
                <Col>
                  <Card className="ca-card-theme">
                    <Radio value="small" style={{ padding: "26px 25px" }}>
                      <div>
                        <FontSizeOutlined style={{ fontSize: "20px" }} />
                        <p>Small</p>
                      </div>
                    </Radio>
                  </Card>
                </Col>

                <Col>
                  <Card className="ca-card-theme">
                    <Radio value="middle" style={{ padding: "19px 15px" }}>
                      <div>
                        <FontSizeOutlined style={{ fontSize: "35px" }} />
                        <p>Medium</p>
                      </div>
                    </Radio>
                  </Card>
                </Col>
                <Col>
                  <Card className="ca-card-theme">
                    <Radio value="large" style={{ padding: "12px 14px" }}>
                      <div>
                        <FontSizeOutlined style={{ fontSize: "50px" }} />
                        <p>Large</p>
                      </div>
                    </Radio>
                  </Card>
                </Col>
              </Row>
            </Radio.Group>

            {/*---------  Sumbit Button ---------*/}
            <Row style={{ paddingTop: "20px" }}>
              <Col lg={12} sm={12} xs={10}>
                <Button
                  type="dashed"
                  onClick={() => {
                    setTrue1(true);
                    setData({
                      darkMode: false,
                      themeColor: "#3a5dd3",
                      fontSize: 14,
                      borderRadius: 6,
                      componentSize: "middle",
                      tablePageSize: 15,
                      silderSize: true,
                    });
                  }}
                >
                  Default Theme
                </Button>
              </Col>
              <Col lg={12} sm={8} xs={12}>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setTrue1(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setTrue1(true);
                    setData(onChange);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
          {true1 === true &&
            (localStorage.setItem("ci-config", JSON.stringify(data)),
            navigate("/accounts"))}
          {true1 === false && navigate("/accounts")}
          {data && <ThemeContextProvider data={data} />}
        </Col>
      </Row>
    </div>
  );
};

export default UserProfileContent;
