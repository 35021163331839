export enum VoucherType {
    All,
    SalesVoucher,
    CreditNote,
    PurchaseVoucher,
    DebitNote,
    Payment,
    Receipt,
    Contra,
    PurchaseOrder,
    SalesOrder,
    Journal,
}

interface Client {
    id: string;
}

export const viewVoucher = (
    navigate: (path: string, state?: any) => void,
    currentClient: any,
    id: string,
    voucherType: VoucherType,
    setId: (id: string) => void,
    setIsOpen: (isOpen: boolean) => void,
    setPaymentId: (id: string) => void,
    setType: (type: VoucherType) => void,
    setInvoiceModel: (model: string) => void,
    setCreditModalOpen: (isOpen: boolean) => void,
    setJournalModal: (isOpen: boolean) => void,
    setContraModalOpen: (isOpen: boolean) => void
) => {
    setId(id);
    setType(voucherType);

    switch (voucherType) {
        case VoucherType.Receipt:
            setIsOpen(true);
            setPaymentId(id);
            setType(voucherType); break;
        case VoucherType.Payment:
            setIsOpen(true);
            setPaymentId(id);
            break;
        case VoucherType.SalesVoucher:
        case VoucherType.PurchaseVoucher:
            setInvoiceModel("viewSalesInvoice");
            setPaymentId(id);
            //   navigate(`/accounts/clients/${currentClient.id}/sales/editInvoice/${id}`, {
            //     state: { copy: false },
            //   });
            break;
        case VoucherType.CreditNote:
        case VoucherType.DebitNote:
            setCreditModalOpen(true);
            setPaymentId(id);
            break;
        case VoucherType.Journal:
            setJournalModal(true);
            setPaymentId(id);
            break;
        case VoucherType.Contra:
            setContraModalOpen(true);
            setPaymentId(id);
            break;
        default:
            setId(id);
    }
};
