import React from "react";
import { Button, Col, Row } from "antd";
import templete from "../../Images/Templetinvoice.jpg";
// import templete2 from "../../Images/templet02.jpg";
import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import tempimg from "../../../images/templatepdf.jpg";

const     SettingsTemplates: React.FC = () => {
  const { currentClient } = useClient();
  const navigate = useNavigate();
  const [click, SetClick] = React.useState<any>(false);

  console.log(click, "check");

  return (
    <>
      <Row style={{ padding: "0px 0px 3px 0px" }}>
        {/* <Col
          lg={4}
          style={{
            background: "#F6F9FF",
            width: 225,
            height: "100%",
            borderRadius: 4,
            padding: "35px 20px 18px 18px",
            border: click === true ? "1px solid #2D81FF " : "1px solid #E5EBF8",
          }}
        >
          <div>
            <img
              alt="imgerror"
              src={tempimg}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",

              justifyContent: "space-between",
              paddingTop: "6px",
            }}
          >
            <Button
              type={click ? "primary" : "default"}
              size="small"
              onClick={() => SetClick(!click)}
            >
              {click ? "Default" : "Selected"}
            </Button>

            <FileSearchOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() => window.alert("inprogress")}
            />

            <EditOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() =>
                navigate(
                  `/accounts/clients/${currentClient?.id}/settings/templates/1`
                )
              }
            />
          </div>
        </Col> */}
        <Col
          lg={4}
          style={{
            background: "#F6F9FF",
            width: 225,
            height: "100%",
            borderRadius: 4,
            padding: "35px 20px 18px 18px",
            marginLeft: "15px",
          }}
        >
          <div>
            <img
              alt="imgerror"
              src={tempimg}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "6px",
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => window.alert("inprogress")}
            >
              Default
            </Button>

            <FileSearchOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() => window.alert("inprogress")}
            />

            <EditOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() =>
                navigate(
                  `/accounts/clients/${
                    currentClient?.id
                  }/settings/templates/${0}`
                )
              }
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SettingsTemplates;
