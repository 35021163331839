import { AndroidOutlined, AppleOutlined, AppstoreOutlined, ProfileOutlined } from '@ant-design/icons'
import { Segmented, Space, Tabs } from 'antd'
import React, { useState } from 'react'
import { BiColorFill, BiUserCircle } from 'react-icons/bi'
import ProfileDetails from './ProfileDetails'
import ThemeSetting from './ThemeSetting'

const Profiletabs = (props: any) => {
    const { setTrue1 } = props;
    const [segementVal, setSegementVal] = useState("1");
    return (
        <div style={{ paddingLeft: "25px" }}>
            <Tabs
                onChange={(e: any) => setSegementVal(e)}

                items={[BiUserCircle, BiColorFill].map((Icon, i) => {

                    const id = String(i + 1);
                    return {
                        label: (
                            <Space>
                                <Icon />
                                {
                                    id === "1" ? "Profile" : "Theme"
                                }
                            </Space>
                        ),
                        key: id,
                        children: <> {segementVal === "1" ? <ProfileDetails /> : <ThemeSetting setTrue1={setTrue1} />}  </>
                    };
                })}
            />


        </div>
    )
}

export default Profiletabs;