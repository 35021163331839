import { Button, Card, Col, Divider, Modal, Row, Upload } from "antd";
import { SliderMarks } from "antd/es/slider";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContextProvider from "../Context/ThemeContext";
import ThemeSetting from "./ThemeSetting";
import { Image } from "antd";
import "./Profile.css";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useUser } from "../../Context/UserContext";
import Profiletabs from "./Profiletabs";
import { SiMicrosoftoutlook } from "react-icons/si";
import { BiLink } from "react-icons/bi";
import {
  ExclamationCircleOutlined,
  MailOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ProfileService from "../../Services/ProfileService";
import adminContext from "../../Context/AdminContext";
import modal from "antd/es/modal";
interface IChangeProps {
  darkMode?: boolean;
  themeColor: string;
  fontSize: number;
  borderRadius: number;
  componentSize: "small" | "middle" | "large" | undefined;
}
const UserProfile = () => {
  const { getUser } = useUser();
  const loginUser: any = { ...getUser() };
  const { getval, setTrigger } = useContext(adminContext);
  const navigate = useNavigate();
  const [onChange, setOnChange] = React.useState<any>({});
  const [data, setData] = React.useState<any>(onChange);
  const [loading, setLoading] = React.useState<any>(false);
  const [segementVal, setSegementVal] = React.useState<any>("1");

  console.log("Login User Detail: ",getval,  loginUser);

  React.useEffect(() => {
    if (localStorage.getItem("ci-config")) {
      let x: any = localStorage.getItem("ci-config");
      console.log("useEffect", x);
      setOnChange(JSON.parse(x));
      setData(JSON.parse(x));
      setLoading(true);
    } else {
      setOnChange({
        darkMode: false,
        themeColor: "#3d4998",
        fontSize: 14,
        borderRadius: 6,
        componentSize: "middle",
        tablePageSize: 15,
        silderSize: true,
      });
      setData({
        darkMode: false,
        themeColor: "#3d4998",
        fontSize: 14,
        borderRadius: 6,
        componentSize: "middle",
        tablePageSize: 15,
        silderSize: true,
      });
      setLoading(true);
    }
  }, []);
  const [true1, setTrue1] = React.useState<boolean>();
  const getOutlook = () => {
    window.location.href = "mailto:email@outlook.com";
  };
  const getMail = () => {
    window.location.href = "mailto:email@gmail.com";
  };

  // Post
  const onImageUpload = (userId: string, file: any) => {
    const config = "multipart/form-data";
    const formData = new FormData();
    formData.append("file", file);
    Modal.confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to upload profile",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        await ProfileService.uploadProfilePicture(
          userId,
          formData,
          config
        ).then((res: any) => {
          if (res?.status) {
            setTrigger((x: any) => !x);
            console.log("Success");
          } else {
            console.log("Failed");
          }
        });
      },
    });
  };

  return (
    <>
      {loading && (
        <>
          <Row
            gutter={16}
            justify={"space-between"}
            style={{
              padding: "20px 20px 0px 20px",
              height: `calc(100vh - 70px)`,
              overflowY: "auto",
            }}
          >
            <Col
              xl={10}
              style={{
                backgroundColor: "rgb(247, 248, 250)",
                // marginTop: "-20px",
                // marginLeft: "-20px",
              }}
            >
              <Card style={{ height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "35px",
                  }}
                >
                  <Image
                    className="custom"
                    style={{
                      padding: "5px",
                    }}
                    src={getval?.profilePhoto?.path}
                  />
                </div>

                <div
                  style={{
                    paddingTop: "12px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Upload
                    maxCount={1}
                    onChange={(e: any) => onImageUpload(loginUser?.id, e.file)}
                    showUploadList={false}
                    beforeUpload={() => {
                      return false;
                    }}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{
                        backgroundColor: "#4CAF50",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      }}
                      size="middle"
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </div>
                <Divider />

                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 500,
                    paddingTop: "4px",
                    color: "lightslategray",
                  }}
                >
                  {/* {loginUser.email} */}
                </span>
                <Row
                  justify="center"
                  gutter={75}
                  style={{ paddingTop: "18px" }}
                >
                  <Col>
                    {/* <Button
                      onClick={getMail}
                      icon={<MailOutlined />}
                      style={{
                        backgroundColor: "#4285F4",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      }}
                      // size="large"
                    >
                      Link Gmail
                    </Button> */}
                  </Col>
                  <Col>
                    {/* <Button
                      onClick={getOutlook}
                      icon={<SiMicrosoftoutlook style={{ color: "#fff" }} />}
                      style={{
                        backgroundColor: "#0078D4",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                      }}
                      // size="large"
                    >
                      Link Outlook
                    </Button> */}
                  </Col>
                </Row>
              </Card>
            </Col>
            {/* mailto:example@outlook.com?subject=Subject&body=Body */}
            <Col xl={14} sm={14} xs={15}>
              <Card style={{ height: "100%" }}>
                <div style={{ marginBottom: "16px" }}>
                  <Profiletabs true1={true1} setTrue1={setTrue1} />
                </div>
              </Card>
            </Col>
          </Row>
          {true1 === true &&
            (localStorage.setItem("ci-config", JSON.stringify(data)),
            navigate(-1))}

          {true1 === false && navigate(-1)}

          {data && <ThemeContextProvider data={data} />}
        </>
      )}
    </>
  );
};

export default UserProfile;
