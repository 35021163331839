import React from "react";
import { useHookstate } from "@hookstate/core";
import ClientService from "../Services/ClientService";
import ClientServiceNew from "../Services/ClientService";
import { useUser } from "../../Context/UserContext";

interface IStateDataProviderProps {
  children?: any;
}

interface IStateContextProps {
  stateOptions: [];
  loading?: boolean;
}

const StateOptionsContext = React.createContext<Partial<IStateContextProps>>(
  {}
);
const StateProvider = ({ children }: IStateDataProviderProps) => {
  const { getUser } = useUser();

  const iState = useHookstate<Partial<IStateContextProps>>({});
  const stateOptions = iState.stateOptions.get({ noproxy: true });
  const loginUser: any = { ...getUser() };

  const setStateOptionsDetail: any = async () => {
    try {
      // if (loginUser?.id) {
      const setState = await ClientServiceNew.getStateOption(true).then(
        (res: any) => {
          if (res.result) {
            const result = res.result;
            result.shift();
            // console.log("state", result);
            return result;
          }
          // setStatesOption(res?.result);
        }
      );
      iState.merge({
        stateOptions: setState,
      });
      // }
    } catch (es) {
      console.error("client Context error ------ ", es);
    }
  };

  React.useEffect(() => {
    setStateOptionsDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <StateOptionsContext.Provider
        value={{
          stateOptions: stateOptions as [],
        }}
        children={children}
      />
    </div>
  );
};

export default StateProvider;

export function useIndianState() {
  const context = React.useContext(StateOptionsContext);
  if (context === undefined) {
    throw new Error("StateData context not defined");
  }
  return context;
}
