import React from "react";
import { useClient } from "../../../Context/ClientContext";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Skeleton,
  Table,
  Tag,
} from "antd";
import { dayjs } from "../../../../Utilities/dayjs";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";
import { ImportOutlined } from "@ant-design/icons";
import { useTheme } from "../../../Context/ThemeContext";
import ImportBankTransactionModal from "./ImportBankTransactionModal";
import { useLocation, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { Utils } from "../../../../Utilities/Utils";
import { notificationContext } from "../../../Common/PageRoute";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import BankTransactions, {
  EntryStatus,
} from "../../../Services/BankTransactions";
const { RangePicker } = DatePicker;

interface IListData {
  id: string;
  startDate: string;
  endDate: string;
  fileName: string;
  importDate: string;
  pendingEntries: number;
  totalEntries: number;
  progress: number;
  entries: any[];
  status: EntryStatus;
}

const History = () => {
  const { themeData } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { openNotification } = React.useContext(notificationContext);
  const { currentClient, companySettings } = useClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs().startOf("month").format("DD/MM/YYYY"),
    to: dayjs().endOf("month").format("DD/MM/YYYY"),
  });

  // React.useEffect(() => {
  //   setFilterDate({
  //     from: JSON.parse(localStorage.getItem("LockPeriod")!)
  //       ? JSON.parse(localStorage.getItem("LockPeriod")!)?.from
  //       : dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),

  //     to: JSON.parse(localStorage.getItem("LockPeriod")!)
  //       ? JSON.parse(localStorage.getItem("LockPeriod")!)?.to
  //       : dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.fromDate, props.toDate]);

  const [ledgerList, setLedgerList] = React?.useState<any>();
  const [ledgerId, setLedgerId] = React.useState<string>("");
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [bankTransactionModal, setBankTransactionModal] = React?.useState<{
    type: string;
    param: any;
  }>({ type: "", param: {} });
  const [historyList, setHistoryList] = React?.useState<any[]>();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [serchAllField, setSerchAllField] = React.useState<string>("");
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [enteryStatus, setEnteryStatus] = React.useState<EntryStatus>(
    EntryStatus.Active
  );
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [id, setId] = React.useState<any>();
  // console.log("location", location.state?.id);

  React.useEffect(() => {
    if (location.state?.ledgerId) setLedgerId(location.state?.ledgerId);
  }, [location.state]);

  React.useEffect(() => {
    if (currentClient?.id) {
      const getLedgersByGroupName = async () => {
        let res = await LedgerService?.getLedgersByGroupName(
          currentClient?.id,
          SearchLedgerByGroupsTypes?.BankOnly
        );
        if (res) {
          setLedgerList(res);
        }
      };
      getLedgersByGroupName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<IListData> = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "10%",
      // width: width <= 1440 ? "8%" : "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.startDate}
        </Skeleton>
      ),
      // ...getColumnSearchProps("ledgerName"),
    },
    {
      title: "Finish Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.endDate}
        </Skeleton>
      ),
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      width: "37%",
      // onFilter: (value: string, record) => {
      //   return set
      // },
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record.fileName}
        </Skeleton>
      ),
      // ...getColumnSearchProps("balance"),
    },
    {
      title: "Import Date",
      dataIndex: "imported",
      key: "imported",
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.importDate}
        </Skeleton>
      ),
    },
    {
      title: "Progress",
      dataIndex: "",
      key: "",
      width: "8%",
      // width: width <= 1440 ? "12%" : "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {`${record.totalEntries - record.pendingEntries} of ${
            record.totalEntries
          } (${Utils.getFormattedNumber(
            ((record.totalEntries - record.pendingEntries) /
              record.totalEntries) *
              100
          )}%)`}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "isActive",
      width: "15%",
      render: (_, record) => (
        <Skeleton
          active
          paragraph={{
            rows: 1,
          }}
          loading={loading}
          title={false}
          className="cursor"
        >
          {/* <Select
            options={[
              { label: "Explain", value: "select" },
              { label: "Delete", value: 2 },
            ]}
            style={{ width: "100%" }}
          /> */}

          <Row gutter={5}>
            {record?.status === 2 ? (
              <>
                <Row style={{ marginLeft: "4px" }}>
                  <Tag color="red">Deleted</Tag>
                </Row>
              </>
            ) : record?.status === 3 ? (
              <>
                <Row style={{ marginLeft: "3px" }}>
                  <Tag
                    color="green"
                    style={{ width: "77px", textAlign: "center" }}
                  >
                    Completed
                  </Tag>
                </Row>
              </>
            ) : (
              <>
                <Col>
                  {record?.pendingEntries !== 0 && (
                    <>
                      <Button
                        onClick={() =>
                          navigate(
                            `/accounts/clients/${currentClient?.id}/banking/bankTransactionsDetails/${record?.id}`,
                            {
                              state: {
                                ledgerId: ledgerId,
                              },
                            }
                          )
                        }
                      >
                        Categorize
                        {/* console.log("record", record?.id) */}
                      </Button>
                    </>
                  )}
                </Col>
                {
                  <Col>
                    {record?.pendingEntries === record?.totalEntries && <></>}
                  </Col>
                }
                <Col>
                  {record?.pendingEntries === record?.totalEntries && (
                    <>
                      <Button
                        onClick={() => {
                          setConfirmation(true);
                          setId(record?.id);
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Skeleton>
      ),
    },
  ];

  const deleteHistoryList = async (bankEnteryId: string) => {
    let res = await BankTransactions?.deleteHistory(
      currentClient?.id!,
      ledgerId,
      bankEnteryId
    );
    if (res?.result) {
      console.log("delete", res);
      getAllImportData();
      openNotification("success", "Deleted Successfully");
    } else {
      openNotification("error", res?.message);
    }
  };

  React.useEffect(() => {
    if (ledgerId) {
      getAllImportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterDate?.from,
    filterDate?.to,
    ledgerId,
    enteryStatus,
    location?.state?.refresh,
  ]);

  const getAllImportData = async () => {
    setLoading(true);

    let res = await BankTransactions?.getAllBankHistoryList(
      currentClient?.id,
      ledgerId,
      dayjs(filterDate?.from, "DD/MM/YYYY").toISOString(),
      dayjs(filterDate?.to, "DD/MM/YYYY").toISOString(),
      enteryStatus
    );
    if (res.result) {
      setHistoryList(
        res.result?.map((x: any) => {
          return {
            id: x.id,
            startDate: dayjs(x?.startDate).format("DD/MM/YYYY"),
            endDate: dayjs(x?.endDate).format("DD/MM/YYYY"),
            fileName: x?.fileName,
            importDate: dayjs(x?.importDate).format("DD/MM/YYYY"),
            pendingEntries: x?.pendingEntries,
            totalEntries: x?.totalEntries,
            progress: x?.pendingEntries / x?.totalEntries,
            entries: x?.entries,
            status: x?.status,
          };
        })
      );
      setLoading(false);

      console.log("res", historyList);
    }
  };

  console.log("val", enteryStatus);
  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs();
    const startDate = now.subtract(15, "day").startOf("day");
    const endDate = now.endOf("day");
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs();
    const startDate = now.subtract(7, "day").startOf("day");
    const endDate = now.endOf("day");
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  return (
    <>
      <div>
        <Card className="TableCard">
          <Row justify={"space-between"}>
            <Col>
              <Row>
                <Col>
                  <h3
                    style={{
                      margin: "5px 0px 15px 0px",
                      color: "rgb(22, 119, 255)",
                    }}
                  >
                    {`Bank Transaction Import History`}
                  </h3>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row gutter={10}>
                <Col>
                  <Select
                    defaultValue={EntryStatus?.Active}
                    style={{ width: 120 }}
                    onChange={(val: any) => setEnteryStatus(val)}
                    options={[
                      { value: EntryStatus?.All, label: "All" },
                      { value: EntryStatus.Active, label: "Active" },
                      { value: EntryStatus?.Deleted, label: "Deleted" },
                      { value: EntryStatus?.Completed, label: "Completed" },
                    ]}
                  />
                </Col>
                <Col>
                  {/* <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                    Period :
                  </span> */}
                  <RangePicker
                    format="DD/MM/YYYY"
                    defaultValue={[
                      dayjs(filterDate?.from, "DD/MM/YYYY"),
                      dayjs(filterDate?.to, "DD/MM/YYYY"),
                    ]}
                    style={{
                      width:
                        width <= 464
                          ? "210px"
                          : width == 375
                          ? "200px"
                          : "250px",
                      border: "1px solid #f1f3ff",
                    }}
                    onChange={(e: any) => {
                      if (e === null) {
                        setFilterDate({});
                      } else {
                        setFilterDate({
                          ...filterDate,
                          from: dayjs(e[0]).format("DD/MM/YYYY"),
                          to: dayjs(e[1]).format("DD/MM/YYYY"),
                        });
                        // localStorage.setItem(
                        //   "LockPeriod",
                        //   JSON.stringify({
                        //     ...filterDate,
                        //     from: dayjs(e[0]).format("DD/MM/YYYY"),
                        //     to: dayjs(e[1]).format("DD/MM/YYYY"),
                        //   })
                        // );
                        // openNotification("success", `Period locked.`);
                      }
                    }}
                    presets={[
                      {
                        label: "Today",
                        value: [dayjs().add(0, "d"), dayjs()],
                      },
                      {
                        label: "Last 7 Days",
                        value: getLast7Days(),
                      },
                      {
                        label: "Last 15 Days",
                        value: getLast15Days(),
                      },
                      {
                        label: "This Month",
                        value: getThisMonth(),
                      },
                      {
                        label: "Last Month",
                        value: getLastMonth(),
                      },
                      {
                        label: "Quarter 1",
                        value: getQuarterPreset(0),
                      },
                      {
                        label: "Quarter 2",
                        value: getQuarterPreset(3),
                      },
                      {
                        label: "Quarter 3",
                        value: getQuarterPreset(6),
                      },
                      {
                        label: "Quarter 4",
                        value: getQuarterPreset(9),
                      },
                      {
                        label: "This Year",
                        value: thisYear(),
                      },
                      {
                        label: "Last Year",
                        value: lastYear(),
                      },
                    ]}
                  />
                </Col>
                <Col>
                  {/* <span style={{ fontWeight: "bold" }}>Select Ledger : </span> */}
                  <Select
                    // defaultValue={ledgerId}
                    value={ledgerId}
                    placeholder="Select.."
                    onChange={(val: any) => {
                      console.log(val);
                      setLedgerId(val);
                    }}
                    style={{ width: "200px" }}
                    options={ledgerList?.map((itm: any) => ({
                      label: itm?.ledgerName,
                      value: itm?.id,
                      title: itm?.partyOrTax,
                    }))}
                  />
                </Col>

                <Col>
                  <Button
                    icon={<ImportOutlined />}
                    type="primary"
                    onClick={() => {
                      setBankTransactionModal({
                        type: "BankTransaction",
                        param: {
                          open: true,
                        },
                      });
                      // setShowTable(false);
                    }}
                  >
                    Import File
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <div>
            <Table
              columns={columns}
              dataSource={
                loading
                  ? (Array.from({ length: pageSize }, (_, index) => ({
                      key: `loading-${index}`,
                    })) as unknown as IListData[])
                  : historyList
              }
              locale={{
                triggerDesc: "",
                triggerAsc: "",
                cancelSort: "",
              }}
              // onChange={tableSort}
              className={`Tabel-style table-${
                themeData?.componentSize ?? "middle"
              }`}
              // rowSelection={rowSelection}
              pagination={{
                total: totalRecords,
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                onChange: (page: number, pageSize: number) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
                pageSizeOptions: ["10", "15", "25", "50", "100"],
                showTotal: (totalRecords, page) =>
                  `${page[0]}-${page[1]} of ${totalRecords} items`,
              }}
              scroll={{ x: 1050, y: window.innerHeight - 330 }}
            />
          </div>
        </Card>
      </div>
      {bankTransactionModal.type === "BankTransaction" && (
        <ImportBankTransactionModal
          ledgerId={ledgerId}
          open={true}
          onCancel={() => setBankTransactionModal({ type: "", param: "" })}
          partyOrTax={location.state?.partyOrTax}
          filterDate={filterDate}
        />
      )}

      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            deleteHistoryList(id);
            setConfirmation(false);
          }}
          text={"Are you sure for deleted ?"}
        />
      )}
    </>
  );
};

export default History;
