import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class DashboardService {
  // route = "V1/API/Acc_Dashboard";
  route = "V1/API/Acc_New_Dashboard";
  getDashboardData = (companyId: string, from: string, to: string) => {
    return ApiUtility.get(`${this.route}/Data?companyId=${companyId}`, {
      fromDate: from,
      toDate: to,
    });
  };
}
export default new DashboardService();
