import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { stringify } from "qs";
import { AuthService } from "../Services/AuthService";
import { InMemoryJWT } from "../Utilities/InMemoryJWT";
import { notification } from "antd";

export interface IApiResponse<T = any> {
 // map(arg0: (i: any) => { id: any; name: any; price: any; rate: any; year: any; method: any; accumulatedDep: any; lastDepDate: any; startDate: any; depreciableAsset: any; }): unknown;
  status: boolean;
  result: T;
  message?: string;
  errors?: {
    number: number;
    message: string;
    suggestion: string;
    exception: any;
  }[];
}
export interface IApiListResult<T = any> {
  totalRecords: number;
  items: T[];
  moreRecords?: boolean;
  continuationToken?: string;
}

export interface IApiListResultWithTotal<TItem = any, TTotal = any>
  extends IApiListResult<TItem> {
  total: TTotal;
}

export enum HttpMethods {
  get = "get",
  post = "post",
}
export enum FileDownloadStatus {
  downloading = "downloading",
  done = "done",
  error = "error",
}
export type DownloadCallback = (
  status: FileDownloadStatus,
  error?: any
) => void;

export interface IAxiosRequestConfigWithoutParams
  extends Omit<AxiosRequestConfig, "params" | "paramsSerializer"> {
  contentType?: string;
}

class ApiUtilityBase {
  // private getParams = (params?: any) => {
  //   if (params) {
  //     for (const key in params) {
  //       if (params[key] == null || params[key] === undefined || params[key] === '')
  //         delete params[key];
  //     }
  //   }
  //   return params;
  // };

  getResponse = async <T = any>(
    endpoint: string,
    params?: any,
    options?: IAxiosRequestConfigWithoutParams
  ) => {
    return await Axios.get<T>(endpoint, {
      params,
      paramsSerializer: {
        serialize: (p, o) => {
          return stringify(params, {
            arrayFormat: "indices",
            allowDots: true,
            skipNulls: true,
          });
        },
      },
      ...this._axiosOptions(),
      ...(options || {}),
    });
  };

  get = async <T = IApiResponse>(
    endpoint: string,
    params?: any,
    throwErrorOn401?: boolean,
    options?: IAxiosRequestConfigWithoutParams
  ) => {
    //check token validity and refresh it if requires
    await InMemoryJWT.checkAndRefreshToken(`get ${endpoint}`);

    const response = await this.getResponse<T>(endpoint, params, options);

    const data = this.handleResponse<T>(response, throwErrorOn401);
    return data;
  };
  getResult = async <T = any>(
    endpoint: string,
    params?: any,
    throwErrorOn401?: boolean,
    options?: IAxiosRequestConfigWithoutParams
  ) => {
    try {
      const data = await this.get<IApiResponse<T>>(
        endpoint,
        params,
        undefined,
        options
      );
      if (data.status) {
        return data.result;
      } else {
        this.handleErrorResponse(data.message, data.errors);
      }
    } catch (error: any) {
      if (error!.isAxiosError) this.handleAxiosError(error, throwErrorOn401);
      else this.handleResponse(error, throwErrorOn401);
    }

    return null;
  };

  post = async <T = IApiResponse>(
    endpoint: string,
    body: any,
    // contentType: string = "multipart/form-data",
    contentType?: string,
    options?: IAxiosRequestConfigWithoutParams
  ): Promise<T> => {
    try {
      //check token validity and refresh it if requires
      await InMemoryJWT.checkAndRefreshToken(`post ${endpoint}`);

      const response = await Axios.post<T>(
        endpoint,
        body,
        this._axiosOptions({ contentType, ...(options || {}) })
      );
      const data = this.handleResponse<T>(response);
      return data;
    } catch (ex: any) {
      if (ex?.isAxiosError) {
        return this.handleAxiosError(ex);
      }
    }
    return {} as T;
  };
  put = async <T = IApiResponse>(
    endpoint: string,
    body: any,
    contentType?: string,
    options?: IAxiosRequestConfigWithoutParams
  ): Promise<T> => {
    try {
      //check token validity and refresh it if requires
      await InMemoryJWT.checkAndRefreshToken(`put ${endpoint}`);

      const response = await Axios.put<T>(
        endpoint,
        body,
        this._axiosOptions({ contentType, ...(options || {}) })
      );
      const data = this.handleResponse<T>(response);
      return data;
    } catch (ex: any) {
      if (ex?.isAxiosError) {
        return this.handleAxiosError(ex);
      }
    }
    return {} as T;
  };
  appendFormDataValues = (form: FormData, values: any, preFix?: string) => {
    if (!preFix || preFix.length <= 0) preFix = "";

    //check if given object is a string/number/boolean then add directly to the list with prefix
    if (
      (typeof values !== "function" &&
        typeof values !== "object" &&
        typeof values !== "symbol") ||
      values instanceof File
    ) {
      if (values && preFix) {
        form.append(preFix, values);
      }
      return;
    }

    for (const key in values) {
      //prepare a field name
      const fieldName = `${preFix}${
        preFix && !preFix.endsWith(".") ? "." : ""
      }${key}`;

      if (values[key] instanceof FileList) {
        for (let fIndex = 0; fIndex < values[key].length; fIndex++) {
          form.append(`${fieldName}[${fIndex}]`, values[key].item(fIndex));
        }
      } else {
        if (Array.isArray(values[key])) {
          values[key].forEach((el: any, idx: number) => {
            this.appendFormDataValues(form, el, `${fieldName}[${idx}]`);
          });
        } else {
          if (typeof values[key] === "object") {
            this.appendFormDataValues(form, values[key], fieldName);
          } else if (values[key]) {
            form.append(fieldName, values[key]);
          }
        }
      }
    }
  };
  postForm = async <T = IApiResponse>(
    endpoint: string,
    params: any,
    options?: IAxiosRequestConfigWithoutParams
  ) => {
    let formData = new FormData();
    if (params instanceof FormData) {
      formData = params;
    } else {
      this.appendFormDataValues(formData, params);
    }
    try {
      //check token validity and refresh it if requires
      await InMemoryJWT.checkAndRefreshToken(`post-form ${endpoint}`);
      const response = await Axios.post<T>(
        endpoint,
        formData,
        this._axiosOptions({
          contentType: "multipart/form-data",
          ...(options || {}),
        })
      );
      const data = this.handleResponse(response);
      return data;
    } catch (ex: any) {
      if (ex?.isAxiosError) {
        return this.handleAxiosError(ex);
      }
    }
    return {} as T;
  };
  putForm = async <T = IApiResponse>(
    endpoint: string,
    params: any,
    options?: IAxiosRequestConfigWithoutParams
  ) => {
    let formData = new FormData();

    if (params instanceof FormData) {
      formData = params;
    } else {
      this.appendFormDataValues(formData, params);
    }

    try {
      //check token validity and refresh it if requires
      await InMemoryJWT.checkAndRefreshToken(`put-form ${endpoint}`);

      const response = await Axios.putForm<T>(
        endpoint,
        formData,
        this._axiosOptions({
          contentType: "multipart/form-data",
          ...(options || {}),
        })
      );
      const data = this.handleResponse(response);
      return data;
    } catch (ex: any) {
      if (ex?.isAxiosError) {
        return this.handleAxiosError(ex);
      }
    }
    return {} as T;
  };

  delete = async <T = IApiResponse>(
    endpoint: string,
    contentType?: string,
    options?: IAxiosRequestConfigWithoutParams
  ) => {
    //check token validity and refresh it if requires
    await InMemoryJWT.checkAndRefreshToken(`delete ${endpoint}`);

    const response = await Axios.delete<T>(
      endpoint,
      this._axiosOptions({ contentType, ...(options || {}) })
    );
    const data = this.handleResponse<T>(response);
    return data;
  };

  private getFileName = (contentDispositionValue: string) => {
    var filename: string | undefined = undefined;
    if (
      contentDispositionValue &&
      contentDispositionValue.indexOf("attachment") !== -1
    ) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDispositionValue);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  };
  downloadFile = async (
    endpoint: string,
    params?: any,
    method?: HttpMethods,
    options?: IAxiosRequestConfigWithoutParams
  ): Promise<{ status: FileDownloadStatus; error?: any }> => {
    //check token validity and refresh it if requires
    await InMemoryJWT.checkAndRefreshToken(`download ${endpoint}`);

    let response: Promise<AxiosResponse<any>>;
    const headers: any = {};
    // return authorization header with jwt token
    const token = AuthService.getAuthToken();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const axiosOptions: IAxiosRequestConfigWithoutParams = {
      headers,
      ...(options || {}),
    };
    if (method === HttpMethods.post) {
      response = Axios.post(endpoint, params, {
        ...this._axiosOptions(axiosOptions),
        responseType: "blob",
      });
    } else {
      response = Axios.get(endpoint, {
        params,
        paramsSerializer: {
          serialize: (p, o) => {
            return stringify(params, {
              arrayFormat: "indices",
              allowDots: true,
              skipNulls: true,
            });
          },
        },
        ...this._axiosOptions(axiosOptions),
        responseType: "blob",
      });
    }

    try {
      const result_1 = await response;
      //extract file name from Content-Disposition header
      const fileName = this.getFileName(
        (result_1.headers.get as any)("Content-Disposition")
      );
      //invoke 'Save As' dialog
      saveAs(result_1.data, fileName);
      return { status: FileDownloadStatus.done };
    } catch (error: any) {
      if (error!.isAxiosError) {
        this.handleResponse(error.response);
      } else this.handleResponse(error);
      if (error.response) {
        return {
          status: FileDownloadStatus.error,
          error: error.response.status,
        };
      } else {
        return { status: FileDownloadStatus.error, error: error.message };
      }
    }
  };

  getAuthHeader = (contentType?: string) => {
    const headers: any = {
      "Content-Type": contentType || "application/json",
      Accept: "application/json",
    };

    // return authorization header with jwt token
    const token = AuthService.getAuthToken();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
  };

  handleResponse = <T = IApiResponse>(
    response: AxiosResponse<T>,
    throwErrorOn401?: boolean
  ) => {
    if (!response) {
      // AOToast.error('No response from the server, please try after some time.');
    } else if ([401, 403].indexOf(response.status) !== -1) {
      if (throwErrorOn401) {
        throw response;
      } else {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        AuthService.logout();

        window.location.reload();
      }
    }
    return response?.data;
  };
  handleAxiosError = <T = IApiResponse>(
    error: AxiosError,
    throwErrorOn401?: boolean
  ): T => {
    if (throwErrorOn401 && error.response?.status === 401) throw error;
    else if (error.response?.status === 400) {
      const data = (error.response as any).data;
      if (data && data.errors) {
        const arr: any[] = [];
        for (const key in data.errors) {
          if (data.errors[key] && data.errors[key].length > 0) {
            arr.push(data.errors[key][0]);
          }
        }
        return {
          result: null,
          status: false,
          message: arr.length > 0 ? arr[0] : "",
          errors: arr,
        } as unknown as T;
      }
    }
    return {} as T;
  };
  handleErrorResponse = (message: any, errors?: any) => {
    notification.error({
      placement: "topRight",
      message: "",
      description: message,
    });
  };

  _axiosOptions = (
    options?: IAxiosRequestConfigWithoutParams
  ): AxiosRequestConfig => {
    const { contentType, headers, ...rest } = options || {};
    return {
      headers: headers || this.getAuthHeader(contentType),
      baseURL: this.getBaseUrl(),
      ...rest,
    };
  };
  getBaseUrl = () => process.env.REACT_APP_BASE_URL;
}

export const ApiUtility = new ApiUtilityBase();
