import { ApiUtility } from "../../Utilities/ApiUtility.axios";



class ChartofAccountsServices {
  route = "/v1/API/Acc_Accounts";

  getListChartofAccounts = (
    clientId: string,
    start: number,
    length: number,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: string
    // accountTypeId?: number,
    // businessType?: number,
    // accountGroupId?: string
  ) =>
    ApiUtility.getResult(`${this.route}/${clientId}`, {
      start,
      length,
      search,
      sortCol,
      sortDir,
      status,
      // accountTypeId,
      // businessType,
      // accountGroupId,
    });

  getChartofAccounts = (clientId: string, id: number) =>
    ApiUtility.get(`${this.route}/${clientId}/${id}`);

  postChartofAccounts = (param: any, clientId: string, id?: number) =>
    ApiUtility.post(`${this.route}/${clientId}${id ? "/" + id : ""}`, param);

  toggleChartofAccount = (clientId: string, id: number) =>
    ApiUtility.post(`${this.route}/${clientId}/${id}/Toggle`, id);

  deleteChartofAccounts = (clientId: string, id: number) =>
    ApiUtility.post(`${this.route}/${clientId}/${id}/Delete`, id);

  resetChartofAcount = (clientId: string, id: number) =>
    ApiUtility.post(`${this.route}/${clientId}/${id}/Reset`, id);

  downloadCSV = (
    clientId: string,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    accountTypeId?: string,
    status?: string,
    accountGroupId?: string
  ) =>
    ApiUtility.downloadFile(
      `${this.route}/${clientId}/Download/CSV/?search=${search}&sortCol=${sortCol}&sortDir${sortDir}&accountTypeId=${accountTypeId}&status=${status}&accountGroupId=${accountGroupId}`
    );

  getAccountCode = (clientId: string, accountTypeId: number) =>
    ApiUtility.get(`${this.route}/${clientId}/Code/${accountTypeId}`);
}

export default new ChartofAccountsServices();
