import { ApiUtility } from "../../Utilities/ApiUtility.axios";
class TermsAndConditions {
  private routes = "/v1/API/Acc_TnC/";

  getTermsAndConditionList = (
    companyId: string | undefined,
    voucherType: any,
    start?: number,
    length?: number,
    search?: string
  ) =>
    ApiUtility.get(
      `${this.routes}${companyId}/GetTnCList?voucherType=${voucherType}&start=${start}&length=${length}&search=${search}`
    );
  getTermsAndConditionbyId = (
    companyId: string | undefined,
    templateId: string,
    voucherType: any
  ) =>
    ApiUtility.get(
      `${this.routes}${companyId}/${templateId}?voucherType=${voucherType}`
    );

  saveData = (
    companyId: string | undefined,
    templateId: string,
    voucherType: any,
    model: any
  ) =>
    ApiUtility.post(
      `${this.routes}${companyId}/${templateId}?voucherType=${voucherType}`,
      model
    );

  deleteDatabyId = (companyId: string | undefined, templateId: string) =>
    ApiUtility.delete(`${this.routes}${companyId}/${templateId}`);
}

export default new TermsAndConditions();
