import React from "react";
import { Card, Col, Collapse, Row, Skeleton } from "antd";
import SalesInvoiceTable from "./SalesInvoiceTable";
import { useClient } from "../../Context/ClientContext";
import {
  CardDivider,
  DashboardChart,
  SalesCard,
} from "../../Common/DashboardChart";
import { Utils } from "../../../Utilities/Utils";
import plusImg from "../../Images/plus.svg";
import equalImg from "../../Images/equal.svg";
import currency1 from "../../../images/currency1.svg";
import currency2 from "../../../images/currency2.svg";
import currency3 from "../../../images/currency3.svg";
import { useLocation } from "react-router-dom";
import { Voucher_Type } from "../../Services/VoucherServices";
import OrderTable from "./OrderTable";
const SalesOrderDashboard: React.FC = () => {
  const { activeKey } = useClient();
  const location = useLocation();
  const [salesOrderList, setSalesOrderList] = React.useState<any>([]);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [loading, setLoading] = React.useState<boolean>(false);
  console.log(activeKey);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    console.log(width, "hello this is screen width");
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const option = {
    tooltip: {
      trigger: "item",
    },
    color: ["#eef2fe", "#fac858", "#91cc75", "#FF4D4F"],
    series: [
      {
        name: "Invoice",
        type: "pie",
        radius: ["45%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
          fontSize: 18,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 16,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 0, name: "chart is empty" },
          { value: salesOrderList?.pending, name: "Pending" },

          { value: salesOrderList?.suppliedQty, name: "Supplied Qty" },
        ],
      },
    ],
  };

  let splitPathInVchType =
    location.pathname.split("/")[4] === "sales"
      ? Voucher_Type.SalesVoucher
      : Voucher_Type.PurchaseVoucher;

  console.log(salesOrderList, "listttttt");

  return (
    <div>
      <div
        style={{
          // paddingTop: "20px",
          // paddingBottom: "30px",
          borderRadius: "8px",
          marginTop: 35,
          padding: "38px 0px 38px",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
          // marginLeft: "-8px",
          backgroundColor: "white",
        }}
      >
        <Collapse className="customcssColl" activeKey={activeKey} ghost>
          <Collapse.Panel
            className="customcss"
            showArrow={false}
            key={"1"}
            header={<></>}
          >
            <Row justify={"space-evenly"}>
              <Col lg={5} xl={5} md={11} xs={24}>
                <div>
                  <Skeleton loading={loading}>
                    <DashboardChart
                      title={
                        splitPathInVchType === Voucher_Type.SalesVoucher
                          ? "Total Amount"
                          : "Total Amount"
                      }
                      paymentstatus2={"Overdue"}
                      amount={
                        // LoadSkeleton ? (
                        //   <AmountSkeleton />
                        // ) : (

                        Utils.getFormattedNumber(
                          Math.round(salesOrderList?.totalInvoiceAmount || 0.0),
                          0
                        )
                      }
                      paymentstatus={"Due"}
                      option={
                        <img
                          alt="₹"
                          src={currency1}
                          style={{ marginTop: "8px" }}
                        />
                      }
                    />
                  </Skeleton>
                </div>
              </Col>
              {width <= 768 ? (
                <Col md={1} style={{ flex: "none" }}></Col>
              ) : (
                <Col>
                  {/* <img
                    className="Resp-signs"
                    alt="plus"
                    src={plusImg}
                    style={{
                      marginTop: "60px",
                    }}
                  /> */}
                  {/* <PlusOutlined
                    style={{
                      marginTop: "70px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  /> */}
                </Col>
              )}

              <Col lg={5} xl={5} md={11} xs={24}>
                <div>
                  <Skeleton loading={loading}>
                    <CardDivider
                      title={"Supplied Qty"}
                      paymentstatus2={"Overdue"}
                      amount={
                        // LoadSkeleton ? (
                        //   <AmountSkeleton />
                        // ) : (
                        Utils.getFormattedNumber(
                          Math.round(salesOrderList?.suppliedQty || 0.0),
                          0
                        )
                      }
                      paymentstatus={"Due"}
                      pageName="SalesOrder"
                      option={
                        <img
                          alt="₹"
                          src={currency3}
                          style={{ marginTop: "8px" }}
                        />
                      }
                    />
                  </Skeleton>
                </div>
              </Col>
              {width <= 768 ? (
                <Col md={1} style={{ flex: "none" }}></Col>
              ) : (
                <Col>
                  <img
                    className="Resp-signs"
                    alt="plus"
                    src={plusImg}
                    style={{
                      marginTop: "60px",
                    }}
                  />
                  {/* <PlusOutlined
                    style={{
                      marginTop: "70px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  /> */}
                </Col>
              )}

              <Col lg={5} xl={5} md={12} xs={24}>
                <div>
                  <Skeleton loading={loading}>
                    <DashboardChart
                      title={"Pending Qty"}
                      paymentstatus2={"Paid"}
                      paymentstatus={"Outstanding"}
                      pageName="SalesOrder"
                      amount={
                        // LoadSkeleton ? (
                        //   <AmountSkeleton />
                        // ) : (
                        Utils.getFormattedNumber(
                          Math.round(salesOrderList?.pending || 0.0),
                          0
                        )
                      }
                      option={
                        <img
                          alt="₹"
                          style={{ marginTop: "8px" }}
                          src={currency2}
                        />
                      }
                    />
                  </Skeleton>
                </div>
              </Col>
              {width <= 768 ? (
                ""
              ) : (
                <Col>
                  <img
                    className="Resp-signs"
                    src={equalImg}
                    alt="plus"
                    style={{
                      marginTop: "60px",
                    }}
                  />
                  {/* <PlusOutlined
                    style={{
                      marginTop: "70px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  /> */}
                </Col>
              )}
              <Col lg={5} xl={5} md={12} xs={24}>
                <Skeleton loading={loading}>
                  <SalesCard
                    // title={" Total Sales (Net) "}
                    title={
                      splitPathInVchType === Voucher_Type.SalesVoucher
                        ? "Ordered Qty"
                        : "Purchase Qty"
                    }
                    paymentstatus2={"Overdue"}
                    pageName="SalesOrder"
                    amount={
                      // LoadSkeleton ? (
                      //   <AmountSkeleton />
                      // ) : (

                      Utils.getFormattedNumber(
                        Math.round(salesOrderList?.totalQty || 0.0),
                        0
                      )
                    }
                    paymentstatus={"Due"}
                    option={option}
                  />
                </Skeleton>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </div>
      <Card
        size="small"
        className="TableCard"
        style={{
          width: "100%",
          height: "100%",
          minHeight: "60vh",

          // marginLeft: "16px",
          // boxShadow: "0px 0px 10px 0px #96A1C84D",
          // marginTop: "30px",
          marginTop: "15px",
          border: "1px solid #eef2fe",
        }}
      >
        <Row>
          <Col lg={24}>
            <OrderTable
              loading={loading}
              setLoading={setLoading}
              salesOrderList={salesOrderList}
              setSalesOrderList={setSalesOrderList}
              partyId={""}
            />
          </Col>
          {/* {Utils.getFormattedNumber(salesInvoiceList?.totalInvoiceAmount)} */}
        </Row>
      </Card>
    </div>
  );
};
export default SalesOrderDashboard;
