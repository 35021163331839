import {
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Select, Spin, Table } from "antd";
import Card from "antd/es/card/Card";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { useTheme } from "../../Context/ThemeContext";
import AddCustomer from "./UsersDetailsTabs";
import { useClient } from "../../Context/ClientContext";
import { useLocation, useNavigate } from "react-router-dom";
import ProductsDetails from "./ProductsDetails";
import AddUser from "./AddUser";
import UsersService, {
  ApplicationRoleNames,
} from "../../Services/UsersService";

export interface dataType {
  id: string;
  name: {
    first: string;
    last: string;
  };
  phoneNumber: number;
  email: string;
  role: any;
  accessSettings: any;
}

const Users = () => {
  const { themeData } = useTheme();

  const { currentClient, companySettings } = useClient();
  const navigate = useNavigate();
  const [isModelOpen, setIsModelOpen] = React.useState<boolean>(false);
  const [selectRole, setSelectRole] = React.useState<ApplicationRoleNames>();
  const [userList, setUserList] = React.useState<dataType[]>();
  const [userId, setUserId] = React.useState<string | undefined>();
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    getUserList();
  }, [refresh]);

  const getUserList = async () => {
    setLoading(true);
    let res = await UsersService?.getUserList();
    if (res) {
      setUserList(
        res?.result?.map((d: dataType) => {
          return {
            id: d?.id,
            name: d?.name?.first + " " + d?.name?.last,
            phone: d?.phoneNumber,
            email: d?.email,
            role: d?.role,
          };
        })
      );
      setLoading(false);
    }
  };

  console.log("role", selectRole);

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      width: "10%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "8%",

      render: (_: any, record: any) => (
        <>
          <Row gutter={12}>
            <>
              <Col>
                <EditOutlined
                  onClick={() => {
                    navigate("/accounts/users/userDetails", {
                      state: {
                        userId: record?.id,
                      },
                    });
                  }}
                />
              </Col>
              <Col>
                <EyeOutlined />
              </Col>

              <Col>
                <DeleteOutlined style={{ color: "red" }} />
              </Col>
            </>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          padding: "0px 0px 3px 0px",
        }}
      >
        <Card
          size="small"
          className="TableCard"
          style={{
            justifyContent: "center",
            margin: "20px 20px",
            boxShadow: "0px 0px 10px 0px #96A1C84D",
            height: "90vh",
          }}
        >
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined />}
            style={{ marginTop: "100px" }}
          >
            <Row gutter={10}>
              <Col>
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => {
                    setIsModelOpen(true);
                    setUserId(undefined);
                  }}
                >
                  Add User
                </Button>
              </Col>
              <Col>
                <span style={{ marginRight: "5px" }}>Select Role :</span>
                <Select
                  style={{ width: "180px" }}
                  options={[
                    {
                      value: ApplicationRoleNames?.ADMIN,
                      label: "Admin",
                    },
                    {
                      value: ApplicationRoleNames?.AUTHORITIES,
                      label: "Authorities",
                    },
                    {
                      value: ApplicationRoleNames?.DATAENTRY,
                      label: "Data Entry",
                    },
                    {
                      value: ApplicationRoleNames?.AUDITORS,
                      label: "Auditors",
                    },
                    {
                      value: ApplicationRoleNames?.VIEWONLY,
                      label: "View Only",
                    },
                  ]}
                  onChange={(val: any) => setSelectRole(val)}
                />
              </Col>
            </Row>
          </Spin>
          <div style={{ marginTop: "20px" }}>
            <Table
              columns={columns}
              dataSource={userList}
              size="small"
              className={`Tabel-style table-${
                themeData?.componentSize ?? "middle"
              }`}
            />
          </div>
        </Card>
      </div>
      {isModelOpen && (
        <AddUser
          onCancel={() => setIsModelOpen(false)}
          isModelOpen={isModelOpen}
          id={userId}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default Users;
