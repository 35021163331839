export const AppConstants = {
  acceptFilesStr: ".doc,.docx,.xls,.xlsx,.pdf,.txt,.csv,.jpg,.png,.jpeg,.bmp",
  acceptFiles: {
    custom: [
      ".doc",
      ".docx",
      ".xls",
      ".xlsx",
      ".pdf",
      ".jpg",
      ".png",
      ".jpeg",
      ".bmp",
      ".txt",
      ".csv",
    ],
  },
};

export enum TransactionTypes {
  Unknown,
  Journal,
  Sales,
  Purchase,
  CrNote,
  DrNote,
  Receipt,
  Payment,
  BadDebts,
  FixedAsset,
  Depreciate,
  DividendAccrual,
  DividendPayment,
  Expenses,
  Contra,
  OpeningBalance,
  Transfer,
  Salary,
  FixedAssetsSellDispose,
  Quotation,
  Dividend,
  QuickEntry,
  Explanation,
}

export enum AccountGroups {
  Unknown = 0,
  Turnover = 1,
  CostOfSales = 2,
  OtherOperatingIncome = 3,
  GainLossOnRevaluationOfAssets = 4,
  InvestmentIncome = 5,
  InterestReceivableEtc = 6,
  SellingAndDistributionCosts = 7,
  AdministrativeExpenses = 8,
  ShareOfOperatingProfit = 9,
  FRS3ExceptionalItems = 10,
  AmountsWrittenOffInvestments = 11,
  InterestPayableSimilarCharges = 12,
  OtherFinanceIncomeCosts = 13,
  Taxation = 14,
  NonControllingInterests = 15,
  ExtraOdrinaryItems = 16,
  TaxationJointVenturesAssociates = 17,
  Dividends = 18,
  PriorYearAdjustment = 19,
  MembersRemunerationCharged = 20,
  CalledupShareCapitalNotPaid = 50,
  FixedAssets = 51,
  FixedAssetsLeased = 52,
  FixedAssetInvestments = 53,
  CurrentAssets = 54,
  DebtorsLessThanOneYear = 55,
  DebtorsMoreThanOneYear = 56,
  CurrentAssetInvestments = 57,
  CashAtBankInHand = 58,
  PrepaymentsAccruedIncome = 59,
  DebtorsOrCreditors = 60,
  CreditorsLessThanOneYear = 61,
  CreditorsMoreThanOneYear = 62,
  FinancialLiabilitiesBorrowings = 63,
  IFRS16Leases = 64,
  ProvisionsForLiabilities = 65,
  AccruedLiabilities = 66,
  EmployeeBenefitObligations = 67,
  CapitalReserves = 68,
  MembersInterest = 69,
  Suspense = 70,
}

export enum AccountNatures {
  Unknown = 0,
  Income = 1,
  Expenses = 2,
  Assets = 3,
  Liability = 4,
}
export enum CompanyGSTTypes {
  Unregistered = 0,
  Regular = 1,
  Composition = 2,
  Consumer = 3,
}

export enum FeatureStatus {
  placeholder = 0,
  alpha = 1,
  beta = 2,
  release = 3,
}

export enum BusinessTypes {
  PrivateLimited = 1,
  PublicLimited,
  SoleProprietorship,
  Partnership,
  LLP,
  LLC,
  JointVenture,
  HUF,
  NGO,
  GA,
}

export enum AppFeatures {
  Calls = 1,
  LeadSources = 2,
  LinkedinLeads = 3,
  WebForms = 4,
  DayEnd = 5,
  Accounts = 6,
  CorporationTax = 7,
  AML = 8,
  Chats = 9,
  Teams = 10,
  BankStatement = 11,
  Payroll = 12,
  ClientDataRequest = 13,
  Bookkeeping = 14,
  Timesheet = 15,
  Other1 = 16,
  Other2 = 17,
  Other3 = 18,
  Other4 = 19,
  Other5 = 20,
}

export enum InvoicesGetTypes {
  Receipt,
  Payment,
}

export enum PurchaseInvoiceTypes {
  PurchaseInvoice = 1,
  PurchaseDebitNote = 2,
  PurchaseInventory = 3,
  PurchaseRecurring = 4,
}

export enum IPaymentMothod {
  Cash,
  Cheque,
  CreditCard,
  DebitCard,
  DirectDebit,
  StandingOrder,
  WebsitePayment,
  PayPal,
  Amex,
  Other,
  BankTransfer,
}

export enum IndianStates {
  /// <summary>
  /// Prompt will be used for GST State Codes
  /// </summary>
  Unspecified = 0,
  // [Display(Name = "Jammu and Kashmir", ShortName = "JK", Prompt = "01")]
  JammuAndKashmir = 1,
  // [Display(Name = "Himachal Pradesh", ShortName = "HP", Prompt = "02")]
  HimachalPradesh = 2,
  // [Display(Name = "Punjab", ShortName = "PB", Prompt ="03")]
  Punjab = 3,
  // [Display(Name = "Chandigarh", ShortName = "CH", Prompt ="04")]
  Chandigarh = 4,
  // [Display(Name = "Uttarakhand", ShortName = "UK", Prompt ="05")]
  Uttarakhand = 5,
  // [Display(Name = "Haryana", ShortName = "HR", Prompt ="06")]
  Haryana = 6,
  // [Display(Name = "Delhi", ShortName = "DL", Prompt ="07")]
  Delhi = 7,
  // [Display(Name = "Rajasthan", ShortName = "RJ", Prompt ="08")]
  Rajasthan = 8,
  // [Display(Name = "Uttar Pradesh", ShortName = "UP", Prompt ="09")]
  UttarPradesh = 9,
  // [Display(Name = "Bihar", ShortName = "BR", Prompt ="10")]
  Bihar = 10,
  // [Display(Name = "Sikkim", ShortName = "SK", Prompt ="11")]
  Sikkim = 11,
  // [Display(Name = "Arunachal Pradesh", ShortName = "AR", Prompt ="12")]
  ArunachalPradesh = 12,
  // [Display(Name = "Nagaland", ShortName = "NL", Prompt ="13")]
  Nagaland = 13,
  // [Display(Name = "Manipur", ShortName = "MN", Prompt ="14")]
  Manipur = 14,
  // [Display(Name = "Mizoram", ShortName = "MZ", Prompt ="15")]
  Mizoram = 15,
  // [Display(Name = "Tripura", ShortName = "TS", Prompt ="16")]
  Tripura = 16,
  // [Display(Name = "Meghalaya", ShortName = "ML", Prompt ="17")]
  Meghalaya = 17,
  // [Display(Name = "Assam", ShortName = "AS", Prompt ="18")]
  Assam = 18,
  // [Display(Name = "West Bengal", ShortName = "WB", Prompt = "19")]
  WestBengal = 19,
  // [Display(Name = "Jharkhand", ShortName = "JH", Prompt = "20")]
  Jharkhand = 20,
  // [Display(Name = "Orissa", ShortName = "OD", Prompt = "21")]
  Orissa = 21,
  // [Display(Name = "Chhattisgarh", ShortName = "CG", Prompt = "22")]
  Chhattisgarh = 22,
  // [Display(Name = "Madhya Pradesh", ShortName = "MP", Prompt = "23")]
  MadhyaPradesh = 23,
  // [Display(Name = "Gujarat", ShortName = "GJ", Prompt = "24")]
  Gujarat = 24,
  // [Display(Name = "Dadra and Nagar Haveli and Daman and Diu", ShortName = "DN", Prompt = "26")]
  DadraAndNagarHaveliDamanAndDiu = 26,
  // [Display(Name = "Maharashtra", ShortName = "MH", Prompt = "27")]
  Maharashtra = 27,
  // [Display(Name = "Andhra Pradesh (Old)", ShortName = "APO", Prompt = "28")]
  AndhraPradeshOld = 28,
  // [Display(Name = "Karnataka", ShortName = "KA", Prompt = "29")]
  Karnataka = 29,
  // [Display(Name = "Goa", ShortName = "GA", Prompt = "30")]
  Goa = 30,
  // [Display(Name = "Lakshadweep", ShortName = "LD", Prompt = "31")]
  Lakshadweep = 31,
  // [Display(Name = "Kerala", ShortName = "KL", Prompt = "32")]
  Kerala = 32,
  // [Display(Name = "Tamil Nadu", ShortName = "TN", Prompt = "33")]
  TamilNadu = 33,
  // [Display(Name = "Puducherry", ShortName = "PY", Prompt = "34")]
  Puducherry = 34,
  // [Display(Name = "Andaman and Nicobar Islands", ShortName = "AN", Prompt = "35")]
  AndamanAndNicobarIslands = 35,
  // [Display(Name = "Telangana", ShortName = "TG", Prompt = "36")]
  Telangana = 36,
  // [Display(Name = "Andhra Pradesh (New)", ShortName = "APN", Prompt = "37")]
  AndhraPradeshNew = 37,
  // [Display(Name = "Ladakh", ShortName = "LD", Prompt = "38")]
  Ladakh = 38,
  // [Display(Name = "Other Territory", ShortName = "OT", Prompt = "97")]
  OtherTerritory = 97,
  // [Display(Name = "Centre Jurisdiction", ShortName = "CJ", Prompt = "99")]
  CentreJurisdiction = 99,
}
