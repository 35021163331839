import React from "react";
import { Route, Routes } from "react-router-dom";
import PurchaseCreditNotes from "./PurchaseCreditNotes";
import PurchaseOrder from "./PurchaseOrder";
import PurchasePayment from "./PurchasePaymentOut";
import PurchaseInvoice from "./PurchaseInvoice";
import Items from "./Items";
import ResourceNotFound from "../../Common/ResourceNotFound";
import AddPurchaseInvoice from "./AddPurchaseInvoice";
import PaymentIn from "../Sales/PaymentIn";
import SalesInvoiceTable from "../Sales/SalesInvoiceTable";
import SubSaleInvoice from "../Sales/SubSalesInvoice";
import CreditNotes from "../Sales/CreditNotes";
import OrderTable from "../Sales/OrderTable";
import SalesOrderDashboard from "../Sales/SalesOrderDashboard";
import { ItemType } from "../../Services/ItemsService";
import Customers from "../Sales/Customers";

const PurchaseNestedRoute = () => {
  return (
    <Routes>
      <Route path="/purchaseBill" element={<SubSaleInvoice />} />
      <Route path="/debitNote" element={<CreditNotes />} />
      <Route path="/paymentOut" element={<PaymentIn />} />
      <Route path="/order" element={<SalesOrderDashboard />} />
      <Route
        path="/items"
        element={<Items itemPage={ItemType.PurchaseItems} />}
      />
      <Route path="/suppliers" element={<Customers />}></Route>
      <Route path="*" element={<ResourceNotFound pageHeight="100%" />} />
    </Routes>
  );
};

export default PurchaseNestedRoute;
