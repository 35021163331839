import React, { useContext, useState } from "react";
import ButtonComponent from "../../Common/ButtonComponent";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Tooltip,
  Popover,
  Divider,
  Badge,
  Skeleton,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  DownloadOutlined,
  SyncOutlined,
  EditOutlined,
} from "@ant-design/icons";
import PurchaseService from "../../Services/PurchaseService";
import noteContext from "../../Context/StyleContext";
import ViewPurchaseInvoice from "./ViewPurchaseInvoice";
import { Utils } from "../../../Utilities/Utils";
import { dayjs } from "../../../Utilities/dayjs";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { InvoiceStatus } from "../../Services/SalesService";
import { BsCurrencyRupee } from "react-icons/bs";
import { FullPageSpinner } from "../../../Common/FullPageSpinner";
import { Link, useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";
import { PurchaseInvoiceTypes } from "../../../Types/CommonConstants";
import { InvoiceDataType, InvoiceStatusShow } from "../Sales/SalesInvoiceTable";
import ViewPurchaseInvoiceNoDetails from "./ViewPurchaseInvoiceNoDetails";
import { MdOutlineDrafts } from "react-icons/md";

interface IPurchaseInvoiceProp {
  purchaseInvoiceList: any;
  setPurchaseInvoiceList: (value: any) => void;
  partyId?: string;
}

const PurchaseInvoiceTable = (props: IPurchaseInvoiceProp) => {
  console.log(props, "this is props");
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setIsOpen] = React.useState<string>("");
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [openPopArray, setOpenPopArray] = useState<boolean[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [searchSupplier, setSearchSupplier] = React.useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [filterDate, setFilterDate] = React.useState<any>({ from: "", to: "" });
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [invoiceStatus, setInvoiceStatus] = React.useState<number>(
    InvoiceStatus.Active
  );
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<any>({
    id: "",
    isRev: false,
  });
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  React.useEffect(() => {
    // console.log(width);
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // --------- Table Columns  ---------
  const columns: ColumnsType<InvoiceDataType> = [
    {
      title: props?.partyId ? "Inv No." : "Invoice No.",
      dataIndex: "invNumber",
      width: width <= 1440 ? "10%" : "9%",
      key: "number",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#395cd2", cursor: "pointer" }}
            onClick={() => {
              setIsOpen("viewPurchaseInvoice");
              setInvoiceId(record?.id);
            }}
          >
            {record?.invNumber?.name}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      // width: "8%",
      ellipsis: true,
      width: "100px",
      key: "date",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      ellipsis: true,
      key: "dueDate",
      width: width <= 1440 ? "9%" : "8%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {dayjs(record?.dueDate).format("DD/MM/YYYY")}
        </Skeleton>
      ),
    },
    {
      title: "Invoice Amt.",
      dataIndex: "invAmount",
      sorter: true,
      key: "TotalInvoiceAmount",
      width: props?.partyId ? "12%" : "9%",
      align: "right",
      ellipsis: {
        showTitle: false,
      },
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹{Utils.getFormattedNumber(record?.invAmount)}
        </Skeleton>
      ),
    },
    {
      title: "Refund",
      dataIndex: "refundAmount",
      key: "refundAmount",
      width: props?.partyId ? "12%" : "9%",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹{Utils.getFormattedNumber(record?.refundAmount)}
        </Skeleton>
      ),
    },
    {
      title: "Received",
      dataIndex: "receivedAmount",
      sorter: true,
      key: "Amount.Paid",
      ellipsis: {
        showTitle: false,
      },
      width: props?.partyId ? "12%" : "9%",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <Tooltip
            placement="topLeft"
            title={
              record?.bank === 0 ? (
                <span>
                  ₹{Utils.getFormattedNumber(record?.paid - record?.bank)}
                </span>
              ) : (
                <>
                  <span>paid: ₹{Utils.getFormattedNumber(record?.paid)}</span>
                  <br />
                  <span>bank: ₹{Utils.getFormattedNumber(record?.bank)}</span>
                </>
              )
            }
          >
            <span onClick={() => console.log(record?.bank)}>
              ₹{Utils.getFormattedNumber(record?.paid - record?.bank)}
            </span>
          </Tooltip>
        </Skeleton>
      ),
    },
    {
      title: "Due Amount",
      dataIndex: "dueBalance",
      key: "DueBalance",
      width: props?.partyId ? "12%" : "9%",
      align: "right",
      ellipsis: {
        showTitle: false,
      },
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <Tooltip
            placement="topLeft"
            title={<span>₹{Utils.getFormattedNumber(record?.dueBalance)}</span>}
          >
            <span>₹{Utils.getFormattedNumber(record?.dueBalance)}</span>
          </Tooltip>
        </Skeleton>
      ),
    },
    {
      title: "Status",
      dataIndex: "payStatus",
      key: "payStatus",
      width: "7%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          <InvoiceStatusShow
            dueDate={record?.dueDate}
            due={record?.dueBalance}
            refund={record?.refundAmount}
            invAmount={record?.invAmount}
          />
        </Skeleton>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Supplier Name",
      dataIndex: "supplierName",
      width: width <= 1440 ? "18%" : "25%",
      render: (x: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Link
            to={`/accounts/clients/${currentClient?.id!}/parties/${x?.id}`}
            style={{ color: "#3a5dd3" }}
          >
            {x?.name}
          </Link>
        </Skeleton>
      ),
    });
  }

  if (invoiceStatus === InvoiceStatus.Deleted) {
    columns.push({
      title: " ",
      dataIndex: "icon",
      width: "10%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          <Tooltip title="Revive invoice and its effects on calculations">
            <SyncOutlined
              onClick={() => {
                setConfirmation(true);
                setInvoiceId(record?.invNumber?.id);
              }}
            />
          </Tooltip>
        </Skeleton>
      ),
    });
  } else {
    columns.push(
      {
        title: " ",
        width: "3%",
        align: "center",
        render: (_, record) => (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
            className="cursor"
          >
            <FiDownload style={{ color: "#395cd2" }} />
          </Skeleton>
        ),
      },
      {
        title: " ",
        width: "3%",
        align: "center",
        render: (_, record) => (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
            className="cursor"
          >
            <Tooltip
              title={
                record?.receivedAmount === 0 && record?.refundAmount === 0
                  ? "Edit"
                  : "Invoice is either paid or refunded"
              }
              placement="top"
              color={
                record?.receivedAmount === 0 && record?.refundAmount === 0
                  ? "#3D4998"
                  : "gray"
              }
            >
              <EditOutlined
                style={{
                  color:
                    record?.receivedAmount === 0 && record?.refundAmount === 0
                      ? "#444653"
                      : "rgb(216 207 207)",
                }}
                className={
                  record?.receivedAmount === 0 && record?.refundAmount === 0
                    ? "ca-edit-btn"
                    : ""
                }
                onClick={() => {
                  if (
                    record?.receivedAmount === 0 &&
                    record?.refundAmount === 0
                  ) {
                    navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/editPurchaseBill/${record?.invNumber?.id}`,
                      {
                        state: { copy: false },
                      }
                    );
                  }
                }}
              />
            </Tooltip>
          </Skeleton>
        ),
      },
      {
        title: " ",
        width: "2%",
        align: "center",
        render: (_, record) => (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
            className="cursor"
          >
            <div
              className={record?.dueBalance === 0 ? "" : "cursor"}
              style={{
                // backgroundColor:
                //   record?.dueBalance === 0 ? "rgba(239, 239, 239, 0.6)" : "",
                textAlign: "center",
              }}
            >
              <Tooltip
                title={
                  record?.dueBalance === 0
                    ? "Invoice is either paid or refunded"
                    : "Create Receipt"
                }
                placement="top"
                color={record?.dueBalance === 0 ? "gray" : ""}
              >
                <BsCurrencyRupee
                  color={
                    record?.dueBalance === 0 ? "rgb(216 207 207)" : "#868ea5"
                  }
                  onClick={() => {
                    if (record?.dueBalance !== 0) {
                      navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/addPaymentOut`,
                        {
                          state: {
                            invoiceId: record?.invNumber?.id,
                            values: {
                              value: record?.dueBalance,
                            },
                          },
                        }
                      );
                    }
                  }}
                />
              </Tooltip>
            </div>
          </Skeleton>
        ),
      },
      {
        title: " ",
        width: "2%",
        align: "center",
        render: (_, record, index) => (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
            className="cursor"
          >
            <Popover
              arrow={{ pointAtCenter: true }}
              placement="bottomRight"
              content={ellipsisContent(record, index)}
              trigger="click"
              open={openPopArray[index]}
              onOpenChange={(newOpen) => handlePopoverChange(index, newOpen)}
            >
              <HiDotsHorizontal
                style={{ color: "#395cd2", cursor: "pointer" }}
              />
            </Popover>
          </Skeleton>
        ),
      }
    );
  }

  const handlePopoverChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  const ellipsisContent = (record: any, index: number) => {
    return (
      <div>
        <Button
          style={{ width: 100 }}
          onClick={() => {
            navigate(
              `/accounts/clients/${currentClient?.id}/purchase/editPurchaseBill/${record?.id}`,
              {
                state: { copy: true },
              }
            );
          }}
        >
          Clone
        </Button>
        <Divider className="my-5" />
        <Tooltip
          title={
            record?.receivedAmount?.paid > 0 || record?.refundAmount > 0
              ? "This record can't be deleted because it has an associated transaction."
              : "Delete temporarily"
          }
        >
          <Button
            disabled={
              record?.receivedAmount?.paid > 0 || record?.refundAmount > 0
                ? true
                : false
            }
            style={{ width: 100 }}
            onClick={() => {
              setConfirmation(true);
              setInvoiceId(record?.id);
              handlePopoverChange(index, false);
            }}
          >
            Delete
          </Button>
        </Tooltip>
        <Divider className="my-5" />
        <Tooltip
          title={
            record?.totalInvoiceAmount === record?.refundAmount
              ? "This record can't be create debit note because it invoice all refund"
              : ""
          }
        >
          <Button
            style={{ width: 100 }}
            disabled={
              record?.totalInvoiceAmount === record?.refundAmount ? true : false
            }
            onClick={() => {
              navigate(
                `/accounts/clients/${currentClient?.id}/purchase/addDebitNote`,
                {
                  state: {
                    invoice: record?.invNumber,
                    partyId: record?.supplierName?.id,
                  },
                }
              );
            }}
          >
            Debit Note
          </Button>
        </Tooltip>
        <Divider className="my-5" />
        <Button
          style={{ width: 100 }}
          onClick={() => {
            setIsOpen("viewPurchaseInvoiceNoAllDetails");
            setInvoiceId(record.id);
            handlePopoverChange(index, false);
          }}
        >
          All Details
        </Button>
      </div>
    );
  };

  // ----- Delete or revive invoices -----
  React.useEffect(() => {
    if (deleteOrRevive.id) {
      const DeleteOrRevive = async () => {
        setLoading(true);
        await PurchaseService.deletePurchaseInvoice(
          currentClient?.id!,
          deleteOrRevive.id,
          deleteOrRevive.isRev,
          false
        ).then((res) => {
          setLoading(false);
          if (res?.result) {
            setRefreshAtDelete(!refreshAtDelete);
            openNotification(
              "success",
              `Entry ${
                invoiceStatus === InvoiceStatus.Deleted ? `revived` : `deleted`
              } successfully `
            );
          }
        });
      };
      DeleteOrRevive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrRevive]);

  // --------- Get TableData API ---------
  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getInvoiceList(
        currentClient?.id,
        invoiceStatus,
        PurchaseInvoiceTypes.PurchaseInvoice,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchSupplier,
        filterDate.from,
        filterDate.to,
        props?.partyId
      );
    } else if (currentClient?.id === null) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    searchSupplier,
    filterDate.from,
    filterDate.to,
    invoiceStatus,
    refreshAtDelete,
    props?.partyId,
  ]);
  const getInvoiceList = async (
    clientId: string,
    status: InvoiceStatus,
    type: PurchaseInvoiceTypes,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    from?: any,
    to?: any,
    partyId?: string
  ) => {
    await PurchaseService.getPurchaseInvoiceList(
      clientId,
      status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId
    )
      .then((res: any) => {
        if (res.items.length > 0) {
          props.setPurchaseInvoiceList({
            invoiceListRes: res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                id: v?.id,
                name: v?.tPartner.name,
                invNumber: { name: v?.number, id: v?.id },
                date: v?.date,
                dueDate: v?.dueDate,
                dueBalance: v?.amount?.due,
                invAmount: v?.amount?.netPayable,
                receivedAmount: {
                  paid: v?.amount?.paid,
                  bank: v?.amount?.bankAmount,
                },
                refundAmount: v?.amount?.refund,
                supplierName: { id: v?.tPartner.id, name: v?.tPartner.name },
              };
            }),
            totalReceived: res?.total?.recieved,
            totalUnpaid: res?.total?.unpaid,
            totalOverdue: res?.total?.overdue,
            totalPurchaseAmount: res?.total?.totalSalesAmount,
            totalRefund: res?.total?.refund,
            //total According to per page---
            totalInvoiceAmount: res?.total?.pageItemTotal?.tInvoiceAmt,
            totalRefundAmount: res?.total?.pageItemTotal?.tRefund,
            totalReceivedAmount: res?.total?.pageItemTotal?.tReceived,
            totalDueAmount: res?.total?.pageItemTotal?.tDueAmt,
          });
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          props.setPurchaseInvoiceList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
        console.log("subtotaldata-------", res?.total?.pageItemTotal);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        props.setPurchaseInvoiceList([]);
      });
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };
  const isDraft = (clientId: string) => {
    const res = JSON.parse(sessionStorage.getItem(`${clientId} Purchase`) + "");
    if (res) return true;
    else return false;
  };
  return (
    <>
      <Row
        justify={width <= 768 ? "space-evenly" : "space-between"}
        // style={{ marginTop: 10, padding: "10px 18px 3px 18px" }}
        gutter={windowWidth <= 1024 ? 65 : 0}
      >
        <Col xl={6} lg={4}>
          <Space size={5}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addPurchaseBill/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addPurchaseBill`
                    );
              }}
            >
              Purchase Bill
            </Button>
            <Button type="default" icon={<DownloadOutlined />}>
              Export
            </Button>
            {/* <Badge
              dot={isDraft(`${currentClient?.id}`) ?? false}
              style={{ marginTop: "6px" }}
            >
              <MdOutlineDrafts
                size={22}
                className="cursor"
                style={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  color: isDraft(`${currentClient?.id}`)
                    ? "#5d616c"
                    : "lightgray",
                }}
                onClick={() => {
                  if (isDraft(`${currentClient?.id}`)) {
                    navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addPurchaseBill`,
                      {
                        state: {
                          isOpenDraft2: true,
                        },
                      }
                    );
                  }
                }}
              />
            </Badge> */}
            {/* <p
              style={{
                color: isDraft(`${currentClient?.id}`) ? "" : "lightgray",
              }}
            >
              {isDraft(`${currentClient?.id}`) ? `New Draft` : `Drafts`}
            </p> */}
          </Space>
        </Col>
        <Col
          xl={18}
          lg={20}
          style={{ marginTop: width <= 768 ? "6px" : "0px" }}
        >
          <Row justify="end" gutter={[8, width <= 425 ? 10 : 0]}>
            <Col>
              <Input
                style={{
                  border: "1px solid #f1f3ff",
                  width:
                    width === 425 ? "330px" : width === 375 ? "280px" : "200px",
                }}
                placeholder="Search Supplier"
                allowClear
                onChange={(e: any) => {
                  setLoading(true);
                  if (!e.cancelable) {
                    const searchItem = setTimeout(() => {
                      setPage(1);
                      setSearchSupplier(e.target.value);
                    }, 500);
                    return () => clearTimeout(searchItem);
                  } else {
                    setSearchSupplier(e.target.value);
                  }
                }}
                suffix={
                  searchSupplier ? "" : <img alt="Search.." src={search} />
                }
              />
            </Col>
            <Col>
              <RangePicker
                format="DD/MM/YYYY"
                style={{
                  width:
                    width == 425 ? "330px" : width == 375 ? "280px" : "220px",
                  border: "1px solid #f1f3ff",
                }}
                onChange={(e: any) => {
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                value={invoiceStatus}
                style={{ width: width === 1024 ? 80 : 120 }}
                options={[
                  { value: InvoiceStatus.Active, label: "Active" },
                  { value: InvoiceStatus.Due, label: "Due" },
                  { value: InvoiceStatus.Overdue, label: "Overdue" },
                  { value: InvoiceStatus.Paid, label: "Paid" },
                  {
                    value: InvoiceStatus.PurchaseOrder,
                    label: "Purchase Order",
                  },
                  { value: InvoiceStatus.Deleted, label: "Deleted" },
                ]}
                onSelect={(val) => {
                  setPage(1);
                  setInvoiceStatus(val);
                }}
              />
            </Col>
            <Col>
              <ButtonComponent />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        scroll={
          pageSize > 10
            ? { x: width <= 1024 ? 1500 : 700, y: 370 }
            : { x: width <= 1024 ? 1500 : 600 }
        }
        // footer={() => (
        //   <Row>
        //     <Col style={{ width: "47%" }} className="textEnd">
        //       Total
        //     </Col>
        //     <Col style={{ width: "11%" }} className="textEnd pe-10">
        //       ₹{" "}
        //       {Utils.getFormattedNumber(
        //         props.purchaseInvoiceList?.totalInvoiceAmount
        //       )}
        //     </Col>
        //     <Col style={{ width: "8%" }} className="textEnd">
        //       ₹{" "}
        //       {Utils.getFormattedNumber(
        //         props.purchaseInvoiceList?.totalRefundAmount
        //       )}
        //     </Col>
        //     <Col style={{ width: "9%" }} className="textEnd pe-3">
        //       ₹{" "}
        //       {Utils.getFormattedNumber(
        //         props.purchaseInvoiceList?.totalReceivedAmount
        //       )}
        //     </Col>
        //     <Col style={{ width: "9%" }} className="textEnd pe-6">
        //       ₹{" "}
        //       {Utils.getFormattedNumber(
        //         props.purchaseInvoiceList?.totalDueAmount
        //       )}
        //     </Col>
        //   </Row>
        // )}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row style={{ backgroundColor: "white" }}>
                <Table.Summary.Cell
                  index={1}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={2} className="fw600">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={3}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={4} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="fw600">
                  {/* ₹{" "}
                      {tableData?.data?.reduce((acc: any, curr: any) => {
                        return (acc += Number(curr?.amount));
                      }, 0) || 0} */}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="fw600" align="right">
                  ₹
                  {Utils.getFormattedNumber(
                    props.purchaseInvoiceList?.totalInvoiceAmount
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="fw600" align="right">
                  ₹
                  {Utils.getFormattedNumber(
                    props.purchaseInvoiceList?.totalRefundAmount
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  {"   "}
                  {Utils.getFormattedNumber(
                    props.purchaseInvoiceList?.totalReceivedAmount
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} className="fw600" align="right">
                  {Utils.getFormattedNumber(
                    props.purchaseInvoiceList?.totalDueAmount
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={13} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as InvoiceDataType[])
            : props.purchaseInvoiceList.invoiceListRes
        }
        onChange={tableSort}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />

      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeleteOrRevive({
              id: invoiceId,
              isRev: invoiceStatus === InvoiceStatus.Deleted ? true : false,
            });
            setConfirmation(false);
          }}
          text={
            invoiceStatus === InvoiceStatus.Deleted
              ? `Are you sure you want to revive ?`
              : `Are you sure you want to delete this row ?`
          }
        />
      )}
      {open === "viewPurchaseInvoice" && (
        <ViewPurchaseInvoice
          open={true}
          onCancel={() => setIsOpen("")}
          invoiceId={invoiceId}
        />
      )}
      {open === "viewPurchaseInvoiceNoAllDetails" && (
        <ViewPurchaseInvoiceNoDetails
          open={true}
          onCancel={() => setIsOpen("")}
          invoiceId={invoiceId}
        />
      )}
    </>
  );
};
export default PurchaseInvoiceTable;
