import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum BankAdjustType {
  BankToBank = 2,
  BankToCash = 3,
  CashToBank = 4,
  IncreaseBalance = 5,
  ReduceBalance = 6,
}
export enum BankStatus {
  All,
  Active,
  Delete,
}

export enum ChequeBookStatus {
  All,
  YetToBeUsed,
  Using,
  Used,
  Archived,
}
class CashAndBankAccountServices {
  private route = "V1/Api";

  // Bank Accounts
  //   https://localhost:7142/V1/Api/Acc_BankAccount/6463611020350584c0d5c017
  postBankAccount = (param: any, clientID: string, id?: string) =>
    ApiUtility.postForm(
      `${this.route}/Acc_BankAccount/${clientID}${id ? "/" + id : ""}`,
      param
    );

  getBankAccount = (clientID: string, id: string) =>
    ApiUtility.get(`${this.route}/Acc_BankAccount/${clientID}/${id}`);

  // https: //localhost:7142/V1/Api/Acc_BankAccount/6463611020350584c0d5c017/653b6d471975cc7168c1674e

  // https://localhost:7142/V1/Api/Acc_BankAccount/6463611020350584c0d5c017?start=0&length=15&status=0

  getBankAccountList = (
    clientId: string,
    start: number,
    length: number,
    status: BankStatus,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    type?: number[],
    allBank?: boolean
  ) =>
    ApiUtility.getResult(`${this.route}/Acc_BankAccount/${clientId}`, {
      start,
      length,
      status,
      sortCol,
      sortDir,
      search,
      type,
      allBank,
    });

  // Changes in all api & all effected
  postBankAccountNew = (param: any, clientID: string, id?: string) =>
    ApiUtility.postForm(
      `${this.route}/Acc_Banking/${clientID}${id ? "/" + id : ""}`,
      param
    );

  getBankAccountListNew = (
    clientId: string,
    start: number,
    length: number,
    search?: any,
    sortCol?: any,
    sortDir?: any
  ) =>
    ApiUtility.getResult(`${this.route}/Acc_Banking/${clientId}`, {
      start,
      length,
      search,
      sortCol,
      sortDir,
    });

  // Bank Adjust

  // https://localhost:7142/V1/API/Acc_BankAdjust/6463611020350584c0d5c017
  postBankAdjust = (param: any, clientID: string, id?: string) =>
    ApiUtility.postForm(
      `${this.route}/Acc_BankAdjust/${clientID}${id ? "/" + id : ""}`,
      param
    );

  // https://localhost:7142/V1/API/Acc_BankAdjust/6463611020350584c0d5c017/652692e18938a4df3d78e474
  getBankAdjust = (clientID: string, id: string) =>
    ApiUtility.get(`${this.route}/Acc_BankAdjust/${clientID}/${id}`);

  // https: //localhost:7142/V1/Api/Acc_BankAccount/6463611020350584c0d5c017/653b6d471975cc7168c1674e/TransactionsList?start=0&length=15

  getBankTransactionsList = (
    clientId: string,
    bankId: string,
    start: number,
    length: number,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    type?: number[],
    search?: string
  ) =>
    ApiUtility.get(
      `${this.route}/Acc_BankAccount/${clientId}/${bankId}/BankTransactionsList`,
      { start, length, from, to, sortCol, sortDir, type, search }
    );

  // Cash In Hand Api

  // https://localhost:7142/V1/Api/Acc_BankAccounts/6463611020350584c0d5c017
  postCashInAdjust = (clientId: string, param: any, id?: string) =>
    ApiUtility.post(
      `${this.route}/Acc_Cash/${clientId}${id ? "/" + id : ""}`,
      param
    );

  // https://localhost:7142/V1/Api/Acc_Cash/6463611020350584c0d5c017/CashInHandTransactionsList?start=0&length=15

  getCashInHandTransactionsList = (
    clientId: string,
    start: number,
    length: number,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    type?: number[]
  ) =>
    ApiUtility.get(
      `${this.route}/Acc_Cash/${clientId}/CashInHandTransactionsList`,
      { start, length, from, to, sortCol, sortDir, type, search }
    );

  addChequeBook = (
    companyId: string | undefined,
    ledgerId: string,
    data: any,
    chequeBookId?: string | null | undefined
  ) => {
    // Base URL
    let url = `${this.route}/Acc_ChequeBook/${companyId}/Ledger/${ledgerId}`;
    if (chequeBookId) {
      url += `/${chequeBookId}`;
    }
    return ApiUtility.post(url, data);
  };

  getChequeBooks = (
    companyId: string | undefined,
    ledgerId: string,
    data: any
  ) =>
    ApiUtility.get(
      `${this.route}/Acc_ChequeBook/${companyId}/Ledger/${ledgerId}/GetList`,
      data
    );

  makeArchive = (companyId: string | undefined, ledgerId: string, data: any) =>
    ApiUtility.get(
      `${this.route}/Acc_ChequeBook/${companyId}/Ledger/${ledgerId}/MakeArchive`,
      data
    );

  getChequeBook = (companyId: string | undefined, id: string) =>
    ApiUtility.get(`${this.route}/Acc_ChequeBook/${companyId}/${id}`);
}

export default new CashAndBankAccountServices();
