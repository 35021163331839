import {
  DownCircleFilled,
  DownloadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
} from "antd";
import React, { useContext } from "react";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import ReportsServiceNew from "../../Services/ReportsServiceNew";
import { notificationContext } from "../../Common/PageRoute";
import Table, { ColumnsType } from "antd/es/table";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";
import {
  getBusinessTypeName,
  getIndianStates,
} from "../../Services/ClientService";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import Item from "antd/es/list/Item";
import { useLocation } from "react-router-dom";
import { GSTTypeOptions } from "../Clients/Clients";

const { RangePicker } = DatePicker;

const PartyOutstanding = ({ Type }: any) => {
  const { currentClient, companySettings } = useClient();
  const [data, setData] = React?.useState<any[]>([]);
  const location = useLocation();
  const [searchLedger, setSearchLedger] = React?.useState<any>();
  const [groupData, setGroupData] = React.useState<any[]>([]);
  const [groupId, setGroupId] = React?.useState<any>();
  const [loading, setLoading] = React?.useState<any>(false);
  const { openNotification } = useContext(notificationContext);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [exportType, setExportType] = React.useState<number>(-1);
  const [ledgers, setLedgers] = React?.useState<any>();
  const [exportOutStandingData, setExportOutStandingData] = React.useState<
    any[]
  >([]);

  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );

  const [selectedColumns, setSelectedColumns] = React?.useState([
    "ledgerName",
    "businessType",
    "state",
    "gstin",
    "pan",
    "phone",
    "email",
    "dueAmount",
    "overDueAmount",
    "gstType",
  ]);

  const allColumns = [
    { title: "Ledger Name", dataIndex: "ledgerName", key: "ledgerName" },
    { title: "Business Type", dataIndex: "businessType", key: "businessType" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "GSTIN", dataIndex: "gstin", key: "gstin" },
    { title: "PAN", dataIndex: "pan", key: "pan" },
    { title: "Mobile No", dataIndex: "phone", key: "phone" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Due Amount", dataIndex: "dueAmount", key: "dueAmount" },
    {
      title: "Overdue Amount",
      dataIndex: "overDueAmount",
      key: "overDueAmount",
    },
    { title: "GST Type", dataIndex: "gstType", key: "gstType" },
    { title: "Balance", dataIndex: "balance", key: "balance" },
    { title: "Credit Period", dataIndex: "creditPeriod", key: "creditPeriod" },
    { title: "Credit Limit", dataIndex: "creditLimit", key: "creditLimit" },
    { title: "CC To", dataIndex: "ccTo", key: "ccTo" },
    { title: "Advance", dataIndex: "advance", key: "advance" },
  ];

  React?.useEffect(() => {
    getPartOutStandingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    filterDate?.from,
    filterDate?.to,
    searchLedger?.value,
    Type,
    exportType,
  ]);

  const getPartOutStandingData = async () => {
    if (exportType < 1) {
      setLoading(true);
    }
    let res = await ReportsServiceNew?.getPartyOutstandingReports(
      currentClient?.id,
      dayjs(filterDate?.from, "DD/MM/YYYY").toISOString(),
      dayjs(filterDate?.to, "DD/MM/YYYY").toISOString(),
      searchLedger?.value ? searchLedger?.value : "",
      Type
    );
    if (res) {
      setLoading(false);
      let partyData = res?.result?.map((d: any, index: number) => {
        return {
          ledgerName: d?.ledgerName,
          pan: d?.pan,
          state: getIndianStates(d?.state),
          gstin: d?.gstin,
          businessType: getBusinessTypeName(d?.businessType),
          gstType: GSTTypeOptions[d?.gstType].label,
          creditPeriod: d?.creditPeriod,
          dueAmount: d?.dueAmount,
          overDueAmount: d?.overDueAmount,
          balance: d?.balance,
          creditLimit: d?.creditLimit,
          ccTo: d?.contactInformation[0]?.ccTo,
          email: d?.contactInformation[0]?.email,
          phone: d?.contactInformation[0]?.phone,
        };
      });
      if (exportType >= 1) {
        setExportOutStandingData(partyData);
        if (exportType === 1) {
          exportCsv(partyData);
        } else if (exportType === 2) {
          exportPDF(partyData);
        } else if (exportType === 3) {
          exportExcel(partyData);
        }
      } else {
        setData(partyData);
        setLoading(false);
      }
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Ledger Name",
      dataIndex: "ledgerName",
    },
    {
      title: "Business Type",
      dataIndex: "businessType",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
    },
    {
      title: "PAN",
      dataIndex: "pan",
    },
    {
      title: "Mobile No.",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "CC To",
      dataIndex: "ccTo",
    },
    {
      title: "Due Amount",
      dataIndex: "dueAmount",
    },
    {
      title: "Overdue Amount",
      dataIndex: "overDueAmount",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
    {
      title: "Credit Limit",
      dataIndex: "creditLimit",
    },
    {
      title: "Credit Period",
      dataIndex: "creditPeriod",
    },
    {
      title: "GST type",
      dataIndex: "gstType",
    },
    {
      title: "Advance",
      dataIndex: "advance",
    },
  ];

  const handleCheckboxChange = (value: any) => {
    setSelectedColumns((prev) =>
      prev.includes(value)
        ? prev.filter((col) => col !== value)
        : [...prev, value]
    );
  };

  const columnOptions = allColumns?.map((col) => ({
    label: col.title,
    value: col.key,
  }));

  const content = (
    <Space direction="vertical">
      {columnOptions?.map((option) => (
        <Checkbox
          key={option.value}
          checked={selectedColumns.includes(option.value)}
          onChange={() => handleCheckboxChange(option.value)}
        >
          {option.label}
        </Checkbox>
      ))}
    </Space>
  );

  React?.useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes?.PartiesOnly
    ).then((res: any) => {
      setLedgers(res);
    });
  }, [currentClient?.id]);

  React.useEffect(() => {
    // setLoading(true);
    const getListData = async () => {
      await ChartofAccountServicesNew.getGroupList(currentClient?.id!)
        .then((res: any) => {
          if (res.items) {
            setGroupData(res.items);
          } else {
            setGroupData([]);
            console.log("Error", res);
          }
        })
        .catch((err: any) => {
          console.log("Error", err);
        });
      //   setLoading(false);
    };
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      // if (col === "Amount") {
      //   return `${Utils.getFormattedNumber(totAmount)}`;
      // } else if (col === "Account Name") {
      //   return "Total";
      // } else {
      //   return ""; // Empty string for other columns
      // }
    });
  };

  const getSelectedColumnTitles = () => {
    return allColumns
      .filter((column) => selectedColumns.includes(column.dataIndex))
      .map((column) => column.title);
  };

  const selectedColumnTitles = getSelectedColumnTitles();
  let path =
    location?.pathname ===
    `/accounts/clients/${currentClient?.id}/reports/partyOutstanding/supplier`
      ? "Supplier Outstanding"
      : "Customer Outstanding";

  // const columnsA = [
  //   "Ledger Name",
  //   "Business Type",
  //   "State",
  //   "GSTIN",
  //   "PAN",
  //   "Mobile No",
  //   "Email",
  //   "CC To",
  //   "Due Amount",
  //   "Overdue Amount",
  //   "Balance",
  //   "GST Type",
  //   "Credit Limit",
  //   "Credit Period",
  //   "Advance",
  // ];

  const fieldMapping = {
    "Ledger Name": (item: any) => item?.ledgerName,
    "Business Type": (item: any) => item?.businessType,
    State: (item: any) => item?.state,
    GSTIN: (item: any) => item?.gstin,
    PAN: (item: any) => item?.pan,
    "Mobile No": (item: any) => item?.phone,
    Email: (item: any) => item?.email,
    "Due Amount": (item: any) => item?.dueAmount,
    "Overdue Amount": (item: any) => item?.overDueAmount,
    "GST Type": (item: any) => item?.gstType,
    "CC To": (item: any) => item?.ccTo,
    Balance: (item: any) => item?.balance,
    "Credit Limit": (item: any) => item?.creditLimit,
    "Credit Period": (item: any) => item?.creditPeriod,
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      selectedColumnTitles,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      selectedColumnTitles,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      selectedColumnTitles,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportContent = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportOutStandingData.length > 0) {
              exportCsv(exportOutStandingData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportOutStandingData.length > 0) {
              exportPDF(exportOutStandingData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSL"
          onClick={() => {
            if (exportOutStandingData.length > 0) {
              exportExcel(exportOutStandingData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSL
        </Button>
      </div>
    </Space>
  );

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };
  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  return (
    <div>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{
          justifyContent: "center",
          marginTop: "180px",
          textAlign: "center",
        }}
      >
        <Card
          // className="ca-card-body"
          style={{
            boxShadow: "0px 0px 10px 0px #96A1C84D",
            height: "90vh",
            padding: "0px",
          }}
        >
          <div>
            <Row>
              <Col>
                {Type === 0 ? (
                  <>
                    <h1
                      style={{
                        color: "rgb(22, 119, 255)",
                        margin: "0px",
                        fontSize: "22px",
                        fontWeight: "500",
                      }}
                    >
                      Customer Outstanding
                    </h1>
                  </>
                ) : (
                  <>
                    <h1
                      style={{
                        color: "rgb(22, 119, 255)",
                        margin: "0px",
                        fontSize: "26px",
                        fontWeight: "500",
                      }}
                    >
                      Supplier Outstanding
                    </h1>
                  </>
                )}
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: "7px" }}>
              <Col>
                <Row>
                  <Col style={{ marginRight: "10px" }}>
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      style={{ marginBottom: "3px", width: "200px" }}
                      placeholder="Select Ledger"
                      labelInValue
                      onChange={(val: any) => {
                        setSearchLedger(val);
                        setExportOutStandingData([]);
                        setExportType(-1);
                      }}
                      options={ledgers?.map((l: any) => ({
                        key: l?.key,
                        value: l?.id,
                        label: l?.ledgerName,
                      }))}
                    />{" "}
                  </Col>
                  {/* <Col>
                  <Select
                    style={{ width: "200px" }}
                    showSearch
                    onChange={(val: any) => setGroupId(val)}
                    placeholder="Select Group"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={groupData?.map((value: any, index: number) => ({
                      key: index + value?.id,
                      value: value?.id,
                      label: value?.group_Name,
                    }))}
                  />{" "}
                </Col> */}
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <span>Colums </span>
                    <Popover content={content} trigger="click">
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                          paddingTop: "1px",
                          marginTop: "3px",
                        }}
                      />
                    </Popover>
                  </Col>
                  <Col>
                    <span style={{ margin: "0px 10px" }}>Date</span>
                    <RangePicker
                      defaultValue={[
                        dayjs(filterDate.from, "DD/MM/YYYY"),
                        dayjs(),
                        // dayjs(filterDate.to, "DD/MM/YYYY"),
                      ]}
                      allowClear
                      format="DD/MM/YYYY"
                      style={{
                        width:
                          width <= 464
                            ? "210px"
                            : width == 375
                            ? "200px"
                            : "250px",
                        border: "1px solid #f1f3ff",
                      }}
                      onChange={(e: any) => {
                        if (e === null) {
                          setFilterDate({});
                        } else {
                          setFilterDate({
                            ...filterDate,
                            from: dayjs(e[0]).format("DD/MM/YYYY"),
                            to: dayjs(e[1]).format("DD/MM/YYYY"),
                          });
                          localStorage.setItem(
                            "LockPeriod",
                            JSON.stringify({
                              ...filterDate,
                              from: dayjs(e[0]).format("DD/MM/YYYY"),
                              to: dayjs(e[1]).format("DD/MM/YYYY"),
                            })
                          );
                        }
                        openNotification("success", `Period locked.`);
                        //console.log(e, "dateCheck");
                        setExportOutStandingData([]);
                        setExportType(-1);
                      }}
                      presets={[
                        {
                          label: "Today",
                          value: [dayjs().add(0, "d"), dayjs()],
                        },
                        {
                          label: "Last 7 Days",
                          value: getLast7Days(),
                        },
                        {
                          label: "Last 15 Days",
                          value: getLast15Days(),
                        },
                        {
                          label: "This Month",
                          value: getThisMonth(),
                        },
                        {
                          label: "Last Month",
                          value: getLastMonth(),
                        },
                        {
                          label: "Quarter 1",
                          value: getQuarterPreset(0),
                        },
                        {
                          label: "Quarter 2",
                          value: getQuarterPreset(3),
                        },
                        {
                          label: "Quarter 3",
                          value: getQuarterPreset(6),
                        },
                        {
                          label: "Quarter 4",
                          value: getQuarterPreset(9),
                        },
                        {
                          label: "This Year",
                          value: thisYear(),
                        },
                        {
                          label: "Last Year",
                          value: lastYear(),
                        },
                      ]}
                    />
                  </Col>
                  <Col style={{ margin: "0px 8px" }}>
                    {data?.length === 0 ? (
                      <>
                        <Button
                          type="default"
                          icon={<DownloadOutlined />}
                          disabled
                        >
                          Export
                        </Button>
                      </>
                    ) : (
                      <>
                        <Popover
                          content={content}
                          placement="rightTop"
                          trigger="hover"
                        >
                          <Button
                            type="default"
                            icon={<DownloadOutlined />}
                            // onClick={() => exportCsv()}
                          >
                            Export
                          </Button>
                        </Popover>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <div style={{ marginTop: "20px" }}>
              <Table
                className="ca-viewmodel-table"
                dataSource={data}
                columns={columns.filter((cl: any) =>
                  selectedColumns.includes(cl?.dataIndex)
                )}
                size="small"
                scroll={
                  data?.length > 15
                    ? { x: 1500, y: window.innerHeight - 300 }
                    : undefined
                }
              />
            </div>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default PartyOutstanding;
