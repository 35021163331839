import { useHotkeys } from "react-hotkeys-hook";
import { useKeyboardShortcuts } from "./KeyboardShortcuts ";

interface EntryLevelKeys {
  createNew?: () => void;
  save: () => void;
  saveAndNew?: () => void;
  saveAndPrint?: () => void;
  cancel?: () => void;
}
const EntryLevelKeys = ({
  createNew,
  save,
  saveAndNew,
  saveAndPrint,
  cancel,
}: any) => {
  // console.log("Entry Level ");
  // useHotkeys("alt+s",()=> save,{
  //   enabled: (event, hotkeysEvent) => {
  //  console.log(event,hotkeysEvent);
   
  //     return true;
  //   }
  // })
  useKeyboardShortcuts("n",["altKey"],createNew)
  useKeyboardShortcuts("s",["altKey"],save)
  useKeyboardShortcuts("s+n",["altKey"],saveAndNew)
  useKeyboardShortcuts("p",["altKey"],saveAndPrint)
  useKeyboardShortcuts("c",["altKey"],cancel)
  // useHotkeys("alt+n", (event) => {
    
  //   event.preventDefault();
  //   createNew();
  //   // notify("Save");
  // });
  // useHotkeys("alt+s", (event) => {
  //   console.log("event",event);

  //   save();
  //   event.preventDefault();
  //   // notify("Save");
  // });
  // useHotkeys("alt+s+n, alt+d", (event) => {
  //   event.preventDefault();
  //   saveAndNew();
  //   // notify("Save & New");
  // });
  // useHotkeys("alt+p", (event) => {
  //   event.preventDefault();
  //   saveAndPrint();
  //   // notify("Save & Print");
  // });
  // useHotkeys("alt+c", (event) => {
  //   event.preventDefault();
  //   cancel();
  //   // notify("Cancel / Discard");
  // });
};
export default EntryLevelKeys;
