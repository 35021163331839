import React, { useRef } from "react";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  UploadProps,
} from "antd";
import SunEditor from "suneditor-react";
import Dragger from "antd/es/upload/Dragger";
import {
  CloseOutlined,
  InboxOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload";
import LedgerService from "../../Services/LedgerService";
import { useClient } from "../../Context/ClientContext";
import { LocalChunkSize } from "papaparse";
import type { AutoCompleteProps } from "antd";
import DownloadService from "../../Services/DownloadService";

const EmailSendParty: React.FC<{
  open: boolean;
  onCancel: (value: React.SetStateAction<string>) => void;
  voucherId: string;
  partyId: string;
  type?: number;
  apiData?: any;
}> = (props) => {
  const [form] = Form.useForm();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { currentClient, companySettings } = useClient();
  const [textValue, setTextValue] = React.useState<string>("");
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [templateId, setTemplateId] = React.useState<string>("");
  const [templateData, setTemplateData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [emailData, setEmailData] = React.useState<{
    emailId: string[];
    ccId: string[];
  }>({ emailId: [], ccId: [] });
  const [inputValue, setInputValue] = React.useState("");

  const editor = useRef();

  console.log("emailId", props, emailData);

  React.useEffect(() => {
    if (props?.partyId) {
      setLoading(true);
      const getGroupDeatils = async (partyId: string) => {
        await LedgerService.getLedgerDetails(currentClient?.id!, partyId)
          .then((res: any) => {
            console.log("result...........", res.result.contactInformation);
            if (res?.result) {
              res.result?.contactInformation?.map((x: any) => {
                // console.log(x.email);
                if (x.email || x.ccTo) {
                  setEmailData((prevState) => ({
                    ...prevState,
                    emailId: x.email
                      ? [...prevState.emailId, x.email]
                      : prevState.emailId,
                    ccId: x.ccTo
                      ? [...prevState?.ccId, x.ccTo]
                      : prevState.ccId,
                  }));
                }
              });
              setLoading(false);
            } else {
              // openNotification(res?.message);
            }
          })
          .catch((err: any) => {
            setLoading(false);
            // openNotification(err?.message);
            //console.log("error", err);
          });
      };
      getGroupDeatils(props?.partyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.partyId]);

  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  React.useEffect(() => {}, []);

  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: null,
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: null,
    },
  ]);

  const handelFile = async (file: any, fileList: any) => {
    fileList?.map(async (itm: any, index: any) => {
      let prevAtt = [...attachmentList];
      let currentFileBase64 = await getBase64(itm as RcFile);

      let fileInfo = {
        id: "",
        name: itm?.name,
        contentType: itm?.type,
        length: itm?.size,
        path: currentFileBase64,
      };

      prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

      setAttachmentList(prevAtt);
    });
  };

  const customRender = (
    originNode: React.ReactElement<any>,
    file: UploadFile,
    fileList: Array<UploadFile>
  ) => {
    return (
      <>
        {/* <div className="ant-upload-list-item">
                  {
                    file.name
                  }

                </div> */}
        {originNode}
        {/* <h1>This is heading</h1> */}
      </>
    );
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: {
      previewIcon: true,
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    beforeUpload(file, FileList) {
      handelFile(file, FileList);
      return false;
    },
    itemRender: customRender,
  };
  //   <!DOCTYPE html>
  //   <html lang="en">
  //   <head>
  //       <meta charset="UTF-8">
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //       <title>Mail</title>
  //   </head>
  //   <body>
  //       <div style="width: 60%; font-size: 16px; font-family: Aptos, Aptos_EmbeddedFont, Aptos_MSFontService, Calibri, Helvetica, sans-serif;">
  //           <p><span style="font-size: 16px">Hi,  ${props?.editRecord?.fullName}</span></p>
  //           <p><span style="font-size: 16px">We are delighted to inform you that your enrollment in the B.Sc. Computer Science program at LSBU has been successfully processed. We believe that this course will equip you with valuable knowledge and skills essential for excelling in your chosen field.</span></p>
  //           <p><span style="font-size: 16px">I've attached to this email your enrollment form and invoice for the enrollment fee. If you have any questions or need any more help, please do not hesitate to reach out to us.</span></p><p><span style="font-size: 16px">Once again, congratulations on your successful enrollment!</span></p><p><span style="font-size: 16px">--</span>
  //           <div style="line-height:0mm; font-size: 50px; font-family: verdana, sans-serif;">
  //           </div>
  //           <div style="line-height:0mm; margin-top: 38px; font-family: 'trebuchet ms', sans-serif; font-size: 13px;">
  //               <h4 style="color:#666666;">CAPSITECH INSTITUTE OF TECHNOLOGY</h4>
  //               <h4 style="color:#a80000; font-size: 10.6667px; font-family: Arial, sans-serif;">H-299 | 3rd Phase, RIICO Industrial Area | Boranada | Jodhpur-342012</h4>
  //           </div>
  //           <div style="line-height:1mm; margin-top: 25px;">
  //               <p style="color:#666666; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">Phone : +91 0291 294 2244 | Mob. +91 (0) 8302303370</p>
  //               <p style="color:#666666; font-size: 10px; font-family: Arial, Helvetica, sans-serif;">Email : vineeta.tookey@capsitech.com | Web URL : <a href="https://www.capsitech.com/" style="color:rgb(0, 68, 255)">https://www.capsitech.com/</a></p>
  //           </div>
  //           <div>
  //               <img src="https://cit.capsitech.com/images/Citlogo.svg" />
  //           </div>
  //       </div>
  //   </body>
  // </html>
  //  `;

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        setButtonLoading(true);
        console.log(" values?.attachments", values?.attachments);

        let formValue = {
          ...values,
          toMail: values.toMail?.map((x: any) => {
            return {
              email: x,
            };
          }),
          cCToMail: values.cCToMail?.map((x: any) => {
            return {
              email: x,
            };
          }),
          attachments: values?.attachments
            ? await Promise.all(
                values?.attachments?.fileList?.map(
                  async (itm: any, index: number) => {
                    let currentFileBase64 = await getBase64(
                      itm?.originFileObj as RcFile
                    );
                    let fileInfo = {
                      fileType: null,
                      fileName: {
                        id: "",
                        name: itm?.name,
                        contentType: itm?.type,
                        length: itm?.size,
                        path: currentFileBase64,
                      },
                      description: null,
                    };
                    return fileInfo;
                  }
                )
              )
            : [],
        };
        console.log(formValue, "=values");

        await DownloadService.sendEmail(
          currentClient?.id!,
          props?.voucherId,
          formValue
        ).then((res) => {
          if (res.status) {
            form.resetFields();
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleInputChange = (value: any) => {
    setInputValue(value);
    // setOpen(value.length > 0);
  };

  return (
    <Drawer
      className="ledgerdrawerstyle"
      title="Send Mail"
      open={props.open}
      maskClosable={false}
      onClose={() => props.onCancel("")}
      width={820}
      closable={false}
      extra={
        <Space>
          <CloseOutlined onClick={() => props.onCancel("")} />
        </Space>
      }
      footer={
        <Space
          style={{
            display: "flex",
            justifyContent: "end",
            marginRight: 0,
          }}
        >
          <Button onClick={() => props.onCancel("")}>Cancel</Button>
          {/* loading={buttonLoading} */}
          <Button onClick={onSubmit} type="primary">
            Send
          </Button>
        </Space>
      }
    >
      <Spin indicator={antIcon} spinning={loading}>
        <Form
          form={form}
          // layout="vertical"
          layout="horizontal"
          colon={false}
          labelAlign="left"
          requiredMark={true}
          initialValues={{ toMail: props?.apiData?.contactDetail?.email }}
          labelCol={{ span: 2 }}
        >
          <Row gutter={10} style={{ justifyContent: "space-between" }}>
            <Col xs={20}>
              <Form.Item
                name="toMail"
                label="To"
                className="inputBoxMb8 inputLabel33"
                rules={[
                  {
                    required: true,
                    message: "Please Enter email",
                  },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  allowClear={false}
                  placeholder="Select Email"
                  popupMatchSelectWidth={false}
                  placement="bottomLeft"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  // onSelect={(val) => setCurrentCustomerState(val)}
                  options={emailData.emailId?.map((x: any) => ({
                    value: x,
                    label: x,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col xs={3}>
              <Form.Item
                name="includePdf"
                // label="Send Pdf"
                className="inputBoxMb8 inputLabel33"
                valuePropName="checked"
                
              >
                <Checkbox>Send Pdf</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {/* <Col lg={6}>
                <Form.Item
                  name="schedule"
                  label="Schedule"
                  className="inputBoxMb8 inputLabel33"
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                    
                  />
                </Form.Item>
              </Col> */}
          {/* </Row> */}

          <Form.Item
            name="cCToMail"
            label="CC"
            className="inputBoxMb8 inputLabel33"
          >
            <Select
              showSearch
              mode="multiple"
              allowClear={false}
              placeholder="Select Email"
              popupMatchSelectWidth={false}
              placement="bottomLeft"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              // onSelect={(val) => setCurrentCustomerState(val)}
              options={emailData.ccId?.map((x: any) => ({
                value: x,
                label: x,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="subject"
            label="Subject"
            className="inputBoxMb8 inputLabel33"
          >
            <Input placeholder="" />
          </Form.Item>

          <Form.Item
            name="body"
            label=""
            className="inputBoxMb8 inputLabel33"
            rules={[{ required: true, message: "Please add content here" }]}
          >
            <SunEditor
              setContents={templateId ? form.getFieldValue("body") : ""}
              getSunEditorInstance={getSunEditorInstance}
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize"],
                  ["bold", "underline", "italic", "strike"],
                  ["fontColor", "hiliteColor"],
                  ["align", "list", "lineHeight"],
                  ["outdent", "indent"],
                  ["removeFormat"],
                ],
                defaultTag: "div",
                font: [
                  "Arial",
                  "Comic Sans MS",
                  "Courier New",
                  "Impact",
                  "Georgia",
                  "Tahoma",
                  "Trebuchet MS",
                  "Verdana",
                ],
                fontSize: [
                  8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 24, 36,
                ],
                defaultStyle: "font-family: Arial; font-size: 14px;", // Set default style
                showPathLabel: false,
              }}
              height="200px"
            />
          </Form.Item>

          {/* <Form.Item
            name={"attachments"}
            // rules={[
            //   {
            //     required: true,
            //     message: "this field is required",
            //   },
            // ]}
            className="inputBoxMb8 inputLabel33"
          >
            <Dragger
              {...uploadProps}
              // onPreview={async (file: UploadFile) => {
              //     console.log("testtest = ", await getBase64(
              //       file.originFileObj as any
              //     ))
              // }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </Form.Item> */}
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EmailSendParty;
