import { Button, Card, Checkbox, Col, Form, Input, Row, message } from "antd";

import companylogo1 from "../../CapsitechAccountsComponents/Images/CapsitechAccounts.svg";
import { AuthService } from "../../Services/AuthService";
import { useUser } from "../../Context/UserContext";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
const ForgotPassword = () => {
  const [form] = Form.useForm();
  const { getUser } = useUser();
  const loginUser = { ...getUser() };
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();

  const HandleSubmit = async () => {
    const info = (msg: any) => {
      messageApi.success(msg, 1);
    };
    const error = (mes: any) => {
      messageApi.error(mes);
    };

    form.validateFields().then((result) => {
      AuthService.resetPassword(
        result.email,
        result.password,
        result.confirmPassword,
        location.search
          .split("&code=")[1]
          .replaceAll("%2B", "+")
          .replaceAll("%2F", "/")
      ).then((res: any) => {
        if (res?.newPassword === res?.confirmPassword) {
          info("password changed successfully");
          form.resetFields();
          console.log("jasldfjasljflsjdlfjasdflsjfljslflsd", res?.token);
        } else {
          error("Something went to wrong");
          form.resetFields();
        }
      });
    });
  };

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "whitesmoke",
        overflowY: "auto",
      }}
    >
      <Row justify={"center"} style={{ paddingTop: "150px" }}>
        <Col lg={24} style={{ textAlign: "center" }}>
          <img src={companylogo1}></img>
        </Col>
        <Card
          style={{ width: "400px", marginTop: "12px" }}
          title="Set New Password"
        >
          {contextHolder}
          <Form
            form={form}
            layout="vertical"
            name="newPass"
            requiredMark={false}
          >
            <Row>
              <Col lg={24}>
                <Form.Item
                  name="email"
                  label="Enter your email"
                  rules={[
                    { required: true, message: "Required" },
                    { type: "email", message: "Invalid Email" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item
                  name="password"
                  label={"New Password"}
                  rules={[
                    {
                      required: true,
                      message: "Enter new password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Enter new password" />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  label={"Confirm Password"}
                  rules={[
                    {
                      required: true,
                      message: "Enter confirm password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you Entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password placeholder="Enter Confirm Password" />
                </Form.Item>
              </Col>
              <Col lg={24}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={HandleSubmit}
                >
                  Sign in
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>
    </div>
  );
};

export default ForgotPassword;
