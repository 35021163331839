import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ShareCapital1 from './ShareCapital1'
import Shareholders from './Shareholders'
import Dividents from './Dividents'
import ResourceNotFound from '../../Common/ResourceNotFound'
const ShareCapitalNestedRoute = () => {
  return (
    <div>
      <Routes>
        <Route path="/sharecapital" element={<ShareCapital1 />} />
        <Route path="/shareholders" element={<Shareholders />} />
        <Route path="/dividents" element={<Dividents />} />
        <Route path="*" element={<ResourceNotFound pageHeight="100%" />} />
      </Routes>
    </div>
  );
}

export default ShareCapitalNestedRoute