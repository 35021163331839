import { EditOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Table, Tabs, TabsProps, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";

interface IDataType {
  code: number;
  Name: string;
}
const FixesAssets = () => {
  const columns: ColumnsType<IDataType> = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "15%",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      width: "55%",
    },
    {
      title: "Archive",
      dataIndex: "archive",
      key: "archive",
      width: "15%",
      render: (_, record) => <Checkbox />,
    },
    {
      title: "Action",
      key: "10",
      width: "15%",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <div>
            <Tooltip title="Edit" placement="top" arrow={true}>
              <EditOutlined onClick={async () => {}} />
            </Tooltip>
          </div>
          <Tooltip title="Reset" placement="top">
            <div style={{ marginLeft: "20px", color: "red" }}>
              <ReloadOutlined
                onClick={() => {
                  //   setEditItem(record);
                }}
              />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];
  const data: IDataType[] = [];
  for (let i = 1; i < 5; i++) {
    data.push({
      code: 100 + i,
      Name: `London ${i}`,
    });
  }
  return (
    <>
      <Button icon={<ReloadOutlined />} type="link" size="large">
        Refresh
      </Button>
      <Table
        className="Tabel-style"
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};

const FixedAssetsInvestments = () => {
  const columns: ColumnsType<IDataType> = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "15%",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      width: "55%",
    },
    {
      title: "Archive",
      dataIndex: "archive",
      key: "archive",
      width: "15%",
      render: (_, record) => <Checkbox />,
    },
    {
      title: "Action",
      key: "10",
      width: "15%",
      render: (_, record) => (
        <div style={{ display: "flex" }}>
          <div>
            <Tooltip title="Edit" placement="top" arrow={true}>
              <EditOutlined onClick={async () => {}} />
            </Tooltip>
          </div>

          <Tooltip title="Reset" placement="top">
            <div style={{ marginLeft: "20px", color: "red" }}>
              <ReloadOutlined
                onClick={() => {
                  //   setEditItem(record);
                }}
              />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];
  const data: IDataType[] = [];
  for (let i = 1; i < 10; i++) {
    data.push({
      code: 200 + i,
      Name: `London ${i}`,
    });
  }
  return (
    <>
      <Table
        className="Tabel-style"
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </>
  );
};

const AddFixedAssetsRegister: React.FC<{
  open: boolean;
  onCancle: (value?: any) => void;
}> = (props) => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Fixed assets`,
      children: <FixesAssets />,
    },
    {
      key: "2",
      label: `Fixed assets investments`,
      children: <FixedAssetsInvestments />,
    },
  ];

  return (
    <>
      <Modal
        open={props.open}
        onCancel={() => props.onCancle()}
        title="Fixed assets register"
        style={{ top: 44 }}
        footer={null}
        width={650}
      >
        <Tabs items={items} size="large" />
      </Modal>
    </>
  );
};

export default AddFixedAssetsRegister;
