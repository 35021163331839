import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";

const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => {
      console.log("Error during base64 conversion", error);
      reject(error);
    };
  });
};

const ImageUpload = ({ setImageBase64 }: any) => {
  const allowedTypes = ["image/jpeg", "image/jpg"];

  const handleChange = async (info: any) => {
    const file = (info.file.originFileObj || info.file) as RcFile; // Access file correctly

    if (!allowedTypes.includes(file.type)) {
      return; // Exit if the file type is not allowed
    }

    try {
      const base64 = await getBase64(file);
      setImageBase64(base64);
    } catch (error) {
      console.log("Error during file handling", error);
      message.error(`Error converting ${info.file.name} to base64.`);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrJpeg = allowedTypes.includes(file.type);
    if (!isJpgOrJpeg) {
      message.error("You can only upload JPG/JPEG file types!");
    } else {
      message.success("File uploaded successfully");
    }
    return isJpgOrJpeg;
  };

  return (
    <div>
      <Upload
        name="avatar"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
    </div>
  );
};

export default ImageUpload;
