import React, { useContext } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import "../../../CapsitechAccountsComponents/Common/Acc_Common.css";
import "../../Pages/Parties/Party.css";
import "../../Services/SettingService";
import TextArea from "antd/es/input/TextArea";
import logicon from "../../Images/CapsitechAccounts.svg";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import {
  InvoiceTemplates,
  ITemplateSettings,
  SettingsSections,
} from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";

// export enum fontoptions {
//   Arial,
//   Calibri,
//   Cambria,
//   Georgia,
//   Myriad,
//   Thoma,
// }
export const fontSizeOptions = [
  { label: "8pt", value: 0 },
  { label: "9pt", value: 1 },
  { label: "10pt", value: 2 },
  { label: "11pt", value: 3 },
];

export const headingfontsize = [
  { label: "8pt", value: 0 },
  { label: "9pt", value: 1 },
  { label: "10pt", value: 2 },
  { label: "11pt", value: 3 },
];

export const taxSubtotals: any = [
  { label: "Tax rates", value: 0 },
  { label: "Tax rates over 0%", value: 1 },
  { label: "A single tax subtotal", value: 2 },
];

const EditInvoiceTemplate: React.FC = () => {
  const { Text } = Typography;
  const { openNotification } = useContext(notificationContext);
  const navigate = useNavigate();
  let { type }: any = useParams();
  const { currentClient, companySettings, updateCompanySettings } = useClient();
  const [margin, setMargin] = React.useState<number>(0);
  const typeAsInt = parseInt(type);

  console.log("type", typeAsInt);

  // const { type } = useParams();
  const [form] = Form.useForm();

  const [isChanged, setIsChanged] = React.useState<ITemplateSettings>({
    type: InvoiceTemplates.Template1,
    showTaxNo: true,
    showTaxColumn: true,
    taxInclusive: true,
    name: "",
    paperSize: "",
    measureIn: "",
    topMargin: 0,
    bottomMargin: 0,
    addressPadding: 0,
    font: "",
    fontSize: 0,
    headingFontSize: 0,
    taxSubtotals: 0,

    contactDetails: "",
    showUnitPriceAndQty: true,
    showPaymentAdviceCutAway: true,
    showRegisteredAddress: true,
    hideDisc: true,
    showContactAcNo: true,
    showColumnHeading: true,
    showLogo: true,
    logoAlign: "",
    showCompanyAddress: true,
    showGSTIN: true,
    showPan: true,
    showEmail: true,
    showPhone: true,
    showWebsite: true,
    showShipping: true,
    showEBillNo: true,
    showEInvoiceNo: true,
    showItemCode: true,
    showUnit: true,
    showHSN: true,
    showTaxableValue: true,
    showBankDetail: true,
    showDispatchDetail: true,
    psCreditCard: {
      name: "",
      id: 0,
    },
    psPayPal: {
      name: "",
      id: 0,
    },
    psBankPayments: {
      name: "",
      id: 0,
    },
  });

  React.useEffect(() => {
    if (typeAsInt || typeAsInt === 0) {
      const allTempleteData = companySettings?.setting;

      const templeteData = allTempleteData?.templateSettings?.find(
        (x) => x?.type === typeAsInt
      );
      // debugger;
      console.log("templeteData", templeteData);
      if (templeteData) {
        form.setFieldsValue(templeteData);
        setIsChanged(templeteData);
      } else {
        console.error("Not found templeteData");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettings]);

  const onsubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        const data = { type: typeAsInt, ...values };
        console.log("fromData", data, values);

        const postData: any = {
          templateSettings: companySettings?.setting?.templateSettings?.map(
            (t) => {
              if (t.type === ((typeAsInt ?? 0) as InvoiceTemplates)) t = data;
              return t;
            }
          ),
          defaultTemplate: typeAsInt ?? 0,
        };

        console.log("values", postData);
        if (postData) {
          await updateCompanySettings!(
            SettingsSections.Template,
            {
              ...companySettings,
              setting: {
                ...companySettings?.setting!,
                templateSettings: postData?.templateSettings,
                defaultTemplate: postData?.defaultTemplate,
              },
            },
            postData
          ).then((result: any) => {
            if (result?.status) {
              openNotification("success", "Setting Updated successfully");
              //  setSaveButtonLoading(false);
            } else {
              //  setSaveButtonLoading(false);
              openNotification("error", "there is some error");
            }
          });
        }
        // updateCompanySettings!(
        //   SettingsSections.Template,
        //   {
        //     ...companySettings,
        //     setting: {
        //       ...companySettings?.setting!,
        //       template: data?.dueOn,
        //     },
        //   },
        //   data
        // );
      })
      .catch((error: any) => {
        console.log(error, "error-------");
      });
  };

  const handleCheckboxChange = (align: any) => {
    setIsChanged((prevState) => ({
      ...prevState,
      logoAlign: prevState.logoAlign === align ? "left" : align,
    }));
  };

  return (
    <>
      <div style={{ backgroundColor: "#f7f8faff" }}>
        <Row>
          <Col lg={11} md={10}>
            <Card
              style={{
                border: "1.5px solid #eef2fe",
                height: "96%",
                overflowY: "hidden",
              }}
              className="templatesec"
            >
              <Row
                style={{
                  fontWeight: 500,
                  color: "#0b308e",
                  alignItems: "center",
                }}
                justify={"space-between"}
              >
                <Col
                  style={{
                    borderBottom: "1px solid rgb(210, 208, 206)",
                    padding: "10px 0",
                  }}
                >
                  <span>Email Template</span>
                </Col>
                <Col>
                  <Button type="primary">
                    <RedoOutlined style={{ fontSize: "16px" }} />
                    Reset
                  </Button>
                </Col>
              </Row>
              <Form
                form={form}
                name="templateform"
                requiredMark={false}
                colon={false}
                labelAlign="left"
                size="small"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <Row style={{ padding: "15px 0px" }}>
                  <Col lg={12}>
                    <Form.Item label="Name" name="name" className="inputBoxMb8">
                      <Input style={{ width: "147px" }} disabled />
                    </Form.Item>
                    <Form.Item
                      label="Paper Size"
                      name="paperSize"
                      className="inputBoxMb8"
                    >
                      <Select
                        style={{ width: "147px" }}
                        options={[{ value: "A4", label: "A4" }]}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Measure in"
                      name="measureIn"
                      className="inputBoxMb8"
                    >
                      <Select
                        style={{ width: "147px" }}
                        options={[
                          {
                            value: "cm",
                            label: "cm",
                          },
                          {
                            value: "inch",
                            label: "inch",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Top margin"
                      name="topMargin"
                      className="inputBoxMb8"
                    >
                      <Input
                        style={{ width: "147px" }}
                        onChange={(e: any) => {
                          setIsChanged({
                            ...isChanged,
                            topMargin: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Bottom margin"
                      name="bottomMargin"
                      className="inputBoxMb8"
                    >
                      <Input
                        style={{ width: "147px" }}
                        onChange={(e: any) => {
                          setIsChanged({
                            ...isChanged,
                            bottomMargin: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Address padding"
                      name="addressPadding"
                      className="inputBoxMb8"
                    >
                      <Input style={{ width: "147px" }} />
                    </Form.Item>
                    <Form.Item label="Font" name="font" className="inputBoxMb8">
                      <Select
                        style={{ width: "147px" }}
                        defaultValue={isChanged.font}
                        options={[
                          { value: "Arial", label: "Arial" },
                          { value: "Calibri", label: "Calibri" },
                          { value: "Cambria", label: "Cambria" },
                          { value: "Georgia", label: "Georgia" },
                          { value: "Myriad", label: "Myriad-Pro" },
                          { value: "Tahoma", label: "Tahoma" },
                        ]}
                        onChange={(e: any) => {
                          setIsChanged({
                            ...isChanged,
                            font: e,
                          });
                          //console.log(isChanged.font);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Font size"
                      name="fontSize"
                      className="inputBoxMb8"
                    >
                      <Select
                        style={{ width: "147px" }}
                        options={fontSizeOptions}
                        onChange={(e: any) => {
                          setIsChanged({
                            ...isChanged,
                            fontSize: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Heading font size"
                      name="headingFontSize"
                      className="inputBoxMb8"
                    >
                      <Select
                        style={{ width: "147px" }}
                        options={headingfontsize}
                        onChange={(e) => {
                          setIsChanged({
                            ...isChanged,
                            headingFontSize: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Row style={{ paddingTop: "8px", paddingBottom: "10px" }}>
                      <span style={{ color: "gray", paddingBottom: "8px" }}>
                        Show tax subtotals by
                      </span>
                      <Form.Item name="taxSubtotals" className="inputBoxMb8">
                        <Select
                          style={{ width: "272px" }}
                          options={taxSubtotals}
                          onChange={(e) => {
                            setIsChanged({
                              ...isChanged,
                              taxSubtotals: e,
                            });
                          }}
                        />
                      </Form.Item>
                    </Row>

                    <span>
                      Enter any additional details (to be displayed in notes)
                    </span>
                    <Form.Item className="inputBoxMb8">
                      <div style={{ width: "280px" }}>
                        <TextArea
                          rows={4}
                          id="additionaldetails"
                          onChange={(e: any) => {
                            // setIsChanged({
                            //   ...isChanged,
                            //   docomentdetails: e.target.value,
                            // });
                            //console.log(additionaldocomentdetails, "details");
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  <Col lg={11} style={{ marginLeft: "13px" }}>
                    <Form.Item
                      label="Show Logo"
                      className="inputBoxMb8"
                      name="showLogo"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showLogo}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showLogo: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showLogo: e.target.checked,
                              logoAlign: "left",
                            });
                          }
                          // console.log(isChanged, "logoimg");
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Logo alignment"
                      className="inputBoxMb8"
                      name="logoAlign"
                      valuePropName="checked"
                    >
                      <Row justify="space-between">
                        <Col>
                          <Checkbox
                            checked={
                              isChanged.showLogo &&
                              isChanged.logoAlign === "left"
                            }
                            disabled={!isChanged.showLogo}
                            onChange={() => handleCheckboxChange("left")}
                          >
                            Left
                          </Checkbox>
                        </Col>
                        <Col>
                          <Checkbox
                            checked={
                              isChanged.showLogo &&
                              isChanged.logoAlign === "right"
                            }
                            disabled={!isChanged.showLogo}
                            onChange={() => handleCheckboxChange("right")}
                          >
                            Right
                          </Checkbox>
                        </Col>
                        <Col>
                          <Checkbox
                            checked={
                              isChanged.showLogo &&
                              isChanged.logoAlign === "center"
                            }
                            disabled={!isChanged.showLogo}
                            onChange={() => handleCheckboxChange("center")}
                          >
                            Center
                          </Checkbox>
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item
                      label="Company Address"
                      name="showCompanyAddress"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showCompanyAddress}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showCompanyAddress: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showCompanyAddress: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="GSTIN"
                      name="showGSTIN"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showGSTIN}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showGSTIN: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showGSTIN: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Pan Card"
                      name="showPan"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showPan}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showPan: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showPan: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Email"
                      name="showEmail"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showEmail}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showEmail: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showEmail: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Mobile"
                      name="showPhone"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showPhone}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showPhone: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showPhone: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Website"
                      name="showWebsite"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showWebsite}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showWebsite: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showWebsite: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Shipping address"
                      name="showShipping"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showShipping}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showShipping: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showShipping: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="E-Bill No."
                      name="showEBillNo"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showEBillNo}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showEBillNo: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showEBillNo: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="E-Invoice No."
                      name="showEInvoiceNo"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showEInvoiceNo}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showEInvoiceNo: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showEInvoiceNo: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Item Code"
                      name="showItemCode"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showItemCode}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showItemCode: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showItemCode: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Unit"
                      name="showUnit"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showUnit}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showUnit: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showUnit: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="HSC/SAC"
                      name="showHSN"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showHSN}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showHSN: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showHSN: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Taxable Value"
                      name="showTaxableValue"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showTaxableValue}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showTaxableValue: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showTaxableValue: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Bank Details"
                      name="showBankDetail"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showBankDetail}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showBankDetail: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showBankDetail: false,
                            });
                          }
                          console.log(isChanged.showBankDetail, "ok");
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Show QR or UPI"
                      name="showBankDetailWithQRorUPI"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Form.Item>

                    <Form.Item
                      label="Dispatch Detail"
                      name="showDispatchDetail"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showDispatchDetail}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showDispatchDetail: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showDispatchDetail: false,
                            });
                          }
                          console.log(isChanged.showDispatchDetail, "ok");
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{
                    padding: "10px 0",
                    borderTop: "1.5px solid rgb(210, 208, 206)",
                  }}
                  justify={"end"}
                >
                  <Col className="ps-14">
                    <Button
                      type="default"
                      size="middle"
                      onClick={() => {
                        navigate(
                          `/accounts/clients/${currentClient?.id}/settings/templates`
                        );
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="ps-14">
                    <Button
                      type="primary"
                      size="middle"
                      onClick={() => onsubmit()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>

          {/* Templete 1 GST Format */}
          <Col lg={13} md={10}>
            <div
              className={"Template" + isChanged?.font}
              style={{
                height: "820px",
                paddingTop: `${isChanged?.topMargin}px`,
                // marginBottom: `${isChanged?.bottomMargin}px`,
              }}
            >
              <Card
                style={{
                  margin: "0px 39px",
                  width: "700px",
                  overflowY: "scroll",
                  height: "810px",
                }}
                className="template"
              >
                <div
                  style={{
                    backgroundColor: "#F3F8FF",
                    padding: "5px",
                    maxHeight: "100%px",
                    margin: isChanged.topMargin,
                    // maxWidth:""
                  }}
                >
                  <div
                    style={{
                      maxWidth: "1000px",
                      margin: "0px auto",
                    }}
                  >
                    <Row justify={"space-between"}>
                      <Col
                        lg={12}
                        style={{
                          textAlign:
                            isChanged.logoAlign === "left"
                              ? "start"
                              : "start" || isChanged.logoAlign === "right"
                              ? "end"
                              : "start",
                          paddingRight:
                            isChanged.logoAlign === "center" ? "70px" : "0px",
                        }}
                      >
                        <img
                          src={logicon}
                          alt="Templete"
                          style={{
                            height: "65%",
                            display: isChanged.showLogo ? "" : "none",
                          }}
                        />
                        {/* <Row>
                        <Typography.Title level={5}>
                          {currentClient?.businessName}
                        </Typography.Title>
                      </Row> */}
                      </Col>
                      {/* <Col lg={12} style={{ textAlign: "end" }}>
                        <Typography.Title level={2} underline>
                          Tax Invoice
                        </Typography.Title>
                      </Col> */}
                    </Row>

                    <div
                      style={{
                        backgroundColor: "#4e6fda",
                        justifyContent: "space-between",
                        display: "flex",
                        padding: "5px",
                        color: "white",
                        fontFamily: isChanged?.font,
                      }}
                    >
                      <div>
                        <h3
                          style={{
                            margin: "0px",
                            fontSize: "23px",
                            borderRightColor: "white",
                          }}
                        >
                          {currentClient?.businessName}
                        </h3>
                        <p
                          style={{
                            margin: "0px",
                            display: isChanged.showCompanyAddress ? "" : "none",
                          }}
                        >
                          Basni Industrial Area, Jodhpur
                        </p>
                        <p style={{ margin: "0px" }}>
                          <strong> State</strong>:08-Rajasthan,
                          <strong
                            style={{
                              display: isChanged.showGSTIN ? "" : "none",
                            }}
                          >
                            GSTIN
                          </strong>
                          <span
                            style={{
                              display: isChanged.showGSTIN ? "" : "none",
                            }}
                          >
                            : 08ABCCA4785G1ZZ,
                          </span>
                          <span
                            style={{
                              display: isChanged.showPan ? "" : "none",
                            }}
                          >
                            <strong>PAN</strong>: ABCCA4785G
                          </span>
                        </p>
                        <p style={{ margin: "0px" }}>
                          <span
                            style={{
                              display: isChanged.showEmail ? "" : "none",
                            }}
                          >
                            <strong>Email:</strong> test@gmail.com,
                          </span>
                          <span
                            style={{
                              display: isChanged.showPhone ? "" : "none",
                            }}
                          >
                            <strong>Mobile:</strong> +919898989898
                          </span>
                          ,
                          <span
                            style={{
                              display: isChanged.showWebsite ? "" : "none",
                            }}
                          >
                            <strong>Website:</strong> www.test.com,
                          </span>
                        </p>
                      </div>
                      <div>
                        <h4 style={{ margin: "0px" }}>
                          (Original Invoice Copy)
                        </h4>
                        <h2
                          style={{
                            margin: "0px",
                            textAlign: "end",
                            paddingTop: "10px",
                          }}
                        >
                          Tax Invoice
                        </h2>
                      </div>
                    </div>
                    <table
                      style={{
                        width: "100%",
                        marginTop: "2px",
                        borderCollapse: "collapse",
                      }}
                      className="bordertable"
                    >
                      <tbody>
                        <tr>
                          <td
                            rowSpan={12}
                            style={{
                              width: "50%",
                              textAlign: "start",
                              fontSize: "16px",
                              verticalAlign: "sub",
                              lineHeight: "1.4rem",
                            }}
                          >
                            <u>Buyer (Bill to)</u>
                            {/* <div
                              style={{
                                width: "20%",
                                backgroundColor: "black",
                                height: "1px",
                              }}
                            ></div> */}
                            <p style={{ margin: "0px" }}>ABC & Sons</p>
                            <p style={{ margin: "0px" }}>
                              Sardarpura Jodhpur Rajasthan India
                            </p>
                            <p style={{ margin: "0px" }}>
                              <strong>State</strong>:08-Rajasthan,{" "}
                              <strong>GSTIN</strong>
                              <span>: 08ABCCA4785G1ZZ</span>
                            </p>
                            <p style={{ margin: "0px", paddingTop: "45px" }}>
                              <u
                                style={{
                                  display: isChanged.showShipping ? "" : "none",
                                }}
                              >
                                Ship to
                              </u>
                            </p>

                            <p
                              style={{
                                margin: "0px",
                                display: isChanged.showShipping ? "" : "none",
                              }}
                            >
                              ABC & Sons
                            </p>
                            <p
                              style={{
                                margin: "0px",
                                display: isChanged.showShipping ? "" : "none",
                              }}
                            >
                              Sardarpura Jodhpur Rajasthan India
                            </p>
                          </td>
                          <td style={{ fontWeight: "bold" }}>Invoice No.</td>
                          <td style={{ fontWeight: "bold" }}>Date</td>
                          <td style={{ fontWeight: "bold" }}>Reference No.</td>
                        </tr>
                        <tr>
                          <td style={{ height: "14px" }}></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>
                            Delivery Note No.
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            Delivery Note Date
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            Dispatch Through
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: "14px" }}></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }}>
                            Place of Supply
                          </td>
                          <td style={{ fontWeight: "bold" }} colSpan={2}>
                            Mode/Terms of Payment
                          </td>
                        </tr>
                        <tr>
                          <td style={{ height: "14px" }}></td>
                          <td colSpan={2}></td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold" }} colSpan={3}>
                            Terms & Conditions
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} style={{ height: "14px" }}></td>
                        </tr>
                        <tr
                          style={{
                            display: isChanged.showEBillNo ? "" : "none",
                          }}
                        >
                          <td style={{ fontWeight: "bold" }}>EWay Bill No.</td>
                          <td colSpan={3}>(Optional)</td>
                        </tr>
                        <tr
                          style={{
                            display: isChanged.showEBillNo ? "" : "none",
                          }}
                        >
                          <td style={{ fontWeight: "bold" }}>EWay Bill Date</td>
                          <td colSpan={2}>(Optional)</td>
                        </tr>
                        <tr
                          style={{
                            display: isChanged.showEInvoiceNo ? "" : "none",
                          }}
                        >
                          <td
                            style={{
                              fontWeight: "bold",
                              display: isChanged.showLogo ? "" : "none",
                            }}
                          >
                            e-Invoice No.
                          </td>
                          <td colSpan={2}>(Optional)</td>
                        </tr>
                        <tr
                          style={{
                            display: isChanged.showEInvoiceNo ? "" : "none",
                          }}
                        >
                          <td style={{ fontWeight: "bold" }}>e-Invoice Date</td>
                          <td colSpan={2}>(Optional)</td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      style={{
                        width: "100%",
                        marginTop: "3px",
                        borderCollapse: "collapse",
                      }}
                      className="bordertable"
                    >
                      <tbody>
                        <tr>
                          <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                            Sr.
                          </th>
                          <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                            Item(s)
                          </th>
                          <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                            Qty
                          </th>
                          <th
                            rowSpan={2}
                            style={{
                              verticalAlign: "sub",
                            }}
                          >
                            <span
                              style={{
                                display: isChanged.showUnit ? "" : "none",
                              }}
                            >
                              Unit
                            </span>
                          </th>
                          <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                            Rate
                          </th>
                          <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                            Taxable <br /> Amount
                          </th>
                          <th colSpan={2}>CGST</th>
                          <th colSpan={2}>SGST</th>
                        </tr>
                        <tr>
                          <th>Rate</th>
                          <th>Amt</th>
                          <th>Rate</th>
                          <th>Amt</th>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            Chair S1 <br /> (Any description will be displayed
                            here)
                          </td>
                          <td style={{ textAlign: "end" }}>01</td>
                          <td style={{ textAlign: "center" }}>
                            <span
                              style={{
                                display: isChanged.showUnit ? "" : "none",
                              }}
                            >
                              Pcs
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>1000.00</td>
                          <td style={{ textAlign: "end" }}>1000.00</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>25.00</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>25.00</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            Sofa SF1 <br /> (Any description will be displayed
                            here){" "}
                          </td>
                          <td style={{ textAlign: "end" }}>01</td>
                          <td style={{ textAlign: "center" }}>
                            <span
                              style={{
                                display: isChanged.showUnit ? "" : "none",
                              }}
                            >
                              Pcs
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>1000.00</td>
                          <td style={{ textAlign: "end" }}>1000.00</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>25.00</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>25.00</td>
                        </tr>
                        {Array.from({ length: 8 }, (_, i) => (
                          <tr key={i}>
                            <td style={{ height: "18px" }}>{i + 3}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <th style={{ textAlign: "end" }}>Items Total (A)</th>
                          <th style={{ textAlign: "end" }}>02</th>
                          <th>Pcs</th>
                          <td style={{ textAlign: "end" }}></td>
                          <th style={{ textAlign: "end" }}>2000.00</th>
                          <td style={{ textAlign: "end" }}></td>
                          <th style={{ textAlign: "end" }}>50.00</th>
                          <td></td>
                          <th style={{ textAlign: "end" }}>50.00</th>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <u>Other Expenses (if any)</u>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>Freight</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "end" }}>500.00</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>12.50</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>12.50</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>Packing</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "end" }}>100.00</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>2.50</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>2.50</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            Total Expenses (B)
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            600.00
                          </td>
                          <td></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            15.00
                          </td>
                          <td></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            15.00
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            Sub Total (A+B)
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            2600.00
                          </td>
                          <td></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            65.00
                          </td>
                          <td></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            65.00
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={4}
                            style={{
                              backgroundColor: "rgb(207, 236, 248)",
                              fontSize: "20px",
                              textAlign: "end",
                              fontWeight: "bold",
                            }}
                          >
                            Total Invoice Value
                          </td>
                          <td
                            colSpan={6}
                            style={{
                              backgroundColor: "rgb(207, 236, 248)",
                              fontSize: "20px",
                              textAlign: "end",
                              fontWeight: "bold",
                            }}
                          >
                            2730.00
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={4}
                            style={{
                              fontSize: "16px",
                              textAlign: "end",
                              fontWeight: "bold",
                            }}
                          >
                            Amount (in words)
                          </td>
                          <td
                            colSpan={6}
                            style={{ fontSize: "16px", textAlign: "end" }}
                          >
                            INR Two Thousand Seven Hundred Thirty only
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: "100%",
                        marginTop: "3px",
                        display: isChanged.showTaxableValue ? "" : "none",
                        borderCollapse: "collapse",
                      }}
                      className="bordertable"
                    >
                      <tbody>
                        <tr>
                          <th
                            rowSpan={2}
                            style={{
                              verticalAlign: "super",
                              fontWeight: "bold",
                              textAlign: "end",
                            }}
                          >
                            Taxable Value
                          </th>
                          <th colSpan={2}>IGST</th>
                          <th colSpan={2}>CGST</th>
                          <th colSpan={2}>SGST/UTGST</th>
                          <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                            Total Tax Amount
                          </th>
                        </tr>
                        <tr>
                          <th>Rate (%)</th>
                          <th>Amount</th>
                          <th>Rate (%)</th>
                          <th>Amount</th>
                          <th>Rate (%)</th>
                          <th>Amount</th>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "end" }}>2600.00</td>
                          <td style={{ textAlign: "center" }}>-</td>
                          <td style={{ textAlign: "center" }}>-</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>65.00</td>
                          <td style={{ textAlign: "center" }}>2.5</td>
                          <td style={{ textAlign: "end" }}>65.00</td>
                          <td style={{ textAlign: "end" }}>130.00</td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            Total
                          </td>
                          <td
                            style={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            -
                          </td>
                          <td
                            style={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            -
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ fontWeight: "bold", textAlign: "end" }}>
                            65.00
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            65.00
                          </td>
                          <td style={{ textAlign: "end", fontWeight: "bold" }}>
                            130.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: "100%",
                        marginTop: "3px",
                        display: isChanged.showBankDetail ? "" : "none",
                        borderCollapse: "collapse",
                      }}
                      className="bordertable"
                    >
                      <tbody>
                        <tr>
                          <th colSpan={4} style={{ textAlign: "start" }}>
                            <u>
                              Bank Detail:
                              <span style={{ fontWeight: 400 }}>
                                (Optional)
                              </span>
                            </u>
                          </th>
                        </tr>
                        <tr>
                          <th style={{ width: "30%", textAlign: "start" }}>
                            Account Name:
                          </th>
                          <th style={{ width: "25%" }}></th>
                          <th style={{ textAlign: "start" }}>Bank Name:</th>
                          <th style={{ width: "30%" }}></th>
                        </tr>
                        <tr>
                          <th style={{ width: "30%", textAlign: "start" }}>
                            Account No.:
                          </th>
                          <th style={{ width: "25%" }}></th>
                          <th style={{ textAlign: "start" }}>IFS Code:</th>
                          <th style={{ width: "30%" }}></th>
                        </tr>
                        <tr>
                          <th style={{ width: "30%", textAlign: "start" }}>
                            Branch:
                          </th>
                          <th style={{ width: "25%" }}></th>
                          <th style={{ textAlign: "start" }}>UPI ID / QRC:</th>
                          <th style={{ width: "30%" }}></th>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: "100%",
                        marginTop: "3px",
                        display: isChanged.showDispatchDetail ? "" : "none",
                        borderCollapse: "collapse",
                      }}
                      className="bordertable"
                    >
                      <tbody>
                        <tr>
                          <th colSpan={4} style={{ textAlign: "start" }}>
                            <u> Dispatch Detail: (Optional)</u>
                          </th>
                        </tr>
                        <tr>
                          <th style={{ width: "30%", textAlign: "start" }}>
                            Transporter Name:
                          </th>
                          <th style={{ width: "25%" }}></th>
                          <th style={{ textAlign: "start" }}>Vehicle No.:</th>
                          <th style={{ width: "30%" }}></th>
                        </tr>
                        <tr>
                          <th style={{ width: "30%", textAlign: "start" }}>
                            Driver Name:
                          </th>
                          <th style={{ width: "25%" }}></th>
                          <th style={{ textAlign: "start" }}>Contact No.:</th>
                          <th style={{ width: "30%" }}></th>
                        </tr>
                        <tr>
                          <th style={{ width: "30%", textAlign: "start" }}>
                            LR / Bilty No.:
                          </th>
                          <th style={{ width: "25%" }}></th>
                          <th style={{ textAlign: "start" }}>
                            LR / Bilty Date:
                          </th>
                          <th style={{ width: "30%" }}></th>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: "100%",
                        marginTop: "3px",
                        borderCollapse: "collapse",
                      }}
                      className="bordertable"
                    >
                      <tbody>
                        <tr>
                          <td style={{ width: "60%" }}>
                            <p style={{ margin: "0px" }}>
                              <u>Disclaimer:</u>
                            </p>

                            <p style={{ margin: "0px" }}>
                              1. Certified that the particular given above are
                              true and correct and the amount indicated
                              represents the price actually charged and there is
                              no additional consideration flowing, directly or
                              indirectly from the buyer.
                            </p>
                            <p style={{ margin: "0px" }}>
                              2. Subject to Jodhpur Jurisdiction only.
                            </p>
                          </td>
                          <td>
                            <h3
                              style={{
                                marginTop: "0px",
                                textAlign: "end",
                                paddingBottom: "20px",
                              }}
                            >
                              For LATIYAL HANDICRAFT
                            </h3>
                            <p
                              style={{
                                marginBottom: "0px",
                                textAlign: "end",
                                verticalAlign: "bottom",
                                paddingTop: "46px",
                              }}
                            >
                              (Authorized Signatory)
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditInvoiceTemplate;
