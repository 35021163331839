import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Table,
} from "antd";
import React from "react";
import UsersService, { Master_Type } from "../../Services/UsersService";
import { masterType } from "./UsersDetailsTabs";
import { notificationContext } from "../../Common/PageRoute";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const MasterSecurity = ({ userId }: any) => {
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(notificationContext);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    getUserDataById();
  }, [userId]);

  const getUserDataById = async () => {
    setLoading(true);
    let res = await UsersService?.getUserDataById(userId);
    if (res) {
      const voucherAccessList = res?.result?.accessSettings?.masterAccessList;
      const formattedData = voucherAccessList?.map((item: any) => ({
        masterType: masterType[item?.masterType],
        addOrEdit: item?.actions?.addOrEdit,
        delete: item?.actions?.delete,
        view: item?.actions?.view,
        print: item?.actions?.print,
        authorize: item?.actions?.authorize,
      }));

      form.setFieldsValue({
        items: formattedData,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const saveBasicDetailsData = () => {
    setBtnLoading(true);
    form.validateFields().then(async (val: any) => {
      console.log("val", val);
      let data = val?.items?.map((itm: any) => {
        let reco = {
          masterType: masterType.indexOf(itm?.masterType),
          actions: {
            authorize: itm?.authorize,
            addOrEdit: itm?.addOrEdit,
            delete: itm?.delete,
            view: itm?.view,
            print: itm?.print,
          },
        };
        return reco;
      });

      let res = await UsersService?.UpdateUserDetailsData(
        userId,
        { AccessSettings: { MasterAccessList: [...data] } },
        3
      );
      if (res?.status === true) {
        console.log("res", res);
        notification.success({
          message: "Success",
          description: "User details updated successfully.",
        });

        getUserDataById();
        setBtnLoading(false);
      } else {
        notification.error({
          message: "Error",
          description: res?.message,
        });

        setBtnLoading(false);
      }
    });
  };
  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Divider orientation="left" orientationMargin={0}>
          Master Security
        </Divider>
        <Spin spinning={loading} indicator={<LoadingOutlined />}>
          <Form
            form={form}
            labelAlign="left"
            layout="vertical"
            requiredMark={false}
            initialValues={{
              items: [{}],
            }}
          >
            <div style={{ minHeight: "74vh", overflowY: "scroll" }}>
              <table
                // border="1"
                // cellPadding="10"
                // cellSpacing="0"
                className="AddPageTabelDesign"
                style={{ width: "50%" }}
              >
                <thead>
                  <tr>
                    <th>Master Type</th>
                    <th>Add Or Edit</th>
                    <th>Delete</th>
                    <th>Print</th>
                    <th>View</th>
                    <th>Authorize</th>
                  </tr>
                </thead>
                <tbody>
                  <Form.List name="items">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <tr key={key}>
                            <td>
                              <Form.Item
                                className="m-0"
                                {...restField}
                                name={[name, "masterType"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Voucher Type",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Form.Item
                                className="m-0"
                                {...restField}
                                name={[name, "addOrEdit"]}
                                valuePropName="checked"
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Add/Edit Info",
                                  },
                                ]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Form.Item
                                className="m-0"
                                {...restField}
                                name={[name, "delete"]}
                                valuePropName="checked"
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Delete Info",
                                  },
                                ]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Form.Item
                                className="m-0"
                                {...restField}
                                name={[name, "print"]}
                                valuePropName="checked"
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Print Info",
                                  },
                                ]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Form.Item
                                className="m-0"
                                {...restField}
                                name={[name, "view"]}
                                valuePropName="checked"
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing View Info",
                                  },
                                ]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Form.Item
                                className="m-0"
                                {...restField}
                                name={[name, "authorize"]}
                                valuePropName="checked"
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing View Info",
                                  },
                                ]}
                              >
                                <Checkbox />
                              </Form.Item>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </Form.List>
                </tbody>
              </table>
              <Row
                style={{ margin: "10px 10px", marginRight: "117px" }}
                justify={"center"}
              >
                <Button
                  type="primary"
                  style={{ padding: "4px 30px" }}
                  onClick={saveBasicDetailsData}
                  loading={btnLoading}
                >
                  Update
                </Button>
              </Row>
            </div>
          </Form>
        </Spin>
      </div>
    </>
  );
};

export default MasterSecurity;
