import React from "react";
import { Route, Routes } from "react-router-dom";
// import BankAccounts from "./Banks/BankAccounts";
// import Cheque from "./Cheque";
// import LoanAccounts from "./LoanAccounts";
import CashInHandAllEffected from "./CashInHandAllEffected";
import BankAccountsAllEffected from "./Banks/BankAccountsAllEffected";
import BankReconciliation from "./BankReconciliation";
import Cheque from "./Cheque";

const BankingNestedRoute: React.FC = () => {
  return (
    <Routes>
      <Route>
        <Route path="/bankAccounts" element={<BankAccountsAllEffected />} />
        <Route path="/cashInHand" element={<CashInHandAllEffected />} />
        <Route path="/cheque" element={<Cheque />} />
        {/* <Route path="/loanAccounts" element={<LoanAccounts />} /> */}
        <Route path="/bankReconciliation" element={<BankReconciliation />} />
      </Route>
    </Routes>
  );
};
export default BankingNestedRoute;
