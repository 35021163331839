import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum ApplicationUserStatus {
  Active,
  Inactive,
  Deleted,
  All,
}
class ProductService {
  route = "v1/API/Acc_Products";

  getProductDetails = (
    start: number,
    length: number,
    search: string,
    status?: ApplicationUserStatus
  ) =>
    ApiUtility.get(
      `${this.route}/GetProductsList?start=${start}&lenth=${length}&search=${search}&status=${status}`
    );

  getDataById = (ProductId: string | undefined) =>
    ApiUtility.get(`${this.route}/${ProductId}`);

  deleteDataById = (ProductId: string | undefined) =>
    ApiUtility.delete(`${this.route}/${ProductId}`);

  postProductDetails = (ProductId: string | undefined, model: any) =>
    ApiUtility?.post(`${this.route}/${ProductId}`, model);
}

export default new ProductService();
