import { Button, Col, Divider, Form, Input, Modal, Row, Space } from "antd";
import phoneImg from "../../../images/phoneIcon.svg";
import emailImg from "../../../images/emailIcon.svg";
import { NameComponent } from "../../Common/CAPComponents";
import { useEffect } from "react";

const ContactPersonModel = ({
  open,
  setOpen,
  setItem,
  values,
}: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(values);
  }, [form, values]);

  const addContactPerson = () => {
    form
      .validateFields()
      .then((val) => {
        setItem(val);
        setOpen(false);
      })
      .catch((ex) => {
        console.log(ex);
      });
  };

  return (   
    <div>
      <Modal
        title="Add Contact Person"
        open={open}
        closable={false}
        centered
        width={440}
        footer={null}
      >
        <Form
          form={form}
          name="contactPerson"
          requiredMark={false}
          colon={false}
          autoComplete="off"
          size="small"
          layout="vertical"
        >
          <Form.Item name="contactName" className="inputBoxMb">
            <NameComponent
              value={form.getFieldValue("contactName")}
              label="Name"
              FormItemName={["contactName"]}
              innerLabel={true}
            />
          </Form.Item>
          <Row justify="space-between">
            <Col>
              <Form.Item
                name="phone"
                style={{ marginTop: "10px" }}
                className="inputBoxMb"
              >
                <Input
                  className="inputBgColor"
                  placeholder="0123456789"
                  prefix={<img src={phoneImg} alt="phone"></img>}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="email"
                style={{ marginTop: "10px" }}
                className="inputBoxMb"
              >
                <Input
                  className="inputBgColor"
                  placeholder="email@domail.com"
                  prefix={<img src={emailImg} alt="email"></img>}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: "13px 0" }} />
          <Row justify="end">
            <Space>
              <Button
                key="Cancel"
                onClick={() => {
                  form.resetFields();
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Form.Item className="inputBoxMb">
                <Button
                  type="primary"
                  className="themeBgColor"
                  onClick={() => addContactPerson()}
                >
                  Save
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default ContactPersonModel;
