import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./Store";

interface IIndState {
  indState: any[];
}
const initialState: IIndState = {
  indState: [],
};

const IndianStateSlice = createSlice({
  name: "IndianState",
  initialState,
  reducers: {
    setIndianState: (state, action) => {
      state.indState = action.payload;
    },
    resetIndianState: (state) => {
      Object.assign(state, initialState);
    },
  },
});


export const { setIndianState, resetIndianState } = IndianStateSlice.actions;

export const useIndianState = (state: RootState) => state.data.IndianState;

export default IndianStateSlice.reducer;
