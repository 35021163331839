import React from "react";
import { Route, Routes } from "react-router-dom";
import Income from "./Income";
import Category from "./Category";
import Expenses from "./Expenses";
const ExpensesIncomeNestedRoute: React.FC = () => {
  return (
    <>
      <Routes>
        <Route>
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/income" element={<Income />} />
          <Route path="/category" element={<Category />} />
        </Route>
      </Routes>
    </>
  );
};
export default ExpensesIncomeNestedRoute;
