/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Card, Col, Collapse, Row } from "antd";
import { DashboardChart, SalesCard } from "../../Common/DashboardChart";
import { useClient } from "../../Context/ClientContext";
import ExpensesInvoiceTable from "./ExpensesTable";
import { Utils } from "../../../Utilities/Utils";
import plusImg from "../../Images/plus.svg";
import equalImg from "../../Images/equal.svg";
const Expenses: React.FC = () => {
  const { activeKey } = useClient();
  const [expensesList, setExpensesList] = React.useState<any>([]);
  const [LoadSkeleton, setLoadSkeleton] = React.useState<boolean>(false);
  // console.log("chartdata", expensesList);
  const option = {
    tooltip: {
      trigger: "item",
    },

    color: ["#ADADC9", "#91cc75", "#fac858"],
    font: {
      size: 12,
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["45%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
          fontSize: 18,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          {
            value: 0,
            name: "No data Available",
          },
          {
            value: expensesList?.chartTotalRecieved,
            name: `Paid (${Utils.getFormattedNumber(
              (expensesList?.chartTotalRecieved * 100) /
                expensesList?.chartTotalTotalExpensesIncomeAmount
            )} %)`,
          },
          {
            value: expensesList?.chartTotalUnpaid,
            name: `Due (${Utils.getFormattedNumber(
              (expensesList?.chartTotalUnpaid * 100) /
                expensesList?.chartTotalTotalExpensesIncomeAmount
            )} %)`,
          },
        ],
      },
    ],
  };

  const yAxisFormatter = (value: any) => {
    const absValue = Math.abs(value);
    if (absValue >= 1e12) {
      return `${value / 1e12}t`; // trillion
    } else if (absValue >= 1e9) {
      return `${value / 1e9}b`; // billion
    } else if (absValue >= 1e6) {
      return `${value / 1e6}m`; // million
    } else if (absValue >= 1e3) {
      return `${value / 1e3}k`; // thousand
    }
    return value;
  };

  return (
    <>
      <div>
        <div
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            borderRadius: "10px",
            marginTop: 7,
            padding: "10px",
            marginLeft: "9px",
          }}
        >
          <Collapse className="customcssColl" activeKey={activeKey} ghost>
            <Collapse.Panel
              className="customcss"
              showArrow={false}
              key={"1"}
              header={<></>}
            >
              <Row gutter={[20, 36]}>
                <Col lg={5} xl={5}>
                  <div>
                    <DashboardChart
                      title={"Expenses Paid"}
                      paymentstatus2={"Overdue"}
                      amount={
                        // LoadSkeleton ? (
                        //   <AmountSkeleton />
                        // ) : (
                        Utils.getFormattedNumber(
                          expensesList?.chartTotalRecieved
                          // )
                        )
                      }
                      paymentstatus={"Due"}
                      option={""}
                    />
                  </div>
                </Col>
                <Col>
                  <img
                    className="Resp-signs"
                    src={plusImg}
                    style={{
                      marginTop: "60px",
                    }}
                  />
                  {/* <PlusOutlined
                    style={{
                      marginTop: "70px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  /> */}
                </Col>
                <Col lg={5} xl={5}>
                  <div>
                    <DashboardChart
                      title={"Expenses Due"}
                      paymentstatus2={"Paid"}
                      paymentstatus={"Outstanding"}
                      amount={
                        // LoadSkeleton ? (
                        //   <AmountSkeleton />
                        // ) : (
                        Utils.getFormattedNumber(
                          expensesList?.chartTotalUnpaid
                          // )
                        )
                      }
                      option={""}
                    />
                  </div>
                </Col>
                <Col>
                  <img
                    className="Resp-signs"
                    src={equalImg}
                    style={{
                      marginTop: "60px",
                    }}
                  />
                  {/* <PlusOutlined
                    style={{
                      marginTop: "70px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  /> */}
                </Col>

                <Col lg={5} xl={5}>
                  <SalesCard
                    title={" Total Expenses"}
                    paymentstatus2={"Overdue"}
                    amount={
                      // LoadSkeleton ? (
                      //   <AmountSkeleton />
                      // ) : (
                      Utils.getFormattedNumber(
                        expensesList?.chartTotalTotalExpensesIncomeAmount
                        // )
                      )
                    }
                    paymentstatus={"Due"}
                    option={option}
                  />
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </div>
        <Card
          size="small"
          className="TableCard"
          style={{
            width: "97%",
            marginLeft: "16px",
            marginTop: "30px",
            border: "1px solid #eef2fe",
          }}
        >
          <Row>
            <Col lg={24}>
              <ExpensesInvoiceTable
                expensesList={expensesList}
                setExpensesList={setExpensesList}
                LoadSkeleton={LoadSkeleton}
                setLoadSkeleton={setLoadSkeleton}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default Expenses;
