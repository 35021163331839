import { Button } from "antd";
import { useClient } from "../Context/ClientContext";
import { BiCollapseVertical, BiExpandVertical } from "react-icons/bi";

const ButtonComponent = () => {
  const { activeKey, setActiveKey } = useClient();
  return (
    <Button
      icon={activeKey === "1" ? <BiCollapseVertical /> : <BiExpandVertical />}
      onClick={() => {
        activeKey === "1" ? setActiveKey("") : setActiveKey("1");
      }}
    ></Button>
  );
};

export default ButtonComponent;
