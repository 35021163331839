import React, { useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  SelectProps,
  Modal,
  Form,
  Divider,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";

interface DataType {
  key: React.Key;
  Refno: string;
  shareholder: string;
  type: string;
  declarationdate: string;
  paymentdate: any;
  dividentamount: string;
  autojournals: string;
  icon: string;
  icon2: string;
  icon3: string;
}

const statusoptions: SelectProps["options"] = [
  { label: "All", value: "1" },
  { label: "Deleted", value: "2" },
];

const TypeOptions: SelectProps["options"] = [];

const Athorizeddirector: SelectProps["options"] = [
  { label: "JUPP,Rupert", value: "1" },
  { label: "SCIGLIANO,Lindsay Jane", value: "2" },
];

const columns: ColumnsType<DataType> = [
  {
    title: "Ref No.",
    dataIndex: "Refno",
  },
  {
    title: "Shareholder",
    dataIndex: "shareholder",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Declaration Date",
    dataIndex: "declarationdate",
  },
  {
    title: "Payment Date",
    dataIndex: "paymentdate",
  },
  {
    title: "Divident Amount",
    dataIndex: "dividentamount",
  },
  {
    title: "Auto Journals",
    dataIndex: "autojournals",
  },

  {
    title: "",
    dataIndex: "icon",
    render: (_, record) => (
      <div className="cursor mx-1">
        <EditOutlined className="cursor-pointer" />
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "icon2",
    render: (_, record) => (
      <div className="cursor mx-1">
        <DeleteOutlined color="red" className="cursor-pointer" />
      </div>
    ),
  },
];
const data: DataType[] = [];
for (let i = 0; i < 1; i++) {
  data.push({
    key: i,
    Refno: `001`,
    shareholder: `xyz`,
    type: `preference`,
    declarationdate: `05/05/2023`,
    paymentdate: `20/05/2023`,
    dividentamount: `2000`,
    autojournals: `xyz`,
    icon: `gp`,
    icon2: ` <></>`,
    icon3: `gp`,
  });
}
const Dividents: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [loading, setLoading] = useState(false);

  // const start = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // };

  // const handleOk = () => {
  //   form.validateFields().then((val) => {
  //     console.log(val);
  //   });
  // };
  const showModal = () => {
    setIsModalOpen(true);
  };


  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [multiplyvalue, setMultiplyvalue] = useState(0);
  const valuestore = (e: any) => {
    setMultiplyvalue(count2 * e.target.value);
    setCount(e.target.value);
    //   setCount2(e.target.value)
  };
  console.log(multiplyvalue);
  

  const valuestore2 = (e: any) => {
    let x = e.target.value * count;
    setCount2(e.target.value);
    setMultiplyvalue(x);
  };
  const [form] = Form.useForm();
  return (
    <div>
      <Row>
        <Col lg={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
            }}
          >
            <div>
              <Space size={5}>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#3D4998" }}
                  icon={<PlusOutlined />}
                  onClick={showModal}
                >
                  Dividend
                </Button>
              </Space>
            </div>

            <Modal
              okText={"Save"}
              width={550}
              title="Add Divident Voucher"
              open={isModalOpen}
              maskClosable={false}
              footer={false}
              closable={false}
            >
              <Form form={form}>
                <Row gutter={[18, 36]} style={{ color: "lightslategray" }}>
                  <Col lg={6}>
                    <p>
                      Ref. no.
                      <Form.Item>
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </p>
                  </Col>
                  <Col lg={9}>
                    <p>Authorized director</p>
                    <Form.Item
                      name="authorizeddirector"
                      rules={[{ required: true, message: "Director required" }]}
                    >
                      <Select
                        options={Athorizeddirector}
                        placeholder="Select..."
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={9}>
                    <p>Shareholder name</p>

                    <Form.Item
                      name="shareholdername"
                      rules={[
                        { required: true, message: "Shareholder required" },
                      ]}
                    >
                      <Select style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />

                <Row
                  gutter={[18, 36]}
                  style={{ color: "lightslategray", marginTop: "10px" }}
                >
                  <Col lg={8}>
                    <p>Period and date</p>
                    <Form.Item name="demo">
                      <DatePicker
                        placeholder="31/03/2023"
                        onChange={valuestore}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <p>Declaration date</p>
                    <DatePicker
                      placeholder="31/03/2023"
                      style={{ width: "100%" }}
                      onChange={valuestore2}
                    />
                  </Col>
                  <Col lg={8}>
                    <p>Payment date</p>
                    <DatePicker style={{ width: "100%" }} />
                  </Col>
                </Row>

                <Row gutter={[18, 36]} style={{ color: "lightslategray" }}>
                  <Col lg={8}>
                    <p>
                      Number of Shares.
                      <Form.Item
                        name="numberofshares"
                        rules={[{ required: true, message: "Number required" }]}
                      >
                        <Select
                          options={TypeOptions}
                          placeholder="Select"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </p>
                  </Col>
                  <Col lg={8}>
                    <p>Divident per share</p>
                    <Form.Item
                      name="dividentpershare"
                      rules={[
                        { required: true, message: "Can't be less than 0.00" },
                      ]}
                    >
                      <Input
                        placeholder="&#x20B9;0.00"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <p>Divident Amount</p>
                    <Form.Item>
                      <Input
                        placeholder="&#x20B9;0.00"
                        style={{ width: "100%", backgroundColor: "whitesmoke" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />

                <Row gutter={[18, 36]} style={{ color: "lightslategray" }}>
                  <Col lg={8}>
                    <p>
                      Type
                      <Form.Item
                        name="type"
                        rules={[{ required: true, message: "Type required" }]}
                      >
                        <Select
                          options={TypeOptions}
                          style={{ width: "100%" }}
                          placeholder="Select"
                        />
                      </Form.Item>
                    </p>
                  </Col>
                  <Col lg={8}>
                    <p>Class</p>
                    <Form.Item
                      name="class"
                      rules={[{ required: true, message: "Class required" }]}
                    >
                      <Select
                        options={Athorizeddirector}
                        style={{ width: "100%" }}
                        placeholder="Select"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8}></Col>
                </Row>

                <Divider />
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Form.Item>
                    <Button
                      style={{ marginRight: "9px" }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ backgroundColor: "rgb(61, 73, 152)" }}
                    >
                      <SaveOutlined /> Save
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Modal>

            <div style={{ display: "flex", justifyContent: "" }}>
              <Space>
                <div>
                  <span style={{ paddingRight: "2px" }}> Status</span>
                  <Select
                    options={statusoptions}
                    style={{ width: "120px", textAlign: "center" }}
                  ></Select>
                </div>
              </Space>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="custom"
        size="small"
        columns={columns}
        dataSource={data}
        style={{ overflowX: "auto" }}
        pagination={false}
      />
    </div>
  );
};
export default Dividents;
