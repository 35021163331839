import { Button, Drawer, message, Modal, Space, Spin } from "antd";
import React, { useContext, useRef, useState } from "react";
import DownloadService, { ViewType } from "../../Services/DownloadService";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import PrintPreview from "./PrintPreview";
import "../../Common/Acc_Common.css";
import { Voucher_Type } from "../../Services/VoucherServices";
import { HttpStatusCode } from "axios";

const ViewPreviewModel: React.FC<{
  open: boolean;
  onCancel: any;
  voucherId?: any;
  invVoucharType?: any;
  invoiceId?: any;
  partyId?: string;
}> = (props) => {
  const [file, setFile] = useState("");
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [type, setType] = useState<ViewType>(ViewType.Preview);
  const [previewData, setPreviewData] = useState<string>("");
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  //let file;
  console.log("This is the =>>", props?.invVoucharType);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  const handlePrint1 = useReactToPrint({
    content: () => componentRef.current,
  });
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  React.useEffect(() => {
    const downloadAndView = async (
      cliendId: string,
      voucherId: string,
      type: ViewType
    ) => {
      if (cliendId && voucherId) {
        if (type === ViewType.Pdf)
          await DownloadService.downloadPdf(cliendId, voucherId, type)
            .then((res) => {
              setDownloadLoading(false);
            })
            .catch((err) => {
              setDownloadLoading(false);
              openNotification("error", err.message);
            });
        else {
          setLoading(true);
          await DownloadService.previewPdf(
            cliendId,
            voucherId,
            type,
            props?.invVoucharType
          )
            .then((res: any) => {
              setFile(() => res);
              // if (res?.status) {
              //   setFile(() => res);
              // } else {
              //   openNotification("error", res?.message);
              // }
              // if (!res?.status) {
              //   openNotification("error", res?.message);
              // }
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      } else {
        // openNotification("error", );
        setLoading(false);
      }
    };

    downloadAndView(currentClient?.id!, props.voucherId, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, type]);

  const sendEmail = async (
    cliendId: string,
    voucherId: string,
    type: ViewType = ViewType.Pdf
  ) => {
    if (props?.partyId) {
      if (cliendId && voucherId) {
        await DownloadService.sendPdfEmail(
          cliendId,
          voucherId,
          type,
          props.partyId
        )
          .then((res) => {
            if (res.result) {
              openNotification("success", "Email sent successfully");
            } else {
              console.log("res", res);
              openNotification("error", res.message);
            }
            setEmailLoading(false);
          })
          .catch((err) => {
            setEmailLoading(false);
            openNotification("error", err.message);
          });
      } else {
        openNotification("error", "Invalid Party");
      }
    }
    setEmailLoading(false);
  };

  const handlePrint = () => {
    var css = `@page { size: A4 portrait; margin: 1.5cm} 
      @page {
      @top-center {
        content: none;
      }
      @bottom-center {
        content: none;
      }
      
    }`;
    var head = document.head || document.getElementsByTagName("head")[0];
    var style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";
    style.appendChild(document.createTextNode(css));

    head.appendChild(style);
    const printWindow = window.open("", "", "");
    if (printWindow) {
      // printWindow.document.write(
      //   "<title>Ledger Report</title>"
      // );
      printWindow.document.write(previewData);
      printWindow.print();
      printWindow.document.close();
    }
  };

  return (
    // <Modal
    //   open={props.open}
    //   width={"214.89mm"}
    //   title="Preview"
    //   onCancel={() => props.onCancel()}
    //   // closable={props.onCancel}
    //   style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
    //   centered
    //   footer={
    //     <div style={{ marginTop: "0px" }}>
    //       <Spin
    //         indicator={<LoadingOutlined />}
    //         spinning={loading}
    //         // style={{ marginBottom: "40px" }}
    //       >
    //         <Space>
    //           {props.partyId && (
    //             <Button
    //               type="primary"
    //               loading={emailLoading}
    //               onClick={() => {
    //                 setEmailLoading(true);
    //                 sendEmail(
    //                   currentClient?.id!,
    //                   props.voucherId,
    //                   ViewType.Pdf
    //                 );
    //               }}
    //             >
    //               Email Pdf
    //             </Button>
    //           )}
    //           <Button
    //             type="primary"
    //             onClick={() => {
    //               // handlePrint();
    //               handlePrint1();
    //             }}
    //           >
    //             Print
    //           </Button>
    //           <Button
    //             type="primary"
    //             loading={downloadLoading}
    //             onClick={() => {
    //               setType(ViewType.Pdf);
    //               setDownloadLoading(true);
    //             }}
    //           >
    //             Download
    //           </Button>
    //           <Button
    //             onClick={() => {
    //               props.onCancel();
    //             }}
    //           >
    //             Cancel
    //           </Button>
    //         </Space>
    //       </Spin>
    //     </div>
    //   }
    // >
    //   <PrintPreview data={previewData} ref={componentRef} />
    //   {/* <div dangerouslySetInnerHTML={{ __html: previewData }} /> */}
    // </Modal>
    <Drawer
      loading={loading}
      className="ca-drawer"
      open={props.open}
      style={{ padding: 0 }}
      width={"50%"}
      closable={false}
      extra={
        <>
          <Button
            size="small"
            // icon={}
            onClick={() => props.onCancel()}
          >
            <CloseOutlined />
          </Button>
        </>
      }
      title={"Preview"}
    >
      {file.length > 0 ? (
        <iframe
          title=""
          src={`data:application/pdf;base64, ${file}`}
          style={{
            flexGrow: 1,
            width: "100%",
            height: "100%",
            border: "none",
            overflow: "auto",
            padding: 0,
          }}
        />
      ) : (
        <h1>Please try again</h1>
      )}
    </Drawer>
  );
};

export default ViewPreviewModel;
