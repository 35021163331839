import { Col, Divider, Modal, Row, Button, Spin } from "antd";
import React, { useContext, useEffect } from "react";
import { PaymentMethodNames } from "../../Services/SalesService";
import { useClient } from "../../Context/ClientContext";
import noteContext from "../../Context/StyleContext";
import "../../Common/AddPageCommon.css";
import { LoadingOutlined } from "@ant-design/icons";
import { notificationContext } from "../../Common/PageRoute";
import { dayjs } from "../../../Utilities/dayjs";
import PurchaseService from "../../Services/PurchaseService";
import { NavLink } from "react-router-dom";
import ViewPurchaseInvoice from "./ViewPurchaseInvoice";

const ViewPaymentIOut: React.FC<{
  open: boolean;
  onCancel: any;
  paymentId: string | undefined;
}> = (props) => {
  const { windowWidth } = React.useContext(noteContext);
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    id: string;
  }>({
    type: "",
    id: "",
  });
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (props.paymentId) {
      setLoading(true);
      PurchaseService.getPaymentOutData(
        currentClient?.id!,
        props?.paymentId,
        "",
        true
      )
        .then((res: any) => {
          setLoading(false);
          if (res?.status) {
            const payment = res?.result?.paymentOut;
            console.log(payment);
            if (payment) {
              setData({
                supplier: payment?.supplier,
                number: payment?.number,
                date: dayjs(payment?.date).format("DD/MM/YYYY"),
                account: payment?.acc_Account?.name,
                method: payment?.method,
                amount: payment?.availableAmount,
                items: payment?.invoices,
                totalAmount: payment?.allocatedAmount,
                bankDts: payment?.bankDts,
              });
            }
          }
        })
        .catch((ex: any) => {
          openNotification("error", ex.message);
          setLoading(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.paymentId]);

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        width={1600}
        style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
        maskClosable={false}
        closable={false}
        onCancel={() => props.onCancel()}
        centered
        footer={
          <div style={{ marginTop: "0px" }} className="pe-15">
            <Button
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <div style={{ padding: 15 }}>
          <Spin indicator={antIcon} spinning={loading}>
            {windowWidth <= 1024 ? (
              <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
                Purchase Invoice
              </h3>
            ) : (
              ""
            )}
            <Row justify="space-between">
              <Col span={11} className="dashedBoxStyle">
                <Row gutter={[14, 10]}>
                  <Col span={10}>
                    <span className="themeColor fw-500">Paid To</span>
                    <div>
                      <NavLink
                        to={`/accounts/clients/${currentClient?.id}/parties/${data.supplier?.id}`}
                      >
                        {data.supplier?.name}
                      </NavLink>
                    </div>
                  </Col>
                  <Col span={7}>
                    <span className="themeColor fw-500">Payment No.</span>
                    <div>{data?.number}</div>
                  </Col>
                  <Col span={7}>
                    <span className="themeColor fw-500">Date</span>
                    <div>{data?.date}</div>
                  </Col>
                  <Col span={10}>
                    <span className="themeColor fw-500">Account</span>
                    <div>{data?.account}</div>
                  </Col>
                  <Col span={7}>
                    <span className="themeColor fw-500">Method</span>
                    <div>{PaymentMethodNames[data?.method]}</div>
                  </Col>
                  <Col span={7}>
                    <span className="themeColor fw-500">Amount</span>
                    <div>&#8377; {data?.amount}</div>
                  </Col>
                </Row>
              </Col>
              <Col span={4}>
                <div className="fs-35 fw-400 themeColor textEnd">
                  Payment Out
                </div>
              </Col>
            </Row>
            <table
              className="ca-viewmodel-table"
              style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "14%" }} className="textStart ps-10">
                    Invoice No.
                  </th>
                  <th style={{ width: "12%" }} className="textStart">
                    Invoice Date
                  </th>
                  <th className="textStart ps-6">Description</th>
                  <th style={{ width: "13%" }} className="textEnd">
                    Invoice Amount
                  </th>
                  <th style={{ width: "13%" }} className="textEnd">
                    Due Amount
                  </th>
                  <th style={{ width: "18%" }} className="textEnd pe-10">
                    Allocated Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.items?.map((item: any, i: any) => {
                  return (
                    <tr key={i}>
                      <td
                        className="ps-10"
                        style={{ cursor: "pointer", color: "#1677FF" }}
                        onClick={() =>
                          setOpenModel({
                            type: "viewPurchaseInvoice",
                            id: item?.purchaseInvoice?.id,
                          })
                        }
                      >
                        {item?.purchaseInvoice?.name}
                      </td>
                      <td>{dayjs(item?.allocatedOn).format("DD/MM/YYYY")}</td>
                      <td>{item?.description}</td>
                      <td className="textEnd">&#8377; {item?.invAmount}</td>
                      <td className="textEnd">&#8377; {item?.dueAmount}</td>
                      <td className="textEnd pe-10">
                        &#8377; {item?.allocatedAmount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <Row justify={"space-between"} className="dashedBorderTop">
              <Col span={11}>
                <div style={{ overflowY: "auto" }}>
                  <table className="ca-viewmodel-table">
                    <>
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>S No.</th>
                          <th
                            style={{
                              textAlign: "start",
                              paddingLeft: "8px",
                            }}
                          >
                            Select Account
                          </th>
                          <th
                            style={{
                              textAlign: "end",
                              paddingRight: "8px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.bankDts?.map((item: any, i: any) => {
                          return (
                            <tr key={i}>
                              <td style={{ textAlign: "center" }}>{i + 1}.</td>
                              <td>
                                <span>{item?.name}</span>
                              </td>
                              <td style={{ textAlign: "end" }}>
                                <span>{item?.amount}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  </table>
                </div>
              </Col>
              <Col span={6} className="mt-14">
                <Row justify="space-around">
                  <Col span={7}>Total receipt</Col>
                  <Col span={14} className="textEnd">
                    &#8377; {data?.totalAmount}
                  </Col>
                </Row>
                <Divider
                  style={{
                    borderBottom: "2px solid rgb(203, 203, 203)",
                  }}
                  className="my-10"
                />
              </Col>
            </Row>
          </Spin>
        </div>
      </Modal>
      {openModel.type === "viewPurchaseInvoice" && (
        <ViewPurchaseInvoice
          open={true}
          onCancel={() => setOpenModel({ type: "", id: "" })}
          invoiceId={openModel.id}
        />
      )}
    </React.Fragment>
  );
};
export default ViewPaymentIOut;
