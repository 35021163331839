import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum AssetStatus {
  All,
  Active,
  Deleted,
}

class FixedAssetsService {
  route = "/v1/api/Acc_FixedAsset";

  // Get Initial data and edit by id
  getFixedAsset = (clientId: string, isDispose?: boolean, id?: string) =>
    ApiUtility.get(
      `${this.route}/${clientId}/Get?${
        id ? `id=${id}&` : ""
      }isDispose=${isDispose}`
    );

  journalPost = (clientId: string, lastAccdate: string, params: any) =>
    ApiUtility.post(
      `${this.route}/${clientId}/Journal?lastAccdate=${lastAccdate}`,
      params
    );

  rollBackJournal = (
    clientId: string,
    id: string,
    rollback: boolean,
    jrnlIds?: string[]
  ) =>
    ApiUtility.get(
      `${this.route}/${clientId}/${id}/Rollback?rollback=${rollback}&${jrnlIds
        ?.map((id: any, i: number) => `jrnlIds=${id}`)
        .join("&")}`
    );
  //https://localhost:7142/v1/api/Acc_FixedAsset/22/11/Rollback?rollback=true&jrnlIds=33&jrnlIds=44

  PostFixedAssets = (clientId: string, params: any, id?: any) =>
    ApiUtility.post(`${this.route}/${clientId}${id ? "/" + id : ""}`, params);

  getFixedAssetsList = (
    clientId: string,
    start: number,
    length: number,
    sortCol: any,
    sortDir: string,
    search: string,
    status: AssetStatus,
    type?: string,
    lastAccDate?: string,
    isDispose?: boolean
  ) =>
    ApiUtility.getResult(`${this.route}/${clientId}`, {
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      type,
      lastAccDate,
      isDispose,
    });

  deleteAsset = (clientId: string, id: string, reactivate: boolean) =>
    ApiUtility.post(
      `${this.route}/${clientId}/${id}/Delete?reactivate=${reactivate}`,
      ""
    );

  searchAsset = (clientId: string, term?: string, length?: number) =>
    ApiUtility.get(`${this.route}/${clientId}/SearchAsset`, { term, length });

  postDispose = (clientId: string, id: string, params: any) =>
    ApiUtility.post(`${this.route}/${clientId}/${id}/Dispose`, params);

  reActivateAsset = (clientId: string, id: string) =>
    ApiUtility.post(
      `${this.route}/${clientId}/${id}/Activate`,
      id
    );

}
export default new FixedAssetsService();
