import { useContext, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import deleteImg from "../../../images/deletebutton.svg";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import "../../Common/Acc_Common.css";
import { Utils } from "../../../Utilities/Utils";
import SalesService, {
  CAPItemTypes,
  DeliveryChallanTypes,
  EstimateTypes,
  InvoiceTradeType,
  TradingPartnerType,
} from "../../Services/SalesService";
import { dayjs } from "../../../Utilities/dayjs";
import "../../Common/Acc_Common.css";
import "../../Common/AddPageCommon.css";
import noteContext from "../../Context/StyleContext";
import { notificationContext } from "../../Common/PageRoute";
import TextArea from "antd/es/input/TextArea";
import TcsModel from "./TcsModel";
import TdsModel from "./TdsModel";
import {
  CloseOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import AddParty from "../Parties/AddParty";
import AddItems from "../Purchase/AddItems";
import { returnNetAmount } from "./AddSalesInvoice";
import { percentCal, percentToRs } from "../../Common/CAPComponents";
import { useIndianState } from "../../Context/StateOptionsContext";
import PartyService from "../../Services/PartyService";
import React from "react";
import { AccountShort, ISelectGroup } from "../../../Types/CommonTypes";

const AddDeliveryChallan = () => {
  const navigate = useNavigate();
  const inputRef: any = useRef(null);
  const { id, partyId } = useParams();
  const { stateOptions } = useIndianState();
  const { windowWidth } = useContext(noteContext);
  const { currentClient, companySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [form] = Form.useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trigger, setTrigger] = useState(false);
  const [dueOn, setDueOn] = useState<number>(0);
  const [isGSTEnabled, setGstEnabled] = useState<boolean>(false);
  const [initialRender, seInitialRender] = useState<boolean>(false);
  const [totalTds, setTotalTds] = useState<number>(0);
  const [totalTcs, setTotalTcs] = useState<number>(0);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [tdsOrTcs, setTdsOrTcs] = useState<number>(0);
  const [currentCustomerState, setCurrentCustomerState] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [itemLength, setItemLength] = useState<number>(0);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [tcsModelOpen, setTcsModelOpen] = useState<boolean>(false);
  const [tdsModelOpen, setTdsModelOpen] = useState<boolean>(false);
  const [saveNewLoading, setSaveNewLoading] = useState<boolean>(false);
  const [openCustomerDrawer, setOpenCustomerDrawer] = useState<boolean>(false);
  const [editParty, setEditParty] = useState<any>({ id: null });
  const [searchItem, setSearchItem] = useState<string>("");
  const [deleteItemList, setDeleteItemList] = useState<number>(-1);
  const [newCustomer, setNewCustomer] = useState<any>();
  const [itemsOption, setItemsOption] = useState<any[]>([]);
  const [bankAccount, setBankAccount] = useState<any[]>([]);
  const [customerOptions, setCustomerOptions] = useState<any[]>([]);
  const [isOpenDueDate, setIsDueDate] = useState(false);
  const [deliveryChallanNo, setDeliveryChallanNo] = useState<any>("");
  const [searchCustomer, setSearchCustomer] = useState<{
    search: string;
    length: number;
  }>({
    search: " ",
    length: 15,
  });
  const [openItemModel, setOpenItemModel] = useState<any>({
    type: "",
    param: {},
  });
  const [newItem, setNewItem] = useState<any>({
    data: {},
    isSaved: false,
  });
  const [itemFields, setItemFields] = useState<any>({
    field: 0,
    index: 0,
    add: null,
  });
  const [presetsDueDate, setPresetsDueDate] = useState<any>({
    label: 0,
    value: "",
  });
  const [footerTotal, setFooterTotal] = useState({
    qty: 0,
    unitPrice: 0,
    sellPrice: 0,
    discount: 0,
    gst: 0,
    amount: 0,
  });

  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const isStateSame =
    currentCustomerState === currentClient?.registeredAddress?.state!;

  const amountInNumber = (val: any, key: any) =>
    Utils.getNumber(val?.amount?.[key]);

  const price = (curr: any) => amountInNumber(curr, "price");
  const qty = (curr: any) => amountInNumber(curr, "qty");
  const discount = (curr: any) => amountInNumber(curr, "discount");
  const taxRate = (curr: any) => Utils.getNumber(curr?.amount?.tax?.value);
  const netAmount = (curr: any) => price(curr) * qty(curr);
  const discountRupee = (curr: any) =>
    percentCal(netAmount(curr), discount(curr));
  const amountWithoutTax = (curr: any) => netAmount(curr) - discountRupee(curr);

  const taxRs = (curr: any) =>
    percentCal(amountWithoutTax(curr), taxRate(curr));

  const amountWithTax = (curr: any) => amountWithoutTax(curr) + taxRs(curr);

  //----  Initial Value Delivery Challan Get Api-----
  // useEffect(() => {
  //   if(currentClient?.id && !id) {
  //     setLoading(true);
  //     SalesService.initialValueDeliveryChallan(
  //       currentClient?.id!,
  //       DeliveryChallanTypes.DeliveryChallan
  //     )
  //       .then((res: any) => {
  //         setBankAccount(res?.result?.accounts[0]?.accounts);
  //         console.log("id............0", id);
  //         if (!id) {
  //           form.setFieldValue("number", res?.result?.number);
  //           setDeliveryChallanNo(res.result.number);
  //         }
  //         // setLoading(false);
  //       })
  //       .catch((ex: any) => {
  //         setLoading(false);
  //         console.error(ex);
  //       });
  //   }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [currentClient?.id]);

  useEffect(() => {
    inputRef?.current?.focus();
    if (currentClient?.id && !id) {
      setLoading(true);
      SalesService.getDeliveryChallan(
        currentClient?.id!,
        DeliveryChallanTypes.DeliveryChallan
      ).then((res) => {
        if (res?.status) {
          const challan = res?.result?.challan;
          setGstEnabled(res?.result?.isGstEnabled);
          setDueOn(challan?.dueOn?.duration);
          setDeliveryChallanNo(challan?.number);
          form.setFieldValue("number", challan?.number);
          form.setFieldValue("date", dayjs(challan?.date));
          form.setFieldValue("dueDate", dayjs(challan?.dueDate));

          if (res?.result?.accounts) {
            let acc: ISelectGroup[] = res?.result?.accounts?.map(
              (group: any) => ({
                label: `${group.typeName} (${group.groupName})`,
                options: group.accounts?.map((a: AccountShort) => ({
                  label: a.name,
                  value: a.id,
                })),
              })
            );
            setBankAccount([...acc]);
          }
          setLoading(false);
        } else setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, initialRender]);

  // -------- Add or Edit Delivery Challan Get API ---------
  useEffect(() => {
    if (id) {
      setLoading(true);
      SalesService.getDeliveryChallan(
        currentClient?.id!,
        DeliveryChallanTypes.DeliveryChallan,
        id
      )
        .then((res: any) => {
          if (res?.status) {
            const challan = res?.result?.challan;
            setFooterTotal({
              qty: challan?.amountSummary?.totalQty,
              unitPrice: challan?.amountSummary?.totalPrice,
              sellPrice: challan?.amountSummary?.sellPrice,
              discount: challan?.amountSummary?.totalDis,
              gst: challan?.amountSummary?.igst
                ? challan?.amountSummary?.igst
                : challan?.amountSummary?.sgst + challan?.amountSummary?.cgst,
              amount: challan.amountSummary?.totalInvoiceAmount,
            });
            setInvoiceTotal(challan.amountSummary?.totalInvoiceAmount);
            setItemLength(challan?.items.length);
            setDeliveryChallanNo(challan?.number);
            setCurrentCustomerState(challan.supplyState);
            setEditParty({ id: challan?.tradingPartner.id });
            setGstEnabled(res?.result?.isGstEnabled);

            if (res?.result?.accounts) {
              let acc: ISelectGroup[] = res?.result?.accounts?.map(
                (group: any) => ({
                  label: `${group.typeName} (${group.groupName})`,
                  options: group.accounts?.map((a: AccountShort) => ({
                    label: a.name,
                    value: a.id,
                  })),
                })
              );
              setBankAccount([...acc]);
            }
            const Items = challan?.items?.map((itm: any) => {
              return {
                ...itm,
                account: {
                  label: itm?.account?.name,
                  value: itm?.account?.id,
                },
                amount: {
                  ...itm?.amount,
                  tax: {
                    key: itm?.amount?.tax?.id,
                    label: itm?.amount?.tax?.name,
                    value: itm?.amount?.tax?.rate,
                  },
                },
              };
            });

            form.setFieldsValue({
              items: [
                ...Items,
                [{ amount: { price: 0.0, qty: 0 }, isDiscInPer: 0 }],
              ],
              phoneNo: challan?.phoneNo,
              number: challan?.number,
              billingAddress: challan?.billingAddress,
              shippingAddress: challan?.shippingAddress,
              supplyState: challan?.supplyState,
              receivedAmount: challan?.amountSummary?.receivedAmount,
              description: challan?.description,
              tradingPartner: {
                value: challan?.tradingPartner.id,
                label: challan?.tradingPartner.name,
              },
              date: dayjs(
                dayjs(challan?.date).format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ),
              dueDate: dayjs(
                dayjs(challan?.dueDate).format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ),
              explanation: challan?.explanation,
            });
            setLoading(false);
          } else setLoading(false);
        })
        .catch((ex: any) => {
          openNotification("error", ex.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // -------- Add or Edit Delivery Challan Post API ---------
  const addDeliveryChallan = (isSaveAndNew: boolean) => {
    form.validateFields().then(async (val) => {
      isSaveAndNew ? setSaveNewLoading(true) : setSaveButtonLoading(true);
      const Items = val?.items?.map((itm: any) => {
        return {
          ...itm,
          account: {
            id: itm?.account?.value,
            name: itm?.account?.label,
          },
          amount: {
            ...itm?.amount,
            tax: {
              id: itm?.amount?.tax?.key,
              name: itm?.amount?.tax?.label,
              rate: itm?.amount?.tax?.value,
            },
          },
        };
      });
      const deliveryChallan = {
        ...val,
        items: Items,
        tradingPartner: {
          id: val.tradingPartner?.value,
          name: val.tradingPartner?.label,
        },
        // Delivery Challan No. Duplicacy check
        isCustomNumber: deliveryChallanNo === val?.number ? false : true,
        date: dayjs(val?.date).format("DD/MM/YYYY"),
        dueDate: dayjs(val?.dueDate).format("DD/MM/YYYY"),
        totalQty: Utils.getFormattedNumber(footerTotal.qty),
        totalUnitPrice: Utils.getFormattedNumber(footerTotal.unitPrice),
        sellPrice: Utils.getFormattedNumber(footerTotal.sellPrice),
        totalDis: Utils.getFormattedNumber(footerTotal.discount),
        igst: !isStateSame && Utils.getFormattedNumber(footerTotal.gst),
        cgst: isStateSame && Utils.getFormattedNumber(footerTotal.gst / 2),
        sgst: isStateSame && Utils.getFormattedNumber(footerTotal.gst / 2),
        totalInvoiceAmount: Utils.getFormattedNumber(invoiceTotal),
        tradeType: InvoiceTradeType.Sales,
        isStateSame: isStateSame,
        DcType: EstimateTypes.Estimate,
      };
      console.log("sales invoice data", deliveryChallan);

      if (footerTotal.discount < footerTotal.sellPrice) {
        if (footerTotal.sellPrice > 0) {
          await SalesService.postDeliveryChallan(
            currentClient?.id!,
            deliveryChallan,
            id
          )
            .then((res) => {
              if (res?.status) {
                openNotification(
                  "success",
                  `Delivery Challan ${
                    id ? "Edit successfully" : "Add successfully"
                  }`
                );
                partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/parties/${editParty?.id}`
                    )
                  : isSaveAndNew
                  ? form.resetFields()
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/sales/deliveryChallan `
                    );

                setFooterTotal({
                  qty: 0,
                  unitPrice: 0,
                  sellPrice: 0,
                  discount: 0,
                  gst: 0,
                  amount: 0,
                });
                form.resetFields();
                setInvoiceTotal(0);
                setItemLength(0);
              } else if (res?.message) {
                setSaveButtonLoading(false);
                openNotification("error", res?.message);
              }
            })
            .catch((ex) => openNotification("error", ex));
          setSaveButtonLoading(false);
          setSaveNewLoading(false);
          isSaveAndNew && seInitialRender(!initialRender);
        } else {
          openNotification("error", "Selling price must be grated than 0");
          setSaveButtonLoading(false);
        }
      } else {
        openNotification("error", "discount should be less than selling price");
        setSaveButtonLoading(false);
      }
    });
  };

  //-------- All Items List-----------
  useEffect(() => {
    SalesService.searchItem(currentClient?.id!, searchItem, CAPItemTypes.Sales)
      .then((res) => {
        setItemsOption(res);
      })
      .catch((ex: any) => {
        console.error(ex);
      });
  }, [currentClient?.id, searchItem]);

  // ----- Serch Party(customer) Api ------
  useEffect(() => {
    SalesService.searchParties(
      currentClient?.id!,
      searchCustomer.search,
      TradingPartnerType.Customer,
      searchCustomer.length
    )
      .then((res: any) => {
        setCustomerOptions(res?.result);
      })
      .catch((ex: any) => {
        console.error(ex);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, searchCustomer.search, newCustomer]);

  // ----- Adding new customer ----------
  useEffect(() => {
    if (newCustomer) {
      form.setFieldValue("tradingPartner", {
        value: newCustomer?.id,
        label: newCustomer?.businessName,
      });
      form.setFieldValue("billingAddress", newCustomer?.billingAddress);
      form.setFieldValue("shippingAddress", newCustomer?.shippingAddress);
      form.setFieldValue("supplyState", newCustomer?.state);
      const defaultPhone = newCustomer?.contactInformation?.find(
        (item: any) => item?.isDefault === true
      );
      form.setFieldValue("phoneNo", defaultPhone?.phone);
      setCurrentCustomerState(newCustomer?.state);
      setEditParty({ id: newCustomer?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCustomer]);

  //------- Adding new item ------
  useEffect(() => {
    if (newItem?.isSaved && newItem?.data?.salesItem?.isActive) {
      // Set the new item's data into the form field
      const newItemFields = {
        name: newItem.data?.name,
        isService: newItem.data?.isService,
        isTaxable: newItem.data?.isTaxable,
        id: newItem.data?.id,
        description: newItem.data?.salesItem?.description,
        account: {
          label: newItem.data?.salesItem?.account?.name,
          value: newItem.data?.salesItem?.account?.id,
        },
        amount: {
          qty: 1,
          price: newItem.data?.isTaxable
            ? Utils.getFormattedNumber(
                (newItem.data?.salesItem?.price /
                  (100 + newItem.data?.taxRate.rate)) *
                  100
              )
            : newItem.data?.salesItem?.price,
          tax: {
            key: newItem.data?.taxRate?.id,
            label: newItem.data?.taxRate?.name,
            value: newItem.data?.taxRate?.rate,
          },
        },
      };

      // Update the current item at index itemFields.index
      setItemLength(itemFields.index + 1);
      form.setFieldValue(["items", itemFields.index], newItemFields);

      // If the current item index is at the end, add a new item with initial values
      if (itemFields.field.length === itemFields.index + 1) {
        form.setFieldValue(["items", itemFields.index + 1], {
          amount: { price: 0.0, qty: 0 },
          isDiscInPer: 0,
        });
      }

      // Prepare the new item data to be added to itemsOption
      const newItemData = {
        name: newItem.data?.name,
        isService: newItem.data?.isService,
        isTaxable: newItem.data?.isTaxable,
        id: newItem.data?.id,
        description: newItem.data?.salesItem?.description,
        account: {
          label: newItem.data?.salesItem?.account?.name,
          value: newItem.data?.salesItem?.account?.id,
        },
        quantity: 1,
        price: newItem.data?.isTaxable
          ? Utils.getFormattedNumber(
              (newItem.data?.salesItem?.price /
                (100 + newItem.data?.taxRate.rate)) *
                100
            )
          : newItem.data?.salesItem?.price,
        tax: {
          key: newItem.data?.taxRate?.id,
          label: newItem.data?.taxRate?.name,
          value: newItem.data?.taxRate?.rate,
        },
      };
      // Add the new item data to itemsOption
      setItemsOption([...itemsOption, newItemData]);
      // Call the function to handle the total values
      handleItemsNetAmmount(itemFields.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItem.data, newItem.isSaved]);

  const TdsOptions = [
    { label: "None", value: 0 },
    { label: "5 %", value: 5 },
    { label: "10 %", value: 10 },
    { label: "2 %", value: 2 },
    { label: "1 %", value: 1 },
  ];

  const handleDeleteRowButton = () => {
    handleItemsNetAmmount(0);
    setConfirmation(false);
  };

  // ----- Set customer to party Balance  ------
  useEffect(() => {
    if (partyId) {
      PartyService.getPartytDetailsById(currentClient?.id!, partyId!)
        .then((result: any) => {
          if (result) {
            form.setFieldsValue({
              phoneNo: result?.defaultContact?.phone,
              billingAddress: result?.bAddress,
              shippingAddress: result?.sAddress,
              receivedAmount: result.amountSummary?.receivedAmount,
              tradingPartner: {
                value: result?.id,
                label: result?.bName,
              },
            });
            setEditParty({ id: result?.id });
            setCurrentCustomerState(result?.state);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyId]);

  const CustomerSelect: any = async (value: any) => {
    const selectedCustomer = customerOptions.find(
      (ItemList: any) => ItemList.id === value.key
    );

    if (selectedCustomer) {
      setEditParty({ id: selectedCustomer?.id });
      form.setFieldValue("billingAddress", selectedCustomer?.billingAddress);
      form.setFieldValue("shippingAddress", selectedCustomer?.shippingAddress);
      form.setFieldValue("supplyState", selectedCustomer?.state);
      form.setFieldValue("phoneNo", selectedCustomer?.defaultContact?.phone);
      setCurrentCustomerState(selectedCustomer?.state);
    }
  };

  const ItemSelect: any = async (value: string, index: number) => {
    setItemLength(index + 1);
    const selectedItem = itemsOption?.find((Item: any) => Item?.id === value);
    const IsService = selectedItem.isService;
    if (selectedItem) {
      form.setFieldValue(["items", index], {
        name: selectedItem.name,
        isService: IsService,
        isTaxable: selectedItem.isTaxable,
        id: selectedItem.id,
        description: selectedItem.description,
        account: {
          label: selectedItem.account?.name,
          value: selectedItem.account?.id,
        },
        amount: {
          qty: selectedItem.quantity,
          price: selectedItem.isTaxable
            ? Utils.getFormattedNumber(
                (selectedItem.price / (100 + selectedItem.taxRate.rate)) * 100
              )
            : selectedItem.price,
          tax: {
            value: selectedItem.taxRate.rate,
            label: selectedItem.taxRate.name,
            key: selectedItem.taxRate.id,
          },
        },
      });
      handleItemsNetAmmount(index);
    }
  };

  const handleAllTheTotalValues = () => {
    const ItemList = form.getFieldValue(["items"]);

    //------- Total Row Calculations -------
    const Total = ItemList.reduce(
      (
        acc: {
          TotalQty: number;
          TotalUnitPrice: number;
          ItemSubTotal: number;
          TotalDiscount: number;
          Totalgst: number;
          TotalAmount: number;
        },
        curr: any
      ) => {
        acc.TotalQty += qty(curr);
        acc.TotalUnitPrice += price(curr);
        acc.ItemSubTotal += price(curr) * qty(curr);
        acc.TotalDiscount += amountInNumber(curr, "discountRupee");
        acc.Totalgst += amountInNumber(curr, "taxRs");
        acc.TotalAmount += amountInNumber(curr, "totalAmount");
        return acc;
      },
      {
        TotalQty: 0,
        TotalUnitPrice: 0,
        ItemSubTotal: 0,
        TotalDiscount: 0,
        Totalgst: 0,
        TotalAmount: 0,
      }
    );
    setFooterTotal({
      qty: Total.TotalQty,
      unitPrice: Total.TotalUnitPrice,
      sellPrice: Total.ItemSubTotal,
      discount: Total.TotalDiscount,
      gst: Total.Totalgst,
      amount: Total.TotalAmount,
    });

    // // ----- Calculate item's total amount with discount -----
    const AmountAfterDisc = ItemList.reduce((acc: number, curr: any) => {
      return (acc += amountWithoutTax(curr));
    }, 0);

    //----- Calculate TDS amount -----
    const TdsRate = form.getFieldValue("tdsRate");
    const TotalTds = percentCal(AmountAfterDisc, TdsRate);
    setTotalTds(TotalTds);

    //----- Calculate TCS amount -----
    const TcsRate = form.getFieldValue("tcsRate");
    const TotalTcs = percentCal(footerTotal.amount, TcsRate);
    setTotalTcs(TotalTcs);

    //----- Calculate invoice total (WITH GST AND DISCOUNT AND TDS) -----
    const InvoiceTotal = ItemList.reduce((acc: number, curr: any) => {
      return (acc +=
        amountInNumber(curr, "totalAmount") +
        (tdsOrTcs === 0
          ? percentCal(amountWithoutTax(curr), TdsRate)
          : tdsOrTcs === 1
          ? percentCal(footerTotal.amount, TcsRate)
          : 0));
    }, 0);
    setInvoiceTotal(InvoiceTotal);
  };

  const handleItemsNetAmmount = (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    if (currentIndexData) {
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discountRupee: Utils.getFormattedNumber(
            discountRupee(currentIndexData)
          ),
          taxRs: Utils.getFormattedNumber(taxRs(currentIndexData)),
          totalAmount: Utils.getFormattedNumber(
            amountWithTax(currentIndexData)
          ),
        },
      });
    }
    handleAllTheTotalValues();
  };

  const onDiscountAmountChange = (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    if (currentIndexData) {
      // Calculate the new values based on the discount change
      const newDiscountAmount = percentToRs(
        amountInNumber(currentIndexData, "discountRupee"),
        netAmount(currentIndexData)
      );
      const newTaxRs = percentCal(
        netAmount(currentIndexData) -
          amountInNumber(currentIndexData, "discountRupee"),
        taxRate(currentIndexData)
      );
      const newTotalAmount =
        netAmount(currentIndexData) -
        amountInNumber(currentIndexData, "discountRupee") +
        newTaxRs;

      // Update the form field with the new calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discount: Utils.getFormattedNumber(newDiscountAmount),
          taxRs: Utils.getFormattedNumber(newTaxRs),
          totalAmount: Utils.getFormattedNumber(newTotalAmount),
        },
      });

      // Call the function to handle the total values if needed
      handleAllTheTotalValues();
    }
  };

  const onItemsTotalAmountChange = async (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    // Calculate the denominator for various calculations
    const denominator =
      100 -
      discount(currentIndexData) +
      percentCal(100 - discount(currentIndexData), taxRate(currentIndexData));

    // Calculate the price based on the given formula
    const calculatedPrice =
      (amountInNumber(currentIndexData, "totalAmount") * 100) /
      (denominator * qty(currentIndexData));

    // Calculate the discountRupee based on the given formula
    const calculatedDiscountRupee =
      (amountInNumber(currentIndexData, "totalAmount") *
        discount(currentIndexData)) /
      denominator;

    // Calculate the taxRs based on the given formula
    const calculatedTaxRs =
      (amountInNumber(currentIndexData, "totalAmount") *
        percentCal(
          100 - discount(currentIndexData),
          taxRate(currentIndexData)
        )) /
      denominator;

    if (denominator > 0 && currentIndexData?.amount?.qty > 0) {
      // Update the form field values with the calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: Utils.getFormattedNumber(calculatedPrice),
          discountRupee: Utils.getFormattedNumber(calculatedDiscountRupee),
          taxRs: Utils.getFormattedNumber(calculatedTaxRs),
        },
      });
    } else {
      // Denominator is zero or negative, set all fields to 0
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: 0,
          discountRupee: 0,
          taxRs: 0,
          totalAmount: 0,
        },
      });
    }
    handleAllTheTotalValues();
  };

  return (
    <div>
      <Spin indicator={antIcon} spinning={loading}>
        <Form
          form={form}
          colon={false}
          autoComplete="off"
          name="diliveryChallan"
          initialValues={{
            tcsRate: 0,
            tdsRate: 0,
            items: [{ amount: { price: 0.0, qty: 0, totalAmount: 0 } }],
          }}
        >
          {windowWidth <= 1024 ? (
            <h3
              className="fw-500 themeColor textCenter"
              style={{ fontSize: "250px" }}
            >
              Delivery Challan
            </h3>
          ) : (
            ""
          )}
          <Row justify="space-between">
            <Col xl={17} lg={24} md={24} className="dashedBoxStyle">
              <Row gutter={24} className="ps-14" justify="space-between">
                <Col
                  lg={14}
                  md={11}
                  xs={23}
                  sm={23}
                  className="themeLightBgColor p-10"
                >
                  <Row gutter={[17, 4]}>
                    <Col lg={12}>
                      <Row justify="space-between">
                        <Col className="themeColor fw-500">Customer</Col>
                        {editParty.id && (
                          <Col className="fw-500">
                            <Tooltip title="View Customer">
                              <EyeOutlined
                                onClick={() => setOpenCustomerDrawer(true)}
                              />
                            </Tooltip>
                          </Col>
                        )}
                      </Row>

                      <Form.Item
                        name="tradingPartner"
                        className="m-0 selectPlaceholder selectedItem errormsg"
                        rules={[
                          { required: true, message: "Select a customer" },
                        ]}
                      >
                        <Select
                          showSearch
                          // allowClear
                          labelInValue
                          optionFilterProp="children"
                          placeholder="Party Name"
                          filterOption={false}
                          ref={inputRef}
                          // showArrow={false}
                          // notFoundContent={null}
                          className="selectedItem"
                          onSearch={(value: string) =>
                            setSearchCustomer({ search: value, length: 15 })
                          }
                          onClick={() =>
                            setSearchCustomer({ ...searchCustomer, search: "" })
                          }
                          onSelect={(value: any) => {
                            CustomerSelect(value);
                          }}
                          options={customerOptions?.map((value: any) => ({
                            value: value?.id,
                            label: value?.name,
                          }))}
                          dropdownRender={(customerOptions) => (
                            <>
                              {customerOptions}
                              <Divider style={{ margin: "5px 0px" }} />
                              <Button
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() => {
                                  setOpenCustomerDrawer(true);
                                  setEditParty({ id: null });
                                }}
                              >
                                New Customer
                              </Button>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Phone No.</span>
                      <Form.Item
                        name="phoneNo"
                        className="inputBoxMb"
                        rules={[
                          {
                            min: 10,
                            message: "Phone no. must be 10 digits",
                          },
                        ]}
                      >
                        <Input
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Billing Address</span>
                      <Form.Item name="billingAddress" className="inputBoxMb">
                        <TextArea rows={3} />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">
                        Shipping Address
                      </span>
                      <Form.Item name="shippingAddress" className="inputBoxMb">
                        <TextArea rows={3} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col lg={9} md={4} xs={23} sm={23}>
                  <Row gutter={[17, 4]}>
                    <Col lg={24}></Col>
                    <Col lg={12}>
                      <span
                        className="themeColor fw-500"
                        style={{ paddingTop: "7px" }}
                      >
                        Delivery Challan No.
                      </span>
                      <Form.Item name="number" className="mb-3">
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <span
                        className="themeColor fw-500"
                        style={{ paddingTop: "7px" }}
                      >
                        Place Of Supply
                      </span>
                      <Form.Item
                        name="supplyState"
                        className="mb-3"
                        rules={[
                          {
                            required: true,
                            message: "place of supply is required",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          allowClear
                          placeholder="Select State"
                          popupMatchSelectWidth={false}
                          placement="bottomLeft"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onSelect={(val) => setCurrentCustomerState(val)}
                          options={stateOptions?.map((x: any) => ({
                            value: x?.id,
                            label: x?.name,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Date</span>
                      <Form.Item
                        name="date"
                        className="m-0"
                        rules={[
                          {
                            required: true,
                            message: "Select Date",
                          },
                        ]}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          disabledDate={(current) =>
                            current && current > dayjs().endOf("day")
                          }
                          style={{ width: "100%" }}
                          allowClear={false}
                          onChange={(val) => {
                            if (dueOn) {
                              let dueDate = dayjs(val, "DD/MM/YYYY").add(
                                dueOn,
                                "day"
                              );
                              form.setFieldValue("dueDate", dueDate);
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Delivery Date</span>

                      <Form.Item
                        name="dueDate"
                        className="m-0"
                        rules={[
                          {
                            required: true,
                            message: "Select Date",
                          },
                        ]}
                      >
                        <DatePicker
                          format="DD/MM/YYYY"
                          disabledDate={(current) =>
                            current && current < dayjs().startOf("day")
                          }
                          allowClear={false}
                          style={{ width: "100%" }}
                          onClick={() => {
                            setIsDueDate((x) => !x);
                          }}
                          onChange={(e) =>
                            inputRef ? setIsDueDate(false) : setIsDueDate(true)
                          }
                          open={isOpenDueDate}
                          presets={
                            windowWidth >= 550
                              ? [
                                  {
                                    label: "Tomorrow",
                                    value: dayjs().add(1, "d"),
                                  },
                                  {
                                    label: "After 7 days",
                                    value: dayjs().add(7, "d"),
                                  },
                                  {
                                    label: "After 15 days",
                                    value: dayjs().add(15, "d"),
                                  },
                                  {
                                    label: "After 30 days",
                                    value: dayjs().add(30, "d"),
                                  },
                                  {
                                    label: "After 45 days",
                                    value: dayjs().add(45, "d"),
                                  },
                                  {
                                    label: "After 60 days",
                                    value: dayjs().add(60, "d"),
                                  },
                                  {
                                    label: "After 90 days",
                                    value: dayjs().add(90, "d"),
                                  },
                                  {
                                    label: "After 180 days",
                                    value: dayjs().add(180, "d"),
                                  },
                                  {
                                    label: "Due on Receipt",
                                    value: dayjs(),
                                  },
                                ]
                              : []
                          }
                          renderExtraFooter={() => (
                            <Space
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <InputNumber
                                placeholder="After"
                                style={{ width: "60px" }}
                                controls={false}
                                onChange={(e) =>
                                  setPresetsDueDate({
                                    ...presetsDueDate,
                                    label: e,
                                  })
                                }
                                onBlur={(e) => {
                                  e.relatedTarget === null
                                    ? setIsDueDate(false)
                                    : setIsDueDate(true);
                                }}
                                onClick={() =>
                                  inputRef.current && inputRef.current.focus()
                                }
                                ref={inputRef}
                              />
                              <Select
                                allowClear
                                onBlur={(e) => {
                                  e.relatedTarget === null
                                    ? setIsDueDate(false)
                                    : setIsDueDate(true);
                                }}
                                style={{ width: "100px" }}
                                onChange={(e: any) => {
                                  setPresetsDueDate({
                                    ...presetsDueDate,
                                    value: e,
                                  });
                                }}
                                options={[
                                  { value: "d", label: "Days" },
                                  { value: "w", label: "Weeks" },
                                  { value: "month", label: "Months" },
                                  { value: "year", label: "Years" },
                                ]}
                              />
                              <Tooltip title="Save" placement="bottom">
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setIsDueDate(false);
                                    form.setFieldValue(
                                      "dueDate",
                                      dayjs().add(
                                        presetsDueDate.label,
                                        presetsDueDate.value
                                      )
                                    );
                                  }}
                                  icon={<SaveOutlined />}
                                />
                              </Tooltip>
                              <Tooltip title="Cancel" placement="right">
                                <Button
                                  onClick={() => {
                                    setIsDueDate(false);
                                    form.setFieldValue(
                                      "dueDate",
                                      dayjs().add(0, "d")
                                    );
                                  }}
                                  icon={<CloseOutlined />}
                                />
                              </Tooltip>
                            </Space>
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {windowWidth <= 1024 ? (
              ""
            ) : (
              <Col
                xl={4}
                lg={7}
                md={7}
                className="fw-400 themeColor textEnd"
                style={{ fontSize: "25px" }}
              >
                Delivery Challan
              </Col>
            )}
          </Row>
          {/* className="tableWrapper" */}
          <div style={{ overflowY: "auto" }}>
            <table
              className="AddPageTabelDesign"
              style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  {/* <Affix offsetTop={0} style={{ width: "100%" }}> */}
                  <th colSpan={3} rowSpan={2} style={{ width: "7%" }}>
                    S No.
                  </th>
                  <th
                    rowSpan={2}
                    style={{
                      width: "11%",
                      borderLeft: "1px solid rgb(240, 245, 255)",
                    }}
                    className="textStart ps-6"
                  >
                    Item
                  </th>
                  <th
                    rowSpan={2}
                    className="textStart ps-6"
                    style={{ width: windowWidth <= 1024 ? "18%" : "" }}
                  >
                    Description
                  </th>
                  <th
                    rowSpan={2}
                    style={{ width: "10%" }}
                    className="textStart ps-6"
                  >
                    Account
                  </th>
                  <th
                    rowSpan={2}
                    style={{ width: "3%" }}
                    className="textStart ps-6"
                  >
                    Qty
                  </th>
                  <th
                    rowSpan={2}
                    style={{ width: "6%" }}
                    className="textStart ps-6"
                  >
                    Unit
                  </th>
                  <th
                    rowSpan={2}
                    style={{ width: "9%" }}
                    className="textEnd pe-6"
                  >
                    Unit Price
                  </th>
                  <th colSpan={2} style={{ width: "10%" }}>
                    Discount
                  </th>
                  {isGSTEnabled && (
                    <th colSpan={2} style={{ width: "10%" }}>
                      GST %
                    </th>
                  )}
                  <th
                    colSpan={2}
                    rowSpan={2}
                    style={{ width: "14%" }}
                    className="textEnd pe-32"
                  >
                    Total Amount
                  </th>
                  {/* </Affix> */}
                </tr>
                <tr>
                  <th style={{ width: "5%" }}>%</th>
                  <th style={{ width: "5%" }}>₹</th>
                  {isGSTEnabled && <th style={{ width: "6%" }}>%</th>}
                  {isGSTEnabled && <th style={{ width: "4%" }}>₹</th>}
                </tr>
              </thead>

              <tbody>
                <Form.List name="items">
                  {(fields, { add, remove, move }) => {
                    return (
                      <>
                        {confirmation && (
                          <ConfirmationModal
                            open={confirmation}
                            text={`Do you really want to delete this item "${form.getFieldValue(
                              ["items", deleteItemList, "name"]
                            )}" ?`}
                            onNo={() => setConfirmation(false)}
                            onYes={() => {
                              remove(deleteItemList);
                              handleDeleteRowButton();
                              setItemLength(fields.length - 2);
                            }}
                          />
                        )}
                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <tr key={key}>
                              <td>
                                {fields.length !== index + 1 && (
                                  <>
                                    <div className="ps-10">{index + 1}.</div>
                                  </>
                                )}
                              </td>
                              <td>
                                {fields.length !== index + 1 && (
                                  <Form.Item
                                    name={[name, "isService"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    {form.getFieldValue([
                                      "items",
                                      index,
                                      "isService",
                                    ]) ? (
                                      <Tooltip
                                        title="Service"
                                        placement="left"
                                        color="#1d39c4"
                                      >
                                        <Avatar
                                          style={{
                                            cursor: "pointer",
                                            verticalAlign: "middle",
                                            color: "#1d39c4",
                                            backgroundColor: "#f0f5ff",
                                            borderColor: "#adc6ff",
                                            fontWeight: "bold",
                                          }}
                                          size="small"
                                        >
                                          S
                                        </Avatar>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="Goods"
                                        placement="left"
                                        color="#389e0d"
                                      >
                                        <Avatar
                                          style={{
                                            cursor: "pointer",
                                            verticalAlign: "middle",
                                            color: "#389e0d",
                                            backgroundColor: "#f6ffed",
                                            borderColor: "#b7eb8f",
                                            fontWeight: "bold",
                                          }}
                                          size="small"
                                        >
                                          G
                                        </Avatar>
                                      </Tooltip>
                                    )}
                                  </Form.Item>
                                )}
                              </td>
                              <td>
                                {fields.length !== index + 1 && (
                                  <>
                                    <Form.Item
                                      name={[name, "isTaxable"]}
                                      className="m-0"
                                      {...restField}
                                    >
                                      {form.getFieldValue([
                                        "items",
                                        index,
                                        "isTaxable",
                                      ]) ? (
                                        <Tooltip
                                          title="With Tax"
                                          placement="right"
                                          color="#08979C"
                                        >
                                          <Avatar
                                            style={{
                                              cursor: "pointer",
                                              verticalAlign: "middle",
                                              color: "#08979C",
                                              backgroundColor: "#E6FFFB",
                                              borderColor: "#08979C",
                                              fontWeight: "bold",
                                            }}
                                            size="small"
                                          >
                                            T
                                          </Avatar>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          title="WithOut Tax"
                                          placement="right"
                                          color="#531DAB"
                                        >
                                          <Avatar
                                            style={{
                                              cursor: "pointer",
                                              verticalAlign: "middle",
                                              color: "#531DAB",
                                              backgroundColor: "#F9F0FF",
                                              borderColor: "#531DAB",
                                              fontWeight: "bold",
                                            }}
                                            size="small"
                                          >
                                            WT
                                          </Avatar>
                                        </Tooltip>
                                      )}
                                    </Form.Item>
                                  </>
                                )}
                              </td>

                              <td>
                                <Form.Item
                                  name={[name, "name"]}
                                  className="m-0"
                                  {...restField}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Search to items"
                                    optionFilterProp="children"
                                    filterOption={false}
                                    popupMatchSelectWidth={false}
                                    style={{ width: "152px" }}
                                    dropdownStyle={{ width: "250px" }}
                                    placement="bottomRight"
                                    onSearch={(value: string) =>
                                      setSearchItem(value)
                                    }
                                    onClick={() => {
                                      setSearchItem("");
                                      setNewItem({
                                        data: {},
                                        isSaved: false,
                                      });
                                    }}
                                    onChange={(value: any) => {
                                      if (fields.length === index + 1) add();
                                      ItemSelect(value, index);
                                    }}
                                    options={itemsOption?.map((itm: any) => ({
                                      value: itm?.id,
                                      label: itm?.name,
                                    }))}
                                    dropdownRender={(itemsOption) => (
                                      <>
                                        {itemsOption}
                                        <Divider
                                          style={{ margin: "5px 0px" }}
                                        />
                                        <Button
                                          style={{ width: "100%" }}
                                          icon={<PlusOutlined />}
                                          type="link"
                                          onClick={() => {
                                            setOpenItemModel({
                                              type: "addItem",
                                              param: {
                                                open: true,
                                              },
                                            });
                                            setNewItem({
                                              data: {},
                                              isSaved: false,
                                            });
                                            setItemFields({
                                              field: fields,
                                              index: index,
                                            });
                                          }}
                                        >
                                          New Item
                                        </Button>
                                      </>
                                    )}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "description"]}
                                >
                                  <Input />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  name={[name, "account"]}
                                  className="m-0"
                                  {...restField}
                                >
                                  <Select
                                    labelInValue
                                    showSearch
                                    placeholder="select"
                                    popupMatchSelectWidth={false}
                                    dropdownStyle={{ width: "250px" }}
                                    style={{ width: "140px" }}
                                    placement="bottomRight"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={bankAccount}
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "qty"]}
                                >
                                  <InputNumber
                                    min={0}
                                    controls={false}
                                    className="inputNumberTextEnd"
                                    onChange={() =>
                                      handleItemsNetAmmount(index)
                                    }
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "unit"]}
                                >
                                  <Select
                                    popupMatchSelectWidth={false}
                                    placement="bottomRight"
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "price"]}
                                >
                                  <InputNumber
                                    min={0}
                                    step={0.01}
                                    style={{ width: "100%" }}
                                    controls={false}
                                    className="inputNumberTextEnd"
                                    onChange={() =>
                                      handleItemsNetAmmount(index)
                                    }
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "discount"]}
                                >
                                  <InputNumber
                                    className="inputNumberTextEnd"
                                    controls={false}
                                    min={0}
                                    max={100}
                                    onChange={() =>
                                      handleItemsNetAmmount(index)
                                    }
                                  />
                                </Form.Item>
                              </td>
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "discountRupee"]}
                                >
                                  <InputNumber
                                    controls={false}
                                    className="inputNumberTextEnd"
                                    min={0}
                                    step={0.01}
                                    max={returnNetAmount(
                                      index,
                                      form.getFieldValue(["items"])
                                    )}
                                    onChange={() => {
                                      onDiscountAmountChange(index);
                                    }}
                                  />
                                </Form.Item>
                              </td>
                              {isGSTEnabled && (
                                <td>
                                  <Form.Item
                                    name={[name, "amount", "tax"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    <Select
                                      labelInValue
                                      placeholder="%"
                                      popupMatchSelectWidth={false}
                                      placement="bottomRight"
                                      onChange={() => {
                                        handleItemsNetAmmount(index);
                                      }}
                                      options={gstOption?.map((gst: any) => ({
                                        key: gst.id,
                                        value: gst.rate,
                                        label: gst.name,
                                      }))}
                                    />
                                  </Form.Item>
                                </td>
                              )}
                              {isGSTEnabled && (
                                <td>
                                  <Tooltip
                                    title={form.getFieldValue([
                                      "items",
                                      index,
                                      "amount",
                                      "taxRs",
                                    ])}
                                    placement="top"
                                  >
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "amount", "taxRs"]}
                                    >
                                      <InputNumber
                                        disabled
                                        className="inputNumberTextEnd"
                                      />
                                    </Form.Item>
                                  </Tooltip>
                                </td>
                              )}
                              <td>
                                <Form.Item
                                  className="m-0"
                                  {...restField}
                                  name={[name, "amount", "totalAmount"]}
                                >
                                  <InputNumber
                                    min={0}
                                    step={0.01}
                                    style={{ width: "100%" }}
                                    controls={false}
                                    className="inputNumberTextEnd"
                                    onChange={() => {
                                      onItemsTotalAmountChange(index);
                                    }}
                                  />
                                </Form.Item>
                              </td>
                              <td style={{ width: 25 }}>
                                {fields.length !== index + 1 && (
                                  <img
                                    className="cursor mx-1"
                                    onClick={() => {
                                      setDeleteItemList(name);
                                      setConfirmation(true);
                                    }}
                                    src={deleteImg}
                                    alt="Delete"
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  }}
                </Form.List>
              </tbody>
              {itemLength > 0 && (
                <tfoot>
                  <tr>
                    <th colSpan={5} className="textEnd pe-14">
                      Total
                    </th>
                    <th colSpan={2} className="textEnd pe-14">
                      {footerTotal.qty}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.unitPrice)}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                    </th>
                    {isGSTEnabled && (
                      <th colSpan={2} className="textEnd pe-12">
                        ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                      </th>
                    )}
                    <th className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                    </th>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>

          <Row className="dashedBorderTop pt-10">
            <Col lg={12} style={{ paddingTop: "15px" }}>
              <Form.Item name="description" className="m-0" label="Description">
                <Input />
              </Form.Item>
            </Col>
            <Col lg={6} offset={5}>
              <Row gutter={[0, 8]}>
                <Col lg={9}>Selling Price</Col>
                <Col lg={15} className="textEnd">
                  ₹ {Utils.getFormattedNumber(footerTotal.sellPrice)}
                </Col>
                <Col lg={9}>Discount</Col>
                <Col lg={15} className="textEnd">
                  ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                </Col>
                {isGSTEnabled && (
                  <>
                    {isStateSame ? (
                      <>
                        <Col lg={9}>CGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(footerTotal.gst / 2)}
                        </Col>
                        <Col lg={9}>SGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(footerTotal.gst / 2)}
                        </Col>
                      </>
                    ) : currentCustomerState ? (
                      <>
                        <Col lg={9}>IGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    <Col lg={15}>
                      <Row>
                        <Col lg={14}>
                          <Radio.Group
                            disabled
                            onChange={(e) => {
                              setTdsOrTcs(e.target.value);
                              handleAllTheTotalValues();
                            }}
                            value={tdsOrTcs}
                          >
                            <Radio value={0}>TDS</Radio>
                            <Radio value={1}>TCS</Radio>
                          </Radio.Group>
                        </Col>
                        <Col lg={10}>
                          {tdsOrTcs === 1 ? (
                            <Form.Item name="tcsRate" className="m-0">
                              <Select
                                disabled
                                size="small"
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <div
                                      className="themeColor ps-6"
                                      onClick={() => setTcsModelOpen(true)}
                                    >
                                      TCS Details
                                    </div>
                                  </>
                                )}
                                onSelect={() => handleAllTheTotalValues()}
                                options={[
                                  { value: 0, label: "None" },
                                  { value: 0.1, label: "0.1 %" },
                                ]}
                              />
                            </Form.Item>
                          ) : tdsOrTcs === 0 ? (
                            <Form.Item name="tdsRate" className="m-0">
                              <Select
                                disabled
                                size="small"
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <div
                                      className="themeColor ps-6"
                                      onClick={() => setTdsModelOpen(true)}
                                    >
                                      TDS Details
                                    </div>
                                  </>
                                )}
                                onChange={() => handleAllTheTotalValues()}
                                options={TdsOptions?.map((itm: any) => ({
                                  value: itm?.value,
                                  label: itm?.label,
                                }))}
                              />
                            </Form.Item>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {tdsOrTcs === 1 ? (
                      <Col lg={9} className="textEnd">
                        ₹ {Utils.getFormattedNumber(totalTcs)}
                      </Col>
                    ) : tdsOrTcs === 0 ? (
                      <Col lg={9} className="textEnd">
                        ₹ {Utils.getFormattedNumber(totalTds)}
                      </Col>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Row>
              <Divider className="my-5 grayBg" />
              <Row gutter={[12, 2]}>
                <Col lg={18}>Total Delivery Challan Value</Col>

                <Col lg={6} className="textEnd">
                  ₹ {Utils.getFormattedNumber(invoiceTotal)}
                </Col>
              </Row>
              <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              />

              <Row justify="end" className="mt-10">
                <Col>
                  <Button
                    onClick={() =>
                      partyId
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/parties/${editParty?.id}`
                          )
                        : navigate(
                            `/accounts/clients/${currentClient?.id}/sales/deliveryChallan`
                          )
                    }
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className="ps-14">
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={saveButtonLoading}
                      onClick={() => addDeliveryChallan(false)}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
                <Col className="ps-14">
                  <Form.Item>
                    <Button
                      loading={saveNewLoading}
                      onClick={() => {
                        addDeliveryChallan(true);
                      }}
                      type="primary"
                    >
                      Save & New
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        {tcsModelOpen && (
          <TcsModel
            isModalOpen={tcsModelOpen}
            setIsModalOpen={setTcsModelOpen}
          />
        )}
        {tdsModelOpen && (
          <TdsModel
            isModalOpen={tdsModelOpen}
            setIsModalOpen={setTdsModelOpen}
          />
        )}
        {/* -------------ADD Party ----------*/}
        {openCustomerDrawer && (
          <AddParty
            openDrawer={openCustomerDrawer}
            setTrigger={setTrigger}
            setOpenDrawer={setOpenCustomerDrawer}
            editParty={editParty}
            setNewPartie={setNewCustomer}
          />
        )}
        {/* -------------ADD Items ----------*/}
        {openItemModel.type === "addItem" && (
          <AddItems
            open={openItemModel?.param.open}
            onCancel={() => setOpenItemModel({ type: "", param: "" })}
            setTrigger={setTrigger}
            setNewItem={setNewItem}
          />
        )}
      </Spin>
    </div>
  );
};

export default AddDeliveryChallan;
