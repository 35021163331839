import { QueryClientProvider, QueryClient } from "react-query";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { UserProvider, useUser } from "./Context/UserContext";
import React, { useEffect, useRef, useState } from "react";
import { FullPageSpinner } from "./Common/FullPageSpinner";
import ResourceNotFound from "./CapsitechAccountsComponents/Common/ResourceNotFound";
import ThemeContextProvider from "./CapsitechAccountsComponents/Context/ThemeContext";
import ResetPassword from "./Common/Login/ForgotPassword";
import AdminContextState from "./Context/AdminContextState";
import StateProvider from "./CapsitechAccountsComponents/Context/StateOptionsContext";
import Login from "./Common/Login/Login";
import MainClientTable from "./CapsitechAccountsComponents/Common/MainClientTable";
import ForgotPassword from "./Common/Login/ForgotPassword";
import GeneralSettings from "./CapsitechAccountsComponents/Pages/GeneralSettings/GeneralSettings";
import AccountsMainLayout from "./CapsitechAccountsComponents/Common/MainLayout";
import UserProfile from "./CapsitechAccountsComponents/Common/UserProfile";
import Clients from "./CapsitechAccountsComponents/Pages/Clients/Clients";
import Gst from "./CapsitechAccountsComponents/Pages/GST/GstSalbs";
import { AuthService } from "./Services/AuthService";
import Users from "./CapsitechAccountsComponents/Pages/Users/Users";
import AddCustomer from "./CapsitechAccountsComponents/Pages/Users/UsersDetailsTabs";
import UsersDetailsTabs from "./CapsitechAccountsComponents/Pages/Users/UsersDetailsTabs";
import Customer from "./CapsitechAccountsComponents/Pages/Customer/Customer";
import Product from "./CapsitechAccountsComponents/Pages/Products/Product";
import TscList from "./CapsitechAccountsComponents/Pages/TcsTds/Tcs/TcsList";
import TdsList from "./CapsitechAccountsComponents/Pages/TcsTds/Tds/TdsList";

export const queryClient = new QueryClient();

const App = () => {
  const params = useParams();
  const { getUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isloggedIn, setIsLoggedIn] = React.useState<boolean>(
    false || JSON.parse(sessionStorage.getItem("state") + "")
  );
  const { isAuthenticated } = useUser();
  // React.useEffect(() => {
  //   const token = isloggedIn
  //     ? localStorage.getItem(`${window.location.hostname}|AO.Token`)
  //     : "";
  //   var splitPath = location.pathname.split("/");
  //   if (!token) {
  //     navigate("/login");
  //   } else if (
  //     isloggedIn &&
  //     (splitPath[1] === "login" || splitPath[1] === "")
  //   ) {
  //     navigate("/accounts");
  //   }
  // }, [navigate, isloggedIn]);
  let loginUser = { ...getUser() };

  const previousPath: any = useRef(null);
  useEffect(() => {
    const token = isloggedIn
      ? localStorage.getItem(`${window.location.hostname}|AO.Token`)
      : "";
    const splitPath = location.pathname.split("/");

    // Check if the user is logged in
    if (isloggedIn) {
      // If the user is logged in and the route is /login, navigate to /accounts
      if (splitPath[1] === "login" || splitPath[1] === "") {
        navigate("/accounts");
      }
    } else {
      // If the user is logged out, and the route is not /forgotpassword, navigate to /login
      if (splitPath[1] !== "forgotpassword") {
        navigate("/login");
      }
    }
    // If the user is logged out and the route is /forgotpassword, navigate to /forgotpassword
    if (!isloggedIn && splitPath[1] === "forgotpassword") {
      navigate("/forgotpassword");
    }

    // Update previousPath with the current path
    previousPath.current = location.pathname;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isloggedIn]);

  return (
    <AdminContextState>
      <UserProvider>
        <ThemeContextProvider>
          <QueryClientProvider client={queryClient}>
            {/* <React.Suspense> */}
            <Routes>
              <Route
                element={
                  <Login
                    setIsLoggedIn={setIsLoggedIn}
                    isloggedIn={isloggedIn}
                  />
                }
                path="/login"
              />
              {loginUser?.role === "SUPERADMIN" ? (
                <Route
                  element={
                    <MainClientTable
                      element={<Customer />}
                      breadcrumprops={[
                        {
                          item: "Customer",
                        },
                      ]}
                    />
                  }
                  path="/accounts"
                />
              ) : (
                <Route
                  element={
                    <MainClientTable
                      setIsLoggedIn={setIsLoggedIn}
                      isloggedIn={isloggedIn}
                      element={<Clients />}
                    />
                  }
                  path="/accounts"
                />
              )}
              <Route
                element={<ForgotPassword />}
                // path="/forgotpassword/:userId/:code/*"
                path="/forgotpassword"
              />

              {/* <Route path='/reset-password/:userId/:code' element={<ResetPassword />} /> */}
              {/* <Route
                  element={
                    <MainClientTable
                      element={<GeneralSettings />}
                      breadcrumprops={[
                        {
                          item: "GeneralSettings",
                        },
                      ]}
                    />
                  }
                  path="/accounts/generalsettings"
                /> */}
              <Route
                element={
                  <MainClientTable
                    element={<Gst />}
                    breadcrumprops={[
                      {
                        item: "Gst",
                      },
                    ]}
                  />
                }
                path="/accounts/gst"
              />

              <Route
                element={
                  <MainClientTable
                    element={<Users />}
                    breadcrumprops={[
                      {
                        item: "Users",
                      },
                    ]}
                  />
                }
                path="/accounts/users"
              />

              <Route
                element={
                  <MainClientTable
                    element={<Customer />}
                    breadcrumprops={[
                      {
                        item: "Customer",
                      },
                    ]}
                  />
                }
                path="/accounts/customer"
              />
              <Route
                element={
                  <MainClientTable
                    element={<Product />}
                    breadcrumprops={[
                      {
                        item: "Product",
                      },
                    ]}
                  />
                }
                path="/accounts/product"
              />
              <Route
                element={
                  <MainClientTable
                    element={<UsersDetailsTabs />}
                    breadcrumprops={[
                      {
                        item: "Users",
                      },
                    ]}
                  />
                }
                path="/accounts/users/userDetails"
              />
              {/* <Route
                element={<AsyncAccountsMainLayout />}
                path="/accounts/clients/:clientid/*"
              /> */}
              <Route
                element={<AccountsMainLayout />}
                path="/accounts/clients/*"
              />
              <Route
                element={
                  <MainClientTable
                    element={<UserProfile />}
                    breadcrumprops={[
                      {
                        item: "Profile",
                      },
                    ]}
                  />
                }
                path="/accounts/setting"
              />
              <Route
                element={
                  <MainClientTable
                    element={<TscList />}
                    breadcrumprops={[
                      {
                        item: "Tcs",
                      },
                    ]}
                  />
                }
                path="/accounts/Tcs"
              />
              <Route
                element={
                  <MainClientTable
                    element={<TdsList />}
                    breadcrumprops={[
                      {
                        item: "Tds",
                      },
                    ]}
                  />
                }
                path="/accounts/Tds"
              />
              {isAuthenticated() && (
                <Route
                  element={<ResourceNotFound pageHeight="100vh" />}
                  path="*"
                />
              )}
            </Routes>
            {/* </React.Suspense> */}
          </QueryClientProvider>
        </ThemeContextProvider>
      </UserProvider>
    </AdminContextState>
  );
};

export default App;
