import React, { useState } from "react";
import { Button, Modal, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import AddTcsTaxRate from "../TcsTds/Tcs/AddTcsTaxRate";

interface IPropsTcs {
  isModalOpen: boolean;
  setIsModalOpen: (value: any) => void;
}

interface DataType {
  key: React.Key;
  rate: string;
  nature: string;
  name: string;
}

const TcsModel = (props: IPropsTcs) => {
  const [newTcsOpen, setNewTcsOpen] = useState<boolean>(false);
 const [trigger, setTrigger] = React.useState<boolean>(false);
  const columns: ColumnsType<DataType> = [
    {
      title: "Tax Name",
      dataIndex: "name",
      width: "40%",
    },
    {
      title: "Rate (%)",
      dataIndex: "rate",
      width: "20%",
    },
    {
      title: "Nature of Collection",
      dataIndex: "nature",
      width: "40%",
    },
  ];
  return (
    <>
      <Modal
        maskClosable={false}
        title="Manage TCS"
        open={props.isModalOpen}
        width={600}
        // onOk={handleOk}
        onCancel={() => props.setIsModalOpen(false)}
      >
        <div className="textEnd">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setNewTcsOpen(true)}
          >
            New Tax Rate
          </Button>
        </div>
        <Table
          className="mt-14"
          size="small"
          columns={columns}
          // dataSource={tableData}
        />
      </Modal>
      <AddTcsTaxRate
        open={true}
        setOpen={() => setNewTcsOpen(true)}
        setTrigger={setTrigger}
      />
    </>
  );
};

export default TcsModel;
