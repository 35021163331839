import React, { useState } from "react";
import { Button, Modal, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import AddTdsTaxRate from "../TcsTds/Tds/AddTdsTaxRate";

interface IPropsTds {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

interface DataType {
  key: React.Key;
  name: string;
  rate: string;
  section: string;
}

const TdsModel = (props: IPropsTds) => {
  const [newTdsOpen, setNewTdsOpen] = useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);

  const columns: ColumnsType<DataType> = [
    {
      title: "Tax Name",
      dataIndex: "name",
      width: "40%",
    },
    {
      title: "Rate (%)",
      dataIndex: "rate",
      width: "20%",
    },
    {
      title: "Section",
      dataIndex: "section",
      width: "40%",
    },
  ];

  const tableData: DataType[] = [
    {
      key: 1,
      name: "Commision or Brokerage",
      rate: "5%",
      section: "Section 194 H",
    },
  ];

  return (
    <>
      <Modal
        maskClosable={false}
        title="Manage TDS"
        open={props.isModalOpen}
        width={600}
        // onOk={handleOk}
        onCancel={() => props.setIsModalOpen(false)}
      >
        <div className="textEnd">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setNewTdsOpen(true)}
          >
            New Tax Rate
          </Button>
        </div>
        <Table
          className="mt-14"
          size="small"
          columns={columns}
          dataSource={tableData}
        />
      </Modal>
      <AddTdsTaxRate
        open={true}
        setOpen={() => setNewTdsOpen(true)}
        setTrigger={setTrigger}
        // editData={openModel.param}
      />
    </>
  );
};

export default TdsModel;
