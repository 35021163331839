import React, { useState } from "react";
import {
  DownloadOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Row,
  DatePicker,
  Card,
  Spin,
  Tabs,
  Popover,
  Space,
} from "antd";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useLocation } from "react-router-dom";
import { dayjs } from "../../../Utilities/dayjs";
import { ReportSettings } from "../../Common/CAPComponents";
import { ITrialBalanceSettings } from "./Ageing";
import ReportsServiceNew, {
  Statements,
} from "../../Services/ReportsServiceNew";
import { useTheme } from "../../Context/ThemeContext";
import { Utils } from "../../../Utilities/Utils";
import GroupDetailsTree from "../Master/Group/AllDetailsGroupWise/GroupDetailsTree";
import InvoiceExportService, {
  generateProfitAndLossCSV,
  generateProfitAndLossExcel,
  generateProfitAndLossPDF,
} from "../../Services/InvoiceExportDetails";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const ProfitAndLossNew: React.FC<{ type: Statements }> = (props) => {
  const { currentClient, companySettings } = useClient();

  const [activeKey, setActiveKey] = useState("");
  const [accountsList, setAccountList] = useState<any[]>([]);
  const [tabData, setTabData] = useState<{ id: number; name: string }>();
  const [moreTabs, setMoreTabs] = useState<any[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  console.log("Company Setting :", companySettings);
  const [exportType, setExportType] = React.useState<number>(-1);

  const [filterDate, setFilterDate] = useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [settings, setSettings] = useState<ITrialBalanceSettings | undefined>();

  const { RangePicker } = DatePicker;
  const { themeData } = useTheme();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  console.log("Company Setting :", companySettings);
  const [exportProfitAndLossData, setExportProfitAndLossData] = React.useState<
    any[]
  >([]);

  // let SelStatement = props.type;

  // RemoveTabs
  const remove = (targetKey: TargetKey) => {
    const newPanes = moreTabs?.filter((pane) => pane.key !== targetKey);
    setMoreTabs(newPanes);
    if (newPanes.length && targetKey === activeKey) {
      setActiveKey("Trial Balance");
    }
    // setMoreTabs(newPanes);
  };

  // console.log("testCaseKey",activeKey );

  React.useEffect(() => {
    setSettings(companySettings?.setting?.tbSettings);
  }, [companySettings, shouldRefresh]);

  React.useEffect(() => {
    getProftAndLossList(currentClient?.id!, props.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    filterDate.from,
    filterDate.to,
    props.type,
    shouldRefresh,
  ]);

  const generateFooterRow = (columns: any) => {
    return columns?.map((col: any) => "");
  };

  let path = "Profit & Loss List";
  const columnsA = ["Particulars", "Amount"];

  const fieldMapping = {
    Particulars: (item: any) => {
      const name = item?.name || "";
      const childrenNames = Array.isArray(item?.children)
        ? item.children.map((itm: any) => itm?.name)
        : "";
      const lastChild = Array.isArray(item?.children)
        ? item.children.map((itm: any) => {
            if (Array.isArray(itm?.children)) {
              const lastGrandChild = itm.children[itm.children.length - 1];
              return lastGrandChild?.name;
            }
            return null;
          })
        : "";
      return childrenNames
        ? `${name}
         ${childrenNames}
         ${lastChild}`
        : name;
      // return name
      //   ? `${name}`
      //   : childrenNames
      //   ? `${childrenNames}`
      //   : lastChild
      //   ? `${lastChild}`
      //   : "";
    },
    Amount: (item: any) => {
      const mainAmount = item?.value || 0;
      const childrenAmounts = Array?.isArray(item?.children)
        ? item.children.map((itm: any) => {
            const childValue = itm?.value || 0;
            const grandChildAmounts = Array?.isArray(itm?.children)
              ? itm?.children?.map((grandChild: any) => grandChild?.value)
              : "";
            return grandChildAmounts
              ? `(${childValue})
                 ${grandChildAmounts}`
              : `${childValue}`;
          })
        : "";
      return childrenAmounts
        ? `${mainAmount}
         ${childrenAmounts}`
        : mainAmount;
    },
  };

  console.log(
    "fieldMapping",
    fieldMapping.Particulars,
    "111",
    fieldMapping.Particulars.name
    // fieldMapping.Particulars.name.childrenNames
  );

  // const exportCsv = (exportData: any[]) => {
  //   InvoiceExportService.generateCSV(
  //     exportData,
  //     columnsA,
  //     fieldMapping,
  //     generateFooterRow,
  //     "",
  //     "",
  //     path,
  //     currentClient?.businessName
  //   );
  //   setExportType(0);
  // };

  // let path = "Draft List";
  const exportCsv = (exportData: any[]) => {
    generateProfitAndLossCSV(
      exportData,
      currentClient?.businessName,
      filterDate,
      path
    );
  };

  // const exportPDF = (exportData: any[]) => {
  //   InvoiceExportService.generatePDF(
  //     exportData,
  //     currentClient?.businessName,
  //     columnsA,
  //     fieldMapping,
  //     generateFooterRow,
  //     "",
  //     path
  //   );
  //   setExportType(0);
  // };

  const exportPDF = (exportData: any[]) => {
    generateProfitAndLossPDF(
      exportData,
      currentClient?.businessName,
      filterDate,
      path
    );
  };

  // const exportExcel = async (exportData: any[]) => {
  //   await InvoiceExportService.generateExcel(
  //     exportData,
  //     currentClient?.businessName,
  //     columnsA,
  //     fieldMapping,
  //     generateFooterRow,
  //     "",
  //     path
  //   );
  //   setExportType(0);
  // };

  const exportExcel = async (exportData: any[]) => {
    generateProfitAndLossExcel(
      exportData,
      currentClient?.businessName,
      filterDate,
      path
    );
  };

  console.log("dataa", data);
  console.log("dataa", data);

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (data?.length > 0) {
              exportCsv(data);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (data?.length > 0) {
              exportPDF(data);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSL"
          onClick={() => {
            if (data?.length > 0) {
              exportExcel(data);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSL
        </Button>
      </div>
    </Space>
  );

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  const commonBar = () => (
    <Row>
      {/* <Button onClick={() => console.log("hello")}>Abc</Button> */}
      <Col lg={12}>
        <>
          <div className="themeColor fs-20 fw-600">
            {currentClient?.businessName}
          </div>
          <p style={{ color: "gray" }}>
            {location.pathname.split("/")[4] === "profitAndLossnew"
              ? "Statement of Profit and Loss (for the year ended"
              : "Balance Sheet (as on"}
            {" " +
              dayjs(companySettings?.period?.to).format("DD/MM/YYYY") +
              ")"}
          </p>
        </>
      </Col>
      <Col lg={12}>
        <Row justify={"end"} gutter={[8, 0]}>
          <Col>
            <RangePicker
              defaultValue={[
                dayjs(filterDate?.from, "DD/MM/YYYY"),
                dayjs(filterDate?.to, "DD/MM/YYYY"),
              ]}
              allowClear={false}
              format="DD/MM/YYYY"
              style={{ width: "240px", border: "1px solid #f1f3ff" }}
              onChange={(e: any) => {
                e === null
                  ? setFilterDate({})
                  : setFilterDate({
                      ...filterDate,
                      from: dayjs(e[0]).format("DD/MM/YYYY"),
                      to: dayjs(e[1]).format("DD/MM/YYYY"),
                    });
                moreTabs.length > 0 && setMoreTabs([]);
              }}
              presets={[
                {
                  label: "Today",
                  value: [dayjs().add(0, "d"), dayjs()],
                },
                {
                  label: "Last 7 Days",
                  value: getLast7Days(),
                },
                {
                  label: "Last 15 Days",
                  value: getLast15Days(),
                },
                {
                  label: "This Month",
                  value: getThisMonth(),
                },
                {
                  label: "Last Month",
                  value: getLastMonth(),
                },
                {
                  label: "Quarter 1",
                  value: getQuarterPreset(0),
                },
                {
                  label: "Quarter 2",
                  value: getQuarterPreset(3),
                },
                {
                  label: "Quarter 3",
                  value: getQuarterPreset(6),
                },
                {
                  label: "Quarter 4",
                  value: getQuarterPreset(9),
                },
                {
                  label: "This Year",
                  value: thisYear(),
                },
                {
                  label: "Last Year",
                  value: lastYear(),
                },
              ]}
            />
          </Col>
          <Col>
            {data?.length === 0 ? (
              <>
                <Button type="default" icon={<DownloadOutlined />} disabled>
                  Export
                </Button>
              </>
            ) : (
              <>
                <Popover content={content} placement="rightTop" trigger="hover">
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    // onClick={() => exportCsv()}
                  >
                    Export
                  </Button>
                </Popover>
              </>
            )}
          </Col>
          {/* <Col>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  icon={<SettingOutlined />}
                ></Button>
              </Col> */}
          <Col>
            <Button
              icon={<ReloadOutlined />}
              disabled={loading}
              onClick={() => setShouldRefresh(!shouldRefresh)}
            ></Button>
          </Col>
        </Row>
      </Col>
      <Divider style={{ margin: "0px" }} />
    </Row>
  );

  const initialItems = [
    {
      label: "Balance Sheet",
      key: "Balance Sheet",
      closable: false,
      children: (
        <div style={{ width: "80%" }} className="m-auto">
          {commonBar()}
          <Row>
            <Col span={24}>
              <Spin
                indicator={antIcon}
                spinning={loading}
                style={{ width: "100%" }}
              >
                <table width="100%" className="ca-viewmodel-table">
                  <thead>
                    <tr>
                      <th
                        rowSpan={2}
                        style={{
                          width: "90%",
                          paddingLeft: "10px",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Particulars
                      </th>
                      <th
                        style={{
                          width: "10%",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        {dayjs(companySettings?.period?.from)
                          .year()
                          .toString() +
                          "-" +
                          dayjs(companySettings?.period?.to)
                            .year()
                            .toString()
                            .substring(2)}
                      </th>
                    </tr>
                    <tr>
                      <th>{"Amount (₹)"}</th>
                    </tr>
                  </thead>
                  <thead></thead>
                  <tbody>
                    {data?.map((reco: any) => {
                      return (
                        <>
                          {reco?.name !== "" && reco?.value !== "" ? (
                            <>
                              {" "}
                              <tr
                                style={{
                                  lineHeight: "30px",
                                  background: "#f2f8fc",
                                }}
                              >
                                <td
                                  style={{
                                    width: "90%",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color: "#543043",
                                  }}
                                  // align={
                                  //   SelStatement === Statements.BalanceSheet
                                  //     ? "right"
                                  //     : "left"
                                  // }
                                >
                                  {reco?.name}
                                </td>
                                <td
                                  align="right"
                                  style={{
                                    width: "10%",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                    color: "#543043",
                                  }}
                                >
                                  {reco?.value !== 0
                                    ? Utils.getFormattedNumber(reco?.value)
                                    : ""}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <></>
                          )}
                          {reco?.children ? (
                            <>
                              {reco?.children?.map((creco: any) => {
                                return (
                                  <>
                                    <tr>
                                      <td
                                        style={{
                                          width: "90%",
                                          paddingLeft: "30px",
                                          fontWeight: "600",
                                          fontSize: "14px",
                                          color: "#193e5e",
                                        }}
                                      >
                                        {creco?.name}
                                      </td>
                                      <td
                                        align="right"
                                        style={{
                                          width: "10%",
                                          fontWeight: "600",
                                          fontSize: "14px",
                                          color: "#193e5e",
                                        }}
                                      >
                                        {Utils.getFormattedNumber(creco?.value)}
                                      </td>
                                    </tr>
                                    {creco?.children ? (
                                      <>
                                        {creco?.children?.map((greco: any) => {
                                          return (
                                            <tr>
                                              <td
                                                style={{
                                                  width: "90%",
                                                  paddingLeft: "60px",
                                                }}
                                              >
                                                {/* <NavLink
                                                to={`/accounts/clients/${currentClient?.id}/master/group/${greco?.id}`}
                                              > */}
                                                <div
                                                  onClick={() =>
                                                    openNewTab(
                                                      greco?.id,
                                                      greco?.name
                                                    )
                                                  }
                                                >
                                                  {greco?.name}
                                                </div>
                                                {/* </NavLink> */}
                                              </td>
                                              <td
                                                align="right"
                                                style={{ width: "10%" }}
                                              >
                                                {Utils.getFormattedNumber(
                                                  greco?.value
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </Spin>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const updatedItems = initialItems?.concat(
    moreTabs?.map((tab: any) => ({
      label: tab.label,
      key: tab.key,
      closable: true,
      children: (
        <div style={{ width: "100%" }} className="m-auto">
          <GroupDetailsTree
            groupId={tab.key}
            groupName={tab.label}
            filterFromDate={filterDate.from}
            filterToDate={filterDate.to}
            // filterFromDate={}
          />
        </div>
      ),
    }))
  );

  React.useEffect(() => {
    setSettings(companySettings?.setting?.tbSettings);
  }, [companySettings, shouldRefresh]);

  React.useEffect(() => {
    getProftAndLossList(currentClient?.id!, props.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    filterDate.from,
    filterDate.to,
    props.type,
    shouldRefresh,
  ]);

  const getProftAndLossList = async (
    clientId: string,
    statements: Statements
  ) => {
    if (statements && clientId) {
      if (exportType < 1) {
        setLoading(true);
      }
      await ReportsServiceNew.getFinalStatement(clientId, statements)
        .then((res: any) => {
          setData(res);
          setLoading(false);
          // if (exportType >= 1) {
          //   setExportProfitAndLossData(res);
          //   if (exportType === 1) {
          //     exportCsv(res);
          //   } else if (exportType === 2) {
          //     exportPDF(res);
          //   } else if (exportType === 3) {
          //     exportExcel(res);
          //   }
          // } else {
          //   setLoading(false);
          //   setData(data);
          // }
        })
        .catch((err: any) => {
          console.error("err..", err.message);
          setLoading(false);
        });
    }
  };

  const openNewTab = (id: string, name: string) => {
    const newActiveKey = id;
    if (moreTabs.find((x: any) => x.key === newActiveKey)) {
      setActiveKey(newActiveKey);
    } else {
      setMoreTabs([
        ...moreTabs,
        {
          label: `${name}`,
          children: "New Tab Pane",
          key: newActiveKey,
        },
      ]);
      setActiveKey(newActiveKey);
    }
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <div className="px-16">
        {moreTabs.length > 0 ? (
          <Tabs
            hideAdd
            type="editable-card"
            // className="tabFlexOverride"
            onEdit={remove}
            activeKey={activeKey}
            items={updatedItems}
            destroyInactiveTabPane={false}
            onChange={(key) => setActiveKey(key)}
            tabBarExtraContent={
              <Button
                className="ms-10"
                onClick={() => {
                  setMoreTabs([]);
                  setTabData(undefined);
                  setActiveKey("");
                }}
              >
                Close All
              </Button>
            }
          />
        ) : (
          <>
            <div style={{ width: "80%" }} className="m-auto">
              {commonBar()}
              <Row>
                <Col span={24}>
                  <Spin
                    indicator={antIcon}
                    spinning={loading}
                    style={{ width: "100%" }}
                  >
                    <table width="100%" className="ca-viewmodel-table">
                      <thead>
                        <tr>
                          <th
                            rowSpan={2}
                            style={{
                              width: "90%",
                              paddingLeft: "10px",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            Particulars
                          </th>
                          <th
                            style={{
                              width: "10%",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            {dayjs(companySettings?.period?.from)
                              .year()
                              .toString() +
                              "-" +
                              dayjs(companySettings?.period?.to)
                                .year()
                                .toString()
                                .substring(2)}
                          </th>
                        </tr>
                        <tr>
                          <th>{"Amount (₹)"}</th>
                        </tr>
                      </thead>
                      <thead></thead>
                      <tbody>
                        {data?.map((reco: any) => {
                          return (
                            <>
                              {reco?.name !== "" && reco?.value !== "" ? (
                                <>
                                  {" "}
                                  <tr
                                    style={{
                                      lineHeight: "30px",
                                      background: "#f2f8fc",
                                    }}
                                  >
                                    <td
                                      style={{
                                        width: "90%",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        color: "#543043",
                                      }}
                                      // align={
                                      //   SelStatement === Statements.BalanceSheet
                                      //     ? "right"
                                      //     : "left"
                                      // }
                                    >
                                      {reco?.name}
                                    </td>
                                    <td
                                      align="right"
                                      style={{
                                        width: "10%",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        color: "#543043",
                                      }}
                                    >
                                      {reco?.value !== 0
                                        ? Utils.getFormattedNumber(reco?.value)
                                        : ""}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )}
                              {reco?.children ? (
                                <>
                                  {reco?.children?.map((creco: any) => {
                                    return (
                                      <>
                                        <tr>
                                          <td
                                            style={{
                                              width: "90%",
                                              paddingLeft: "30px",
                                              fontWeight: "600",
                                              fontSize: "14px",
                                              color: "#193e5e",
                                            }}
                                          >
                                            {creco?.name}
                                          </td>
                                          <td
                                            align="right"
                                            style={{
                                              width: "10%",
                                              fontWeight: "600",
                                              fontSize: "14px",
                                              color: "#193e5e",
                                            }}
                                          >
                                            {Utils.getFormattedNumber(
                                              creco?.value
                                            )}
                                          </td>
                                        </tr>
                                        {creco?.children ? (
                                          <>
                                            {creco?.children?.map(
                                              (greco: any) => {
                                                return (
                                                  <tr>
                                                    <td
                                                      style={{
                                                        width: "90%",
                                                        paddingLeft: "60px",
                                                      }}
                                                    >
                                                      {/* <NavLink
                                                to={`/accounts/clients/${currentClient?.id}/master/group/${greco?.id}`}
                                              > */}
                                                      <div
                                                        onClick={() =>
                                                          openNewTab(
                                                            greco?.id,
                                                            greco?.name
                                                          )
                                                        }
                                                      >
                                                        {greco?.name}
                                                      </div>
                                                      {/* </NavLink> */}
                                                    </td>
                                                    <td
                                                      align="right"
                                                      style={{ width: "10%" }}
                                                    >
                                                      {Utils.getFormattedNumber(
                                                        greco?.value
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </Spin>
                </Col>
              </Row>
            </div>

            {/* customize setting modal */}
            {isModalOpen && (
              <ReportSettings
                isOpen={isModalOpen}
                setOpen={setIsModalOpen}
                type="PnL"
                value={settings}
                refresh={shouldRefresh}
                setRefresh={setShouldRefresh}
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default ProfitAndLossNew;
