import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import AddCategoryModel from "./AddCategoryModel";
import { useTheme } from "../../Context/ThemeContext";
import CategoryService from "../../Services/CategoryService";
import { useClient } from "../../Context/ClientContext";
import search from "../../Images/search.svg";
import "../../Common/Acc_Common.css";
import "../../Common/AddPageCommon.css";

interface ICategoryListData {
  key: string;
  sno: number;
  categoryName: string;
  natureType: string;
  categoryType: string;
  status: string;
}

const Category = () => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabelData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [editCategory, setEditCategory] = React.useState<any>();
  const [newItem, setNewItem] = React.useState<any>();

  // const [openModel, setOpenModel] = React.useState<any>({
  //   type: "",
  //   param: {},
  // });


  const columns: ColumnsType<ICategoryListData> = [
    {
      title: "S.NO",
      dataIndex: "sno",
      width: "4%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Name",
      dataIndex: "categoryName",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.categoryName}
        </Skeleton>
      ),
    },
    {
      title: "Category Type",
      dataIndex: "categoryType",
      width: "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.categoryType}
        </Skeleton>
      ),
    },
    {
      title: "Nature Type",
      dataIndex: "natureType",
      width: "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.natureType}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: "8%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Tooltip title="Edit" placement="top" color="#3D4998">
            <EditOutlined
              // className="ca-edit-btn"
              style={{ color: "#444653" }}
              onClick={async () => {
                setEditCategory(record);
                setIsModalOpen(true);
                console.log("record_data", editCategory);
              }}
            />
          </Tooltip>
        </Skeleton>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    getCategoryList(
      0,
      (page - 1) * pageSize,
      pageSize * page,
      undefined,
      undefined,
      searchItem,
      undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, trigger, searchItem]);

  const getCategoryList = async (
    type: number,
    start: any,
    length: any,
    sortCol: any,
    sortDir: any,
    search: any,
    status: any
  ) => {
    await CategoryService.getCategoryList(
      currentClient?.id,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status
    )
      .then((res: any) => {
        if (res != null || res?.items?.length > 0) {
          console.log(res, "rescategorydata");
          setTableData(
            res?.items?.map((r: any, i: any) => {
              return {
                key: i,
                sno: i + 1,
                id: r?.id,
                categoryName: r?.name,
                categoryType: r?.category === 1 ? "Expenses" : "Income",
                natureType: r?.type === 0 ? "Direct" : "InDirect",
                accountType: r?.accountType,
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setTableData([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((err: any) => {
        console.log("error", err);
      });
    setLoading(false);
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        width: "97%",
        marginLeft: "16px",
        marginTop: "24px",
        border: "1px solid #eef2fe",
      }}
    >
      <Row
        justify="space-between"
        style={{ marginTop: 10, padding: "10px 18px 3px 18px" }}
      >
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalOpen(true);
              setEditCategory({ id: null });
            }}
          >
            Category
          </Button>
        </Col>
        <Col>
          <Space>
            <Input
              style={{ width: "200px", border: "1px solid #f1f3ff" }}
              placeholder="Search Expenses"
              allowClear
              onChange={(e: any) => {
                setLoading(true);
                if (!e.cancelable) {
                  //----- Search Api call in 500 ms -----
                  const searchItem = setTimeout(() => {
                    setPage(1);
                    setSearchItem(e.target.value);
                  }, 500);
                  return () => clearTimeout(searchItem);
                } else {
                  setSearchItem(e?.target?.value?.trim());
                }
              }}
              suffix={loading || searchItem ? "" : <img alt="Search.." src={search} />}
            />
            <Select
              style={{ width: "100px" }}
              defaultValue={"Both"}
              options={[
                { value: 1, label: "Expenses" },
                { value: 2, label: "Income" },
                { value: "all", label: "Both" },
              ]}
              onChange={(e: any) => {
                console.log(e);
                setLoading(true);
                setPage(1);
                getCategoryList(
                  e,
                  (page - 1) * pageSize,
                  pageSize,
                  undefined,
                  undefined,
                  searchItem,
                  undefined
                ).then((res) => {
                  setLoading(false);
                });
              }}
            />
          </Space>
        </Col>
      </Row>
      {/*-------- Add Category Tabel --------*/}
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        size="small"
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as ICategoryListData[])
            : tabelData
        }
        columns={columns}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}- ${page[1]} of ${totalRecords} items`,
        }}
        scroll={tabelData.length > 10 ? { x: 700, y: 620 } : { x: 600 }}
      />
      {/*-------- Add Category model --------*/}
      {isModalOpen && (
        <AddCategoryModel
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setTrigger={setTrigger}
          editCategory={editCategory}
          setNewCategory={setNewItem}
        />
      )}
    </Card>
  );
};
export default Category;
