import { LoadingOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useContext } from "react";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import FixedAssetsService from "../../Services/FixedAssetsService";
import { notificationContext } from "../../Common/PageRoute";

interface IAssetJournalModel {
  modal: {
    open: boolean;
    data: any;
  };
  setModal: any;
  success: boolean;
  setSuccess: any;
  isCustomJrnls?: boolean;
}

interface IDataType {
  key: number;
  id: string;
  name: string;
  date: string;
}

const AssetJournalModal = (props: IAssetJournalModel) => {
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [checkedKeys, setCheckedKeys] = React.useState<any[]>(
    props?.isCustomJrnls ? [props.modal?.data?.jrNumber[0]?.id] : []
  );

  const rowSelect = props?.isCustomJrnls
    ? {
        selectedRowKeys: checkedKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
          setCheckedKeys(selectedRowKeys);
        },
      }
    : undefined;

  console.log("checkedKeys", checkedKeys);

  React.useEffect(() => {
    setLoading(true);
    const tableData = props.modal?.data?.journals?.map((itm: any) => {
      return {
        ...itm,
        key: itm?.id,
      };
    });
    setData(tableData);
    setLoading(false);
  }, [props.modal?.data]);

  const cols: ColumnsType<IDataType> = [
    {
      title: "Number",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (x) => <div>{dayjs(x).format("DD/MM/YYYY")}</div>,
    },
  ];

  console.log(props.modal?.data?.id);

  const DeleteJournal = () => {
    FixedAssetsService.rollBackJournal(
      currentClient?.id!,
      props.modal?.data?.id,
      true,
      checkedKeys
    )
      .then((res) => {
        if (res?.status) {
          openNotification("success", "Journal Rollback Successfully");
          props.setModal({ open: false, data: {} });
          props.setSuccess(!props.success);
        }
      })
      .catch((ex) => {
        openNotification("error", ex);
      });
  };

  return (
    <Modal
      title="Asset Journals"
      maskClosable={false}
      open={props.modal?.open}
      onCancel={() => props.setModal({ open: false, data: {} })}
      footer={
        <Space>
          <Button onClick={() => props.setModal({ open: false, data: {} })}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={
              props?.isCustomJrnls
                ? checkedKeys.length > 0 && checkedKeys[0]
                  ? false
                  : true
                : false
            }
            onClick={DeleteJournal}
          >
            {props?.isCustomJrnls ? "Delete" : "Delete All"}
          </Button>
        </Space>
      }
    >
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
        spinning={loading}
      >
        <Table
          className="Tabel-style"
          rowSelection={rowSelect}
          columns={cols}
          dataSource={data}
          pagination={false}
        />
      </Spin>
    </Modal>
  );
};
export default AssetJournalModal;
