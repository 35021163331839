import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { ApplicationUserStatus } from "./ProductService";
import { DBRecordStatus } from "./SalesService";
export enum Customer_Type {
  Company,
  Practitioner,
}

class CustomerService {
  route = "v1/API/Acc_Customer";
  getCustomerDetails = (
    start: number,
    length: number,
    search: string,
    status: ApplicationUserStatus
  ) =>
    ApiUtility.get(
      `${this.route}/GetCustomerList?start=${start}&length=${length}&search=${search}&status=${status}`
    );

  getCustomerDetailsByID = (customerId: string | undefined) =>
    ApiUtility?.get(`${this.route}/${customerId}`);

  postCutomerData = (CustomerId: string | undefined, model: any) =>
    ApiUtility.post(`${this.route}/${CustomerId}`, model);

  deleteCustomerDetailsById = (CustomerId: string | undefined) =>
    ApiUtility.delete(`${this.route}/${CustomerId}`);
}

export default new CustomerService();
