import { Button, Card, Col, Input, Row, Skeleton, Table, Tooltip } from "antd";
import React from "react";
import ItemsService, { UnitTypes } from "../../Services/ItemsService";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { LedgerStatus } from "../../Services/LedgerService";
import ItemGroupModel from "./ItemGroupModel";
import { GroupTypes } from "../../Services/ChartofAccountServicesNew";
// import UnitModel from "./UnitModel";

interface IItemDataType {
  key: string;
  // companyId: string;
  id: number;
  itemGroupName: string;
  parent: string;
  groupType: GroupTypes;
  system: boolean;
  status: LedgerStatus;
}

const ItemGroups = () => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [itemGroupList, setItemGroupList] = React.useState<any[]>([]);
  const [searchItemGroup, setSearchItemGroup] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [itemGroupData, setItemGroupData] = React.useState<any>({});
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getItemGroupsList(
        currentClient?.id,
        searchItemGroup,
        filter.sortCol,
        filter.sortDir
      );
    } else if (currentClient?.id === null) {
      console.error("No client found.");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, searchItemGroup, filter.sortCol, filter.sortDir]);

  // --------- Get Units TableData API ---------
  const getItemGroupsList = async (
    clientId: string | undefined,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    groupId?: string,
    groupSearch?: string,
    status?: LedgerStatus
  ) => {
    await ItemsService.getItemGroupsList(clientId, search, sortCol, sortDir)
      .then((res: any) => {
        if (res.result?.length > 0) {
          console.log(res.result);
          setItemGroupList(
            res.result?.map((v: any) => {
              return {
                // companyId: v?.companyId,
                groupType: v?.groupType,
                system: v?.system,
                itemGroupName: v?.itemGroupName,
                parent: v?.parent,
                parent_Name: v?.parent_Name,
                parent_Type: v?.parent_Type,
                id: v?.id,
                status: v?.status,
              };
            })
          );
          setLoading(false);
        } else if (res.result?.items?.length === 0) {
          setItemGroupList([]);
          // setLoading(false);
        }
      })
      .catch((ex: any) => {
        console.error(ex.message);
        // setLoading(false);
        setItemGroupList([]);
      });
  };

  // --------- Units Table Columns  ---------
  const itemColumns: ColumnsType<IItemDataType> = [
    {
      title: "S NO.",
      dataIndex: "",
      key: "",
      width: "5%",
      render: (_, record, index: number) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {index + 1}
        </Skeleton>
      ),
    },
    {
      title: "Item Group's Name",
      dataIndex: "itemGroupName",
      key: "itemGroupName",
      width: "40%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.itemGroupName}
        </Skeleton>
      ),
    },
    {
      title: "Parent Group",
      dataIndex: "parent_Name",
      key: "parent_Name",
      width: "40%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.parent_Name}
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "",
      key: "edit",
      render: (record: IItemDataType) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Row gutter={18}>
            <Col>
              <Tooltip title="Edit" placement="top" color="#3D4998">
                <EditOutlined
                  // className="ca-edit-btn"
                  style={{
                    color: "#444653",

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenModel(true);
                    setItemGroupData({
                      id: record?.id,
                      groupType: record?.groupType,
                      system: record?.system,
                      // companyId: record?.companyId,
                      itemGroupName: record?.itemGroupName,
                      parent: record?.parent,
                    });
                  }}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Delete" placement="top" color="red">
                <DeleteOutlined
                  // className="ca-edit-btn"
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Skeleton>
      ),
    },
  ];

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <div>
        <Row justify="space-between">
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setOpenModel(true)}
            >
              Item Group
            </Button>
          </Col>
          <Col>
            <Input
              allowClear
              style={{ width: "220px" }}
              placeholder="Search Item Group"
              onChange={(e: any) => {
                setLoading(true);
                if (!e.cancelable) {
                  //console.log("typeing user............");
                  const searchItemGroup = setTimeout(() => {
                    setSearchItemGroup(e.target.value);
                  }, 1000);
                  return () => clearTimeout(searchItemGroup);
                } else {
                  // console.log("Click cancle............");
                  setSearchItemGroup(e.target.value);
                }
              }}
              suffix={loading || searchItemGroup ? "" : <SearchOutlined />}
            />
          </Col>
        </Row>
        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          columns={itemColumns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as IItemDataType[])
              : itemGroupList
          }
          // scroll={
          //   pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
          // }
          // pagination={{
          //   total: totalRecords,
          //   current: page,
          //   pageSize: pageSize,
          //   showSizeChanger: true,
          //   onChange: (page: number, pageSize: number) => {
          //     setPage(page);
          //     setPageSize(pageSize);
          //   },
          //   pageSizeOptions: ["15", "25", "50", "100"],
          //   showTotal: (totalRecords, page) =>
          //     `${page[0]}-${page[1]} of ${totalRecords} items`,
          // }}
        />
        {openModel && (
          <ItemGroupModel
            open={true}
            data={itemGroupData}
            onCancel={() => setOpenModel(false)}
            setTrigger={setTrigger}
            groupData={itemGroupList}
          />
        )}
      </div>
    </Card>
  );
};

export default ItemGroups;
