import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import React from "react";
import CustomerService, { Customer_Type } from "../../Services/CustomerService";
import ClientService, { getIndianStates } from "../../Services/ClientService";
import ProductService, {
  ApplicationUserStatus,
} from "../../Services/ProductService";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PicLeftOutlined,
  PlusCircleOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import AddProductDetails from "./AddProductDetails";
import { ColumnsType } from "antd/es/table";
import { useTheme } from "../../Context/ThemeContext";
interface props {
  onCancel: any;
  isModelOpen: boolean;
  setRefresh: any;
  id: string | undefined;
}
const AddCustomer = ({ onCancel, isModelOpen, setRefresh, id }: props) => {
  const [form] = Form.useForm();
  const { themeData } = useTheme();
  const [state, setState] = React.useState<any>();
  const [productList, setProductList] = React.useState<any[]>();
  const [model, setModel] = React.useState<boolean>(false);
  const [productDataList, setProductDataList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [editingProduct, setEditingProduct] = React.useState<any>(null);
  React.useEffect(() => {
    getProductDetails();
    getIndianState();
  }, []);
  const getIndianState = async () => {
    let res = await ClientService?.getStateOption(false);
    if (res) {
      console.log("res", res);
      setState(res?.result);
    }
  };

  const getProductDetails = async () => {
    try {
      let res = await ProductService?.getProductDetails(
        0,
        0,
        "",
        ApplicationUserStatus?.Active
      );
      if (res) {
        setProductList(res?.result);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "some error",
      });
    } finally {
    }
  };
  React.useEffect(() => {
    if (id) {
      const getCustomerDataById = async () => {
        setLoading(true);
        let res = await CustomerService?.getCustomerDetailsByID(id);
        if (res) {
          let c = res?.result;
          form?.setFieldsValue({
            // address: c?.address,
            // contactDetail: c?.contactDetail,
            first: c?.customerName?.first,
            last: c?.customerName?.last,
            Email: c?.email,
            customerType: c?.customerType,
            GSTIN: c?.gstin,
            pan: c?.pan,
            socialProfiles: c?.socialProfiles,
            personName: c?.contactDetail?.personName,
            designation: c?.contactDetail?.designation,
            email: c?.contactDetail?.email,
            phone: c?.contactDetail?.phone,
            gender: c?.contactDetail?.gender,
            building: c?.address?.building,
            city: c?.address?.city,
            country: c?.address?.country,
            district: c?.address?.district,
            pincode: c?.address?.pincode,
            state: c?.address?.state,
            landmark: c?.address?.landmark,
            street: c?.address?.street,
          });
          const productsWithIndex = res?.result?.products?.map(
            (product: any, index: number) => ({
              ...product,
              index,
            })
          );
          setProductDataList(productsWithIndex);
          console.log("res");
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
      getCustomerDataById();
    } else {
    }
  }, []);

  const saveCustomerDetails = () => {
    setBtnLoading(true);
    form?.validateFields().then(async (val: any) => {
      let data = {
        ...val,
        customerName: {
          first: val?.first,
          last: val?.last,
        },

        contactDetail: {
          personName: val?.personName,
          designation: val?.designation,
          email: val?.email,
          phone: val?.phone,
          gender: val?.gender,
        },
        address: {
          building: val?.building,
          street: val?.street,
          landmark: val?.landmark,
          city: val?.city,
          district: val?.district,
          pincode: val?.pincode,
          state: val?.state,
          country: val?.country,
        },

        socialProfiles: val?.socialProfiles?.map((profile: any) => ({
          name: profile?.name,
          value: profile?.value,
        })),
        products: productDataList?.map((p: any) => ({
          id: p?.id,
          name: p?.name,
        })),
      };
      console.log("data", data);

      let res = await CustomerService?.postCutomerData(id, data);
      if (res?.status) {
        console.log("res", res);
        setRefresh((x: boolean) => !x);
        notification.success({
          message: "Success",
          description: id ? "Edit SuccessFully" : "Saved Successfully0",
        });
        setBtnLoading(false);
        onCancel(false);
      } else {
        notification.error({
          message: "Error",
          description: res?.message,
        });
      }
    });
  };
  const deleteData = (index: number) => {
    console.log("index", index);
    let filteredProducts = productDataList?.filter(
      (x: any) => x.index !== index
    );
    setProductDataList(filteredProducts);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any, index: number) => (
        <Space>
          <EditOutlined onClick={() => handleEdit(record)} />
          <DeleteOutlined
            style={{ color: "red" }}
            onClick={() => deleteData(record?.index)}
          />
        </Space>
      ),
    },
  ];

  const handleEdit = (record: any) => {
    setEditingProduct(record);
    setModel(true);
  };

  console.log("productDataList", productDataList);

  return (
    <>
      <div>
        <Modal
          width={1300}
          title={id ? "Edit Customer" : "Add Customer"}
          onCancel={() => onCancel(false)}
          open={isModelOpen}
          footer={
            <div style={{ marginTop: "0px" }}>
              <Button
                onClick={() => onCancel(false)}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={saveCustomerDetails}
                loading={btnLoading}
              >
                {id ? "Edit" : "Save"}
              </Button>
            </div>
          }
        >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
            style={{ marginTop: "50px" }}
          >
            <Form
              form={form}
              labelAlign="left"
              layout="vertical"
              initialValues={{
                socialProfiles: [{}],
              }}
            >
              <div
                style={{
                  height: "700px",
                  overflowY: "scroll",
                  overflowX: "clip",
                }}
              >
                <Row gutter={20}>
                  <Col span={6}>
                    <Form.Item
                      name="first"
                      label="First Name"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="last"
                      label="Last Name"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="Email"
                      label="Email"
                      className="inputBoxMb"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="customerType"
                      label="Customer Type"
                      className="inputBoxMb"
                    >
                      <Select
                        options={[
                          { value: Customer_Type?.Company, label: "Company" },
                          {
                            value: Customer_Type?.Practitioner,
                            label: "Practitioner",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="GSTIN"
                      label="GSTIN"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="pan"
                      label="PAN No."
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left" orientationMargin={0}>
                  Contact Detail
                </Divider>
                <Row gutter={20}>
                  <Col span={6}>
                    <Form.Item
                      name="personName"
                      label="Person Name"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="designation"
                      label="Designation"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="email"
                      label="Email"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="phone"
                      label="Phone"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="gender"
                      label="Gender"
                      className="inputBoxMb"
                    >
                      <Radio.Group>
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Femal</Radio>
                        <Radio value={3}>Other</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left" orientationMargin={0}>
                  Address
                </Divider>

                <Row gutter={20}>
                  <Col span={6}>
                    <Form.Item
                      name="building"
                      label="Building"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="street"
                      label="Street"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="landmark"
                      label="Landmark"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="city" label="City" className="inputBoxMb">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="district"
                      label="District"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="pincode"
                      label="Pincode"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="state"
                      label="State"
                      className="inputBoxMb"
                    >
                      <Select
                        placeholder="Select state"
                        options={state?.map((s: any) => ({
                          key: s.name,
                          value: s.id,
                          label: s.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="country"
                      label="Country"
                      className="inputBoxMb"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left" orientationMargin={0}>
                  Social Profiles
                </Divider>

                <div>
                  <Form.List name="socialProfiles">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Row gutter={20} key={key}>
                            <Col span={6}>
                              <Form.Item
                                name={[name, "name"]}
                                label="Social Name"
                                className="inputBoxMb"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                name={[name, "value"]}
                                label="Social Link"
                                className="inputBoxMb"
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>
                </div>

                <Divider orientation="left" orientationMargin={0}>
                  Product(s)
                </Divider>
                <Row>
                  <Col span={6}>
                    <Button
                      icon={<PlusOutlined />}
                      size="small"
                      onClick={() => {
                        setEditingProduct(null);
                        setModel(true);
                      }}
                    >
                      Add Product
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Table
                    style={{ width: "100%" }}
                    className={`Tabel-style table-${
                      themeData?.componentSize ?? "middle"
                    }`}
                    columns={columns}
                    dataSource={productDataList}
                    rowKey="id"
                  />
                </Row>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>

      {/* {model && (
        <AddProductDetails
          onCancel={() => {
            setModel(false);
            setEditingProduct(null);
          }}
          isModelOpen={isModelOpen}
          setProductData={(x) => {
            setProductDataList([...productDataList, x]);
            setModel(false);
          }}
          productData={productDataList}
        />
      )} */}

      {model && (
        <AddProductDetails
          onCancel={() => {
            setModel(false);
            setEditingProduct(null);
          }}
          isModelOpen={isModelOpen}
          setProductData={(updatedProduct) => {
            setProductDataList((prev) => {
              if (editingProduct) {
                return prev.map((product, index: number) => {
                  if (product.id === editingProduct.id) {
                    return { ...product, ...updatedProduct, index };
                  }
                  return { ...product, index };
                });
              } else {
                const newIndex = prev.length;
                return [...prev, { ...updatedProduct, index: newIndex }];
              }
            });
            setModel(false);
            setEditingProduct(null);
          }}
          productData={editingProduct}
        />
      )}
    </>
  );
};

export default AddCustomer;
