import { ApiUtility } from "../../Utilities/ApiUtility.axios";
export const enum LogTypes {
  All,
  Vouchers,
  Master,
  Users,
  Settings,
  Security,
  Reports,
  Email,
}

// class LogService {
//   route = `/v1/api/UserLog`;
//   getUserLog = (
//     types: LogTypes,
//     userId: string,
//     clientId: string,
//     start: number,
//     length: number,
//     search:string | undefined,
//   ) =>
//     ApiUtility.getResult(`${this.route}`, {
//       types,
//       userId,
//       clientId,
//       start,
//       length,
//       search
//     });
// }

class LogService {
  route = `/v1/api/Acc_User_Log`;
  getUserLog = (
    clientId: string,
    userId: string,
    types: LogTypes | undefined,
    fromDate: string,
    toDate: string,
    search: any,
    start: number,
    length: number
  ) =>
    ApiUtility.getResult(`${this.route}/${clientId}`, {
      clientId,
      userId,
      types,
      fromDate,
      toDate,
      search,
      start,
      length,
    });
}
export default new LogService();
