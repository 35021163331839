import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  Select,
  Space,
  Tooltip,
  Card,
  Skeleton,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { PlusOutlined, FormOutlined } from "@ant-design/icons";
import AddParty from "./AddParty";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import "../../Pages/Sales/SalesStyle.css";
import PartyService from "../../Services/PartyService";
import "../../Common/Acc_Common.css";
import { Utils } from "../../../Utilities/Utils";
import { NavLink } from "react-router-dom";
import search from "../../Images/search.svg";
import { NameTitles } from "../../Services/SalesService";

interface IPartyData {
  id: any;
  sno: number;
  key: React.Key;
  bName: string;
  name: string;
  email: string;
  phone: number;
  balance: number;
}
const selectParty: any = [
  { label: "Both", value: 0 },
  { label: "Customers", value: 1 },
  { label: "Suppliers", value: 2 },
];

const Party = () => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IPartyData[]>([]);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [editParty, setEditParty] = React.useState<any>();
  const [type, setType] = React.useState<number>(0);
  const [width, SetWidth] = React.useState<any>(window.innerWidth);

  // --------- Table Columns  ---------
  const columns: ColumnsType<IPartyData> = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      width: "4%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Business Name",
      dataIndex: "bName",
      width: "20%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <NavLink
            to={`/accounts/clients/${currentClient?.id}/parties/${record.id}`}
          >
            {record.bName}
          </NavLink>
        </Skeleton>
      ),
    },
    {
      title: "Contact Person",
      dataIndex: "name",
      width: "20%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.email}
        </Skeleton>
      ),
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1   }}
          title={false}
        >
          {record?.phone}
        </Skeleton>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      width: "18%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.balance}
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "edit",
      width: "4%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Tooltip title="Edit" placement="top" color="#3D4998">
            <FormOutlined
              className="ca-edit-btn"
              style={{ color: "#a5abdd" }}
              onClick={() => {
                setEditParty({ id: record.id });
                // setEditParty(record);
                setOpenDrawer(true);
              }}
              color="red"
            />
          </Tooltip>
        </Skeleton>
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "delete",
    //   width: "3%",
    //   align: "center",
    //   render: (_, record) => (
    //     <div className="cursor mx-1">
    //       <Popover
    //         style={{ padding: 0 }}
    //         placement="bottomRight"
    //         content={content}
    //         trigger="click"
    //       >
    //         <EllipsisOutlined style={{ color: "rgb(57, 136, 255)" }} />
    //       </Popover>
    //     </div>
    //   ),
    // },
  ];

  // const content = (
  //   <div>
  //     <Button>Report</Button>
  //     <Button>Delete</Button>
  //   </div>
  // );

  useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getPartyList(
        type,
        (page - 1) * pageSize,
        pageSize,
        undefined,
        undefined,
        searchItem,
        undefined
      );
    } else if (currentClient?.id === null) {
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, page, pageSize, searchItem, trigger]);

  // --------- Get PartyList API ---------
  const getPartyList = async (
    type: number,
    start: any,
    length: any,
    sortCol: any,
    sortDir: any,
    search: any,
    status: any
  ) => {
    await PartyService.getPartyList(
      currentClient?.id,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status
    )
      .then((res: any) => {
        if (res != null || res?.items?.length > 0) {
          console.log(res, "resdata");
          setTableData(
            res?.items?.map((r: any, i: any) => {
              console.log(r);
              return {
                key: i,
                sno: i + 1,
                id: r?.id,
                bName: r?.bName,
                //  (r.defaultContact?.name?.title === 0
                //         ? " "
                //         : NameTitles[r.defaultContact?.name?.title]
                //         ? ""
                //         : r.defaultContact?.name?.first) +
                //       " " +
                name:
                  r?.defaultContact?.name === null
                    ? ""
                    : (r.defaultContact?.name?.title === 0
                        ? " "
                        : NameTitles[r.defaultContact?.name?.title] + " ") +
                      (r.defaultContact?.name?.first === null
                        ? ""
                        : r.defaultContact?.name?.first) +
                      " " +
                      (r.defaultContact?.name?.last === null
                        ? ""
                        : r.defaultContact?.name?.last),
                // name: r.defaultContact?.name
                //   ? r.defaultContact?.name?.first +
                //     " " +
                //     r.defaultContact?.name?.last
                //   : "",
                email: r?.defaultContact?.email,
                phone: r?.defaultContact?.phone,
                balance: Utils.getFormattedNumber(r?.balance),
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setTableData([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.log("error", ex);
        setLoading(false);
        setTableData([]);
      });
    setLoading(false);
  };

  window.addEventListener("resize", () => {
    SetWidth(window.innerWidth);
  });

  return (
    <React.Fragment>
      <Card
        size="small"
        className="TableCard"
        style={{
          width: "97%",
          marginLeft: "16px",
          marginTop: "24px",
          border: "1px solid #eef2fe",
        }}
      >
        <Row
          justify="space-between"
          style={{
            marginTop: 10,
            padding: "10px 18px 3px 18px",
          }}
        >
          <Col style={{ paddingBottom: width <= 543 ? "10px" : "0px" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setOpenDrawer(true);
                setEditParty({ id: null });
              }}
            >
              Add Party
            </Button>
          </Col>
          <Col>
            <Row gutter={[8, width <= 425 ? 8 : 0]}>
              <Col>
                <Input
                  style={{ width: "220px" }}
                  allowClear={true}
                  placeholder="Search Party"
                  suffix={
                    loading || searchItem ? (
                      ""
                    ) : (
                      <img alt="search" src={search} />
                    )
                  }
                  onChange={(e: any) => {
                    setLoading(true);
                    if (!e.cancelable) {
                      const data = setTimeout(() => {
                        setPage(1);
                        setSearchItem(e.target.value);
                      }, 1000);
                      return () => clearTimeout(data);
                    } else {
                      setSearchItem(e.target.value);
                    }
                  }}
                />
              </Col>
              <Col>
                <Select
                  style={{ width: 110 }}
                  options={selectParty}
                  showSearch={true}
                  defaultValue={"Both"}
                  onChange={(e: any) => {
                    // setPage(1);
                    setType(e);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          style={{ fontSize: "1px" }}
          size="small"
          columns={columns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as IPartyData[])
              : tableData
          }
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["15", "25", "50", "100"],
            showTotal: (totalRecords, page) =>
              `${page[0]}-${page[1]} of ${totalRecords} items`,
          }}
          scroll={tableData.length > 15 ? { x: 600, y: 600 } : { x: 600 }}
        />

        {/* -------------ADD Party ----------*/}
        {openDrawer && (
          <AddParty
            openDrawer={openDrawer}
            setTrigger={setTrigger}
            setOpenDrawer={setOpenDrawer}
            editParty={editParty}
            setNewPartie={() => ""}
          />
        )}
      </Card>
    </React.Fragment>
  );
};
export default Party;
