import { Form, Input, Modal, message } from "antd"
import { AuthService } from "../../Services/AuthService";
import { useEffect, useState } from "react";
import { GrKey } from "react-icons/gr";
import React from "react";
import { useUser } from "../../Context/UserContext";
const ChangePassword = (props: any) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { getUser } = useUser();
    const loginUser = { ...getUser() }
    const info = (msg: any) => {

        messageApi.success(msg, 1);

    };
    const error = (mes: any) => {

        messageApi.error(mes);

    };
    // const [loginUser, setLoginuser] = React.useState<any>(JSON.stringify(sessionStorage.getItem("ao-user")) + "")
    useEffect(() => {
        console.log("this---------------------------------------is------------------------------------------", loginUser.name)
  
    }, [])
    const [changePasswordForm] = Form.useForm();
    const { editPasswordModal, setEditPasswordModal } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setConfirmLoading(false);
        }, 2000);
        changePasswordForm.validateFields()
            .then((val: any) => {
                setConfirmLoading(true);
                // let data = {newpass: val.
                // token:}  
                AuthService.changePassword(loginUser?.id, val)
                    .then((res: any) => {
                        if (res?.data?.result === true) {
                            info("Password Changed Successfully")
                            setEditPasswordModal(false)
                            changePasswordForm.resetFields()
                         
                        }
                        else {

                            error(res?.data?.message)
                            changePasswordForm.resetFields();
                        }
                        setConfirmLoading(false);
                    })
            })

    };
    const handleCancel = () => {
        changePasswordForm.resetFields()
        setEditPasswordModal(false);
    };
    return (
        <>
            {contextHolder}
            <Form
                form={changePasswordForm}
                name="ChangePassword"
                initialValues={{ remember: true }}
                layout="vertical"
                labelCol={{ span: 13 }}
                wrapperCol={{ lg: 25 }}
                labelAlign="left"

            >
                <Modal
                    title={<>Change Password <GrKey /></>}
                    open={editPasswordModal}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    maskClosable={false}
                    confirmLoading={confirmLoading}
                    width={450}
                >
                    <Form.Item
                        name="currentPassword"
                        label={"Current Password"}
                        rules={[
                            {
                                required: true,
                                message: "Enter current password!",
                            },
                        ]}
                        hasFeedback>
                        <Input.Password placeholder="Enter Old Password" />
                    </Form.Item>

                    <Form.Item
                        name="newPassword"
                        label={"New Password"}
                        rules={[
                            {
                                required: true,
                                message: 'Enter new password!',
                            },
                        ]}
                        hasFeedback>
                        <Input.Password placeholder="Enter new password" />
                    </Form.Item>

                    <Form.Item
                        name="confirmPassword"
                        dependencies={['newPassword']}
                        label={"Confirm Password"}
                        rules={[
                            {
                                required: true,
                                message: 'Enter confirm password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject
                                        (new Error('The new password that you Entered do not match!'));
                                },
                            }),
                        ]} hasFeedback>
                        <Input.Password placeholder="Enter Confirm Password" />
                    </Form.Item>
                </Modal>

            </Form >
        </>
    )
}
export default ChangePassword;