import React from "react";
import { Coordinate, Measurement } from "./AddChequeBookModal";

interface DemoChequeProps {
  measurement: Measurement;
  imageBase64: string | null;
}

const DemoCheque: React.FC<DemoChequeProps> = ({
  measurement,
  imageBase64,
}) => {
  const { chequeHeight, chequeWidth, coordinates, fields } = measurement;

  const renderField = (field: Coordinate) => {
    const isAccountPayable = field.key === "accountPayable";

    let fieldStyle: React.CSSProperties = {
      position: "absolute",
      left: `${field.x}mm`,
      top: `${field.y}mm`,
      backgroundColor: "rgba(255,255,255,0.8)",
      // padding: "2px 4px",
      // border: "1px solid #ddd",
      // borderRadius: "4px",
    };

    if (isAccountPayable) {
      fieldStyle = {
        ...fieldStyle,
        borderTop: "solid 1px grey",
        borderBottom: "solid 1px grey",
        transform: "rotate(-45deg)",
        transformOrigin: "0 0", // Rotate by 45 degrees
      };
    }

    if (field.x !== null && field.y !== null) {
      return (
        <div key={field.key} style={fieldStyle}>
          {field.value}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {chequeWidth && chequeHeight && (
        <table style={{ margin: "2px auto" }}>
          <tbody>
            <tr>
              <td align="right">0</td>
              <td align="center"> -- X axis -- {chequeWidth} mm</td>
            </tr>
            <tr>
              <td valign="middle">
                <p
                  style={{
                    textAlign: "center",
                    transformOrigin: "100% 100%",
                    whiteSpace: "nowrap",
                    transform: "rotate(-90deg)",
                    width: "20px",
                  }}
                >
                  -- Y axis -- {chequeHeight} mm
                </p>
              </td>
              <td>
                {" "}
                <div
                  style={{
                    width: `${chequeWidth}mm`,
                    height: `${chequeHeight}mm`,
                    border: "1px solid #000",
                    position: "relative",
                    margin: "0 auto",
                    backgroundImage: `url(${imageBase64})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    overflow: "hidden",
                  }}
                >
                  {fields?.map(renderField)}
                  {coordinates?.map(renderField)}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DemoCheque;
