import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  InputRef,
  notification,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  TableColumnType,
  Tag,
  Tooltip,
} from "antd";
import React from "react";
import { useTheme } from "../../../Context/ThemeContext";
import { useClient } from "../../../Context/ClientContext";
import search from "../../../Images/search.svg";
import { AddLedger } from "./AddLedger";
import { GroupTypes } from "../../../Services/ChartofAccountServicesNew";
import { ColumnsType } from "antd/es/table";
import { Utils } from "../../../../Utilities/Utils";
import { getIndianStates } from "../../../Services/ClientService";
import { FilterDropdownProps } from "antd/es/table/interface";
import LedgerService, { LedgerStatus } from "../../../Services/LedgerService";
import { NavLink } from "react-router-dom";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { IndianStates } from "../../../../Types/CommonConstants";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { dayjs } from "../../../../Utilities/dayjs";
import InvoiceExportService from "../../../Services/InvoiceExportDetails";
import { useUser } from "../../../../Context/UserContext";

interface IItemListData {
  key: string;
  parent: string;
  id: string;
  ledgerName: string;
  parentGroup: string;
  balance: number;
  gstin: string;
  state: IndianStates;
  name: string;
  phone: string;
  system: boolean;
  editable: boolean;
  status: LedgerStatus;
  isActive: boolean;
}

type NotificationType = "success" | "error";

type DataIndex = keyof IItemListData;

const LedgerTable = () => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const [api, contextHolder] = notification.useNotification();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [openModel, setOpenModel] = React.useState<{
    open: boolean;
    id: any;
  }>({
    open: false,
    id: "",
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [serchAllField, setSerchAllField] = React.useState<string>("");
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [ledgerData, setLedgerData] = React.useState<any[]>([]);
  const [filterGroupTypes, setFilterGroupTypes] = React.useState<GroupTypes>();
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [exportType, setExportType] = React.useState<number>(-1);

  const [exportLedgerData, setExportLedgerData] = React.useState<any[]>([]);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [searchColumns, setSearchColumns] = React.useState<{
    ledgerName: string;
    parentGroup: string;
    GSTNo: string;
    contPerson: string;
    contPhone: string;
  }>({
    ledgerName: "",
    parentGroup: "",
    GSTNo: "",
    contPerson: "",
    contPhone: "",
  });
  const [selectIndState, setSelectIndState] = React.useState<IndianStates>(
    IndianStates.Unspecified
  );
  const [confirmationActive, setConfirmationActive] =
    React.useState<boolean>(false);
  const [confirmationDeleted, setConfirmationDelete] =
    React.useState<boolean>(false);
  const [selectChange, setSelectChange] = React.useState<string>("");
  const [ledgerStatus, setLedgerStatus] = React.useState<LedgerStatus>(
    LedgerStatus.active
  );
  // const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<InputRef>(null);
  const searchSelect = React.useRef<any>(null);
  const [onlyAmountValue, setOnlyAmountValue] = React.useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = React.useState<DataIndex | null>(
    null
  );
  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };

  const handleSearch = (
    selectedKeys: string[] | any,
    confirm: any,
    dataIndex: DataIndex
  ) => {
    // console.log(selectedKeys, dataIndex);
    switch (dataIndex) {
      case "ledgerName":
        setSearchColumns({
          ...searchColumns,
          ledgerName: selectedKeys[0],
        });
        break;
      case "parentGroup":
        setSearchColumns({ ...searchColumns, parentGroup: selectedKeys[0] });
        break;
      case "gstin":
        setSearchColumns({ ...searchColumns, GSTNo: selectedKeys[0] });
        break;
      case "name":
        setSearchColumns({ ...searchColumns, contPerson: selectedKeys[0] });
        break;
      case "phone":
        setSearchColumns({ ...searchColumns, contPhone: selectedKeys[0] });
        break;
      case "state":
        setSelectIndState(selectedKeys[0]);
        break;
    }
    confirm();
    setOpenDropdown(null); // Close the dropdown
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    clearFilters();
    confirm();
    console.log("dataIndex", dataIndex);

    switch (dataIndex) {
      case "ledgerName":
        setSearchColumns({
          ...searchColumns,
          ledgerName: "",
        });
        break;
      case "parentGroup":
        setSearchColumns({ ...searchColumns, parentGroup: "" });
        break;
      case "gstin":
        setSearchColumns({ ...searchColumns, GSTNo: "" });
        break;
      case "name":
        setSearchColumns({ ...searchColumns, contPerson: "" });
        break;
      case "phone":
        setSearchColumns({ ...searchColumns, contPhone: "" });
        break;
      case "state":
        setSelectIndState(IndianStates.Unspecified);
        break;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IItemListData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {dataIndex === "state" ? (
          <Select
            allowClear
            ref={searchSelect}
            showSearch
            placeholder="Select.."
            value={selectedKeys[0]}
            options={Object.entries(stateOptions)?.map(([key, x]: any) => ({
              value: x?.id,
              label: x?.name,
            }))}
            onChange={(e) => setSelectedKeys(e ? [e] : [])}
            filterOption={(input: string, option: any) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            // style={{
            //   width: "100%",
            // }}
            style={{ marginBottom: 8, display: "block" }}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search..`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            // icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    filterDropdownOpen: openDropdown === dataIndex,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setOpenDropdown(dataIndex);
        setTimeout(() => {
          if (dataIndex === "state") {
            searchSelect.current?.focus(); // Use focus for Select component
          } else {
            searchInput.current?.select(); // Use select for Input component
          }
        }, 100);
      } else {
        setOpenDropdown(null);
      }
    },
  });

  // --------- Table list data ---------
  React.useEffect(() => {
    // debugger;

    const getListData = async (
      search: string,
      groupSearch: string,
      start: number,
      length: number,
      ledgerStatus?: LedgerStatus,
      GstTxt?: string,
      selectIndState?: IndianStates,
      contPerson?: string,
      contPhone?: string,
      onlyAmountValue?: boolean
      // StateTxt?: IndianStates,
    ) => {
      if (exportType < 1) {
        setLoading(true);
      }
      await LedgerService.getLedgerList(
        currentClient?.id!,
        start,
        length,
        search,
        groupSearch,
        filter.sortCol,
        filter.sortDir,
        "",
        ledgerStatus,
        GstTxt,
        selectIndState,
        contPerson,
        contPhone,
        onlyAmountValue
        // serchAllField
      )
        .then((res: any) => {
          if (res.items) {
            let lederList = res?.items?.map((r: any, i: any) => {
              let defaultConatct = r?.contactInformation?.find(
                (x: any) => x?.isDefault === true
              );
              // console.log("defaultConatct", defaultConatct);
              return {
                key: i,
                sno: i + 1,
                system: r?.system,
                id: r?.id,
                ledgerName: r?.ledgerName,
                parentGroup: r?.groupName,
                balance:
                  r?.balance > 0
                    ? Utils.getFormattedNumber(r?.balance) + " Dr."
                    : r?.balance < 0
                    ? Utils.getFormattedNumber(Math.abs(r?.balance)) + " Cr."
                    : 0,
                gstin: r?.gstin,
                state: r?.state,
                status: r?.status,
                name: defaultConatct?.name,
                phone: defaultConatct?.phone,
                isActive: r?.isActive,
              };
            });
            if (exportType >= 1) {
              setExportLedgerData(lederList);
              if (exportType === 1) {
                exportCsv(lederList);
              } else if (exportType === 2) {
                exportPDF(lederList);
              } else if (exportType === 3) {
                exportExcel(lederList);
              }
            } else {
              setLoading(false);
              setTotalRecords(res?.totalRecords);
              setLedgerData(lederList);
            }
            // setLedgerData(res.items);
          } else {
            setLedgerData([]);
            setLoading(false);
            setTotalRecords(res?.totalRecords);
            // console.log("Error", res);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          // console.log("Error", err);
        });
    };
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getListData(
        serchAllField,
        searchColumns.parentGroup,
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        ledgerStatus,
        searchColumns.GSTNo,
        selectIndState,
        searchColumns?.contPerson,
        searchColumns?.contPhone,
        onlyAmountValue

        // filterGroupTypes,
        // searchColumns,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trigger,
    filter,
    searchColumns,
    serchAllField,
    ledgerStatus,
    selectIndState,
    onlyAmountValue,
    exportType,
  ]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<IItemListData> = [
    {
      title: "Ledger Name",
      dataIndex: "ledgerName",
      key: "ledgerName",
      width: "19%",
      sorter: true,
      defaultSortOrder: "ascend",
      // width: width <= 1440 ? "8%" : "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <NavLink
            to={`/accounts/clients/${currentClient?.id}/master/ledger/${record?.id}`}
          >
            {record?.ledgerName}
          </NavLink>
        </Skeleton>
      ),
      // ...getColumnSearchProps("ledgerName"),
    },
    {
      title: "Parent Group",
      dataIndex: "parentGroup",
      key: "parentGroup",
      width: "17%",
      // sorter: true,
      // width: width <= 1440 ? "8%" : "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.parentGroup}
        </Skeleton>
      ),
      ...getColumnSearchProps("parentGroup"),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: "9%",
      sorter: true,
      align: "right",
      // onFilter: (value: string, record) => {
      //   return set
      // },
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record.balance}
        </Skeleton>
      ),
      // ...getColumnSearchProps("balance"),
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
      width: "10%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.gstin}
        </Skeleton>
      ),
      ...getColumnSearchProps("gstin"),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "12%",
      // width: width <= 1440 ? "12%" : "10%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {getIndianStates(record?.state)}
        </Skeleton>
      ),
      ...getColumnSearchProps("state"),
    },
    {
      title: "Contact Person",
      dataIndex: "name",
      key: "name",
      width: "10%",
      // sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Contact No.",
      dataIndex: "phone",
      // key: "group_Name",
      width: "9%",
      // width: width <= 1440 ? "12%" : "10%",
      // sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.phone}
        </Skeleton>
      ),
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Action",
      dataIndex: "isActive",
      width: "7%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          paragraph={{
            rows: 1,
          }}
          loading={loading}
          title={false}
          className="cursor"
        >
          <div
            style={{ display: "flex", justifyContent: "flex-Star", gap: 12 }}
          >
            {(record.status === LedgerStatus.active ||
              record.status === LedgerStatus.deactive) && (
              <Checkbox
                checked={record?.isActive}
                onChange={(e: any) => {
                  console.log(e.target.checked);
                  setOpenModel({ ...openModel, id: record?.id });
                  setConfirmationActive(true);
                  setSelectChange(record?.isActive ? "De-Active" : "Active");
                }}
              />
            )}

            {record.status === LedgerStatus.active && (
              <Tooltip
                title={
                  record?.system ? "Default ledger are not editable" : "Edit"
                }
                placement="top"
                color={record?.system ? "rgb(216 207 207)" : "#444653"}
              >
                <EditOutlined
                  // className="ca-edit-btn"
                  style={{
                    color: record?.system ? "rgb(216 207 207)" : "#444653",
                  }}
                  onClick={() => {
                    !record?.system &&
                      setOpenModel({
                        open: true,
                        id: record?.id,
                      });
                  }}
                />
              </Tooltip>
            )}

            {(record.status === LedgerStatus.active ||
              record.status === LedgerStatus.deactive) && (
              <Tooltip
                title={
                  record?.system ? "Default groups are not delete" : "Delete"
                }
                placement="top"
                color={record?.system ? "rgb(216 207 207)" : "red"}
              >
                <DeleteTwoTone
                  className="ca-delete-btn cursor"
                  twoToneColor={record?.system ? "rgb(216 207 207)" : "#ff879d"}
                  onClick={() => {
                    if (!record?.system) {
                      setOpenModel({ ...openModel, id: record?.id });
                      setConfirmationDelete(true);
                    }
                  }}
                />
              </Tooltip>
            )}

            {record.status === LedgerStatus.deleted && (
              <Tooltip title="Revive Ledger">
                <SyncOutlined
                  style={{ color: "green" }}
                  // className="ca-delete-btn cursor"
                  onClick={() => {
                    setSelectChange("Revive");
                    setConfirmationActive(true);
                    setOpenModel({ ...openModel, id: record?.id });
                  }}
                />
              </Tooltip>
            )}
          </div>
        </Skeleton>
      ),
    },
  ];

  //----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "number",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    // console.log("sort", sort);

    // if (sort?.group_Type) {
    // console.log("sort", sort?.group_Type);
    // setFilterGroupTypes(sort?.group_Type);
    // }
  };

  // document.addEventListener("keydown", (e) => {
  //   if (e.altKey && e.key.toLowerCase() === "o") {
  //     setOpenModel({ open: true, id: "" });
  //   }
  // });

  //----- Short-Cut for Open Model TimeCondition -----

  // const exportCsv = () => {
  //   LedgerService.exportCsv(
  //     currentClient?.id!,
  //     serchAllField,
  //     searchColumns.parentGroup,
  //     filter.sortCol,
  //     filter.sortDir,
  //     "",
  //     ledgerStatus,
  //     searchColumns.GSTNo,
  //     selectIndState,
  //     searchColumns?.contPerson,
  //     searchColumns?.contPhone
  //     // invoiceStatus
  //   )
  //     .then((res: any) => {
  //       if (res) {
  //         console.log("download sucess");
  //       }
  //     })
  //     .catch((ex: any) => {
  //       openNotification("error", ex.message);
  //     });
  // };

  const generateFooterRow = (columns: any) => {
    return columns?.map((col: any) => "");
  };

  let path = "Ledger List";
  const columnsA = [
    "Ledger Name",
    "Parent Group",
    "Balance",
    "GSTIN",
    "State",
    "Contact Person",
    "Contact No",
  ];

  const fieldMapping = {
    "Ledger Name": (item: any) => item?.ledgerName,
    "Parent Group": (item: any) => item?.parentGroup,
    Balance: (item: any) => item?.balance,
    GSTIN: (item: any) => item?.gstin,
    State: (item: any) => getIndianStates(item?.state),
    "Contact Person": (item: any) => item?.name,
    "Contact No": (item: any) => item?.phone,
  };
  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      "",
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };
  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportLedgerData.length > 0) {
              exportCsv(exportLedgerData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportLedgerData.length > 0) {
              exportPDF(exportLedgerData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSL"
          onClick={() => {
            if (exportLedgerData.length > 0) {
              exportExcel(exportLedgerData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSL
        </Button>
      </div>
    </Space>
  );
  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <React.Fragment>
        <Row
          justify="space-between"
          style={
            {
              // paddingTop: props?.partyId ? "10px" : "0px"
              // marginTop: 10,
              // padding: "0px 0px 10px 0px",
            }
          }
          // style={{ position: "sticky",zIndex:1, top: 0, padding: "5px" }}
        >
          <Col>
            <Space size={width <= 768 ? 465 : 5}>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setOpenModel({ open: true, id: "" })}
                >
                  Ledger
                </Button>
              </Col>

              <Col>
                {ledgerData?.length === 0 ? (
                  <>
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      // onClick={() => exportCsv()}
                      disabled
                    >
                      Export
                    </Button>
                  </>
                ) : (
                  <>
                    <Popover
                      content={content}
                      placement="rightTop"
                      trigger="hover"
                    >
                      <Button
                        type="default"
                        icon={<DownloadOutlined />}
                        // onClick={() => exportCsv()}
                      >
                        Export
                      </Button>
                    </Popover>
                  </>
                )}
              </Col>
              {/* <Button
              style={{
                marginLeft: "10px",
              }}
              icon={<ArrowDownOutlined />}
            >
              Export
            </Button> */}
            </Space>
          </Col>

          <Col
            style={{
              marginTop: width <= 768 ? 15 : "",
            }}
          >
            <Row gutter={width <= 768 ? 98 : 8}>
              <Col>
                <Input
                  allowClear
                  style={{ width: "200px", border: "1px solid #f1f3ff" }}
                  placeholder="Search Ledger.."
                  onChange={(e: any) => {
                    setLoading(true);
                    if (!e.cancelable) {
                      //console.log("typeing user............");
                      const serchAllField = setTimeout(() => {
                        setPage(1);
                        setSerchAllField(e.target.value);
                      }, 1000);
                      return () => clearTimeout(serchAllField);
                    } else {
                      // console.log("Click cancle............");
                      setSerchAllField(e.target.value);
                    }
                    setExportLedgerData([]);
                    setExportType(-1);
                  }}
                  suffix={
                    loading || serchAllField ? (
                      ""
                    ) : (
                      <img alt="Search.." src={search} />
                    )
                  }
                />
              </Col>

              <Col>
                <Select
                  defaultValue={false}
                  style={{ width: "200px" }}
                  onChange={(e: boolean) => {
                    setPage(1);
                    setOnlyAmountValue(e);
                    setExportLedgerData([]);
                    setExportType(-1);
                  }}
                  options={[
                    { value: false, label: "All Ledgers" },
                    { value: true, label: "Having Transactions Only" },
                  ]}
                />
              </Col>

              <Col>
                <Select
                  defaultValue={LedgerStatus.active}
                  style={{ width: "100px" }}
                  onChange={(e: LedgerStatus) => {
                    setPage(1);
                    setLedgerStatus(e);
                    setExportLedgerData([]);
                    setExportType(-1);
                  }}
                  options={[
                    { value: LedgerStatus.all, label: "All" },
                    { value: LedgerStatus.active, label: "Active" },
                    { value: LedgerStatus.deactive, label: "De-Active" },
                    { value: LedgerStatus.deleted, label: "Delete" },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* --------- Table --------- */}
        <div>
          <Table
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: pageSize }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as IItemListData[])
                : ledgerData
            }
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            onChange={tableSort}
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            // rowSelection={rowSelection}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
            scroll={
              pageSize > 15
                ? { x: 1500, y: window.innerHeight - 300 }
                : undefined
            }
          />
        </div>
        {openModel.open && (
          <AddLedger
            openDrawer={openModel.open}
            id={openModel?.id}
            setOpenDrawer={() => setOpenModel({ open: false, id: "" })}
            setTrigger={() => setTrigger(!trigger)}
          />
        )}
        {confirmationActive && (
          <ConfirmationModal
            open={confirmationActive}
            onNo={() => setConfirmationActive(false)}
            onYes={async () =>
              await LedgerService.makeLedgerActiveInactive(
                currentClient?.id!,
                openModel?.id
              )
                .then((res: any) => {
                  if (res.result) {
                    console.log("res", res);
                    setTrigger((x: boolean) => !x);
                    openNotification("success", `${selectChange} Successfully`);
                    setConfirmationActive(false);
                  } else if (res?.message) {
                    openNotification("error", res?.message);
                    setConfirmationActive(false);
                  }
                })
                .catch((err: any) => {
                  openNotification("error", err);
                  setConfirmationActive(false);
                  console.log("error", err);
                })
            }
            text={`Are you sure you want to ${selectChange} Ledger ?`}
          />
        )}
        {confirmationDeleted && (
          <ConfirmationModal
            open={confirmationDeleted}
            onNo={() => setConfirmationDelete(false)}
            onYes={async () =>
              await LedgerService.deleteLedgerMaster(
                currentClient?.id!,
                openModel?.id
              )
                .then((res: any) => {
                  if (res.result) {
                    console.log("res", res);
                    setTrigger((x: boolean) => !x);
                    openNotification("success", "Delete Successfully");
                    setConfirmationDelete(false);
                  } else if (res?.message) {
                    debugger;
                    openNotification("error", res?.message);
                    setConfirmationDelete(false);
                  }
                })
                .catch((err: any) => {
                  openNotification("error", err);
                  // setConfirmationDelete(false);
                  console.log("error", err);
                })
            }
            text="Are you sure you want to Delete Ledger ?"
            loading={false}
          />
        )}
      </React.Fragment>
      {contextHolder}
    </Card>
  );
};

export default LedgerTable;
