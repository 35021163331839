import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  notification,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React from "react";
import AddProduct from "./AddProduct";
import { useTheme } from "../../Context/ThemeContext";
import { ColumnsType } from "antd/es/table";
import ProductService, {
  ApplicationUserStatus,
} from "../../Services/ProductService";
import { notificationContext } from "../../Common/PageRoute";

const Product = () => {
  const { themeData } = useTheme();
  const { openNotification } = React.useContext(notificationContext);
  const [isModelOpen, setIsModelOpen] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [productList, setProductList] = React.useState<any[]>();
  const [productId, setProductId] = React.useState<string | undefined>();
  const [status, setStatus] = React.useState<ApplicationUserStatus>(
    ApplicationUserStatus?.Active
  );

  React.useEffect(() => {
    getProductDetails();
  }, [refresh, status]);

  const getProductDetails = async () => {
    setLoading(true);
    try {
      let res = await ProductService?.getProductDetails(0, 15, "", status);
      if (res) {
        console.log("res", res);
        setProductList(
          res?.result?.map((d: any, index: number) => {
            return {
              id: d?.id,
              sNo: index + 1,
              name: d?.name,
            };
          })
        );
        setLoading(false);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "some error",
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const deleteDataById = async (id: string) => {
    let res = await ProductService?.deleteDataById(id);
    if (res?.status) {
      notification.success({
        message: "Success",
        description: "deleted successfully.",
      });
      getProductDetails();
    } else {
      notification.error({
        message: "Error",
        description: res?.message,
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "S. No.",
      dataIndex: "sNo",
      width: "10%",
    },
    {
      title: "Product Name",
      dataIndex: "name",
    },

    {
      title: "Action",
      dataIndex: "",
      width: "10%",
      render: (_: any, record: any) => (
        <>
          <Row gutter={14}>
            <Col>
              <EditOutlined
                onClick={() => {
                  setIsModelOpen(true);
                  setProductId(record?.id);
                }}
              />
            </Col>
            <Col>
              <DeleteOutlined
                style={{ color: "red" }}
                onClick={() => deleteDataById(record?.id)}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <Card
          size="small"
          className="TableCard"
          style={{
            margin: "15px",
            height: "100%",
            border: "1px solid #eef2fe",
          }}
        >
          <Spin spinning={loading} indicator={<LoadingOutlined />}>
            <div style={{ padding: "5px 10px" }}>
              <Row gutter={15}>
                <Col>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setIsModelOpen(true);
                      setProductId("");
                    }}
                  >
                    Product
                  </Button>
                </Col>
                <Col>
                  <span style={{ margin: "0px 10px" }}>Status :</span>
                  <Select
                    style={{ width: "200px" }}
                    defaultValue={status}
                    options={[
                      {
                        value: ApplicationUserStatus?.Active,
                        label: "Active",
                      },
                      {
                        value: ApplicationUserStatus?.Deleted,
                        label: "Deleted",
                      },
                      {
                        value: ApplicationUserStatus?.Inactive,
                        label: "In-Active",
                      },
                      {
                        value: ApplicationUserStatus?.All,
                        label: "All",
                      },
                    ]}
                    onChange={(val: any) => setStatus(val)}
                  />
                </Col>
              </Row>

              <div style={{ marginTop: "15px" }}>
                <Table
                  columns={columns}
                  dataSource={productList}
                  className={`Tabel-style table-${
                    themeData?.componentSize ?? "middle"
                  }`}
                />
              </div>
            </div>
          </Spin>
        </Card>
      </div>

      {isModelOpen && (
        <AddProduct
          onCancel={() => setIsModelOpen(false)}
          isModelOpen={isModelOpen}
          setRefresh={setRefresh}
          productId={productId}
        />
      )}
    </>
  );
};

export default Product;
