import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Popover,
  Divider,
  Card,
  Skeleton,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import { dayjs } from "../../../Utilities/dayjs";
import PurchaseService from "../../Services/PurchaseService";
import ViewPaymentOut from "./ViewPaymentOut";
import { Utils } from "../../../Utilities/Utils";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import ViewPurchaseInvoice from "./ViewPurchaseInvoice";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";

interface IPurchasePaymentListData {
  key: string;
  id: string;
  number: { name: string; id: string };
  name: number;
  date: string;
  account: string;
  amount: number;
  invoices: any[];
  supplierName: string;
}

const PurchasePaymentOut: React.FC<{ partyId?: string }> = (props) => {
  const navigate = useNavigate();
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const { currentClient } = useClient();
  const [open, setIsOpen] = React.useState<boolean>(false);
  const { openNotification } = useContext(notificationContext);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [paymentId, setPaymentId] = React.useState<string>("");
  const [salesInvoiceId, setSalesInvoiceId] = React.useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openPopArray, setOpenPopArray] = useState<boolean[]>([]);
  const [openInvoiceNoPopArray, setOpenInvoiceNoPopArray] = useState<boolean[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [paymentOutList, setPaymentOutList] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [searchSupplier, setSearchSupplier] = React.useState<string>("");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [filterDate, setFilterDate] = React.useState<any>({ from: "", to: "" });
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [paymentOutStatus, setPaymentOutStatus] =
    React.useState<string>("active");
  const [openPurchaseInvoice, setOpenPurchaseInvoice] =
    React.useState<boolean>(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [deletePaymentOut, setDeletePaymentOut] = React.useState<any>({
    id: "",
    isRev: false,
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  // --------- Table Columns  ---------
  const columns: ColumnsType<IPurchasePaymentListData> = [
    {
      title: "PO No.",
      dataIndex: "number",
      width: width <= 1024 ? "12%" : "7%",
      render: (x: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
              setPaymentId(x?.id);
            }}
          >
            {x?.no}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Invoices",
      dataIndex: "invoices",
      width: width <= 1440 ? "15%" : "10%",
      render: (_: any, record: any, index: number) => (
        <>
          <Row justify={"start"} align={"middle"} className="p-0">
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <span
                style={{ cursor: "pointer", color: "#1677FF" }}
                onClick={() => {
                  setOpenPurchaseInvoice(true);
                  setSalesInvoiceId(record?.invoices[0]?.purchaseInvoice?.id);
                }}
              >
                {record?.invoices?.length > 0 &&
                  record?.invoices[0]?.purchaseInvoice?.name}
              </span>
            </Skeleton>
            {record?.invoices?.length > 1 && (
              <Popover
                open={openInvoiceNoPopArray[index]}
                arrow={{ pointAtCenter: true }}
                onOpenChange={(newOpen) =>
                  invoicePopoverOnchange(index, newOpen)
                }
                placement="rightTop"
                trigger="click"
                content={() => (
                  <>
                    <h3 className="my-1">Invoices</h3>
                    <div
                      style={{
                        maxHeight: 300,
                        maxWidth: 200,
                        overflow: "auto",
                      }}
                    >
                      {record?.invoices?.map((v: any, index: any) => (
                        <>
                          {index > 0 && (
                            <div key={index}>
                              <Divider className="m-0" key={index} />
                              <div
                                style={{ cursor: "pointer", color: "#1677FF" }}
                                className="p-1"
                                key={index + 1}
                                onClick={() => {
                                  setOpenPurchaseInvoice(true);
                                  setSalesInvoiceId(v?.purchaseInvoice?.id);
                                  invoicePopoverOnchange(index, false);
                                }}
                              >
                                {v?.purchaseInvoice?.name}
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </>
                )}
              >
                <div
                  style={{
                    // color: "#1677FF",
                    cursor: "pointer",
                    paddingLeft: 10,
                  }}
                >{`${record?.invoices.length - 1} more`}</div>
              </Popover>
            )}
          </Row>
        </>
      ),
    } as any,
    {
      title: "Date",
      dataIndex: "date",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Account",
      dataIndex: "account",
      width: `${props.partyId ? "28%" : "18%"}`,
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.account}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "10%",
      align: "right",
      render: (x) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹ {x}
        </Skeleton>
      ),
    },
    {
      title: "  ",
      width: "5%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          <FiDownload style={{ color: "#395cd2" }} />
        </Skeleton>
      ),
    },
    paymentOutStatus !== "deleted"
      ? {
          title: "  ",
          width: "5%",
          align: "center",
          render: (_, record, index) => (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
              className="cursor"
            >
              <Popover
                arrow={{ pointAtCenter: true }}
                style={{ padding: 0 }}
                placement="bottomRight"
                open={openPopArray[index]}
                onOpenChange={(newOpen) => handleOpenChange(index, newOpen)}
                content={
                  <Button
                    onClick={() => {
                      setConfirmation(true);
                      setPaymentId(record?.id);
                      handleOpenChange(index, false);
                    }}
                  >
                    Delete
                  </Button>
                }
                trigger="click"
              >
                <HiDotsHorizontal
                  style={{ color: "#395cd2", cursor: "pointer" }}
                />
              </Popover>
            </Skeleton>
          ),
        }
      : {},
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Supplier Name",
      dataIndex: "name",
      width: width <= 1024 ? "20%" : "30%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          {record?.name}
        </Skeleton>
      ),
    });
  }

  const handleOpenChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };
  const invoicePopoverOnchange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenInvoiceNoPopArray(updatedOpenPopArray);
  };

  // --------- Get TableData API ---------
  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getList(
        currentClient?.id,
        (page - 1) * pageSize,
        pageSize,
        searchSupplier,
        filterDate.from,
        filterDate.to,
        paymentOutStatus,
        filter.sortCol,
        filter.sortDir,
        props?.partyId
      );
    } else if (currentClient?.id === null) {
    }
  }, [
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    currentClient?.id,
    paymentOutStatus,
    searchSupplier,
    refreshAtDelete,
    props?.partyId,
  ]);

  const getList = async (
    clientId: string,
    start: number,
    length: number,
    search?: string,
    fromDate?: string,
    toDate?: string,
    status?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string
  ) => {
    await PurchaseService.getPaymentOutList(
      clientId,
      start,
      length,
      search,
      fromDate,
      toDate,
      status,
      sortCol,
      sortDir,
      partyId
    )
      .then((res: any) => {
        if (res?.items?.length > 0) {
          setPaymentOutList(
            res?.items?.map((v: any) => {
              return {
                key: v?.sno,
                id: v?.id,
                number: { no: v?.number, id: v?.id },
                name: v?.supplier?.name,
                date: dayjs(v?.date).format("DD/MM/YYYY"),
                account: v?.acc_Account?.name,
                amount: Utils.getFormattedNumber(v?.availableAmount),
                invoices: v?.item,
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else if (res?.items?.length === 0) {
          setPaymentOutList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setPaymentOutList([]);
      });
  };

  console.log("hello", "..........................");

  React.useEffect(() => {
    console.log("deleteCreditNote.id", deletePaymentOut?.id);
    if (deletePaymentOut?.id) {
      const DeleteCreditNote = async () => {
        setLoading(true);
        await PurchaseService.deletePaymentOut(
          currentClient?.id!,
          deletePaymentOut.isRev,
          deletePaymentOut.id
        ).then((res) => {
          setLoading(false);
          if (res?.result) {
            setRefreshAtDelete(!refreshAtDelete);
            openNotification("success", `Entry deleted successfully`);
          }
        });
      };
      DeleteCreditNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymentOut]);

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        width: "97%",
        marginLeft: "16px",
        marginTop: "24px",
        border: "1px solid #eef2fe",
      }}
    >
      <Row
        justify="space-between"
        style={{
          //  paddingTop: props.partyId ? "10px" : "0px"
          marginTop: 10,
          padding: "10px 18px 3px 18px",
        }}
      >
        <Col>
          <Space size={width <= 768 ? 440 : 5}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addPaymentOut/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addPaymentOut`
                    );
              }}
            >
              Payment Out
            </Button>
          </Space>
        </Col>
        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 62 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Supplier"
                allowClear
                onChange={(e: any) => {
                  setPage(1);
                  setSearchSupplier(e.target.value);
                }}
                suffix={
                  searchSupplier ? "" : <img alt="Search.." src={search} />
                }
              />
            </Col>
            <Col>
              <RangePicker
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                style={{ width: 120 }}
                value={paymentOutStatus}
                options={[
                  { value: "active", label: "Active" },
                  { value: "deleted", label: "Deleted" },
                ]}
                onSelect={(val) => {
                  setPage(1);
                  setPaymentOutStatus(val);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        scroll={
          pageSize > 10
            ? { x: width <= 768 ? 1000 : 700, y: 370 }
            : { x: width <= 768 ? 1000 : 600 }
        }
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IPurchasePaymentListData[])
            : paymentOutList
        }
        onChange={tableSort}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeletePaymentOut({
              id: paymentId,
              isRev: paymentOutStatus === "deleted" ? true : false,
            });
            setConfirmation(false);
          }}
          text={`Are you sure you want to delete?`}
        />
      )}
      {open && (
        <ViewPaymentOut
          open={open}
          onCancel={() => setIsOpen(false)}
          paymentId={paymentId}
        />
      )}
      {openPurchaseInvoice && (
        <ViewPurchaseInvoice
          open={openPurchaseInvoice}
          onCancel={() => setOpenPurchaseInvoice(false)}
          invoiceId={salesInvoiceId}
        />
      )}
    </Card>
  );
};
export default PurchasePaymentOut;
