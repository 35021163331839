import { Col, Row, Skeleton, Table } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const inputStyle = {
  height: "18px",
  width: "2px",
};


export const SkeletonTable: React.FC<{
  columns?: number;
  rowCount?: number;
}> = (props) => {
  const columns1 = new Array(props.columns);
  const rowCount1 = new Array(props.rowCount);

  return (
    <Table
      style={{ marginTop: "29px" }}
      rowKey="key"
      pagination={false}
      dataSource={columns1.map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={rowCount1.map((column: any) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return (
              <Skeleton.Input
                active
                key={column.dataIndex}
                size="small"
                style={{ height: "100vh", width: "200px" }}
              />
            );
          },
        };
      })}
    />
  );
};

export const AmountSkeleton = () => {
  return (
    <>
      <Skeleton.Input style={{ height: "20px" }} />
    </>
  );
};
export const SiderSkeleton = () => {
  return (
    <div style={{ height: "100vh", width: "200px" }}>
      <Skeleton.Avatar
        shape="square"
        style={{ height: "100vh", width: "200px" }}
      />
    </div>
  );
};
export const HeaderSkeleton = (props: any) => {
  const location = useLocation();
  let splittedpath = location.pathname.split("/");
  let path =
    splittedpath[splittedpath.length - 2] +
    splittedpath[splittedpath.length - 1];

  let InvPath = splittedpath[splittedpath.length - 1];

  return (
    <div
      style={{
        height: props.loading && InvPath === "addInvoice" ? "55px" : "80px",
        width: "100%",
      }}
    >
      <Skeleton.Avatar
        shape="square"
        active
        style={{
          height: props.loading && InvPath === "addInvoice" ? "55px" : "80px",
          width: "1480px",
        }}
      />
    </div>
  );
};
export const LayoutSkeleton = () => {
  return (
    <>
      <div style={{ display: "flex", marginTop: "-2px" }}>
        <SiderSkeleton />

        <div style={{ width: "100%" }}>
          <HeaderSkeleton />
          <span style={{ marginLeft: "1100px" }}>
            <Skeleton.Avatar
              shape="square"
              active
              style={{
                width: "350px",
                marginTop: "35px",
                marginBottom: "25px",
                borderRadius: "12px",
              }}
            />
          </span>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <Skeleton.Avatar
                shape="square"
                active
                style={{ width: "300px", height: "155px", borderRadius: "5px" }}
              />
            </div>
            <div>
              <Skeleton.Avatar
                shape="square"
                active
                style={{ width: "300px", height: "155px", borderRadius: "5px" }}
              />
            </div>
            <div>
              <Skeleton.Avatar
                shape="square"
                active
                style={{ width: "300px", height: "155px", borderRadius: "5px" }}
              />
            </div>
            <div>
              <Skeleton.Avatar
                shape="square"
                active
                style={{ width: "300px", height: "155px", borderRadius: "5px" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "58px",
            }}
          >
            <div>
              <Skeleton.Avatar
                shape="square"
                active
                style={{ height: "450px", width: "850px", borderRadius: "5px" }}
              />
            </div>
            <div>
              <Skeleton.Avatar
                shape="square"
                active
                style={{ height: "450px", width: "500px", borderRadius: "5px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const BodySkeleton = (props: any) => {
  const myData = [
    <Skeleton.Avatar active shape="square" style={{ width: "145px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "150px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "105px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "105px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "105px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "105px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "110px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "110px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "110px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "110px" }} />,
    <Skeleton.Avatar active shape="square" style={{ width: "150px" }} />,
  ];
  return (
    <>
      <Row style={{ marginTop: "12px" }}>
        <Col lg={18}>
          <Skeleton.Input
            active
            style={{
              height: "200px",
              marginLeft: "15px",
              marginTop: "5px",
              width: "980px",
            }}
          />
        </Col>
        <Col lg={6}>
          <Skeleton.Input
            active
            style={{
              height: "25px",
              marginLeft: "165px",
              marginTop: "25px",
            }}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: "12px" }}>
        <Col lg={24}>
          <Skeleton.Input
            active
            style={{ marginLeft: "15px", width: "1440px", height: "48px" }}
          />
        </Col>
      </Row>
      <table style={{ marginLeft: "60px" }}>
        <tr>
          {myData.map((value, index) => (
            <td key={index}>{value}</td>
          ))}
        </tr>
      </table>

      <Row>
        <Col lg={16}></Col>
        <Col lg={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Skeleton
                active
                style={{
                  marginTop: "3px",
                  width: "250px",
                  marginLeft: "20px",
                }}
                paragraph={{ rows: 0 }}
              ></Skeleton>
            </div>
            <div>
              <Skeleton
                active
                paragraph={{ rows: 0 }}
                style={{
                  marginTop: "3px",
                  width: "150px",
                  marginLeft: "15px",
                }}
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Skeleton
                active
                style={{
                  width: "250px",
                  marginLeft: "20px",
                }}
                paragraph={{ rows: 0 }}
              ></Skeleton>
            </div>
            <div>
              <Skeleton
                active
                paragraph={{ rows: 0 }}
                style={{
                  width: "150px",
                  marginLeft: "15px",
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Skeleton.Avatar
                shape="circle"
                active
                style={{
                  height: "11px",
                  marginLeft: "20px",
                  width: "11px",
                }}
              ></Skeleton.Avatar>
              <Skeleton.Avatar
                shape="circle"
                active
                style={{
                  marginLeft: "45px",
                  height: "11px",
                  width: "11px",
                }}
              ></Skeleton.Avatar>
              <Skeleton.Avatar
                active
                shape="square"
                style={{
                  marginLeft: "45px",
                  height: "20px",
                  width: "85px",
                }}
              ></Skeleton.Avatar>
            </div>
            <div>
              <Skeleton
                paragraph={{ rows: 0 }}
                active
                style={{
                  marginTop: "3px",
                  width: "150px",
                  marginLeft: "15px",
                }}
              />
            </div>
          </div>
          <div>
            <Skeleton.Avatar
              shape="square"
              active
              style={{ height: "2px", width: "400px", marginLeft: "5px" }}
            />
            <Skeleton.Avatar
              shape="square"
              active
              style={{
                height: "2px",
                marginTop: "8px",
                width: "400px",
                marginLeft: "5px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "250px",
            }}
          >
            <div>
              <Skeleton.Button active />
              <Skeleton.Button active style={{ marginLeft: "25px" }} />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export const InvoiceSkeleton = (props: any) => {
  const { loading, setLoading } = props;
  // const [col, setCol] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);

  return (
    <>
      <div style={{ display: "flex", marginTop: "-2px" }}>
        <SiderSkeleton />

        <div style={{ width: "100%" }}>
          <HeaderSkeleton loading={loading} setLoading={setLoading} />
        </div>
      </div>
      <BodySkeleton loading={loading} setLoading={setLoading} />
    </>
  );
};
export const ExpensesSkeleton = (props: any) => {
  return (
    <>
      <Row>
        <Col lg={18}>
          <Skeleton.Avatar
            shape="square"
            active
            style={{ width: "720px", marginLeft: "15px", height: "150px" }}
          />
        </Col>
        <Col lg={6}>
          <Skeleton.Avatar
            shape="square"
            active
            style={{
              justifyContent: "end",
              marginLeft: "220px",
              height: "25px",
              marginTop: "20px",
              width: "100px",
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col lg={24}>
          <Skeleton.Avatar
            shape="square"
            active
            style={{ height: "20px", width: "1380px", marginLeft: "15px" }}
          />
        </Col>
      </Row>
      <Row gutter={6} style={{ marginLeft: "40px", marginTop: "2px" }}>
        <Col>
          <Skeleton.Input active style={{ width: "390px" }} />
        </Col>
        <Col>
          <Skeleton.Input active style={{ width: "2px" }} />
        </Col>
        <Col>
          <Skeleton.Input active style={{ width: "388px" }} />
        </Col>
        <Col>
          <Skeleton.Input active style={{ width: "388px" }} />
        </Col>
      </Row>
      <Row>
        <Col lg={16}></Col>
        <Col lg={8}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Skeleton
                active
                style={{
                  marginTop: "3px",
                  width: "250px",
                  marginLeft: "20px",
                }}
                paragraph={{ rows: 0 }}
              ></Skeleton>
            </div>
            <div>
              <Skeleton
                active
                paragraph={{ rows: 0 }}
                style={{
                  marginTop: "3px",
                  width: "150px",
                  marginLeft: "15px",
                }}
              />
            </div>
          </div>
          <div>
            <Skeleton.Avatar
              shape="square"
              active
              style={{ height: "2px", width: "400px", marginLeft: "5px" }}
            />
          </div>

          {/* ------------------ */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Skeleton
                active
                style={{
                  width: "250px",
                  marginLeft: "20px",
                }}
                paragraph={{ rows: 0 }}
              ></Skeleton>
            </div>
            <div>
              <Skeleton
                active
                paragraph={{ rows: 0 }}
                style={{
                  width: "150px",
                  marginLeft: "15px",
                }}
              />
            </div>
          </div>
          <Skeleton.Avatar
            shape="square"
            active
            style={{
              height: "2px",
              // marginTop: "8px",
              width: "400px",
              marginLeft: "5px",
            }}
          />
          <Row style={{ marginLeft: "150px" }} gutter={18}>
            <Col>
              <Skeleton.Button active />
            </Col>
            <Col>
              <Skeleton.Button active />
            </Col>
            <Col>
              <Skeleton.Button active />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
