import React, { useContext } from "react";
import { Avatar, Col, Divider, Modal, Row, Tooltip, Button, Spin } from "antd";
import "../../Common/AddPageCommon.css";
import noteContext from "../../Context/StyleContext";
import { Utils } from "../../../Utilities/Utils";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useNavigate } from "react-router-dom";
import { EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import { notificationContext } from "../../Common/PageRoute";
import ViewSalesInvoiceNoDetails from "./ViewSalesInvoiceNoDetails";
import VoucherServices, { Voucher_Type } from "../../Services/VoucherServices";
import { getIndianStates } from "../../Services/ClientService";
import { useUser } from "../../../Context/UserContext";

const ViewSalesInvoice: React.FC<{
  open: boolean;
  onCancel?: any;
  invoiceId?: string | undefined;
  invVoucharType?: Voucher_Type;
  type?: any;
}> = (props) => {
  //console.log(props.invoiceId, "resid");
  const navigate = useNavigate();
  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const { currentClient, companySettings } = useClient();
  const { windowWidth } = React.useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const [data, setData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isGSTEnabled, setGstEnabled] = React.useState<boolean>(false);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [freightLength, setFreightLength] = React.useState<number>(0);
  // const [isInCash, setIsInCash] = React.useState<boolean>(false);
  const [isStateSame, setIsStateSame] = React.useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [voucherType, setVoucherType] = React.useState<Voucher_Type>(
    Voucher_Type.SalesVoucher
  );
  const [freightCheckbox, setfreightCheckbox] = React.useState<boolean>(true);
  const emptystring = " ";
  const parser = new DOMParser();
  React.useEffect(() => {
    if (props.invoiceId) {
      setLoading(true);
      VoucherServices.getVoucharById(
        currentClient?.id!,
        props.invoiceId,
        props.invVoucharType
      )
        .then((res: any) => {
          const resp = res?.result;
          if (res) {
            if (resp) {
              console.log("Check Result:", resp);
              setVoucherType(resp?.voucherType);
              // setGstEnabled(resp?.isGstEnabled ?? true);
              setGstEnabled(
                resp?.taxSummary?.grandTaxableAmount > 0 ? true : false
              );
              setItemLength(resp?.items.length);
              setFreightLength(resp?.freightInsLedgers?.length);
              setIsStateSame(resp?.isStateSame);
              setData({
                number: resp?.number,
                invoiceId: resp?.id,
                ledgerId: resp?.partyLedger[0]?.id,
                ledgerName: resp?.partyLedger[0]?.ledgerName,
                phoneNo: resp?.phoneNo,
                purchaseOrderRef: resp?.purchaseOrderRef?.name,
                voucherStatus: resp?.voucherStatus,
                billingAddress: resp?.billingAddress?.address
                  .split(" \n")
                  .map((item: string, index: number) => (
                    <React.Fragment key={index}>
                      {item}
                      <br />
                    </React.Fragment>
                  )),
                shippingAddress:
                  resp?.shippingAddress?.address !== null
                    ? resp?.shippingAddress?.address
                        .split(" \n")
                        .map((item: string, index: number) => (
                          <React.Fragment key={index}>
                            {item}
                            <br />
                          </React.Fragment>
                        ))
                    : "",
                effectiveLedger: resp?.effectiveLedger[0],
                supplyState: getIndianStates(resp?.supplyState),
                date: Utils.getFormattedDate(resp?.date),
                dueDate: Utils.getFormattedDate(resp?.dueDate),
                items: resp?.items,
                narration: resp?.narration,
                taxSummary: resp?.taxSummary,
                totalQty: Utils.getFormattedNumber(
                  resp.amountSummary?.totalQty
                ),
                totalPrice: Utils.getFormattedNumber(
                  resp.amountSummary?.totalPrice
                ),
                totalDiscount: Utils.getFormattedNumber(
                  resp.amountSummary?.totalDis
                ),
                sellPrice: Utils.getFormattedNumber(
                  resp.amountSummary?.taxableAmount
                ),
                dueBalance: Utils.getFormattedNumber(
                  resp.amountSummary?.dueBalance
                ),
                itemsTotalAmount: Utils.getFormattedNumber(
                  Math.abs(resp?.effectiveLedger[0]?.amount) +
                    resp.amountSummary?.totalItemGSTAmt
                ),
                cifExpenses: Utils.getFormattedNumber(
                  resp.amountSummary?.cifExpenses
                ),
                ledgerTotalAmount:
                  resp?.amountSummary?.cifExpenses +
                  resp?.amountSummary?.totalCIFGSTAmt,
                ledgerGstAmount: Utils.getFormattedNumber(
                  resp?.amountSummary?.totalCIFGSTAmt
                ),
                totalItemGSTAmt: Utils.getFormattedNumber(
                  resp?.amountSummary?.totalItemGSTAmt
                ),
                igstAmt: Utils.getFormattedNumber(resp?.amountSummary?.igstAmt),
                cgstAmt: Utils.getFormattedNumber(resp?.amountSummary?.cgstAmt),
                sgstAmt: Utils.getFormattedNumber(resp?.amountSummary?.sgstAmt),
                received: resp.amount?.paid,
                refund: resp.amount?.refund,
                // dueAmount: resp.amount?.due,
                totalgst: resp.amountSummary?.igst
                  ? resp.amountSummary?.igst
                  : resp.amountSummary?.sgst + resp.amountSummary?.cgst,
                igst: resp.amountSummary?.igst,
                cgst: resp.amountSummary?.cgst,
                sgst: resp.amountSummary?.sgst,
                receivedAmount: Utils.getFormattedNumber(
                  resp.amountSummary?.receivedAmount
                ),
                dueAmount: Utils.getFormattedNumber(
                  resp?.amountSummary?.dueAmount
                ),

                totalInvoiceAmount: Utils.getFormattedNumber(
                  resp.amountSummary?.totalInvoiceAmount
                ),
                freightInsLedgers: resp?.freightInsLedgers,
                totalInvoiceAmountInWords:
                  resp?.amountSummary?.totalInvoiceAmountInWords,
              });
            }

            setLoading(false);
          } else setLoading(false);
          console.log("Data Result: ", data);
        })
        .catch((ex: any) => {
          openNotification("error", ex.message);
          setLoading(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.invoiceId]);

  // console.log(freightLength, "checklength");
  //console.log(data.totalItemGSTAmt,"gstamounttoatal");
  // console.log(data?.dueAmount, "testDueAmount");
  console.log("data", data);

  return (
    <Modal
      open={props.open}
      width={"75%"}
      style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
      maskClosable={false}
      closable={false}
      onCancel={() => props.onCancel()}
      centered
      footer={
        <div style={{ marginTop: "0px" }} className="pe-15">
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          {data?.voucherStatus === 1 ? (
            <></>
          ) : (
            <>
              <Button
                className="modelSaveButtonMargin"
                type="primary"
                style={{ width: "90px" }}
                onClick={async () => {
                  props.invVoucharType === Voucher_Type.SalesVoucher
                    ? navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editInvoice/${props.invoiceId}`
                      )
                    : navigate(
                        `/accounts/clients/${currentClient?.id}/purchase/editPurchasebill/${props.invoiceId}`
                      );
                }}
              >
                Edit
              </Button>
            </>
          )}
          {/* <Button type="primary">Print</Button> */}
        </div>
      }
    >
      <>
        {data?.voucherStatus === 1 ? (
          <>
            <h2>This voucher has been deleted</h2>
          </>
        ) : (
          <>
            <div style={{ padding: "3px 15px" }}>
              <Spin indicator={antIcon} spinning={loading}>
                {/* {windowWidth <= 1024 ? (
            <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
              Purchase Invoice
            </h3>
          ) : (
            ""
          )} */}
                <Row>
                  <Col span={12}>
                    <>
                      <div>
                        <span style={{ fontSize: "1.5em" }}>
                          {companySettings?.setting.companyInfo.name}
                        </span>
                      </div>
                      <div>
                        {companySettings?.setting?.companyInfo?.regAddress
                          ?.building !== null ? (
                          <>
                            <span>
                              {
                                companySettings?.setting?.companyInfo
                                  ?.regAddress?.building!
                              }
                            </span>
                            {", "}
                          </>
                        ) : (
                          ""
                        )}
                        {companySettings?.setting?.companyInfo?.regAddress
                          ?.street !== null ? (
                          <>
                            <span>
                              {
                                companySettings?.setting?.companyInfo
                                  ?.regAddress?.street
                              }
                            </span>
                            {", "}
                          </>
                        ) : (
                          ""
                        )}
                        {companySettings?.setting?.companyInfo?.regAddress
                          ?.landmark !== null ? (
                          <>
                            <span>
                              {
                                companySettings?.setting?.companyInfo
                                  ?.regAddress?.landmark
                              }
                            </span>
                            {", "}
                          </>
                        ) : (
                          ""
                        )}
                        {companySettings?.setting?.companyInfo?.regAddress
                          ?.city !== null ? (
                          <>
                            <span>
                              {
                                companySettings?.setting?.companyInfo
                                  ?.regAddress?.city
                              }
                            </span>
                            {", "}
                          </>
                        ) : (
                          ""
                        )}
                        {companySettings?.setting?.companyInfo?.regAddress
                          ?.district !== null ? (
                          <>
                            <span>
                              {
                                companySettings?.setting?.companyInfo
                                  ?.regAddress?.district
                              }
                            </span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        {companySettings?.setting?.companyInfo?.regAddress
                          ?.state !== null ? (
                          <>
                            <span>
                              <strong>State: </strong>
                              {Object.entries(stateOptions)?.map(
                                ([key, s]: any) =>
                                  s.id ===
                                  companySettings?.setting?.companyInfo
                                    ?.regAddress?.state
                                    ? s.name
                                    : ""
                              )}
                              , <strong>Code: </strong>
                              {companySettings?.setting?.companyInfo?.regAddress?.state
                                .toString()
                                .padStart(2, "0")}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {companySettings?.setting?.companyInfo?.gstNumber !==
                        null ? (
                          <>
                            <span>
                              <strong>{", "}GSTIN: </strong>
                              {companySettings?.setting?.companyInfo?.gstNumber}
                            </span>
                            <br />
                          </>
                        ) : (
                          ""
                        )}

                        {companySettings?.setting?.companyContactDetails
                          ?.email !== null ? (
                          <>
                            <span>
                              <strong>Email: </strong>
                              {
                                companySettings?.setting?.companyContactDetails
                                  ?.email
                              }
                            </span>
                            {", "}
                          </>
                        ) : (
                          ""
                        )}
                        {companySettings?.setting?.companyContactDetails
                          ?.mobile !== null ? (
                          <>
                            <span>
                              <strong>Mobile: </strong>
                              {
                                companySettings?.setting?.companyContactDetails
                                  ?.mobile
                              }
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  </Col>
                  <Col span={12}>
                    <div>
                      <span
                        className="themeColor"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          fontSize: "1.5em",
                        }}
                      >
                        {props?.invVoucharType === 1
                          ? companySettings?.setting?.invoice?.title
                          : companySettings?.setting?.pInvoice?.title}
                      </span>
                    </div>
                  </Col>
                  <Divider className="my-5 grayBg"></Divider>
                </Row>
                <Row>
                  <Col span={24}>
                    <table className="tbl-customerinfo">
                      <tbody>
                        <tr>
                          <td
                            rowSpan={4}
                            width={"46%"}
                            style={{ padding: "5px", verticalAlign: "top" }}
                          >
                            <div>
                              <u>
                                {voucherType === Voucher_Type.SalesVoucher
                                  ? `Buyer (Bill to)`
                                  : "Supplier"}
                              </u>
                            </div>
                            <div>
                              <div>
                                <strong>{data?.ledgerName}</strong>
                              </div>
                              <div>{data?.billingAddress}</div>
                            </div>
                            {data?.shippingAddress === "" ? (
                              ""
                            ) : (
                              <>
                                <div>
                                  <u>{`Shipping Address`}</u>
                                </div>
                                <div>{data?.shippingAddress}</div>
                              </>
                            )}
                          </td>
                          <td width={"18%"} style={{ padding: "5px" }}>
                            <div>
                              <strong>Invoice No.</strong>
                            </div>
                            <div>{data.number}</div>
                          </td>
                          <td width={"18%"} style={{ padding: "5px" }}>
                            <div>
                              <strong>Date</strong>
                            </div>
                            <div>{data.date}</div>
                          </td>
                          <td width={"18%"} style={{ padding: "5px" }}>
                            <div>
                              <strong>Reference No.</strong>
                            </div>
                            <div>
                              {data.purchaseOrderRef === null ? (
                                <>&nbsp;</>
                              ) : (
                                data.purchaseOrderRef
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td width={"18%"} style={{ padding: "5px" }}>
                            <div>
                              <strong>Delivery Note No.</strong>
                            </div>
                            <div>{<>&nbsp;</>}</div>
                            {/* Need to add Delivery Note No. here */}
                          </td>
                          <td width={"18%"} style={{ padding: "5px" }}>
                            <div>
                              <strong>Delivery Note Date</strong>
                            </div>
                            <div>{<>&nbsp;</>}</div>
                            {/* Need to add Delivery Note Date here */}
                          </td>
                          <td width={"18%"} style={{ padding: "5px" }}>
                            <div>
                              <strong>Dispatched Through</strong>
                            </div>
                            <div>{<>&nbsp;</>}</div>
                            {/* Need to add Dispatch mode here */}
                          </td>
                        </tr>
                        <tr>
                          <td width={"18%"} style={{ padding: "5px" }}>
                            <div>
                              <strong>Place of Supply</strong>
                            </div>
                            <div>
                              {data.supplyState === null ? (
                                <>&nbsp;</>
                              ) : (
                                data.supplyState
                              )}
                            </div>
                          </td>
                          <td width={"18%"} style={{ padding: "5px" }}>
                            <div>
                              <strong>Mode / Terms of Payment</strong>
                            </div>
                            <div>{<>&nbsp;</>}</div>
                            {/* Need to add mode / terms of payment here */}
                          </td>
                          <td width={"18%"} style={{ padding: "5px" }}></td>
                        </tr>
                        <tr>
                          <td colSpan={3} style={{ padding: "5px" }}>
                            <div>
                              <strong>Terms & Conditions</strong>
                            </div>
                            <div>{<>&nbsp;</>}</div>
                            {/* Need to add terms and conditions here */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Divider className="my-5 grayBg"></Divider>
                </Row>

                <Row justify="space-between"></Row>
                <table className="ca-viewmodel-table">
                  <thead>
                    <tr>
                      <th colSpan={3} rowSpan={2} style={{ width: "7%" }}>
                        S.No.
                      </th>
                      <th
                        rowSpan={2}
                        style={{
                          width: "20%",
                          borderLeft: "1px solid rgb(240, 245, 255)",
                          textAlign: "left",
                        }}
                      >
                        Item
                      </th>
                      <th
                        rowSpan={2}
                        style={{
                          width: "3%",
                          borderLeft: "1px solid rgb(240, 245, 255)",
                          textAlign: "left",
                        }}
                      >
                        HSN/SAC
                      </th>
                      <th
                        rowSpan={2}
                        style={{ width: windowWidth <= 1024 ? "18%" : "18%" }}
                      >
                        Description
                      </th>

                      <th rowSpan={2} style={{ width: "5%" }}>
                        Qty
                      </th>
                      <th rowSpan={2} style={{ width: "6%" }}>
                        Unit
                      </th>
                      <th rowSpan={2} style={{ width: "9%" }}>
                        Unit Price
                      </th>
                      {/* <th colSpan={2} style={{ width: "12%", textAlign: "center" }}>
                  Discount
                </th> */}
                      {isGSTEnabled && (
                        <th
                          colSpan={2}
                          style={{ width: "12%", textAlign: "center" }}
                        >
                          GST
                        </th>
                      )}
                      <th
                        rowSpan={2}
                        style={{ width: "14%", textAlign: "end" }}
                      >
                        Gross Amount
                      </th>
                    </tr>
                    <tr>
                      {/* <th style={{ width: "6%" }}>%</th>
                <th style={{ width: "6%" }}>₹</th> */}
                      {isGSTEnabled && <th style={{ width: "6%" }}>%</th>}
                      {isGSTEnabled && <th style={{ width: "6%" }}>₹</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data.items?.map((item: any, i: any) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {item.isService ? (
                              <Tooltip
                                title="Service"
                                placement="left"
                                color="#1d39c4"
                              >
                                <Avatar
                                  style={{
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                    color: "#1d39c4",
                                    backgroundColor: "#f0f5ff",
                                    borderColor: "#adc6ff",
                                    fontWeight: "bold",
                                  }}
                                  size="small"
                                >
                                  S
                                </Avatar>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Goods"
                                placement="left"
                                color="#389e0d"
                              >
                                <Avatar
                                  style={{
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                    color: "#389e0d",
                                    backgroundColor: "#f6ffed",
                                    borderColor: "#b7eb8f",
                                    fontWeight: "bold",
                                  }}
                                  size="small"
                                >
                                  G
                                </Avatar>
                              </Tooltip>
                            )}
                          </td>
                          <td>
                            {item.isTaxable ? (
                              <Tooltip
                                title="With Tax"
                                placement="right"
                                color="#08979C"
                              >
                                <Avatar
                                  style={{
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                    color: "#08979C",
                                    backgroundColor: "#E6FFFB",
                                    borderColor: "#08979C",
                                    fontWeight: "bold",
                                  }}
                                  size="small"
                                >
                                  T
                                </Avatar>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="WithOut Tax"
                                placement="right"
                                color="#531DAB"
                              >
                                <Avatar
                                  style={{
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                    color: "#531DAB",
                                    backgroundColor: "#F9F0FF",
                                    borderColor: "#531DAB",
                                    fontWeight: "bold",
                                  }}
                                  size="small"
                                >
                                  WT
                                </Avatar>{" "}
                              </Tooltip>
                            )}
                          </td>

                          <td>
                            <NavLink
                              to={`/accounts/clients/${currentClient?.id}/purchase/inventory`}
                              state={{
                                id: item.id,
                                type: "sales",
                                itemType: item.isService
                                  ? "purchaseService"
                                  : "purchaseGoods",
                              }}
                            >
                              {item.name}
                            </NavLink>
                          </td>
                          <td>{item?.hsnsac}</td>
                          <td>{item?.description}</td>
                          <td style={{ textAlign: "right" }}>
                            {Utils.getFormattedNumber(item.amount?.qty)}
                          </td>
                          <td>{item.amount?.unit?.name}</td>
                          <td style={{ textAlign: "right" }}>
                            {Utils.getFormattedNumber(item.amount?.price)}
                          </td>
                          {/* <td style={{ textAlign: "right" }}>
                      {Utils.getFormattedNumber(item.amount?.discount)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {Utils.getFormattedNumber(item.amount?.discountRupee)}
                    </td> */}
                          {isGSTEnabled && (
                            <td style={{ textAlign: "right" }}>
                              {Utils.getFormattedNumber(item.amount?.tax?.rate)}
                            </td>
                          )}
                          {isGSTEnabled && (
                            <td style={{ textAlign: "right" }}>
                              {Utils.getFormattedNumber(
                                item.amount?.itemTaxAmt
                              )}
                            </td>
                          )}
                          <td style={{ textAlign: "end" }}>
                            {Utils.getFormattedNumber(item.amount?.totalAmount)}
                          </td>
                        </tr>
                      );
                    })}
                    {itemLength > 0 && (
                      <tr className="dashedBorderTop">
                        <td colSpan={5} className="textEnd">
                          Total
                        </td>
                        <td></td>
                        <td className="textEnd">{data.totalQty}</td>
                        <td colSpan={2} className="textEnd"></td>
                        {/* <td colSpan={2} className="textEnd">
                    ₹ {data.totalDiscount}
                  </td> */}
                        {isGSTEnabled && (
                          <td colSpan={2} className="textEnd">
                            ₹ {data?.totalItemGSTAmt}
                          </td>
                        )}
                        <td colSpan={2} className="textEnd">
                          ₹ {data.itemsTotalAmount}
                        </td>
                      </tr>
                    )}
                    {data?.freightInsLedgers?.map((itm: any, indx: any) => {
                      return (
                        <tr key={indx} style={{ marginTop: "10px" }}>
                          <td colSpan={3}></td>
                          <td colSpan={4}>
                            <NavLink
                              to={`/accounts/clients/${currentClient?.id}/master/ledger/${itm?.id}`}
                            >
                              {itm?.ledgerName}
                            </NavLink>
                          </td>
                          <td></td>
                          <td style={{ textAlign: "right" }}>
                            {Utils.getFormattedNumber(Math.abs(itm?.amount))}
                          </td>
                          {/* <td></td>
                    <td></td> */}
                          {isGSTEnabled ? (
                            <>
                              <td style={{ textAlign: "right" }}>
                                {Utils.getFormattedNumber(itm?.gstRate?.rate)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {Utils.getFormattedNumber(itm?.gstAmount)}
                              </td>
                            </>
                          ) : (
                            ""
                          )}
                          <td style={{ textAlign: "end" }}>
                            {Utils.getFormattedNumber(itm?.totalAmount)}
                          </td>
                        </tr>
                      );
                    })}
                    {freightLength > 0 && (
                      <tr className="dashedBorderTop">
                        <td colSpan={5} className="textEnd">
                          Total
                        </td>
                        <td colSpan={4} className="textEnd">
                          ₹ {data?.cifExpenses}
                        </td>
                        {isGSTEnabled && (
                          <td colSpan={2} className="textEnd">
                            ₹ {data.ledgerGstAmount}
                          </td>
                        )}

                        <td className="textEnd">
                          ₹ {Utils.getFormattedNumber(data.ledgerTotalAmount)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Row className="pt-10">
                  <Col lg={15}>
                    <div className="mt-10" style={{ paddingRight: "10px" }}>
                      <strong>
                        {voucherType === Voucher_Type.SalesVoucher
                          ? `Invoice Amount (in words): `
                          : `Bill Amount (in words): `}
                      </strong>
                      <em>INR {data.totalInvoiceAmountInWords}</em>
                    </div>
                    <div className="mt-10" style={{ paddingRight: "10px" }}>
                      <strong>{`Narration: `}</strong>
                      <br></br>
                      {data?.narration}
                    </div>
                  </Col>
                  <Col lg={2}>
                    {/* <Row gutter={[0, 8]}>
                <Col lg={9}>Invoice Amount</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.totalInvoiceAmount}
                </Col>
                <Col lg={9}>Refund</Col>
                <Col lg={15} className="textEnd">
                  ₹ {Utils.getFormattedNumber(data?.refund)}
                </Col>

                <Col lg={9}>Received</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.receivedAmount}
                </Col>
                <Col lg={9}>Due Amount</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.dueAmount}
                </Col>
              </Row> */}
                  </Col>

                  <Col lg={7}>
                    <Row gutter={[0, 8]}>
                      <Col lg={12}>
                        {voucherType === Voucher_Type.SalesVoucher
                          ? "Selling Price"
                          : "Purchase Price"}
                      </Col>
                      <Col lg={12} className="textEnd">
                        ₹ {data?.sellPrice}
                      </Col>
                      {data?.totalDiscount > 0 ? (
                        <>
                          <Col lg={12}>Discount</Col>
                          <Col lg={12} className="textEnd">
                            ₹ {data?.totalDiscount}
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {data?.ledgerTotalAmount > 0 ? (
                        <>
                          <Col lg={12}>
                            {freightLength === 1
                              ? data?.freightInsLedgers[0]?.ledgerName
                              : "Others"}
                          </Col>
                          <Col lg={12} className="textEnd">
                            ₹ {data?.cifExpenses}
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {isGSTEnabled && (
                        <>
                          {isStateSame ? (
                            <>
                              <Col lg={12}>CGST</Col>
                              <Col lg={12} className="textEnd">
                                ₹ {data?.cgstAmt}
                              </Col>
                              <Col lg={12}>SGST</Col>
                              <Col lg={12} className="textEnd">
                                ₹ {data?.sgstAmt}
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col lg={12}>IGST</Col>
                              <Col lg={12} className="textEnd">
                                ₹ {data?.igstAmt}
                              </Col>
                            </>
                          )}
                          {/* <Col lg={10}>
                  <Radio.Group value={tdsOrTcs}>
                    <Radio value={0}>TDS</Radio>
                    <Radio value={1}>TCS</Radio>
                  </Radio.Group>
                </Col>
                {tdsOrTcs === 1 ? (
                  <Col lg={14} className="textEnd">
                    ₹ {data.totalTcs ? data.totalTcs : 0}
                  </Col>
                ) : tdsOrTcs === 0 ? (
                  <Col lg={14} className="textEnd">
                    ₹ {data.totalTds ? data.totalTds : 0}
                  </Col>
                ) : (
                  <></>
                )} */}
                        </>
                      )}
                    </Row>
                    <Divider className="my-5 grayBg " />
                    <Row gutter={[12, 2]} className="themeColor">
                      <Col
                        lg={12}
                        style={{ fontSize: "1.2em", fontWeight: 500 }}
                      >
                        {voucherType === Voucher_Type.SalesVoucher
                          ? "Total Invoice Value"
                          : "Total Bill Value"}
                      </Col>
                      <Col
                        lg={12}
                        className="textEnd"
                        style={{ fontSize: "1.2em", fontWeight: 500 }}
                      >
                        ₹ {data?.totalInvoiceAmount}
                      </Col>
                      {/* {!isInCash && itemLength > 0 && (
                  <>
                    <Col lg={12} className="pt-5">
                      Received
                    </Col>
                    <Col lg={12} className="textEnd">
                      <div> ₹ {data?.receivedAmount}</div>
                    </Col>
                    <Divider className="my-5 grayBg" />

                    <Col lg={9}>Balance</Col>
                    <Col lg={15} className="textEnd">
                      ₹ {data?.dueBalance}
                    </Col>
                  </>
                )} */}
                    </Row>
                    <Divider
                      style={{
                        borderBottom: "2px solid rgb(203, 203, 203)",
                      }}
                      className="my-5"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    {voucherType === Voucher_Type.SalesVoucher ? (
                      <>
                        {" "}
                        <div>
                          <u>Disclaimer:</u>
                        </div>
                        <div>
                          <ol>
                            <li>
                              Certified that the particular given above are true
                              and correct and the amount indicated represents
                              the price actually charged and there is no
                              additional consideration flowing, directly or
                              indirectly from the buyer.
                            </li>
                            <li>Subject to Jodhpur Jurisdiction only.</li>
                          </ol>
                          {/* We declare that this invoice shows the actual price of the
                    goods described and that all particulars are true and
                    correct. */}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    span={12}
                    style={{
                      marginTop: "10px",
                      textAlign: "right",
                    }}
                  >
                    <div style={{ marginBottom: "60px" }}>
                      <strong>
                        for{" "}
                        {companySettings?.setting.companyInfo.name.toUpperCase()}
                      </strong>
                    </div>
                    <div>Authorized Signatory</div>
                  </Col>
                </Row>
                <Row>
                  <Divider className="my-5 grayBg"></Divider>
                  {/* <Col span={24} style={{ textAlign: "center" }}>
              <div>This is computer generated invoice.</div>
            </Col> */}
                </Row>
              </Spin>
            </div>
          </>
        )}
      </>
      {openModel && (
        <ViewSalesInvoiceNoDetails
          open={openModel}
          onCancel={() => setOpenModel(false)}
          invoiceId={data?.invoiceId}
        />
      )}
    </Modal>
  );
};
export default ViewSalesInvoice;
