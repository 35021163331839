import { FloatButton } from "antd";
import { MainBreadCrumbs } from "../Common/MainBreadCrumbs";
import { useTheme } from "../Context/ThemeContext";
import { useLocation } from "react-router-dom";

const DashboardTemplate = (props: any) => {
  const themeData: any = useTheme();
  const location = useLocation();
  let routepath = "";
  const splittedpath = location.pathname.split("/");
  routepath = splittedpath[splittedpath.length - 1]
  return (
    <div>
      <MainBreadCrumbs breadCrumbItems={props.breadcrumprops} />
      <div
        style={{
          // padding: "10px 20px 0px 20px",
          padding: "10px 15px 0px",
          margin: "0px",
          height: routepath === "addInvoice" || routepath === "addPurchaseBill" ? `calc(100vh - 120px)` : `calc(100vh - 72px)`,
          overflowY: "auto",
          backgroundColor: themeData?.themeData?.darkMode ? "" : "#f7f8fa",
        }}
      // className="chartdivstyle12"
      >
        {props.element}
      </div>
    </div>
  );
};

export default DashboardTemplate;
