import { ApiUtility } from "../../Utilities/ApiUtility.axios";

 export enum GeneralSettingsSections {
   All,
   Account,
   Language,
 }

 export enum Languages {
   English,
   Hindi
 }

class GeneralSettingService {
  private route = "/v1/api/GeneralSettings";

  getGeneralSetting = () => ApiUtility.getResult(`${this.route}`);

  updateGeneralSettings = (params: any, section: GeneralSettingsSections) =>
    ApiUtility.post(`${this.route}?section=${section}`, params);
}
export default new GeneralSettingService()