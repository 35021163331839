import { CloseOutlined, FormOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import React, { useContext } from "react";
import { useClient } from "../../Context/ClientContext";
import {
  Discount_Policy,
  DuplicateCopy_Type,
  ItemRate_Type,
  RoundOff_Type,
  SettingsSections,
} from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";
import AdditionalNotes from "../../Common/AdditionalNotes";
import TextArea from "antd/es/input/TextArea";
import { Voucher_Type } from "../../Services/VoucherServices";
import TermsAndConditionModal from "../Sales/Terms&ConditionModal";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";

const SettingSecCustomization = () => {
  const [form] = Form.useForm();
  const { companySettings, updateCompanySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [duplicateCopy, setDuplicateCopy] = React.useState<any>();
  const [tnCTemplateID, setTnCTemplateID] = React.useState<any>();

  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [select, setSelect] = React?.useState<{
    value: SettingsSections;
    label: string;
    key: string;
  }>({
    value: SettingsSections.Invoice,
    label: "Sales Invoice",
    key: "invoice",
  });
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);
  const [note, setNote] = React.useState<string>();
  const [notesInitial, setNotesInitial] = React.useState<string>();
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [editorContent, setEditorContent] = React?.useState("");

  const handleSelect = (value: any) => {
    setIsEdit(false);
  };

  const handleChange = (content: any) => {
    setEditorContent(content);
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: SettingsSections }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  React?.useEffect(() => {
    // console.log(
    //   "companySettings?.setting?.invoice",
    //   companySettings?.setting?.invoice
    // );

    form.setFieldsValue({
      invoice: companySettings?.setting?.invoice,
      crNote: companySettings?.setting?.crNote,
      receipt: companySettings?.setting?.receipt,
      estimate: companySettings?.setting?.estimate,
      sOrder: companySettings?.setting?.sOrder,
      dChallan: companySettings?.setting?.dChallan,
      pInvoice: companySettings?.setting?.pInvoice,
      drNote: companySettings?.setting?.drNote,
      payment: companySettings?.setting?.payment,
      pOrder: companySettings?.setting?.pOrder,
      item: companySettings?.setting?.item,
      expenses: companySettings?.setting?.expenses,
      income: companySettings?.setting?.income,
      asset: companySettings?.setting?.asset,
      journal: companySettings?.setting?.journal,
      dividend: companySettings?.setting?.dividend,
      contra: companySettings?.setting?.contra,
    });

    if (select.key) {
      setTnCTemplateID({
        templateId: (companySettings?.setting as any)?.[select.key]
          ?.tnCTemplateId,
      });
    }
    setDuplicateCopy(
      companySettings?.setting?.invoice?.invoiceCopyLabelling?.duplicateCopyType
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettings, select.key, select?.value]);

  // console.log("val", companySettings?.setting?.invoice);

  // console.log("compSettingType", compSettingType);
  console.log("tnCTemplateID", tnCTemplateID);

  return (
    <>
      <div
        style={{
          padding: "5px 5px 5px 5px",
          height: "100%",
        }}
      >
        <Row>
          <Col span={20} style={{ marginLeft: "30px" }}>
            <span>Select Voucher </span>
            <Select
              style={{ width: "18%" }}
              labelInValue
              showSearch
              placeholder="Select..."
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={SettingsSections.Invoice}
              options={[
                {
                  value: SettingsSections.Invoice,
                  label: "Sales Invoice",
                  key: "invoice",
                },
                {
                  value: SettingsSections.CrNote,
                  label: "Credit Note",
                  key: "crNote",
                },
                {
                  value: SettingsSections.Receipt,
                  label: "Receipt",
                  key: "receipt",
                },
                {
                  value: SettingsSections.Quotation,
                  label: "Quotation",
                  key: "estimate",
                },
                {
                  value: SettingsSections.SOrder,
                  label: "Sales Order",
                  key: "sOrder",
                },
                {
                  value: SettingsSections.DChallan,
                  label: "Delivery Challan",
                  key: "dChallan",
                },
                {
                  value: SettingsSections.PInvoice,
                  label: "Purchase Invoice",
                  key: "pInvoice",
                },
                {
                  value: SettingsSections.DrNote,
                  label: "Debit Note",
                  key: "drNote",
                },
                {
                  value: SettingsSections.Payment,
                  label: "Payment",
                  key: "payment",
                },
                {
                  value: SettingsSections.POrder,
                  label: "Purchase Order",
                  key: "pOrder",
                },
                {
                  value: SettingsSections.Item,
                  label: "Items",
                  key: "item",
                },
                {
                  value: SettingsSections.Asset,
                  label: "Fixed Assets",
                  key: "asset",
                },
                {
                  value: SettingsSections.Journal,
                  label: "Journal",
                  key: "journal",
                },
                {
                  value: SettingsSections.Contra,
                  label: "Contra",
                  key: "contra",
                },
                {
                  value: SettingsSections.Dividend,
                  label: "Dividend",
                  key: "dividend",
                },
              ]}
              onChange={(e: any) => {
                setSelect({
                  value: e.value,
                  label: e.label,
                  key: e.key,
                });
                handleSelect(e);
              }}
            />
          </Col>

          <Col>
            {isEdit ? (
              <></>
            ) : (
              <>
                <Tooltip title="Edit">
                  <FormOutlined
                    style={{ color: "rgb(106 119 227)", paddingTop: "15px" }}
                    // className="ms-40"
                    className="ca-edit-btn"
                    type="default"
                    onClick={() => {
                      setNote(undefined);
                      setNotesInitial(
                        companySettings?.setting?.invoice?.disclaimer
                      );
                      setIsEdit(true);
                    }}
                  />
                  {/* <Button
                        className="ms-40"
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setNote(undefined);
                          setNotesInitial(
                            companySettings?.setting?.invoice?.notes
                          );
                          setIsEdit({
                            edit: true,
                            key:
                              isEdit.key !== "salesInv"
                                ? "salesInv"
                                : undefined,
                          });
                        }}
                      /> */}
                </Tooltip>
              </>
            )}
          </Col>
        </Row>
        <>
          <div style={{ margin: "0 auto", maxWidth: "96%" }}>
            {/* --------- Sales Invoice --------- */}
            <Divider className="my-7" />
            <Row>
              <Col
                span={24}
                style={{
                  maxHeight: "660px",
                  overflowY: "auto",
                  position: "relative",
                }}
              >
                <Form
                  form={form}
                  colon={false}
                  name="CustomizeSalesInvoice"
                  disabled={!isEdit}
                  labelAlign="left"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 12 }}
                  // layout="horizontal"
                >
                  <Row>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        name={[select.key, "isSuffix"]}
                        className="m-0"
                        label="Prefix / Suffix"
                      >
                        <Select
                          className="custom-select small-font-select"
                          placeholder="select"
                          options={[
                            { value: false, label: "Prefix" },
                            { value: true, label: "Suffix" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        name={[select.key, "preSuf"]}
                        className="m-0"
                        label="PreSuf Text"
                      >
                        <Input className="custom-input small-font-input" />
                      </Form.Item>
                    </Col>
                    {/* <Col lg={2}>
             
                    </Col> */}
                  </Row>

                  <Row>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        name={[select.key, "start"]}
                        className="m-0 textCenter"
                        label="Start from"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 12 }}
                      >
                        <Input
                          className="custom-input small-font-input"
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        name={[select.key, "title"]}
                        className="m-0"
                        label="Title"
                      >
                        <Input className="custom-input small-font-input" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        label="Year Include"
                        valuePropName="checked"
                        name={[select.key, "yearInclude"]}
                        className="m-0"
                      >
                        <Checkbox
                          className="custom-select"
                          style={{ marginTop: "6px" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        label="Month Include"
                        valuePropName="checked"
                        name={[select.key, "monthInclude"]}
                        className="m-0"
                      >
                        <Checkbox
                          className="custom-select"
                          style={{ marginTop: "6px" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        label="Month Format"
                        name={[select.key, "monthNumFormat"]}
                        className="m-0"
                      >
                        <Select
                          className="custom-select small-font-select"
                          placeholder="Select Format"
                          options={[
                            { value: 0, label: "Number" },
                            { value: 1, label: "Text" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        label="Frequency of No."
                        name={[select.key, "startNumberFrequency"]}
                        className="m-0"
                      >
                        <Select
                          className="custom-select small-font-select"
                          placeholder="Select Frequency"
                          options={[
                            { value: 0, label: "Yearly" },
                            { value: 1, label: "Monthly" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} sm={12}>
                      <Form.Item
                        label="Separator"
                        name={[select.key, "separator"]}
                        className="m-0"
                      >
                        <Select
                          className="custom-select small-font-select"
                          placeholder="Separator"
                          options={[
                            { value: "-", label: "-" },
                            { value: "#", label: "#" },
                            { value: "/", label: "/" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col lg={24} sm={12}>
                      {!isEdit ? (
                        <Form.Item
                          className="m-0"
                          label="Disclaimer"
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 18 }}
                          name={[select.key, "disclaimer"]}
                        >
                          <div
                            style={{
                              background: "rgba(0, 0, 0, 0.04)",
                              padding: 10,
                              height: "130px",
                              overflowX: "scroll",
                              border: "1px solid #d9d9d9",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: (companySettings?.setting as any)?.[
                                select.key
                              ].disclaimer!,
                            }}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          className="m-0"
                          label="Disclaimer"
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 18 }}
                          name={[select.key, "disclaimer"]}
                        >
                          <SunEditor
                            setContents={
                              (companySettings?.setting as any)?.[select.key]
                                .disclaimer
                            }
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                ["bold", "underline", "italic", "strike"],
                                ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],
                                ["removeFormat"],
                              ],
                              defaultTag: "div",
                              showPathLabel: false,
                            }}
                            /*  ["table", "horizontalRule", "link", "image", "video"], */
                            height="150px"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>

                  <div>
                    <div style={{ marginTop: "10px", marginLeft: "192px" }}>
                      <Button
                        style={{ backgroundColor: "ButtonFace" }}
                        onClick={() => {
                          setOpenModel({
                            type: "termsAndConditionsModal",
                            param: { open: true },
                          });
                        }}
                      >
                        Select Term & Conditions
                      </Button>
                    </div>
                    <>
                      {(select.value === SettingsSections.Invoice ||
                        select.value === SettingsSections.PInvoice ||
                        select.value === SettingsSections.CrNote ||
                        select.value === SettingsSections.DrNote ||
                        select.value === SettingsSections.Quotation ||
                        select.value === SettingsSections.SOrder ||
                        select.value === SettingsSections.POrder) && (
                        <Row style={{ marginTop: "10px" }}>
                          <Col lg={24} sm={12}>
                            {!isEdit ? (
                              <Form.Item
                                className="m-0"
                                label="Terms & Conditions"
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 18 }}
                                name={[select.key, "termsnConditions"]}
                              >
                                <div
                                  style={{
                                    background: "rgba(0, 0, 0, 0.04)",
                                    padding: 10,
                                    height: "130px",
                                    overflowX: "scroll",
                                    border: "1px solid #d9d9d9",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: (companySettings?.setting as any)?.[
                                      select.key
                                    ]?.termsnConditions!,
                                  }}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                className="m-0"
                                label="Terms & Conditions"
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 18 }}
                                name={[select.key, "termsnConditions"]}
                              >
                                <SunEditor
                                  setContents={
                                    tnCTemplateID?.tnCText === undefined
                                      ? (companySettings?.setting as any)?.[
                                          select.key
                                        ]?.termsnConditions
                                      : tnCTemplateID?.tnCText
                                  }
                                  setOptions={{
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize"],
                                      ["bold", "underline", "italic", "strike"],
                                      ["fontColor", "hiliteColor"],
                                      ["align", "list", "lineHeight"],
                                      ["outdent", "indent"],
                                      ["removeFormat"],
                                    ],
                                    defaultTag: "div",
                                    showPathLabel: false,
                                  }}
                                  /*  ["table", "horizontalRule", "link", "image", "video"], */
                                  height="150px"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            )}
                          </Col>
                        </Row>
                      )}

                      <Row style={{ marginTop: "20px" }}>
                        <Col lg={24} sm={20}>
                          <>
                            <Row>
                              {select.value === SettingsSections.Invoice && (
                                <Col span={12}>
                                  <Form.Item
                                    label="Show Contact Detail"
                                    name={[select.key, "showContactDetail"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                              )}
                              {(select.value === SettingsSections.Invoice ||
                                select.value === SettingsSections.PInvoice) && (
                                <Col span={12}>
                                  <Form.Item
                                    label="Show Receipt"
                                    name={[select.key, "showReceipt"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox style={{ marginTop: "5px" }} />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              {(select.value === SettingsSections.Invoice ||
                                select.value === SettingsSections.PInvoice ||
                                select.value === SettingsSections.CrNote ||
                                (select.value as SettingsSections) ===
                                  SettingsSections.DrNote ||
                                select.value === SettingsSections.Quotation ||
                                select.value === SettingsSections.SOrder ||
                                (select.value as SettingsSections) ===
                                  SettingsSections.POrder) && (
                                <Col span={12}>
                                  <Form.Item
                                    label="Show Shipping Address"
                                    name={[select.key, "showShippingAddress"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                              )}

                              {(select.value === SettingsSections.Invoice ||
                                select.value === SettingsSections.PInvoice ||
                                // select.value === SettingsSections.CrNote ||
                                // (select.value as SettingsSections) ===
                                //   SettingsSections.DrNote ||
                                select.value === SettingsSections.Quotation ||
                                select.value === SettingsSections.SOrder ||
                                (select.value as SettingsSections) ===
                                  SettingsSections.POrder) && (
                                <Col span={12}>
                                  <Form.Item
                                    label="Show Price History"
                                    name={[select.key, "showPriceHistory"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox style={{ marginTop: "5px" }} />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              {(select.value === SettingsSections.Invoice ||
                                select.value === SettingsSections.PInvoice ||
                                select.value === SettingsSections.Quotation ||
                                select.value === SettingsSections.SOrder ||
                                select.value === SettingsSections.CrNote ||
                                select.value === SettingsSections.DrNote ||
                                select.value === SettingsSections.POrder) && (
                                <Col span={12}>
                                  <Form.Item
                                    label="Show Other Charges"
                                    name={[select.key, "showOtherCharges"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                              )}
                              {select.value === SettingsSections.Invoice && (
                                <Col span={12}>
                                  <Form.Item
                                    label="E-Invoicing"
                                    name={[select.key, "eInvoicing"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox style={{ marginTop: "5px" }} />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                            <Row>
                              {select.value === SettingsSections.Invoice && (
                                <Col span={12}>
                                  <Form.Item
                                    label="E-Way billing"
                                    name={[select.key, "eWayBilling"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                              )}
                              {(select.value === SettingsSections.Invoice ||
                                select.value === SettingsSections.PInvoice ||
                                select.value === SettingsSections.CrNote ||
                                select.value === SettingsSections.DrNote) && (
                                <Col span={12}>
                                  <Form.Item
                                    label="Show Item Description"
                                    name={[select.key, "showItemDescription"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                              )}
                              {select.value === SettingsSections.PInvoice &&
                                companySettings?.setting?.gstDetails?.scheme ===
                                  CompanyGSTTypes.Regular && (
                                  <Col span={12}>
                                    <Form.Item
                                      label="Show Reverse Charge (RCM)"
                                      name={[select.key, "showRCM"]}
                                      className="m-0"
                                      labelCol={{ span: 10 }}
                                      valuePropName="checked"
                                    >
                                      <Checkbox />
                                    </Form.Item>
                                  </Col>
                                )}
                              {(select.value === SettingsSections.CrNote ||
                                (select.value as SettingsSections) ===
                                  SettingsSections.DrNote) && (
                                <Col span={12}>
                                  <Form.Item
                                    label="Credit Note day limit"
                                    name={[select.key, "returnNoteDayLimit"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                  >
                                    {/* <Checkbox style={{ marginTop: "5px" }} /> */}
                                    <InputNumber min={0} controls={false} />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>

                            <Row>
                              {(select.value === SettingsSections.Invoice ||
                                select.value === SettingsSections.PInvoice) && (
                                <Col span={12}>
                                  <Form.Item
                                    label={
                                      select.value === SettingsSections.Invoice
                                        ? "Show Despatch Details"
                                        : "Receipt Note"
                                    }
                                    name={[select.key, "showDispatchDetail"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                              )}
                              {(select.value === SettingsSections.Invoice ||
                                select.value === SettingsSections.PInvoice ||
                                select.value === SettingsSections.Quotation ||
                                select.value === SettingsSections.POrder ||
                                select.value === SettingsSections.SOrder) && (
                                <Col span={12}>
                                  <Form.Item
                                    label="Show Freight Type"
                                    name={[select.key, "showFreightType"]}
                                    className="m-0"
                                    valuePropName="checked"
                                    labelCol={{ span: 10 }}
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                            {(select.value === SettingsSections.Invoice ||
                              select.value === SettingsSections.PInvoice ||
                              select.value === SettingsSections.CrNote ||
                              select.value === SettingsSections.DrNote ||
                              select.value === SettingsSections.Payment ||
                              select.value === SettingsSections.Receipt ||
                              select.value === SettingsSections.Quotation ||
                              select.value === SettingsSections.POrder ||
                              select.value === SettingsSections.SOrder) && (
                              <Row>
                                <Col span={12}>
                                  <Form.Item
                                    label="Show RoundOff"
                                    name={[select.key, "autoRoundOff"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    label="RoundOff Type"
                                    name={[select.key, "roundOffType"]}
                                    className="m-0"
                                    labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 10 }}
                                    initialValue={RoundOff_Type.Normal}
                                  >
                                    <Select
                                      options={[
                                        {
                                          value: RoundOff_Type.Normal,
                                          label: "Normal",
                                        },
                                        {
                                          value: RoundOff_Type.Ceiling,
                                          label: "Ceiling",
                                        },
                                        {
                                          value: RoundOff_Type.Floor,
                                          label: "Floor",
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              {(select.value === SettingsSections.Invoice ||
                                select.value === SettingsSections.PInvoice ||
                                select.value === SettingsSections.Quotation ||
                                select.value === SettingsSections.SOrder ||
                                (select.value as SettingsSections) ===
                                  SettingsSections.POrder) && (
                                <>
                                  <Col span={12}>
                                    <Form.Item
                                      label="Discount policy"
                                      name={[select.key, "discountPolicy"]}
                                      className="m-0"
                                      labelCol={{ span: 10 }}
                                      wrapperCol={{ span: 10 }}
                                      initialValue={Discount_Policy.NoDiscount}
                                    >
                                      <Select
                                        options={[
                                          {
                                            value: Discount_Policy.NoDiscount,
                                            label: "No Discount",
                                          },
                                          {
                                            value:
                                              Discount_Policy.OnlyTradeDiscount,
                                            label: "Only trade discount",
                                          },
                                          {
                                            value:
                                              Discount_Policy.OnlyCashDiscount,
                                            label: "Only cash discount",
                                          },
                                          {
                                            value:
                                              Discount_Policy.TradenCashDiscount,
                                            label: "Trade & cash discount",
                                          },
                                        ]}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      label="Item rate type"
                                      name={[select.key, "itemRateType"]}
                                      className="m-0"
                                      labelCol={{ span: 10 }}
                                      wrapperCol={{ span: 10 }}
                                      initialValue={ItemRate_Type.Exclusive}
                                    >
                                      <Select
                                        options={[
                                          {
                                            value: ItemRate_Type.Exclusive,
                                            label: "Exclusive",
                                          },
                                          {
                                            value: ItemRate_Type.Inclusive,
                                            label: "Inclusive",
                                          },
                                        ]}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              )}
                            </Row>

                            {select.value === SettingsSections.Invoice && (
                              <Row style={{ marginTop: "14px" }}>
                                <Col span={5}>
                                  <p>Invoice copy labelling</p>
                                </Col>
                                <Col span={19}>
                                  <Form.Item
                                    name={[
                                      select.key,
                                      "invoiceCopyLabelling",
                                      "duplicateCopyType",
                                    ]} // wrapperCol={{ span: 6 }}
                                    initialValue={DuplicateCopy_Type.Original}
                                  >
                                    <Select
                                      style={{ width: "52%" }}
                                      onChange={(val: any) =>
                                        setDuplicateCopy(val)
                                      }
                                      options={[
                                        {
                                          value: DuplicateCopy_Type.Original,
                                          label: "Oringinal",
                                        },
                                        {
                                          value: DuplicateCopy_Type.Duplicate,
                                          label: "Duplicate",
                                        },
                                        {
                                          value: DuplicateCopy_Type.Triplicate,
                                          label: "Triplicate",
                                        },
                                        {
                                          value:
                                            DuplicateCopy_Type.Quadruplicate,
                                          label: "Quadruplicate",
                                        },
                                        {
                                          value:
                                            DuplicateCopy_Type.Quintuplicate,
                                          label: "Quintuplicate",
                                        },
                                      ]}
                                    />
                                  </Form.Item>

                                  <Row>
                                    {duplicateCopy === 0 ||
                                    duplicateCopy === 1 ||
                                    duplicateCopy === 2 ||
                                    duplicateCopy === 3 ||
                                    duplicateCopy === 4 ? (
                                      <>
                                        <Col span={4}>
                                          <span>Copy-1</span>
                                          <Form.Item
                                            name={[
                                              select.key,
                                              "invoiceCopyLabelling",
                                              "title1",
                                            ]}
                                            className="m-0"
                                            // labelCol={{ span: 12 }}
                                            wrapperCol={{ span: 20 }}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </Col>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {duplicateCopy === 1 ||
                                    duplicateCopy === 2 ||
                                    duplicateCopy === 3 ||
                                    duplicateCopy === 4 ? (
                                      <>
                                        <Col span={4}>
                                          <span>Copy-2</span>
                                          <Form.Item
                                            name={[
                                              select.key,
                                              "invoiceCopyLabelling",
                                              "title2",
                                            ]}
                                            className="m-0"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 20 }}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </Col>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {duplicateCopy === 2 ||
                                    duplicateCopy === 3 ||
                                    duplicateCopy === 4 ? (
                                      <>
                                        <Col span={4}>
                                          <span>Copy-3</span>

                                          <Form.Item
                                            name={[
                                              select.key,
                                              "invoiceCopyLabelling",
                                              "title3",
                                            ]}
                                            className="m-0"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 20 }}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </Col>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {duplicateCopy === 3 ||
                                    duplicateCopy === 4 ? (
                                      <>
                                        <Col span={4}>
                                          <span>Copy-4</span>

                                          <Form.Item
                                            name={[
                                              select.key,
                                              "invoiceCopyLabelling",
                                              "title4",
                                            ]}
                                            className="m-0"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 20 }}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </Col>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {duplicateCopy === 4 ? (
                                      <>
                                        <Col span={4}>
                                          <span>Copy-5</span>

                                          <Form.Item
                                            name={[
                                              select.key,
                                              "invoiceCopyLabelling",
                                              "title5",
                                            ]}
                                            className="m-0"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 20 }}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </Col>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            )}
                          </>
                        </Col>
                      </Row>
                    </>
                    {/* )} */}
                  </div>
                </Form>
              </Col>
            </Row>
            <Row justify={"end"} style={{ marginTop: "20px" }}>
              <Col span={6}>
                {isEdit ? (
                  <Space
                    style={{
                      position: "sticky",
                      bottom: 0,
                    }}
                  >
                    <Button
                      icon={<CloseOutlined />}
                      onClick={() => {
                        setNote(undefined);
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      // className="ms-40"
                      icon={<SaveOutlined />}
                      loading={saveButtonLoading}
                      onClick={() => {
                        if (isEdit) {
                          setSaveButtonLoading(true);
                          form.validateFields().then((val: any) => {
                            /**
                             * to add state values in Form Values
                             */
                            let newVal = {
                              ...val,
                              [select.key!]: {
                                ...val[select?.key],
                                tnCTemplateId: tnCTemplateID?.templateId,
                              },
                            };
                            // console.log("New val", val, newVal);
                            updateCompanySettings!(
                              select.value!,
                              {
                                ...companySettings,
                                setting: {
                                  ...companySettings?.setting!,
                                  [select.key!]: newVal[select?.key],
                                },
                              },
                              newVal
                            ).then((result: any) => {
                              if (result?.status) {
                                setIsEdit(false);
                                setSaveButtonLoading(false);
                                openNotification(
                                  "success",
                                  "Setting Updated successfully"
                                );
                              } else {
                                setSaveButtonLoading(false);
                                openNotification(
                                  "error",
                                  "there is some error"
                                );
                              }
                            });
                          });
                        }
                      }}
                    >
                      Save
                    </Button>

                    {/* <Button
                      type="primary"
                      // className="ms-40"
                      icon={<SaveOutlined />}
                      loading={saveButtonLoading}
                      onClick={() => {
                        // if (isEdit) {
                        //   setSaveButtonLoading(true);
                        //   form.validateFields().then((val: any) => {
                        //     updateCompanySettings!(
                        //       select.value!,
                        //       {
                        //         ...companySettings,
                        //         setting: {
                        //           ...companySettings?.setting!,
                        //           [select.key!]: val[select?.key],
                        //         },
                        //       },
                        //       val
                        //     ).then((result: any) => {
                        //       if (result?.status) {
                        //         setIsEdit(false);
                        //         setSaveButtonLoading(false);
                        //         openNotification(
                        //           "success",
                        //           "Setting Updated successfully"
                        //         );
                        //       } else {
                        //         setSaveButtonLoading(false);
                        //         openNotification(
                        //           "error",
                        //           "there is some error"
                        //         );
                        //       }
                        //     });
                        //   });
                        // }
                      }}
                    >
                      Only Save All Number
                    </Button> */}
                  </Space>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </>
      </div>
      {openModel.type === "termsAndConditionsModal" && (
        <TermsAndConditionModal
          open={openModel.param.open}
          onCancel={() => setOpenModel({ type: "", param: { open: false } })}
          setTermsAndConditionField={setTnCTemplateID}
          termsAndConditionField={tnCTemplateID} // setTermsAndConditionField={setTermsAndConditionField}
          // termsAndConditionField={termsAndConditionField}
          voucherType={
            select?.value === SettingsSections.Invoice
              ? Voucher_Type?.SalesVoucher
              : select?.value === SettingsSections.CrNote
              ? Voucher_Type?.CreditNote
              : select?.value === SettingsSections.DrNote
              ? Voucher_Type?.DebitNote
              : select?.value === SettingsSections.PInvoice
              ? Voucher_Type?.PurchaseVoucher
              : select?.value === SettingsSections.Quotation
              ? Voucher_Type?.SalesVoucher
              : select?.value === SettingsSections.POrder
              ? Voucher_Type?.PurchaseVoucher
              : select?.value === SettingsSections.SOrder
              ? Voucher_Type?.SalesVoucher
              : ""
          }
        />
      )}
      {openNotes && (
        <AdditionalNotes
          value={note ? note : notesInitial ? notesInitial : ""}
          open={openNotes}
          save={(a: string) => {
            setOpenNotes(false);
            setNote(a);
          }}
          cancel={() => setOpenNotes(false)}
        />
      )}
    </>
  );
};

export default SettingSecCustomization;
