import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class GstService {
  private route = "/v1/api/Acc_Gst";

  getGst = (
    start?: number,
    length?: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    isActive?: boolean
  ) =>
    ApiUtility.getResult(`${this.route}`, {
      start,
      length,
      sortCol,
      sortDir,
      search,
      isActive,
    });
}
export default new GstService();
