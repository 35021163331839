import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import React from "react";
import "./User.css";
import UsersService, {
  ApplicationRoleNames,
} from "../../Services/UsersService";
import { notificationContext } from "../../Common/PageRoute";
import ClientService from "../../Services/ClientService";

interface props {
  onCancel: any;
  isModelOpen: boolean;
  id: string | undefined;
  setRefresh: any;
}
const AddUser = ({ onCancel, isModelOpen, id, setRefresh }: props) => {
  const [form] = Form.useForm();
  const [selectRole, setSelectRole] = React.useState<ApplicationRoleNames>();
  const [btnLoading, setBtnLoading] = React.useState<any>(false);
  const [clientList, setClientList] = React.useState<any>();
  const [selectedClients, setSelectedClients] = React.useState<string[]>([]);
  const [selectAllChecked, setSelectAllChecked] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getClientData();
  }, []);
  const getUserDataById = async () => {
    let res = await UsersService?.getUserDataById(id);
    if (res) {
      console.log("ress", res);
    }
  };

  React.useEffect(() => {
    getUserDataById();
  }, []);

  const getClientData = async () => {
    let res = await ClientService?.getClientList(0, 15);
    if (res) {
      setClientList(res?.items);
    }
  };

  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    if (checked) {
      setSelectedClients(clientList?.map((client: any) => client.id));
    } else {
      setSelectedClients([]);
    }
  };

  const handleSelectChange = (selectedValues: string[]) => {
    setSelectedClients(selectedValues);
    setSelectAllChecked(selectedValues.length === clientList?.length);
  };

  const saveData = () => {
    form.validateFields().then(async (val: any) => {
      setBtnLoading(true);
      let data = {
        ...val,
        role: selectRole,
        companyList: selectedClients,
        parentId: JSON.parse(sessionStorage.getItem("ao-user")!).id,
        name: {
          first: val?.first,
          last: val?.last,
        },
      };
      let res = await UsersService?.postUserData(data);
      if (res?.status) {
        console.log("res", res);
        setBtnLoading(false);
        notification.success({
          message: "Success",
          description: "Add user successfully.",
        });
        setRefresh((x: boolean) => !x);
        onCancel(false);
      } else {
        notification.error({
          message: "Error",
          description: res?.message,
        });
        onCancel(false);
        setBtnLoading(false);
      }
      console.log("val", val);
    });
  };

  console.log("selectedClients", selectedClients);

  return (
    <>
      <div>
        <Modal
          title="Add User"
          onCancel={() => onCancel(false)}
          open={isModelOpen}
          footer={
            <div style={{ marginTop: "0px" }}>
              <Button
                onClick={() => onCancel(false)}
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                // loading={btnLoading}
                type="primary"
                onClick={saveData}
              >
                {/* {id ? "Edit" : "Save"} */}
                Save
              </Button>
            </div>
          }
        >
          <Form
            requiredMark={false}
            form={form}
            labelCol={{ span: 6 }}
            labelAlign="left"
            layout="horizontal"
          >
            <Form.Item
              label="First Name"
              className="custom-input"
              name="first"
              rules={[
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "First Name can only contain letters and spaces",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              className="custom-input"
              name="last"
              rules={[
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "First Name can only contain letters and spaces",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              className="custom-input"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "The input is not a valid email!",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Phone"
              className="custom-input"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Phone number must be between 10 digits!",
                },
              ]}
            >
              <Input
                type="tel"
                maxLength={10}
                placeholder="Enter your phone number"
                onInput={(e: any) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?~`\\|-])[A-Za-z\d!@#$%^&*()_+={}\[\]:;"'<>,.?~`\\|-]{6,}$/,
                  message:
                    "Password must be at least 6 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character!",
                },
              ]}
            >
              <Input.Password size="middle" />
            </Form.Item>
            <Form.Item
              label="Re-Password"
              name="rePassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The password do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="middle" />
            </Form.Item>
            <Form.Item label="Role" name="role">
              <Select
                style={{ width: "100%" }}
                options={[
                  {
                    value: "ADMIN",
                    label: "Admin",
                  },
                  {
                    value: "AUTHORITIES",
                    label: "Authorities",
                  },
                  {
                    value: "DATAENTRY",
                    label: "Data Entry",
                  },
                  {
                    value: "AUDITORS",
                    label: "Auditors",
                  },
                  {
                    value: "VIEWONLY",
                    label: "View Only",
                  },
                ]}
                onChange={(val: any) => setSelectRole(val)}
              />
            </Form.Item>

            <Form.Item
              // name="companyList"
              label="Companies"
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select..."
                value={selectedClients}
                onChange={handleSelectChange}
                options={clientList?.map((client: any) => ({
                  value: client?.id,
                  label: client?.bName,
                }))}
              />
              <span>
                <Checkbox
                  style={{ margin: "5px 5px 0px 0px" }}
                  checked={selectAllChecked}
                  onChange={handleSelectAll}
                />
                Select All Companise
              </span>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default AddUser;
