import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Tag,
  Tooltip,
  Card,
  Skeleton,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  DownloadOutlined,
  DeleteTwoTone,
  RetweetOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import noteContext from "../../Context/StyleContext";
import SalesService, {
  DBRecordStatus,
  DeliveryChallanStatus,
  InvoiceTypes,
} from "../../Services/SalesService";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import ViewDeliveryChallanModel from "./ViewDeliveryChallanModel";
import { notificationContext } from "../../Common/PageRoute";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { FullPageSpinner } from "../../../Common/FullPageSpinner";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";

export interface IQuotationListData {
  key: React.Key;
  id: string;
  tPartner: any;
  number: any;
  Invoicenum: string;
  name: string;
  date: string;
  dueDate: string;
  totalInvoiceAmount: number;
  dueBalance: number;
  deleteType: number;
}

const DeliveryChallan: React.FC<{ partyId?: string }> = (props) => {
  const { RangePicker } = DatePicker;
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const navigate = useNavigate();
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [deliveryChallanList, setDeliveryChallanList] = useState<any>([]);
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>({ from: "", to: "" });
  const [deliveryChallanStatus, setDeliveryChallanStatus] =
    React.useState<number>(0);
  const [deleteDeliveryChallan, setDeleteDeliveryChallan] = React.useState<any>(
    { status: DBRecordStatus.Active, open: false, name: "" }
  );
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [deleteDeliveryId, setDeleteDeliveryId] = React.useState<string>("");
  const [confirmationModel, setConfirmationModel] =
    React.useState<boolean>(false);
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //-------- Delete Delivery Challan --------
  React.useEffect(() => {
    if (deleteDeliveryChallan.open) {
      SalesService.deleteDeliveryChallan(
        currentClient?.id!,
        deleteDeliveryId,
        deleteDeliveryChallan.status
      )
        .then((res) => {
          if (res.result) {
            setTrigger((x) => !x);
            console.log(res.result);
            openNotification(
              "success",
              `${
                deleteDeliveryChallan.status === 1 ? "Delete" : "Active"
              } Delivery Challan`
            );
            setDeleteDeliveryChallan({});
          }
        })
        .catch((ex: any) => {
          setDeleteDeliveryChallan({});
          console.error("Delete Api Error", ex);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDeliveryChallan.open]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<IQuotationListData> = [
    {
      title: props?.partyId ? "DC No." : "Delivery Challan No.",
      dataIndex: "number",
      sorter: true,
      width: width <= 1440 ? "20%" : "13%",
      key: "autoNumber",
      render: (x: any) => (
        <>
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <span
              style={{ color: "#1677FF", cursor: "pointer" }}
              onClick={() => {
                setIsOpen(true);
                setInvoiceId(x?.id);
              }}
            >
              {x?.no}
            </span>
          </Skeleton>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      key: "Date",
      width: "10%",
      render: (_, record: IQuotationListData) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Delivery Date",
      dataIndex: "dueDate",
      sorter: true,
      key: "DueDate",
      width: width <= 1440 ? "14%" : "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {dayjs(record?.dueDate).format("DD/MM/YYYY")}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "totalDeliveryChallanAmount",
      sorter: true,
      align: "right",
      width: width <= 1440 ? "15%" : "",
      key: "totalDeliveryChallanAmount",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹{Utils.getFormattedNumber(record?.totalDeliveryChallanAmount)}
        </Skeleton>
      ),
    },
    {
      title: "Status",
      dataIndex: "address",
      width: "8%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <StatusShow dueDate={record?.dueDate} />
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "icon",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <FiDownload style={{ color: "#395cd2" }} />
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "icon2",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div>
          {deliveryChallanStatus !== DeliveryChallanStatus.Deleted &&
          record?.deleteType === 0 ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip title="Edit" placement="top" color="#3D4998">
                <Link
                  to={`/accounts/clients/${currentClient?.id}/sales/editDeliveryChallan/${record?.id}`}
                >
                  <EditOutlined
                    className="ca-edit-btn"
                    onClick={() =>
                      navigate(
                        `/accounts/clients/${currentClient?.id}/sales/editDeliveryChallan/${record?.id}`
                      )
                    }
                    style={{ color: "#444653" }}
                  />
                </Link>
              </Tooltip>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip title="Not Editable" placement="top" color="gray">
                <EditOutlined style={{ color: "rgb(216 207 207)" }} />
              </Tooltip>
            </Skeleton>
          )}
        </div>
      ),
    },
    {
      title: " ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div>
          {deliveryChallanStatus !== DeliveryChallanStatus.Deleted &&
          record.deleteType === 0 ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip title="Delete" placement="top" color="red">
                <DeleteTwoTone
                  className="ca-delete-btn"
                  twoToneColor="#ff879d"
                  onClick={() => {
                    setConfirmationModel(true);
                    setDeleteDeliveryId(record.id);
                    setDeleteDeliveryChallan({
                      ...deleteDeliveryChallan,
                      status: DBRecordStatus.Deleted,
                      name: record.number?.no,
                    });
                  }}
                />
              </Tooltip>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip
                title="Revive Delivery Challan"
                placement="top"
                color="green"
              >
                <RetweetOutlined
                  className="ca-invoice-btn"
                  style={{ color: "#64CA31" }}
                  onClick={() => {
                    setConfirmationModel(true);
                    setDeleteDeliveryId(record.id);
                    setDeleteDeliveryChallan({
                      ...deleteDeliveryChallan,
                      status: DBRecordStatus.Active,
                      name: record.number?.no,
                    });
                  }}
                />
              </Tooltip>
            </Skeleton>
          )}
        </div>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Party Name",
      dataIndex: "customerName",
      render: (_, record: IQuotationListData) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    });
  }

  // --------- Get TableData API ---------
  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getEstimateList(
        currentClient?.id,
        deliveryChallanStatus,
        InvoiceTypes.Invoice,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchCustomer,
        filterDate.from,
        filterDate.to,
        props?.partyId
      );
    } else if (currentClient?.id === null) {
      console.error("InValid Client");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    deliveryChallanStatus,
    filter.sortCol,
    filter.sortDir,
    searchCustomer,
    filterDate.from,
    filterDate.to,
    trigger,
    props?.partyId,
  ]);

  const getEstimateList = async (
    clientId: string,
    status: DeliveryChallanStatus,
    type: InvoiceTypes.Invoice,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    from?: any,
    to?: any,
    partyId?: string
  ) => {
    await SalesService.getDeliveryChallanList(
      clientId,
      status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId
    )
      .then((res: any) => {
        console.log(res);
        if (res?.items?.length > 0) {
          setDeliveryChallanList(
            res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                id: v?.id,
                name: v?.tPartner.name,
                number: { no: v?.number, id: v?.id },
                date: v?.date,
                dueDate: v?.dueDate,
                dueBalance: v?.dueBalance,
                totalDeliveryChallanAmount: v?.totalDeliveryChallanAmount,
                customerName: v?.tPartner.name,
                deleteType: v?._s,
                // itemType: v?.isService,
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else if (res?.items?.length === 0) {
          setDeliveryChallanList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setDeliveryChallanList([]);
      });
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "tableSort",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        padding: "48px 0px 38px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <Row
        // justify={windowWidth <= 768 ? "center" : "space-between"}
        justify="space-between"
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px"
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space size={width <= 768 ? 410 : 5}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addDeliveryChallan/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addDeliveryChallan`
                    );
              }}
            >
              Delivery Challan
            </Button>
            <Button type="default" icon={<DownloadOutlined />}>
              Export
            </Button>
          </Space>
        </Col>

        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 63 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search..."
                allowClear
                onChange={(e: any) => {
                  setLoading(true);
                  if (!e.cancelable) {
                    const data = setTimeout(() => {
                      setPage(1);
                      setSearchCustomer(e.target.value);
                    }, 1000);
                    return () => clearTimeout(data);
                  } else {
                    setSearchCustomer(e.target.value);
                  }
                }}
                suffix={
                  loading || searchCustomer ? (
                    ""
                  ) : (
                    <img alt="search" src={search} />
                  )
                }
              />
            </Col>
            <Col>
              <RangePicker
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                style={{ width: windowWidth === 1024 ? 80 : 120 }}
                value={deliveryChallanStatus}
                onSelect={(e) => {
                  setPage(1);
                  setDeliveryChallanStatus(e);
                }}
                options={[
                  { value: DeliveryChallanStatus.Active, label: "Active" },
                  { value: DeliveryChallanStatus.Pending, label: "Pending" },
                  { value: DeliveryChallanStatus.Delivered, label: "Deliverd" },
                  { value: DeliveryChallanStatus.Deleted, label: "Deleted" },
                  { value: DeliveryChallanStatus.All, label: "All" },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        scroll={
          pageSize > 10
            ? { x: width <= 768 ? 1000 : 700, y: 370 }
            : { x: width <= 768 ? 1000 : 600 }
        }
        rowSelection={rowSelection}
        columns={columns}
        onChange={tableSort}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IQuotationListData[])
            : deliveryChallanList
        }
        // style={{ overflowX: "auto" }}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {open && (
        <ViewDeliveryChallanModel
          open={open}
          onCancel={() => setIsOpen(false)}
          invoiceId={invoiceId}
        />
      )}
      {confirmationModel && (
        <ConfirmationModal
          open={confirmationModel}
          text={`Do you really want to ${
            deleteDeliveryChallan.status === 1 ? "Delete" : "Active"
          }  this Delivery Challan "${deleteDeliveryChallan.name}"?`}
          onNo={() => setConfirmationModel(false)}
          onYes={() => {
            setConfirmationModel(false);
            setDeleteDeliveryChallan({ ...deleteDeliveryChallan, open: true });
          }}
        />
      )}
    </Card>
  );
};
export default DeliveryChallan;

export const StatusShow = ({ dueDate }: any) => {
  let col: string = "",
    txt = "",
    status;
  let diff = dayjs().diff(dayjs(dueDate), "day", true);

  if (diff >= 1) status = 0;
  switch (status) {
    case 0:
      col = "success";
      txt = "Delivered";
      break;
    default:
      col = "error";
      txt = "Pending";
  }
  return <Tag color={col}>{txt}</Tag>;
};
