import React from "react";
import {
  Button,
  Card,
  Col,
  Popover,
  Row,
  Select,
  Table,
  Tooltip,
  Skeleton,
  Input,
  Tabs,
} from "antd";
import { NavLink, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DownOutlined,
  FilterFilled,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useClient } from "../../../Context/ClientContext";
import "../../Parties/Party.css";
import { useTheme } from "../../../Context/ThemeContext";
import { ColumnsType } from "antd/es/table";
import LedgerService from "../../../Services/LedgerService";
import { Utils } from "../../../../Utilities/Utils";
import search from "../../../Images/search.svg";
import { AddLedger } from "./AddLedger";
import { useKeyboardShortcuts } from "../../../Common/KeyboardShortcuts ";
import { getIndianStates } from "../../../Services/ClientService";
import { IndianStates } from "../../../../Types/CommonConstants";
import VoucherDetails from "../../Reports/VoucherDetailsByLedger";

interface DataType {
  id: any;
  key: React.Key;
  ledgerName: string;
  email: string;
  phone: number;
  balance: number;
}

const LedgerDetails = () => {
  const { ledgerId } = useParams();
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [trigger, setTrigger] = React.useState<boolean>(false);

  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [openModel, setOpenModel] = React.useState<{
    open: boolean;
    id: any;
  }>({
    open: false,
    id: "",
  });
  const [ledgerDetailsData, setLedgerDetailsData] = React.useState<{
    ledgerName: string;
    groupName: string;
    id: string;
    balance: number;
    businessNature: number;
    gstType: number;
    gstin: string;
    state: IndianStates;
    partyOrTax: number;
    creditLimit: number;
    taxRate: number;
    typeOfDuty: number;
    system: Boolean;
  }>({
    ledgerName: "",
    groupName: "",
    id: "",
    balance: 0,
    businessNature: -1,
    gstType: -1,
    gstin: "",
    state: IndianStates.Unspecified,
    partyOrTax: -1,
    creditLimit: -1,
    taxRate: -1,
    typeOfDuty: -1,
    system: false,
  });

  const BusinessNatures = [
    "All",
    "Manufacturer",
    "ServiceProvider",
    "Hybrid",
    "Trader",
  ];

  const GstType = [
    "Unregistered",
    "Regular",
    "Composition",
    "Unspecified",
    "Consumer",
  ];

  const DutyType = ["GST", "TDS", "TCS", "Cess", "other"];

  // --------- Table Columns  ---------
  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "ledgerName",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <NavLink
            to={`/accounts/clients/${currentClient?.id}/master/ledger/${record?.id}`}
          >
            <div
              onClick={() => {
                const ledgerDetails = tableData.find(
                  (el: any, i: any) => el?.id === record?.id
                );
                // return console.log("ledgerDetails", ledgerDetails);
                return setLedgerDetailsData(ledgerDetails);
              }}
              style={{
                color: "black",
              }}
            >
              {record?.ledgerName}
            </div>
          </NavLink>
        </Skeleton>
      ),
    },
  ];
  console.log("dataClick", ledgerDetailsData);

  // --------- Table list data ---------
  React.useEffect(() => {
    setLoading(true);
    const getListData = async (searchItem?: string) => {
      await LedgerService.getLedgerList(
        currentClient?.id!,
        0,
        0,
        searchItem,
        "",
        "",
        ""
      )
        .then((res: any) => {
          if (res.items) {
            setTableData(
              res?.items?.map((r: any, i: any) => {
                let defaultConatct = r?.contactInformation?.find(
                  (x: any) => x?.isDefault === true
                );
                // console.log("defaultConatct", defaultConatct);
                return {
                  key: `${r?.id}-${i}`,
                  sno: i + 1,
                  id: r?.id,
                  ledgerName: r?.ledgerName,
                  parentGroup: r?.groupName,
                  balance: Utils.getFormattedNumber(r?.balance),
                  gstin: r?.gstin,
                  name: defaultConatct?.name,
                  phone: defaultConatct?.phone,
                  businessNature: r?.businessNature,
                };
              })
            );
            setTotalRecords(res?.totalRecords);
            setTableData(res.items);
            // return console.log("ledgerDetails", ledgerDetails);
            setLedgerDetailsData(
              res.items.find((el: any, i: any) => el?.id === ledgerId)
            );

            setLoading(false);
          } else {
            setTableData([]);
            setLoading(false);
            setTotalRecords(res?.totalRecords);
          }
        })
        .catch((err: any) => {
          setLoading(false);
        });
    };
    getListData(searchItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, searchItem]);

  //----- Short-Cut for Item -----
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    setOpenModel({ open: true, id: "" });
  });

  return (
    <>
      <Row>
        <Col lg={5} style={{ paddingRight: "5px", display: "flex" }}>
          <Card
            style={{ border: "1.5px solid #eef2fe" }}
            className="ca-card-body"
          >
            <div>
              <Row
                align={"middle"}
                style={{
                  width: "292px",
                  paddingTop: "5px",
                  borderBottom: "1px solid #DFE9F7",
                }}
              >
                <Col lg={1} style={{ margin: "0px 8px" }}>
                  <NavLink
                    to={`/accounts/clients/${currentClient?.id}/master/ledger`}
                  >
                    <ArrowLeftOutlined />
                  </NavLink>
                </Col>
                <Col lg={19}>
                  <Input
                    allowClear
                    // width: "220px",
                    style={{ margin: "5px 0px 5px 0px" }}
                    placeholder="Search Ledger.."
                    onChange={(e: any) => {
                      setLoading(true);
                      if (!e.cancelable) {
                        const searchItem = setTimeout(() => {
                          setSearchItem(e.target.value);
                        }, 1000);
                        return () => clearTimeout(searchItem);
                      } else {
                        // console.log("Click cancle............");
                        setSearchItem(e.target.value);
                      }
                    }}
                    suffix={
                      loading || searchItem ? (
                        ""
                      ) : (
                        <img alt="Search.." src={search} />
                      )
                    }
                  />
                </Col>
                <Col lg={2} style={{ marginLeft: "8px" }}>
                  <PlusOutlined
                    onClick={() => {
                      setOpenModel({ open: true, id: "" });
                    }}
                  />
                </Col>
                {/* <Col lg={2}>
                  <Popover
                    trigger="click"
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                    content={
                      <Row style={{ maxWidth: "230px" }}>
                        <Col lg={24}>
                          <Select
                            style={{ width: "100%" }}
                            options={[
                              {
                                label: "All",
                                value: 0,
                              },
                              { label: "Customer", value: 1 },
                              { label: "Suppliers", value: 2 },
                            ]}
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="GST Type"
                            // value={party}
                            options={[
                              {
                                label: "Unregistered",
                                value: 0,
                              },
                              { label: "Regular", value: 1 },
                              { label: "Composition", value: 2 },
                            ]}
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="State"
                            optionFilterProp="children"
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Row justify="space-around">
                            <Button type="primary">Apply</Button>
                            <Button danger>Reset</Button>
                            <Button>Close</Button>
                          </Row>
                        </Col>
                      </Row>
                    }
                  >
                    <FilterFilled
                      style={{ color: "#9AAEC1", fontSize: "16px" }}
                    />
                  </Popover>
                </Col>
                <Col lg={1}>
                  <DownOutlined />
                </Col> */}
              </Row>

              <div
              // style={{
              //   // height: toggle ? "8px " : `calc(100vh - 145px)`,
              //   // backgroundColor: "GrayText",
              //   overflowY: "auto",
              // }}
              >
                <div
                  className={"withoutanimation"}
                  style={{
                    maxHeight: "100%",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  <Table
                    rowClassName={(record) => {
                      return record?.id === ledgerDetailsData?.id
                        ? "ca-active-table"
                        : "";
                    }}
                    className={`Tabel-style-none table-${
                      themeData?.componentSize ?? "middle"
                    }`}
                    showHeader={false}
                    // rowSelection={rowSelection}
                    dataSource={
                      loading
                        ? (Array.from({ length: pageSize }, (_, index) => ({
                            key: `loading-${index}`,
                          })) as DataType[])
                        : tableData
                    }
                    columns={columns}
                    pagination={false}
                  />
                </div>

                <div
                  style={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#F5F8FF",
                    height: "40px",
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th className="textStart">Total</th>
                        <th style={{ textAlign: "end" }}>{totalRecords}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col
          lg={19}
          // style={{ paddingLeft: "22px" }}
          className="ca-card-body-padding"
        >
          <Card
            style={{
              border: "1.5px solid #eef2fe",
              marginBottom: "10px",
            }}
          >
            <Row>
              <Col span={6}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Ledger:
                  </span>
                  {ledgerDetailsData?.ledgerName?.length > 30 ? (
                    <Tooltip
                      title={ledgerDetailsData?.ledgerName}
                      placement="top"
                      color="#3D4998"
                    >
                      {ledgerDetailsData?.ledgerName.slice(0, 30) + "..."}
                    </Tooltip>
                  ) : (
                    ledgerDetailsData?.ledgerName
                  )}
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Group:
                  </span>
                  {ledgerDetailsData?.groupName}
                </div>
              </Col>
              <Col span={6}>
                <div>
                  <span
                    style={{
                      paddingRight: "14px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Balance:
                  </span>
                  {ledgerDetailsData?.balance === 0
                    ? 0
                    : ledgerDetailsData?.balance > 0
                    ? Utils.getFormattedNumber(ledgerDetailsData?.balance) +
                      " Dr"
                    : Utils.getFormattedNumber(
                        Math.abs(ledgerDetailsData?.balance)
                      ) + " Cr"}
                </div>
                {ledgerDetailsData?.partyOrTax === 1 && (
                  <>
                    <div>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Business Nature:
                      </span>
                      {BusinessNatures[ledgerDetailsData?.businessNature]}
                    </div>
                  </>
                )}
                {ledgerDetailsData?.partyOrTax === 2 && (
                  <>
                    <div>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Type of Duty:
                      </span>
                      {DutyType[ledgerDetailsData?.typeOfDuty]}
                    </div>
                  </>
                )}
              </Col>
              <Col span={5}>
                {ledgerDetailsData?.partyOrTax === 1 && (
                  <>
                    <div>
                      <span
                        style={{
                          paddingRight: "16px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        GST Type:
                      </span>
                      {GstType[ledgerDetailsData?.gstType]}
                    </div>
                  </>
                )}
                {ledgerDetailsData?.partyOrTax === 2 && (
                  <>
                    <div>
                      <span
                        style={{
                          paddingRight: "16px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Tax Rate:
                      </span>
                      {ledgerDetailsData?.taxRate
                        ? ledgerDetailsData?.taxRate + "%"
                        : ""}
                    </div>
                  </>
                )}
                {ledgerDetailsData?.partyOrTax === 1 && (
                  <>
                    <div>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        GSTIN:
                      </span>
                      {ledgerDetailsData?.gstin}
                    </div>
                  </>
                )}
              </Col>
              {/* style={{ marginRight: "30px" }} */}
              <Col span={6}>
                {ledgerDetailsData?.partyOrTax === 1 && (
                  <>
                    <div>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        State:
                      </span>
                      {getIndianStates(ledgerDetailsData?.state)}
                    </div>
                  </>
                )}
                {ledgerDetailsData?.partyOrTax === 1 && (
                  <>
                    <div>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Credit Limit:
                      </span>
                      {ledgerDetailsData?.creditLimit}
                    </div>
                  </>
                )}
              </Col>
              <Col span={1} style={{ textAlign: "center" }}>
                {/* <Tooltip placement="top" color="#3D4998"> */}
                <Tooltip
                  title={
                    ledgerDetailsData?.system
                      ? "Default ledger are not editable"
                      : "Edit"
                  }
                  placement="top"
                  color={
                    ledgerDetailsData?.system ? "rgb(216 207 207)" : "#444653"
                  }
                  // color="#444653"
                >
                  <FormOutlined
                    className="ca-edit-btn"
                    // style={{ color: "#a5abdd" }}
                    style={{
                      color: ledgerDetailsData?.system
                        ? "rgb(216 207 207)"
                        : "#444653",
                    }}
                    onClick={() => {
                      !ledgerDetailsData?.system &&
                        setOpenModel({
                          open: true,
                          id: ledgerDetailsData?.id,
                        });
                    }}
                    // onClick={() => {
                    //   setOpenModel({ open: true, id: ledgerDetailsData?.id });
                    // }}
                  />
                </Tooltip>
                {/* </Tooltip> */}
              </Col>
            </Row>
          </Card>
          <div>
            <Card className="ca-card-body">
              <div
                style={{
                  height: `calc(100vh - 168px)`,
                  // backgroundColor: "azure",
                  overflowY: "auto",
                }}
              >
                <VoucherDetails
                  ledgerId={ledgerDetailsData?.id}
                  ledgerName={ledgerDetailsData?.ledgerName}
                />
              </div>
            </Card>
          </div>
        </Col>
      </Row>
      {openModel.open && (
        <AddLedger
          openDrawer={openModel.open}
          id={openModel?.id}
          setOpenDrawer={() => setOpenModel({ open: false, id: "" })}
          setTrigger={() => setTrigger(!trigger)}
        />
      )}
    </>
  );
};

export default LedgerDetails;
