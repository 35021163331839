import { InvoicesGetTypes } from "../../Types/CommonConstants";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
export enum TradingPartnerType {
  All = 0,
  Customer = 1,
  Supplier = 2,
}

class PartyService {
  private route = "/v1/api";
  //https://localhost:7142/v1/api/Acc_TradingPartners/64648be6e5150fb677222509  //for post

  //https://localhost:7142/v1/api/Acc_TradingPartners/64647592e72dfee880fd3f5d/64647592e72dfee880fd3f5c //for editpost

  PostParty = (clientId: string, PostBody: any, id?: string) => {
    return ApiUtility.postForm(
      `${this.route}/Acc_TradingPartners/${clientId}${id ? "/" + id : ""}`,
      PostBody
    );
  };

  //https://localhost:7142/v1/api/Acc_TradingPartners/64647592e72dfee880fd3f5d?type=1&start=0&length=15 //for getlist

  // https: //localhost:7142/v1/api/Acc_TradingPartners/64647592e72dfee880fd3f5d?type=1&start=0&length=15
  getPartyList = (
    clientId: any,
    type: TradingPartnerType,
    start: any,
    length: any,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    status?: any
  ) => {
    return ApiUtility.getResult(
      `${this.route}/Acc_TradingPartners/${clientId}`,
      {
        type,
        start,
        length,
        sortCol,
        sortDir,
        search,
        status,
      }
    );
  };

  //https://localhost:7142/v1/api/Acc_TradingPartners/64647592e72dfee880fd3f5d/649d90422a02febbbd1fc99d

  getPartyById = (clientId: string, id?: string) => {
    return ApiUtility.get(
      `${this.route}/Acc_TradingPartners/${clientId}/${id}`
    );
  };

  // getting the invoice data of particular party
  getInvoicesByParty = (
    clientId: string,
    partyId?: string,
    type?: InvoicesGetTypes
  ) => {
    return ApiUtility.get(
      `${this.route}/Acc_TradingPartners/${clientId}/${partyId}/invoices?type=${type}`
    );
  };

  // https: //localhost:7142/V1/API/TradingPartnersDetails/6463611020350584c0d5c017?type=1&start=0&length=15&iState=1&GSTType=3
  //  Party Deatils Information
  getPartDetailsList = (
    clientId: string,
    type: TradingPartnerType,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    status?: any,
    GSTType?: number,
    iState?: number
  ) =>
    ApiUtility.getResult(`${this.route}/TradingPartnersDetails/${clientId}`, {
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      GSTType,
      iState,
    });

  //  ------- Party Balance List Api -------
  getPartytDetailsById = (clientId: string, id: string) =>
    ApiUtility.getResult(
      `${this.route}/TradingPartnersDetails/${clientId}/${id}`
    );

  getPartyBalanceList = (
    clientId: string,
    partyId: string | undefined,
    start: number,
    length: number,
    type: TradingPartnerType,
    from: string,
    to: string
  ) =>
    ApiUtility.getResult(
      `${this.route}/TradingPartnersDetails/${clientId}/customer`,
      {
        partyId,
        start,
        length,
        type,
        from,
        to,
      }
    );
}

export default new PartyService();
