import { Button, Divider, Form, Input, Modal, Select, Spin } from "antd";
import React, { useEffect } from "react";
import ChartofAccountsServices from "../../Services/ChartofAccountsServices";
import { useClient } from "../../Context/ClientContext";
import { AccountGroups } from "../../../Types/CommonConstants";
import { notificationContext } from "../../Common/PageRoute";
import { LoadingOutlined } from "@ant-design/icons";

const AddChartOfAccounts: React.FC<{
  open: boolean;
  onCancle: (value?: any) => void;
  clientId: string;
  id: number;
  setRefresh: (value?: boolean) => void;
  refresh: boolean;
}> = (props) => {
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { openNotification } = React.useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [initialValues, setInitialValues] = React.useState({
    name: "",
    code: "",
    company: {},
    tags: [],
    isCF: false,
  });

  const sumbit = () => {
    setSaveButtonLoading(true);
    form.validateFields().then((val: any) => {
      const data = {
        ...initialValues,
        type: val?.type,
        name: val?.name,
        code: val?.code,
        BusinessType: [currentClient?.type],
      };

      ChartofAccountsServices.postChartofAccounts(
        data,
        props?.clientId,
        props?.id
      )
        .then((res: any) => {
          if (res.status) {
            openNotification("success", "Added Successfully");
            props.onCancle();
            props.setRefresh(!props.refresh);
          }
          setSaveButtonLoading(false);
        })
        .catch((ex) => {
          openNotification("error", ex);
          setSaveButtonLoading(false);
        });
    });
  };

  //  Get COA by id
  useEffect(() => {
    if (props?.id) {
      setLoading(true);
      ChartofAccountsServices.getChartofAccounts(props?.clientId, props?.id)
        .then((res: any) => {
          if (res.result) {
            setInitialValues(res.result);
            form.setFieldsValue(res.result);
          }
          setLoading(false);
        })
        .catch((ex: any) => {
          console.log(ex);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  return (
    <div>
      <Modal
        open={props.open}
        title={props.id ? "Edit Account" : "Add Account"}
        maskClosable={false}
        width={350}
        style={{ top: 44 }}
        onCancel={() => props.onCancle()}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancle();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => sumbit()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
            >
              {props.id ? "Edit" : "Save"}
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={loading}
        >
          <Form
            name="Account"
            form={form}
            autoCapitalize="false"
            requiredMark={false}
            colon={false}
            initialValues={initialValues}
          >
            <span>Account type</span>
            <Form.Item
              name="type"
              className="mb-10"
              rules={[{ required: true, message: "Account Type is required" }]}
            >
              <Select
                options={
                  [
                    // ...(getAccountTypesOptions!([
                    //   AccountGroups.FixedAssets,
                    //   AccountGroups.FixedAssetsLeased,
                    //   AccountGroups.FixedAssetInvestments,
                    // ]) || []),
                  ]
                }
                onChange={(val) => {
                  ChartofAccountsServices.getAccountCode(
                    props?.clientId,
                    val
                  ).then((res) => {
                    console.log(res);
                    if (res?.status) {
                      form.setFieldValue("code", res?.result);
                    }
                  });
                }}
              />
            </Form.Item>
            <span>Name</span>
            <Form.Item
              name="name"
              className="mb-10"
              rules={[{ required: true, message: "Account Name is required" }]}
            >
              <Input />
            </Form.Item>
            <span>Code</span>
            <Form.Item
              name="code"
              className="mb-10"
              rules={[{ required: true, message: "Account Code is required" }]}
            >
              <Input />
            </Form.Item>
          </Form>
          <Divider className="divider-account" />
        </Spin>
      </Modal>
    </div>
  );
};

export default AddChartOfAccounts;
