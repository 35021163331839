import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./Store";

interface UserRights {}

interface UserState {
  token: string;
  name: string;
  tokenExpiry: number;
  id: string;
  role: string;
  email: string;
  userName: string;
  status: number;
  roles: string[];
  rights: UserRights | null;
}

const initialState: UserState = {
  token: "",
  name: "",
  tokenExpiry: 0,
  id: "",
  role: "",
  email: "",
  userName: "",
  status: 0,
  roles: [],
  rights: null,
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginUserData: (state, action) => {
      state.token = action.payload.token;
      state.name = action.payload.name;
      state.tokenExpiry = action.payload.tokenExpiry;
      state.id = action.payload.id;
      state.role = action.payload.role;
      state.email = action.payload.email;
      state.userName = action.payload.userName;
      state.status = action.payload.status;
      state.roles = action.payload.roles;
      state.rights = action.payload.rights;
    },
    resetLoginUserData: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { setLoginUserData, resetLoginUserData } = UserSlice.actions;
export const userData = (state: RootState) => state.data.User
// export const userToken = (state: RootState) => state.user.token;
export default UserSlice.reducer;
