import React from "react";
import {
  Checkbox,
  Col,
  Divider,
  Row,
  Form,
  Button,
  message,
  Tooltip,
} from "antd";
import "./Settings.css";
import {
  CloseOutlined,
  EditOutlined,
  FormOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const SettingsAdvance: React.FC = () => {
  const [form] = Form.useForm();
  const [isDisable, setIsDisable] = React.useState<boolean>(true);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Setting updated successfully",
    });
  };
  return (
    <div style={{ padding: "0px 0px 3px 0px" }}>
      {contextHolder}
      <Row>
        <Col span={6} className="fs-18 fw-600">
          Output Options
        </Col>
        <Col>
          {isEdit ? (
            <></>
          ) : (
            <>
              <Tooltip title="Edit">
                <FormOutlined
                  style={{
                    color: "rgb(106 119 227)",
                    paddingTop: "10px",
                    marginLeft: "30px",
                  }}
                  className="ca-edit-btn"
                  onClick={() => {
                    setIsDisable(false);
                    setIsEdit(true);
                  }}
                />
              </Tooltip>
            </>
          )}
        </Col>
      </Row>
      <Divider className="my-12" />

      <Form form={form} disabled={isDisable}>
        <Row style={{ paddingTop: "0px" }}>
          <Col lg={3} md={7}>
            <Form.Item className="inputboxform_mb">
              <Checkbox defaultChecked>Show logo</Checkbox>
            </Form.Item>
            <Form.Item className="inputboxform_mb">
              <Checkbox>Show tax no</Checkbox>
            </Form.Item>
            <Form.Item className="inputboxform_mb">
              <Checkbox defaultChecked>Hide Discount</Checkbox>
            </Form.Item>
            <Form.Item className="inputboxform_mb">
              <Checkbox>Show item code</Checkbox>
            </Form.Item>
            <Form.Item className="inputboxform_mb">
              <Checkbox defaultChecked>Show tax column</Checkbox>
            </Form.Item>
          </Col>
          <Col lg={6} md={10}>
            <Form.Item className="inputboxform_mb">
              <Checkbox defaultChecked>Show column headings</Checkbox>
            </Form.Item>
            <Form.Item className="inputboxform_mb">
              <Checkbox defaultChecked>Show unit price & quantity</Checkbox>
            </Form.Item>
            <Form.Item className="inputboxform_mb">
              <Checkbox>Show Contact Account Number</Checkbox>
            </Form.Item>
            <Form.Item className="inputboxform_mb">
              <Checkbox defaultChecked>Show registered address</Checkbox>
            </Form.Item>
            <Form.Item className="inputboxform_mb">
              <Checkbox>Show payment advice cut-away</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {isEdit ? (
        <>
          <Row gutter={6}>
            <Col>
              <Button
                style={{
                  marginLeft: "8px",
                }}
                onClick={() => {
                  setIsDisable(true);
                  setIsEdit(false);
                }}
              >
                <CloseOutlined />
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={success} icon={<SaveOutlined />}>
                Save
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {/* <EditOutlined />
            <span style={{ paddingLeft: "5px" }}>Edit</span> */}
        </>
      )}
    </div>
  );
};

export default SettingsAdvance;
