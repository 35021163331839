import { CloseOutlined, FormOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useContext } from "react";
import { useClient } from "../../Context/ClientContext";
import { SettingsSections } from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";

const SettingsReport = () => {
  const { companySettings, updateCompanySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [form] = Form.useForm();
  const [disable, setDisable] = React.useState<boolean>(true);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);

  const submitReport = () => {
    form.validateFields().then((val: any) => {
      const data = {
        tbSettings: {
          ...val,
          showAccounts: {
            id: val?.showAccounts?.value,
            name: val?.showAccounts?.label,
          },
        },
      };
      console.log("submit", data);
      setSaveButtonLoading(true);
      updateCompanySettings!(
        SettingsSections.TBSettings,
        {
          ...companySettings,
          setting: {
            ...companySettings?.setting!,
            tbSettings: data?.tbSettings,
          },
        },
        data
      )
        .then((result: any) => {
          if (result?.status) {
            setDisable(true);
            openNotification("success", "Setting Updated successfully");
            setSaveButtonLoading(false);
          } else {
            openNotification("error", "there is some error");
            setSaveButtonLoading(false);
          }
        })
        .catch((ex: any) => {
          setSaveButtonLoading(false);
          openNotification("error", ex.message);
        });
    });
  };

  React.useEffect(() => {
    const set = companySettings?.setting?.tbSettings;
    console.log(set);

    const setData = {
      ...set,
      showAccounts: {
        label: set?.showAccounts?.name,
        value: set?.showAccounts?.id,
      },
      hideDecimals: set?.hideDecimals,
      roundToThousand: set?.roundToThousand,
      roundToMillion: set?.roundToMillion,
      showZeros: set?.showZeros,
      showNegative: set?.showNegative,
      detailed: set?.detailed,
      notes: set?.notes,
    };
    form.setFieldsValue(setData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log("tb", set);
  }, [companySettings]);

  return (
    <div style={{ padding: "0px 0px 3px 0px", width: "40%" }}>
      <Row justify={"space-between"}>
        <Col className="fs-15 fw-600">Customize Report</Col>
        <Col>
          {disable && (
            <Tooltip title="Edit">
              <FormOutlined
                className="ca-edit-btn"
                style={{ color: "rgb(106 119 227)" }}
                onClick={() => setDisable(false)}
              />
            </Tooltip>
          )}
        </Col>
      </Row>
      <Divider className="my-12" />
      <Divider orientation="left" orientationMargin={0} className="m-0">
        Number format
      </Divider>

      <Form
        form={form}
        colon={false}
        name="Setting-PaymentTerm"
        disabled={disable}
        labelAlign="left"
        labelCol={{ flex: "150px" }}
        // wrapperCol={{ span: 4 }}
      >
        <Form.Item
          label="Show decimals"
          name="hideDecimals"
          valuePropName="checked"
          className="mb-10"
        >
          <Checkbox />
        </Form.Item>
        <Row>
          <Col span={8}>
            <span>Round-off</span>
            <Form.Item
              name="roundToThousand"
              valuePropName="checked"
              className="mb-10"
            >
              <Checkbox>In thousands</Checkbox>
            </Form.Item>
            <Form.Item name="roundToMillion" valuePropName="checked">
              <Checkbox>In millions</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <span>Show zero as</span>
            <Form.Item name="showZeros">
              <Radio.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                  rowGap: "10px",
                }}
              >
                <Radio value={true}>0</Radio>
                <Radio value={false}>-</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <span>Show negative numbers as</span>
            <Form.Item name="showNegative">
              <Radio.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                  rowGap: "10px",
                }}
              >
                <Radio value={true}>{`-500`}</Radio>
                <Radio value={false}>{`(500)`}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider className="divider-setting" />
        <Form.Item
          label="Show accounts"
          name="showAccounts"
          wrapperCol={{ span: 10 }}
          className="mb-10"
        >
          <Select
            labelInValue
            defaultValue="Account with transaction"
            options={[
              { value: 0, label: "Accounts with transactions" },
              { value: 1, label: "Accounts with balance" },
            ]}
          />
        </Form.Item>

        {/* <Form.Item
          label="Show columns"
          name="showCustomCols"
          className="mb-10"
        >
          <Radio.Group>
            <Radio value="false">Default</Radio>
            <Radio value="true">Custom</Radio>
          </Radio.Group>
        </Form.Item> */}
        {/* <Row gutter={15}>
          <Col span={10}>
            <Form.Item
              label="Show"
              name="showAccBasis"
              valuePropName="checked"
              className="mb-10"
            >
              <Checkbox>Accounting basic</Checkbox>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="showDatePrepared"
              valuePropName="checked"
              className="mb-10"
            >
              <Checkbox>Date prepared</Checkbox>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="showTimePrepared"
              valuePropName="checked"
              className="mb-10"
            >
              <Checkbox>Time prepared</Checkbox>
            </Form.Item>
          </Col>
        </Row> */}
        <Divider orientation="left" orientationMargin={0}>
          Changed format to
        </Divider>
        <Row>
          <Col span={12}>
            <Form.Item
              name="detailed"
              valuePropName="checked"
              className="mb-10"
            >
              <Checkbox>Detailed trial balance</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="monthly" valuePropName="checked" className="mb-10">
              <Checkbox>Monthly trial balance</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left" orientationMargin={0}>
          Notes
        </Divider>
        <Form.Item
          name="notes"
          className="mb-10"
          // wrapperCol={{ span: 12 }}
        >
          <TextArea placeholder="note" />
        </Form.Item>
      </Form>
      <Row justify="end" style={{ height: 32 }}>
        {!disable && (
          <Space>
            <Button
              icon={<CloseOutlined />}
              style={{ backgroundColor: "aliceblue" }}
              onClick={() => {
                setDisable(true);
              }}
            >
              Cancel
            </Button>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              loading={saveButtonLoading}
              onClick={() => {
                setDisable(true);
                submitReport();
              }}
            >
              Save
            </Button>
          </Space>
        )}
      </Row>
      {/* {!disable && (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setDisable(true);
              sumbit();
            }}
            icon={<SaveOutlined />}
            style={{ marginTop: "6px" }}
          >
            Save
          </Button>

          <Button
            onClick={() => setDisable(true)}
            icon={<CloseOutlined />}
            style={{ marginTop: "6px" }}
          >
            Cancel
          </Button>
        </Space>
      )}
      {disable && (
        <Button
          type="primary"
          onClick={() => setDisable(false)}
          icon={<EditOutlined />}
          style={{ marginTop: "6px" }}
        >
          Edit
        </Button>
      )} */}
    </div>
  );
};

export default SettingsReport;
