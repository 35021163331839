import React from "react";
import { Button, Input, Modal } from "antd";

interface IAdditionalNotesProps {
  value: string;
  open: boolean;
  save: (note: string) => void;
  cancel: () => void;
}
const AdditionalNotes = (props: IAdditionalNotesProps) => {
  const [note, setNote] = React.useState<string>(props.value);

  React.useEffect(() => {
    // Reset the note state when the modal opens
    if (props.open) {
      setNote(props.value);
    }
  }, [props.open, props.value]);

  const handleSave = () => {
    props.save(note);
    props.cancel(); // Close the modal after saving
  };

  return (
    <div>
      <Modal
        open={props.open}
        title="Note"
        maskClosable={false}
        closable={false}
        centered
        width={500}
        footer={
          <>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
            <Button onClick={props.cancel}>Cancel</Button>
          </>
        }
      >
        {/* <TextArea value={note} onChange={(e) => setNote(e?.target.value)} /> */}
        <Input.TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Modal>
    </div>
  );
};
export default AdditionalNotes;
