import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { LedgerStatus, SearchLedgerByGroupsTypes } from "./LedgerService";

export enum Statements {
  Trading = 1,
  ProfitAndLoss = 2,
  BalanceSheet = 3,
}
export enum GroupTypes {
  Primary = 1,
  Secondary = 2,
}
export enum Nature_new {
  DirectExpenses = 1,
  DirectIncome = 2,
  IndirectExpenses = 3,
  IndirectIncome = 4,
  Assets = 5,
  Liabilities = 6,
}

export enum Default_Groups {
  Self = 0,
  PurchaseAccount,
  DirectExpenses,
  DirectIncomes,
  SalesAccounts,
  IndirectExpenses,
  IndirectIncomes,
  FixedAssets,
  Investments,
  LoansAdvancesAsset,
  MiscExpensesAsset,
  CurrentAssets,
  StockInHand,
  SundryDebtors,
  DepositsAsset,
  BankAccounts,
  CashInHand,
  CapitalAccount,
  LoansLiability,
  ReservesSurplus,
  SecuredLoans,
  UnsecuredLoans,
  CurrentLiabilities,
  SundryCreditors,
  BankOD,
  DutiesTaxes,
  Provisions,
  Suspense,
}

//  not taken for now enum
export enum NewCompanyType {
  All = 0,
  Customer = 1,
  Supplier = 2,
}

export enum BusinessTypes {
  PrivateLimited,
  PublicLimited,
  SoleProprietorship,
  Partnership,
  LLP,
  LLC,
  JointVenture,
  NGO,
}

class ChartofAccountServiceNew {
  route = "v1/API";

  // ----- Group Services(Api) -----
  getGroupList = (
    companyId: string,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    //GroupType?: GroupTypes,
    //sGroupName?: string,
    //sAliasName?: string,
    // sOrder?: string,
    groupId?: string,
    groupSelType?: SearchLedgerByGroupsTypes,
    groupSearch?: string,
    status?: LedgerStatus
  ) =>
    ApiUtility.getResult(`${this.route}/Acc_New_Group/${companyId}/GetList`, {
      search,
      sortCol,
      sortDir,
      //GroupType,
      // sGroupName,
      //sAliasName,
      // sOrder,
      groupId,
      groupSelType,
      groupSearch,
      status,
    });

  postGroup = (companyId: string, params: any, id?: string) =>
    ApiUtility.post(
      `${this.route}/Acc_New_Group/${companyId}/${id ? id : ""}`,
      params
    );
  // delete Api
  // https://localhost:7142/v1/API/Acc_New_Group/65cdd05235241f48a55de738/Delete/6633996dfc3a199983c6c8a8

  deleteGroup = (companyId: string, groupId: string) =>
    ApiUtility.post(
      `${this.route}/Acc_New_Group/${companyId}/SetActiveDeactive/${groupId}`,
      null
    );

  // ----- Department Services(Api) -----
  getDepttList = (
    companyId: string,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: LedgerStatus
  ) =>
    ApiUtility.getResult(`${this.route}/Acc_Departments/${companyId}/GetList`, {
      search,
      sortCol,
      sortDir,
      status,
    });

  postDepartment = (companyId: string | undefined, params: any, id?: string) =>
    ApiUtility.post(
      `${this.route}/Acc_Departments/${companyId}/${id ? id : ""}`,
      params
    );
  // delete Api
  // https://localhost:7142/v1/API/Acc_New_Group/65cdd05235241f48a55de738/Delete/6633996dfc3a199983c6c8a8

  deleteDepartment = (companyId: string, groupId: string) =>
    ApiUtility.post(
      `${this.route}/Acc_Departments/${companyId}/SetActiveDeactive/${groupId}`,
      null
    );
}
export default new ChartofAccountServiceNew();
