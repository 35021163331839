import React from "react";

// {darkMode: false, themeColor: '#8628c0', fontSize: 14, borderRedius: 6, componentSize: 'middle'}

export interface IThemeData {
  darkMode?: boolean;
  themeColor?: string;
  fontSize?: number;
  componentSize: "small" | "middle" | "large" | undefined;
  tablePageSize: number;
}

interface IThemeContextProps {
  themeData: IThemeData;
}

const ThemeContext = React.createContext<Partial<IThemeContextProps>>({});

// const [onChange, setOnChange] = React.useState<any>();

const ThemeContextProvider = (props: any) => {
  const [themeData, setThemeData] = React.useState<IThemeData>();

  React.useEffect(() => {
    if (localStorage.getItem("ci-config")) {
      const x: any = localStorage?.getItem("ci-config");
      // console.log("context localStorage", JSON.parse(x));
      setThemeData(JSON.parse(x));
    } else {
      //  setThemeData({ tablePageSize: 15 });
    }
  }, [props]);

  // console.log(props.data);

  return (
    <ThemeContext.Provider
      value={{
        themeData: themeData as IThemeData,
      }}
      children={props.children}
    />
  );
};

export default ThemeContextProvider;

export function useTheme() {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("ClientData context not defined");
  }
  return context;
}
