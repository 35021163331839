import { Col, Divider, Modal, Row, Button, Spin } from "antd";
import React, { useContext, useEffect } from "react";
import { useClient } from "../../Context/ClientContext";
import noteContext from "../../Context/StyleContext";
import "../../Common/AddPageCommon.css";
import { LoadingOutlined } from "@ant-design/icons";
import { notificationContext } from "../../Common/PageRoute";
import { dayjs } from "../../../Utilities/dayjs";
import { NavLink, useLocation } from "react-router-dom";
import VoucherServices, {
  Party_Tax,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { Utils } from "../../../Utilities/Utils";
import AdjustBankBalanceModel from "./Banks/AdjustBankBalanceModel";
import { useUser } from "../../../Context/UserContext";

const ViewContra: React.FC<{
  open: boolean;
  onCancel: any;
  voucherId: string | undefined;
  type?: any;
}> = (props) => {
  console.log(props);
  const { windowWidth } = React.useContext(noteContext);
  const { currentClient, companySettings } = useClient();
  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const { openNotification } = useContext(notificationContext);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});
  const [refresh, setRefresh] = React.useState<boolean>(false);

  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [bank, setBank] = React.useState<{
    id: string;
    name: string;
    partyOrTax: Party_Tax;
  }>({
    id: "",
    name: "",
    partyOrTax: Party_Tax.other,
  });
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (props.voucherId) {
      setLoading(true);
      VoucherServices.getVoucharById(
        currentClient?.id!,
        props.voucherId,
        Voucher_Type.Contra
      )
        .then((res: any) => {
          setLoading(false);
          console.log("contraData", res);
          // setRefresh((x: boolean) => !x);
          setData({
            date: dayjs(res.result?.date).format("DD-MM-YYYY"),
            number: res.result?.number,
            narration: res.result?.narration,
            effectiveLedger: res.result?.effectiveLedger[0]?.ledgerName,
            effectiveLedgerId: res.result?.effectiveLedger[0]?.id,
            partyLedger: res.result?.partyLedger,
            voucherNetAmount: res.result?.amountSummary?.totalInvoiceAmount,
            amountInWords: res.result?.amountSummary.totalInvoiceAmountInWords,
            //refValue: res.result?.partyLedger?.[0]?.ref[0]?.value,
            //refName: res.result?.partyLedger?.[0]?.ref[0]?.name,
            // refValue: res.result?.partyLedger?.ref?.map(
            //   (el: any, indx: any) => {
            //     return el?.name, el?.amount;
            //   }
            // ),
          });
        })
        .catch((ex: any) => {
          openNotification("error", ex.message);
          setLoading(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.voucherId, refresh]);

  // console.log("refDetails", data?.ref);
  // console.log("refDetailsSSSSSS", data);

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        width={"50%"}
        style={{ maxHeight: `calc(100% - 20%)`, overflowY: "auto" }}
        maskClosable={false}
        closable={false}
        onCancel={() => props.onCancel()}
        centered
        footer={
          <div style={{ marginTop: "0px" }} className="pe-15">
            <Button
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setOpenModel({
                  type: "adjustBankBalance",
                  param: {
                    id: "",
                    type: "",
                  },
                });
              }}
            >
              Edit
            </Button>
            {/* <Button type="primary">Print</Button> */}
          </div>
        }
      >
        <div style={{ padding: 15 }}>
          <Spin indicator={antIcon} spinning={loading}>
            {/* {windowWidth <= 1024 ? (
              <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
                Purchase Invoice
              </h3>
            ) : (
              ""
            )} */}
            <Row>
              <Col span={12}>
                <>
                  <div>
                    <span style={{ fontSize: "1.5em" }}>
                      {companySettings?.setting.companyInfo.name}
                    </span>
                  </div>
                  <div>
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.building !== null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.building!
                          }
                        </span>
                        {", "}
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.street !== null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.street
                          }
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.landmark !== null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.landmark
                          }
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress?.city !==
                    null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.city
                          }
                        </span>
                        {", "}
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.district !== null ? (
                      <>
                        <span>
                          {
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.district
                          }
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.regAddress
                      ?.state !== null ? (
                      <>
                        <span>
                          <strong>State: </strong>
                          {Object.entries(stateOptions)?.map(([key, s]: any) =>
                            s.id ===
                            companySettings?.setting?.companyInfo?.regAddress
                              ?.state
                              ? s.name
                              : ""
                          )}
                          , <strong>Code: </strong>
                          {companySettings?.setting?.companyInfo?.regAddress?.state
                            .toString()
                            .padStart(2, "0")}
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {companySettings?.setting?.companyInfo?.gstNumber !==
                    null ? (
                      <>
                        <span>
                          <strong>GSTIN: </strong>
                          {companySettings?.setting?.companyInfo?.gstNumber}
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              </Col>
              <Col span={12} style={{ height: "auto" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <div
                    className="themeColor"
                    style={{
                      fontSize: "1.5em",
                      textAlign: "right",
                    }}
                  >
                    Cash / Bank Transfer
                  </div>
                  <div
                    style={{
                      marginTop: "auto",
                    }}
                  >
                    <div style={{ textAlign: "end" }}>
                      <strong>Date:</strong> {data?.date}
                    </div>
                    <div style={{ textAlign: "end" }}>
                      <strong>Voucher No.:</strong> {data?.number}
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: "20px" }}>
              <Col>
                <strong>Transfer From Account:</strong>{" "}
                <NavLink
                  to={`/accounts/clients/${currentClient?.id}/master/ledger/${data?.effectiveLedgerId}`}
                >
                  {data?.effectiveLedger}
                </NavLink>
              </Col>
            </Row>

            <table
              className="ca-payment-table"
              style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "8%" }} className="textStart ps-10">
                    S.No
                  </th>
                  <th style={{ width: "72%" }} className="textStart ps-10">
                    {`Ledger(s)`}
                  </th>
                  <th style={{ width: "20%" }} className="textEnd pe-10">
                    {`Amount (in ₹)`}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.partyLedger?.map((itm: any, i: any) => {
                  return (
                    <>
                      <tr key={i}>
                        <td style={{ verticalAlign: "top" }}>
                          <div className="ps-10">{i + 1}</div>
                        </td>
                        <td className="ps-10" style={{ verticalAlign: "top" }}>
                          {itm?.ledgerName}
                        </td>
                        <td
                          className="textEnd pe-10"
                          style={{ verticalAlign: "top" }}
                        >
                          {Utils.getFormattedNumber(Math.abs(itm?.amount))}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <Row className=" pt-10 pe-10">
              <Col lg={16} style={{ paddingRight: "10px" }}>
                <Row>
                  <div>
                    {`Amount (in words): `}
                    <em>INR {data.amountInWords}</em>
                  </div>
                </Row>
                <Row>
                  <strong>Description-:</strong>
                  <div>{data?.narration}</div>
                </Row>
              </Col>
              <Col lg={8} md={24}>
                <Row>
                  <Col lg={12} md={12}>
                    <span
                      style={{
                        color: "rgb(11, 48, 142)",
                        fontWeight: "400",
                        fontSize: "18px",
                      }}
                    >
                      Total Amount
                    </span>
                  </Col>
                  <Col lg={12} md={12} className="textEnd">
                    <span
                      style={{
                        color: "rgb(11, 48, 142)",
                        fontWeight: "400",
                        fontSize: "18px",
                      }}
                    >
                      &#8377;
                      {Utils.getFormattedNumber(data?.voucherNetAmount)}
                    </span>
                  </Col>
                </Row>
                <Divider
                  style={{
                    borderBottom: "2px solid rgb(203, 203, 203)",
                  }}
                  className="my-5"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "60px" }}>
              <Col span={8}>
                <strong>Prepared by</strong>
              </Col>
              <Col span={8} style={{ textAlign: "center" }}>
                <strong>Checked by</strong>
              </Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <strong>Authorized Signatory</strong>
              </Col>
            </Row>
          </Spin>
        </div>
      </Modal>

      {openModel.type === "adjustBankBalance" && (
        <AdjustBankBalanceModel
          id={props?.voucherId}
          type={props?.type}
          allBankAccount={[]}
          bank={bank}
          setRefresh={setRefresh}
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
        />
      )}
    </React.Fragment>
  );
};
export default ViewContra;
