import { IIdName } from "../../Types/CommonTypes";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export const transactionType = [
  "-",
  "Journal",
  "Sales",
  "Purchase",
  "Cr. Note",
  "Dr. Note",
  "Receipt",
  "Payment",
  "BadDebts",
  "Fixed Assets",
  "Depreciate",
  "DividendAccrual",
  "DividendPayment",
  "Opening",
];
class ReportsService {
  route = "v1/API/Reports";
  routeNew = "v1/API/New_Reports";

  getTrialBalance = (
    clientId: string,
    fromDate: string,
    toDate: string,
    isAccWithBalance: boolean,
    isMonthlyTb: boolean,
    years: number,
    isPdf: boolean
  ) => {
    return ApiUtility.getResult(`${this.route}/${clientId}/TrialBalance`, {
      fromDate,
      toDate,
      isAccWithBalance,
      isMonthlyTb,
      years,
      isPdf,
    });
  };

  // https: //localhost:7142/v1/API/New_Reports/65cdd05235241f48a55de738/TrialBalance?isAccWithBalance=false&isMonthlyTb=false&years=0&isPdf=false
  getTrialBalanceNew = (
    clientId: string,
    fromDate: string,
    toDate: string,
    isAccWithBalance: boolean,
    isMonthlyTb: boolean,
    years: number,
    isPdf: boolean
  ) =>
    ApiUtility.getResult(`${this.route}/${clientId}/TrialBalance`, {
      fromDate,
      toDate,
      isAccWithBalance,
      isMonthlyTb,
      years,
      isPdf,
    });

  getAccountDetails = (
    clientId: string,
    accountId: number | string,
    from?: string,
    to?: string,
    isPdf?: boolean
  ) => {
    return ApiUtility.getResult(
      `${this.route}/${clientId}/Account?accountId=${accountId}`,
      {
        from,
        to,
        isPdf,
      }
    );
  };

  getAccounts = (clientId: string) => {
    return ApiUtility.get(`${this.route}/${clientId}/Accounts`);
  };

  getPorfitAndLoss = (
    clientId: string,
    fromDate?: string,
    toDate?: string,
    isAccWithBalance?: boolean,
    years?: number,
    reportType?: boolean,
    isPdf?: boolean,
    frequency?: boolean
  ) => {
    return ApiUtility.get(
      `${this.route}/${clientId}/PandL?fromDate=${fromDate}`,
      {
        toDate,
        isAccWithBalance,
        years,
        reportType,
        isPdf,
        frequency,
      }
    );
  };

  getBalanceSheet = (
    clientId: string,
    fromDate: string,
    toDate: string,
    isAccWithBalance?: boolean,
    years?: number,
    reportType?: number,
    isPdf?: boolean
  ) => {
    return ApiUtility.get(
      `${this.route}/${clientId}/BalanceSheet?fromDate=${fromDate}`,
      {
        toDate,
        isAccWithBalance,
        years,
        reportType,
        isPdf,
      }
    );
  };
}
export default new ReportsService();
