import storage from "redux-persist/lib/storage"; // Use localStorage for web
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import UserSlice from "../Redex/UserSlice";
import IndianStateSlice from "../Redex/IndianStateSlice";
import persistStore from "redux-persist/es/persistStore";
import ClientReduxSlice from "../Redex/ClientRedusSlice";

const userPersistConfig = {
  version: 1,
  key: "data",
  storage,
};

// const indianPersistConfig = {
//   version: 0,
//   key: "indianState",
//   storage,
// };

// Wrap only the user slice with persistReducer
const persistcombinedReducer = combineReducers({
  User: UserSlice,
  IndianState: IndianStateSlice,
});

const persistedReducer = persistReducer(
  userPersistConfig,
  persistcombinedReducer
);
// const persistedIndianStateReducer = persistReducer(
//   indianPersistConfig,
//   IndianStateSlice
// );

const rootReducer = combineReducers({
  data: persistedReducer,
  // indianStates: persistedIndianStateReducer,
  clientRedus: ClientReduxSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


export const persistor = persistStore(store);

 
// const saveToSessionStorage = (state: RootState) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     sessionStorage.setItem("state", serializedState);
//   } catch (e) {
//     console.log(e);
//   }
// };

// store.subscribe(() => saveToSessionStorage(store.getState()));
 
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
