import { Col, Row, Tooltip, Typography } from "antd";
import { Card } from "antd";
import "../Common/Dashboardlayout.css";
import "../Common/ResponsiveUi.css";
import EChartsReact from "echarts-for-react";
import currencySymbol from "../Images/Money icon.svg";
import React from "react";
import currency1 from "../../images/currency1.svg";
import currency3 from "../../images/currency3.svg";
interface IPropDashboardChart {
  title: string;
  amount: any | number;
  paymentstatus?: string;
  paymentstatus2?: string;
  option?: any;
  pageName?: string;
}
export const DashboardChart = ({
  title,
  amount,
  option,
  pageName,
}: // paymentstatus,
// paymentstatus2,
// option,
IPropDashboardChart) => {
  // console.log(amount, "props...........");
  return (
    <>
      <Card
        bordered={false}
        className="cusomcard"
        style={{
          height: "125px",
          marginTop: "10px",
          // border: "2px solid #eef2fe",
          border: "1px solid #E4E8F0",
          boxShadow: "none",
          // boxShadow: "rgb(150, 161, 200,30%) 0px 0px 10px 0px",
          // borderRadius: "15px",
          borderRadius: "5px",
        }}
      >
        <Row className="Resp-Icons" style={{ marginLeft: "22px" }}>
          <Col lg={5} xl={6}>
            <div
              className="Resp-Img"
              style={{
                // backgroundColor: "#f2f4ff",
                display: "flex",
                justifyContent: "center",
                width: "50px",
                marginTop: "17px",
                marginLeft: "-10px",
                borderRadius: "6px",
              }}
            >
              {option}
            </div>
          </Col>
          <Col lg={19} xl={18}>
            <Row style={{ marginTop: "20px", marginLeft: "18px" }}>
              <Col lg={24} xl={24}>
                <Typography.Title
                  level={4}
                  style={{
                    fontSize: "20px",
                    fontFamily: "'Open Sans','sans-serif'",
                    fontWeight: 500,
                    color: "#444653",
                  }}
                >
                  {amount?.length > 11 ? (
                    <Tooltip
                      title={<span className="Resp-AmtFont">₹ {amount}</span>}
                    >
                      ₹ {amount?.toString()?.substring(0, 6)}...
                    </Tooltip>
                  ) : pageName === "SalesOrder" ? (
                    <span className="Resp-AmtFont"> {amount}</span>
                  ) : (
                    <span className="Resp-AmtFont">₹ {amount}</span>
                  )}
                </Typography.Title>
                <Typography.Title
                  level={5}
                  style={{
                    // marginTop: "25px",
                    fontSize: "13px",
                    marginTop: "-12px",
                    // color: "#395cd2",
                    color: "#8B99A7",
                  }}
                >
                  {" "}
                  <span className="Resp-Tital">{title}</span>
                </Typography.Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

interface IPropCardDivider {
  title: string;
  amount: any | number;
  paymentstatus?: string;
  paymentstatus2?: string;
  option?: any;
  pageName?: string;
}
export const CardDivider = ({
  title,
  amount,
  option,
  pageName,
}: // paymentstatus,
// paymentstatus2,
// option,
IPropCardDivider) => {
  return (
    <>
      <Card
        bordered={false}
        className="cusomcard"
        style={{
          marginTop: "10px",
          // border: "2px solid #eef2fe",
          border: "1px solid #E4E8F0",
          boxShadow: "none",
          // boxShadow: "rgb(150, 161, 200,30%) 0px 0px 10px 0px",
          height: "125px",
          // borderRadius: "15px",
          borderRadius: "5px",
        }}
      >
        <Row className="Resp-Icons" style={{ marginLeft: "22px" }}>
          <Col xl={6} lg={5}>
            <div
              className="Resp-Img"
              style={{
                // backgroundColor: "#f2f4ff",
                // border: "1px solid #f5f8ff",
                display: "flex",
                justifyContent: "center",
                width: "50px",
                marginTop: "17px",
                marginLeft: "-10px",
                borderRadius: "6px",
              }}
            >
              {option}
            </div>
          </Col>
          <Col xl={18} lg={19}>
            <Row style={{ marginTop: "20px", marginLeft: "16px" }}>
              <Col lg={24}>
                <Typography.Title
                  level={4}
                  style={{
                    // color: "rgb(11, 48, 142)",
                    fontSize: "20px",
                    fontFamily: "'Open Sans','sans-serif'",
                    fontWeight: 500,
                    color: "#444653",
                  }}
                >
                  {amount?.length > 11 ? (
                    <Tooltip
                      title={<span className="Resp-AmtFont">₹ {amount}</span>}
                    >
                      ₹ {amount?.toString()?.substring(0, 6)}...
                    </Tooltip>
                  ) : pageName === "SalesOrder" ? (
                    <span className="Resp-AmtFont"> {amount}</span>
                  ) : (
                    <span className="Resp-AmtFont">₹ {amount}</span>
                  )}
                </Typography.Title>

                <Typography.Title
                  level={5}
                  style={{
                    fontSize: "13px",
                    marginTop: "-12px",
                    // color: "#395cd2",
                    color: "#8B99A7",
                  }}
                >
                  <span className="Resp-Tital">{title}</span>
                </Typography.Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

interface IPropSalesCard {
  title: string;
  amount: any | number;
  paymentstatus?: string;
  paymentstatus2?: string;
  option: any;
  pageName?: string;
}
export const SalesCard = ({
  title,
  amount,
  paymentstatus,
  paymentstatus2,
  option,
  pageName,
}: IPropSalesCard) => {
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <>
      <Card
        bordered={false}
        className="cusomcard"
        style={{
          marginTop: "10px",
          boxShadow: "none",
          // border: "2px solid #eef2fe",
          border: "1px solid #E4E8F0",
          // boxShadow: "rgb(150, 161, 200,30%) 0px 0px 10px 0px",
          height: "125px",
          // borderRadius: "15px",
          borderRadius: "5px",
        }}
      >
        <Row>
          <Col xl={6} lg={2} md={5} xs={7}>
            <EChartsReact
              className="EchartDisplay"
              option={option}
              opts={{ renderer: "svg" }}
              style={{
                width: "120px",
                height: "148px",
                marginTop: "-26px",
                position: "absolute",
              }}
            />
          </Col>
          <Col xl={18} lg={22} md={19} xs={17}>
            <Row
              className="TotalSalesGraph"
              style={{
                marginTop: "20px",
                marginLeft: width <= 768 ? "0px" : "56px",
                position: "absolute",
              }}
            >
              <Col lg={24}>
                <Typography.Title
                  level={4}
                  style={{
                    fontSize: "20px",
                    fontFamily: "'Open Sans','sans-serif'",
                    fontWeight: 500,
                    color: "#444653",
                  }}
                >
                  {amount?.length > 12 ? (
                    <Tooltip
                      title={<span className="Resp-AmtFont">₹ {amount}</span>}
                    >
                      {amount?.toString()?.substring(0, 6)}...
                    </Tooltip>
                  ) : pageName === "SalesOrder" ? (
                    <span className="Resp-AmtFont"> {amount}</span>
                  ) : (
                    <span className="Resp-AmtFont">₹ {amount}</span>
                  )}
                </Typography.Title>
                <Typography.Title
                  level={5}
                  style={{
                    fontSize: "13px",
                    marginTop: "-12px",
                    // color: "#395cd2",
                    color: "#8B99A7",
                  }}
                >
                  {" "}
                  {title}
                </Typography.Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};
