import {
  MinusCircleOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "../Assets/Assets.css";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Upload,
} from "antd";
import "../Sales/SalesStyle.css";
import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import phoneImg from "../../../images/phoneIcon.svg";
import emailImg from "../../../images/emailIcon.svg";
import { AddressFields, NameComponent } from "../../Common/CAPComponents";
import SalesService from "../../Services/SalesService";
import { useClient } from "../../Context/ClientContext";
import "../../Common/Acc_Common.css";
import TextArea from "antd/es/input/TextArea";
import FormItem from "antd/es/form/FormItem";
import ClientService from "../../Services/ClientService";
interface AddCustomerProps {
  openDrawer?: boolean;
  setOpenDrawer?: any;
  setTrigger?: any;
  notification?: any;
  cstmr?: any;
}
const AddCustomers = ({
  openDrawer,
  setOpenDrawer,
  setTrigger,
  notification,
  cstmr,
}: AddCustomerProps) => {
  const { currentClient } = useClient();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [errorForBName, setErrorForBName] = useState<boolean>(false);
  const [statesOption, setStatesOption] = useState<any[]>([]);
  const [openStockValue, setOpenStockValue] = useState<any>();
  const [atPriceValue, setAtPriceValue] = useState<any>();
  const [totalValue, setTotalValue] = useState<any>();

  useEffect(() => {
    if (openDrawer) {
      if (cstmr?.id !== null) {
        SalesService.getCustomerById(currentClient!.id, cstmr?.id).then(
          (res: any) => {
            if (res.result) {
              console.log("data", res.result);
              form.setFieldsValue({
                ...res?.result,
                // contactPerson: contactItem,
              });
            }
            // setContactItem({
            //   contactName: {
            //     first: res?.result?.contactPerson?.contactName?.first,
            //     last: res?.result?.contactPerson?.contactName?.last,
            //   },
            //   phone: res?.result?.contactPerson?.phone,
            //   email: res?.result?.contactPerson?.email,
            // });
          }
        );
      }
    }
  }, [openDrawer]);

  React.useEffect(() => {
    ClientService.getStateOption(false).then((res: any) => {
      setStatesOption(res?.result);
    });
    //console.log(statesOption);
  }, []);

  const onDismissConfirm = () => {
    setConfirmation(false);
    setOpenDrawer(true);
  };

  const onDismissBothModels = () => {
    setTouched(false);
    setConfirmation(false);
    setOpenDrawer(false);
    setErrorForBName(false);
    form.resetFields();
  };

  const onClose = () => {
    if (touched) {
      setConfirmation(true);
    } else {
      form.resetFields();
      setErrorForBName(false);
      setConfirmation(false);
      setOpenDrawer(false);
      setAtPriceValue(0);
      setOpenStockValue(0);
    }
  };

  const neitherBusinessName = (val: any) => {
    if (
      val.businessName === "" &&
      val.name.first === "" &&
      val.name.last === ""
    ) {
      console.log("empty");
      setErrorForBName(true);
      return true;
    } else {
      setErrorForBName(false);
      console.log("not empty");
      return false;
    }
  };

  const handleStockValue = () => {
    let openingstock = form.getFieldValue("openingstock");
    if (openingstock === "" || 0 || null) setTotalValue("");
    setOpenStockValue(() => openingstock);
    setTotalValue(openingstock * atPriceValue);
    form.setFieldValue("totalprice", openingstock * atPriceValue);
    //console.log("stock", openStockValue);
  };
  const handleAtPrice = () => {
    let atprice = form.getFieldValue("atprice");
    if (atprice === "" || 0 || null) setTotalValue("");
    setAtPriceValue(() => atprice);
    setTotalValue(openStockValue * atprice);
    form.setFieldValue("totalprice", openStockValue * atprice);
    //console.log("atprice", atPriceValue);
  };

  const addCustomers = () => {
    form.validateFields().then(async (val) => {
      // const error = neitherBusinessName(val);
      const customer = {
        ...val,
        // contactPerson: contactItem,
        type: 0,
      };
      console.log("data", customer);
      // if (!error) {
      await SalesService.postCustomer(currentClient?.id!, customer, cstmr?.id)
        .then((res) => {
          if (res?.status) {
            console.log("hello", errorForBName);
            notification(
              "success",
              `Customer details ${cstmr?.id ? "Updated" : "Saved"} Successfully`
            );
            form.resetFields();
            // setContactItem(initialContactPerson);
            setOpenDrawer(false);
            setTrigger((x: any) => !x);
          } else {
            notification("error", res?.message);
          }
        })
        .catch((ex) => {
          notification("error", ex);
        });
      // }
    });
  };
  return (
    <>
      <Drawer
        title="Add Party"
        open={openDrawer}
        closable={false}
        onClose={onClose}
        width={950}
        maskClosable={false}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: 20,
              marginRight: 20,
            }}
          >
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => addCustomers()} type="primary">
              Save
            </Button>
          </Space>
        }
        className="drawerTitle partydrawer"
      >
        <Form
          form={form}
          name="customers"
          requiredMark={false}
          colon={false}
          initialValues={{
            openingstock: 0,
            atprice: 0,
            totalprice: 0,
            ContactPerson: [{}],
          }}
          scrollToFirstError
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={15}>
            <Col lg={7}>
              <Tooltip
                placement="bottom"
                color="#fff"
                title={
                  <p className="grayColor fs-12">
                    Enter the name of the business to be reflected on the
                    invoice. (Eg. company's name, partnership firm's name, sole
                    trader business' name, individual's name, etc).
                  </p>
                }
              >
                <Form.Item
                  name="businessName"
                  className="inputBoxMb8 inputBoxMb inputLabel33"
                  label="Name"
                >
                  <Input
                    onChange={(e) => {
                      if (e.target.value) setTouched(true);
                      else setTouched(false);
                      neitherBusinessName(form.getFieldsValue(true));
                    }}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col lg={7}>
              <Form.Item
                name="Gstin"
                className="inputBoxMb8 inputBoxMb inputLabel33"
                label="GSTIN"
              >
                <Input
                  onChange={(e) => {
                    if (e.target.value) setTouched(true);
                    else setTouched(false);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <table style={{ width: "84%" }}>
            <thead>
              <tr>
                <th style={{ width: "24%" }} className="textStart fw-500 ">
                  Contact Person
                </th>
                <th style={{ width: "29%" }} className="textStart px-12 fw-500">
                  Phone No.
                </th>
                <th style={{ width: "28%" }} className="textStart fw-500">
                  Email
                </th>
                <th style={{ width: "3%" }}></th>
              </tr>
            </thead>
            <Form.List name="ContactPerson">
              {(fields, { add, remove }) => (
                <>
                  <tbody>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <>
                        <tr>
                          <td>
                            <Form.Item
                              name={[name, "name"]}
                              {...restField}
                              className="inputBoxMb8"
                            >
                              <NameComponent
                                value={form.getFieldValue([
                                  "ContactPerson",
                                  "name",
                                ])}
                                FormItemName={[name, "name"]}
                                innerLabel={false}
                                // onChangeInput={() =>
                                //   neitherBusinessName(form.getFieldsValue(true))
                                // }
                              />
                              {/* <Input /> */}
                            </Form.Item>
                          </td>
                          <td className="px-12">
                            <Form.Item
                              name={[name, "phone"]}
                              {...restField}
                              className="inputBoxMb8"
                            >
                              <Input
                                placeholder="0123456789"
                                suffix={<img src={phoneImg} alt="phone"></img>}
                                addonBefore={
                                  <Form.Item
                                    name={[name, "prefix"]}
                                    {...restField}
                                    noStyle
                                  >
                                    <Select
                                      style={{ width: 67 }}
                                      options={[
                                        { value: "0", label: "+81" },
                                        { value: "1", label: "+91" },
                                      ]}
                                    />
                                  </Form.Item>
                                }
                                maxLength={10}
                                onKeyPress={(e) =>
                                  !/[0-9]/.test(e.key) && e.preventDefault()
                                }
                                onChange={(e) => {
                                  if (e.target.value) setTouched(true);
                                  else setTouched(false);
                                }}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name={[name, "email"]}
                              {...restField}
                              className="inputBoxMb8"
                              rules={[
                                {
                                  type: "email",
                                  message: "Enter valid email address",
                                },
                              ]}
                            >
                              <Input
                                placeholder="email@domail.com"
                                suffix={<img src={emailImg} alt="email"></img>}
                                onChange={(e) => {
                                  if (e.target.value) setTouched(true);
                                  else setTouched(false);
                                }}
                              />
                            </Form.Item>
                          </td>
                          <td>
                            {fields.length !== index + 1 && (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                  <Form.Item className="inputBoxMb">
                    <Row>
                      <Col>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          disabled={fields[2]?.name === 2 ? true : false}
                        >
                          Add Contact
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </table>
          <Divider className="my-10 grayBg" />
          <p style={{ marginBottom: "8px", fontWeight: 600 }}>
            GST & Address Details
          </p>
          <Row gutter={12}>
            <Col lg={7}>
              <Form.Item
                name="vatNumber"
                label="GST Type"
                className="inputBoxMb inputLabel33"
              >
                <Select
                  options={[
                    { value: "0", label: "Unregistered" },
                    { value: "1", label: "Regular" },
                    {
                      value: "2",
                      label: "Composition",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg={7}>
              <FormItem name="state" label="State" className="inputLabel33">
                <Select
                  showSearch
                  optionFilterProp="children"
                  options={statesOption.map((x: any) => ({
                    value: x.id,
                    label: x.name,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col lg={7}>
              <FormItem
                name="billingaddress"
                label="Billing Address"
                className="inputLabel33"
              >
                <TextArea rows={3} placeholder="" minLength={5} />
              </FormItem>
            </Col>
            <Col lg={7}>
              <FormItem
                name="shippingaddress"
                label="Shipping Address"
                className="inputLabel33"
              >
                <TextArea rows={3} placeholder="" minLength={5} />
              </FormItem>
            </Col>
          </Row>
          <Divider className="my-10 grayBg" />
          <p style={{ marginBottom: "8px", fontWeight: 600 }}>
            Additional Information
          </p>
          <Row gutter={12}>
            <Col lg={4}>
              <Form.Item
                name="openingstock"
                className="inputBoxMb8 inputBoxMb inputLabel33"
                label="Opening Stock"
                rules={[{ pattern: /^[0-9]*$/, message: "Enter only Number!" }]}
              >
                <Input onChange={handleStockValue} />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item
                name="atprice"
                className="inputBoxMb8 inputBoxMb inputLabel33"
                label="At Price"
                rules={[{ pattern: /^[0-9]*$/, message: "Enter only Number!" }]}
              >
                <Input onChange={handleAtPrice} />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item
                name="totalprice"
                className="inputBoxMb8 inputBoxMb inputLabel33"
                label="Total Value"
              >
                <Input value={totalValue} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col lg={4}>
              <Form.Item
                name="date"
                label="As Of Date"
                className="inputBoxMb8 inputBoxMb inputLabel33 pt-5 "
              >
                <DatePicker allowClear={false} format={"DD-MM-YYYY"} />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                name="notes"
                label="Notes"
                className="inputBoxMb8 inputBoxMb inputLabel33 pt-5 "
              >
                <Input
                  onChange={(e) => {
                    if (e.target.value) setTouched(true);
                    else setTouched(false);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={1} style={{ paddingTop: "27px" }}>
              <Upload
                beforeUpload={() => {
                  return false;
                }}
                className="imgpreview"
              >
                <Button className="imgupload ">
                  <PaperClipOutlined />
                </Button>
              </Upload>
            </Col>
          </Row>
          {fileList.map((x: any) => {
            return <Row>{x?.name}</Row>;
          })}

          {confirmation && (
            <ConfirmationModal
              open={confirmation}
              text="All details filled will be lost, are you sure you want to continue ?"
              onNo={onDismissConfirm}
              onYes={onDismissBothModels}
            />
          )}
        </Form>
      </Drawer>
    </>
  );
};

export default AddCustomers;
