import { CustomerServiceOutlined, HolderOutlined } from "@ant-design/icons";
import { FloatButton, Tag } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
// import "./Dashboardlayout.css";
const FloatButtons = ({ setQuickAddProps, clientId, images }: any) => {
  const navigate = useNavigate();
  return (
    <div>
      <FloatButton.Group
        className="ca-float-btn"
        trigger="click"
        type="primary"
        style={{ insetInlineEnd: "12px", bottom: "12px" }}
        icon={<HolderOutlined />}
      >
        <FloatButton
          className="ca-float-btn"
          icon={
            <>
              <img
                alt="masters"
                src={images?.masters}
                style={{
                  height: "21px",
                  width: "18px",
                }}
              />
            </>
          }
          tooltip={
            <>
              Add Ledger
              <br />
              <Tag>
                <kbd>Ctrl + Shift + L</kbd>
              </Tag>
            </>
          }
          // tooltip="Add Ledger / ctrl+shift+l"
          onClick={() => {
            navigate(`/accounts/clients/${clientId}/master/ledger`);
            setQuickAddProps({
              type: "AddLedger",
              open: true,
            });
          }}
        />
        <FloatButton
          icon={
            <>
              <img
                alt="sales"
                src={images?.saleslogo}
                style={{
                  height: "21px",
                  width: "18px",
                }}
              />
            </>
          }
          onClick={() => {
            navigate(`/accounts/clients/${clientId}/sales/addInvoice`);
          }}
          tooltip={
            <>
              Add Invoice
              <br />
              <Tag>
                <kbd>Shift + F5</kbd>
              </Tag>
            </>
          }
          // tooltip="Add Invoice / shift+f5"
        />
        <FloatButton
          icon={
            <>
              <img
                alt="purchaselogo"
                src={images?.purchaselogo}
                style={{
                  height: "21px",
                  width: "18px",
                }}
              />
            </>
          }
          onClick={() => {
            navigate(`/accounts/clients/${clientId}/purchase/addPurchaseBill`);
          }}
          tooltip={
            <>
              Add Purchase Bill
              <br />
              <Tag>
                <kbd>Shift + F9</kbd>
              </Tag>
            </>
          }
          // tooltip="Add PurchaseBill / shift+f9"
        />
        {/* <FloatButton
          icon={
            <>
              <img
                alt="AssetImg"
                src={images?.AssetImg}
                style={{
                  height: "21px",
                  width: "18px",
                }}
              />
            </>
          }
          onClick={() => {
            navigate(`/accounts/clients/${clientId}/asset/addFixedAsset`);
          }}
          tooltip="Add FixedAsset "
        /> */}
        <FloatButton
          icon={
            <>
              <img
                alt="journals"
                src={images?.journals}
                style={{
                  height: "21px",
                  width: "18px",
                }}
              />
            </>
          }
          onClick={() => {
            navigate(`/accounts/clients/${clientId}/journals/add`);
          }}
          tooltip={
            <>
              Add Journal
              <br />
              <Tag>
                <p>Shift + F3</p>
              </Tag>
            </>
          }
          // tooltip="Add Journals / shift+f3"
        />
        <FloatButton
          icon={
            <>
              <img
                alt="bankImg"
                src={images?.bankImg}
                style={{
                  height: "21px",
                  width: "18px",
                }}
              />
            </>
          }
          onClick={() => {
            navigate(`/accounts/clients/${clientId}/banking/bankAccounts`);
            setQuickAddProps({
              type: "AdjustBankBalanceModel",
              open: true,
            });
          }}
          tooltip={
            <>
              Add Transfer Entry
              <br />
              <Tag>
                <kbd>Shift + F4</kbd>
              </Tag>
            </>
          }
          // tooltip="AdjustBankBalance / shift+f4"
        />
      </FloatButton.Group>
    </div>
  );
};

export default FloatButtons;
