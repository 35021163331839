import React, { useState, useEffect } from "react";
import { G2, Pie } from "@ant-design/plots";
import EChartsReact from "echarts-for-react";
import { Typography } from "antd";
import { dayjs } from "../../../Utilities/dayjs";
export const IncomeExpensesGraphs = (props: any) => {
  const G = G2.getEngine("canvas");
  const data: any = [
    {
      type: "Expenses",
      // value : 100,
      total: props.value
        ? props.cashOrAccrual === "Accrual"
          ? props.value?.expenseDue + props.value?.incomeDue
          : props.cashOrAccrual === "Cash"
          ? props.value?.expenseReceived + props.value?.incomeReceived
          : 0
        : 0,
      // total: props.value?.expenseDue + props.value?.incomeDue,
      value: props.value
        ? props.cashOrAccrual === "Accrual"
          ? props.value?.expenseDue
          : props.cashOrAccrual === "Cash"
          ? props.value?.expenseReceived
          : 0
        : 0,
      backgroundColor: "red",
    },
    {
      type: "Income",
      // value : 60
      total: props.value
        ? props.cashOrAccrual === "Accrual"
          ? props.value?.expenseDue + props.value?.incomeDue
          : props.cashOrAccrual === "Cash"
          ? props.value?.expenseReceived + props.value?.incomeReceived
          : 0
        : 0,
      //  total: props.value?.expenseDue + props.value?.incomeDue,
      value: props.value
        ? props.cashOrAccrual === "Accrual"
          ? props.value?.incomeDue
          : props.cashOrAccrual === "Cash"
          ? props.value?.incomeReceived
          : 0
        : 0,
      // props.value?.incomeDue,
    },
  ];

  const cfg: any = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.95,
    color: ["orange", "#1677ff"],
    legend: false,
    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data: any, mappingData: any) => {
        const group = new G.Group({});
        group.addShape({
          type: "circle",
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 30,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 10,
            y: 8,
            text: `${data.type}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 25,
            text: `${data.value} (${
              data.total
                ? Math.round(((data.value * 100) / data.total) * 100) / 100
                : 0
            }%)`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const config = cfg;
  return (
    <>
      {" "}
      <Pie style={{ height: "185px" }} {...config} />
    </>
  );
};

export const SalesBarChart = ({ data, hideDasBackground }: any) => {
  const initialMonths = [
    `Aug ${dayjs().format("YY")}`,
    `Sep ${dayjs().format("YY")}`,
    `Oct ${dayjs().format("YY")}`,
    `Nov ${dayjs().format("YY")}`,
    `Dec ${dayjs().format("YY")}`,
    `Jan ${dayjs().add(1, "year").format("YY")}`,
    `Feb ${dayjs().add(1, "year").format("YY")}`,
    `Mar ${dayjs().add(1, "year").format("YY")}`,
    `Apr ${dayjs().add(1, "year").format("YY")}`,
    `May ${dayjs().add(1, "year").format("YY")}`,
    `Jun ${dayjs().add(1, "year").format("YY")}`,
    `jul ${dayjs().add(1, "year").format("YY")}`,
  ];

  const option: any = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: ["Sales", "Purchase"],
    },
    xAxis: [
      {
        type: "category",
        data:
          !data?.salesSummary || data?.salesSummary?.graphData?.length === 0
            ? initialMonths?.map((i) => i)
            : data?.salesSummary?.graphData?.map((s: any) => s.label),
        axisPointer: {
          type: "shadow",
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "Purchase",
        // min: 0,
        // max: 2500,
        // interval: 500,
        // axisLabel: {
        //   formatter: "{value} Rs.",
        // },
      },
    ],
    series: [
      {
        name: "Sales",
        type: "bar",
        tooltip: {
          valueFormatter: function (value: any) {
            return value + " Rs.";
          },
        },
        data: hideDasBackground
          ? Array(data?.salesSummary?.graphData?.length || 12).fill("****")
          : !data?.salesSummary || data?.salesSummary?.graphData?.length === 0
          ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          : data?.salesSummary?.graphData?.map((s: any) =>
              s.value?.sales ? Math.abs(s.value.sales) : 0
            ),
      },
      {
        name: "Purchase",
        type: "bar",
        tooltip: {
          valueFormatter: function (value: any) {
            return value + " Rs.";
          },
        },
        data: hideDasBackground
          ? Array(data?.salesSummary?.graphData?.length || 12).fill("****")
          : !data?.salesSummary || data?.salesSummary?.graphData?.length === 0
          ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          : data?.salesSummary?.graphData?.map((s: any) =>
              s.value?.purchase ? Math.abs(s.value.purchase) : 0
            ),
      },
    ],
  };
  return (
    <div className="chartStyle">
      <div>
        <Typography.Title
          level={4}
          style={{
            marginTop: 16,
            // fontFamily: "REM,sans-serif",
            fontWeight: 500,
            // color: "rgb(68, 84, 111)",
          }}
        >
          Total Sales / Purchase
        </Typography.Title>
      </div>
      <div>
        <EChartsReact
          // className="EchartDisplay"
          option={option}
          opts={{ renderer: "svg" }}
          style={{
            marginTop: "-25px",
            height: "380px",
            padding: 0,
            width: "100%",
          }}
        />
      </div>
    </div>
  );
};
