import { MenuProps } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";

export const VoucherEnum = () => {
  const { currentClient, companySettings } = useClient();

  return <div></div>;
};

export const SalesDropdownItems: any = () => {
  const { currentClient, companySettings } = useClient();
  return [
    {
      label: (
        <Link to={`/accounts/clients/${currentClient?.id}/sales/addInvoice`}>
          Sales Invoice
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={`/accounts/clients/${currentClient?.id}/sales/addCreditNote`}>
          Credit Notes
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link to={`/accounts/clients/${currentClient?.id}/sales/addReceipt`}>
          Receipt
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link to={`/accounts/clients/${currentClient?.id}/sales/addQuotation`}>
          Quotation{" "}
        </Link>
      ),
      key: "3",
    },
    {
      label: (
        <Link to={`/accounts/clients/${currentClient?.id}/sales/addOrder`}>
          Sales Order
        </Link>
      ),
      key: "4",
    },
  ];
};

export const PurchaseDropdownItems: any = () => {
  const { currentClient, companySettings } = useClient();
  return [
    {
      label: (
        <Link
          to={`/accounts/clients/${currentClient?.id}/purchase/addPurchaseBill`}
        >
          Purchase Invoice
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link
          to={`/accounts/clients/${currentClient?.id}/purchase/addDebitNote`}
        >
          Debit Notes
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link
          to={`/accounts/clients/${currentClient?.id}/purchase/addPaymentOut`}
        >
          Payments
        </Link>
      ),
      key: "2",
    },

    {
      label: (
        <Link to={`/accounts/clients/${currentClient?.id}/purchase/addOrder`}>
          Purchase Order
        </Link>
      ),
      key: "3",
    },
  ];
};
