import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from "antd";
import { dayjs } from "../../../Utilities/dayjs";
import noteContext from "../../Context/StyleContext";
import deleteImg from "../../../images/deletebutton.svg";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import "../../Common/Acc_Common.css";
import "../../Common/AddPageCommon.css";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import CategoryService, { CategoryType } from "../../Services/CategoryService";
import { Utils } from "../../../Utilities/Utils";
import AddCategoryModel from "./AddCategoryModel";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import ExpensesAndIncomeService, {
  ExpensesIncomeTypes,
} from "../../Services/ExpensesAndIncomeService";
import { notificationContext } from "../../Common/PageRoute";
import { useIndianState } from "../../Context/StateOptionsContext";
import { percentCal, percentToRs } from "../../Common/CAPComponents";
import SalesService, { TradingPartnerType } from "../../Services/SalesService";
import AddParty from "../Parties/AddParty";
import { InvoicePaymentMethodType } from "./AddIncome";
import { ExpensesSkeleton } from "../../../Common/Skeleton";

const AddExpensesInvoice = () => {
  let { id } = useParams();
  const [form] = Form.useForm();
  const { openNotification } = useContext(notificationContext);
  const { windowWidth } = useContext(noteContext);
  const [deleteItemList, setDeleteItemList] = useState<any>();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trigger, setTrigger] = useState(false);
  const [totalAmount, setTotalAmount] = useState<number>(0.0);
  const [balance, setBalance] = useState<number>(0);
  const { currentClient, companySettings } = useClient();
  const { expensesList, setTotalDue, setStatus } = useClient();
  const [newItem, setNewItem] = useState<{
    id: string;
    name: string;
    isSaved: boolean;
  }>({
    id: "",
    name: "",
    isSaved: false,
  });
  const [itemFields, setItemFields] = useState<any>({
    field: 0,
    index: 0,
    add: null,
  });
  const navigate = useNavigate();
  const [totalQty, setTotalQty] = useState<number>(0);
  const [itemSubTotal, setItemSubTotal] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalgst, setTotalgst] = useState<number>(0);
  const [isGstEnabled, setIsGstEnabled] = React.useState<boolean>();
  const [totalUnitPrice, setTotalUnitPrice] = useState<number>(0);
  const [invoiceTotal, setInvoiceTotal] = useState<number>(0);
  const [itemLength, setItemLength] = useState<number>(0);
  const [gstCheckbox, setGstCheckbox] = React.useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = React.useState<
    InvoicePaymentMethodType | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [newCategory, setNewCategory] = useState<any>({
  //   data: {},
  //   isSaved: false,
  // });
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [saveNewLoading, setSaveNewLoading] = useState<boolean>(false);
  const [sameStateOption, setSameStateOption] = useState<boolean>(false);
  const { stateOptions } = useIndianState();
  const [partyOptions, setPartyOptions] = useState<any[]>([]);
  const [newParty, setNewParty] = useState<any>();
  const [openPartyDrawer, setOpenPartyDrawer] = useState<boolean>(false);
  const [editParty, setEditParty] = useState<{ id: any }>({ id: null });
  //console.log(paymentMethod, "again check");
  const isStateSame = currentClient?.registeredAddress?.state!;
  console.log("statechek", isStateSame);
  const amountInNumber = (val: any, key: any) =>
    Utils.getNumber(val?.amount?.[key]);
  const price = (curr: any) => amountInNumber(curr, "price");
  const qty = (curr: any) => amountInNumber(curr, "qty");
  const discount = (curr: any) => amountInNumber(curr, "discount");
  const taxRate = (curr: any) => Utils.getNumber(curr?.amount?.tax?.value);
  const netAmount = (curr: any) => price(curr) * qty(curr);
  const discountRupee = (curr: any) =>
    percentCal(netAmount(curr), discount(curr));
  const amountWithoutTax = (curr: any) => netAmount(curr) - discountRupee(curr);
  const taxRs = (curr: any) =>
    percentCal(amountWithoutTax(curr), taxRate(curr));
  const amountWithTax = (curr: any) => amountWithoutTax(curr) + taxRs(curr);
  const [searchParty, setSearchParty] = useState<{
    search: string;
    length: number;
  }>({
    search: " ",
    length: 15,
  });
  const [initialRender, seInitialRender] = useState<boolean>(false);

  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const returnNetAmount = (i: number, items: any[]) => {
    let netAmount = items && items[i]?.amount?.price * items[i]?.amount?.qty;
    return netAmount;
  };

  //----  Initial Value Invoice Get Api-----
  useEffect(() => {
    setLoading(true);
    ExpensesAndIncomeService.initialInvoice(
      currentClient?.id!,
      ExpensesIncomeTypes.Expenses
    ).then((res) => {
      setIsGstEnabled(res?.result?.isGstEnabled);
      id ?? form.setFieldValue("number", res?.result?.number);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, initialRender]);

  //for a category search
  useEffect(() => {
    CategoryService.searchCategory(
      currentClient?.id!,
      CategoryType.Expances,
      10,
      searchCategory
    )
      .then((res: any) => {
        if (res.result) {
          console.log(res.result, "result==============");
          setCategoryOptions(res?.result);
          // console.log(searchCategory, "data");
        }
      })
      .catch((ex: any) => {
        console.error(ex);
      });
  }, [currentClient?.id, searchCategory, newItem]);
  //console.log("expenses Category", categoryOptions);

  //------- Search Party APi------
  useEffect(() => {
    SalesService.searchParties(
      currentClient?.id!,
      searchParty.search,
      TradingPartnerType.All,
      searchParty.length
    ).then((res: any) => {
      setPartyOptions(res?.result);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, searchParty.search, newParty]);

  //------- Adding new Party ------
  useEffect(() => {
    if (newParty) {
      form.setFieldValue("tradingPartner", {
        value: newParty?.id,
        label: newParty?.businessName,
      });
      form.setFieldValue("supplyState", newParty?.state);
      setEditParty({ id: newParty?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newParty]);

  //------- Adding new category ------
  useEffect(() => {
    if (newItem?.isSaved && newItem?.id) {
      // Set the new item's data into the form field
      const newItemFields = {
        id: newItem.id,
        name: newItem.name,
        amount: {
          qty: 1,
          price: Utils.getFormattedNumber(0.0),
          net: 1,
        },
      };

      // Update the current item at index itemFields.index
      setItemLength(itemFields.index + 1);
      form.setFieldValue(["items", itemFields.index], newItemFields);

      // If the current item index is at the end, add a new item with initial values
      if (itemFields.field.length === itemFields.index + 1) {
        form.setFieldValue(["items", itemFields.index + 1], {
          amount: {
            price: Utils.getFormattedNumber(0.0),
            qty: Utils.getFormattedNumber(0.0),
            totalAmount: Utils.getFormattedNumber(0.0),
          },
        });
      }
      // Prepare the new item data to be added to itemsOption
      const newCategoryData = {
        id: newItem.id,
        name: newItem.name,
      };

      // Add the new item data to itemsOption
      setCategoryOptions([...categoryOptions, newCategoryData]);

      // Call the function to handle the total values
      handleItemsNetAmmount(itemFields.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItem.id, newItem.isSaved]);

  //console.log(newItem, "When add a new expenses");

  const handleDeleteRowButton = () => {
    handleItemsNetAmmount(0);
    onItemsTotalAmountChange(0);
    setConfirmation(false);
  };
  // const handelTwoDigitAfterDecimal = (e: any) => {
  //   if (!/\d|\./.test(e.key)) e.preventDefault();
  //   if (e.key === "." && e.target.value && e.target.value.includes("."))
  //     e.preventDefault();
  // };
  const PartySelect: any = async (value: any) => {
    const selectedParty = partyOptions.find(
      (ItemList: any) => ItemList.id === value.key
    );
    if (selectedParty) {
      setSameStateOption(isStateSame === selectedParty?.state);
      setEditParty({ id: selectedParty?.id });
      form.setFieldValue("supplyState", selectedParty?.state);
      setSameStateOption(isStateSame === selectedParty?.state);
    }
  };
  // console.log("partyoption", partyOptions);

  const ItemSelect: any = async (value: any, index: number) => {
    setItemLength(index + 1);
    // console.log(value, index, "indexvalues", value?.key, value?.label);

    //const selectedItem = categoryOptions?.find((Item: any) => Item?.id === value);
    //console.log(selectedItem, "selectedItem============================");

    if (value) {
      form.setFieldValue(["items", index], {  
        name: {
          value: value?.key,
          label: value?.label,
        },
        amount: {
          qty: Utils.getFormattedNumber(1),
          price: Utils.getFormattedNumber(0),
          // taxRate: selectedItem.taxRate.rate,
          net: 1,
        },
      });
      handleItemsNetAmmount(index);
    }
  };

  // ------- Get withID (Edit) Api ExpensesInvoice --------
  useEffect(() => {
    if (id) {
      setLoading(true);
      ExpensesAndIncomeService.getExpensesInvoice(currentClient?.id!, id)
        .then((res: any) => {
          setLoading(true);
          if (res?.result) {
            // console.log(res?.result, "byiddata");
            setIsGstEnabled(res?.result?.isGstEnabled);
            setGstCheckbox(res?.result?.isGst);
            setSameStateOption(isStateSame === res?.result?.supplyState);
            res?.result?.amountSummary?.igst
              ? setTotalgst(res?.result?.amountSummary?.igst)
              : setTotalgst(
                  res?.result?.amountSummary?.sgst +
                    res?.result?.amountSummary?.cgst
                );

            res?.result?.amountSummary?.totalQty &&
              setTotalQty(res?.result?.amountSummary?.totalQty);

            res?.result?.amountSummary?.totalPrice &&
              setTotalUnitPrice(res?.result?.amountSummary?.totalPrice);

            res?.result?.amountSummary?.sellPrice &&
              setItemSubTotal(res?.result?.amountSummary?.sellPrice);

            res?.result?.amountSummary?.totalDis &&
              setTotalDiscount(res?.result?.amountSummary?.totalDis);

            // res.result?.amountSummary?.receivedAmount &&
            //   setBalance(res.result.amountSummary?.receivedAmount);

            res.result.amountSummary?.totalInvoiceAmount &&
              setInvoiceTotal(res.result.amountSummary?.totalInvoiceAmount);
            setTotalAmount(res.result.amountSummary?.totalInvoiceAmount);
            // console.log(res.result.items.length);
            setItemLength(res.result.items.length + 1);
            setEditParty({ id: res.result.tradingPartner?.id });
            form.setFieldsValue({
              ...res?.result,
              items: [
                ...res.result.items.map((itm: any) => {
                  return {
                    ...itm,
                    amount: {
                      ...itm?.amount,
                      tax: {
                        value: itm?.amount?.tax?.id,
                        label: itm?.amount?.tax?.name,
                        key: itm?.amount?.tax?.name,
                        title: itm?.amount?.tax?.rate,
                      },
                    },
                    name: {
                      value: itm?.id,
                      label: itm?.name,
                    },
                  };
                }),

                [{ amount: { price: 0.0, qty: 0 }, isDiscInPer: 0 }],
              ],
              tradingPartner: {
                value: res?.result?.tradingPartner?.id,
                label: res?.result?.tradingPartner?.name,
              },
              date: dayjs(
                dayjs(res.result.date).format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              ),
              paymentType: res?.result?.paymentType === 0 ? "Credit" : "Cash",
            });

            setLoading(false);
            console.log(res?.result, "getExpensesInvoice");
          }
        })
        .catch((err: any) => {
          openNotification("error", err);
          setLoading(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // ------- Post & Edit Api Exp.Invoice --------//
  const onSubmit = (isSaveAndNew: boolean) => {
    form.validateFields().then(async (values) => {
      isSaveAndNew ? setSaveNewLoading(true) : setSaveButtonLoading(true);
      // console.log(values, "values------------");
      const invoice = {
        ...values,
        tradingPartner: {
          id: values.tradingPartner?.value,
          name: values.tradingPartner?.label,
        },
        items: values.items.map((x: any) => {
          const data = {
            ...x,
            id: x.name?.value,
            name: x.name?.label,
            amount: {
              ...x.amount,
              tax: gstCheckbox
                ? {
                    id: x?.amount?.tax?.value,
                    name: x?.amount?.tax?.key,
                    rate: x.amount?.tax?.title,
                  }
                : null,
              taxRs: gstCheckbox
                ? Utils.getFormattedNumber(x?.amount?.taxRs)
                : null,
              discount: gstCheckbox ? x.amount?.discount : null,
              discountRupee: gstCheckbox ? x?.amount?.discountRupee : null,
            },
          };
          return data;
        }),
        date: dayjs(values?.date).format("DD/MM/YYYY"),
        isGst: gstCheckbox,
        sellPrice: Utils.getFormattedNumber(itemSubTotal),
        totalDis: Utils.getFormattedNumber(totalDiscount),
        igst: gstCheckbox && !isStateSame && Utils.getFormattedNumber(totalgst),
        cgst:
          gstCheckbox && isStateSame && Utils.getFormattedNumber(totalgst / 2),
        sgst:
          gstCheckbox && isStateSame && Utils.getFormattedNumber(totalgst / 2),
        totalInvoiceAmount: Utils.getFormattedNumber(invoiceTotal),
        // EITradeType
        eiType: ExpensesIncomeTypes.Expenses,
      };
      // console.log("success check", invoice);

      await ExpensesAndIncomeService.PostExpensesInvoice(
        currentClient?.id!,
        invoice,
        id
      )
        .then((res) => {
          if (res?.status) {
            openNotification(
              "success",
              `${
                id
                  ? "Update Expenses Successfully                                                                                                                                                                                                           ully"
                  : "Save Expenses Successfully"
              }`
            );
            !isSaveAndNew
              ? navigate(`/accounts/clients/${currentClient?.id}/expenses`)
              : setTotalQty(0);
            setTotalUnitPrice(0);
            setItemSubTotal(0);
            setTotalDiscount(0);
            setTotalgst(0);
            setTotalAmount(0);
            form.resetFields();
            const x: number =
              expensesList?.reduce((acc: any, curr: any) => {
                return (acc += Number(curr?.dueBalance));
              }, 0) || 0;
            setTotalDue(x);
            setStatus("");
            // console.log("===================================", totalDue);
          } else if (res?.message) {
            // console.log(res.message, "message");
            openNotification("error", res?.message);
          }
          isSaveAndNew && seInitialRender(!initialRender);
          // console.log(res, "expensesdata---------------------");
        })
        .catch((err) => {
          // console.log(err, "errrr_______________");
          openNotification("error", err);
          setSaveButtonLoading(false);
        });
      setSaveNewLoading(false);
      setSaveButtonLoading(false);
    });
  };

  const onAmountReceived = () => {
    const amt = form.getFieldValue("receivedAmount");
    setBalance(amt);
    // console.log(amt);
  };

  const handleAllTheTotalValues = () => {
    const ItemList = form.getFieldValue(["items"]);

    // ----- Calculate items total Quantity -----
    const TotalQty = ItemList.reduce((acc: number, curr: any) => {
      return (acc += qty(curr));
    }, 0);
    setTotalQty(TotalQty);

    // ----- Calculate items net amount (WITHOUT GST AND DISCOUNT) -----
    const ItemSubTotal = ItemList.reduce((acc: number, curr: any) => {
      return (acc += price(curr) * qty(curr));
    }, 0);
    setItemSubTotal(ItemSubTotal);

    // ----- Calculate items total unit Price -----
    const TotalUnitPrice = ItemList.reduce((acc: number, curr: any) => {
      return (acc += price(curr));
    }, 0);
    setTotalUnitPrice(TotalUnitPrice);

    // ----- Calculate item's total discount -----
    const TotalDiscount = ItemList.reduce((acc: number, curr: any) => {
      return (acc += amountInNumber(curr, "discountRupee"));
    }, 0);
    setTotalDiscount(TotalDiscount);

    // ----- Calculate item's total amount with discount -----
    // const AmountAfterDisc = ItemList.reduce((acc: number, curr: any) => {
    //   return (acc += amountWithoutTax(curr));
    // }, 0);

    // ----- Calculate item's total GST -----
    const Totalgst = ItemList.reduce((acc: number, curr: any) => {
      // return (acc += taxRs(curr));
      return (acc += amountInNumber(curr, "taxRs"));
    }, 0);
    setTotalgst(Totalgst);

    // ----- Calculate total amount (WITH GST AND DISCOUNT) -----
    const TotalAmount = ItemList.reduce((acc: number, curr: any) => {
      // return (acc += amountWithTax(curr));
      return (acc += amountInNumber(curr, "totalAmount"));
    }, 0);
    setTotalAmount(TotalAmount);
  };

  const handleItemsNetAmmount = (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];
    // console.log(currentIndexData, "currentIndexData-------");
    if (currentIndexData) {
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          price: Utils.getFormattedNumber(price(currentIndexData)),
          discountRupee: Utils.getFormattedNumber(
            discountRupee(currentIndexData)
          ),
          taxRs: Utils.getFormattedNumber(taxRs(currentIndexData)),
          totalAmount: Utils.getFormattedNumber(
            amountWithTax(currentIndexData)
          ),
        },
      });
    }
    handleAllTheTotalValues();
  };

  const onDiscountAmountChange = (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    if (currentIndexData) {
      // Calculate the new values based on the discount change
      const newDiscountAmount = percentToRs(
        amountInNumber(currentIndexData, "discountRupee"),
        netAmount(currentIndexData)
      );
      const newTaxRs = percentCal(
        netAmount(currentIndexData) -
          amountInNumber(currentIndexData, "discountRupee"),
        taxRate(currentIndexData)
      );
      const newTotalAmount =
        netAmount(currentIndexData) -
        amountInNumber(currentIndexData, "discountRupee") +
        newTaxRs;

      // Update the form field with the new calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData.amount,
          discount: Utils.getFormattedNumber(newDiscountAmount),
          taxRs: Utils.getFormattedNumber(newTaxRs),
          totalAmount: Utils.getFormattedNumber(newTotalAmount),
        },
      });

      // Call the function to handle the total values if needed
      handleAllTheTotalValues();
    }
  };

  const onItemsTotalAmountChange = async (index: number) => {
    const ItemList = form.getFieldValue(["items"]);
    const currentIndexData = ItemList[index];

    // Calculate the denominator for various calculations
    const denominator =
      100 -
      discount(currentIndexData) +
      percentCal(100 - discount(currentIndexData), taxRate(currentIndexData));

    // Calculate the price based on the given formula
    const calculatedPrice =
      (amountInNumber(currentIndexData, "totalAmount") * 100) /
      (denominator * qty(currentIndexData));

    // Calculate the discountRupee based on the given formula
    const calculatedDiscountRupee =
      (amountInNumber(currentIndexData, "totalAmount") *
        discount(currentIndexData)) /
      denominator;

    // Calculate the taxRs based on the given formula
    const calculatedTaxRs =
      (amountInNumber(currentIndexData, "totalAmount") *
        percentCal(
          100 - discount(currentIndexData),
          taxRate(currentIndexData)
        )) /
      denominator;

    if (denominator > 0 && currentIndexData?.amount?.qty > 0) {
      // Update the form field values with the calculated values
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: Utils.getFormattedNumber(calculatedPrice),
          discountRupee: Utils.getFormattedNumber(calculatedDiscountRupee),
          taxRs: Utils.getFormattedNumber(calculatedTaxRs),
        },
      });
    } else {
      // Denominator is zero or negative, set all fields to 0
      form.setFieldValue(["items", index], {
        ...currentIndexData,
        amount: {
          ...currentIndexData?.amount,
          price: 0,
          discountRupee: 0,
          taxRs: 0,
          totalAmount: 0,
        },
      });
    }
    handleAllTheTotalValues();
  };

  return (
    <div>
      {loading ? (
        <ExpensesSkeleton loading={loading} setLoading={setLoading} />
      ) : (
        <Form
          form={form}
          autoComplete="off"
          name="expenses"
          initialValues={{
            items: [
              {
                amount: { price: Utils.getInputNumberFormat(0.0), qty: 0 },
                isDiscInPer: 0,
              },
            ],
          }}
        >
          <Row justify="space-between">
            <Col xl={12} lg={18} md={24} className="dashedBoxStyle">
              <Row gutter={16}>
                <Col xl={9} lg={8} className="themeLightBgColor ml-10">
                  <Row justify={"space-between"}>
                    <Col lg={12}>
                      <span className="themeColor fw-500">Party</span>
                    </Col>
                    {editParty.id && (
                      <Col className="fw-500">
                        <Tooltip title={"View Party"}>
                          <EyeOutlined
                            onClick={() => {
                              setOpenPartyDrawer(true);
                            }}
                          />
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                  <Form.Item
                    name="tradingPartner"
                    className="m-0  selectedItem errormsg"
                    rules={[{ required: true, message: "Select Party" }]}
                  >
                    <Select
                      showSearch
                      labelInValue
                      optionFilterProp="children"
                      placeholder="Party Name"
                      filterOption={false}
                      className="selectedItem"
                      onSearch={(value: string) =>
                        setSearchParty({ search: value, length: 15 })
                      }
                      onClick={() =>
                        setSearchParty({ ...searchParty, search: "" })
                      }
                      options={partyOptions?.map((value: any) => ({
                        value: value?.id,
                        label: value?.name,
                      }))}
                      onSelect={(e: any) => {
                        PartySelect(e);
                        // console.log(editParty, "checkid");
                      }}
                      dropdownRender={(partyOptions) => (
                        <>
                          {partyOptions}
                          <Divider style={{ margin: "5px 0px" }} />
                          <Button
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            type="link"
                            onClick={() => {
                              setOpenPartyDrawer(true);
                              setEditParty({ id: null });
                            }}
                          >
                            New Party
                          </Button>
                        </>
                      )}
                    />
                  </Form.Item>
                  <span className="themeColor fw-500">Payment Method</span>
                  <Form.Item name="paymentType" className="inputBoxMb pb-5">
                    <Select
                      optionFilterProp="children"
                      placeholder="Payment Method"
                      onChange={(value: any) => {
                        setPaymentMethod(value);
                      }}
                      options={[
                        { value: InvoicePaymentMethodType.cash, label: "Cash" },
                        {
                          value: InvoicePaymentMethodType.credit,
                          label: "Credit",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4} xl={5}>
                  <span className="themeColor fw-500">Expenses No.</span>
                  <Form.Item name="number" className="m-0">
                    <Input disabled />
                  </Form.Item>
                  <span className="themeColor fw-500">Date</span>
                  <Form.Item name="date" className="m-0">
                    <DatePicker
                      allowClear={false}
                      format={"DD/MM/YYYY"}
                      defaultValue={dayjs()}
                      disabledDate={(current) =>
                        current && current > dayjs().endOf("day")
                      }
                    />
                  </Form.Item>
                </Col>
                <Col lg={7} xl={7} className="pt-19">
                  {isGstEnabled && (
                    <Form.Item
                      className="m-0"
                      name="isGst"
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) => {
                          setGstCheckbox(e.target.checked);
                          //console.log("chekde", e.target.checked);
                        }}
                      >
                        <span className="themeColor fw-500">Gst</span>
                      </Checkbox>
                    </Form.Item>
                  )}
                  {gstCheckbox && (
                    <>
                      <span className="themeColor fw-500">Place of Supply</span>
                      <Form.Item name="supplyState" className="m-0">
                        <Select
                          // style={{ width: "300px" }}
                          showSearch
                          placeholder="Select State"
                          popupMatchSelectWidth={false}
                          optionFilterProp="children"
                          options={stateOptions?.map((x: any) => ({
                            value: x.id,
                            label: x.name,
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(e: any) => {
                            setSameStateOption(isStateSame === e);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            {windowWidth <= 1024 ? (
              ""
            ) : (
              <Col
                xl={4}
                lg={7}
                md={7}
                className="fs-35 fw-400 themeColor textEnd"
              >
                Expenses
              </Col>
            )}
          </Row>
          <>
            <div style={{ overflowY: "auto" }}>
              <table
                className="AddPageTabelDesign"
                // style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
              >
                <thead>
                  <tr>
                    <th rowSpan={2} style={{ width: "2%" }}>
                      S.No
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        width: "11%",
                        borderLeft: "1px solid rgb(240, 245, 255)",
                      }}
                      className="textStart ps-6"
                    >
                      Expenses
                    </th>

                    <th
                      rowSpan={2}
                      style={{ width: "3%" }}
                      className="textStart ps-6"
                    >
                      Qty
                    </th>

                    <th
                      rowSpan={2}
                      style={{ width: "10%" }}
                      className="textEnd pe-6"
                    >
                      Price
                    </th>
                    {gstCheckbox ? (
                      <>
                        <th colSpan={2} style={{ width: "10%" }}>
                          Discount
                        </th>
                        <th colSpan={2} style={{ width: "10%" }}>
                          GST %
                        </th>
                      </>
                    ) : null}
                    <th
                      colSpan={2}
                      rowSpan={2}
                      style={{ width: "14%" }}
                      className="textEnd pe-32"
                    >
                      Total Amount
                    </th>
                  </tr>
                  {gstCheckbox ? (
                    <>
                      <tr>
                        <th style={{ width: "5%" }}>%</th>
                        <th style={{ width: "5%" }}>₹</th>
                        <th style={{ width: "6%" }}>%</th>
                        <th style={{ width: "4%" }}>₹</th>
                      </tr>
                    </>
                  ) : null}
                </thead>
                <Form.List name="items">
                  {(fields, { add, remove, move }) => {
                    return (
                      <>
                        {confirmation && (
                          <ConfirmationModal
                            open={confirmation}
                            text="Do you really want to delete this row?"
                            onNo={() => setConfirmation(false)}
                            onYes={() => (
                              // eslint-disable-next-line no-sequences
                              remove(deleteItemList),
                              handleDeleteRowButton(),
                              setItemLength(fields.length - 2)
                            )}
                          />
                        )}
                        <tbody>
                          {fields.map(({ key, name, ...restField }, index) => {
                            return (
                              <tr key={key}>
                                <td>
                                  {fields.length !== index + 1 && (
                                    <>
                                      <div className="ps-10">{index + 1}.</div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {fields.length !== index + 1 && (
                                    <>
                                      <div className="textCenter">
                                        {index + 1}.
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {/* name */}
                                  <Form.Item
                                    name={[name, "name"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Expenses Name"
                                      labelInValue
                                      optionFilterProp="children"
                                      filterOption={false}
                                      // showArrow={false}
                                      notFoundContent={"Select"}
                                      onSearch={(value: string) =>
                                        setSearchCategory(value)
                                      }
                                      onClick={() => {
                                        setSearchCategory("");
                                        // setNewItem({
                                        //   id: "",
                                        //   name: "",
                                        //   isSaved: false,
                                        // });
                                      }}
                                      onChange={(value: any) => {
                                        if (fields.length === index + 1) add();
                                        ItemSelect(value, index);
                                      }}
                                      options={categoryOptions?.map(
                                        (value: any) => ({
                                          value: value?.id,
                                          label: value?.name,
                                        })
                                      )}
                                      dropdownRender={(categoryOptions) => (
                                        <>
                                          {categoryOptions}
                                          <Divider
                                            style={{ margin: "5px 0px" }}
                                          />
                                          <Button
                                            style={{ width: "100%" }}
                                            icon={<PlusOutlined />}
                                            type="link"
                                            onClick={() => {
                                              setIsModalOpen(true);
                                              setItemFields({
                                                field: fields,
                                                index: index,
                                              });
                                              setNewItem({
                                                id: "",
                                                name: "",
                                                isSaved: false,
                                              });
                                            }}
                                          >
                                            New Expenses
                                          </Button>
                                        </>
                                      )}
                                    />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "amount", "qty"]}
                                  >
                                    <InputNumber
                                      min={0}
                                      controls={false}
                                      style={{ width: "100%" }}
                                      onChange={() =>
                                        handleItemsNetAmmount(index)
                                      }
                                      // formatter={(value: any) =>
                                      //   Utils.getInputNumberFormat(value)
                                      // }
                                      // onKeyPress={(e) =>
                                      //   handelTwoDigitAfterDecimal(e)
                                      // }
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "amount", "price"]}
                                  >
                                    <InputNumber
                                      min={0}
                                      style={{ width: "100%" }}
                                      controls={false}
                                      className="inputNumberTextEnd"
                                      // formatter={(value: any) =>
                                      //   Utils.getInputNumberFormat(value)
                                      // }
                                      // onKeyPress={(e) =>
                                      //   handelTwoDigitAfterDecimal(e)
                                      // }
                                      onChange={() =>
                                        handleItemsNetAmmount(index)
                                      }
                                    />
                                  </Form.Item>
                                </td>
                                {gstCheckbox ? (
                                  <>
                                    <td>
                                      <Form.Item
                                        className="m-0"
                                        {...restField}
                                        name={[name, "amount", "discount"]}
                                      >
                                        <InputNumber
                                          className="inputNumberTextEnd"
                                          controls={false}
                                          style={{ width: "100%" }}
                                          min={0}
                                          max={100}
                                          // formatter={(value: any) =>
                                          //   Utils.getInputNumberFormat(value)
                                          // }
                                          // onKeyPress={(e) =>
                                          //   handelTwoDigitAfterDecimal(e)
                                          // }
                                          onChange={() =>
                                            handleItemsNetAmmount(index)
                                          }
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        className="m-0"
                                        {...restField}
                                        name={[name, "amount", "discountRupee"]}
                                      >
                                        <InputNumber
                                          controls={false}
                                          className="inputNumberTextEnd"
                                          style={{ width: "100%" }}
                                          min={0}
                                          step={0.01}
                                          max={returnNetAmount(
                                            index,
                                            form.getFieldValue(["items"])
                                          )}
                                          // max={form.getFieldValue([
                                          //   "items",
                                          //   index,
                                          //   "amount",
                                          //   "price",
                                          // ])}
                                          // formatter={(value: any) =>
                                          //   Utils.getInputNumberFormat(value)
                                          // }
                                          // onKeyPress={(e) =>
                                          //   handelTwoDigitAfterDecimal(e)
                                          // }
                                          onChange={() => {
                                            onDiscountAmountChange(index);
                                          }}
                                          onKeyUp={() =>
                                            onDiscountAmountChange(index)
                                          }
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name={[name, "amount", "tax"]}
                                        className="m-0"
                                        {...restField}
                                      >
                                        <Select
                                          labelInValue
                                          placeholder="%"
                                          popupMatchSelectWidth={false}
                                          placement="bottomRight"
                                          onChange={() => {
                                            // console.log(
                                            //   gstOption.find(
                                            //     ({ rate }) => rate === 5
                                            //   ),
                                            //   "value"
                                            // );
                                            handleItemsNetAmmount(index);
                                          }}
                                          options={gstOption?.map(
                                            (gst: any) => ({
                                              key: gst.name,
                                              value: gst.rate,
                                              title: gst.rate,
                                              label: gst.name,
                                            })
                                          )}
                                          // {gstSlabs?.map((option: any) => (
                                          //   <Select.Option
                                          //     key={option?.id}
                                          //     value={option?.rate}
                                          //     title={option?.name}
                                          //   >
                                          //     {option?.rate + `%`}
                                          //   </Select.Option>
                                          // ))}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Tooltip
                                        title={form.getFieldValue([
                                          "items",
                                          index,
                                          "amount",
                                          "taxRs",
                                        ])}
                                        placement="top"
                                        // color="#3D4998"
                                      >
                                        <Form.Item
                                          className="m-0"
                                          {...restField}
                                          name={[name, "amount", "taxRs"]}
                                        >
                                          <InputNumber
                                            disabled
                                            // controls={false}
                                            style={{ width: "100%" }}
                                            className="inputNumberTextEnd"
                                            // formatter={(value: any) =>
                                            //   Utils.getInputNumberFormat(value)
                                            // }
                                            // onKeyPress={(e) =>
                                            //   handelTwoDigitAfterDecimal(e)
                                            // }
                                            // onChange={() => handleItemsNetAmmount(index)}
                                          />
                                        </Form.Item>
                                      </Tooltip>
                                    </td>
                                  </>
                                ) : null}
                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "amount", "totalAmount"]}
                                  >
                                    <InputNumber
                                      min={0}
                                      style={{ width: "100%" }}
                                      controls={false}
                                      className="inputNumberTextEnd"
                                      // formatter={(value: any) =>
                                      //   Utils.getInputNumberFormat(value)
                                      // }
                                      // onKeyPress={(e) =>
                                      //   handelTwoDigitAfterDecimal(e)
                                      // }
                                      onChange={(value) => {
                                        onItemsTotalAmountChange(index);
                                      }}
                                      onKeyUp={() => {
                                        onItemsTotalAmountChange(index);
                                      }}
                                    />
                                  </Form.Item>
                                </td>
                                {/* {gstCheckbox ? (
                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "amount", "totalAmount"]}
                                    >
                                      <InputNumber
                                        min={0}
                                        style={{ width: "100%" }}
                                        controls={false}
                                        className="inputNumberTextEnd"
                                        // formatter={(value: any) =>
                                        //   Utils.getInputNumberFormat(value)
                                        // }
                                        // onKeyPress={(e) =>
                                        //   handelTwoDigitAfterDecimal(e)
                                        // }
                                        onChange={(value) => {
                                          onItemsTotalAmountChange(index);
                                        }}
                                        onKeyUp={() => {
                                          onItemsTotalAmountChange(index);
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                ) : (
                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "amount", "totalAmounts"]}
                                    >
                                      <InputNumber
                                        min={0}
                                        style={{ width: "100%" }}
                                        controls={false}
                                        className="inputNumberTextEnd"
                                        // formatter={(value: any) =>
                                        //   Utils.getInputNumberFormat(value)
                                        // }
                                        // onKeyPress={(e) =>
                                        //   handelTwoDigitAfterDecimal(e)
                                        // }
                                        onChange={(value) => {
                                          onItemsTotalAmountChange(index);
                                        }}
                                        onKeyUp={() => {
                                          onItemsTotalAmountChange(index);
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                )} */}
                                <td style={{ width: 25 }}>
                                  {fields.length !== index + 1 && (
                                    <img
                                      className="cursor mx-1"
                                      onClick={() => {
                                        setDeleteItemList(name);
                                        setConfirmation(true);
                                      }}
                                      src={deleteImg}
                                      alt="Delete"
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </>
                    );
                  }}
                </Form.List>
                {gstCheckbox ? (
                  <>
                    {itemLength > 0 && (
                      <tfoot>
                        <tr>
                          <th colSpan={2} className="ps-6">
                            Total
                          </th>
                          <th className="textStart ps-14">{totalQty}</th>
                          <th className="textEnd">
                            ₹ {Utils.getFormattedNumber(totalUnitPrice)}
                          </th>
                          <th colSpan={2} className="textEnd pe-17">
                            ₹ {Utils.getFormattedNumber(totalDiscount)}
                          </th>
                          <th colSpan={2} className="textEnd pe-17">
                            ₹ {Utils.getFormattedNumber(totalgst)}
                          </th>
                          <th colSpan={2} className="textEnd pe-17">
                            ₹ {Utils.getFormattedNumber(totalAmount)}
                          </th>
                        </tr>
                      </tfoot>
                    )}
                  </>
                ) : (
                  <>
                    {itemLength > 0 && (
                      <tfoot>
                        <tr>
                          <th colSpan={2} className="ps-6">
                            Total
                          </th>
                          <th className="textStart ps-14">{totalQty}</th>
                          <th className="textEnd">
                            ₹ {Utils.getFormattedNumber(totalUnitPrice)}
                          </th>
                          <th colSpan={2} className="textEnd pe-17">
                            ₹ {Utils.getFormattedNumber(itemSubTotal)}
                          </th>
                        </tr>
                      </tfoot>
                    )}
                  </>
                )}
              </table>
            </div>
            <Row className="dashedBorderTop pt-10">
              <Col lg={17}></Col>
              <Col lg={6}>
                <Row gutter={[0, 5]}>
                  <Col lg={9}>Sub Total</Col>
                  <Col lg={15} className="textEnd">
                    ₹ {Utils.getFormattedNumber(itemSubTotal)}
                  </Col>
                  {gstCheckbox ? (
                    <>
                      <Col lg={9}>Discount</Col>
                      <Col lg={15} className="textEnd">
                        ₹ {Utils.getFormattedNumber(totalDiscount)}
                      </Col>
                      {sameStateOption ? (
                        <>
                          <Col lg={9}>CGST</Col>
                          <Col lg={15} className="textEnd">
                            ₹ {Utils.getFormattedNumber(totalgst / 2)}
                          </Col>
                          <Col lg={9}>SGST</Col>
                          <Col lg={15} className="textEnd">
                            ₹ {Utils.getFormattedNumber(totalgst / 2)}
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col lg={9}>IGST</Col>
                          <Col lg={15} className="textEnd">
                            ₹ {Utils.getFormattedNumber(totalgst)}
                          </Col>
                        </>
                      )}
                    </>
                  ) : null}
                </Row>
                <Divider className="my-5 grayBg" />
                <Row gutter={[12, 2]}>
                  <Col lg={18}>Total Value</Col>
                  <Col lg={6} className="textEnd">
                    {gstCheckbox ? (
                      <> ₹ {Utils.getFormattedNumber(totalAmount)}</>
                    ) : (
                      <>₹ {Utils.getFormattedNumber(totalUnitPrice)}</>
                    )}
                  </Col>
                  {paymentMethod === InvoicePaymentMethodType.credit &&
                    itemLength > 0 && (
                      <>
                        {/* <Divider className="my-10 grayBg" /> */}
                        <Col lg={12} className="pt-5">
                          <Checkbox
                            onChange={(e) => {
                              e.target.checked
                                ? form.setFieldValue(
                                    "receivedAmount",
                                    Utils.getFormattedNumber(
                                      gstCheckbox ? totalAmount : itemSubTotal
                                    )
                                  )
                                : form.setFieldValue("receivedAmount", 0);
                              onAmountReceived();
                            }}
                          >
                            Paid
                          </Checkbox>
                        </Col>
                        <Col lg={12} className="textEnd">
                          <Form.Item className="m-0" name="receivedAmount">
                            <InputNumber
                              style={{ width: "100%" }}
                              size="small"
                              controls={false}
                              className="inputNumberTextEnd "
                              onChange={(e) => onAmountReceived()}
                            />
                          </Form.Item>
                        </Col>
                        {/* <Divider className="my-10 grayBg" /> */}
                        <Col lg={9}>Balance</Col>
                        <Col lg={15} className="textEnd">
                          ₹
                          {Utils.getFormattedNumber(
                            gstCheckbox
                              ? totalAmount - balance
                              : itemSubTotal - balance
                          )}
                        </Col>
                      </>
                    )}
                </Row>
                <Divider
                  // style={{
                  //   borderBottom: "2px solid rgb(203, 203, 203)",
                  // }}
                  className="my-5 grayBg"
                />
                <Row justify="end" className="mt-10">
                  <Col>
                    <Button
                      onClick={() => {
                        navigate(
                          `/accounts/clients/${currentClient?.id}/expenses`
                        );
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="ps-14">
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => onSubmit(false)}
                        loading={saveButtonLoading}
                      >
                        {id ? "Update" : "Save"}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col className="ps-14">
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => onSubmit(true)}
                        loading={saveNewLoading}
                      >
                        {id ? " Update & New" : " Save & New"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        </Form>
      )}
      {/*-------- Add Category model --------*/}
      {isModalOpen && (
        <AddCategoryModel
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          editCategory={{ id: null }}
          setNewCategory={setNewItem}
          setnamecategory={1}
          isDisable={true}
        />
      )}
      {/* -------------ADD Party ----------*/}
      {openPartyDrawer && (
        <AddParty
          openDrawer={openPartyDrawer}
          setTrigger={setTrigger}
          setOpenDrawer={setOpenPartyDrawer}
          editParty={editParty}
          setNewPartie={setNewParty}
        />
      )}
    </div>
  );
};

export default AddExpensesInvoice;
