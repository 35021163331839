import {
  CloudDownloadOutlined,
  DeleteTwoTone,
  EditOutlined,
  FormOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import AddChartOfAccounts from "./AddChartOfAccounts";
import AddFixedAssetsRegister from "./AddFixedAssetsRegister";
import { useClient } from "../../Context/ClientContext";
import ChartofAccountsServices from "../../Services/ChartofAccountsServices";
import { notificationContext } from "../../Common/PageRoute";
import "../Purchase/Purchase.css";
import { useTheme } from "../../Context/ThemeContext";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { TbCheckbox } from "react-icons/tb";
import { RxBox } from "react-icons/rx";

interface IDataType {
  key: React.Key;
  id: number;
  sno: number;
  code: string;
  name: string;
  inActive: boolean;
  typeName: string;
  groupName: string;
  archive: string;
}

const ChartOfAccounts = () => {
  const { currentClient } = useClient();
  const { themeData }: any = useTheme();
  const { openNotification } = React.useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [data, setData] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [select, setSelect] = React.useState<any>();
  const [accountId, setAccountId] = React.useState<number>(0);
  const [shouldRefresh, setShouldRefresh] = React.useState<boolean>(false);
  const [confirmation, setConfirmation] = React.useState<{
    type: string;
    name: string;
    open: boolean;
  }>({
    type: "",
    name: "",
    open: false,
  });
  const [openPopover, setOpenPopover] = React.useState<any>({
    filter: false,
    status: false,
    showValueSelect: "",
  });
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
    status: "",
  });
  const [openModel, setOpenModel] = React.useState<any>({
    type: "",
    param: {},
  });

  //---------- Add & Edit Model Send props ----------
  const addorEditModel = (clientId: string | undefined, id: string | null) => {
    setOpenModel({
      type: "Account",
      params: {
        open: true,
        clientId: clientId,
        id: id,
        setRefresh: setShouldRefresh,
        refresh: shouldRefresh,
      },
    });
  };

  // --------- Get ChartofAccounts List Api ---------
  const getChartofAccountList = async (
    clientId: string,
    start: number,
    length: number,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: string
  ) => {
    await ChartofAccountsServices.getListChartofAccounts(
      clientId,
      start,
      length,
      search,
      sortCol,
      sortDir,
      status
    ).then((res: any) => {
      if (res.items.length > 0) {
        setData(res.items);
      } else if (res.items.length === 0) {
        setData([]);
        setTotalRecords(res?.totalRecords);
      }
      setLoading(false);
      setTotalRecords(res?.totalRecords);
    });
  };

  // --------- Download Api ---------
  const downloadCSV = async (
    clientId: string,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: string
  ) => {
    await ChartofAccountsServices.downloadCSV(
      clientId,
      search,
      sortCol,
      sortDir,
      status
    ).then((res: any) => {
      openNotification("success", "Accounts downloaded successfully");
    });
  };

  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.columnKey,
      sortDir: filter.order === "descend" ? "desc" : "asc",
    });
    console.log("filter", filter, "sort", sort);
  };

  React.useEffect(() => {
    setLoading(true);
    getChartofAccountList(
      currentClient!.id,
      (page - 1) * pageSize,
      pageSize,
      search,
      filter.sortCol,
      filter.sortDir,
      filter.status
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, page, pageSize, search, filter, shouldRefresh]);

  const columns: ColumnsType<IDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "name",
      width: "5%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "5%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.code}
        </Skeleton>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    {
      title: "Account Type",
      dataIndex: "typeName",
      key: "type",
      width: "25%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.typeName}
        </Skeleton>
      ),
    },
    {
      title: "Account Group",
      dataIndex: "groupName",
      key: "group",
      width: "30%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.groupName}
        </Skeleton>
      ),
    },
    {
      title: "Archive",
      dataIndex: "inActive",
      key: "inActive",
      width: "5%",
      sorter: true,
      align: "center",
      render: (x, record: any) =>
        record.system === false && (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            {x ? (
              <TbCheckbox
                fontSize={16}
                onClick={() => {
                  setConfirmation({
                    type: "active",
                    name: record?.name,
                    open: true,
                  });
                  setAccountId(record?.id);
                }}
              />
            ) : (
              <RxBox
                fontSize={15}
                onClick={() => {
                  setConfirmation({
                    type: "archive",
                    name: record?.name,
                    open: true,
                  });
                  setAccountId(record?.id);
                }}
              />
            )}
          </Skeleton>
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div style={{ display: "flex", gap: "12px" }}>
            <div>
              <Tooltip title="Edit" placement="top" color="#3D4998">
                <FormOutlined
                  className="ca-edit-btn"
                  style={{ color: "#a5abdd" }}
                  onClick={async () => {
                    addorEditModel(currentClient?.id, record?.id);
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title={
                  record.system === false
                    ? "Delete"
                    : "Account can not be delete"
                }
                placement="top"
                color={record.system === false ? "red" : "gray"}
              >
                <DeleteTwoTone
                  disabled
                  className={record.system === false ? "ca-delete-btn" : "px-7"}
                  twoToneColor={
                    record.system === false ? "#ff879d" : "rgb(216 207 207)"
                  }
                  onClick={() => {
                    if (record.system === false) {
                      setConfirmation({
                        type: "delete",
                        name: record?.name,
                        open: true,
                      });
                      setAccountId(record?.id);
                    }
                  }}
                />
              </Tooltip>
            </div>
            <Tooltip
              title={record.system === false ? "Can not reset" : "Reset"}
              placement="top"
              color={record.system === false ? "gray" : "green"}
            >
              <ReloadOutlined
                style={{
                  fontSize: "16px",
                  color: record.system === false ? "rgb(216 207 207)" : "green",
                }}
                onClick={() => {
                  setConfirmation({
                    type: "reset",
                    name: record?.name,
                    open: true,
                  });
                  setAccountId(record?.id);
                }}
              />
            </Tooltip>
          </div>
        </Skeleton>
      ),
    },
  ];

  // ---------Status Filter ---------
  const contentStatus = (
    <>
      <p>Status</p>
      <p style={{ marginTop: "10px", fontWeight: "bold" }}>Value *</p>
      <p style={{ marginTop: "10px" }}>
        <Select
          defaultValue="All"
          onChange={(value: string) => setSelect(value)}
          style={{ width: "220px" }}
          options={[
            { value: "", label: "Any" },
            { value: "active", label: "Normal" },
            { value: "archived", label: "Arctive" },
          ]}
        />
      </p>
      <p style={{ marginTop: "12px", textAlign: "end" }}>
        <Button
          type="link"
          onClick={() => setOpenPopover({ ...openPopover, status: false })}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setfilter({ ...filter, status: { select } });
            setOpenPopover({ ...openPopover, status: false });
          }}
        >
          Apply
        </Button>
      </p>
    </>
  );
  // --------- Add Filter ---------
  const contentFilter = (
    <>
      <p>Status</p>
      <p style={{ marginTop: "10px", fontWeight: "bold" }}>Filter *</p>
      <p style={{ marginTop: "10px" }}>
        <Select
          onChange={(value: string) =>
            setOpenPopover({ ...openPopover, showValueSelect: value })
          }
          style={{ width: "220px" }}
          options={[
            { value: "accountType", label: "Account Type" },
            { value: "accountGroup", label: "Account Group" },
          ]}
        />

        {openPopover.showValueSelect === "accountType" && (
          <>
            <p style={{ marginTop: "10px", fontWeight: "bold" }}>Value *</p>
            <Select
              defaultValue="Account Group 1"
              // onChange={(value: string) => setOpenPopover({ type: { value } })}
              style={{ width: "220px" }}
              options={[
                { value: "accountType", label: "Account Group 1 " },
                { value: "accountGroup", label: "Account Group 2" },
              ]}
            />
          </>
        )}
        {openPopover.showValueSelect === "accountGroup" && (
          <>
            <p style={{ marginTop: "10px", fontWeight: "bold" }}>Value *</p>
            <Select
              defaultValue="Account Type 1  "
              // onChange={(value: string) => setOpenPopover({ type: { value } })}
              style={{ width: "220px" }}
              options={[
                { value: "accountType", label: "Account Type 1" },
                { value: "accountGroup", label: "Account Type 2" },
              ]}
            />
          </>
        )}
      </p>
      <p style={{ marginTop: "12px", textAlign: "end" }}>
        <Button type="link" onClick={() => setOpenPopover({ type: "" })}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setfilter(select);
            setOpenPopover({ type: "" });
          }}
        >
          Apply
        </Button>
      </p>
    </>
  );
  return (
    <>
      <Row>
        <Col xl={12} md={22}>
          <Row justify="start">
            <Col>
              <Space size={window.innerWidth > 1024 ? 5 : 60}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => addorEditModel(currentClient?.id, null)}
                >
                  Account
                </Button>
                <Button
                  icon={<CloudDownloadOutlined />}
                  onClick={() =>
                    downloadCSV(
                      currentClient!.id,
                      search,
                      filter.sortCol,
                      filter.sortDir,
                      filter.status
                    )
                  }
                >
                  Download
                </Button>
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    setShouldRefresh(!shouldRefresh);
                    setPage(1);
                  }}
                >
                  Refresh
                </Button>
                <Button
                  icon={<EditOutlined />}
                  onClick={() =>
                    setOpenModel({ type: "FixedAsset", params: { open: true } })
                  }
                >
                  Fixed Asset Register
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>

        <Col xl={12} md={22}>
          <Row justify="end">
            <Col>
              <Space
                size={window.innerWidth > 1024 ? 5 : 60}
                style={
                  window.innerWidth > 1024
                    ? { paddingTop: 0 }
                    : { paddingTop: 10 }
                }
              >
                <Input.Search
                  onSearch={(e: any) => {
                    setPage(1);
                    setSearch(e);
                  }}
                  placeholder="Search..."
                  style={{ width: 200 }}
                />
                <Popover
                  trigger="click"
                  placement="bottomRight"
                  content={contentStatus}
                  open={openPopover.status}
                  onOpenChange={(x: boolean) =>
                    setOpenPopover({ ...openPopover, status: x })
                  }
                >
                  <Button type="primary" shape="round">
                    Status =
                  </Button>
                </Popover>

                <Popover
                  trigger="click"
                  placement="bottomRight"
                  content={contentFilter}
                  open={openPopover.filter}
                  onOpenChange={(x: boolean) =>
                    setOpenPopover({ ...openPopover, filter: x })
                  }
                >
                  <Button type="primary" shape="round">
                    Add Filter
                  </Button>
                </Popover>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        onChange={tableSort}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IDataType[])
            : data
        }
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
        scroll={pageSize > 15 ? { x: 700, y: 650 } : { x: 600 }}
      />
      {openModel.type === "Account" && (
        <AddChartOfAccounts
          {...openModel.params}
          onCancle={() => setOpenModel({ type: "", param: {} })}
        />
      )}
      {openModel.type === "FixedAsset" && (
        <AddFixedAssetsRegister
          {...openModel.params}
          onCancle={() => setOpenModel({ type: "", param: {} })}
        />
      )}
      {confirmation && (
        <ConfirmationModal
          open={confirmation.open}
          onNo={() => setConfirmation({ type: "", name: "", open: false })}
          // text={
          //   confirmation.type === "delete"
          //     ? "Are you sure to delete this record?"
          //     : confirmation.type === "reset"
          //     ? "Are you sure to reset this record?"
          //     : ""
          // }
          text={`Are you sure to ${confirmation.type} ${confirmation?.name}?`}
          onYes={() => {
            if (accountId) {
              if (confirmation.type === "delete") {
                ChartofAccountsServices.deleteChartofAccounts(
                  currentClient!.id,
                  accountId
                ).then((res) => {
                  if (res?.status) {
                    setShouldRefresh(!shouldRefresh);
                    openNotification(
                      "success",
                      `${confirmation.type} successfully`
                    );
                  } else if (res.message) {
                    openNotification("error", res.message);
                  }
                });
              } else if (confirmation.type === "reset") {
                ChartofAccountsServices.resetChartofAcount(
                  currentClient!.id,
                  accountId
                ).then((res) => {
                  if (res?.status) {
                    setShouldRefresh(!shouldRefresh);
                    openNotification(
                      "success",
                      `${confirmation.type} successfully`
                    );
                  }
                });
              } else if (
                confirmation.type === "archive" ||
                confirmation.type === "active"
              ) {
                ChartofAccountsServices.toggleChartofAccount(
                  currentClient!.id,
                  accountId
                ).then((res) => {
                  if (res?.status) {
                    setShouldRefresh(!shouldRefresh);
                    openNotification(
                      "success",
                      `${confirmation.type} successfully`
                    );
                  }
                });
              }
              setConfirmation({ type: "", name: "", open: false });
            }
          }}
        />
      )}
    </>
  );
};

export default ChartOfAccounts;
