import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React from "react";
import TermsAndConditionsService from "../../Services/TermsAndConditionsService";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { Voucher_Type } from "../../Services/VoucherServices";
import SunEditor from "suneditor-react";
import { LoadingOutlined } from "@ant-design/icons";
interface Modelprops {
  onCancel: any;
  isModelOpen: boolean;
  id?: any;
  voucher?: Voucher_Type | undefined;
  setRefresh: any;
}
const AddTermsAndCondition = ({
  isModelOpen,
  onCancel,
  id,
  voucher,
  setRefresh,
}: Modelprops) => {
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [vouchertype, setVoucherType] = React.useState<Voucher_Type>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [termsAndCond, setTermsAndCond] = React.useState<any>();
  const addTermsndCondData = () => {
    form.validateFields().then(async (val) => {
      setBtnLoading(true);
      console.log("val", val);
      let obj = {
        ...val,
      };
      let res = await TermsAndConditionsService?.saveData(
        currentClient?.id,
        id,
        vouchertype ?? voucher,
        obj
      );
      if (res) {
        setBtnLoading(false);
        setRefresh((x: boolean) => !x);
        onCancel(false);
        openNotification(
          "success",
          `Term & Condition ${id ? "Edit successfully" : "Add successfully"}`
        );
        onCancel(false);
      } else {
        setBtnLoading(false);
        openNotification("error", "error");
      }
    });
  };

  React.useEffect(() => {
    if (id) {
      const getDatabyId = async () => {
        setLoading(true);
        let res = await TermsAndConditionsService?.getTermsAndConditionbyId(
          currentClient?.id,
          id,
          voucher
        );
        if (res?.result?.termNConditions !== undefined) {
          setTermsAndCond(res?.result?.termNConditions);
        } else {
          form?.setFieldsValue({
            termNConditions: res?.result?.termNConditions,
          });
        }
        if (res) {
          setTermsAndCond(res?.result?.termNConditions);
          form?.setFieldsValue({
            templateName: res?.result?.templateName,
            voucherType: res?.result?.voucherType,
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
      getDatabyId();
    }
  }, []);

  const handleSunEditorChange = (content: any) => {
    form.setFieldsValue({
      termNConditions: content,
    });
  };
  return (
    <>
      <Modal
        width={1000}
        open={isModelOpen}
        onCancel={() => onCancel(false)}
        title="Add Terms & Conditions"
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => onCancel(false)}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              loading={btnLoading}
              type="primary"
              onClick={addTermsndCondData}
            >
              {id ? "Edit" : "Save"}
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 20 }} />}
          spinning={loading}
        >
          <Form form={form} labelAlign="left">
            <Row>
              <Col span={12}>
                <Form.Item
                  name="templateName"
                  label="Template Name"
                  labelCol={{ span: 8 }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="voucherType"
                  label="Voucher Type"
                  labelCol={{ span: 8 }}
                  labelAlign="right"
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Select Voucher Type..."
                    options={[
                      {
                        value: Voucher_Type?.SalesVoucher,
                        label: "Sales Voucher",
                      },
                      {
                        value: Voucher_Type?.CreditNote,
                        label: "Credit Note",
                      },
                      {
                        value: Voucher_Type?.PurchaseVoucher,
                        label: "Purchase Voucher",
                      },
                      {
                        value: Voucher_Type?.DebitNote,
                        label: "Debit Note",
                      },
                      // {
                      //   value: Voucher_Type?.PurchaseOrder,
                      //   label: "Purchase Order",
                      // },
                      // {
                      //   value: Voucher_Type?.SalesOrder,
                      //   label: "Sales Order",
                      // },

                      // {
                      //   value: Voucher_Type?.Quotation,
                      //   label: "Quotation",
                      // },
                    ]}
                    onChange={(val: any) => setVoucherType(val)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="termNConditions" label="Terms and Conditions">
              <SunEditor
                setContents={termsAndCond}
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["bold", "underline", "italic", "strike"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],
                    ["outdent", "indent"],
                    ["removeFormat"],
                  ],
                  defaultTag: "div",
                  showPathLabel: false,
                }}
                height="150px"
                onChange={handleSunEditorChange}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddTermsAndCondition;
