import React from "react";
import UsersService, { Setting_Type } from "../../Services/UsersService";
import { Button, Checkbox, Divider, Form, Input, Row, Spin } from "antd";
import { settingType } from "./UsersDetailsTabs";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const SettingSecurity = ({ userId }: any) => {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    getUserDataById();
  }, [userId]);
  const getUserDataById = async () => {
    setLoading(true);
    let res = await UsersService?.getUserDataById(userId);
    if (res) {
      const voucherAccessList = res?.result?.accessSettings?.settingsAccessList;
      const formattedData = voucherAccessList?.map((item: any) => ({
        allowToEdit: item?.allowToEdit,
        settingType: settingType[item?.settingType],
      }));

      form.setFieldsValue({
        items: formattedData,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const saveBasicDetailsData = () => {
    setBtnLoading(true);
    form.validateFields().then(async (val: any) => {
      console.log("val", val);
      let data = val?.items.map((itm: any) => {
        let reco = {
          settingType: settingType.indexOf(itm?.settingType),
          allowToEdit: itm?.allowToEdit,
        };
        return reco;
      });

      let res = await UsersService?.UpdateUserDetailsData(
        userId,
        { AccessSettings: { SettingsAccessList: [...data] } },
        4
      );
      if (res?.status === true) {
        console.log("res", res);
        getUserDataById();
        setBtnLoading(false);
        notification.success({
          message: "Success",
          description: "User details updated successfully.",
        });
      } else {
        setBtnLoading(false);
        notification.error({
          message: "Error",
          description: res?.message,
        });
      }
    });
  };
  return (
    <div>
      <Divider orientation="left" orientationMargin={0}>
        Setting Security
      </Divider>
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <Form
          form={form}
          labelAlign="left"
          layout="vertical"
          requiredMark={false}
          initialValues={{
            items: [{}],
          }}
        >
          <table
            // border="1"
            // cellPadding="10"
            // cellSpacing="0"
            className="AddPageTabelDesign"
            style={{ width: "50%" }}
          >
            <thead>
              <tr>
                <th>Setting Type</th>
                <th>Allow To Edit</th>
              </tr>
            </thead>
            <tbody>
              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <tr key={key}>
                        <td>
                          <Form.Item
                            className="m-0"
                            {...restField}
                            name={[name, "settingType"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Voucher Type",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Form.Item
                            className="m-0"
                            {...restField}
                            name={[name, "allowToEdit"]}
                            valuePropName="checked"
                            rules={[
                              {
                                required: true,
                                message: "Missing Add/Edit Info",
                              },
                            ]}
                          >
                            <Checkbox />
                          </Form.Item>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </Form.List>
            </tbody>
          </table>
          <Row
            style={{ margin: "10px 10px", marginRight: "117px" }}
            justify={"center"}
          >
            <Button
              loading={btnLoading}
              type="primary"
              style={{ padding: "4px 30px" }}
              onClick={saveBasicDetailsData}
            >
              Update
            </Button>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default SettingSecurity;
