import { Card, Tabs, TabsProps } from "antd";
import React from "react";
import UserBasicDetails from "./UserBasicDetails";
import VoucherSecurity from "./VoucherSecurity";
import MasterSecurity from "./MasterSecurity";
import ReportSecurity from "./ReportSecurity";
import { useLocation } from "react-router-dom";
import SettingSecurity from "./SettingSecurity";

export const reportType = [
  "Balance Sheet",
  "PnL",
  "Trial Balance",
  "Cash Flow",
  "Debtors Ageing",
  "Creditors Ageing",
  "Customer Outstanding",
  "Supplier Outstanding",
  "General Ledger",
  "FAR",
  "BRS",
  "GST",
  "TCSTDS",
];

export const masterType = [
  "Client",
  "Ledger",
  "Group",
  "Item",
  "Item Group",
  "Unit",
  "Asset",
];

export const settingType = [
  "Company Info",
  "Voucher",
  "Report",
  "Opening Entry",
  "Templates",
  "Default Ledger",
  "TnC",
  "Email",
];

const UsersDetailsTabs = () => {
  const location = useLocation();

  let userId = location?.state?.userId;

  const onChange = (key: string) => {
    console.log(key);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Basic Details",
      children: (
        <>
          <UserBasicDetails userId={userId} />
        </>
      ),
    },
    {
      key: "2",
      label: "Voucher Security",
      children: (
        <>
          <VoucherSecurity userId={userId} />
        </>
      ),
    },
    {
      key: "3",
      label: "Report Security",
      children: (
        <>
          <ReportSecurity userId={userId} />
        </>
      ),
    },
    {
      key: "4",
      label: "Master Security",
      children: (
        <>
          <MasterSecurity userId={userId} />
        </>
      ),
    },

    {
      key: "5",
      label: "Setting Security",
      children: (
        <>
          <SettingSecurity userId={userId} />
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <Card
          size="small"
          style={{
            margin: "10px",
            boxShadow: "none",
            borderRadius: "5px",
          }}
        >
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Card>
      </div>
    </>
  );
};

export default UsersDetailsTabs;
