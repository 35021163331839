import {
  Avatar,
  Col,
  Divider,
  Modal,
  Row,
  Switch,
  Tooltip,
  Button,
  Radio,
  Spin,
} from "antd";
import React from "react";
import { useClient } from "../../Context/ClientContext";
import noteContext from "../../Context/StyleContext";
import { Utils } from "../../../Utilities/Utils";
import "../../Common/AddPageCommon.css";
import PurchaseService from "../../Services/PurchaseService";
import { PurchaseInvoiceTypes } from "../../../Types/CommonConstants";
import { LoadingOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import ViewPurchaseInvoice from "./ViewPurchaseInvoice";

const ViewDebitNote: React.FC<{
  open: boolean;
  onCancel: any;
  debitNoteId: string | undefined;
}> = (props) => {
  console.log(props);
  const { windowWidth } = React.useContext(noteContext);
  const { currentClient } = useClient();
  const [isGSTEnabled, setGstEnabled] = React.useState<boolean>(false);
  const [isStateSame, setIsStateSame] = React.useState<boolean>();
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [tdsOrTcs, setTdsOrTcs] = React.useState<number>(0);
  const [isInCash, setIsInCash] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({});
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    id: string;
  }>({
    type: "",
    id: "",
  });

  React.useEffect(() => {
    setLoading(true);
    if (props.debitNoteId) {
      PurchaseService.getPurchaseInvoices(
        currentClient?.id!,
        PurchaseInvoiceTypes.PurchaseDebitNote,
        true,
        props.debitNoteId
      )
        .then((res: any) => {
          if (res.result) {
            console.log("345235345", res.result);
            setGstEnabled(res.result?.isGstEnabled);
            const debitNote = res?.result?.invoice;
            setItemLength(debitNote?.items?.length);
            setIsStateSame(debitNote?.isStateSame);
            setData({
              number: debitNote?.number,
              invoiceRefNo: debitNote?.invoiceRef?.name,
              tradingPartner: debitNote?.tradingPartner,
              phoneNo: debitNote?.phoneNo,
              billingAddress: debitNote?.billingAddress,
              shippingAddress: debitNote?.shippingAddress,
              paymentType: debitNote?.paymentType === 1 ? true : false,
              date: Utils.getFormattedDate(debitNote?.date),
              dueDate: Utils.getFormattedDate(debitNote?.dueDate),
              items: debitNote?.items,
              totalQty: debitNote?.amountSummary?.totalQty,
              totalPrice: Utils.getFormattedNumber(
                debitNote.amountSummary?.totalPrice
              ),
              totalDiscount: Utils.getFormattedNumber(
                debitNote?.amountSummary?.totalDis
              ),
              sellPrice: Utils.getFormattedNumber(
                debitNote?.amountSummary?.sellPrice
              ),
              dueBalance: Utils.getFormattedNumber(
                debitNote.amountSummary?.dueBalance
              ),
              totalgst: debitNote.amountSummary?.igst
                ? debitNote.amountSummary?.igst
                : debitNote.amountSummary?.sgst + debitNote.amountSummary?.cgst,
              receivedAmount: debitNote.amountSummary?.receivedAmount,
              totalInvoiceAmount: debitNote.amountSummary?.totalInvoiceAmount,
              bankDts: debitNote?.bankDts,
            });
            setLoading(false);
          } else setLoading(false);
        })
        .catch((ex: any) => {
          console.error(ex);
          setLoading(false);
        });
    } else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.debitNoteId]);

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        width={1600}
        style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
        maskClosable={false}
        closable={false}
        onCancel={() => props.onCancel()}
        centered
        footer={
          <div style={{ marginTop: "0px" }} className="pe-15">
            <Button
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <div style={{ padding: 15 }}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            spinning={loading}
          >
            {/* {windowWidth <= 1024 ? (
          <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
            Debit Note
          </h3>
        ) : (
          ""
        )} */}
            <Row justify="space-between">
              <Col xl={17} lg={24} md={24} className="dashedBoxStyle">
                <Row gutter={24} className="ps-14" justify="space-between">
                  <Col
                    lg={14}
                    md={11}
                    xs={23}
                    sm={23}
                    className="themeLightBgColor p-10"
                  >
                    <Row gutter={[17, 2]}>
                      <Col lg={12}>
                        <span className="themeColor fw-500">Party Name</span>
                        <div>
                          <NavLink
                            to={`/accounts/clients/${currentClient?.id}/parties/${data.tradingPartner?.id}`}
                          >
                            {data.tradingPartner?.name}
                          </NavLink>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <span className="themeColor fw-500">Phone No.</span>
                        <div>{data?.phoneNo}</div>
                      </Col>
                      <Col lg={12}>
                        <span className="themeColor fw-500">
                          Billing Address
                        </span>
                        <div>{data?.billingAddress}</div>
                      </Col>
                      <Col lg={12}>
                        <span className="themeColor fw-500">
                          Shipping Address
                        </span>
                        <div>{data?.shippingAddress}</div>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={9} md={4} xs={23} sm={23}>
                    <Row gutter={[17, 8]}>
                      <Col lg={24}>
                        <span
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span
                            className="pe-10 pt-5 fw-500"
                            style={{
                              color: isInCash ? "gray" : "rgb(11, 48, 142)",
                            }}
                          >
                            Debit
                          </span>

                          <Switch
                            checked={data?.paymentType}
                            disabled
                            size="small"
                            style={{ marginTop: "7px" }}
                          />

                          <span
                            className="ps-11 pt-5 fw-500"
                            style={{
                              color: isInCash ? "rgb(11, 48, 142)" : "gray",
                            }}
                          >
                            Cash
                          </span>
                        </span>
                      </Col>
                      <Col lg={12}>
                        <span className="themeColor fw-500">
                          Debit Note No.
                        </span>
                        <div>{data?.number}</div>
                      </Col>
                      <Col lg={12}>
                        <span className="themeColor fw-500">Invoice Date</span>
                        <div>{data?.date}</div>
                      </Col>
                      <Col lg={12}>
                        <span className="themeColor fw-500">
                          Invoice Ref.No.
                        </span>
                        <div
                          style={{ cursor: "pointer", color: "#1677FF" }}
                          onClick={() =>
                            setOpenModel({
                              type: "viewPurchaseInvoice",
                              id: data.invoiceRefNo?.id,
                            })
                          }
                        >
                          {data?.invoiceRefNo?.name}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {windowWidth <= 1024 ? (
                ""
              ) : (
                <Col
                  xl={4}
                  lg={7}
                  md={7}
                  className="fs-35 fw-400 themeColor textEnd"
                >
                  Debit Note
                </Col>
              )}
            </Row>

            <table
              className="ca-viewmodel-table"
              style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={2}
                    rowSpan={2}
                    style={{ width: "6%" }}
                    className="textStart ps-10"
                  >
                    S no.
                  </th>
                  <th
                    rowSpan={2}
                    style={{
                      width: "11%",
                      borderLeft: "1px solid rgb(240, 245, 255)",
                    }}
                    className="textStart"
                  >
                    Item
                  </th>
                  <th rowSpan={2} className="textStart">
                    Description
                  </th>
                  <th
                    rowSpan={2}
                    style={{ width: "10%" }}
                    className="textStart"
                  >
                    Account
                  </th>
                  <th rowSpan={2} style={{ width: "6%" }} className="textEnd">
                    Qty
                  </th>
                  <th rowSpan={2} style={{ width: "6%" }}>
                    Unit
                  </th>
                  <th rowSpan={2} style={{ width: "9%" }} className="textEnd">
                    Unit Price
                  </th>
                  <th
                    colSpan={2}
                    style={{ width: "12%" }}
                    className="textEnd pe-32"
                  >
                    Discount
                  </th>
                  {isGSTEnabled && (
                    <th
                      colSpan={2}
                      style={{ width: "12%" }}
                      className="textEnd pe-32"
                    >
                      GST %
                    </th>
                  )}
                  <th
                    rowSpan={2}
                    style={{ width: "14%" }}
                    className="pe-10 textEnd"
                  >
                    Total Amount
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "6%" }} className="textEnd">
                    %
                  </th>
                  <th style={{ width: "7%" }} className="textEnd">
                    ₹
                  </th>
                  {isGSTEnabled && (
                    <th style={{ width: "6%" }} className="textEnd">
                      %
                    </th>
                  )}
                  {isGSTEnabled && (
                    <th style={{ width: "7%" }} className="textEnd">
                      ₹
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {data?.items?.map((item: any, i: any) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {item.isService ? (
                          <Tooltip
                            title="Service"
                            placement="left"
                            color="#1d39c4"
                          >
                            <Avatar
                              style={{
                                cursor: "pointer",
                                verticalAlign: "middle",
                                color: "#1d39c4",
                                backgroundColor: "#f0f5ff",
                                borderColor: "#adc6ff",
                                fontWeight: "bold",
                              }}
                              size="small"
                            >
                              S
                            </Avatar>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Goods"
                            placement="left"
                            color="#389e0d"
                          >
                            <Avatar
                              style={{
                                cursor: "pointer",
                                verticalAlign: "middle",
                                color: "#389e0d",
                                backgroundColor: "#f6ffed",
                                borderColor: "#b7eb8f",
                                fontWeight: "bold",
                              }}
                              size="small"
                            >
                              G
                            </Avatar>
                          </Tooltip>
                        )}
                      </td>
                      {/* <td>
                    {item.isTaxable ? (
                      <Tooltip
                        title="With Tax"
                        placement="right"
                        color="#08979C"
                      >
                        <Avatar
                          style={{
                            cursor: "pointer",
                            verticalAlign: "middle",
                            color: "#08979C",
                            backgroundColor: "#E6FFFB",
                            borderColor: "#08979C",
                            fontWeight: "bold",
                          }}
                          size="small"
                        >
                          T
                        </Avatar>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="WithOut Tax"
                        placement="right"
                        color="#531DAB"
                      >
                        <Avatar
                          style={{
                            cursor: "pointer",
                            verticalAlign: "middle",
                            color: "#531DAB",
                            backgroundColor: "#F9F0FF",
                            borderColor: "#531DAB",
                            fontWeight: "bold",
                          }}
                          size="small"
                        >
                          WT
                        </Avatar>{" "}
                      </Tooltip>
                    )}
                  </td> */}
                      <td>
                        <NavLink
                          to={`/accounts/clients/${currentClient?.id}/purchase/inventory`}
                          state={{
                            id: item.id,
                            type: "sales",
                            itemType: item.isService
                              ? "purchaseService"
                              : "purchaseGoods",
                          }}
                        >
                          {item.name}
                        </NavLink>
                      </td>
                      <td>{item?.description}</td>
                      <td>{item.account?.name}</td>
                      <td className="textEnd">
                        {Utils.getFormattedNumber(item.amount?.qty)}
                      </td>
                      <td>{item.amount?.unit?.name}</td>
                      <td className="textEnd">
                        {Utils.getFormattedNumber(item.amount?.price)}
                      </td>
                      <td className="textEnd">{item.amount?.discount} %</td>
                      <td className="textEnd">
                        {Utils.getFormattedNumber(item.amount?.discountRupee)}
                      </td>
                      {isGSTEnabled && (
                        <td className="textEnd">{item.amount?.tax?.name}</td>
                      )}
                      {isGSTEnabled && (
                        <td className="textEnd">
                          {Utils.getFormattedNumber(item.amount?.taxAmount)}
                        </td>
                      )}
                      <td className="pe-10 textEnd">
                        {Utils.getFormattedNumber(item.amount?.totalAmount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              {itemLength > 0 && (
                <tfoot>
                  <tr>
                    <th colSpan={4} className="textStart ps-10">
                      Total
                    </th>
                    <th colSpan={2} className="textEnd">
                      {data.totalQty}
                    </th>
                    <th colSpan={2} className="textEnd">
                      ₹ {data.totalPrice}
                    </th>
                    <th colSpan={2} className="textEnd">
                      ₹ {data.totalDiscount}
                    </th>
                    {isGSTEnabled && (
                      <th colSpan={2} className="textEnd">
                        ₹ {data.totalgst}
                      </th>
                    )}
                    <th colSpan={2} className="textEnd pe-10">
                      ₹ {data.totalInvoiceAmount}
                    </th>
                  </tr>
                </tfoot>
              )}
            </table>

            <Row
              justify={"space-between"}
              className="dashedBorderTop pt-10 pe-10"
            >
              <Col xl={7} lg={11} md={24}>
                <div style={{ overflowY: "auto" }}>
                  <table className="ca-viewmodel-table">
                    <>
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>S No.</th>
                          <th
                            style={{
                              textAlign: "start",
                              paddingLeft: "8px",
                            }}
                          >
                            Select Account
                          </th>
                          <th
                            style={{
                              textAlign: "end",
                              paddingRight: "8px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.bankDts?.map((item: any, i: any) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>
                                <span>{item?.name}</span>
                              </td>
                              <td style={{ textAlign: "end" }}>
                                <span>{item?.amount}</span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  </table>
                </div>
              </Col>
              <Col lg={6} offset={5}>
                <Row gutter={[0, 8]}>
                  <Col lg={9}>Selling Price</Col>
                  <Col lg={15} className="textEnd">
                    ₹ {data?.sellPrice}
                  </Col>
                  <Col lg={9}>Discount</Col>
                  <Col lg={15} className="textEnd">
                    ₹ {data?.totalDiscount}
                  </Col>
                  {isGSTEnabled && (
                    <>
                      {isStateSame ? (
                        <>
                          <Col lg={9}>CGST</Col>
                          <Col lg={15} className="textEnd">
                            ₹ {Utils.getFormattedNumber(data?.totalgst / 2)}
                          </Col>
                          <Col lg={9}>SGST</Col>
                          <Col lg={15} className="textEnd">
                            ₹ {Utils.getFormattedNumber(data?.totalgst / 2)}
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col lg={9}>IGST</Col>
                          <Col lg={15} className="textEnd">
                            ₹ {Utils.getFormattedNumber(data?.totalgst)}
                          </Col>
                        </>
                      )}
                      <Col lg={15}>
                        <Row>
                          <Col lg={14}>
                            <Radio.Group
                              disabled
                              // onChange={(e) => {
                              //   setTdsOrTcs(e.target.value);
                              // }}
                              value={tdsOrTcs}
                            >
                              <Radio value={0}>TDS</Radio>
                              <Radio value={1}>TCS</Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                      </Col>
                      {tdsOrTcs === 1 ? (
                        <Col lg={9} className="textEnd">
                          ₹ {data?.totalTcs ? data?.totalTcs : 0}
                        </Col>
                      ) : tdsOrTcs === 0 ? (
                        <Col lg={9} className="textEnd">
                          ₹ {data?.totalTds ? data?.totalTds : 0}
                        </Col>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Row>
                <Divider className="my-5 grayBg" />
                <Row gutter={[12, 2]}>
                  <Col lg={18}>Total Invoice Value</Col>

                  <Col lg={6} className="textEnd">
                    ₹ {data?.totalInvoiceAmount}
                  </Col>
                  {/* {!isInCash && itemLength > 0 && (
                <>
                  <Col lg={12} className="pt-5">
                    Received
                  </Col>
                  <Col lg={12} className="textEnd">
                    <div>
                      ₹ {data?.receivedAmount ? data?.receivedAmount : 0}
                    </div>
                  </Col>
                  <Divider className="my-5 grayBg" />

                  <Col lg={9}>Balance</Col>
                  <Col lg={15} className="textEnd">
                    ₹ {data?.dueBalance}
                  </Col>
                </>
              )} */}
                </Row>
                <Divider
                  style={{
                    borderBottom: "2px solid rgb(203, 203, 203)",
                  }}
                  className="my-5"
                />
              </Col>
            </Row>
          </Spin>
        </div>
      </Modal>
      {openModel.type === "viewPurchaseInvoice" && (
        <ViewPurchaseInvoice
          open={true}
          onCancel={() => setOpenModel({ type: "", id: "" })}
          invoiceId={openModel.id}
        />
      )}
    </React.Fragment>
  );
};
export default ViewDebitNote;
