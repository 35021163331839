import React, { useEffect, useState } from "react";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import {
  Radio,
  Tabs,
  Button,
  Col,
  DatePicker,
  Row,
  Select,
  Skeleton,
  Table,
  Card,
} from "antd";
import { useTheme } from "../../Context/ThemeContext";
import { ColumnsType } from "antd/es/table";
import { Utils } from "../../../Utilities/Utils";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { RegisterDetail } from "./RegisterDetail";

export const Registers = () => {
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  type TabItem = {
    label: string;
    key: string;
    children: React.ReactNode;
  };
  const items: TabItem[] = [
    {
      label: "Day Register",
      key: "0",
      children: (
        <>
          <RegisterDetail voucherType={0} />
        </>
      ),
    },
    {
      label: "Journal Register",
      key: "10",
      children: (
        <>
          <RegisterDetail voucherType={10} />
        </>
      ),
    },

    {
      label: "Sales Register",
      key: "1",
      children: (
        <>
          <RegisterDetail voucherType={1} />
        </>
      ),
    },
    {
      label: "Credit Note Register",
      key: "2",
      children: (
        <>
          <RegisterDetail voucherType={2} />
        </>
      ),
    },
    {
      label: "Receipt Register",
      key: "6",
      children: (
        <>
          <RegisterDetail voucherType={6} />
        </>
      ),
    },

    {
      label: "Purchase Register",
      key: "3",
      children: (
        <>
          <RegisterDetail voucherType={3} />
        </>
      ),
    },
    {
      label: "Debit Note Register",
      key: "4",
      children: (
        <>
          <RegisterDetail voucherType={4} />
        </>
      ),
    },
    {
      label: "Payment Register",
      key: "5",
      children: (
        <>
          <RegisterDetail voucherType={5} />
        </>
      ),
    },
  ];
  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <Row justify={"end"}></Row>
      <Tabs type="card" items={items}></Tabs>
    </Card>
  );
};
