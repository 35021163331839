import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum ApplicationRoleNames {
  SUPERADMIN,
  ADMIN,
  AUTHORITIES,
  DATAENTRY,
  AUDITORS,
  VIEWONLY,
}

export enum UserUpdateSection {
  BasicDetail,
  VoucherSetting,
  ReportSetting,
  MasterSetting,
  SettingAccess,
}

export enum Report_Type {
  BalanceSheet,
  PnL,
  TrialBalance,
  CashFlow,
  DebtorsAgeing,
  CreditorsAgeing,
  CustomerOutstanding,
  SupplierOutstanding,
  GeneralLedger,
  FAR,
  BRS,
  GST,
  TCSTDS,
}

export enum Master_Type {
  Client,
  Ledger,
  Group,
  Item,
  ItemGroup,
  Unit,
  Asset,
}

export enum Setting_Type {
  CompanyInfo,
  Voucher,
  Report,
  OpeningEntry,
  Templates,
  DefaultLedger,
  TnC,
  Email,
}

class UserService {
  route = "/API/User";

  getUserList = () => ApiUtility.get(`${this.route}/UserList`);

  getUserDataById = (userId: string | undefined) =>
    ApiUtility.get(`${this.route}/${userId}`);

  postUserData = (model: any) => ApiUtility?.post(`${this.route}`, model);

  UpdateUserDetailsData = (
    UserId: string | undefined,
    model: any,
    UserUpdateSection: UserUpdateSection
  ) =>
    ApiUtility.post(
      `${this.route}/${UserId}?updateSection=${UserUpdateSection}`,
      model
    );
}

export default new UserService();
