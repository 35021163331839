import {
  ContactsTwoTone,
  DownOutlined,
  MinusCircleOutlined,
  MinusCircleTwoTone,
  MinusOutlined,
  PaperClipOutlined,
  PlusOutlined,
  PrinterOutlined,
  PrinterTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import "../Sales/SalesStyle.css";
import React, { useEffect } from "react";
import phoneImg from "../.././../images/phoneIcon.svg";
import emailImg from "../.././../images/emailIcon.svg";
import PurchaseService, {
  CAPContactTypes,
} from "../../Services/PurchaseService";
import ContactPersonModel from "../Sales/ContactPersonModel";
import { useClient } from "../../Context/ClientContext";
import { AddressFields, NameComponent } from "../../Common/CAPComponents";
import { ConfirmationModal } from "../../Common/ConfirmationModal";

interface AddSupplierProps {
  openDrawer?: boolean;
  setTrigger?: any;
  setOpenDrawer?: any;
  notification?: any;
  splr?: any;
}
const initialContactPerson = {
  contactName: {
    first: "",
    last: "",
  },

  phone: "",
  email: "",
};

export const AddPurchaseSupplier = ({
  openDrawer,
  setOpenDrawer,
  setTrigger,
  notification,
  splr,
}: AddSupplierProps) => {
  const { currentClient } = useClient();
  const [form] = Form.useForm();
  const [contactItem, setContactItem] =
    React.useState<any>(initialContactPerson);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [touched, setTouched] = React.useState<boolean>(false);
  const [errorForBName, setErrorForBName] = React.useState<boolean>(false);
  const [openContactPerson, setOpenContactPerson] =
    React.useState<boolean>(false);
  const [openDropDown, setOpenDropDown] = React.useState<any>({
    forMobile: false,
    forEmail: false,
    forFax: false,
    forWebsite: false,
  });

  const initialValues = {
    number: "",
    type: CAPContactTypes.Customer,
    businessName: "",
    name: {
      title: undefined,
      first: "",
      last: "",
    },
    phone: { primary: "" },
    email: { primary: "" },
    billingAddress: undefined,
    shippingAddress: undefined,
    language: "english",
    bankName: {
      name: "",
    },
    currency: {
      name: "",
    },
    discount: "",
    // payOption: BkpayOptionTypes.Stripe,
    vat: {
      vatCode: 0,
      vatNumber: "",
      EoriNumber: "",
    },
    tags: [],
    notes: "",
    fax: "",
    website: "",
    contactPerson: {
      contactName: {
        title: 0,
        first: "",
        last: "",
      },
      phone: "",
      email: "",
    },
    payTerms: "",
    attachments: [],
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          onClick={() =>
            console.log(setOpenDropDown({ ...openDropDown, forFax: true }))
          }
          icon={<PrinterTwoTone />}
        >
          Add Fax
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="link"
          onClick={() => setOpenDropDown({ ...openDropDown, forWebsite: true })}
          icon={<MinusCircleTwoTone />}
        >
          Add Webside
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="link"
          onClick={() => setOpenContactPerson(true)}
          icon={<ContactsTwoTone />}
        >
          Add Contact Person
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (openDrawer) {
      if (splr?.id !== null) {
        PurchaseService.getCustomerById(currentClient!.id, splr?.id).then(
          (res: any) => {
            if (res.result) {
              form.setFieldsValue({
                ...res?.result,
                contactPerson: contactItem,
              });
            }
            setContactItem({
              contactName: {
                first: res?.result?.contactPerson?.contactName?.first,
                last: res?.result?.contactPerson?.contactName?.last,
              },
              phone: res?.result?.contactPerson?.phone,
              email: res?.result?.contactPerson?.email,
            });
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer]);

  const onDismissConfirm = () => {
    setConfirmation(false);
    setOpenDrawer(true);
  };

  const onDismissBothModels = () => {
    setTouched(false);
    setConfirmation(false);
    setOpenDrawer(false);
    setErrorForBName(false);
    form.resetFields();
  };

  const onClose = () => {
    // const val = form.getFieldsValue();
    // let touched = false;

    // function flattenObj(obj: any) {
    //   for (let key in obj) {
    //     if (typeof obj[key] === "object") {
    //       flattenObj(obj[key]);
    //     } else {
    //       if (!!obj[key]) {
    //         touched = true;
    //       }
    //     }
    //   }
    // }
    // flattenObj(val);

    if (touched) {
      setConfirmation(true);
    } else {
      setConfirmation(false);
      form.resetFields();
      setOpenDrawer(false);
      setOpenDropDown(false);
    }
  };

  const addSuppliers = () => {
    form
      .validateFields()
      .then(async (val) => {
        const supplier = {
          ...val,
          contactPerson: contactItem,
          type: 1,
        };
        console.log("supplier data", supplier)
        await PurchaseService.postSupplier(
          currentClient!.id,
          supplier,
          splr?.id
        ).then((res) => {
          if (res?.status) {
            notification(
              "success",
              `Customer details
                 ${splr?.id ? "Updated" : "Saved"} Successfully`
            );
            form.resetFields();
            setContactItem(initialContactPerson);
            setOpenDrawer(false);
            setTrigger((x: any) => !x);
          } else {
            notification("error", res?.message);
          }
        });
      })
      .catch((ex: any) => {
        console.log(ex);
      });
  };
  return (
    <React.Fragment>
      <Drawer
        title="Add Supplier"
        open={openDrawer}
        closable={false}
        onClose={onClose}
        width={950}
        maskClosable={false}
        footer={
          <Space style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => addSuppliers()} type="primary">
              Save
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          name="suppliers"
          requiredMark={false}
          colon={false}
          autoComplete="off"
          // size="small"
          layout="vertical"
          initialValues={initialValues}
        >
          <Row justify="space-around">
            <Col span={12}>
              <Tooltip
                placement="bottom"
                color="#fff"
                title={
                  <p className="grayColor fs-12">
                    Enter the name of the business to be reflected on the
                    invoice. (Eg. company's name, partnership firm's name, sole
                    trader business' name, individual's name, etc).
                  </p>
                }
              >
                <span style={{ color: "gray" }}>Name</span>
                <Form.Item name="businessName" className="inputBoxMb8">
                  <Input
                    onChange={(e) => {
                      if (e.target.value) setTouched(true);
                      else setTouched(false);
                      // neitherBusinessName(form.getFieldsValue(true));
                    }}
                  />
                </Form.Item>
              </Tooltip>

              <Form.Item name="name" className="inputBoxMb">
                <NameComponent
                  value={form.getFieldValue("name")}
                  label="Contact Person"
                  FormItemName={["name"]}
                  innerLabel={true}
                />
              </Form.Item>

              <Row gutter={20}>
                {/* --------- addPhone --------- */}
                <Col span={12}>
                  <Form.Item name="phone" className="inputBoxMb">
                    {/* <Space style={{ display: "flex", flexDirection: "column" }}> */}
                    <Input.Group>
                      <Form.Item
                        name={["phone", "primary"]}
                        style={{ marginTop: "10px" }}
                        className="inputBoxMb"
                      >
                        <Input
                          placeholder="0123456789"
                          prefix={<img src={phoneImg} alt="phone"></img>}
                          maxLength={10}
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                          onChange={(e) => {
                            if (e.target.value) setTouched(true);
                            else setTouched(false);
                          }}
                          suffix={
                            !openDropDown.forMobile ? (
                              <PlusOutlined
                                onClick={() =>
                                  setOpenDropDown({
                                    ...openDropDown,
                                    forMobile: true,
                                  })
                                }
                              />
                            ) : (
                              <MinusOutlined
                                className="cursor grayColor"
                                onClick={() =>
                                  setOpenDropDown({
                                    ...openDropDown,
                                    forMobile: false,
                                  })
                                }
                              />
                            )
                          }
                        />
                      </Form.Item>
                      {openDropDown.forMobile && (
                        <Form.Item
                          name={["phone", "secondary"]}
                          style={{ marginTop: "10px" }}
                          className="inputBoxMb"
                        >
                          <Input
                            placeholder="0123456789"
                            prefix={<img src={phoneImg} alt="phone"></img>}
                            maxLength={10}
                            onKeyPress={(e) =>
                              !/[0-9]/.test(e.key) && e.preventDefault()
                            }
                            onChange={(e) => {
                              if (e.target.value) setTouched(true);
                              else setTouched(false);
                            }}
                            suffix={
                              openDropDown.forMobile && (
                                <MinusOutlined
                                  className="cursor grayColor"
                                  onClick={() =>
                                    setOpenDropDown({
                                      ...openDropDown,
                                      forMobile: false,
                                    })
                                  }
                                />
                              )
                            }
                          />
                        </Form.Item>
                      )}
                    </Input.Group>
                    {/* </Space> */}
                  </Form.Item>
                </Col>

                {/* --------- addMail --------- */}
                <Col span={12}>
                  <Form.Item name="email" className="inputBoxMb">
                    <Input.Group>
                      <Form.Item
                        name={["email", "primary"]}
                        style={{ marginTop: "10px" }}
                        className="inputBoxMb"
                        rules={[{ type: "email", message: "Hello " }]}
                      >
                        <Input
                          placeholder="email@domail.com"
                          prefix={<img src={emailImg} alt="email"></img>}
                          onChange={(e) => {
                            if (e.target.value) setTouched(true);
                            else setTouched(false);
                          }}
                          suffix={
                            !openDropDown.forEmail ? (
                              <PlusOutlined
                                className="cursor grayColor"
                                onClick={() =>
                                  setOpenDropDown({
                                    ...openDropDown,
                                    forEmail: true,
                                  })
                                }
                              />
                            ) : (
                              <MinusOutlined
                                className="cursor grayColor"
                                onClick={() =>
                                  setOpenDropDown({
                                    ...openDropDown,
                                    forEmail: false,
                                  })
                                }
                              />
                            )
                          }
                        />
                      </Form.Item>
                      {openDropDown.forEmail && (
                        <Form.Item
                          name={["email", "secondary"]}
                          style={{ marginTop: "10px" }}
                          className="inputBoxMb"
                        >
                          <Input
                            placeholder="email@domail.com"
                            prefix={<img src={emailImg} alt="email"></img>}
                            onChange={(e) => {
                              if (e.target.value) setTouched(true);
                              else setTouched(false);
                            }}
                            suffix={
                              <MinusOutlined
                                className="cursor grayColor"
                                onClick={() =>
                                  setOpenDropDown({
                                    ...openDropDown,
                                    forEmail: false,
                                  })
                                }
                              />
                            }
                          />
                        </Form.Item>
                      )}
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                {/* --------- addFax --------- */}
                {openDropDown.forFax && (
                  <Col span={12}>
                    <Form.Item
                      name="fax"
                      style={{ marginTop: "10px" }}
                      className="inputBoxMb"
                    >
                      <Input
                        placeholder="555-123-4567"
                        prefix={<PrinterOutlined className="grayColor" />}
                        onChange={(e) => {
                          if (e.target.value) setTouched(true);
                          else setTouched(false);
                        }}
                        suffix={
                          <MinusOutlined
                            className="cursor grayColor"
                            onClick={() =>
                              setOpenDropDown({
                                ...openDropDown,
                                forFax: false,
                              })
                            }
                          />
                          // )
                        }
                      />
                    </Form.Item>
                  </Col>
                )}

                {/*--------- addwebsite ---------*/}
                {openDropDown.forWebsite && (
                  <Col span={12}>
                    <Form.Item
                      name="website"
                      style={{ marginTop: "10px" }}
                      className="inputBoxMb"
                    >
                      <Input
                        placeholder="www.google.com"
                        prefix={<MinusCircleOutlined className="grayColor" />}
                        onChange={(e) => {
                          if (e.target.value) setTouched(true);
                          else setTouched(false);
                        }}
                        suffix={
                          <MinusOutlined
                            className="cursor grayColor"
                            onClick={() =>
                              setOpenDropDown({
                                ...openDropDown,
                                forWebsite: false,
                              })
                            }
                          />
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                className="dropDown-purchase"
                trigger={["click"]}
              >
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Button icon={<PlusOutlined className="grayColor" />}>
                    More
                    <DownOutlined className="grayColor" />
                  </Button>
                </div>
              </Dropdown>
            </Col>
            <Col span={10}>
              <span style={{ fontWeight: 600 }}>Billing Address</span>
              <Form.Item name="billingAddress">
                <AddressFields
                  formItemName="billingAddress"
                  address={Form.useWatch("billingAddress", form)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider
            style={{ backgroundColor: "#ddd7d7", margin: "13px 0px 15px 18px" }}
          />

          <Row justify="space-around">
            <Col span={12}>
              <p style={{ marginBottom: "10px", fontWeight: 600 }}>
                Bank Account Details
              </p>
              <Form.Item name="accDetails" className="inputBoxMb">
                <Input.Group>
                  <span style={{ color: "gray" }}>Account Name</span>
                  <Form.Item
                    name={["accDetails", "name"]}
                    className="inputBoxMb8"
                  >
                    <Input
                      onChange={(e) => {
                        if (e.target.value) setTouched(true);
                        else setTouched(false);
                      }}
                    />
                  </Form.Item>
                  <Row gutter={20}>
                    <Col span={12}>
                      <span style={{ color: "gray" }}>Sort Code</span>
                      <Form.Item
                        name={["accDetails", "sortCode"]}
                        className="inputBoxMb"
                      >
                        <Input
                          onChange={(e) => {
                            if (e.target.value) setTouched(true);
                            else setTouched(false);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <span style={{ color: "gray" }}>Account Number</span>
                      <Form.Item
                        name={["accDetails", "number"]}
                        className="inputBoxMb"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col span={10}>
              <p
                style={{
                  // paddingLeft: "5px",
                  marginBottom: "10px",
                  fontWeight: 600,
                }}
              >
                VAT Details
              </p>
              <Form.Item name="vat" className="inputBoxMb">
                <Input.Group>
                  <Row justify="space-between">
                    <Col span={11}>
                      <span style={{ color: "gray" }}>Vat number</span>
                      <Form.Item name="GB" labelCol={{ span: 24 }}>
                        <Select
                          onChange={(e) => {
                            setTouched(true);
                          }}
                          options={[
                            { value: "GB", label: "GB" },
                            { value: "XL", label: "Xl" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <span style={{ color: "gray" }}>EORI Number</span>
                      <Form.Item
                        name={["vat", "eoriNumber"]}
                        className="inputBoxMb"
                      >
                        <Input
                          onChange={(e) => {
                            if (e.target.value) setTouched(true);
                            else setTouched(false);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
              <p style={{ marginBottom: "15px", fontWeight: 600 }}>
                Payment Terms
              </p>
            </Col>
          </Row>
          <Divider
            style={{ backgroundColor: "#ddd7d7", margin: "26px 0px 15px 18px" }}
          />

          <Row justify="space-around">
            <Col>
              <span style={{ color: "gray" }}>Project tags</span>
              <Form.Item name="tagsName" className="inputBoxMb">
                <Input placeholder="Search" style={{ width: "188px" }} />
              </Form.Item>
            </Col>

            <Col span={17} style={{}}>
              <span style={{ color: "gray" }}>Notes</span>
              <Form.Item name="notes" className="inputBoxMb">
                <Input
                  suffix={<PaperClipOutlined />}
                  onChange={(e) => {
                    if (e.target.value) setTouched(true);
                    else setTouched(false);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>

      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          text="All details filled will be lost, are you sure you want to continue ?"
          onNo={onDismissConfirm}
          onYes={onDismissBothModels}
        />
      )}

      <ContactPersonModel
        open={openContactPerson}
        setOpen={setOpenContactPerson}
        setItem={setContactItem}
        values={contactItem}
      />
    </React.Fragment>
  );
};
export default AddPurchaseSupplier;
