import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Drawer, Image, Space, Spin, UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import React, { useState } from "react";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DocumentPreview: React.FC<{
  open: boolean;
  onCancel: any;
  documentDetails: any;
}> = (props) => {
  console.log("props", props);

  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("image");
  const [loading, setLoading] = useState<boolean>(false);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  React.useEffect(() => {
    setLoading(true);
    if (props.documentDetails.isBaseConvert) {
      const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewTitle(
          file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
        );
        setLoading(false);
      };
      handlePreview(props.documentDetails?.path);
    } else {
      setPreviewImage(props.documentDetails?.path?.path);
      setPreviewTitle(props.documentDetails?.path?.name);
      setLoading(false);
    }
  }, [props.documentDetails]);

  return (
    <Spin indicator={antIcon} spinning={loading}>
      <Drawer
        open={props.open}
        title={previewTitle}
        extra={
          <Space>
            <CloseOutlined onClick={() => props.onCancel({})} />
          </Space>
        }
        width={600}
        maskClosable={false}
        closable={false}
      >
        {props.documentDetails?.path?.type?.split("/")[0] === "image" ||
        props.documentDetails?.path?.contentType?.split("/")[0] === "image" ? (
          <Image alt={previewTitle} src={previewImage} />
        ) : (
          <iframe
            title="error"
            src={previewImage}
            width={"100%"}
            height={"100%"}
            style={{ border: "none" }}
          />
        )}
      </Drawer>
    </Spin>
  );
};
export default DocumentPreview;
