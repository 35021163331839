import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tooltip,
  Typography,
  notification,
} from "antd";
import React, { useContext, useEffect } from "react";
import { useUser } from "../../Context/UserContext";
import { EditOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons";
import { dayjs } from "../../Utilities/dayjs";
import ProfileService from "../../Services/ProfileService";
import adminContext from "../../Context/AdminContext";

const { Paragraph } = Typography;

const ProfileDetails = () => {
  const [form] = Form.useForm();
  const { getUser } = useUser();
  const loginUser: any = { ...getUser() };

  // const{userData,setUserData}=UserContext

  // const [userData, setUserData] = React.useState<any>();
  const { userData, getval, trigger, setTrigger, getUserdata } =
    useContext(adminContext);
  const [userhover, setUserHover] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  // const [trigger, setTrigger] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  type NotificationType = "success" | "info" | "warning" | "error";
  const showModal = () => {
    setIsModalOpen(true);

    //  *********** ----it will show prefill values in form ------************* //

    form.setFieldsValue({ ...getval });
  };
  const [messageApi, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type: NotificationType) => {
    messageApi[type]({
      message: " Profile Details Updated Successfully !",
      description: "",
    });
  };
  const error = (mes: any) => {
    messageApi.error(mes);
  };

  useEffect(() => {
    getUserdata();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const handleOk = () => {
    form.validateFields().then((val: any) => {
      //  ************** --------it will post values of form ----------************* //
      setLoading(true);
      let details = {
        ...val,

        gender: userData?.userDetails?.gender,
        phone: userData?.userDetails?.phone,
        birthDate: dayjs(userData?.userDetails?.birthDate).format("DD/MM/YYYY"),
      };
      ProfileService.editUserProfile(loginUser?.id, details).then(
        (res: any) => {
          if (res?.result === true) {
            setTrigger((x: any) => !x);
            setIsModalOpen(false);
            openNotificationWithIcon("success");

            form.resetFields();
            console.log("user details response", details);
            setLoading(false);
          } else {
            error(res?.message);
            setLoading(false);
            form.resetFields();
          }
        }
      );
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const Showicons = () => {
    return (
      <>
        <EditOutlined onClick={showModal} />
      </>
    );
  };
  const text = <span>Read Only</span>;

  return (
    <>
      <Card
        onMouseOver={() => setUserHover(true)}
        onMouseOut={() => {
          setUserHover(false);
        }}
        title={
          <div>
            <UserOutlined />{" "}
            {userData?.name?.first + " " + userData?.name?.last}{" "}
            {userhover ? <Showicons /> : null}
          </div>
        }
        style={{
          border: "2.5px solid whitesmoke",
          backgroundColor: "rgb(247, 248, 250)",
        }}
      >
        <Modal
          width={680}
          okText={
            <>
              {loading ? (
                <Spin style={{}} spinning={loading}></Spin>
              ) : (
                <>
                  <SaveOutlined />
                  Save
                </>
              )}
            </>
          }
          title={
            <>
              <Typography.Title level={4}>
                <UserOutlined /> Edit Profile
              </Typography.Title>
              <div style={{ borderTop: "3px solid rgb(61, 73, 152)" }}></div>
            </>
          }
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {contextHolder}
          <Form
            form={form}
            requiredMark={false}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 10 }}
            labelAlign="left"
            colon={false}
          >
            <Row gutter={25} style={{}}>
              <Col lg={24}>
                <Divider orientation="left">
                  <Typography.Title style={{ color: "gray" }} level={5}>
                    Details
                  </Typography.Title>
                </Divider>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["aName", "first"]}
                  label="First Name"
                  rules={[{ required: true, message: " Can't be empty" }]}
                >
                  <Input
                    style={{ width: "185px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["aName", "last"]}
                  label="Last Name"
                  rules={[{ required: true, message: " Can't be empty" }]}
                >
                  <Input
                    style={{ width: "185px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["userDetails", "gender"]}
                  label="Gender"
                  rules={[{ required: true, message: "Can't be empty" }]}
                >
                  <Input
                    style={{ width: "185px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["userDetails", "birthDate"]}
                  label="Date of Birth"
                  rules={[{ required: true, message: " Can't be empty" }]}
                >
                  <DatePicker
                    style={{ width: "185px", backgroundColor: "whitesmoke" }}
                    format="DD/MM/YYYY"
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Tooltip placement="bottom" title={text}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: " Can't be empty" }]}
                  >
                    <Input
                      readOnly
                      style={{ width: "185px", backgroundColor: "whitesmoke" }}
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["userDetails", "phone"]}
                  label="Phone"
                  rules={[{ required: true, message: "Can't be empty" }]}
                >
                  <Input
                    style={{ width: "180px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={25}>
              <Col lg={24}>
                <Divider orientation="left">
                  <Typography.Title style={{ color: "gray" }} level={5}>
                    Address
                  </Typography.Title>
                </Divider>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["aAddress", "building"]}
                  label="Building"
                  rules={[{ required: true, message: " Can't be empty" }]}
                >
                  <Input
                    style={{ width: "180px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["aAddress", "street"]}
                  label="Street"
                  rules={[{ required: true, message: "Can't be empty" }]}
                >
                  <Input
                    style={{ width: "180px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["aAddress", "city"]}
                  label="City"
                  rules={[{ required: true, message: " Can't be empty" }]}
                >
                  <Input
                    style={{ width: "180px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["aAddress", "country"]}
                  label="Country"
                  rules={[{ required: true, message: " Can't be empty" }]}
                >
                  <Input
                    style={{ width: "180px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  name={["aAddress", "pincode"]}
                  label="PinCode"
                  rules={[{ required: true, message: "Can't be empty" }]}
                >
                  <Input
                    style={{ width: "180px", backgroundColor: "whitesmoke" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Row gutter={[18, 17]}>
          <Col lg={12}>
            <Typography.Title style={{ fontSize: "15px" }} level={5}>
              First Name
            </Typography.Title>
            <Paragraph>{userData?.name?.first}</Paragraph>
          </Col>

          <Col lg={12}>
            <Typography.Title style={{ fontSize: "15px" }} level={5}>
              Last Name
            </Typography.Title>
            <Paragraph>{userData?.name?.last}</Paragraph>
          </Col>

          <Col lg={12}>
            <Typography.Title style={{ fontSize: "15px" }} level={5}>
              Gender
            </Typography.Title>
            <Paragraph> {userData?.userDetails?.gender}</Paragraph>
          </Col>
          <Col lg={12}>
            <Typography.Title style={{ fontSize: "15px" }} level={5}>
              Email
            </Typography.Title>
            <Paragraph> {userData?.email}</Paragraph>
          </Col>
          <Col lg={12}>
            <Typography.Title style={{ fontSize: "15px" }} level={5}>
              Role
            </Typography.Title>
            <Paragraph> {userData?.role}</Paragraph>
          </Col>
          <Col lg={12}>
            <Typography.Title style={{ fontSize: "15px" }} level={5}>
              Address
            </Typography.Title>
            <Paragraph>
              {" "}
              {userData?.aAddress?.building},{userData?.aAddress?.street},
              {userData?.aAddress?.city},{userData?.aAddress?.pincode},
              {userData?.aAddress?.country}
            </Paragraph>
          </Col>

          {/* <h1>{loginUser?.aAddress?.building}</h1> */}
        </Row>
      </Card>
    </>
  );
};

export default ProfileDetails;
