import React, { useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  SelectProps,
  Modal,
  Form,
  Divider,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import "../../Common/AddPageCommon.css";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
  icon: string;
  icon2: any;
  icon3: string;
  type: string;
  class: string;
  date: string;
  shares: string;
  nominalvalue: string;
  totalshares: string;
}
const options: SelectProps["options"] = [
  { label: "All", value: "1" },
  { label: "Equity", value: "2" },
  { label: "Preference", value: "3" },
];

const classoptions: SelectProps["options"] = [
  { label: "All", value: "1" },
  { label: "Ordinary", value: "2" },
  { label: "Deferred Ordinary", value: "3" },
  { label: "Redeemable", value: "4" },
  { label: "A Ordinary", value: "5" },
  { label: "B Ordinary", value: "6" },
  { label: "C Ordinary", value: "7" },
  { label: "Deferred", value: "8" },
  { label: "A Preference", value: "9" },
  { label: "B Preference", value: "10" },
];

const statusoptions: SelectProps["options"] = [
  { label: "All", value: "1" },
  { label: "Deleted", value: "2" },
];

const TypeOptions: SelectProps["options"] = [
  { label: "Equity", value: "Equity" },
  { label: "Preference", value: "Preference" },
];

const ClassOptions: SelectProps["options"] = [
  { label: "Ordinary", value: "Ordinary" },
  { label: "Deferred Ordinary", value: "Deferred Ordinary" },
  { label: "Redeemable", value: "Redeemable" },
  { label: "A Ordinary", value: "A Ordinary" },
  { label: "B Ordinary", value: "B Ordinary" },
  { label: "C Ordinary", value: "C Ordinary" },
];

const columns: ColumnsType<DataType> = [
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Class",
    dataIndex: "class",
  },
  {
    title: "Issue Date",
    dataIndex: "date",
  },
  {
    title: "No. of Shares",
    dataIndex: "shares",
  },
  {
    title: "Nominal Value",
    dataIndex: "nominalvalue",
  },
  {
    title: "Total Share Capital",
    dataIndex: "nominalvalue",
  },

  {
    title: "",
    dataIndex: "icon",
    render: (_, record) => (
      <div className="cursor mx-1">
        <EditOutlined className="cursor-pointer" />
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "icon2",
    render: (_, record) => (
      <div className="cursor mx-1">
        <DeleteOutlined color="red" className="cursor-pointer" />
      </div>
    ),
  },
];
const data: DataType[] = [];
for (let i = 0; i < 3; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `Capsitech. ${i}`,
    icon: `gp`,
    icon2: <></>,
    icon3: `gp`,
    type: `Equity`,
    class: `A Ordinary`,
    date: `03/03/2023`,
    shares: `1`,
    nominalvalue: `15`,
    totalshares: `15`,
  });
}
const ShareCapital1: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.validateFields().then((val) => {
      console.log(val);
      form.resetFields();
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [newState, setNewState] = useState({
    valuefield1: 0,
    valuefield2: 0,
    multival: 0,
  });

  const valuestore = (e: any) => {
    let obj = {
      valuefield1: e.target.value,
      valuefield2: newState.valuefield2,
      multival: e.target.value * newState.valuefield2,
    };
    setNewState({ ...obj });

    form.setFieldValue("totalsharecapital", obj.multival);
  };

  const valuestore2 = (e: any) => {
    let obj = {
      valuefield1: newState.valuefield1,
      valuefield2: e.target.value,
      multival: e.target.value * newState.valuefield1,
    };
    setNewState({ ...obj });
    form.setFieldValue("totalsharecapital", obj.multival);
  };
  const [form] = Form.useForm();
  return (
    <div>
      <Row>
        <Col lg={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
            }}
          >
            <div>
              <Space size={5}>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#3D4998" }}
                  icon={<PlusOutlined />}
                  onClick={showModal}
                >
                  Share Capital
                </Button>
              </Space>
            </div>

            <Modal
              title="Add Share Capital"
              open={isModalOpen}
              maskClosable={false}
              footer={false}
              closable={false}
            >
              <Form
                form={form}
                initialValues={{ remember: true }}
                onFinish={handleOk}
                autoComplete="off"
              >
                <Row
                  justify={"space-between"}
                  style={{ color: "lightslategray" }}
                >
                  <Col lg={6}>
                    <p>Type</p>
                    <Form.Item name="type">
                      <Select options={TypeOptions} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <p>Class</p>
                    <Form.Item name="class">
                      <Select
                        options={ClassOptions}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <p>Issue Date</p>
                    <Form.Item name="issuedate">
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={(date: any) =>
                          console.log(date.format("DD-MMM-YYYY"))
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  justify={"space-between"}
                  style={{ color: "lightslategray", marginTop: "10px" }}
                >
                  <Col lg={6}>
                    <p>Number of shares</p>
                    <Form.Item
                      name="number of shares"
                      rules={[{ required: true, message: "Value can't be 0" }]}
                    >
                      <Input
                        placeholder="0"
                        onChange={valuestore}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={6}>
                    {" "}
                    <p>Nominal value</p>
                    <Form.Item
                      name="nominal value"
                      rules={[{ required: true, message: "Value can't be 0" }]}
                    >
                      <Input
                        placeholder="&#x20B9;0.00"
                        style={{ width: "100%" }}
                        onChange={valuestore2}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={6}>
                    {" "}
                    <p>Total share capital</p>
                    <Form.Item name={"totalsharecapital"}>
                      <Input
                        value={newState.multival}
                        placeholder="&#x20B9;0.00"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Form.Item>
                    <Button
                      style={{ marginRight: "9px" }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ backgroundColor: "rgb(61, 73, 152)" }}
                    >
                      <SaveOutlined /> Save
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Modal>

            <div style={{ display: "flex", justifyContent: "" }}>
              <Space>
                <div>
                  <span style={{ paddingRight: "2px" }}>Type</span>
                  <Select
                    options={options}
                    style={{ width: "120px", textAlign: "center" }}
                  ></Select>
                </div>
                <div>
                  <span style={{ paddingRight: "2px" }}>Class</span>
                  <Select
                    options={classoptions}
                    style={{ width: "120px", textAlign: "center" }}
                  ></Select>
                </div>

                <div>
                  <span style={{ paddingRight: "2px" }}>Status</span>
                  <Select
                    options={statusoptions}
                    style={{ width: "120px", textAlign: "center" }}
                  ></Select>
                </div>
              </Space>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="custom"
        size="small"
        columns={columns}
        dataSource={data}
        style={{ overflowX: "auto" }}
        pagination={false}
      />
    </div>
  );
};
export default ShareCapital1;
