import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import Papa from "papaparse";
import { InvoiceDataType } from "../Pages/Sales/SalesInvoiceTable";
import { Footer } from "antd/es/layout/layout";
import { Utils } from "../../Utilities/Utils";

interface SalesInvoice {
  InvoiceAmount: string | number;
}
type PageOrientation = "portrait" | "landscape" | "p" | "l";
const amountColumns = [
  "Invoice Amount",
  "Credit Note",
  "Received",
  "Due Amount",
  "Amount",
  "Adjust Amount",
  "Pending Amount",
  "Total Qty",
  "Supplied Qty",
  "Pending Qty",
  "Balance",
  "Paid",
  "Debit",
  "Credit",
  "Pur. Price",
  "Sales Price",
  "Central Tax",
  "Integrated Tax",
  "State/UT Tax",
  "Invoice Value",
  "Taxable Value",
  "Opening Balance",
  "Closing Balance",
  "Credit Amount",
  "Debit Amount",
  "Central Tax",
];
class InvoiceExportDetails {
  // Method to generate PDF
  static generatePDF(
    invoiceListData: any[],
    currentClient: any,
    columns: any[],
    fieldMapping: any,
    generateFooterRow: any,
    filterDate?: any,
    path?: string,
    oneMoreFooter?: any,
    type?: number,
    pageOrientation: PageOrientation = "portrait"
  ) {
    console.log("props data", invoiceListData, columns, fieldMapping);

    const doc: any = new jsPDF(pageOrientation, "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.setFontSize(16);
    const clientTextWidth = doc.getTextWidth(currentClient);
    doc.text(currentClient, (pageWidth - clientTextWidth) / 2, 16);
    doc.setFontSize(10);
    const dateText =
      filterDate && (filterDate.from || filterDate.to)
        ? `From: ${filterDate.from || ""} To: ${filterDate.to || ""}`
        : "";
    const dateTextWidth = doc.getTextWidth(dateText);
    doc.setFontSize(12);
    const pathTextWidth = doc.getTextWidth(path);
    doc.text(path, (pageWidth - pathTextWidth) / 2, 40);
    doc.text(dateText, (pageWidth - dateTextWidth) / 2, 24);
    let colsName: any = [];

    const startY = 50;

    // Handling mixed column definitions (strings and objects)
    const tableHead =
      type === 1
        ? columns
        : [
            columns.map((col: any) => {
              if (typeof col === "object" && col.content) {
                return {
                  ...col,
                  styles: amountColumns.includes(col.content)
                    ? { halign: "right" }
                    : {},
                };
              } else {
                return {
                  content: col,
                  styles: amountColumns.includes(col)
                    ? { halign: "right" }
                    : {},
                }; // For string columns
              }
            }),
          ];

    if (type === 1) {
      let colIndex = 1;
      let selIndex = 0;
      columns[0].forEach((col: any, index: number) => {
        if (col.colSpan) {
          let currIndex = 0;
          for (
            let colSpanNumbers = col.colSpan;
            colSpanNumbers !== 0;
            colSpanNumbers--
          ) {
            colsName.push(
              columns[1][selIndex > 0 ? selIndex + currIndex : currIndex]
                ?.content
            );
            currIndex++;
          }
          selIndex += currIndex;
          colIndex += col.colSpan - 1;
        } else {
          colsName.push(col.content);
        }
        colIndex++;
      });
    } else {
      colsName = columns;
    }

    // let textcols = Object.keys(fieldMapping);

    const tableBody = invoiceListData?.map((item) =>
      colsName?.map((col: any) => {
        const columnKey = typeof col === "object" ? col.content : col;

        const value = fieldMapping[columnKey]
          ? fieldMapping[columnKey](item)
          : "";

        // console.log(
        //   "value",
        //   fieldMapping[columnKey],
        //   "fieldMapping[columnKey](item),1111111111111111111111111111111111111111",
        //   fieldMapping[columnKey](item),
        //   "value222222222222222222222222222222222222222222",
        //   value
        // );

        const isAmountColumn = amountColumns.includes(columnKey);

        return isAmountColumn
          ? {
              content: value,
              // styles: { halign: "right", cellPadding: { left: 30 } },
              styles: { halign: "right" },
            }
          : value;
      })
    );

    // type === 1
    //   ? invoiceListData?.map((item) =>
    //       colsName?.map((col: any) => {
    //         const columnKey = typeof col === "object" ? col.content : col;
    //         const value = fieldMapping[columnKey]
    //           ? fieldMapping[columnKey](item)
    //           : "";
    //         const isAmountColumn = amountColumns.includes(columnKey);

    //         return isAmountColumn
    //           ? {
    //               content: value,
    //               // styles: { halign: "right", cellPadding: { left: 30 } },
    //               styles: { halign: "right" },
    //             }
    //           : value;
    //       })
    //     )
    //   : invoiceListData?.map((item) =>
    //       colsName?.map((col: any) => {
    //         // let test = typeof col;
    //         const columnKey = typeof col === "object" ? col.content : col;
    //         const value = fieldMapping[columnKey]
    //           ? fieldMapping[columnKey](item)
    //           : "";
    //         const isAmountColumn = amountColumns.includes(columnKey);

    //         return isAmountColumn
    //           ? { content: value, styles: { halign: "right" } }
    //           : value;
    //       })
    //     );
    // const tableBody = invoiceListData.map((item) =>
    //   textcols.map((col: any) => {
    //     // debugger;
    //     // Handle both object and string column types
    //     let test = typeof col;
    //     const columnKey = typeof col === "object" ? col.content : col;
    //     const value = fieldMapping[columnKey]
    //       ? fieldMapping[columnKey](item)
    //       : "";
    //     const isAmountColumn = amountColumns.includes(columnKey);

    //     return isAmountColumn
    //       ? { content: value, styles: { halign: "right" } }
    //       : value;
    //   })
    // );

    // const tableBody = invoiceListData.map((item) =>
    //   columns.map((col: any) => {
    //     // debugger;
    //     // Handle both object and string column types
    //     let test = typeof col;
    //     const columnKey = typeof col === "object" ? col.content : col;
    //     const value = fieldMapping[columnKey]
    //       ? fieldMapping[columnKey](item)
    //       : "";
    //     const isAmountColumn = amountColumns.includes(columnKey);

    //     return isAmountColumn
    //       ? { content: value, styles: { halign: "right" } }
    //       : value;
    //   })
    // );

    // Footer Row Generation
    const footerRow = generateFooterRow(colsName).map(
      (cell: any, index: any) => {
        const column = columns[index];
        const columnName = typeof column === "object" ? column.content : column;
        const isAmountColumn = amountColumns.includes(columnName);
        const value = cell && typeof cell !== "object" ? cell : ""; // Avoid 'object Object'

        return isAmountColumn
          ? {
              content: value,
              styles: {
                halign: "right",
                fontStyle: "bold",
                fillColor: "#789fca",
                textColor: "white",
              },
            }
          : {
              content: value,
              styles: {
                fontStyle: "bold",
                fillColor: "#789fca",
                textColor: "white",
              },
            };
      }
    );

    tableBody.push(footerRow);

    // Additional Footer Row if exists
    if (typeof oneMoreFooter === "function") {
      const additionalFooterRow = oneMoreFooter(columns).map(
        (cell: any, index: any) => {
          const column = columns[index];
          const columnName =
            typeof column === "object" ? column.content : column;
          const isAmountColumn = amountColumns.includes(columnName);
          const value = cell && typeof cell !== "object" ? cell : ""; // Avoid 'object Object'

          return isAmountColumn
            ? { content: value, styles: { halign: "right", fontStyle: "bold" } }
            : { content: value, styles: { fontStyle: "bold" } };
        }
      );
      tableBody.push(additionalFooterRow);
    }
    // Generate the table with headers and body
    doc.autoTable({
      startY: startY,
      head: tableHead,
      body: tableBody,
      columnStyles: columns.reduce((styles: any, col: any) => {
        const columnName = typeof col === "object" ? col.content : col;
        if (amountColumns.includes(columnName)) {
          styles[columns.indexOf(col)] = { halign: "right" };
        }
        return styles;
      }, {}),
      styles: {
        fontSize: 10,
      },
      didDrawCell: (data: any) => {
        if (data.section === "footer") {
          doc.setFillColor(200, 200, 200);
          doc.setTextColor(255, 255, 255);
        }
      },
    });

    doc.save(`${currentClient}_${path}.pdf`);
  }

  static generateCSV(
    invoiceListData: any[],
    columns: any,
    fieldMapping: any,
    generateFooterRow: any,
    oneMoreFooter?: any,
    filterDate?: any,
    title?: any,
    companyName?: any,
    type?: number
  ) {
    try {
      const csvData = [];
      const addLeftAlignedRow = (text: string) => {
        if (text) {
          const leftAlignedRow = [text].concat(
            Array(columns?.length - 1).fill("")
          );
          csvData.push(leftAlignedRow);
        }
      };

      if (companyName) addLeftAlignedRow(companyName);
      if (filterDate && (filterDate.from || filterDate.to)) {
        addLeftAlignedRow(
          `From: ${filterDate?.from || ""} To: ${filterDate?.to || ""}`
        );
      }

      if (title) addLeftAlignedRow(title);
      csvData?.push(columns);

      // Add invoice data with formatting
      csvData?.push(
        ...invoiceListData?.map((item: any) =>
          columns?.map((col: any) => {
            const value = fieldMapping[col] ? fieldMapping[col](item) : "";
            return amountColumns?.includes(col)
              ? value // Adjust padding as needed
              : value;
          })
        )
      );

      // Add footer rows if available
      if (typeof generateFooterRow === "function") {
        const footerRow = generateFooterRow(columns).map(
          (cell: any, index: any) => {
            const column = columns[index];
            return amountColumns.includes(column)
              ? cell
                ? cell.padStart(20, " ")
                : "" // Adjust padding as needed
              : cell;
          }
        );
        if (footerRow) {
          csvData.push(footerRow);
        }
      }
      if (typeof oneMoreFooter === "function") {
        const addRow = oneMoreFooter(columns).map((cell: any, index: any) => {
          const column = columns[index];
          return amountColumns.includes(column)
            ? cell
              ? cell.padStart(20, " ")
              : "" // Adjust padding as needed
            : cell;
        });
        if (addRow) {
          csvData.push(addRow);
        }
      }

      const csv = Papa.unparse(csvData);
      const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(csvBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${companyName}_${title}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      console.log("CSV file created successfully");
    } catch (error) {
      console.error("Error generating CSV file:", error);
    }
  }

  static async generateExcel(
    invoiceListData: any[],
    currentClient: any,
    columns: any[],
    fieldMapping: any,
    generateFooterRow: any,
    filterDate?: any,
    path?: any,
    oneMoreFooter?: any,
    type?: number
  ) {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Invoice Data");
      let rowIndex = 4;
      let colIndex = 1;
      let totColumns = 0;
      let colsName: any = [];
      //To get totalColumns
      if (type === 1) {
        columns[0].forEach((col: any, index: number) => {
          if (col.rowSpan) {
            totColumns += 1;
          }
          if (col.colSpan) {
            totColumns += col.colSpan;
          }
        });
      } else {
        totColumns = columns.length;
      }

      // Title and other headers
      worksheet.mergeCells(1, 1, 1, totColumns);
      const titleCell = worksheet.getCell(1, 1);
      titleCell.value = `${currentClient}`;
      titleCell.alignment = { horizontal: "center", vertical: "middle" };
      titleCell.font = { size: 16, bold: true };

      // Period
      worksheet.mergeCells(2, 1, 2, totColumns);
      const companyNameCell = worksheet.getCell(2, 1);
      companyNameCell.value =
        filterDate && (filterDate.from || filterDate.to)
          ? `From: ${filterDate.from || ""} To: ${filterDate.to || ""}`
          : "";
      companyNameCell.alignment = { horizontal: "center", vertical: "middle" };
      companyNameCell.font = { size: 12 };

      //Report title
      worksheet.mergeCells(3, 1, 3, totColumns);
      const pathCell = worksheet.getCell(3, 1);
      pathCell.value = `${path}`;
      pathCell.alignment = { horizontal: "center", vertical: "middle" };
      pathCell.font = { size: 12 };

      //Data Table
      if (type === 1) {
        // Add the first row for merged headers
        columns[0].forEach((col: any, index: number) => {
          const cell = worksheet.getCell(rowIndex, colIndex);
          cell.value = col.content;
          if (col.rowSpan) {
            worksheet.mergeCells(
              rowIndex,
              colIndex,
              rowIndex + col.rowSpan - 1,
              colIndex
            );
            cell.alignment = { vertical: "middle" };
            totColumns += 1;
          }
          if (col.colSpan) {
            worksheet.mergeCells(
              rowIndex,
              colIndex,
              rowIndex,
              colIndex + col.colSpan - 1
            );
            colIndex += col.colSpan - 1;
            totColumns += col.colSpan;
            cell.alignment = { horizontal: "center", vertical: "middle" };
          }
          colIndex++;

          if (amountColumns.includes(col.content)) {
            cell.alignment = { horizontal: "right", vertical: "middle" };
          }
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "278a59" },
          };
          cell.font = {
            color: { argb: "ffffff" }, // Text color (white in this case)
            bold: true,
          };
        });

        // Add the second row for detailed headers
        rowIndex++;
        colIndex = 1;
        let selIndex = 0;

        columns[0].forEach((col: any, index: number) => {
          if (col.colSpan) {
            let currIndex = 0;
            for (
              let colSpanNumbers = col.colSpan;
              colSpanNumbers !== 0;
              colSpanNumbers--
            ) {
              const cell = worksheet.getCell(rowIndex, colIndex + currIndex);
              cell.value =
                columns[1][
                  selIndex > 0 ? selIndex + currIndex : currIndex
                ]?.content;
              colsName.push(
                columns[1][selIndex > 0 ? selIndex + currIndex : currIndex]
                  ?.content
              );
              currIndex++;
              if (
                amountColumns.includes(
                  columns[1][selIndex > 0 ? selIndex + currIndex : currIndex]
                    ?.content
                )
              ) {
                cell.alignment = {
                  horizontal: "right",
                  vertical: "middle",
                };
              } else {
                cell.alignment = {
                  horizontal: "left",
                  vertical: "middle",
                };
              }
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "278a59" },
              };
              cell.font = {
                color: { argb: "ffffff" }, // Text color (white in this case)
                bold: true,
              };
            }
            selIndex += currIndex;
            colIndex += col.colSpan - 1;
          } else {
            colsName.push(col.content);
          }
          colIndex++;
        });

        // let textcols = Object.keys(fieldMapping);
        // Adding rows of invoice data
        // invoiceListData?.forEach((item) => {
        //   const row: any = worksheet?.addRow(
        //     colsName?.map((col: any) =>
        //       fieldMapping[col] ? fieldMapping[col](item) : ""
        //     )
        //   );

        //   row.eachCell({ includeEmpty: true }, (cell: any, colNumber: any) => {
        //     const columnName = colsName[colNumber - 1];
        //     if (amountColumns.includes(columnName)) {
        //       cell.alignment = { horizontal: "right" };
        //     } else {
        //       cell.alignment = { horizontal: "left" };
        //     }
        //   });
        // });
      } else {
        colsName = columns;
        // Add the first row for merged headers
        colsName.forEach((col: any, index: number) => {
          const cell = worksheet.getCell(rowIndex, index + 1);
          cell.value = col;
          if (amountColumns.includes(col)) {
            cell.alignment = { horizontal: "right", vertical: "middle" };
          }
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "278a59" },
          };
          cell.font = {
            color: { argb: "ffffff" }, // Text color (white in this case)
            bold: true,
          };
        });
      }

      // let textcols = Object.keys(fieldMapping);
      // Adding rows of invoice data
      invoiceListData?.forEach((item) => {
        const row: any = worksheet?.addRow(
          colsName?.map((col: any) =>
            fieldMapping[col] ? fieldMapping[col](item) : ""
          )
        );

        row.eachCell({ includeEmpty: true }, (cell: any, colNumber: any) => {
          const columnName = colsName[colNumber - 1];
          if (amountColumns.includes(columnName)) {
            cell.alignment = { horizontal: "right" };
          } else {
            cell.alignment = { horizontal: "left" };
          }
        });
      });

      if (typeof generateFooterRow === "function") {
        const footerRowValues = generateFooterRow(colsName);

        const footerRow: any = worksheet?.addRow(footerRowValues);
        footerRow.eachCell({ includeEmpty: true }, (cell: any) => {
          cell.alignment = { horizontal: "right", vertical: "middle" };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "63b68d" }, // Background color (e.g., gold)
          };
          cell.font = {
            color: { argb: "ffffff" }, // Text color (e.g., red)
            bold: true, // Optional: make text bold
          };
        });
      }

      if (typeof oneMoreFooter === "function") {
        const additionalFooterRowValues = oneMoreFooter(colsName);
        const additionalFooterRow: any = worksheet?.addRow(
          additionalFooterRowValues
        );
        additionalFooterRow.eachCell({ includeEmpty: true }, (cell: any) => {
          cell.alignment = { horizontal: "right", vertical: "middle" };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "63b68d" }, // Background color (e.g., gold)
          };
          cell.font = {
            color: { argb: "ffffff" }, // Text color (e.g., red)
            bold: true, // Optional: make text bold
          };
        });
      }

      // // Adding columns and setting column widths
      // const headerRow: any = worksheet?.addRow(columns);
      // worksheet.columns = columns?.map(() => ({ width: 22 }));
      // headerRow.font = { bold: true };

      // headerRow.eachCell(
      //   { includeEmpty: true },
      //   (cell: any, colNumber: any) => {
      //     const columnName = columns[colNumber - 1];
      //     if (amountColumns.includes(columnName)) {
      //       cell.alignment = { horizontal: "right", vertical: "middle" };
      //     } else {
      //       cell.alignment = { horizontal: "left", vertical: "middle" };
      //     }
      //     cell.fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "278a59" },
      //     };
      //     cell.font = {
      //       color: { argb: "ffffff" }, // Text color (white in this case)
      //       bold: true,
      //     };
      //   }
      // );

      // // Adding rows of invoice data
      // invoiceListData?.forEach((item) => {
      //   const row: any = worksheet?.addRow(
      //     columns?.map((col: any) =>
      //       fieldMapping[col] ? fieldMapping[col](item) : ""
      //     )
      //   );

      //   row.eachCell({ includeEmpty: true }, (cell: any, colNumber: any) => {
      //     const columnName = columns[colNumber - 1];
      //     if (amountColumns.includes(columnName)) {
      //       cell.alignment = { horizontal: "right" };
      //     } else {
      //       cell.alignment = { horizontal: "left" };
      //     }
      //   });
      // });

      // if (typeof generateFooterRow === "function") {
      //   const footerRowValues = generateFooterRow(columns);
      //   const footerRow: any = worksheet?.addRow(footerRowValues);
      //   footerRow.eachCell({ includeEmpty: true }, (cell: any) => {
      //     cell.alignment = { horizontal: "right", vertical: "middle" };
      //     cell.fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "63b68d" }, // Background color (e.g., gold)
      //     };
      //     cell.font = {
      //       color: { argb: "ffffff" }, // Text color (e.g., red)
      //       bold: true, // Optional: make text bold
      //     };
      //   });
      // }

      // if (typeof oneMoreFooter === "function") {
      //   const additionalFooterRowValues = oneMoreFooter(columns);
      //   const additionalFooterRow: any = worksheet?.addRow(
      //     additionalFooterRowValues
      //   );
      //   additionalFooterRow.eachCell({ includeEmpty: true }, (cell: any) => {
      //     cell.alignment = { horizontal: "right", vertical: "middle" };
      //     cell.fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "63b68d" }, // Background color (e.g., gold)
      //     };
      //     cell.font = {
      //       color: { argb: "ffffff" }, // Text color (e.g., red)
      //       bold: true, // Optional: make text bold
      //     };
      //   });
      // }

      // Write to buffer and save file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "invoice_data_export.xlsx";
      link.click();
      window.URL.revokeObjectURL(url);
      console.log("Excel file created successfully");
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  }
}

export default InvoiceExportDetails;

// Function to generate the PDF
export const generateProfitAndLossPDF = (
  data: any,
  currentClient: any,
  filterDate: any,
  path: string
) => {
  const doc: any = new jsPDF();

  // Document title
  doc.setFontSize(16);
  const pageWidth = doc.internal.pageSize.getWidth();

  // Centered client name
  const clientTextWidth = doc.getTextWidth(currentClient);
  doc.setFontSize(14);
  doc.text(currentClient, (pageWidth - clientTextWidth) / 2, 20);

  // Display filter date range if available
  doc.setFontSize(12);
  const dateText =
    filterDate && (filterDate.from || filterDate.to)
      ? `From: ${filterDate.from || ""} To: ${filterDate.to || ""}`
      : "";
  if (dateText) {
    const dateTextWidth = doc.getTextWidth(dateText);
    doc.text(dateText, (pageWidth - dateTextWidth) / 2, 30); // Centered
  }

  // Display path if available
  if (path) {
    const pathTextWidth = doc.getTextWidth(path);
    doc.text(path, (pageWidth - pathTextWidth) / 2, 40); // Centered
  }

  // Helper function to add rows to the table
  const addRows = (reco: any, paddingLeft: number = 0) => {
    const rows = [];

    if (reco?.name !== "" && reco?.value !== "") {
      rows.push([
        {
          content: reco?.name,
          styles: {
            halign: "left",
            fontStyle: "bold",
            fillColor: "#f2f8fc",
            textColor: "#543043",
            fontSize: 11,
            cellPadding: { left: paddingLeft },
          },
        },
        {
          content:
            reco?.value !== 0 ? Utils.getFormattedNumber(reco?.value) : "",
          styles: {
            halign: "right",
            fontStyle: "bold",
            fillColor: "#f2f8fc",
            textColor: "#543043",
            fontSize: 11,
          },
        },
      ]);
    }

    if (reco?.children) {
      reco?.children?.forEach((creco: any) => {
        rows.push([
          {
            content: creco?.name,
            styles: {
              halign: "left",
              fillColor: "#ffffff",
              textColor: "#193e5e",
              fontSize: 10,
              cellPadding: { left: paddingLeft + 5 },
            },
          },
          {
            content: Utils.getFormattedNumber(creco?.value),
            styles: {
              halign: "right",
              fillColor: "#ffffff",
              textColor: "#193e5e",
              fontSize: 10,
            },
          },
        ]);

        if (creco?.children) {
          creco?.children?.forEach((greco: any) => {
            rows.push([
              {
                content: greco?.name,
                styles: {
                  halign: "left",
                  fillColor: "#ffffff",
                  textColor: "#000000",
                  cellPadding: { left: paddingLeft + 10 },
                },
              },
              {
                content: Utils.getFormattedNumber(greco?.value),
                styles: {
                  halign: "right",
                  fillColor: "#ffffff",
                  textColor: "#000000",
                },
              },
            ]);
          });
        }
      });
    }

    return rows;
  };

  let finalRows: any[] = [];
  data.forEach((reco: any) => {
    finalRows = [...finalRows, ...addRows(reco)];
  });

  doc.autoTable({
    head: [["Particulars", "Amount"]],
    body: finalRows,
    theme: "striped",
    startY: 50,
  });
  doc.save("profit-and-loss-report.pdf");
};

export const generateProfitAndLossExcel = async (
  data: any,
  currentClient: any,
  filterDate: any,
  path: string
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Profit and Loss");

  const totColumns = 2; // Total number of columns (e.g., "Name" and "Value")

  // Merge and set title (currentClient) at the top, centered
  worksheet.mergeCells(1, 1, 1, totColumns);
  const titleCell = worksheet.getCell(1, 1);
  titleCell.value = `${currentClient}`; // Use name or fallback to the value
  titleCell.alignment = { horizontal: "center", vertical: "middle" };
  titleCell.font = { size: 16, bold: true };

  // Merge and set filter period (From - To) in the second row, centered
  worksheet.mergeCells(2, 1, 2, totColumns);
  const periodCell = worksheet.getCell(2, 1);
  periodCell.value =
    filterDate && (filterDate.from || filterDate.to)
      ? `From: ${filterDate.from || ""} To: ${filterDate.to || ""}`
      : "";
  periodCell.alignment = { horizontal: "center", vertical: "middle" };
  periodCell.font = { size: 12 };

  // Merge and set report path in the third row, centered
  worksheet.mergeCells(3, 1, 3, totColumns);
  const pathCell = worksheet.getCell(3, 1);
  pathCell.value = `${path}`;
  pathCell.alignment = { horizontal: "center", vertical: "middle" };
  pathCell.font = { size: 12 };

  // Leave a blank row between the header and the table
  worksheet.addRow([]);

  // Define columns for the data
  worksheet.columns = [
    { header: "Name", key: "name", width: 40 },
    // { header: "Value", key: "value", width: 20 },
  ];

  // Function to add rows for each data item, with indentation
  const addRows = (reco: any, indentLevel: number = 0) => {
    if (reco?.name !== "" && reco?.value !== "") {
      worksheet.addRow({
        name: " ".repeat(indentLevel * 4) + reco?.name,
        value: reco?.value !== 0 ? Utils.getFormattedNumber(reco?.value) : "",
      });
    }

    // Add children if available
    if (reco?.children) {
      reco?.children.forEach((creco: any) => {
        worksheet.addRow({
          name: " ".repeat((indentLevel + 1) * 4) + creco?.name,
          value: Utils.getFormattedNumber(creco?.value),
        });

        if (creco?.children) {
          creco?.children.forEach((greco: any) => {
            worksheet.addRow({
              name: " ".repeat((indentLevel + 2) * 4) + greco?.name,
              value: Utils.getFormattedNumber(greco?.value),
            });
          });
        }
      });
    }
  };

  // Add rows to the worksheet
  data.forEach((reco: any) => {
    addRows(reco);
  });

  // Save the Excel file and download
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "profit_and_loss_report.xlsx";
  link.click();
  window.URL.revokeObjectURL(url);
  console.log("Excel file created successfully");
};

export const generateProfitAndLossCSV = (
  data: any,
  currentClient: any,
  filterDate: any,
  path: string
) => {
  // Initialize CSV content
  let csvContent = "";

  // Helper function to add a left-aligned row with empty cells to match column length
  const addLeftAlignedRow = (text: string, columnsLength: number) => {
    if (text) {
      csvContent += `"${text}"${",".repeat(columnsLength - 1)}\n`;
    }
  };

  // Add company name if available
  if (currentClient) {
    addLeftAlignedRow(currentClient, 2); // Assuming 2 columns
  }

  // Add filter date if provided
  if (filterDate && (filterDate.from || filterDate.to)) {
    const fromDate = filterDate.from ? `From: ${filterDate.from}` : "";
    const toDate = filterDate.to ? `To: ${filterDate.to}` : "";
    addLeftAlignedRow(`${fromDate} ${toDate}`, 2); // Assuming 2 columns
  }

  // Add title if available
  if (path) {
    addLeftAlignedRow(path, 2); // Assuming 2 columns
  }

  // Function to format rows and handle indentation for child rows
  const addRows = (record: any, indentLevel: number = 0) => {
    if (record?.name && record?.value !== "") {
      csvContent += `"${" ".repeat(indentLevel * 4) + record.name}","${
        record?.value !== 0 ? Utils.getFormattedNumber(record.value) : ""
      }"\n`;
    }

    // Add children if any
    if (record?.children) {
      record.children.forEach((childRecord: any) => {
        csvContent += `"${
          " ".repeat((indentLevel + 1) * 4) + childRecord.name
        }","${Utils.getFormattedNumber(childRecord.value)}"\n`;

        // Add grandchildren if any
        if (childRecord?.children) {
          childRecord.children.forEach((grandChildRecord: any) => {
            csvContent += `"${
              " ".repeat((indentLevel + 2) * 4) + grandChildRecord.name
            }","${Utils.getFormattedNumber(grandChildRecord.value)}"\n`;
          });
        }
      });
    }
  };

  // Iterate over the data and add rows
  data.forEach((record: any) => {
    addRows(record);
  });

  // Create a Blob object from the CSV content and download it
  const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = window.URL.createObjectURL(csvBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${currentClient?.companyName || "Report"}_${
    path || "ProfitAndLoss"
  }.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);

  console.log("CSV file created successfully");
};
