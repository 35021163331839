import { Button, Divider, Form, Input, Modal } from "antd";
import React, { useRef } from "react";
import { useClient } from "../../../Context/ClientContext";
import { notificationContext } from "../../../Common/PageRoute";
import ChartofAccountServicesNew from "../../../Services/ChartofAccountServicesNew";

const DepttModel: React.FC<{
  open: boolean;
  data: any;
  setTrigger: any;
  onCancel: any;
}> = (props) => {
  const autoCompleteRef = useRef<any>(null);
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);

  // Post Api
  const postItem = () => {
    form.validateFields().then(async (value: any) => {
      setSaveButtonLoading(true);
      let data = {
        ...value,
      };

      await ChartofAccountServicesNew.postDepartment(
        currentClient?.id,
        data,
        props.data?.id
      )
        .then((res: any) => {
          openNotification(
            "success",
            `Item Group ${
              props.data?.id ? "Edit successfully" : "Add successfully"
            }`
          );
          props.onCancel();
          setSaveButtonLoading(false);
          props.setTrigger((x: boolean) => !x);
          console.log(res);
        })
        .catch((ex: any) => {
          setSaveButtonLoading(false);

          openNotification("error", ex);
          console.error(ex);
        });
    });
  };

  // Get Api
  React.useEffect(() => {
    if (props?.data?.id) {
      form.setFieldsValue({ ...props.data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data.id]);

  // --------- InitialValues in Add Item Model ---------
  const initialValues = {};
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (autoCompleteRef.current) {
        autoCompleteRef.current.focus();
      }
    }, 100); // Adjust delay if needed

    return () => clearTimeout(timer);
  }, []); // Ensures autofocus after initial render

  return (
    <div>
      <Modal
        open={props.open}
        title={props?.data.id ? "Update Department" : "New Department"}
        width={"40%"}
        onCancel={() => props.onCancel()}
        style={{ maxHeight: `calc(100% - 100px)`, overflowY: "auto", top: 180 }}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className="modelSaveButtonMargin"
              type="primary"
              onClick={() => postItem()}
              loading={saveButtonLoading}
            >
              {!props?.data.id ? "Save" : "Update"}
            </Button>
          </div>
        }
      >
        <Divider className="ca-model-css" />
        {/* <Spin indicator={antIcon} spinning={modelLoading}> */}
        <Form
          form={form}
          name="deptt"
          autoCapitalize="false"
          requiredMark={false}
          initialValues={initialValues}
          colon={false}
          labelAlign="left"
          labelCol={{ xs: 9, sm: 8 }}
          wrapperCol={{ xs: 16, sm: 16 }}
          labelWrap={true}
        >
          <Form.Item
            label="Department Name"
            name="depttName"
            rules={[
              { required: true, message: "Department name is required." },
            ]}
            // className="input-item"
          >
            <Input
              placeholder="Department's name"
              onInput={(e: any) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              ref={autoCompleteRef}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DepttModel;
