import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const ResourceNotFound = ({ pageHeight }: { pageHeight: string }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("localhost|AO.Token");
  React.useEffect(() => {
    if (token === null) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  return (
    <div
      style={{
        height: pageHeight,
        width: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
        }}
      >
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button onClick={() => navigate(-1)} type="primary">
              Back Home
            </Button>
          }
        />
      </div>
    </div>
  );
};
export default ResourceNotFound;
