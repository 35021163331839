import { ApiUtility } from "../Utilities/ApiUtility.axios";

class ProfileService {
  //   route = `${process.env.REACT_APP_API_BASE_URL}/Profile`;
  route = "API/Profile";
  getUserProfile = (userId: string) =>
    ApiUtility.getResult(`${this.route}/${"GetUserProfile/" + userId}`);

  editUserProfile = (userId: string, values: any) =>
    ApiUtility.post(`${this.route}${"/EditUserProfile/" + userId}`, values);

  uploadProfilePicture = (userId: string, formData: any, headers: any) =>
    ApiUtility.post(
      `${this.route}${"/UploadProfilePicture/" + userId}`,
      formData,
      headers
    );
}
// eslint-disable-next-line new-parens
export default new ProfileService();
