import React, { useEffect, useState } from "react";
import { dayjs } from "../../../../Utilities/dayjs";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useClient } from "../../../Context/ClientContext";
import TextArea from "antd/es/input/TextArea";
import { notificationContext } from "../../../Common/PageRoute";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";
import VoucherServices, {
  Party_Tax,
  ReconciledFilter,
  Voucher_Type,
  VoucherFilterStatus,
} from "../../../Services/VoucherServices";
import { AddLedger } from "../../Master/Ledger/AddLedger";
import { BankAccountTypes } from "../../../Services/ClientService";
import { Utils } from "../../../../Utilities/Utils";
import EntryLevelKeys from "../../../Common/EntryLevelKeys";
import DraftService from "../../../Services/DraftService";
import { useParams } from "react-router-dom";
import DocumentPreview from "../../../Common/DocumentPreview";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export enum AdjustBankType {
  undefined,
  // BankToBank,
  // BankToCash,
  // CashToBank,
  // CashToCash,
  BankReconciliation,
  Contra,
}

const AdjustBankBalanceModel: React.FC<{
  open: boolean;
  onCancel: any;
  type: any;
  allBankAccount?: any[];
  bank?: { id: string; name?: string; partyOrTax?: Party_Tax };
  // triggerBankTransactions?: any;
  // bankAccountTrigger?: any;
  setRefresh?: any;
  id?: any;
  ledgerName?: any;
  cashType?: any;
  disable?: boolean;
  bankDetails?: any;
  bankImportIndex?: number;
  bankEntriesId?: string;
  saveBankImport?: any;
}> = (props) => {
  // console.log(
  //   "props..........................",
  //   props.bank,
  //   props,
  //   props?.type
  // );
  const [form] = Form.useForm();
  const autoCompleteRef = React.useRef<any>(null);

  const { currentClient } = useClient();
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const { openNotification } = React.useContext(notificationContext);
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  // const [bankAccounts, setBankAccounts] = React.useState<any>();
  const [cashAndBankLedger, setCashAndBankLedger] = React.useState<any[]>([]);
  const [fromSelect, setFromSelect] = React.useState<any[]>([]);
  const [ledgerOptions, setLedgerOptions] = React.useState<any[]>([]);
  const [toSelect, setToSelect] = React.useState<any[]>([]);
  const [partyRef, setPartyRef] = React.useState<any[]>([]);
  const [selectPartyRef, setSelectPartyRef] = React.useState<any[]>([[]]);
  const [showRef, setShowRef] = React.useState<boolean>(false);
  // const [cashBankAccounts, setCashBankAccounts] = React.useState<any>();
  const [selectType, setSelectType] = React?.useState<Voucher_Type>(
    Voucher_Type.Contra
  );

  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });

  // const [refresh, setRefresh] = React?.useState(false);
  const [trigger, setTrigger] = React?.useState(false);
  const [dateFiled, setDateFiled] = React.useState<any>();
  const [bankImportData, setBankImportData] = React.useState<{
    bankImportEntryRef: string;
    isReconciled: ReconciledFilter;
  }>({
    bankImportEntryRef: "",
    isReconciled: ReconciledFilter.nonreconciled,
  });
  const [bankBalance, setBankBalance] = React.useState<number>(0.0);
  const [currentAmount, setCurrentAmount] = React.useState<number>(0.0);
  const [editPayment, setEditPayment] = React.useState<{
    balance: number;
    id: string;
  }>({
    balance: 0.0,
    id: "",
  });
  const [editDate, setEditDate] = useState<any>();
  const [deleteUploadId, setDeleteUploadId] = React.useState<string[]>([]);
  const [previewImage, setPreviewImage] = React.useState<{
    isBaseConvert: boolean;
    path: any;
  }>({ isBaseConvert: false, path: "" });

  // console.log("location", toSelect, fromSelect, ledgerOptions);

  React.useEffect(() => {
    const Date = dayjs(props?.bankDetails?.date, "DD/MM/YYYY");
    if (
      props?.bankDetails &&
      props.type === AdjustBankType.BankReconciliation
    ) {
      form?.setFieldsValue({
        amountSummary:
          props?.bankDetails?.moneyOut || props?.bankDetails?.moneyIn,
        date: Date,
        payRecReference: props?.bankDetails?.reference,
        effectiveLedger: {
          value: props?.bank?.id,
          title: props?.bank?.partyOrTax,
        },
      });
    } else if (props?.bankDetails) {
      // debugger;
      // console.log("bankDetails", props?.ledgerId);
      props?.bankDetails?.moneyOut
        ? form?.setFieldsValue({
            amountSummary: props?.bankDetails?.moneyOut,
            date: Date,
            payRecReference: props?.bankDetails?.reference,
            effectiveLedger: {
              value: props?.bank?.id,
              title: props?.bank?.partyOrTax,
            },
          })
        : form?.setFieldsValue({
            amountSummary: props?.bankDetails?.moneyIn,
            date: Date,
            payRecReference: props?.bankDetails?.reference,
            partyLedger: {
              value: props?.bank?.id,
              title: props?.bank?.partyOrTax,
            },
          });
    }
  }, []);

  // Date Api setDateFile
  React.useEffect(() => {
    if (!props?.bankDetails?.date) {
      const getDate = async () => {
        if (currentClient?.id && !props.id) {
          await VoucherServices.getMaxVoucherDate(
            currentClient?.id!,
            Voucher_Type.Contra
          ).then((res) => {
            const givenDate = new Date(res?.result);
            const currentTime = new Date();

            // Set the time part of givenDate to match currentTime
            givenDate?.setHours(
              currentTime.getHours(),
              currentTime.getMinutes(),
              currentTime.getSeconds(),
              currentTime.getMilliseconds()
            );
            form.setFieldValue("date", dayjs(givenDate));

            setDateFiled(res.result);
          });
        }
      };
      getDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  // Post Api
  const saveContra = async (isSaveAndNew?: boolean, isDraft?: boolean) => {
    console.log("post value", isDraft);
    try {
      const value = isDraft
        ? form.getFieldsValue()
        : await form.validateFields();

      if (value?.effectiveLedger?.value !== value?.partyLedger?.value) {
        setSaveButtonLoading(true);

        let givenDate;
        if (props?.id && dayjs(value?.date).isSame(dayjs(editDate), "day")) {
          givenDate = editDate;
        } else {
          givenDate = new Date(value.date);
          const currentTime = new Date();

          // Set the time part of givenDate to match currentTime
          givenDate?.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
        }

        console.log();
        

        let data = {
          ...value,
          date: dayjs(givenDate).toISOString(),
          dueDate: value?.date,
          isReconciled: props?.id
            ? bankImportData?.isReconciled
            : props.bankEntriesId
            ? ReconciledFilter.reconciled
            : ReconciledFilter.nonreconciled,
          bankImportEntryRef: props?.id
            ? bankImportData.bankImportEntryRef
            : props?.bankEntriesId
            ? props?.bankEntriesId
            : "",
          effectiveLedger: [
            {
              id: value?.effectiveLedger?.value,
              amount: isDraft ? -value?.amountSummary : value?.amountSummary,
              partyOrTax: value?.effectiveLedger?.title,
            },
          ],
          partyLedger: [
            {
              id: value?.partyLedger?.value,
              amount: value?.amountSummary,
              partyOrTax: value?.partyLedger?.title,
            },
          ],
          amountSummary: {
            totalInvoiceAmount: value?.amountSummary,
          },
          deleteUploadId: deleteUploadId,
        };

        console.log("date", data);

        const res: any = isDraft
          ? await DraftService.postVoucherDraft(
              currentClient?.id!,
              Voucher_Type.Contra,
              data,
              props?.id
            )
          : await VoucherServices?.postVoucher(
              currentClient?.id!,
              Voucher_Type?.Contra,
              data,
              props?.id
            );
        console.log("props?.bankImportIndex", res);
        if (res.result) {
          // debugger

          if (props?.bankImportIndex === 0 || props?.bankImportIndex) {
            props?.saveBankImport([
              {
                id: res.result?.id,
                name: res.result?.name,
                voucherType: res.result?.voucherType,
              },
            ]);
          } else {
            props?.setRefresh((x: boolean) => !x);
          }

          if (isDraft) {
            openNotification(
              "success",
              `${
                props?.id ? "Edit Draft Sucessfully" : "Save Draft Sucessfully"
              }`
            );
          } else {
            openNotification(
              "success",
              `Account Transaction${
                props?.id ? "Edit successfully" : "Add successfully"
              }`
            );
            form.resetFields();
            props.onCancel();
            setSaveButtonLoading(false);
          }

          // props.bankAccountTrigger();
          // props.triggerBankTransactions();
        } else {
          openNotification("error", res?.message);
          setSaveButtonLoading(false);
        }
        // .catch((ex: any) => {
        //   openNotification("error", ex.message);
        //   console.error(ex);
        //   setSaveButtonLoading(false);
        // });
      } else {
        openNotification("error", "Both account cannot be same");
      }
    } catch (ex) {
      openNotification("error", ex);
      console.error("error..", ex);
    }
  };

  EntryLevelKeys({
    save: () => saveContra(),
    cancel: () => props.onCancel(),
  });

  // Post Api
  const saveBankReconciliation = async (
    isSaveAndNew?: boolean,
    isDraft?: boolean
  ) => {
    try {
      const val = isDraft ? form.getFieldsValue() : await form.validateFields();
      setSaveButtonLoading(true);

      const givenDate = new Date(val?.date);
      const currentTime = new Date();

      // Set the time part of givenDate to match currentTime
      givenDate?.setHours(
        currentTime.getHours(),
        currentTime.getMinutes(),
        currentTime.getSeconds(),
        currentTime.getMilliseconds()
      );

      let param = {
        // dueDate: val?.date,
        date: dayjs(givenDate).toISOString(),
        narration: val?.narration,
        effectiveLedger: [
          {
            id: val?.effectiveLedger?.value,
            amount: val?.amountSummary,
            partyOrTax: val?.effectiveLedger?.title,
          },
        ],
        partyLedger: [
          {
            id: val?.partyLedger?.value,
            amount: val?.amountSummary,
            partyOrTax: val?.partyLedger?.title,
            ref:
              val?.ref?.length > 0
                ? val?.ref.map((val: any) => {
                    return {
                      ...val,
                      name: val?.name?.label ?? val?.name,
                      partyRef: val?.name?.value,
                    };
                  })
                : null,
          },
        ],
        amountSummary: {
          totalInvoiceAmount: val?.amountSummary,
        },
        deleteUploadId: deleteUploadId,
      };

      const res = isDraft
        ? await DraftService.postVoucherDraft(
            currentClient?.id,
            selectType,
            param
            //  props?.id
          )
        : await VoucherServices?.postVoucher(
            currentClient?.id,
            selectType,
            param,
            props?.id
          );
      if (res?.result) {
        if (isDraft) {
          openNotification("success", `Save Draft Sucessfully`);
          // props?.setRefresh((x: boolean) => !x);
          // props?.onCancel(false);
        } else {
          openNotification("success", `Save Sucessfully`);
          props?.setRefresh((x: boolean) => !x);
          props?.onCancel(false);
        }
      } else {
        openNotification("error", res.message);
        setSaveButtonLoading(false);
      }
    } catch (ex) {
      openNotification("error", ex);
      console.log("eroor", ex);
    }
    // });
  };

  // Edit get update contra entry
  React.useEffect(() => {
    // if (props?.id) {
    const getById = async (id: string) => {
      if (id) {
        setModelLoading(true);
        try {
          const res =
            props?.type === VoucherFilterStatus.Draft
              ? await DraftService.getByDraftId(currentClient?.id!, id)
              : await VoucherServices.getVoucharById(
                  currentClient?.id!,
                  id,
                  Voucher_Type?.Contra
                );
          // .then((res: any) => {
          if (res?.result) {
            setEditDate(res.result?.date);
            form.setFieldsValue({
              attachments: res.result?.attachments,
              number: res.result?.number,
              date: dayjs(res.result?.date),
              effectiveLedger: {
                value: res.result?.effectiveLedger[0]?.id,
                label: res.result?.effectiveLedger[0]?.ledgerName,
              },
              amountSummary: res.result?.partyLedger[0]?.amount,
              partyLedger: {
                value: res.result?.partyLedger[0]?.id,
                label: res.result?.partyLedger[0]?.ledgerName,
              },
              narration: res.result?.narration,
              payRecReference: res.result?.payRecReference,
            });

            console.log(
              "edit ",
              res.result?.amountSummary?.totalInvoiceAmount,
              res.result?.effectiveLedger[0]?.id
            );

            setEditPayment({
              balance: res.result?.amountSummary?.totalInvoiceAmount,
              id: res.result?.effectiveLedger[0]?.id,
            });

            setBankImportData({
              bankImportEntryRef: res.result?.bankImportEntryRef,
              isReconciled: res.result?.isReconciled,
            });

            // GetLedgersByGroupTypes();
            setModelLoading(false);
            // console.log("update Items", res);
          } else {
            setModelLoading(false);
          }
          // })
        } catch (ex: any) {
          console.error(ex.message);
          setModelLoading(false);
        }
      }
    };
    getById(props?.id);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  //========= Autofoucs in Amount ====//
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (autoCompleteRef.current) {
        autoCompleteRef.current.focus();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  // --------- InitialValues in Add Item Model ---------
  const initialValues = {
    type: 1,
    effectiveLedger: !props?.bankDetails?.moneyIn
      ? {
          value: props.bank?.id,
          label: props.bank?.name,
          title: props.bank?.partyOrTax,
        }
      : null,
    // : (props.type === AdjustBankType.BankToBank ||
    //     props.type === AdjustBankType.BankToCash) && {
    //     value: props.bank.id,
    //     label: props.bank.name,
    //     title: props.bank.partyOrTax,
    //   },

    // partyLedger: props.cashType
    //   ? (props.type === AdjustBankType.CashToCash ||
    //       props.type === AdjustBankType.BankToCash) && {
    //       value: props.bank.id,
    //       label: props.bank.name,
    //       title: props.bank.partyOrTax,
    //     }
    //   : (props.type === AdjustBankType.CashToBank ||
    //       props.type === AdjustBankType.BankToBank) && {
    //       value: props.bank.id,
    //       label: props.bank.name,
    //       title: props.bank.partyOrTax,
    //     },
    date: dayjs(),
  };

  React?.useEffect(() => {
    GetLedgersByGroupTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const GetLedgersByGroupTypes = async () => {
    !props.id && setModelLoading(true);
    const updateSelectOptions = (accounts: any) => {
      return accounts?.map((v: any) => ({
        label: v?.ledgerName.concat(
          v?.aliasName ? `${" (" + v?.aliasName + ")"}` : ""
        ),
        value: v?.id,
        title: v?.partyOrTax,
      }));
    };

    // if (props.id || trigger) {
    if (props.type !== AdjustBankType.BankReconciliation)
      await LedgerService?.getLedgersByGroupName(
        currentClient?.id,
        SearchLedgerByGroupsTypes?.BankAndCash
      )
        .then((res: any) => {
          if (res) {
            setCashAndBankLedger(res);

            console.log(
              "edit",
              res.find((x: any) => x.id === props.bank?.id)?.balance
            );

            setBankBalance(
              res.find((x: any) => x.id === props.bank?.id)?.balance
            );
            const ledgerOptions = updateSelectOptions(res);
            console.log("ledgerOptions", ledgerOptions);
            if (ledgerOptions?.length > 0) {
              setLedgerOptions(ledgerOptions);
              if (props?.id) {
                console.log(
                  "form1111",
                  ledgerOptions?.filter(
                    (option: any) =>
                      option.value !== form.getFieldValue("partyLedger")
                  )
                  // form.getFieldValue("partyLedger"),
                  // form.getFieldValue("effectiveLedger")
                );

                setFromSelect(
                  ledgerOptions?.filter(
                    (option: any) =>
                      option.value !== form.getFieldValue("partyLedger")
                  )
                );
                setToSelect(
                  ledgerOptions?.filter(
                    (option: any) =>
                      option.value !== form.getFieldValue("effectiveLedger")
                  )
                );
              } else {
                setFromSelect(ledgerOptions);
                setToSelect(ledgerOptions);
              }
            }
            // setTrigger(false);
            !props.id && setModelLoading(false);
          }
        })
        .catch((ex: any) => {
          console.error("error..", ex.message);
          setModelLoading(false);
        });
  };

  // console.log(props?.ledgerName, "ladgerName");
  useEffect(() => {
    if (!props.id) {
      setModelLoading(true);
      getNumber();
      if (props.type === AdjustBankType.BankReconciliation && selectType) {
        getLedgerType();
        form.setFieldValue(["partyLedger"], {});
        // console.log("aaaaa", form.getFieldValue("partyLedger"));
        if (
          form.getFieldValue("partyLedger").value &&
          (selectType === Voucher_Type.Payment ||
            selectType === Voucher_Type.Receipt)
        ) {
          onSelectParty(form.getFieldValue("partyLedger").value, selectType);
        } else {
          form.setFieldValue(["ref"], []);
          setShowRef(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectType, dateFiled]);

  const getLedgerType = async () => {
    let res;
    if (selectType === Voucher_Type.Payment) {
      // For Payment
      res = await LedgerService?.getLedgersByGroupName(
        currentClient?.id,
        SearchLedgerByGroupsTypes?.PaymentOut
      );
    } else if (selectType === Voucher_Type.Receipt) {
      // For Receipt
      res = await LedgerService?.getLedgersByGroupName(
        currentClient?.id,
        SearchLedgerByGroupsTypes?.PaymentIn
      );
    } else if (selectType === Voucher_Type.Contra) {
      // For Contra or when props?.type is 0
      res = await LedgerService?.getLedgersByGroupName(
        currentClient?.id,
        SearchLedgerByGroupsTypes?.BankAndCash
      );
    }
    if (res) {
      console.log("res...", res);
      setToSelect(
        res?.map((v: any, i: any) => {
          return {
            title: v?.partyOrTax,
            label: v?.ledgerName.concat(
              v?.aliasName ? `${" (" + v?.aliasName + ")"}` : ""
            ),
            value: v?.id,
          };
        })
      );
      if (selectType === Voucher_Type.Contra) {
        // console.log("res", res);
        setCashAndBankLedger(res);

        const setFromValue = res?.map((v: any, i: any) => {
          return {
            title: v?.partyOrTax,
            label: v?.ledgerName.concat(
              v?.aliasName ? `${" (" + v?.aliasName + ")"}` : ""
            ),
            value: v?.id,
          };
        });
        setFromSelect(setFromValue);
        setLedgerOptions(setFromValue);
      }
      setModelLoading(false);
    }
  };

  // initial api get number
  const getNumber = async () => {
    // setLoading(true);
    await VoucherServices.getVoucherMaxNo(
      currentClient?.id!,
      selectType,
      dateFiled
    )
      .then((res) => {
        form.setFieldValue("number", res?.result);
        !Voucher_Type.Contra &&
          form.setFieldValue(["ref", 0], {
            name: form.getFieldValue(["number"]),
            amount: 0,
            refType: 0,
          });
      })
      .catch((err: any) => {
        openNotification("error", err.message);
        console.error(err.message);
      });
    setModelLoading(false);
    // }
    // setLoading(false);
  };

  // Contra post

  // useEffect(() => {
  //   getVoucherDetails();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const getVoucherDetails = async () => {
  //   let res = await VoucherServices?.getVoucharById(
  //     currentClient?.id,
  //     props?.id,
  //     Voucher_Type?.Contra
  //   );
  //   if (res) {
  //     form?.setFieldsValue({
  //       effectiveLedger: res?.result?.effectiveLedger[0]?.ledgerName,
  //       partyLedger: res?.result?.partyLedger[0]?.ledgerName,
  //       amountSummary: res?.result?.amountSummary?.totalInvoiceAmount,
  //       narration: res?.result?.narration,
  //       date: dayjs(res?.result?.date),
  //     });
  //   }
  // };

  const filterOption = (
    input: string,
    option?: { label: string; value: Voucher_Type }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSelectParty = async (partyId: string, path: Voucher_Type) => {
    // console.log("partyId", partyId, path);
    await VoucherServices.getReferencesByParty(
      currentClient?.id!,
      partyId,
      path
    )
      .then((res: any) => {
        console.log(
          "result",
          res.result,
          res.result?.length,
          form.getFieldValue("amountSummary")
        );
        if (res.result?.length > 0) {
          const data = res.result?.map((v: any, i: number) => {
            return {
              name: res.result[i]?.refNo,
              maxAmount: res.result[i]?.dueAmount,
              dueAmount: res.result[i]?.dueAmount,
              // id: res.result[i]?.id,
              parentRef: res.result[i]?.id,
              id: null,
              refType: 1,
            };
          });

          form.setFieldValue(["ref"], []);

          form.setFieldValue(["ref", 0], {
            name: form.getFieldValue(["number"]),
            value: form.getFieldValue("amountSummary"),
            refType: 0,
          });

          form.setFieldValue(["ref", 1], {});

          setPartyRef(data);
        } else {
          // console.log("helo");
          setPartyRef([]);

          form.setFieldValue(["ref"], []);

          form.setFieldValue(["ref", 0], {
            name: form.getFieldValue(["number"]),
            value: form.getFieldValue("amountSummary"),
            refType: 0,
          });
        }
      })
      .catch((ex: any) => {
        console.error("error..", ex.message);
      });
  };

  const onChangeSetPartyRef = async (refIndex: number, partyRefId: string) => {
    const x = partyRef.find((x: any) => x.parentRef === partyRefId);
    if (x) {
      form.setFieldValue(["ref", refIndex], {
        ...x,
        value: x.dueAmount,
      });
    }
    setSelectPartyRef((prevState: any[]) => {
      const updatedSelectedOptions: any[] = [...prevState];
      updatedSelectedOptions[refIndex] = partyRefId;
      return updatedSelectedOptions;
    });
    onChangeRefAmountSet(x.dueAmount, false);
  };

  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);
  const [ledgerOption, setLedgerOption] = useState<any>(null);

  const handleLedgerResponse = (selectArray: any, fieldName: any) => {
    const exists = selectArray.some(
      (option: any) => option.value === addLedgerResponse.id
    );
    console.log("exists", exists);

    if (exists) {
      form.setFieldValue(fieldName, addLedgerResponse.id);
      setAddLedgerResponse(null);
      setLedgerOption(null);
    }
  };

  useEffect(() => {
    if (addLedgerResponse) {
      if (ledgerOption === "from") {
        handleLedgerResponse(fromSelect, "effectiveLedger");
      } else if (ledgerOption === "to") {
        handleLedgerResponse(toSelect, "partyLedger");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromSelect, toSelect]);

  const onChangeRefAmountSet = (
    value: string,
    // partyIndex: number,
    isParty: boolean,
    refIndex?: number
  ) => {
    if (refIndex) {
      // setSelectPartyRef((prevDataArray) => {
      //   const newArray = [...prevDataArray];
      //   newArray[partyIndex] = [...(prevDataArray[partyIndex] || [])];
      //   newArray[partyIndex].splice(refIndex, 1);
      //   return newArray;
      // });
    }
    console.log(
      "ref.........., selectPartyRef",
      value,
      // partyIndex,
      isParty,
      refIndex
    );

    if (isParty) {
      //  disabledAmountInputs[partyIndex] &&
      form.setFieldValue(
        ["ref", 0, "value"],
        Utils.getNumber(value) -
          Utils.getNumber(
            form
              .getFieldValue(["ref"])
              ?.slice(1)
              ?.reduce((acc: number, curr: any) => {
                return (acc += Utils.getNumber(curr?.value));
              }, 0)
          )
      );
    } else {
      //  disabledAmountInputs[partyIndex] &&
      form.setFieldValue(
        ["ref", 0, "value"],
        Utils.getNumber(form.getFieldValue(["amountSummary"])) -
          Utils.getNumber(
            form
              .getFieldValue(["ref"])
              ?.slice(1)
              ?.reduce((acc: number, curr: any) => {
                return (acc += Utils.getNumber(curr?.value));
              }, 0)
          )
      );
    }
    // disabledAmountInputs[index1] &&
    //   form.setFieldValue(
    //     ["partyLedger", index1, "ref", 0, "value"],
    //     Utils.getNumber(form.getFieldValue(["partyLedger", index1, "amount"])) -
    //       Utils.getNumber(
    //         form
    //           .getFieldValue(["partyLedger", index1, "ref"])
    //           .slice(1)
    //           .reduce((acc: number, curr: any) => {
    //             return (acc += Utils.getNumber(curr?.value));
    //           }, 0)
    //       )
    //   );
  };

  //  if (
  //   //  location.state?.ledgerId &&
  //    props.bankEntriesId
  //    //  splitPathInVchType === Voucher_Type.Payment
  //  ) {
  //    x =
  //      totalPaymentIn >
  //        balance +
  //          (editPayment.id === openLedgerDrawer.id
  //            ? editPayment.balance
  //            : 0) ||
  //      totalPaymentIn !== location?.state?.BankTransactionData?.moneyOut
  //        ? `Insufficient balance & Amount must be  ${location?.state?.BankTransactionData?.moneyOut}`
  //        : "";
  //  } else

  // if (props?.bankEntriesId) {
  //   x =
  //     totalPaymentIn !==
  //     (props.bankDetails?.moneyOut || props.bankDetails?.moneyIn)
  //       ? `$Amount must be  ${props.bankDetails?.moneyOut}`
  //       : "";
  // } else
  //  if (splitPathInVchType === Voucher_Type.Payment) {

  const saveDisable = (isDisabled: boolean) => {
    // console.log(
    //   "edit...",
    //   form.getFieldValue("effectiveLedger"),
    //   editPayment.balance,
    //   editPayment.id === props.bank?.id
    // );

    // only Receipt type payment
    if (selectType === Voucher_Type.Receipt) {
      if (isDisabled) return false;
      else
        return (
          <Tag
            bordered={true}
            color={bankBalance >= 0 ? "green" : "red"}
            className="m-5"
          >
            Bank Balance: {bankBalance}
          </Tag>
        );
    } else if (form.getFieldValue("effectiveLedger")) {
      var x: any = "";
      x =
        form.getFieldValue("amountSummary") >
        bankBalance +
          (editPayment.id === props.bank?.id &&
          props.type !== VoucherFilterStatus.Draft
            ? editPayment.balance
            : 0) ? (
          <Tag bordered={true} color="red" className="m-5">
            Bank Balance: {bankBalance}
          </Tag>
        ) : (
          ""
        );

      if (isDisabled) {
        if (x === "") return false;
        else return true;
      } else
        return x === "" ? (
          <Tag
            bordered={true}
            color={bankBalance >= 0 ? "green" : "red"}
            className="m-5"
          >
            Bank Balance: {bankBalance}
          </Tag>
        ) : (
          x
        );
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        maskClosable={false}
        title={
          props?.id ? "Update Cash / Bank Transfer" : "Add Cash / Bank Transfer"
          // : props.type === AdjustBankType.BankReconciliation
          // ? `Bank Record`
          // : `Add ${
          //     props.type === AdjustBankType.BankToBank
          //       ? "bank to bank account"
          //       : props.type === AdjustBankType.BankToCash
          //       ? "bank to cash transfer (Withdraw)"
          //       : props.type === AdjustBankType.CashToBank
          //       ? "Cash to bank transfer (Deposit)"
          //       : "Cash to Cash transfer"
          //   }`
        }
        width={500}
        onCancel={() => props.onCancel()}
        // closable={props.onCancel}
        style={{ maxHeight: `calc(100% - 30px)`, overflowY: "auto" }}
        // centered
        footer={
          <div style={{ marginTop: "0px" }}>
            {saveDisable(false)}

            {/* <Tag bordered={true} color="red" className="m-5">
              Selected GST Rate is not listed in Company's GST Settings hence
              the same will be added.
            </Tag> */}
            <Button
              onClick={() => {
                if (
                  form.getFieldValue("amountSummary") > 0 &&
                  form.getFieldValue("effectiveLedger") && form.getFieldValue("partyLedger") &&
                  !props?.id
                ) {
                  props?.type === AdjustBankType.BankReconciliation
                    ? saveBankReconciliation(false, true)
                    : saveContra(false, true);
                }

                form.resetFields();
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            {/* <Tooltip title={saveDisable(false)} placement="top" color="red"> */}
            <Button
              // disabled={props?.type === 0}
              disabled={saveDisable(true)}
              // disabled={
              //   currentAmount >
              //   bankBalance +
              //     (editPayment.id === props?.id ? editPayment.balance : 0)
              // }
              type="primary"
              onClick={() =>
                props?.type === AdjustBankType.BankReconciliation
                  ? saveBankReconciliation()
                  : saveContra()
              }
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {!props?.id ? "Save" : "Update"}
            </Button>
            {/* </Tooltip> */}
          </div>
        }
      >
        <Divider className="ca-model-css" />
        <Spin indicator={antIcon} spinning={modelLoading}>
          <Form
            form={form}
            name="item"
            autoCapitalize="false"
            requiredMark={false}
            initialValues={initialValues}
            colon={false}
            labelAlign="left"
            labelCol={{ xs: 8, sm: 5 }}
            wrapperCol={{ xs: 15, sm: 18 }}
            labelWrap={true}
          >
            <div
              style={{ justifyContent: "space-between" }}
              // gutter={{ sm: 30 }}
            >
              <Row>
                {props?.type === AdjustBankType.BankReconciliation && (
                  <Col sm={24} xs={24}>
                    <Form.Item label="Type" className="input-item">
                      <Select
                        defaultValue={selectType}
                        showSearch
                        filterOption={filterOption}
                        ref={autoCompleteRef}
                        placeholder="Select..."
                        onChange={(e) => {
                          form.setFieldValue(["res"], [{}]);
                          setPartyRef([]);
                          setSelectType(e);
                        }}
                        options={[
                          {
                            value: Voucher_Type.Contra,
                            label: "Cash / Bank Transfer",
                          },
                          {
                            value: Voucher_Type.Receipt,
                            label: "Receipt",
                            disabled:
                              props.type ===
                                AdjustBankType.BankReconciliation &&
                              props.bankDetails?.moneyOut
                                ? true
                                : false,
                          },
                          {
                            value: Voucher_Type.Payment,
                            label: "Payment",
                            disabled:
                              props.type ===
                                AdjustBankType.BankReconciliation &&
                              props.bankDetails?.moneyIn
                                ? true
                                : false,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col sm={24} xs={24}>
                  <Form.Item
                    label="Number"
                    name="number"
                    className="input-item"
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24}>
                  <Form.Item
                    label="Amount"
                    name="amountSummary"
                    className="input-item"
                    rules={[
                      { required: true, message: "Amount is required" },
                      {
                        pattern: /^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/,
                        message: "0 Not Valid!",
                      },
                    ]}
                  >
                    <InputNumber
                      ref={autoCompleteRef}
                      disabled={
                        props?.type !== AdjustBankType.BankReconciliation &&
                        (props?.bankImportIndex === 0 || props?.bankImportIndex)
                          ? true
                          : false
                      }
                      controls={false}
                      placeholder="0.00"
                      style={{ width: "100%" }}
                      // onChange={(e: any) =>}
                      onChange={(e: any) => {
                        setCurrentAmount(e);
                        onChangeRefAmountSet(e, true);
                      }}
                      onKeyPress={(e: any) => {
                        if (!/\d|\./.test(e.key)) e.preventDefault();
                        if (e.key === "." && e.target.value.includes("."))
                          e.preventDefault();
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24}>
                  <Form.Item
                    name="effectiveLedger"
                    label="From"
                    rules={[{ required: true, message: "From is required" }]}
                    className="input-item"
                  >
                    <Select
                      allowClear
                      // defaultValue={props?.ledgerId}
                      disabled={
                        props?.bankDetails?.moneyOut &&
                        props.type === Voucher_Type?.Contra
                          ? true
                          : (props.bankDetails?.moneyIn ||
                              props.bankDetails?.moneyOut) &&
                            props.type === AdjustBankType.BankReconciliation
                          ? true
                          : false
                      }
                      showSearch
                      labelInValue
                      filterOption={filterOption}
                      options={
                        selectType === Voucher_Type.Contra
                          ? fromSelect.filter(
                              (option: any) =>
                                option.value !==
                                form.getFieldValue("partyLedger")?.value
                            )
                          : fromSelect
                      }
                      placeholder="Select..."
                      dropdownRender={(options) => (
                        <>
                          {options}
                          <Divider style={{ margin: "5px 0px" }} />
                          <Button
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            type="link"
                            onClick={() => {
                              setLedgerOption("from");
                              setOpenModel({
                                type: "addLedger",
                                param: {
                                  open: true,
                                },
                              });
                            }}
                          >
                            New Ledger
                          </Button>
                        </>
                      )}
                      onChange={(e: any) => {
                        const selectAccount = cashAndBankLedger.find(
                          (x) => x?.id === e?.value
                        );
                        // console.log(
                        //   "selectAccount",
                        //   cashAndBankLedger,
                        //   fromSelect,
                        //   selectAccount
                        // );

                        setBankBalance(selectAccount?.balance);
                        if (e?.value && selectType === Voucher_Type.Contra) {
                          // console.log(
                          //   "onChange",
                          //   ledgerOptions,
                          //   ledgerOptions?.filter(
                          //     (option: any) => option.value !== e?.value
                          //   )
                          // );

                          setToSelect(
                            ledgerOptions?.filter(
                              (option: any) => option.value !== e?.value
                            )
                          );
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} xs={24}>
                  <Form.Item
                    label="To"
                    name="partyLedger"
                    style={{ margin: "0px" }}
                    // className="input-item"
                    rules={[{ required: true, message: "To is required" }]}
                  >
                    <Select
                      disabled={
                        props?.bankDetails?.moneyIn &&
                        props.type === Voucher_Type?.Contra
                          ? true
                          : false
                      }
                      allowClear
                      showSearch
                      labelInValue
                      filterOption={filterOption}
                      options={
                        selectType === Voucher_Type.Contra
                          ? toSelect.filter(
                              (option) =>
                                option.value !==
                                form.getFieldValue("effectiveLedger")?.value
                            )
                          : toSelect
                      }
                      placeholder="Select..."
                      // onChange={(e: any) => {
                      //   onChangeRefAmountSet(e, true);
                      // }}
                      onChange={(e: any) => {
                        console.log("e", e);
                        if (props.type === Voucher_Type?.Contra) {
                          setFromSelect(
                            ledgerOptions?.filter(
                              (option: any) => option.value !== e?.value
                            )
                          );
                        }
                        if (e.title === Party_Tax.party) {
                          onSelectParty(e.value, selectType);
                          setShowRef(true);
                        } else {
                          form.setFieldValue("ref", []);
                          setPartyRef([]);
                        }
                      }}
                      dropdownRender={(customerOptions) => (
                        <>
                          {customerOptions}
                          <Divider style={{ margin: "5px 0px" }} />
                          <Button
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            type="link"
                            onClick={() => {
                              setLedgerOption("to");
                              setOpenModel({
                                type: "addLedger",
                                param: {
                                  open: true,
                                },
                              });
                            }}
                          >
                            New Ledger
                          </Button>
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>

                <tr
                  style={{
                    display: !showRef ? "none" : "contents",
                  }}
                >
                  <td colSpan={2}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <Form.Item className="m-0" name="ref">
                          <Form.List name={["ref"]}>
                            {(subFields, { add, remove, move }) => {
                              return (
                                <div>
                                  {subFields.map((subField, index) => {
                                    return (
                                      <tr
                                        style={{
                                          paddingLeft: "0px",
                                        }}
                                        key={index}
                                      >
                                        <td>{index + 1}</td>
                                        <td>
                                          {index === 0 ? (
                                            <Tooltip
                                              title="New Reference"
                                              placement="left"
                                              color="#389e0d"
                                            >
                                              <Avatar
                                                style={{
                                                  cursor: "pointer",
                                                  verticalAlign: "middle",
                                                  color: "#389e0d",
                                                  backgroundColor: "#f6ffed",
                                                  borderColor: "#b7eb8f",
                                                  fontWeight: "bold",
                                                }}
                                                size="small"
                                              >
                                                N
                                              </Avatar>
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              title="Old Reference"
                                              placement="left"
                                              color="#08979C"
                                            >
                                              <Avatar
                                                style={{
                                                  cursor: "pointer",
                                                  verticalAlign: "middle",
                                                  color: "#08979C",
                                                  backgroundColor: "#E6FFFB",
                                                  borderColor:
                                                    "rgb(127 231 235)",
                                                  fontWeight: "bold",
                                                }}
                                                size="small"
                                              >
                                                A
                                              </Avatar>
                                            </Tooltip>
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            width: "25%",
                                          }}
                                        >
                                          <Form.Item
                                            className="m-0"
                                            name={[subField.name, "name"]}
                                            rules={[
                                              {
                                                required:
                                                  index !== 0 &&
                                                  subFields.length !==
                                                    index + 1 &&
                                                  form.getFieldValue([
                                                    "ref",
                                                    index,
                                                    "value",
                                                  ]) > 0
                                                    ? true
                                                    : false,
                                                message: "Select a invoice",
                                              },
                                            ]}
                                          >
                                            <Select
                                              disabled={index === 0 && true}
                                              style={{ width: "100%" }}
                                              labelInValue
                                              showSearch
                                              optionFilterProp="children"
                                              placeholder="Select Account"
                                              filterOption={(input, option) =>
                                                (
                                                  option?.label?.toString() ??
                                                  ""
                                                )
                                                  .toLowerCase()
                                                  .includes(input.toLowerCase())
                                              }
                                              // showArrow={false}
                                              notFoundContent={null}
                                              onChange={(e) => {
                                                if (
                                                  subFields.length ===
                                                  index + 1
                                                )
                                                  add();

                                                onChangeSetPartyRef(
                                                  index,
                                                  e?.value
                                                );
                                              }}
                                              options={partyRef
                                                ?.filter(
                                                  (option: any) =>
                                                    !selectPartyRef?.includes(
                                                      option.parentRef
                                                    )
                                                )
                                                .map((value: any) => ({
                                                  value: value?.parentRef,
                                                  label: value?.name,
                                                  // title: value?.balance,
                                                }))}
                                            />
                                          </Form.Item>
                                        </td>

                                        <td
                                          style={{
                                            width: "20%",
                                          }}
                                        >
                                          <Form.Item
                                            className="m-0"
                                            name={[subField.name, "dueAmount"]}
                                          >
                                            <InputNumber
                                              disabled
                                              controls={false}
                                              style={
                                                {
                                                  // width: "100%",
                                                }
                                              }
                                              placeholder="Amount"
                                            />
                                          </Form.Item>
                                        </td>

                                        <td
                                          style={{
                                            width: "30%",
                                          }}
                                        >
                                          <Form.Item
                                            className="m-0"
                                            name={[subField.name, "value"]}
                                            rules={[
                                              {
                                                required:
                                                  index !== 0 &&
                                                  form.getFieldValue([
                                                    "ref",
                                                    index,
                                                    "name",
                                                  ])
                                                    ? true
                                                    : false,
                                                message: "Enter a amount",
                                              },
                                              {
                                                validator: (_, value) => {
                                                  if (value < 0) {
                                                    return Promise.reject(
                                                      "Number must be non-negative"
                                                    );
                                                  }
                                                  return Promise.resolve();
                                                },
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              min={0}
                                              max={
                                                index !== 0
                                                  ? Math.round(
                                                      form.getFieldValue([
                                                        "ref",
                                                        index,
                                                        "dueAmount",
                                                      ]) * 100
                                                    ) / 100
                                                  : Infinity
                                              }
                                              controls={false}
                                              style={
                                                {
                                                  // width: "100%",
                                                }
                                              }
                                              placeholder="amount"
                                              onBlur={() => {
                                                if (
                                                  subFields.length ===
                                                  index + 1
                                                )
                                                  add();
                                              }}
                                              onChange={(e: any) => {
                                                onChangeRefAmountSet(e, false);

                                                if (
                                                  Utils.getNumber(
                                                    form.getFieldValue([
                                                      "amount",
                                                    ])
                                                  ) !==
                                                  Utils.getNumber(
                                                    form
                                                      .getFieldValue(["ref"])
                                                      .reduce(
                                                        (
                                                          acc: number,
                                                          curr: any
                                                        ) => {
                                                          return (acc +=
                                                            Utils.getNumber(
                                                              curr?.value
                                                            ));
                                                        },
                                                        0
                                                      )
                                                  )
                                                ) {
                                                  console.log("error");
                                                  // form.validateFields([
                                                  //   "partyLedger",
                                                  //   index1,
                                                  //   "amount",
                                                  // ]);
                                                  return Promise.resolve();
                                                }
                                              }}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td>
                                          <td
                                            style={{
                                              width: 25,
                                            }}
                                          >
                                            {index !== 0 &&
                                              subFields.length !==
                                                index + 1 && (
                                                <CloseOutlined
                                                  onClick={() => {
                                                    remove(subField.name);

                                                    onChangeRefAmountSet(
                                                      "0",
                                                      false,
                                                      index
                                                    );
                                                  }}
                                                />
                                              )}
                                          </td>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </div>
                              );
                            }}
                          </Form.List>
                        </Form.Item>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <Col sm={24} xs={24} style={{ paddingTop: "10px" }}>
                  <Form.Item label="Date" name="date" className="input-item">
                    <DatePicker
                      disabled={
                        props?.bankImportIndex === 0 || props?.bankImportIndex
                          ? true
                          : false
                      }
                      allowClear={false}
                      format="DD/MM/YYYY"
                      // disabledDate={(current) =>
                      //   current && current > dayjs().endOf("day")
                      // }
                      style={{ width: "100%" }}
                      onChange={(val) => {
                        setDateFiled(dayjs(val).toISOString());
                      }}
                    />
                  </Form.Item>
                </Col>

                <Form.Item
                  label="Reference"
                  name="payRecReference"
                  className="input-item"
                  style={{ width: "100%" }}
                >
                  <Input />
                </Form.Item>

                <Col sm={24} xs={24}>
                  <Form.Item
                    label="Description"
                    name="narration"
                    className="input-item"
                    style={{ width: "100%" }}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
                <Col sm={24} xs={24}>
                  <Form.Item
                    label="Documents"
                    name="attachments"
                    className="input-item"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                      return e.fileList;
                    }}
                  >
                    <Upload
                      multiple
                      // accept={".png, .jpg, .jpeg, .pdf"}
                      beforeUpload={() => {
                        return false;
                      }}
                      onRemove={(e: any) => {
                        if (props?.id && e?.id) {
                          setDeleteUploadId([...deleteUploadId, e?.id]);
                        }
                      }}
                      onPreview={(e: any) => {
                        console.log("preview", e);
                        if (props?.id && e?.path) {
                          setPreviewImage({
                            isBaseConvert: false,
                            path: e,
                          });
                        } else if (e.type?.split("/")[0] === "image") {
                          setPreviewImage({
                            isBaseConvert: true,
                            path: e,
                          });
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        style={{ backgroundColor: "ButtonFace" }}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Col sm={12} xs={24}>
                <Form.Item
                  label="Add Image"
                  name="holderName"
                  className="input-item"
                >
                  <Button icon={<UploadOutlined />}></Button>
                </Form.Item>
              </Col> */}
            </div>
          </Form>
          <Divider style={{ margin: "0px" }} />
        </Spin>
      </Modal>

      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(true)}
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
      {previewImage.path && (
        <DocumentPreview
          open={true}
          onCancel={() =>
            setPreviewImage({
              path: "",
              isBaseConvert: false,
              // uploadType: "",
            })
          }
          documentDetails={previewImage}
        />
      )}
    </div>
  );
};

export default AdjustBankBalanceModel;
