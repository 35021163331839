import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum CategoryType {
  All = 0,
  Expances = 1,
  Income = 2,
}
class CategoryService {
  private route = "/v1/api/Acc_Category/";
  //https://localhost:7142/v1/api/Acc_Category/64647592e72dfee880fd3f5d 'post'

  //https://localhost:7142/v1/api/Acc_Category/64647592e72dfee880fd3f5d/64afae1cd313362e1265e73c 'id post'

  PostCategory = (clientID: string, PostBody: any, id?: string) =>
    ApiUtility.postForm(
      `${this.route}${clientID}${id ? "/" + id : ""}`,
      PostBody
    );

  //https://localhost:7142/v1/api/Acc_Category/64647592e72dfee880fd3f5d?type=1&start=0&length=15 'getlist'

  getCategoryList = (
    clientId: any,
    type: number,
    start: any,
    length: any,
    sortCol?: any,
    sortDir?: any,
    search?: any,
    status?: any
  ) =>
    ApiUtility.getResult(`${this.route}${clientId}`, {
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
    });

  //https://localhost:7142/v1/api/Acc_Category/64647592e72dfee880fd3f5d/64ae86512d2242867e18d356 'getbyid'

  getCategoryById = (clientId: string, id?: string) =>
    ApiUtility.get(`${this.route}${clientId}/${id}`);

  //https://localhost:7142/v1/api/Acc_Category/64647592e72dfee880fd3f5d/Search?type=1&term=expenses&length=15

  searchCategory = (
    clientId: string,
    type: CategoryType,
    length: number,
    term: string = ""
  ) =>
    ApiUtility.get(
      `${this.route}${clientId}/Search?type=${type}&term=${term}&length=${length}`
    );
}
export default new CategoryService();
