import {
  CalendarOutlined,
  DashOutlined,
  DeleteTwoTone,
  EditOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  notification,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import ClientService, {
  BusinessNatures,
  getBusinessTypeName,
} from "../../Services/ClientService";
import AddOrEditClient from "./AddOrEditClient";
import { NavLink } from "react-router-dom";
import React from "react";
import { dayjs } from "../../../Utilities/dayjs";
import { BusinessTypes, CompanyGSTTypes } from "../../../Types/CommonConstants";
import "../../Common/Acc_Common.css";
import { useTheme } from "../../Context/ThemeContext";
import { useNavigate } from "react-router-dom";
//import { RiHistoryFill } from "react-icons/ri";
import search from "../../Images/search.svg";
import { time } from "echarts";
import { ClientStatus, LedgerStatus } from "../../Services/LedgerService";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { useClient } from "../../Context/ClientContext";
import { useUser } from "../../../Context/UserContext";

interface DataType {
  key: string;
  id: string;
  clientId: string;
  //autoId: string;
  bName: string;
  aliasName: string;
  //name: string;
  type: string;
  gstin: string;
  state: number;
  contactInformation: any;
  // email: string;
  // phone: number;
  pan: any;
  status: any;
}
type NotificationType = "success" | "error";

export const GSTTypeOptions = [
  {
    key: CompanyGSTTypes.Unregistered,
    value: CompanyGSTTypes.Unregistered,
    label: "Unregistered",
  },
  {
    key: CompanyGSTTypes.Regular,
    value: CompanyGSTTypes.Regular,
    label: "Regular",
  },
  {
    key: CompanyGSTTypes.Composition,
    value: CompanyGSTTypes.Composition,
    label: "Composition",
  },
  {
    key: CompanyGSTTypes.Consumer,
    value: CompanyGSTTypes.Consumer,
    label: "Consumer",
  },
];

export const BusinessTypeOptions = [
  { key: 0, value: 0, label: "Select Type" },
  {
    key: BusinessTypes.PrivateLimited,
    value: BusinessTypes.PrivateLimited,
    label: "Private Limited",
  },
  {
    key: BusinessTypes.PublicLimited,
    value: BusinessTypes.PublicLimited,
    label: "Public Limited",
  },
  {
    key: BusinessTypes.SoleProprietorship,
    value: BusinessTypes.SoleProprietorship,
    label: "Sole Proprietorship",
  },
  {
    key: BusinessTypes.Partnership,
    value: BusinessTypes.Partnership,
    label: "Partnership",
  },
  { key: BusinessTypes.LLP, value: BusinessTypes.LLP, label: "LLP" },
  { key: BusinessTypes.LLC, value: BusinessTypes.LLC, label: "LLC" },
  {
    key: BusinessTypes.JointVenture,
    value: BusinessTypes.JointVenture,
    label: "Joint Ventures",
  },
  {
    key: BusinessTypes.HUF,
    value: BusinessTypes.HUF,
    label: "Hindu Undivided Family",
  },
  { key: BusinessTypes.NGO, value: BusinessTypes.NGO, label: "NGO" },
  {
    key: BusinessTypes.GA,
    value: BusinessTypes.GA,
    label: "Govt. Authority (Local Authority)",
  },
];

export const BusinessNaturesTypeOptions = [
  { key: 0, value: 0, label: "Unspecified" },
  {
    key: BusinessNatures.Manufacturer,
    value: BusinessNatures.Manufacturer,
    label: "Manufacturer",
  },
  {
    key: BusinessNatures.ServiceProvider,
    value: BusinessNatures.ServiceProvider,
    label: "Service Provider",
  },
  // {
  //   key: BusinessNatures.Hybrid,
  //   value: BusinessNatures.Hybrid,
  //   label: "Hybrid",
  // },
  {
    key: BusinessNatures.Trader,
    value: BusinessNatures.Trader,
    label: "Trader",
  },
];

const Clients = () => {
  const { themeData } = useTheme();
  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const navigate = useNavigate();
  const [companyId, setCompanyId] = React.useState<any>();
  const [open, setOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [apiResponse, setApiResponse] = useState<DataType[]>();
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchClient, setSearchClient] = useState("");
  const [editClient, setEditClient] = useState<any>();
  const [page, setPage] = React.useState(1);
  const [api, contextHolder] = notification.useNotification();
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [businessType, setBusinessType] = React.useState<number>();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [clienData, setClientData] = React.useState<any>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [changeStatusConfirmation, setChangesStatusConfirmation] =
    useState<boolean>(false);
  const [clientStatus, setClientStatus] = React.useState<any>(ClientStatus.All);
  const [statusRecord, setStatusRecord] = React.useState<any>();
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: width <= 1440 ? "5%" : "4%",
      render: (sno: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {sno}
        </Skeleton>
      ),
    },
    {
      title: "Business Name (Short Name)",
      dataIndex: "bName",
      key: "bName",
      width: "16%",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: true,
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <>
            <NavLink
              style={{ color: "#395cd2" }}
              to={`/accounts/clients/${record.id}/dashboard`}
              onClick={() => {
                localStorage.setItem("id", record.id);
                localStorage.setItem("name", record.bName);
              }}
            >
              {record.aliasName !== null
                ? record.bName + " (" + record.aliasName + ")"
                : record.bName}
            </NavLink>
          </>
        </Skeleton>
      ),
    },
    {
      title: "Client Type",
      dataIndex: "type",
      key: "type",
      width: "10%",
      sorter: true,
      ellipsis: true,
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div>{record?.businessType}</div>
        </Skeleton>
      ),
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
      width: "10%",
      // sorter: true,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: true,
      render: (v: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {v}
        </Skeleton>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "10%",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: true,
      render: (v: number) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {Object.entries(stateOptions)?.map(([key, s]: any) =>
            s.id === v ? s.name : ""
          )}
        </Skeleton>
      ),
    },
    {
      title: "PAN No.",
      dataIndex: "pan",
      key: "pan",
      width: "10%",
      sorter: true,
      render: (v: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {v}
        </Skeleton>
      ),
    },

    {
      title: "Email",
      dataIndex: "contactInformation",
      key: "email",
      width: width <= 1440 ? "11%" : "18%",
      // sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (v: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {v?.email}
        </Skeleton>
      ),
      showSorterTooltip: true,
    },
    {
      title: "Contact No.",
      dataIndex: "contactInformation",
      key: "phone",
      width: "8%",
      render: (v: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {v?.phone}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: width <= 1024 ? "8%" : "6%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Row gutter={6}>
            <Col>
              {record?.status === 1 ? (
                <>
                  <Tooltip title="Not Editable">
                    <EditOutlined
                      disabled
                      style={{
                        color: "gray",
                      }}
                      className="ca-edit-btn"
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Edit">
                    <EditOutlined
                      style={{
                        color: "#444653",
                      }}
                      className="ca-edit-btn"
                      onClick={() => {
                        console.log(record);

                        setEditClient(record);
                        setOpen(true);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </Col>
            <Col>
              <Popover content={content(record)}>
                <DashOutlined />
              </Popover>
            </Col>
          </Row>
        </Skeleton>
      ),
    },
  ];
  const content = (record: DataType) => {
    return (
      <>
        <Space>
          <Tooltip title="Change status" color="gray">
            <Checkbox
              // style={{ fontSize: "20px" }}
              checked={record?.status === 0}
              onClick={() => {
                setChangesStatusConfirmation(true);
                setCompanyId(record?.id);
                setStatusRecord(record?.status === 0 ? record?.bName : "");
              }}
            />
          </Tooltip>
          <Tooltip title="Delete" color="red">
            <DeleteTwoTone
              // style={{ fontSize: "20px" }}
              className="ca-delete-btn cursor"
              twoToneColor="#ff879d"
              onClick={() => {
                setConfirmation(true);
                setCompanyId(record?.id);
              }}
            />
          </Tooltip>
        </Space>
      </>
    );
  };

  // Table Ascending & Desending Order
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "name",
      sortDir:
        filter.column !== undefined
          ? filter.order === "ascend"
            ? "asc"
            : "desc"
          : "asc",
    });
  };

  const ChangeCompanyStatus = async () => {
    let res = await ClientService?.changeCompanyStatus(companyId);
    if (res?.status) {
      getClientLists(
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchClient,
        businessType!,
        clientStatus
      );

      setChangesStatusConfirmation(false);
      getClientSummary();
      openNotification("success", "Status changed Successfully");
      if (localStorage.getItem("name") === statusRecord) {
        localStorage.removeItem("name");
      }
    } else {
      openNotification("error", `${res?.message}`);
      setChangesStatusConfirmation(false);
    }
  };

  React.useEffect(() => {
    getClientSummary();
  }, []);

  const getClientSummary = async () => {
    setCardLoading(true);
    let res = await ClientService?.getClientSummary("", "");
    if (res?.result) {
      setClientData(res?.result);
    }
    setCardLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getClientLists(
      (page - 1) * pageSize,
      pageSize,
      filter.sortCol,
      filter.sortDir,
      searchClient,
      businessType!,
      clientStatus
    );
  }, [
    businessType,
    filter.sortCol,
    filter.sortDir,
    page,
    pageSize,
    searchClient,
    trigger,
    clientStatus,
  ]);

  const getClientLists = async (
    start: any,
    length: any,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    type?: number,
    clientStatus?: LedgerStatus
  ) => {
    await ClientService.getClientList(
      start,
      length,
      sortCol,
      sortDir,
      search,
      type,
      clientStatus
    )
      .then((res: any) => {
        if (res != null || res?.items?.length > 0) {
          setApiResponse(
            res?.items?.map((r: any, i: number) => ({
              key: r?.id,
              id: r?.id,
              sno: i + 1,
              bName: r?.bName,
              aliasName: r?.alias,
              clientId: r?.clientId,
              contactInformation: r?.contactInformation[0],
              name: r?.defaultContact?.name,
              businessType: getBusinessTypeName(r?.businessType),
              gstin: r?.gstin,
              state: r?.state,
              pan: r?.pan,
              status: r?.status,
            }))
          );

          setTotalRecords(res?.totalRecords);
          setLoading(false);
        } else if (res.items.length === 0) {
          setApiResponse([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((ex: any) => {
        console.log("error", ex);
      });
    setLoading(false);
  };

  const deleteCliet = async () => {
    let res = await ClientService?.deleteClientRow(companyId);
    {
      if (res?.status) {
        openNotification("success", "Deleted Successfully");
        localStorage.removeItem("name");
        getClientLists(
          (page - 1) * pageSize,
          pageSize,
          filter.sortCol,
          filter.sortDir,
          searchClient,
          businessType!,
          clientStatus
        );
        setConfirmation(false);
      } else {
        openNotification("error", `${res?.message}`);
      }
    }
  };

  const RedirectToPreviousRoute = () => {
    let xyz: any = localStorage.getItem("id");
    navigate(`/accounts/clients/${xyz}/dashboard`);
  };

  return (
    <React.Fragment>
      <>
        <div
          style={{
            justifyContent: "center",
            margin: "20px 20px",
            // border: "1px solid #eef2fe",
            // boxShadow: "0px 0px 10px 0px #96A1C84D",
          }}
        >
          {/* {cardLoading ? (
              new Array(4).map((x: any)=>(
                <Card loading={cardLoading}> <Skeleton active loading={cardLoading}></Skeleton></Card>
              ))
            ) : (  )}*/}
          {clienData.length > 0 && (
            <>
              {" "}
              <Row gutter={15}>
                {clienData.length > 0
                  ? clienData?.map((client: any, index: number) => (
                      <Col span={6} key={index}>
                        <Card
                          // loading={cardLoading}
                          className="dashboard-card"
                          bordered={false}
                          style={{
                            borderRadius: 10,
                            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          <Skeleton
                            active
                            loading={cardLoading}
                            paragraph={{ rows: 8 }}
                            title={false}
                          >
                            <Card.Meta
                              title={
                                <Row align="middle" justify="space-between">
                                  <Typography.Title
                                    level={5}
                                    style={{ fontWeight: 500, marginBottom: 0 }}
                                  >
                                    <UserOutlined style={{ marginRight: 8 }} />

                                    <NavLink
                                      to={`/accounts/clients/${client.id}/dashboard`}
                                      onClick={() => {
                                        localStorage.setItem("id", client.id);
                                        localStorage.setItem(
                                          "name",
                                          client.bName
                                        );
                                      }}
                                    >
                                      {client?.bName}
                                    </NavLink>
                                  </Typography.Title>
                                </Row>
                              }
                            />
                            <Divider style={{ margin: "8px 0" }} />
                            <Row
                              justify="space-between"
                              align="middle"
                              style={{ marginBottom: 4 }}
                            >
                              <Col>
                                <Typography.Text type="secondary">
                                  {client?.businessType === undefined ||
                                  client?.businessType === null
                                    ? "Unknown Type"
                                    : getBusinessTypeName(client?.businessType)}
                                </Typography.Text>
                              </Col>
                              <Col>
                                <Typography.Text type="secondary">
                                  {client?.gstin}
                                </Typography.Text>
                              </Col>
                            </Row>
                            <Divider style={{ margin: "8px 0" }} />
                            <Row
                              justify="space-between"
                              align="middle"
                              style={{ marginBottom: 4 }}
                            >
                              <Col>
                                <Typography.Text>Sales Voucher</Typography.Text>
                              </Col>
                              <Col>
                                <Typography.Text>
                                  Purchase Voucher
                                </Typography.Text>
                              </Col>
                            </Row>
                            <Row
                              justify="space-between"
                              align="middle"
                              style={{ marginBottom: 4 }}
                            >
                              <Col>
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  <span>{`${client?.salesVouchers} No(s).`}</span>
                                </Typography.Title>
                              </Col>
                              <Col>
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  <span>{`${client?.purchaseVouchers} No(s).`}</span>
                                </Typography.Title>
                              </Col>
                            </Row>
                            <Divider style={{ margin: "8px 0" }} />
                            <Row
                              justify="space-between"
                              align="middle"
                              style={{ marginBottom: 4 }}
                            >
                              <Col>
                                <Typography.Text>
                                  Payment Voucher
                                </Typography.Text>
                              </Col>
                              <Col>
                                <Typography.Text>
                                  Receipt Voucher
                                </Typography.Text>
                              </Col>
                            </Row>
                            <Row
                              justify="space-between"
                              align="middle"
                              style={{ marginBottom: 4 }}
                            >
                              <Col>
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  <span>{`${client?.paymentVouchers} No(s).`}</span>
                                </Typography.Title>
                              </Col>
                              <Col>
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  <span>{`${client?.receiptVouchers} No(s).`}</span>
                                </Typography.Title>
                              </Col>
                            </Row>
                            {/* <Divider style={{ margin: "8px 0" }} />
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <BankOutlined style={{ marginRight: 8 }} />
                    <Typography.Text>Bank</Typography.Text>
                  </Col>
                  <Col>
                    <DollarOutlined style={{ marginRight: 8 }} />
                    <Typography.Text>Cash</Typography.Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 4 }}
                >
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      ₹ {client?.bankBalance}
                    </Typography.Title>
                  </Col>
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      ₹ {client?.cashBalance}
                    </Typography.Title>
                  </Col>
                </Row> */}
                            <Divider style={{ margin: "8px 0" }} />
                            <Row justify="space-between" align="middle">
                              <Col>
                                <Typography.Text>
                                  <CalendarOutlined
                                    style={{ marginRight: 8 }}
                                  />
                                  Last Entry Date
                                </Typography.Text>
                              </Col>
                              <Col></Col>
                            </Row>
                            <Row
                              justify="space-between"
                              align="middle"
                              style={{ marginBottom: 4 }}
                            >
                              <Col>
                                <Typography.Title
                                  level={5}
                                  style={{ margin: 0 }}
                                >
                                  {client?.lastEntryDate
                                    ? dayjs(client?.lastEntryDate).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "No entry made yet"}
                                </Typography.Title>
                              </Col>
                              <Col>
                                <Typography.Text>
                                  <Row gutter={10}>
                                    <Col>
                                      {client.status === 1 ? (
                                        <>
                                          <Button size="small" type="primary">
                                            Explore
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            size="small"
                                            type="primary"
                                            onClick={() => {
                                              navigate(
                                                `/accounts/clients/${client.id}/dashboard`
                                              );
                                              localStorage.setItem(
                                                "id",
                                                client.id
                                              );
                                              localStorage.setItem(
                                                "name",
                                                client.bName
                                              );
                                            }}
                                          >
                                            Explore
                                          </Button>
                                        </>
                                      )}
                                    </Col>
                                    <Col>
                                      <Button
                                        onClick={() => {
                                          setEditClient(client);
                                          setOpen(true);
                                        }}
                                        type="primary"
                                        size="small"
                                      >
                                        <EditOutlined />
                                      </Button>
                                    </Col>
                                  </Row>
                                </Typography.Text>
                              </Col>
                            </Row>
                          </Skeleton>
                        </Card>
                      </Col>
                    ))
                  : new Array(4).fill(0).map((client: any, index: number) => (
                      <Col span={6}>
                        <Card loading={cardLoading} style={{ minWidth: 300 }}>
                          <Skeleton active paragraph={{ rows: 8 }}></Skeleton>
                        </Card>
                      </Col>
                    ))}
              </Row>
            </>
          )}
        </div>
      </>

      <div>
        <AddOrEditClient
          open={open}
          setOpen={setOpen}
          setTrigger={setTrigger}
          notification={openNotification}
          clt={editClient}
        />

        <Card
          size="small"
          className="TableCard"
          style={{
            justifyContent: "center",
            margin: "20px 20px",
            // border: "1px solid #eef2fe",
            boxShadow: "0px 0px 10px 0px #96A1C84D",
          }}
        >
          <Row
            justify={"space-between"}
            style={{ marginTop: 10, padding: "10px 2px 3px 2px" }}
          >
            <Col>
              <Space size={width <= 768 ? 280 : 5}>
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                    setEditClient({ id: null });
                  }}
                >
                  <PlusOutlined />
                  Add Client
                </Button>
                {localStorage.getItem("name") ? (
                  <>
                    <div className="clientHover" style={{ padding: "5px" }}>
                      <span style={{ cursor: "pointer" }}>Continue with </span>

                      <span
                        onClick={RedirectToPreviousRoute}
                        style={{
                          fontWeight: 500,
                          color: "rgb(68, 70, 83)",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        {" "}
                        {localStorage.getItem("name")}
                      </span>
                      {/* <span>
                    <RiHistoryFill />
                  </span> */}
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="clientHover" style={{ padding: "5px" }}>
                  {/* <span style={{ cursor: "pointer" }}>Continue with </span>

                  <span
                    onClick={RedirectToPreviousRoute}
                    style={{
                      fontWeight: 500,
                      color: "rgb(68, 70, 83)",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    {localStorage.getItem("name")}
                  </span> */}
                  {/* <span>
                    <RiHistoryFill />
                  </span> */}
                </div>
              </Space>
            </Col>
            <Col
            // style={{
            //   marginTop: width <= 768 ? 15 : "",
            // }}
            >
              <Row gutter={9}>
                <Col>
                  <Select
                    placeholder="Select Client Type"
                    style={{ width: 161 }}
                    showSearch
                    options={BusinessTypeOptions}
                    onSelect={(val) => {
                      setPage(1);
                      setBusinessType(val);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  ></Select>
                </Col>
                {/* <Col style={{ marginLeft: "10px" }}>
                  <Select
                    disabled
                    defaultValue="all"
                    style={{ width: 100 }}
                    options={statusOptions}
                  />
                </Col> */}
                <Col style={{ marginLeft: "10px" }}>
                  <Input
                    style={{
                      width: "200px",
                      // marginTop: "-5px",
                      border: "1px solid #f1f3ff",
                    }}
                    allowClear={true}
                    placeholder="Search here"
                    suffix={
                      loading || searchClient ? (
                        ""
                      ) : (
                        <img alt={"search client"} src={search} />
                      )
                    }
                    onChange={(e: any) => {
                      setLoading(true);
                      if (!e.cancelable) {
                        const data = setTimeout(() => {
                          setPage(1);
                          setSearchClient(e.target.value);
                        }, 1000);
                        return () => clearTimeout(data);
                      } else {
                        setSearchClient(e.target.value);
                      }
                    }}
                  />
                </Col>

                <Col>
                  <Select
                    style={{ width: "110px" }}
                    defaultValue={ClientStatus.All}
                    onChange={(e: ClientStatus) => {
                      setClientStatus(e);
                    }}
                    options={[
                      { value: ClientStatus.All, label: "All" },
                      { value: ClientStatus.Active, label: "Active" },
                      { value: ClientStatus.Inactive, label: "In-Active" },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Table
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: 20 }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as DataType[])
                : apiResponse
            }
            size="small"
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            onChange={tableSort}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
            scroll={
              pageSize > 15
                ? { x: 1500, y: window.innerHeight - 300 }
                : undefined
            }
          />
        </Card>
        {contextHolder}
      </div>

      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            deleteCliet();
          }}
          text={"Are you sure you want to delete this row ? "}
        />
      )}
      {changeStatusConfirmation && (
        <ConfirmationModal
          open={changeStatusConfirmation}
          onNo={() => setChangesStatusConfirmation(false)}
          onYes={() => {
            ChangeCompanyStatus();
          }}
          text={"Are you sure you want to change status this company ? "}
        />
      )}
    </React.Fragment>
  );
};
export default Clients;
