import React, { useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  SelectProps,
  Modal,
  Divider,
  Form,
  message,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import "../../Common/AddPageCommon.css";
const { Search } = Input;
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
  icon: string;
  icon2: any;
  icon3: string;
  type: string;
  class: string;
  date: string;
  shares: string;
  nominalvalue: string;
  totalsharecapital: string;
}

const options: SelectProps["options"] = [
  { label: "Individual", value: "Individual" },
  { label: "Corporate", value: "Corporate" },
];

const options2: SelectProps["options"] = [
  { label: "Equity", value: "Equity" },
  { label: "Preference", value: "Preference" },
];

const classoptions: SelectProps["options"] = [
  { label: "Ordinary", value: "Ordinary" },
  { label: "Deferred Ordinary", value: "Deferred Ordinary" },
  { label: "Redeemable", value: "Redeemable" },
  { label: "A Ordinary", value: "A Ordinary" },
  { label: "B Ordinary", value: "B Ordinary" },
  { label: "C Ordinary", value: "C Ordinary" },
];

const statusoptions: SelectProps["options"] = [
  { label: "All", value: "All" },
  { label: "Deleted", value: "Deleted" },
];

const selectoptions: SelectProps["options"] = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Miss.", value: "Miss." },
  { label: "Sir.", value: "Sir." },
  { label: "Ms.", value: "Ms." },
  { label: "Dr.", value: "Dr." },
  { label: "Prof.", value: "Prof." },
  { label: "Madam.", value: "Madam." },
  { label: "Ma'am.", value: "Ma'am." },
  { label: "Lord.", value: "Lord." },
  { label: "Lady.", value: "Lady." },
  { label: "Revere.", value: "Revere." },
];

const stateoptions: SelectProps["options"] = [
  { label: "Rajasthan", value: "Rajasthan" },
  { label: "Maharastra", value: "Maharastra" },
  { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
];

const Countryoptions: SelectProps["options"] = [
  { label: "India", value: "India" },
  { label: "U.S.A", value: "U.S.A" },
];
const columns: ColumnsType<DataType> = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Allotment Date",
    dataIndex: "date",
  },
  {
    title: "No. of Shares",
    dataIndex: "shares",
  },
  {
    title: "Nominal Value",
    dataIndex: "nominalvalue",
  },
  {
    title: "Total Share Capital",
    dataIndex: "totalsharecapital",
  },
  {
    title: "Type",
    dataIndex: "type",
  },

  {
    title: "Class",
    dataIndex: "class",
  },

  {
    title: "",
    dataIndex: "icon",
    render: (_, record) => (
      <div className="cursor mx-1">
        <EditOutlined className="cursor-pointer" />
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "icon2",
    render: (_, record) => (
      <div className="cursor mx-1">
        <DeleteOutlined color="red" className="cursor-pointer" />
      </div>
    ),
  },
];
const data: DataType[] = [];
for (let i = 0; i < 3; i++) {
  data.push({
    key: i,
    name: `Gaurav Purohit`,
    age: 32,
    address: `Capsitech. ${i}`,
    icon: `gp`,
    icon2: <></>,
    icon3: `gp`,
    date: `25/5/2023`,
    shares: `5`,
    nominalvalue: `15`,
    totalsharecapital: `200`,
    type: `individual`,
    class: `B Ordinary`,
  });
}
const Shareholders: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form1] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputitem, setInputitem] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (data: any) => {
    form1
      .validateFields()
      .then((val) => {
        const finalUser = val.users.map((item: any, i: number) => {
          return { ...item, totalsharecapital: newArr[i].multival };
        });
        const sharecapital = {
          ...val,
          users: finalUser,
        };
        console.log("its resulrt", sharecapital);
      })
      .catch((ex) => console.log(ex));
  };

  const handleCancel = () => {
    const findvalue = form1.isFieldsTouched();
    if (findvalue) {
      Modal.confirm({
        title: "Confirmation",
        icon: <ExclamationCircleOutlined />,
        content:
          "All details filled will be lost, are you sure you want to continue?",
        okText: "Yes",
        cancelText: "No",
        onOk: () => setIsModalOpen(false),
      });
    } else {
      setIsModalOpen(false);
    }
  };

  const [fields, setFields] = useState(false);

  const fieldsReturn = () => {
    setFields(true);
  };

  const [newArr, setNewArr] = useState<any>([
    {
      value1field: "",
      value2field: "",
      multival: "",
    },
  ]);

  const valuestore = (e: any, index: number) => {
    let arr = newArr;

    arr[index] = {
      value1field: e.target.value,
      value2field: newArr[index].value2field,
      multival: newArr[index].value2field * e.target.value,
    };

    setNewArr([...arr]);
    console.log("this is array", arr);
  };
  const valuestore2 = (e: any, index: number) => {
    let arr = newArr;
    arr[index] = {
      value2field: e.target.value,
      value1field: newArr[index].value1field,
      multival: newArr[index].value1field * e.target.value,
    };

    setNewArr([...arr]);

    console.log(arr);
  };
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Content Deleted",
    });
  };

  const removecontent = (remove: any, name: any) => {
    success();
    remove(name);
  };

  const returnitem = () => {
    setInputitem(true);
  };

  return (
    <div>
      <Row>
        <Col lg={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px",
            }}
          >
            <div>
              <Space size={5}>
                <Button
                  type="primary"
                  style={{ backgroundColor: "#3D4998" }}
                  icon={<PlusOutlined />}
                  onClick={showModal}
                >
                  Shareholder
                </Button>
              </Space>
            </div>

            <Modal
              okText={"Save"}
              title="Add Shareholder"
              open={isModalOpen}
              width={650}
              footer={false}
              closable={false}
              className="edit"
            >
              <Form
                layout="vertical"
                onFinish={handleOk}
                form={form1}
                initialValues={{ users: [{ remember: true }] }}
                autoComplete="off"
              >
                <Row>
                  <Col lg={6}>
                    <Form.Item label="Shareholder type" name="shareholdertype">
                      <Select options={options} style={{ width: "100px" }} />
                    </Form.Item>
                  </Col>

                  <Col lg={18}>
                    {fields ? (
                      <Row>
                        <Col lg={5}>
                          <Form.Item
                            label="First name"
                            name="firstname"
                            rules={[{ required: true, message: "required" }]}
                          >
                            <Select
                              options={selectoptions}
                              style={{
                                width: "80px",
                                backgroundColor: "whitesmoke",
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={8}>
                          <Form.Item
                            name="username"
                            rules={[
                              { required: true, message: "name required" },
                            ]}
                          >
                            <Input
                              style={{
                                marginTop: "30px",
                                width: "140px",
                                backgroundColor: "whitesmoke",
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={11}>
                          <Form.Item
                            label="Last name"
                            name="lastname"
                            rules={[
                              { required: true, message: "Last name required" },
                            ]}
                          >
                            <Input style={{ backgroundColor: "whitesmoke" }} />
                          </Form.Item>
                        </Col>
                        <p
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => setFields(false)}
                        >
                          Undo
                        </p>
                      </Row>
                    ) : (
                      <Form.Item
                        label="Full name"
                        name="fullname"
                        rules={[
                          {
                            required: true,
                            message: "Shareholder's name must be required",
                          },
                        ]}
                      >
                        <Input
                          style={{ backgroundColor: "whitesmoke" }}
                          onClick={fieldsReturn}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Form
                    form={form1}
                    initialValues={{ users: [{ remember: true }] }}
                    onFinish={handleOk}
                  >
                    <p>Address</p>
                    {inputitem ? (
                      <Row>
                        <Col lg={4}>
                          <Form.Item name="building">
                            <Input placeholder="Building" />
                          </Form.Item>
                        </Col>

                        <Col lg={4}>
                          <Form.Item name="street">
                            <Input placeholder="Street" />
                          </Form.Item>
                        </Col>

                        <Col lg={4}>
                          <Form.Item name="city">
                            <Input placeholder="City" />
                          </Form.Item>
                        </Col>

                        <Col lg={4}>
                          <Form.Item name="country">
                            <Select
                              placeholder="Country"
                              options={Countryoptions}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={4}>
                          <Form.Item name="state">
                            <Select
                              placeholder="State"
                              options={stateoptions}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={4}>
                          <Form.Item name="postcode">
                            <Input placeholder="Postcode" />
                          </Form.Item>
                        </Col>

                        <p
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => setInputitem(false)}
                        >
                          Undo
                        </p>
                      </Row>
                    ) : (
                      <Col lg={24}>
                        <div
                          className="shareholder-form-item"
                          onClick={returnitem}
                        >
                          Building,Street,City,Country,State,Postcode
                        </div>
                      </Col>
                    )}
                  </Form>
                </Row>
                <div
                  style={{
                    height: "500px",
                    maxHeight: "300px",
                    overflowY: "scroll",
                  }}
                >
                  <Row>
                    <Form.List
                      name="users"
                      initialValue={[
                        { numberofshares: 0, rangeTo: 0, rate: 0 },
                      ]}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {contextHolder}
                          {fields.map(({ key, name, ...restField }, index) => (
                            <div>
                              <Divider />

                              <Row gutter={[18, 25]}>
                                <Col lg={7}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "numberofshares"]}
                                    label="Number of Shares"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Number can't be 0",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="0"
                                      type="number"
                                      onChange={(e: any) =>
                                        valuestore(e, index)
                                      }
                                    />
                                  </Form.Item>
                                </Col>

                                <Col lg={7}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "nominalvalue"]}
                                    label="Nominal value "
                                    rules={[
                                      {
                                        required: true,
                                        message: "Nominal Value",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="0"
                                      type="number"
                                      onChange={(e: any) =>
                                        valuestore2(e, index)
                                      }
                                    />
                                  </Form.Item>
                                </Col>

                                <Col lg={7}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "totalsharecapital"]}
                                    label="Total Share Capital"
                                  >
                                    {" "}
                                    <Input
                                      value={newArr[index].multival}
                                      placeholder="Total"
                                    />{" "}
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={[18, 25]}>
                                <Col lg={7}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "allotmentdate"]}
                                    label="Allotment Date"
                                  >
                                    <DatePicker
                                      onChange={(date: any) =>
                                        console.log(date.format("DD-MMM-YYYY"))
                                      }
                                    />
                                  </Form.Item>
                                </Col>

                                <Col lg={7}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "type"]}
                                    label="Type"
                                  >
                                    <Select options={options2} />
                                  </Form.Item>
                                </Col>

                                <Col lg={7}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "class"]}
                                    label="Class"
                                  >
                                    {" "}
                                    <Select options={classoptions} />
                                  </Form.Item>
                                </Col>
                                <Col lg={3}>
                                  <Form.Item>
                                    {" "}
                                    {index > 0 && (
                                      <DeleteOutlined
                                        onClick={() => {
                                          removecontent(remove, name);
                                        }}
                                      />
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <Row>
                            <Col lg={24}>
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    setNewArr([
                                      ...newArr,

                                      {
                                        value1field: "",
                                        value2field: "",
                                        multival: "",
                                      },
                                    ]);
                                    add();
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add field
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Form.List>
                  </Row>
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Form.Item>
                    <Button
                      style={{ marginRight: "9px" }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      onClick={handleOk}
                      type="primary"
                      style={{ backgroundColor: "rgb(61, 73, 152)" }}
                    >
                      <SaveOutlined /> Save
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Modal>
            <div style={{ display: "flex", justifyContent: "" }}>
              <Space>
                <div>
                  <Search />
                </div>

                <div>
                  <span style={{ paddingRight: "2px" }}> Status</span>
                  <Select
                    options={statusoptions}
                    style={{ width: "120px", textAlign: "center" }}
                  ></Select>
                </div>
              </Space>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="custom"
        size="small"
        columns={columns}
        dataSource={data}
        style={{ overflowX: "auto" }}
        pagination={false}
      />
    </div>
  );
};
export default Shareholders;
