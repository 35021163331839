import {
  Button,
  Col,
  Input,
  Row,
  Space,
  DatePicker,
  Select,
  Table,
  Tooltip,
  Tag,
  Popover,
} from "antd";
import React, { useContext } from "react";
import noteContext from "../../Context/StyleContext";
import { dayjs } from "../../../Utilities/dayjs";
import { useTheme } from "../../Context/ThemeContext";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import {
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../Common/ButtonComponent";
import { useClient } from "../../Context/ClientContext";
import ExpensesAndIncomeService, {
  EICategoryStatus,
  ExpensesIncomeTypes,
} from "../../Services/ExpensesAndIncomeService";
import { Utils } from "../../../Utilities/Utils";
import ViewExpenses from "./ViewExpenses";
import { FullPageSpinner } from "../../../Common/FullPageSpinner";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";

interface DataType {
  key: number;
  id: string;
  CategoryName: string;
  naturetype: string;
  categorytype: string;
  status: string;
  receivedAmount: number;
  dueBalance: number;
  recieved: number;
  totalInvoiceAmount: number;
  unpaid: number;
  totalExpensesIncomeAmount: number;
}

interface ExpensesInvoiceprops {
  expensesList: any;
  setExpensesList: (value: any) => void;
  LoadSkeleton: boolean;
  setLoadSkeleton: (value: boolean) => void;
}
const ExpensesTable = (props: ExpensesInvoiceprops) => {
  const navigate = useNavigate();
  const { windowWidth } = useContext(noteContext);
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const [filterDate, setFilterDate] = React.useState<any>({ from: "", to: "" });
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [searchExpenses, setSearchCExpenses] = React.useState<string>("");
  const [invoiceStatus, setInvoiceStatus] = React.useState<number>(
    EICategoryStatus.Active
  );
  React.useEffect(() => {
    // console.log(width);
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [test, setTest] = React.useState<number>(5);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<any>({
    id: "",
    isRev: null,
  });
  console.log(deleteOrRevive, "check the status");
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const { expensesList, setExpensesList } = props;

  const tableLoading = {
    spinning: loading,
    indicator: <FullPageSpinner />,
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Exp. No.",
      dataIndex: "number",
      render: (x: any) => (
        <>
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
              setInvoiceId(x.id);
              //console.log(x.id, "idexp");
            }}
          >
            {x.no}
          </span>
        </>
      ),
    },
    {
      title: "Party Name",
      dataIndex: "tradingPartner",
    },
    {
      title: "Date",
      dataIndex: "date",
    },

    {
      title: "Expenses",
      dataIndex: "totalInvoiceAmount",
      sorter: true,
      key: "TotalInvoiceAmount",
      align: "right",
      render: (_, record: any) => (
        <span>₹{Utils.getFormattedNumber(record?.totalInvoiceAmount)}</span>
      ),
    },
    {
      title: "Paid ",
      dataIndex: "receivedAmount",
      sorter: true,
      key: "ReceivedAmount",
      align: "right",
      render: (_, record: any) => (
        <span>₹{Utils.getFormattedNumber(record?.receivedAmount)}</span>
      ),
    },
    {
      title: "Due Amt",
      dataIndex: "dueBalance",
      sorter: true,
      key: "DueBalance",
      align: "right",
      render: (_, record: any) => (
        <span>₹{Utils.getFormattedNumber(record?.dueBalance)}</span>
      ),
    },

    {
      title: "Status",
      dataIndex: "address",
      width: "5%",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.dueBalance ? (
            <Tag
              color="warning"
              style={{
                textAlign: "center",
                width: 75,
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              Due
            </Tag>
          ) : (
            <Tag
              color="success"
              style={{
                textAlign: "center",
                width: 75,
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              Paid
            </Tag>
          )}
        </div>
      ),
    },
  ];

  if (invoiceStatus === EICategoryStatus.Deleted) {
    columns.push({
      title: " ",
      dataIndex: "icon",
      width: "10%",
      align: "left",
      render: (_, record) => (
        <div className="cursor">
          <Tooltip title="Revive Expenses">
            <SyncOutlined
              onClick={() => {
                setConfirmation(true);
                setInvoiceId(record?.id);
              }}
            />
          </Tooltip>
        </div>
      ),
    });
  } else {
    columns.push(
      {
        title: "  ",
        dataIndex: "icon",
        width: "3%",
        align: "center",
        render: () => (
          <div className="cursor">
            <FiDownload style={{ color: "#395cd2" }} />
          </div>
        ),
      },
      {
        title: "  ",
        dataIndex: "icon2",
        width: "3%",
        align: "center",
        render: (_, record) => (
          <div>
            <Tooltip
              title={
                record?.totalInvoiceAmount === record?.dueBalance ||
                record?.dueBalance !== 0
                  ? "Edit"
                  : "Expense Paid Can't be Edit"
              }
              placement="top"
              color={
                record?.totalInvoiceAmount === record?.dueBalance ||
                record?.dueBalance !== 0
                  ? "#3D4998"
                  : "gray"
              }
            >
              <EditOutlined
                style={{
                  color:
                    record?.totalInvoiceAmount === record?.dueBalance ||
                    record?.dueBalance !== 0
                      ? "#444653"
                      : "rgb(216 207 207)",
                }}
                className={
                  record?.totalInvoiceAmount === record?.dueBalance ||
                  record?.dueBalance !== 0
                    ? "ca-edit-btn"
                    : ""
                }
                onClick={() => {
                  if (
                    record?.totalInvoiceAmount === record?.dueBalance ||
                    record?.dueBalance !== 0
                  )
                    navigate(
                      `/accounts/clients/${currentClient?.id}/expenses/editExpenses/${record.id}`
                    );
                  //console.log("chekcr", record?.totalInvoiceAmount);
                }}
              />
            </Tooltip>
          </div>
        ),
      },
      {
        title: "  ",
        width: "2%",
        align: "center",
        render: (_, record, index) => (
          <Popover
            arrow={{ pointAtCenter: true }}
            placement="bottomRight"
            content={ellipsisContent(record, index)}
            trigger="click"
            // open={openPopArray[index]}
            // onOpenChange={(newOpen) => handlePopoverChange(index, newOpen)}
          >
            <HiDotsHorizontal style={{ color: "#395cd2", cursor: "pointer" }} />
          </Popover>
        ),
      }
    );
  }
  const ellipsisContent = (record: any, index: number) => {
    return (
      <div>
        <Tooltip
          title={
            record?.totalInvoiceAmount === record?.dueBalance ||
            record?.dueBalance !== 0
              ? "Delete temporarily"
              : "Can't be Delete"
          }
        >
          <Button
            disabled={record?.receivedAmount > 0 ? true : false}
            style={{ width: 100 }}
            onClick={() => {
              setConfirmation(true);
              setInvoiceId(record?.id);
              console.log(record?.id, "idcheck");
              //handlePopoverChange(index, false);
              console.log(setTest, "yogeshtest");
            }}
          >
            Delete
          </Button>
        </Tooltip>
      </div>
    );
  };

  //--------- Delete or revive API --------//
  React.useEffect(() => {
    if (deleteOrRevive.id) {
      setLoading(true);
      ExpensesAndIncomeService.deleteExpensesIncome(
        currentClient?.id!,
        deleteOrRevive.id,
        deleteOrRevive.isRev,
        ExpensesIncomeTypes.Expenses
      )
        .then((res: any) => {
          setLoading(false);
          if (res?.result) {
            setRefreshAtDelete(!refreshAtDelete);
            openNotification(
              "success",
              `Expenses ${
                invoiceStatus === 4 ? "Revived" : "Deleted"
              } successfully `
            );
          }
          console.log(res, "delete res");
        })
        .catch((err: any) => {
          console.log(err, "error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrRevive]);

  // --------- Get TableList API ---------//

  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getExpensesList(
        currentClient?.id,
        ExpensesIncomeTypes.Expenses,
        invoiceStatus,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchExpenses,
        filterDate.from,
        filterDate.to
      );
    } else if (currentClient?.id === null) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    searchExpenses,
    filterDate.from,
    filterDate.to,
    invoiceStatus,
    refreshAtDelete,
  ]);

  const getExpensesList = async (
    clientId: string,
    eIType: ExpensesIncomeTypes.Expenses,
    status: EICategoryStatus.Active,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    from?: any,
    to?: any
  ) => {
    await ExpensesAndIncomeService.getExpensesList(
      clientId,
      eIType,
      status,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to
    )
      .then((res: any) => {
        console.log("getexpenseslist", res);
        if (res?.items?.length > 0) {
          setExpensesList({
            expensesListRes: res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                id: v?.id,
                tradingPartner: v?.tradingPartner.name,
                number: { no: v?.number, id: v?.id },
                date: v?.date,
                totalInvoiceAmount: v?.totalInvoiceAmount,
                receivedAmount: v?.receivedAmount,
                dueBalance: v?.dueBalance,
              };
            }),
            tableTotalExpensesIncomeAmount:
              res?.total?.tableTotalExpensesIncomeAmount,
            tableTotalRecieved: res?.total?.tableTotalRecieved,
            tableTotalUnpaid: res?.total.tableTotalUnpaid,
            chartTotalRecieved: res?.total?.chartTotalRecieved,
            chartTotalUnpaid: res?.total?.chartTotalUnpaid,
            chartTotalTotalExpensesIncomeAmount:
              res?.total?.chartTotalTotalExpensesIncomeAmount,
          });
          // const x: number =
          //   expensesList?.reduce((acc: any, curr: any) => {
          //     return (acc += Number(curr?.dueBalance));
          //   }, 0) || 0;
          // setTotalDue(x);
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setExpensesList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err.message);
        setLoading(false);
        setExpensesList([]);
      });
  };
  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
  };
  console.log(searchExpenses, "searchExpenses");
  return (
    <div>
      <Row
        justify="space-between"
        style={{
          paddingBottom: 5,
          marginTop: 10,
          padding: "10px 18px 3px 18px",
        }}
        gutter={windowWidth === 1024 ? 65 : 0}
      >
        <Col xl={6} lg={4} md={12}>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                navigate(
                  `/accounts/clients/${currentClient?.id}/expenses/addExpenses`
                );
              }}
            >
              Expenses
            </Button>
            <Button type="default" icon={<DownloadOutlined />}>
              Export
            </Button>
          </Space>
        </Col>
        <Col
          xl={18}
          lg={20}
          md={18}
          style={{ marginTop: windowWidth <= 768 ? "15px" : "0" }}
        >
          <Row justify="end" gutter={8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Party"
                allowClear
                suffix={loading || searchExpenses ? "" : <img src={search} />}
                onChange={(e: any) => {
                  setLoading(true);
                  if (!e.cancelable) {
                    //----- Search Api call in 500 ms -----
                    const searchItem = setTimeout(() => {
                      setPage(1);
                      setSearchCExpenses(e.target.value);
                    }, 500);
                    return () => clearTimeout(searchItem);
                  } else {
                    setSearchCExpenses(e.target.value);
                    // console.log(searchExpenses, "search");
                  }
                }}
              />
            </Col>
            <Col>
              <RangePicker
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                defaultValue={"Active"}
                style={{ width: 120 }}
                options={[
                  { value: EICategoryStatus.Active, label: "Active" },
                  { value: EICategoryStatus.Due, label: "Due" },
                  { value: EICategoryStatus.Paid, label: "Paid" },
                  { value: EICategoryStatus.Deleted, label: "Deleted" },
                  { value: EICategoryStatus.All, label: "ALL" },
                ]}
                onChange={(e: any) => {
                  setPage(1);
                  setInvoiceStatus(e);
                }}
              />
            </Col>
            <Col>
              <ButtonComponent />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <Table
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            loading={tableLoading}
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            onChange={tableSort}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={expensesList.expensesListRes}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
            scroll={expensesList.length > 10 ? { x: 600, y: 380 } : { x: 600 }}
            // footer={() => (
            //   <Row>
            //     <Col
            //       style={{ width: "45%", paddingRight: "141px" }}
            //       className="textEnd"
            //     >
            //       Total
            //     </Col>
            //     <Col style={{ width: "12%" }} className="textEnd pe-15">
            //       ₹
            //       {Utils.getFormattedNumber(
            //         props.expensesList?.tableTotalExpensesIncomeAmount
            //       )}
            //     </Col>
            //     <Col style={{ width: "14%" }} className="textEnd">
            //       ₹{" "}
            //       {Utils.getFormattedNumber(
            //         props.expensesList?.tableTotalRecieved
            //       )}
            //     </Col>
            //     <Col style={{ width: "14%" }} className="textEnd ps-5 ">
            //       ₹{" "}
            //       {Utils.getFormattedNumber(
            //         props.expensesList?.tableTotalUnpaid
            //       )}
            //     </Col>
            //   </Row>
            // )}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row style={{ backgroundColor: "#f5f8ff" }}>
                    <Table.Summary.Cell index={1} className="fw600">
                      {" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} className="fw600">
                      {" "}
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={3}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={4}
                      className="fw600"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={5}
                      className="fw600"
                      align="right"
                    >
                      {"   "}₹
                      {Utils.getFormattedNumber(
                        props.expensesList?.tableTotalExpensesIncomeAmount
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={5}
                      className="fw600"
                      align="right"
                    >
                      {" "}
                      ₹
                      {Utils.getFormattedNumber(
                        props.expensesList?.tableTotalRecieved
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={6}
                      className="fw600"
                      align="right"
                    >
                      {"   "}₹{" "}
                      {Utils.getFormattedNumber(
                        props.expensesList?.tableTotalUnpaid
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={7}
                      className="fw600"
                      align="right"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={8}
                      className="fw600"
                      align="right"
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell index={9} className="fw600">
                      {"   "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10} className="fw600">
                      {"   "}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Col>
      </Row>
      {/* <AddRefno open={open} handleCancel={() => setIsOpen(false)} type={type} /> */}
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeleteOrRevive({
              id: invoiceId,
              isRev: invoiceStatus === EICategoryStatus.Deleted ? 0 : 1,
            });
            //console.log("yogesh");
            setConfirmation(false);
          }}
          text={
            invoiceStatus === EICategoryStatus.Deleted
              ? `Are you sure you want to revive ?`
              : `Are you sure you want to Delete this row ?`
          }
        />
      )}
      {open && (
        <ViewExpenses
          open={open}
          onCancel={() => setIsOpen(false)}
          invoiceId={invoiceId}
        />
      )}
    </div>
  );
};

export default ExpensesTable;
