import React from "react";
import AdminContext from "./AdminContext";
import { useUser } from "./UserContext";
import { dayjs } from "../Utilities/dayjs";
import ProfileService from "../Services/ProfileService";
const AdminContextState = (props: any) => {
  // debugger
  const { getUser } = useUser();
  const loginUser: any = { ...getUser() };
  const [userData, setUserData] = React.useState<any>();
  const [trigger, setTrigger] = React.useState(false);
  const [loadSkeleton, setLoadSkeleton] = React.useState(true);
  const getUserdata = async () => {
    await ProfileService.getUserProfile(loginUser?.id).then((res: any) => {
      console.log(res);
      if (res) {
        setUserData(res);
        setLoadSkeleton(false);

        // setCurrentUser(updatedUser);
      } else {
        setLoadSkeleton(false);
      }
    });
  };
  let getval: any = {
    aName: {
      first: userData?.name?.first,
      last: userData?.name?.last,
    },
    email: loginUser?.email,
    role: userData?.role,
    profilePhoto: userData?.profilePhoto,
    userDetails: {
      gender: userData?.userDetails?.gender,
      phone: userData?.userDetails?.phone,
      birthDate: dayjs(userData?.userDetails?.birthDate),
    },
    aAddress: {
      building: userData?.aAddress?.building,
      street: userData?.aAddress?.street,
      city: userData?.aAddress?.city,
      country: userData?.aAddress?.country,
      pincode: userData?.aAddress?.pincode,
    },
  };
  return (
    <>
      <AdminContext.Provider
        value={{
          userData,
          setUserData,
          getval,
          getUserdata,
          trigger,
          setTrigger,
          loadSkeleton,
          setLoadSkeleton,
        }}
      >
        {props.children}
      </AdminContext.Provider>
    </>
  );
};

export default AdminContextState;
