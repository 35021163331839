import { IIdName } from "../../Types/CommonTypes";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export interface IAgeingListParams {
  toDate: string;
  type: AgeingReportTypes;
  useDueDate: boolean;
  filterType: AgeingFilterType;
  numberOfSlabs: number;
  periods: number;
  sortCol?: string;
  sortDir?: string;
}

export enum AgeingReportTypes {
  Debtors,
  Creditors,
}

export enum AgeingFilterType {
  Month = 0,
  Weeks = 1,
  Days = 2,
}

export interface IAgeingInvoices {
  date: string;
  number: string;
  due: number;
}

export interface IAgeingReportRange {
  id: number;
  amount: number;
  invoices?: IAgeingInvoices[];
}

export interface IAgeingReport {
  contact: IIdName;
  prepayments: number;
  ranges: IAgeingReportRange[];
  totalDue: number;
  creditLimit: number;
  availableCredit: number;
}

export interface IAgeingResponse {
  items: IAgeingReport;
  totalPrepayments: number;
  totalCreditLimit: number;
  totalAvailableCredit: number;
  totalDueAmt: number;
  rangeTotals: { id?: number; name?: number | string }[];
}

export enum Statements {
  None,
  Trading = 1,
  ProfitAndLoss,
  BalanceSheet,
}
class New_Reports {
  private route = "/v1/API/New_Reports/";

  getTrialBalanceReport = (
    clientId: string,
    includeOpening: true,
    fromDate: string,
    toDate: string
  ) =>
    ApiUtility.getResult(
      `${this.route}${clientId}/TrialBalance?fromDate=${fromDate}&toDate=${toDate}&includeOpening=${includeOpening}`
    );

  //https://localhost:7142/v1/API/New_Reports/65cdd05235241f48a55de738/ProfitAndLoss?fromDate=04-03-2024&toDate=07-03-2024

  getGroupHierarchy = (
    clientId: string,
    fromDate: string,
    toDate: string,
    groupId?: string
  ) =>
    ApiUtility.getResult(
      `${this.route}${clientId}/GetGroupHierarchy?fromDate=${fromDate}&toDate=${toDate}`,
      {
        groupId,
      }
    );
  //https://localhost:7142/v1/API/New_Reports/65cdd05235241f48a55de738/GetFinalStatement

  getFinalStatement = (clientId: string, statement: Statements) =>
    ApiUtility.getResult(`${this.route}${clientId}/GetFinalStatement`, {
      statement,
    });

  getGeneralReport = (
    companyId: string,
    fromDate: string,
    toDate: string,
    groupId?: string | "",
    searchLedger?: string | ""
  ) =>
    ApiUtility.get(
      `${this.route}${companyId}/GetGeneralReport?fromDate=${fromDate}&toDate=${toDate}&groupId=${groupId}&searchLedger=${searchLedger}`
    );

  getGSTReports = (
    clientId: string | undefined,
    fromdate: string,
    toDate: string,
    reportType?: any
  ) => {
    return ApiUtility.get(
      `${this.route}${clientId}/GetGSTReport?fromDate=${fromdate}&toDate=${toDate}&ReportType=${reportType}`
    );
  };

  getAgeingReport = (clientId: string, params: IAgeingListParams) => {
    return ApiUtility.get(`${this.route}${clientId}/Ageing`, params);
  };

  getPartyOutstandingReports = (
    clientId: string | undefined,
    fromdate: string,
    toDate: string,
    ledgerId?: string | "",
    partyType?: any
  ) => {
    return ApiUtility.get(
      `${this.route}${clientId}/GetPartyOutstandingReport?fromDate=${fromdate}&toDate=${toDate}&ledgerId=${ledgerId}&partyType=${partyType}`
    );
  };
}

export default new New_Reports();
