import {
  CloseOutlined,
  EditOutlined,
  FormOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useContext, useEffect } from "react";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { BusinessTypeOptions, GSTTypeOptions } from "../Clients/Clients";
import { dayjs } from "../../../Utilities/dayjs";
import {
  ICompanyInfo,
  ICompanySettings,
  SettingsSections,
} from "../../Services/SettingService";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import { useUser } from "../../../Context/UserContext";
const SettingsCompanyInfo = () => {
  const { companySettings, updateCompanySettings } = useClient();
  const [companyInfoform] = Form.useForm();
  const [contactDetailsForm] = Form.useForm();
  const [paymentTermForm] = Form.useForm();
  const [gstForm] = Form.useForm();
  const [gSTTypeValue, setGSTTypeValue] = React.useState<CompanyGSTTypes>(
    companySettings?.setting?.gstDetails?.scheme
      ? companySettings?.setting?.gstDetails?.scheme
      : CompanyGSTTypes.Unregistered
  );
  const [disable, setDisable] = React.useState<boolean>(true);
  const [acc, setAcc] = React.useState<boolean>(false);
  const [gstDisable, setGSTDisable] = React.useState<boolean>(true);
  const [savebuttonloading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [checkbox, setCheckbox] = React.useState<number>(1);
  const { openNotification } = useContext(notificationContext);
  const [infoDisable, setInfoDisable] = React.useState<boolean>(true);
  const [detailsDisable, setDetailsDisable] = React.useState<boolean>(true);
  const [fydate, SetFYDate] = React.useState<string | undefined>("");
   const { getAllIndStates } = useUser();
   const stateOptions: any = getAllIndStates();
  const [saveButtonLoading, setSaveButtonLoadingg] =
    React.useState<boolean>(false);

  const [activeKey, setActiveKey] = React.useState<string[]>([]);
  const [lastClickedPanel, setLastClickedPanel] = React.useState<string | null>(
    null
  );

  const handleIconClick = (e: React.MouseEvent, panelKey: string) => {
    e.stopPropagation();
    if (!activeKey.includes(panelKey)) {
      setActiveKey([panelKey]);
      setLastClickedPanel(panelKey);
    } else {
    }
  };

  const handleCollapseChange = (key: string | string[]) => {
    if (Array.isArray(key) && key.length > 0 && key[0] === lastClickedPanel) {
      setActiveKey(key as string[]);
    } else {
      setActiveKey(key as string[]);
    }
  };
  console.log("companySettings", companySettings?.setting?.dueOn);

  const DueOnSubmit = () => {
    paymentTermForm.validateFields().then(async (val: any) => {
      setSaveButtonLoading(true);
      setDisable(true);
      const data = {
        dueOn: {
          ...companySettings?.setting?.dueOn,
          ...val?.dueOn,
        },
      };
      await updateCompanySettings!(
        SettingsSections.DueOn,
        {
          ...companySettings,
          setting: {
            ...companySettings?.setting!,
            dueOn: data?.dueOn,
          },
        },
        data
      ).then((result: any) => {
        if (result?.status) {
          openNotification("success", "Setting Updated successfully");
          setSaveButtonLoading(false);
        } else {
          setSaveButtonLoading(false);
          openNotification("error", "there is some error");
        }
      });
    });
  };

  useEffect(() => {
    const set = companySettings?.setting;
    SetFYDate(set?.companyInfo?.fydate);
    const gst = set?.gstDetails?.gst?.map((itm: any) => {
      return {
        title: itm?.rate,
        label: itm?.name,
        value: itm?.id,
      };
    });

    const formData = {
      ...set,
      companyInfo: {
        ...set?.companyInfo,
        financialYearBegin: dayjs(set?.companyInfo?.financialYearBegin),
      },
      companyContactDetails: {
        ...set?.companyContactDetails,
      },
      dueOn: {
        ...set?.dueOn,
      },
      gstDetails: {
        ...set?.gstDetails,
        registrationDate: set?.gstDetails?.registrationDate
          ? dayjs(set?.gstDetails?.registrationDate)
          : "",
        gst: gst,
      },
    };
    companyInfoform.setFieldsValue(formData);
    contactDetailsForm.setFieldsValue(formData);
    paymentTermForm?.setFieldsValue(formData);
    gstForm.setFieldsValue(formData);
  }, [companySettings]);
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: " Company Information",
      collapsible: "header",
      extra: (
        <>
          {infoDisable && (
            <Tooltip title="Edit">
              <FormOutlined
                className="ca-edit-btn"
                style={{ color: "rgb(106 119 227)" }}
                onClick={(e) => {
                  handleIconClick(e, "1");
                  setInfoDisable(false);
                  setGSTDisable(true);
                  setDisable(true);
                  setDetailsDisable(true);
                }}
              />
            </Tooltip>
          )}
        </>
      ),
      children: (
        <>
          <Form
            //labelCol={{ span: 7 }}
            requiredMark={false}
            form={companyInfoform}
            disabled={infoDisable}
            labelAlign="left"
            autoComplete="off"
            name="companyInfo"
            onFinish={(val) => {
              console.log("valll", val.companyInfo);
              setSaveButtonLoading(true);
              // financialYearBegin: val.date.toISOString(),
              // const data: ICompanyInfo = {
              //   ...val?.companyInfo,
              //   financialYearBegin: val?.companyInfo.financialYearBegin.toISOString(),
              // };
              updateCompanySettings!(
                SettingsSections.CompanyInfo,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    companyInfo: val?.companyInfo,
                  },
                },
                (val = {
                  ...val,
                  companyInfo: {
                    ...val?.companyInfo,
                    fydate: fydate,
                  },
                })
              ).then((result: any) => {
                if (result?.status) {
                  setInfoDisable(true);
                  openNotification("success", "Setting Updated successfully");
                  setSaveButtonLoading(false);
                } else {
                  setSaveButtonLoading(false);
                  openNotification("error", "there is some error");
                }
              });
            }}
          >
            <Row>
              <Col xxl={12} xl={12} lg={24} md={24}>
                <Row gutter={20}>
                  <Col xl={5} xxl={5} lg={5} md={5}>
                    <p>Name</p>
                  </Col>
                  <Col xxl={19} xl={19} lg={19} md={19}>
                    <Form.Item
                      className="mb-10"
                      name={["companyInfo", "name"]}
                      rules={[{ required: true, message: "required!!!" }]}
                      // labelCol={{ span: 5 }}
                      // wrapperCol={{ span: 16 }}
                    >
                      <Input style={{ width: "95%" }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col xl={5} xxl={5} lg={5} md={5}>
                    <p> Financial Year start</p>
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={8}>
                    <Form.Item
                      className="mb-10"
                      name={["companyInfo", "financialYearBegin"]}
                      // labelCol={{ span: 10 }}
                      // wrapperCol={{ span: 20 }}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(date: any, dateString: any) => {
                          SetFYDate(dateString);
                        }}
                        // style={{ marginLeft: "70px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={10} xl={10} lg={10} md={10}>
                    <Form.Item
                      label="Business Type"
                      className="mb-10"
                      name={["companyInfo", "businessType"]}
                      // labelCol={{ span: 7 }}
                      // wrapperCol={{ span: 15 }}
                    >
                      <Select
                        // style={{ width: "100%" }}
                        options={BusinessTypeOptions}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xl={5} xxl={5} lg={5} md={5}>
                    PAN
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={8}>
                    <Form.Item
                      className="mb-10"
                      name={["companyInfo", "panNo"]}
                      // labelCol={{ span: 12 }}
                      // wrapperCol={{ span: 12 }}
                    >
                      <Input style={{ width: "53%", marginLeft: "4px" }} />
                    </Form.Item>
                  </Col>
                  <Col xxl={10} xl={10} lg={10} md={10}>
                    <Form.Item
                      label="GST Number"
                      className="mb-10"
                      name={["companyInfo", "gstNumber"]}
                      labelCol={{ span: 8 }}
                      // wrapperCol={{ span: 16 }}
                    >
                      <Input style={{ marginLeft: "7px", width: "96%" }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xxl={2} xl={2} lg={5} md={24}>
                <span> Reg. Office</span>
              </Col>
              <Col xxl={10} xl={10}>
                <Row gutter={18}>
                  <Col xl={8} xxl={8} lg={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "building"]}
                      className="mb-10"
                    >
                      <Input placeholder="Building" />
                    </Form.Item>
                  </Col>
                  <Col xl={8} xxl={8} lg={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "district"]}
                      className="mb-10"
                    >
                      <Input placeholder="District" />
                    </Form.Item>
                  </Col>
                  <Col xl={8} xxl={8} lg={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "city"]}
                      className="mb-10"
                    >
                      <Input placeholder="City" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col xl={8} xxl={8} lg={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "pincode"]}
                      className="mb-10"
                    >
                      <Input placeholder="Pincode" />
                    </Form.Item>
                  </Col>
                  <Col xl={16} xxl={16} lg={16}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "state"]}
                      className="mb-10"
                    >
                      <Select
                        showSearch
                        placeholder="State"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={Object.entries(stateOptions)?.map(
                          ([key, x]: any) => ({
                            value: x?.id,
                            label: x?.name,
                          })
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col xl={8} xxl={8} lg={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "landmark"]}
                      className="mb-10"
                    >
                      <Input placeholder="Landmark" />
                    </Form.Item>
                  </Col>
                  <Col xl={8} xxl={8} lg={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "street"]}
                      className="mb-10"
                    >
                      <Input placeholder="Street" />
                    </Form.Item>
                  </Col>
                  <Col xl={8} xxl={8} lg={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "country"]}
                      className="mb-10"
                    >
                      <Input placeholder="Country" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row></Row> */}
            <Row justify="end" style={{ height: 32 }}>
              {!infoDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => setInfoDisable(true)}
                  >
                    Cancel
                  </Button>
                  <Form.Item className="m-0">
                    <Button
                      icon={<SaveOutlined />}
                      type="primary"
                      htmlType="submit"
                      loading={saveButtonLoading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Space>
              )}
            </Row>
          </Form>
        </>
      ),
    },
    {
      key: "2",
      label: "Contact Details",
      collapsible: "header",
      extra: (
        <>
          <Row>
            {detailsDisable && (
              <Tooltip title="Edit">
                <FormOutlined
                  className="ca-edit-btn"
                  style={{ color: "rgb(106 119 227)" }}
                  onClick={(e) => {
                    handleIconClick(e, "2");
                    setDetailsDisable(false);
                    setGSTDisable(true);
                    setInfoDisable(true);
                    setDisable(true);
                  }}
                />
              </Tooltip>
            )}
          </Row>
        </>
      ),
      children: (
        <>
          <Form
            requiredMark={false}
            form={contactDetailsForm}
            disabled={detailsDisable}
            autoComplete="off"
            name="companyContactDetails"
            onFinish={(val) => {
              setSaveButtonLoading(true);
              updateCompanySettings!(
                SettingsSections.ContactDetails,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    companyContactDetails: val?.companyContactDetails,
                  },
                },
                val
              )
                .then((result: any) => {
                  if (result?.status) {
                    setDetailsDisable(true);
                    openNotification("success", "Setting Updated successfully");
                    setSaveButtonLoading(false);
                  } else {
                    setSaveButtonLoading(false);
                    openNotification("error", "there is some error");
                  }
                })
                .catch((ex: any) => {
                  setSaveButtonLoading(false);
                  openNotification("error", ex.message);
                });
            }}
          >
            <Row>
              <Col span={7}>
                <Form.Item
                  className="mb-10"
                  label="Phone"
                  name={["companyContactDetails", "phone"]}
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 12 }}
                  labelAlign="left"
                  rules={[{ min: 10, message: "Phone no. must be 10 digits" }]}
                >
                  <Input
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Mobile"
                  className="mb-10"
                  name={["companyContactDetails", "mobile"]}
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Please input Mobile!",
                    },
                    { min: 10, message: "Mobile No. must be of 10 digits" },
                  ]}
                >
                  <Input
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Email"
                  className="mb-10"
                  name={["companyContactDetails", "email"]}
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 12 }}
                  rules={[
                    {
                      required: true,
                      message: "Please input Email!",
                    },
                    {
                      pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" style={{ height: 32 }}>
              {!detailsDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => setDetailsDisable(true)}
                  >
                    Cancel
                  </Button>

                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                    loading={saveButtonLoading}
                  >
                    Save
                  </Button>
                </Space>
              )}
            </Row>
          </Form>
        </>
      ),
    },
    {
      key: "3",
      label: "Payment Term",
      collapsible: "header",
      extra: (
        <>
          {disable && (
            <Tooltip title="Edit">
              <FormOutlined
                style={{ color: "rgb(106 119 227)" }}
                className="ca-edit-btn"
                onClick={(e) => {
                  handleIconClick(e, "3");
                  setGSTDisable(true);
                  setInfoDisable(true);
                  setDisable(false);
                  setDetailsDisable(true);
                }}
              />
            </Tooltip>
          )}
        </>
      ),
      children: (
        <>
          <Form
            requiredMark={false}
            labelAlign="left"
            form={paymentTermForm}
            name="dueOn"
            colon={false}
            disabled={disable}
          >
            <Form.Item className="input-setting-paymentTerm">
              <Row>
                {/* <Checkbox
              style={{ paddingRight: "20px" }}
              checked={checkbox === 1}
              onChange={() => {
                if (checkbox !== 1) {
                  setCheckbox(1);
                }
              }}
            /> */}

                <Col span={7} style={{ paddingRight: "10px" }}>
                  <Form.Item
                    label="Credit Period (days)"
                    name={["dueOn", "duration"]}
                    className="input-setting-paymentTerm"
                    // labelCol={{ span: 7 }}
                    // wrapperCol={{ span: 13 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter days in number",
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </Form.Item>
                </Col>

                {/* <Col span={7}>
                  <Form.Item
                    name="durationUnit"
                    className="input-setting-paymentTerm"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 13 }}
                  >
                    <Select
                      // disabled={checkbox !== 1}
                      defaultValue="Days"
                      options={[
                        { label: "Days", value: 0 },
                        { label: "Weeks", value: 1 },
                        { label: "Months", value: 2 },
                        { label: "Years", value: 3 },
                      ]}
                    />
                  </Form.Item>
                </Col> */}
                <Col span={7}>
                  <Form.Item
                    label="Credit Limit ( ₹ )"
                    name={["dueOn", "creditLimit"]}
                    // labelCol={{ span: 7 }}
                    // wrapperCol={{ span: 13 }}
                  >
                    <Input placeholder="Enter Amount" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            {/* <Row>
          <Checkbox
            style={{ paddingRight: "20px" }}
            checked={checkbox === 2}
            onChange={() => {
              if (checkbox !== 2) {
                setCheckbox(2);
              }
            }}
          />

           <Col span={2} style={{ paddingRight: "10px" }}>
            <Form.Item name="ordinal" className="input-setting-paymentTerm">
              <Select
                disabled={checkbox !== 2}
                defaultValue="Last"
                options={[
                  { label: "Last", value: 0 },
                  { label: "1st", value: 1 },
                  { label: "2nd", value: 2 },
                  { label: "3rd", value: 3 },
                  { label: "4th", value: 4 },
                  { label: "5th", value: 5 },
                  { label: "6th", value: 6 },
                  { label: "7th", value: 7 },
                  { label: "8th", value: 8 },
                  { label: "9th", value: 9 },
                  { label: "10th", value: 10 },
                  { label: "11th", value: 11 },
                  { label: "12th", value: 12 },
                  { label: "13th", value: 13 },
                  { label: "14th", value: 14 },
                  { label: "15th", value: 15 },
                  { label: "16th", value: 16 },
                  { label: "17th", value: 17 },
                  { label: "18th", value: 18 },
                  { label: "19th", value: 19 },
                  { label: "20th", value: 20 },
                  { label: "21th", value: 21 },
                  { label: "22th", value: 22 },
                  { label: "23th", value: 23 },
                  { label: "24th", value: 24 },
                  { label: "25th", value: 25 },
                  { label: "26th", value: 26 },
                  { label: "27th", value: 27 },
                  { label: "28th", value: 28 },
                  { label: "29th", value: 29 },
                  { label: "30th", value: 30 },
                  { label: "31th", value: 31 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={2} style={{ paddingRight: "10px" }}>
            <Form.Item name="ordinalUnit" className="input-setting-paymentTerm">
              <Select
                disabled={checkbox !== 2}
                defaultValue="Monday"
                options={[
                  { label: "Sunday", value: 0 },
                  { label: "Monday", value: 1 },
                  { label: "Thesday", value: 2 },
                  { label: "Wednesday", value: 3 },
                  { label: "Thursday", value: 4 },
                  { label: "Friday", value: 5 },
                  { label: "Saturday", value: 6 },
                  { label: "Day", value: 1 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={3} style={{ paddingRight: "10px" }}>
            <Form.Item
              label="of the"
              name="ordinalTarget"
              className="input-setting-paymentTerm"
            >
              <Select
                disabled={checkbox !== 2}
                defaultValue="Next"
                options={[
                  { label: "Current", value: 0 },
                  { label: "Next", value: 1 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              name="durationUnit"
              className="input-setting-paymentTerm"
            >
              <Select
                disabled={checkbox !== 2}
                defaultValue="Week"
                options={[
                  { label: "Week", value: 1 },
                  { label: "Month", value: 2 },
                  { label: "Years", value: 3 },
                ]}
              />
            </Form.Item>
          </Col> 
        </Row> */}

            {/* <Checkbox
          style={{ gap: "20px", paddingBottom: "15px" }}
          checked={checkbox === 3}
          onChange={() => {
            if (checkbox !== 3) {
              setCheckbox(3);
            }
          }}
        />
        <span>Due on receipt</span> */}
          </Form>
          {!disable && (
            <Space style={{ justifyContent: "end", display: "flex" }}>
              <Button icon={<CloseOutlined />} onClick={() => setDisable(true)}>
                Cancel
              </Button>

              <Button
                type="primary"
                loading={savebuttonloading}
                onClick={() => {
                  DueOnSubmit();
                }}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Space>
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "GST",
      collapsible: "header",
      extra: (
        <>
          {gstDisable && (
            <Tooltip title="Edit">
              <FormOutlined
                className="ca-edit-btn"
                style={{ color: "rgb(106 119 227)" }}
                onClick={(e) => {
                  handleIconClick(e, "4");
                  setGSTDisable(false);
                  setInfoDisable(true);
                  setDisable(true);
                  setDetailsDisable(true);
                }}
              />
            </Tooltip>
          )}
        </>
      ),
      children: (
        <>
          <Form
            requiredMark={false}
            form={gstForm}
            labelAlign="left"
            disabled={gstDisable}
            autoComplete="off"
            name="Gst"
            onFinish={(val) => {
              const gst = val?.gstDetails?.gst?.map((itm: any) => {
                return {
                  id: itm?.value,
                  name: itm?.label,
                  rate: itm?.title,
                };
              });

              const data = {
                gstDetails: {
                  ...val?.gstDetails,
                  registrationDate: dayjs(
                    val?.gstDetails?.registrationDate
                  ).toISOString(),
                  gst: gst,
                  // isGSTEnabled: showData,
                },
              };
              console.log("data", val);

              setSaveButtonLoading(true);
              updateCompanySettings!(
                SettingsSections.GstDetails,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    gstDetails: data?.gstDetails,
                  },
                },
                data
              )
                .then((result: any) => {
                  if (result?.status) {
                    setGSTDisable(true);
                    openNotification("success", "Setting Updated successfully");
                    setSaveButtonLoading(false);
                  } else {
                    openNotification("error", "there is some error");
                    setSaveButtonLoading(false);
                  }
                })
                .catch((ex: any) => {
                  setSaveButtonLoading(false);
                  openNotification("error", ex.message);
                });
            }}
          >
            <Row>
              <Col xxl={6} xl={6} lg={12} md={12}>
                <Form.Item
                  label="GST Type"
                  name={["gstDetails", "scheme"]}
                  className="inputBoxMb8 inputLabel33"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 14 }}
                  rules={[
                    {
                      required: true,
                      message: "Please select the appropriate GST Type",
                    },
                  ]}
                >
                  <Select
                    onChange={(val) => {
                      setGSTTypeValue(val);
                    }}
                    options={GSTTypeOptions}
                  />
                </Form.Item>
              </Col>
              {gSTTypeValue === CompanyGSTTypes.Regular && (
                <>
                  <Col xxl={6} xl={6} lg={12} md={12}>
                    <Form.Item
                      label="Registration Date"
                      className="mb-10"
                      name={["gstDetails", "registrationDate"]}
                      labelCol={{ span: 8 }}
                      wrapperCol={{ span: 17 }}
                      rules={[
                        {
                          required: true,
                          message: "Please input Date!",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "180px" }}
                        format={"DD/MM/YYYY"}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl={6} xl={6} lg={12} md={12}>
                    <Form.Item
                      label="GSTIN"
                      name={["gstDetails", "registrationNo"]}
                      className="inputBoxMb8 inputLabel33"
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 15 }}
                      rules={[
                        {
                          required: true,
                          pattern:
                            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                          message: "Enter Valid GST Number",
                        },
                      ]}
                    >
                      <Input
                        placeholder="00AABCC1234D1ZZ"
                        allowClear={true}
                        onChange={(e: any) => {
                          const capitalvalue = e.target.value.toUpperCase();
                          gstForm.setFieldValue("gstin", capitalvalue);
                        }}
                        // onBlur={handlePANNo}
                        // disabled={
                        //   gSTTypeValue === CompanyGSTTypes.Unregistered
                        //     ? true
                        //     : false
                        // }
                        // onChange={(e: any) => {
                        //   if (e.target.value) setTouched(true);
                        //   else setTouched(false);
                        // }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl={6} xl={6} lg={12} md={12}>
                    <Form.Item
                      label="Sales GST Options"
                      className="mb-10"
                      name={["gstDetails", "gst"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input GST Options.",
                        },
                      ]}
                    >
                      <Select
                        labelInValue
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select GSTs"
                        onChange={(val) => console.log(val)}
                        options={companySettings?.gsts?.map((gst: any) => ({
                          title: gst?.rate,
                          value: gst?.id,
                          label: gst?.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row justify="end" style={{ height: 32 }}>
              {!gstDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => {
                      setGSTDisable(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                    loading={saveButtonLoading}
                  >
                    Save
                  </Button>
                </Space>
              )}
            </Row>
          </Form>
        </>
      ),
    },
  ];
  console.log(acc, "=>>>>>>>");

  return (
    <div style={{ padding: "0px 0px 3px 0px" }}>
      <Collapse
        activeKey={activeKey}
        onChange={handleCollapseChange}
        items={items}
        defaultActiveKey={["1"]}
      />
      <Divider className="my-12" />
    </div>
  );
};

export default SettingsCompanyInfo;
