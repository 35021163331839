import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

export type ModifierKey = "ctrlKey" | "altKey" | "shiftKey";

interface Shortcut {
  key: string;
  modifiers: ModifierKey[];
  callback: () => void;
}

export const useMultipleKeyboardShortcuts = (shortcuts: Shortcut[]) => {
  const shortcutsRef = useRef<Shortcut[]>(shortcuts);

  useLayoutEffect(() => {
    shortcutsRef.current = shortcuts;
  }, [shortcuts]);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    const activeElement = document.activeElement;
    const isInputField =
      activeElement &&
      (activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA");

    // if (isInputField) return;

    shortcutsRef.current.forEach(({ key, modifiers, callback }) => {
      if (
        (event.key === key || event.key.toUpperCase() === key) &&
        (modifiers.length === 0 || modifiers.every((m) => event[m]))
      ) {
        if (isInputField && activeElement instanceof HTMLElement) {
          activeElement.blur();
        }
        event.preventDefault();
        callback();
      }
    });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);
};
