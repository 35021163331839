import { LogoutOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Skeleton,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../../Services/AuthService";
import ChangePassword from "./ChangePassword";
import { useUser } from "../../Context/UserContext";
import noteContext from "../../CapsitechAccountsComponents/Context/StyleContext";
import adminContext from "../../Context/AdminContext";
import "../Login/Login.css";
import { useDispatch } from "react-redux";
import { resetLoginUserData } from "../../CapsitechAccountsComponents/Redex/UserSlice";
import { resetIndianState } from "../../CapsitechAccountsComponents/Redex/IndianStateSlice";
export const Content = () => {
  const navigate = useNavigate();
  <div style={{ width: 295 }}>
    <Row
      className="px-3 py-2 linkcolor"
      onClick={() => {
        localStorage.setItem("projecttype", "accounts");
        navigate("/accounts");
      }}
    >
      <p style={{ cursor: "pointer" }}>Accounting</p>
    </Row>
  </div>;
};
//  ---------************ it will logout and redirect to the login page ****************--------------------//
export const ContentUser = (props: any) => {
  const { isloggedIn, setIsLoggedIn } = props;
  // const { userData, setUserData } = useContext(adminContext);
  const { userData, trigger, getUserdata, loadSkeleton } =
    useContext(adminContext);
  const { closePopover, setClosePopover } = props;
  const { getUser } = useUser();
  const loginUser: any = { ...getUser() };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const UserLogOut = () => {
    AuthService.logout().then((user) => {
      navigate("/login");
      dispatch(resetLoginUserData());
      dispatch(resetIndianState());
      // setIsLoggedIn(false);
      // localStorage.setItem("");
      setIsLoggedIn(false);
      sessionStorage.clear();
    });
  };
  const [open, setOpen] = React.useState<boolean>(false);
  const [editPasswordModal, setEditPasswordModal] = useState(false);

  useEffect(() => {
    getUserdata();
  }, [trigger]);

  return (
    <div style={{ width: 295 }}>
      <Row justify="center" className="mt-3 mb-2">
        <Avatar
          // src="https://media.istockphoto.com/id/1034357476/photo/indoor-photo-of-handsome-european-guy-pictured-isolated-on-grey-background-standing-close-to.jpg?s=612x612&w=0&k=20&c=3F-nSSoTbe6IhXeCn-tZHCUTx-DT58YOs1-9vGv__es="
          src={userData?.profilePhoto?.path}
          className="avatar-user cursor"
        />
      </Row>

      <Row justify="center">
        <Typography.Title
          level={5}
          className="mb-2"
          style={{ margin: 0, cursor: "pointer" }}
        >
          {loadSkeleton ? (
            <Skeleton.Input style={{ height: "5px" }} />
          ) : (
            userData?.name?.first + " " + userData?.name?.last
          )}
        </Typography.Title>
      </Row>

      <Row justify="center" className="mb-2">
        <Typography.Title
          style={{
            fontSize: "13px",
            color: "lightslategray",
            cursor: "pointer",
          }}
        >
          {loadSkeleton ? (
            <Skeleton.Input style={{ height: "5px" }} />
          ) : (
            loginUser?.email
          )}
        </Typography.Title>
      </Row>

      <Divider
        className="headerprofile"
        style={{
          backgroundColor: "#3a5dd3",
          borderWidth: "3px",
          marginTop: "-2px",
        }}
      ></Divider>

      <Row justify={"center"} className="mb-2"></Row>
      <Row
        className="py-2"
        style={{ paddingLeft: 9, paddingRight: 9 }}
        align="middle"
      >
        <Col className="cursor">
          <Button
            type="text"
            size="small"
            className="linkcolor"
            icon={<UserOutlined />}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Link to={`/accounts/setting`} onClick={() => setOpen(false)}>
              User Profile
            </Link>
          </Button>
        </Col>
      </Row>

      <Row
        className="py-2"
        style={{ paddingLeft: 9, paddingRight: 9 }}
        align="middle"
      >
        <Col className="cursor">
          <Button
            type="text"
            size="small"
            className="linkcolor"
            icon={<SyncOutlined />}
            onClick={() => {
              setEditPasswordModal(true);
              setClosePopover(true);
            }}
          >
            Change Password
          </Button>
        </Col>
      </Row>

      <Row
        className="py-2"
        style={{ paddingLeft: 9, paddingRight: 9 }}
        align="middle"
      >
        <Col className="cursor">
          <Button
            type="text"
            size="small"
            className="linkcolor"
            icon={<LogoutOutlined rotate={-90} />}
            onClick={() => {
              UserLogOut();
            }}
          >
            Logout
          </Button>
        </Col>
      </Row>
      {/************************************* sent props to the change password component ********************************************/}
      <ChangePassword
        editPasswordModal={editPasswordModal}
        setEditPasswordModal={setEditPasswordModal}
      />
    </div>
  );
};
