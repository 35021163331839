import { Button, Card, Col, Input, Row, Skeleton, Table, Tooltip } from "antd";
import React from "react";
import ItemsService, { UnitTypes } from "../../Services/ItemsService";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import UnitModel from "./UnitModel";

interface IItemDataType {
  key: string;
  sno: number;
  companyId: string;
  id: number;
  name: string;
  type: string;
  shortName: string;
  tAmount: number;
  system: boolean;
}

const UnitsItems = () => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [unitsList, setUnitsList] = React.useState<any[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [searchUnit, setSearchUnit] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [unitData, setUnitData] = React.useState<any>({});
  const [trigger, setTrigger] = React.useState<boolean>(false);

  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getUnitsList(
        currentClient?.id,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchUnit
      );
    } else if (currentClient?.id === null) {
      console.error("currentClient is null Items");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trigger,
    page,
    pageSize,
    searchUnit,
    // location.pathname,
    filter.sortCol,
    filter.sortDir,
  ]);

  // --------- Get Units TableData API ---------
  const getUnitsList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string
  ) => {
    await ItemsService.getUnitsList(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      search
    )
      .then((res: any) => {
        if (res.result?.items?.length > 0) {
          console.log(res.result.items);
          setUnitsList(
            res.result.items?.map((v: any, i: any) => {
              return {
                companyId: v?.companyId,
                name: v?.name,
                type: v?.type,
                shortName: v?.shortName,
                sno: v?.sno,
                id: v?.id,
                system: v?.system,
              };
            })
          );
          setTotalRecords(res.result?.totalRecords);
        } else if (res.result?.items?.length === 0) {
          setUnitsList([]);
          setTotalRecords(res.result.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setUnitsList([]);
      });
  };

  // --------- Units Table Columns  ---------
  const itemColumns: ColumnsType<IItemDataType> = [
    {
      title: "S NO.",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "40%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {UnitTypes[record?.type]}
        </Skeleton>
      ),
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.shortName}
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "",
      key: "edit",
      render: (record: IItemDataType) =>
        record?.system === false ? (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <Row gutter={18}>
              <Col>
                <Tooltip title="Edit" placement="top" color="#3D4998">
                  <EditOutlined
                    // className="ca-edit-btn"
                    style={{
                      color: "#444653",

                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenModel(true);
                      setUnitData({
                        id: record?.id,
                        companyId: record?.companyId,
                        name: record?.name,
                        type: record?.type,
                        shortName: record?.shortName,
                      });
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Delete" placement="top" color="red">
                  <DeleteOutlined
                    // className="ca-edit-btn"
                    style={{
                      color: "red",

                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Skeleton>
        ) : (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <Row gutter={18}>
              <Col>
                <Tooltip title="Not Editable" placement="top" color="#3D4998">
                  <EditOutlined
                    style={{ color: "rgb(216 207 207)", cursor: "pointer" }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  title="Default unit are not delete"
                  placement="top"
                  color="#3D4998"
                >
                  <DeleteOutlined
                    style={{ color: "rgb(216 207 207)", cursor: "pointer" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Skeleton>
        ),
    },
  ];

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <div>
        <Row justify="space-between">
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setOpenModel(true)}
            >
              Unit
            </Button>
          </Col>
          <Col>
            <Input
              allowClear
              style={{ width: "220px" }}
              placeholder="Search Unit"
              onChange={(e: any) => {
                setLoading(true);
                if (!e.cancelable) {
                  //console.log("typeing user............");
                  const searchUnit = setTimeout(() => {
                    setPage(1);
                    setSearchUnit(e.target.value);
                  }, 1000);
                  return () => clearTimeout(searchUnit);
                } else {
                  // console.log("Click cancle............");
                  setSearchUnit(e.target.value);
                }
              }}
              suffix={loading || searchUnit ? "" : <SearchOutlined />}
            />
          </Col>
        </Row>
        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          // loading={tableLoading}
          columns={itemColumns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as IItemDataType[])
              : unitsList
          }
          scroll={
            pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
          }
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["15", "25", "50", "100"],
            showTotal: (totalRecords, page) =>
              `${page[0]}-${page[1]} of ${totalRecords} items`,
          }}
        />
        {openModel && (
          <UnitModel
            open={true}
            data={unitData}
            onCancel={() => setOpenModel(false)}
            setTrigger={setTrigger}
          />
        )}
      </div>
    </Card>
  );
};

export default UnitsItems;
