import { voucherType } from './../Pages/Reports/VoucherDetailsByLedger';
import { Voucher_Type } from './VoucherServices';
import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum ViewType {
  Pdf,
  Preview,
}

class DownloadService {
  route = "V1/API/DownloadPDF";
  downloadPdf = (companyId: string, voucherId: string, type: ViewType,voucher_Type :Voucher_Type = Voucher_Type.All) =>
    ApiUtility.downloadFile(
      `${this.route}/${companyId}?voucherId=${voucherId}&type=${type}&voucher_Type=${voucher_Type}`
    );

  previewPdf = (companyId: string, voucherId: string, type: ViewType,voucher_Type :Voucher_Type = Voucher_Type.All) =>
    ApiUtility.get(
      `${this.route}/${companyId}?voucherId=${voucherId}&type=${type}&voucher_Type=${voucher_Type}`
    );

  // https: //localhost:7142/v1/API/DownloadPDF/6683bfca4a18e5b2a61bc2ff/EmailSendPdf?voucherId=6683cbfe4a18e5b2a61bc334&type=0&partyId=6683cba84a18e5b2a61bc333
  sendPdfEmail = (
    companyId: string,
    voucherId: string,
    type: ViewType,
    partyId: string
  ) =>
    ApiUtility.post(
      `${this.route}/${companyId}/EmailSendPdf?voucherId=${voucherId}&type=${type}&partyId=${partyId}`,
      ""
    );

  // https://localhost:7142/v1/API/DownloadPDF/6683abcf4818982f5c3ad2f4/SendEmail?voucherId=66975b1f0e328b96f228845b

  sendEmail = (companyId: string, voucherId: string, model: any) =>
    ApiUtility.post(
      `${this.route}/${companyId}/SendEmail?voucherId=${voucherId}`,
      model
    );
}
export default new DownloadService();
