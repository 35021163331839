import { UploadOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import {
  Button,
  Modal,
  Row,
  Skeleton,
  Table,
  Upload,
  UploadProps,
  message,
} from "antd";
import React, { useState } from "react";
import Papa from "papaparse";
import { ColumnsType } from "antd/es/table";
import { dayjs } from "../../../Utilities/dayjs";
import { number } from "echarts";
import { render } from "@testing-library/react";

export const UploadCSVfile: React.FC<{
  open: boolean;
  onCancel: any;
}> = (props) => {
  const [tableRows, setTableRows] = useState<any>();
  const [values, setValues] = useState<any>([]);
  const [parsedData, setParsedData] = useState<any>([]);

  const handleBeforeUpload = (file: any) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      Papa.parse(e.target.result, {
        header: true,
        skipEmptyLines: true,
        complete: function (results: Papa.ParseResult<any>) {
          const rowsArray: any[] = [];
          const valuesArray: any[] = [];

          // Iterating data to get column name and their values
          results.data.map((d: any) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          // Parsed Data Response in array format
          setParsedData(results.data);

          // Filtered Column Names
          setTableRows(rowsArray[0]);

          setValues(valuesArray);
        },
      });
    };
    reader.readAsText(file);
    return false;
  };

  const columns: any[] =
    tableRows && tableRows.length > 0
      ? Object.keys(tableRows[0]).map((key) => ({
          title: tableRows[key],
          dataIndex: tableRows[key],
        }))
      : [];

  const newColumn = {
    title: tableRows?.length > 0 ? "Transactions" : "",
    dataIndex: "Deposits",
    render: (text: any) => {
      return text === "00.00" ? "Payment" : "Receipt";
    },
  };

  columns.push(newColumn);

  console.log("pasdata", parsedData);

  return (
    <>
      <Modal
        width={1000}
        title="Upload CSV FIle"
        onCancel={() => props.onCancel()}
        open={props.open}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button type="primary">Upload</Button>
          </div>
        }
      >
        <Row style={{ justifyContent: "center", width: "100%" }}>
          <Upload
            //   {...Props}
            accept=".txt, .csv"
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
          >
            <Button style={{ width: "500px" }}>
              <Icon type="upload" /> Click to Upload
            </Button>
          </Upload>
        </Row>
        {/* <pre style={{ maxHeight: "600px", overflowY: "scroll" }}> */}
        <div
          style={{
            width: "100%",
            marginTop: "40px",
            height: "550px",
            overflowY: "scroll",
          }}
        >
          <Table
            dataSource={parsedData}
            columns={columns}
            pagination={false}
            expandable={{
              expandedRowRender: (record) =>
                tableRows && tableRows.length > 0 ? (
                  <Table
                    dataSource={parsedData}
                    columns={columns}
                    pagination={false}
                    rowKey="key"
                  />
                ) : null,
            }}
          />
        </div>
        {/* </pre> */}
      </Modal>
    </>
  );
};

export default UploadCSVfile;
