import {
  EyeOutlined,
  LoadingOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import "../../Common/Acc_Common.css";
import "../../Common/AddPageCommon.css";
import AddParty from "../Parties/AddParty";
import PartyService from "../../Services/PartyService";
import deleteImg from "../../../images/deletebutton.svg";
import PurchaseService from "../../Services/PurchaseService";
import SalesService, { TradingPartnerType } from "../../Services/SalesService";
import { Utils } from "../../../Utilities/Utils";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import { useContext, useEffect, useState } from "react";
import { notificationContext } from "../../Common/PageRoute";
import {
  IPaymentMothod,
  InvoicesGetTypes,
} from "../../../Types/CommonConstants";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AccountShort, ISelectGroup } from "../../../Types/CommonTypes";
import CashAndBankAccountServices, {
  BankStatus,
} from "../../Services/CashAndBankAccountServices";
import ViewPurchaseInvoiceNoDetails from "./ViewPurchaseInvoiceNoDetails";

const AddPaymentOut = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const { partyId } = useParams();
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  // const [itemList, setItemList] = useState<any[]>([]);
  const [accountId, setAccountId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemLength, setItemLength] = useState<number>(0);
  const [amountValue, setAmountValue] = useState<number>(0);
  const [bankAccount, setBankAccount] = useState<any[]>([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [deleteItemList, setDeleteItemList] = useState<any>();
  const [editParty, setEditParty] = useState<any>({ id: null });
  const [showOpenInvoices, setShowOpenInvoices] = useState(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [searchSupplier, setSearchSupplier] = useState<string>("");
  const [isAnyAllocatedAmount, setAnyAllocatedAmount] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [openCustomerDrawer, setOpenCustomerDrawer] = useState<boolean>(false);
  const [paymentTotal, setPaymentTotal] = useState<string>(
    Utils.getFormattedNumber(0)
  );
  const [bankConfirmation, setBankConfirmation] = useState<boolean>(false);
  const [bankAccountAmt, setBankAccountAmt] = useState<number>(0);
  const [bankAccountList, setBankAccountList] = useState<any[]>([
    { value: currentClient?.id, label: "Cash In Hand" },
  ]);
  const [deleteBankAccountRow, setDeleteBankAccountRow] = useState<any>();
  const [searchBankAccount, setSearchBankAccount] = useState<string>("");
  const [openModel, setOpenModel] = useState<{ type: string; id: string }>({
    type: "",
    id: "",
  });
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const ItemCurrentList = form.getFieldValue(["invoices"]);

  // const getAllocatedAmount = (totalAmount: number) => {
  //   if (totalAmount > 0) {
  //     let newAllocatedAmt = ItemCurrentList?.map((itm: any) => {
  //       let dueAmount = Utils.getNumber(itm?.dueAmount);
  //       if (totalAmount >= dueAmount) {
  //         totalAmount -= dueAmount;
  //         return dueAmount;
  //       } else if (dueAmount > totalAmount && totalAmount !== 0) {
  //         let allocAmt = totalAmount;
  //         totalAmount = 0;
  //         return allocAmt;
  //       }
  //       return 0;
  //     });
  //     let newInvoiceArr = ItemCurrentList?.map((itm: any, idx: number) => {
  //       return {
  //         ...itm,
  //         allocatedAmount: newAllocatedAmt[idx]
  //           ? Utils.getFormattedNumber(newAllocatedAmt[idx])
  //           : 0,
  //       };
  //     });
  //     form.setFieldValue("invoices", newInvoiceArr);
  //     setAnyAllocatedAmount(
  //       newInvoiceArr?.some((itm: any) => itm?.allocatedAmount === 0)
  //     );
  //     setShowOpenInvoices(true);
  //   } else {
  //     setShowOpenInvoices(false);
  //   }
  // };

  // for supplier search
  useEffect(() => {
    if (partyId) SupplierSelect(partyId);
    SalesService.searchParties(
      currentClient?.id!,
      searchSupplier,
      TradingPartnerType.Supplier,
      10
    )
      .then((res: any) => {
        setSupplierOptions(res?.result);
      })
      .catch((err: any) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, searchSupplier, partyId]);

  // for initial value and when payment added from invoice table
  useEffect(() => {
    setLoading(true);
    PurchaseService.getPaymentOutData(
      currentClient?.id!,
      "",
      (location?.state as any)?.invoiceId,
      true,
      partyId
    ).then((res: any) => {
      setLoading(false);
      const Payment = res?.result?.paymentOut;
      const getRecord = async () => {
        if (res) {
          if (Payment) {
            if (location.state && !partyId) {
              const itms = Payment?.invoices;
              const inv = itms?.map((itm: any) => {
                return {
                  purchaseInvoice: {
                    id: itm?.purchaseInvoice?.id,
                    name: itm?.purchaseInvoice?.name,
                  },
                  allocatedOn: dayjs(itm?.allocatedOn).format("DD/MM/YYYY"),
                  description: itm?.description,
                  invAmount: Utils.getFormattedNumber(itm?.invAmount),
                  dueAmount: Utils.getFormattedNumber(itm?.dueAmount),
                  allocatedAmount: Utils.getFormattedNumber(
                    location.state ? (location.state as any)?.values?.value : 0
                  ),
                };
              });
              form.setFieldValue(
                "invoices",
                Payment?.invoices && Payment?.invoices?.length > 0 ? inv : []
              );
              // setItemList(itms);
              setAmountValue((location.state as any)?.values?.value);
              setPaymentTotal((location.state as any)?.values?.value);
            }

            form.setFieldValue("date", dayjs(Payment?.date));
            form.setFieldValue("number", Payment?.number);
            Payment?.invoices && setItemLength(Payment?.invoices?.length);
            Payment?.supplier &&
              form.setFieldValue("supplier", {
                value: Payment?.supplier?.id,
                key: Payment?.supplier?.id,
                label: Payment?.supplier?.name,
              });
            //  Payment?.method && form.setFieldValue("method", Payment?.method);

            form.setFieldValue(
              "availableAmount",
              Utils.getFormattedNumber(
                location.state
                  ? (location.state as any)?.values?.value
                  : Payment?.availableAmount
                  ? Payment?.availableAmount
                  : 0
              )
            );

            // getAllocatedAmount(Utils.getNumber(Payment?.availableAmount));
          }
          // const accountArray = [
          //   ...res?.result?.accounts[0]?.accounts,
          //   ...res?.result?.accounts[1]?.accounts,
          //   ...res?.result?.accounts[2]?.accounts,
          // ];
          // setBankAccount(accountArray);
          if (res?.result?.accounts) {
            let acc: ISelectGroup[] = res?.result?.accounts?.map(
              (group: any) => ({
                label: `${group.typeName} (${group.groupName})`,
                options: group.accounts?.map((a: AccountShort) => ({
                  label: a.name,
                  value: a.id,
                  title: a.code,
                })),
              })
            );
            setBankAccount([...acc]);
          }
        }
      };
      getRecord();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, location?.state]);

  //function for amount value change
  const handelAmmountChange = (amt: number) => {
    const ItemsList = form.getFieldValue(["invoices"]);
    setAmountValue(amt);
    if (amt > 0) {
      let maxAllocation = 0;
      let items = ItemsList.filter(
        (itm: any) => Utils.getNumber(itm?.dueAmount) > 0
      ).map((itm: any, i: number) => {
        if (amt < Utils.getNumber(itm?.dueAmount)) {
          maxAllocation = amt;
          amt = 0;
        } else {
          maxAllocation = Utils.getNumber(itm?.dueAmount);
          amt -= maxAllocation;
        }
        return {
          // purchaseInvoice: {
          //   id: itm?.purchaseInvoice?.id,
          //   name: itm?.purchaseInvoice?.name,
          // },
          // allocatedOn: dayjs(itm?.allocatedOn).format("DD/MM/YYYY"),
          // description: itm?.description,
          // invAmount: Utils.getFormattedNumber(itm?.invAmount),
          // dueAmount: Utils.getFormattedNumber(itm?.dueAmount),
          ...itm,
          allocatedAmount: Utils.getFormattedNumber(
            maxAllocation ? maxAllocation : 0
          ),
        };
      });
      form.setFieldValue("invoices", items);
      setAnyAllocatedAmount(
        items?.some((itm: any) => Utils.getNumber(itm?.allocatedAmount) === 0)
      );
      handelTotalPayment(items);
      setShowOpenInvoices(true);
    } else {
      console.log(ItemsList);
      const newArray = [...ItemsList];
      newArray[0] = {
        ...newArray[0],
        allocatedAmount: Utils.getFormattedNumber(0),
      };
      setShowOpenInvoices(false);
      form.setFieldValue("invoices", newArray);
    }
  };

  const handelTotalPayment = (ItemsList: any[]) => {
    const totalAllocAmt = ItemsList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr.allocatedAmount));
    }, 0);
    setPaymentTotal(Utils.getFormattedNumber(totalAllocAmt));
  };

  const handelAllocatedAmtChange = (val: string, index: number) => {
    const ItemsList = form.getFieldValue(["invoices"]);
    const newArray = [...ItemsList];
    const current = newArray[index];
    let newValue = val ? parseFloat(val.replace(",", "")) : 0;
    let allocatedAmt =
      newValue <= Utils.getNumber(current.dueAmount)
        ? newValue
        : current?.dueAmount;
    newArray[index] = { ...current, allocatedAmount: allocatedAmt };
    form.setFieldValue("invoices", newArray);
    setShowOpenInvoices(false);
    handelTotalPayment(newArray);
  };

  const handelAllocAmtBlur = async (e: any, i: number) => {
    const ItemsList = form.getFieldValue(["invoices"]);
    const current = ItemsList[i];
    let totalAllocAmt = 0;
    let totalAllocAmtArr = ItemsList?.map((itm: any, idx: number) => {
      return Utils.getNumber(itm.allocatedAmount);
    });
    totalAllocAmt = totalAllocAmtArr.reduce((pre: number, next: number) => {
      return pre + next;
    }, 0);

    if (totalAllocAmt > Utils.getNumber(amountValue)) {
      let splicedArr = [...totalAllocAmtArr];
      splicedArr.splice(i, 1);
      let totalSplicedArrAmt = splicedArr.reduce(
        (pre: number, next: number) => pre + next,
        0
      );

      let newAllocatedAmt = amountValue - totalSplicedArrAmt;
      const newItemList = [...ItemsList];
      newItemList[i].allocatedAmount = newAllocatedAmt;
      form.setFieldsValue({
        invoices: newItemList,
      });
      handelTotalPayment(newItemList);
    }

    if (
      Utils.getNumber(e.target.value) <= Utils.getNumber(current?.dueAmount)
    ) {
      if (totalAllocAmt <= Utils.getNumber(amountValue)) {
        // calculate amount of old and remaining invoice allocated amount
        let oldAllocArr = totalAllocAmtArr.slice(0, i + 1);
        let totalOldArrSum = oldAllocArr.reduce(
          (pre: number, next: number) => pre + next,
          0
        );
        let remainingAmount = Utils.getNumber(amountValue) - totalOldArrSum;
        // set values of old and remaining invoice array
        let oldInvoiceArr = ItemsList.slice(0, i + 1);
        let oldAllocArrValue = oldInvoiceArr?.map((itm: any, idx: number) => {
          return {
            ...itm,
            allocatedAmount: itm.allocatedAmount
              ? Utils.getFormattedNumber(Utils.getNumber(itm.allocatedAmount))
              : 0,
          };
        });
        let remainingInvoiceArr = ItemsList.slice(
          i + 1,
          totalAllocAmtArr?.length
        );
        let remainingAllocArr = totalAllocAmtArr.slice(
          i + 1,
          totalAllocAmtArr?.length
        );
        let newAllocatedAmt = remainingAllocArr?.map(
          (itm: any, idx: number) => {
            let dueAmount = Utils.getNumber(remainingInvoiceArr[idx].dueAmount);
            if (remainingAmount >= dueAmount) {
              remainingAmount -= dueAmount;
              return dueAmount;
            } else if (dueAmount > remainingAmount && remainingAmount !== 0) {
              let allocAmt = remainingAmount;
              remainingAmount = 0;
              return allocAmt;
            }
            return 0;
          }
        );
        let newInvoiceArr = remainingInvoiceArr?.map(
          (itm: any, idx: number) => {
            return {
              ...itm,
              allocatedAmount: newAllocatedAmt[idx]
                ? Utils.getFormattedNumber(newAllocatedAmt[idx])
                : 0,
            };
          }
        );
        let finalInvoiceArr = oldAllocArrValue.concat(newInvoiceArr);
        form.setFieldValue("invoices", finalInvoiceArr);
        handelTotalPayment(finalInvoiceArr);
      }
    }
  };

  const SupplierSelect = async (supplierId: string) => {
    setAmountValue(0);
    setPaymentTotal(Utils.getFormattedNumber(0));
    form.setFieldValue("availableAmount", Utils.getFormattedNumber(0));
    // service for getting the invoices of a selected supplier
    setTableLoading(true);
    await PartyService.getInvoicesByParty(
      currentClient?.id!,
      supplierId,
      InvoicesGetTypes.Payment
    ).then(async (res: any) => {
      if (res?.result?.purchases) {
        let items = res?.result?.purchases
          .filter((itm: any) => itm?.amount?.due > 0)
          .map((itm: any) => {
            return {
              purchaseInvoice: { id: itm.id, name: itm.number },
              allocatedOn: dayjs(itm.date).format("DD/MM/YYYY"),
              description: "",
              invAmount: Utils.getFormattedNumber(itm.amount?.netPayable),
              dueAmount: Utils.getFormattedNumber(itm.amount?.due),
              allocatedAmount: Utils.getFormattedNumber(0),
            };
          });

        // setItemList(items);
        setEditParty({ id: supplierId });
        setItemLength(items?.length);
        form.setFieldValue("invoices", items);
        setTableLoading(false);
      } else setTableLoading(false);
    });
    setShowOpenInvoices(false);
  };

  // ------- Add payment post api --------
  const addPaymentOut = () => {
    form.validateFields().then(async (val) => {
      setSaveButtonLoading(true);
      const inv = val?.invoices.map((itm: any) => {
        return {
          ...itm,
          allocatedOn: dayjs(itm?.allocatedOn, "DD/MM/YYYY").format(
            "YYYY-MM-DDTHH:mm:ss[Z]"
          ),
          invAmount: Utils.getNumber(itm?.invAmount),
          dueAmount: Utils.getNumber(itm?.dueAmount),
          isPaymentDiscount: false,
          paymentDiscount: 0,
          allocatedAmount: Utils.getNumber(itm?.allocatedAmount),
        };
      });

      const PaymentOut = {
        ...val,
        date: dayjs(val?.date, "DD/MM/YYYY").format("DD/MM/YYYY"),
        supplier: {
          id: val?.supplier?.value,
          name: val?.supplier?.label,
        },
        acc_Account: {
          id: val?.account?.value,
          name: val?.account?.label,
        },
        bankDts: val.bankDts?.map((v: any) => {
          return {
            ...v,
            name: v.name?.label,
            id: v.name?.value,
          };
        }),
        // method: {
        //   id: val?.method?.value,
        //   name: val?.method?.label,
        // },
        invoices: inv,
      };
      // console.log("payment out", PaymentOut);
      await PurchaseService.postPaymentOut(currentClient?.id!, PaymentOut)
        .then((res) => {
          if (res?.status) {
            openNotification(
              "success",
              `${res?.result.name} PaymentOut Add successfully`
            );
            partyId
              ? navigate(
                  `/accounts/clients/${currentClient?.id}/parties/${PaymentOut?.supplier?.id}`
                )
              : navigate(-1);
          } else if (res?.message) {
            openNotification("error", res?.message);
          }
        })
        .catch((ex) => openNotification("error", ex));
      setSaveButtonLoading(false);
    });
  };

  //----- Bank Account Get Api -----
  useEffect(() => {
    CashAndBankAccountServices.getBankAccountList(
      currentClient?.id!,
      0,
      15,
      BankStatus.Active,
      "",
      "",
      searchBankAccount,
      [],
      true
    )
      .then((res: any) => {
        console.log("bankAccountList", bankAccountList);
        if (res.items.length > 0) {
          res.items?.forEach((v: any) => {
            bankAccountList.push({
              label: v?.displayName,
              value: v?.id,
            });
          });
        }
      })
      .catch((ex: any) => {
        console.error(ex.message);
        // setBankLoading(false);
        setBankAccountList([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  return (
    <div className="mainbox">
      <Spin indicator={antIcon} spinning={loading}>
        <Form
          form={form}
          name="paymentOut"
          autoComplete="off"
          initialValues={{ bankDts: [{}] }}
        >
          <Row justify="space-between">
            <Col span={11} className="dashedBoxStyle">
              <Row gutter={[14, 10]}>
                <Col span={10}>
                  <Row justify="space-between">
                    <Col className="themeColor fw-500">Paid To</Col>
                    {editParty.id && (
                      <Col className="fw-500">
                        <Tooltip title="View Supplier">
                          <EyeOutlined
                            onClick={() => setOpenCustomerDrawer(true)}
                          />
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                  {/* <span className="themeColor fw-500"></span> */}
                  <Form.Item name="supplier" className="m-0">
                    <Select
                      labelInValue
                      showSearch
                      optionFilterProp="children"
                      placeholder="Supplier Name"
                      filterOption={false}
                      showArrow={false}
                      notFoundContent={null}
                      onSearch={(value: string) => setSearchSupplier(value)}
                      onSelect={(value: any) => {
                        SupplierSelect(value?.value);
                        // form.resetFields(["amount"]);
                        // setPaymenttTotal(0);
                      }}
                      options={supplierOptions?.map((value: any) => ({
                        value: value?.id,
                        label: value?.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <span className="themeColor fw-500">Payment No.</span>
                  <Form.Item name="number" className="m-0">
                    <Input disabled/>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <span className="themeColor fw-500">Date</span>
                  <Form.Item
                    name="date"
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: "Select Date",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      disabledDate={(current) =>
                        current && current > dayjs().endOf("day")
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <span className="themeColor fw-500">Account</span>
                  <Form.Item name="account" className="m-0">
                    <Select
                      labelInValue
                      // options={backAccount?.map((x: any) => ({
                      //   title: x?.code,
                      //   value: x?.id,
                      //   label: x?.name,
                      // }))}
                      options={bankAccount}
                      onChange={(e) => {
                        setAccountId(e.value);
                        form.setFieldValue(
                          "method",
                          e.value === 1764
                            ? IPaymentMothod.Cash
                            : IPaymentMothod.BankTransfer
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <span className="themeColor fw-500">Method</span>
                  <Form.Item name="method" className="m-0">
                    <Select
                      labelInValue
                      options={
                        accountId !== 1764
                          ? [
                              {
                                value: IPaymentMothod.BankTransfer,
                                label: "Bank Transfer",
                              },
                              { value: IPaymentMothod.Cheque, label: "Cheque" },
                              {
                                value: IPaymentMothod.CreditCard,
                                label: "Credit card",
                              },
                              {
                                value: IPaymentMothod.DebitCard,
                                label: "Debit card",
                              },
                              {
                                value: IPaymentMothod.DirectDebit,
                                label: "Direct debit",
                              },
                              {
                                value: IPaymentMothod.StandingOrder,
                                label: "Standing order",
                              },
                              {
                                value: IPaymentMothod.WebsitePayment,
                                label: "Website payment",
                              },
                              { value: IPaymentMothod.PayPal, label: "PayPal" },
                              { value: IPaymentMothod.Amex, label: "Amex" },
                              { value: IPaymentMothod.Other, label: "Other" },
                            ]
                          : [{ value: IPaymentMothod.Cash, label: "Cash" }]
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <span className="themeColor fw-500">Amount</span>
                  <Form.Item name="availableAmount" className="m-0">
                    <Input
                      prefix="&#8377;"
                      onChange={(e) => {
                        handelAmmountChange(Utils.getNumber(e.target.value));
                        // getAllocatedAmount(Utils.getNumber(e.target.value));
                      }}
                      onBlur={(e) => {
                        e.target.value
                          ? form.setFieldValue(
                              "availableAmount",
                              Utils.getFormattedNumber(
                                Utils.getNumber(e.target.value)
                              )
                            )
                          : form.setFieldValue(
                              "availableAmount",
                              Utils.getFormattedNumber(0)
                            );
                        // getAllocatedAmount(Utils.getNumber(e.target.value));
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <div className="fs-35 fw-400 themeColor textEnd">Payment Out</div>
            </Col>
          </Row>
          <Spin indicator={antIcon} spinning={tableLoading}>
            <div
              style={{ paddingTop: 20 }}
              //  className="tableWrapper"
            >
              <table className="AddPageTabelDesign pb-10">
                <thead>
                  <tr>
                    <th
                      colSpan={2}
                      style={{ width: "14%" }}
                      className="textStart ps-32"
                    >
                      Invoice No.
                    </th>
                    <th style={{ width: "12%" }} className="textStart ps-6">
                      Invoice Date
                    </th>
                    <th className="textStart ps-6">Description</th>
                    <th style={{ width: "13%" }} className="textEnd pe-6">
                      Invoice Amount
                    </th>
                    <th style={{ width: "13%" }} className="textEnd pe-6">
                      Due Amount
                    </th>
                    <th
                      colSpan={3}
                      style={{ width: "18%" }}
                      className="textEnd pe-60"
                    >
                      Allocated Amount
                    </th>
                  </tr>
                </thead>
                <Form.List name="invoices">
                  {(fields, { add, remove, move }) => {
                    return (
                      <>
                        {confirmation && (
                          <ConfirmationModal
                            open={confirmation}
                            text="Do you really want to delete this row ?"
                            onNo={() => setConfirmation(false)}
                            onYes={() => {
                              remove(deleteItemList);
                              let ItemsList = form.getFieldValue(["invoices"]);
                              handelTotalPayment(ItemsList);
                              setConfirmation(false);
                            }}
                          />
                        )}
                        <tbody>
                          {/* eslint-disable-next-line array-callback-return */}
                          {fields.map(({ key, name, ...restField }, index) => {
                            if (
                              !showOpenInvoices ||
                              Utils.getNumber(
                                ItemCurrentList[name]?.allocatedAmount
                              ) !== 0
                            )
                              return (
                                <tr key={key}>
                                  <td style={{ width: 25 }}></td>
                                  <td>
                                    <Form.Item
                                      name={[name, "purchaseInvoice", "name"]}
                                      className="m-0"
                                      {...restField}
                                    >
                                      <Input disabled className="cursorAuto" />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "allocatedOn"]}
                                    >
                                      <Input disabled className="cursorAuto" />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[name, "description"]}
                                      className="m-0"
                                      {...restField}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "invAmount"]}
                                    >
                                      <Input
                                        className="textEnd cursorAuto"
                                        disabled
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "dueAmount"]}
                                    >
                                      <Input
                                        className="textEnd cursorAuto"
                                        disabled
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <Form.Item
                                      name={[name, "allocatedAmount"]}
                                      className="m-0"
                                      {...restField}
                                    >
                                      <Input
                                        className="textEnd"
                                        onChange={(e) =>
                                          handelAllocatedAmtChange(
                                            e.target.value,
                                            index
                                          )
                                        }
                                        onBlur={(val) => {
                                          handelAllocAmtBlur(val, index);
                                          // handelAllocAmtChange();
                                        }}
                                      />
                                    </Form.Item>
                                  </td>
                                  <td>
                                    <SettingOutlined
                                      onClick={() =>
                                        // console.log(
                                        //   "hllo...",
                                        //   form.getFieldValue([
                                        //     "invoices",
                                        //     index,
                                        //     "purchaseInvoice",
                                        //     "id",
                                        //   ])
                                        // )
                                        setOpenModel({
                                          type: "viewInvoiceNoAllDetails",
                                          id: form.getFieldValue([
                                            "invoices",
                                            index,
                                            "purchaseInvoice",
                                            "id",
                                          ]),
                                        })
                                      }
                                    />
                                  </td>
                                  <td style={{ width: 25 }}>
                                    {fields.length > 1 && (
                                      <img
                                        className="cursor mx-1"
                                        onClick={() => {
                                          setDeleteItemList(name);
                                          setConfirmation(true);
                                        }}
                                        src={deleteImg}
                                        alt="Delete"
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                          })}
                          {isAnyAllocatedAmount && (
                            <tr>
                              <td colSpan={9}>
                                <div className="textEnd pt-10">
                                  <span
                                    className="hoverDiv"
                                    onClick={() => {
                                      setShowOpenInvoices(!showOpenInvoices);
                                    }}
                                  >
                                    {showOpenInvoices
                                      ? `Show All Invoices`
                                      : `Hide All Invoices`}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </>
                    );
                  }}
                </Form.List>
                {itemLength === 0 && (
                  <tfoot>
                    <tr>
                      <td colSpan={9} style={{ textAlign: "center" }}>
                        No Invoice Found
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </Spin>

          <Row justify="space-between" className="dashedBorderTop">
            {/*----- Bank Account Details ----- */}
            <Col xl={7} lg={11} md={24}>
              <div style={{ overflowY: "auto" }}>
                <table className="AddPageTabelDesign">
                  <Form.List name="bankDts">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {bankConfirmation && (
                            <ConfirmationModal
                              open={true}
                              text="Do you really want to delete this Row ?"
                              onNo={() => setBankConfirmation(false)}
                              onYes={() => {
                                remove(deleteBankAccountRow);
                                setBankAccountAmt(
                                  form
                                    .getFieldValue("bankDts")
                                    .reduce(
                                      (acc: number, x: any) => acc + x?.amount,
                                      0
                                    )
                                );
                                setBankConfirmation(false);
                              }}
                            />
                          )}
                          {/* <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "start",
                                  paddingLeft: "8px",
                                }}
                              >
                                Select Account
                              </th>
                              <th
                                style={{
                                  textAlign: "end",
                                  paddingRight: "8px",
                                }}
                              >
                                Amount
                              </th>
                              <th>
                                <Form.Item className="m-0">
                                  <PlusOutlined onClick={() => add()} />
                                </Form.Item>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>
                                <Input disabled />
                              </td>
                              <td>
                                <InputNumber
                                  style={{ textAlign: "end", width: "100%" }}
                                  min={0}
                                  disabled
                                  value={
                                    form.getFieldValue("availableAmount") -
                                    bankAccountAmt
                                  }
                                />
                              </td>
                            </tr>
                            {fields.map(
                              ({ key, name, ...restField }, index) => {
                                return (
                                  <tr key={key}>
                                    <td>
                                      <Form.Item
                                        name={[name, "name"]}
                                        className="m-0"
                                        {...restField}
                                      >
                                        <Select
                                          labelInValue
                                          showSearch
                                          placeholder="Search to Bank"
                                          optionFilterProp="children"
                                          filterOption={false}
                                          popupMatchSelectWidth={false}
                                          style={{ width: "250px" }}
                                          // dropdownStyle={{ width: "250px" }}
                                          // placement="bottomRight"
                                          // notFoundContent={
                                          //   <Empty
                                          //     image={
                                          //       Empty.PRESENTED_IMAGE_SIMPLE
                                          //     }
                                          //     description={
                                          //       <span>
                                          //         Sales item not found.
                                          //       </span>
                                          //     }
                                          //   />
                                          // }
                                          // prefixCls={<EyeOutlined />}
                                          onSearch={(value: string) =>
                                            setSearchBankAccount(value)
                                          }
                                          onClick={() => {
                                            // setSearchItem("");
                                            // setNewItem({
                                            //   data: {},
                                            //   isSaved: false,
                                            // });
                                          }}
                                          onChange={(value: any) => {
                                            console.log(
                                              "key....................",
                                              key,
                                              name,
                                              index,
                                              form.getFieldValue("bankDts")
                                            );

                                            // if (
                                            //   invoiceTotal - bankAccountAmt >
                                            //   0
                                            // ) {
                                            //   form.setFieldValue(
                                            //     ["bankDts", key, "amount"],
                                            //     invoiceTotal - bankAccountAmt
                                            //   );
                                            //   console.log(true);
                                            // }

                                            // setBankAccountAmt(
                                            //   form
                                            //     .getFieldValue("bankDts")
                                            //     .reduce(
                                            //       (acc: number, x: any) =>
                                            //         acc + x.amount,
                                            //       0
                                            //     )
                                            // );

                                            if (
                                              form
                                                .getFieldValue("bankDts")
                                                .every(
                                                  (x: any) => x.amount === 0
                                                )
                                            ) {
                                              form.setFieldValue(
                                                ["bankDts", index, "amount"],
                                                form.getFieldValue(
                                                  "availableAmount"
                                                )
                                              );
                                              setBankAccountAmt(
                                                form
                                                  .getFieldValue("bankDts")
                                                  .reduce(
                                                    (acc: number, x: any) =>
                                                      acc + x.amount,
                                                    0
                                                  )
                                              );
                                            } else if (
                                              form.getFieldValue([
                                                "bankDts",
                                                index,
                                                "amount",
                                              ]) === 0
                                            ) {
                                              form.setFieldValue(
                                                ["bankDts", index, "amount"],
                                                form.getFieldValue(
                                                  "availableAmount"
                                                ) - bankAccountAmt
                                              );
                                              setBankAccountAmt(
                                                form
                                                  .getFieldValue("bankDts")
                                                  .reduce(
                                                    (acc: number, x: any) =>
                                                      acc + x.amount,
                                                    0
                                                  )
                                              );
                                            }

                                            if (fields.length === index + 1)
                                              add();
                                          }}
                                          options={bankAccountList}
                                          dropdownRender={(bankAccountList) => (
                                            <>
                                              {bankAccountList}
                                              <Divider
                                                style={{ margin: "5px 0px" }}
                                              />
                                              <Button
                                                style={{ width: "100%" }}
                                                icon={<PlusOutlined />}
                                                type="link"
                                                onClick={() => {
                                                  // setOpenModel({
                                                  //   type: "addBankAccount",
                                                  //   param: {
                                                  //     open: true,
                                                  //   },
                                                  // });
                                                  // setNewItem({
                                                  //   data: {},
                                                  //   isSaved: false,
                                                  // });
                                                  // setItemFields({
                                                  //   field: fields,
                                                  //   index: index,
                                                  // });
                                                }}
                                              >
                                                New Bank
                                              </Button>
                                            </>
                                          )}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <Form.Item
                                        name={[name, "amount"]}
                                        className="m-0"
                                        {...restField}
                                        initialValue={0.0}
                                      >
                                        <InputNumber
                                          step={0.01}
                                          min={0}
                                          onBlur={(x) => {
                                            console.log(x.target.value);
                                            if (
                                              form.getFieldValue(
                                                "availableAmount"
                                              ) -
                                                form
                                                  .getFieldValue("bankDts")
                                                  .reduce(
                                                    (acc: number, x: any) =>
                                                      acc + x?.amount,
                                                    0
                                                  ) <
                                              0
                                            ) {
                                              form.setFieldValue(
                                                ["bankDts", index, "amount"],
                                                0.0
                                              );
                                              setBankAccountAmt(
                                                form
                                                  .getFieldValue("bankDts")
                                                  .reduce(
                                                    (acc: number, x: any) =>
                                                      acc + x?.amount,
                                                    0
                                                  )
                                              );
                                            } else
                                              setBankAccountAmt(
                                                form
                                                  .getFieldValue("bankDts")
                                                  .reduce(
                                                    (acc: number, x: any) =>
                                                      acc + x?.amount,
                                                    0
                                                  )
                                              );
                                          }}
                                          // min={1}
                                          className="inputNumberTextEnd"
                                          controls={false}
                                          style={{ width: "100%" }}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td>
                                      {fields.length !== index + 1 && (
                                        <img
                                          className="cursor mx-1"
                                          onClick={() => {
                                            setDeleteBankAccountRow(name);
                                            setBankConfirmation(true);
                                          }}
                                          src={deleteImg}
                                          alt="Delete"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody> */}
                        </>
                      );
                    }}
                  </Form.List>
                </table>
              </div>
            </Col>
            <Col xl={6} lg={8} md={24}>
              <Divider className="my-10 grayBg" />
              <Row justify="space-around">
                <Col span={7}>Total payment</Col>
                <Col span={14} className="textEnd">
                  &#8377; {paymentTotal}
                </Col>
              </Row>
              <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-10"
              />
              <Row justify="end">
                <Col>
                  <Button
                    onClick={() => {
                      const supplier = form.getFieldValue("supplier");
                      partyId
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/parties/${supplier?.value}`
                          )
                        : navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className="ps-14">
                  <Form.Item>
                    <Button
                      disabled={amountValue > 0 ? false : true}
                      type="primary"
                      loading={saveButtonLoading}
                      onClick={() => addPaymentOut()}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
      {/* ------------View Party ----------*/}
      {openCustomerDrawer && (
        <AddParty
          openDrawer={openCustomerDrawer}
          setOpenDrawer={setOpenCustomerDrawer}
          editParty={editParty}
          setNewPartie={() => {}}
        />
      )}
      {openModel.type === "viewInvoiceNoAllDetails" && (
        <ViewPurchaseInvoiceNoDetails
          open={openModel.type === "viewInvoiceNoAllDetails"}
          onCancel={() => setOpenModel({ type: "", id: "" })}
          invoiceId={openModel.id}
        />
      )}
    </div>
  );
};

export default AddPaymentOut;
