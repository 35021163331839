import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { dayjs } from "../../../../Utilities/dayjs";
import TdsTcsServices, {
  Limit_Type,
  Transaction_Type,
} from "../../../Services/TdsTcsServices";
import {
  notificationContext,
  NotificationType,
} from "../../../Common/PageRoute";
import { LocalChunkSize } from "papaparse";

interface IPropsTcs {
  open: boolean;
  setOpen: (value: boolean) => void;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  editData?: any;
}

const AddTdsTaxRate = (props: IPropsTcs) => {
  console.log("props: ", props);
  const [form] = Form.useForm();
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [type, setType] = useState<Transaction_Type>(Transaction_Type.Domestic);

  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };

  useEffect(() => {
    if (props.editData?.id) {
      form.setFieldsValue({
        section: props.editData?.section,
        natureOfPayment: props.editData?.natureOfPayment,
        limitType: props.editData?.limitType,
        domesticSegment: props.editData?.domesticSegment,
        internationalSegment: props.editData?.internationalSegment,
        transactionType: props.editData?.transactionType,
        // effectiveDate: dayjs(
        //   dayjs(props.editData?.effectiveDate).format("DD/MM/YYYY"),
        //   "DD/MM/YYYY"
        // ),
      });
      setType(props.editData?.transactionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editData?.id]);

  const saveTDS = () => {
    form.validateFields().then(async (value) => {
      try {
        setSaveButtonLoading(true);
        const postData = {
          ...value,
          // effectiveDate: dayjs(
          //   dayjs(value?.effectiveDate).format("DD/MM/YYYY"),
          //   "DD/MM/YYYY"
          // ),
        };

        console.log("data", postData);

        const res = await TdsTcsServices.addTDS(postData, props.editData?.id);

        if (res.status) {
          openNotification("success", `Add TDS Rate`);
          setSaveButtonLoading(false);
          props.setOpen(false);
          props.setTrigger((x: boolean) => !x);
        } else {
          setSaveButtonLoading(false);
          // openNotification("error", res?.message);
          setSaveButtonLoading(false);
          res?.message && openNotification("error", res.message);
        }
      } catch (err: any) {
        console.log(err);
        // openNotification("error", err);
        setSaveButtonLoading(false);
        err && openNotification("error", err);
      }
    });
  };

  return (
    <>
      <Modal
        maskClosable={false}
        title={props.editData?.id ? "Update TDS Rate" : "Add TDS Rate"}
        open={props.open}
        width={800}
        // centered
        onCancel={() => props.setOpen(false)}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveTDS()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {props.editData?.id ? "Update" : "Save"}
            </Button>
          </div>
        }
      >
        {/* <Spin */}
        {/* // indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} */}
        {/* // spinning={loading} */}
        {/* > */}
        <Form
          form={form}
          // layout="vertical"
          labelWrap={true}
          labelCol={{ xs: 7, sm: 10 }}
          wrapperCol={{ xs: 16, sm: 14 }}
          autoComplete="off"
          colon={false}
          labelAlign="left"
          name="tscDetail"
          requiredMark={false}
          initialValues={{ limitType: Limit_Type.Yearly, transactionType: 0 }}
        >
          <Divider className="ca-model-css" />
          <Row gutter={20}>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                // className="inputBoxMb8"
                name="section"
                label="Section"
                rules={[{ required: true, message: "Section is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                name="natureOfPayment"
                label="Nature Of Payment"
                rules={[
                  { required: true, message: "Nature Of Payment is required" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col lg={12}>
              <Form.Item
                className="mb-14"
                label="EffectiveDate"
                name="effectiveDate"
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col> */}

            <Col lg={12}>
              <Form.Item className="mb-14" label="LimitType" name="limitType">
                <Select
                  options={[
                    { label: "Yearly", value: Limit_Type.Yearly },
                    { label: "Monthly", value: Limit_Type.Monthly },
                    {
                      label: "PerTransaction",
                      value: Limit_Type.PerTransaction,
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                className="mb-14"
                label="Transaction Type"
                name="transactionType"
              >
                <Select
                  options={[
                    { label: "Domestic", value: Transaction_Type.Domestic },
                    {
                      label: "International",
                      value: Transaction_Type.International,
                    },
                  ]}
                  onChange={(e) => setType(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          {type === Transaction_Type.Domestic ? (
            <>
              {/* DomesticSegment */}
              <Row gutter={20}>
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  className="dividertext"
                  style={{ marginTop: "11px", marginBottom: "11px" }}
                >
                  <p style={{ fontWeight: 600 }}>Domestic</p>
                </Divider>
                <Col lg={12}>
                  <Form.Item
                    className="mb-14"
                    label="Threshold Limit"
                    name={["domesticSegment", "thresholdLimit"]}
                  >
                    <InputNumber
                      min={0}
                      // max={100}
                      style={{ width: "100%" }}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    className="mb-14"
                    label="Ind HUFRate"
                    name={["domesticSegment", "rates", "indHUFRate"]}
                    rules={[
                      {
                        required: true,
                        message: "Ind HUFRate is required",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    className="mb-14"
                    label="Other Rate"
                    name={["domesticSegment", "rates", "otherRate"]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    className="mb-14"
                    label="PAN Not Rate"
                    name={["domesticSegment", "rates", "ifPANNotRate"]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {/* InternationalSegment */}
              <Row gutter={20}>
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  className="dividertext"
                  style={{ marginTop: "11px", marginBottom: "11px" }}
                >
                  <p style={{ fontWeight: 600 }}>International</p>
                </Divider>
                <Col lg={12}>
                  <Form.Item
                    className="mb-14"
                    label="Threshold Limit"
                    name={["internationalSegment", "thresholdLimit"]}
                  >
                    <InputNumber
                      min={0}
                      // max={100}
                      style={{ width: "100%" }}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    className="mb-14"
                    label="Int HUFRate"
                    name={["internationalSegment", "rates", "indHUFRate"]}
                    rules={[
                      {
                        required: true,
                        message: "Int HUFRate is required",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    className="mb-14"
                    label="Other Rate"
                    name={["internationalSegment", "rates", "otherRate"]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    className="mb-14"
                    label="PAN Not Rate"
                    name={["internationalSegment", "rates", "ifPANNotRate"]}
                  >
                    <InputNumber
                      min={0}
                      style={{ width: "100%" }}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
        {/* </Spin> */}
        <Divider style={{ margin: "0px" }} />
      </Modal>
      {contextHolder}
    </>
  );
};

export default AddTdsTaxRate;
