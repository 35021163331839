import React, { useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Divider,
  Select,
  InputNumber,
  Spin,
  InputRef,
} from "antd";
import ChartofAccountServicesNew, {
  GroupTypes,
} from "../../../Services/ChartofAccountServicesNew";
import { useClient } from "../../../Context/ClientContext";
import { notificationContext } from "../../../Common/PageRoute";
import { LoadingOutlined } from "@ant-design/icons";
import { useHotkeys } from "react-hotkeys-hook";
import { useKeyboardShortcuts } from "../../../Common/KeyboardShortcuts ";
import EntryLevelKeys from "../../../Common/EntryLevelKeys";

const AddGroup: React.FC<{
  open: boolean;
  editData: any;
  onCancel: React.Dispatch<{}>;
  setTrigger: any;
  groupData: any[];
}> = (props) => {
  console.log("props", props.editData);
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  // const [groupData, setGroupData] = React.useState<any[]>();
  // const [groupType, setGroupType] = React.useState<GroupTypes>(
  //   GroupTypes.Secondary
  // );
  const [loading, setLoading] = React.useState<boolean>(false);
  // useKeyboardShortcuts("S", ["altKey"], () => {
  //   saveGroup();
  // });
  // useKeyboardShortcuts("C", ["altKey"], () => {
  //   props.onCancel({});
  // });
  React.useEffect(() => {
    setLoading(true);
    inputRef.current?.focus();
    if (props.editData?.id) {
      // setGroupType(props.editData?.group_Type);
      form.setFieldsValue(props.editData);
    }
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   inputRef.current?.focus();
  //   setLoading(true);
  //   const getListData = async () => {
  //     await ChartofAccountServicesNew.getGroupList(currentClient?.id!)
  //       .then((res: any) => {
  //         if (res.items) {
  //           setGroupData(res.items);
  //           if (props.editData.id) {
  //             setGroupType(props.editData?.group_Type);
  //             form.setFieldsValue(props.editData);
  //           }
  //         } else {
  //           setGroupData([]);
  //           console.log("Error", res);
  //         }
  //       })
  //       .catch((err: any) => {
  //         console.log("Error", err);
  //       });
  //     setLoading(false);
  //     //  setTotalRecords(res?.totalRecords);
  //   };
  //   getListData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const saveGroup = () => {
    form.validateFields().then(async (value) => {
      setSaveButtonLoading(true);

      // const parentGroup: any =
      //   value?.group_Type === GroupTypes.Secondary &&
      //   props.groupData?.find((x) => x?.id === value?.parent);
      const parentGroup: any = props.groupData?.find(
        (x) => x?.id === value?.parent
      );

      let data = {
        ...value,
        group_Type: GroupTypes.Secondary,
        nature: parentGroup?.nature,
        statement: parentGroup.statement,
        parent: value?.parent,
        order: value?.order ?? 500,
        editable: true,
      };

      console.log("post Data....", data);

      await ChartofAccountServicesNew.postGroup(
        currentClient?.id!,
        data,
        props.editData?.id
      )
        .then((res: any) => {
          if (res.result) {
            // setProps1(true);
            props.setTrigger();
            openNotification(
              "success",
              `Group ${
                props?.editData?.id ? "Update successfully" : "Add successfully"
              }`
            );
            props.onCancel({});
          } else if (res?.message) {
            openNotification("error", res?.message);
          }
          setSaveButtonLoading(false);
        })
        .catch((ex: any) => {
          console.error(ex);
          openNotification("error", ex.message);
          setSaveButtonLoading(false);
        });
    });
  };

  EntryLevelKeys({
    save: () => saveGroup(),
    cancel: () => props.onCancel({}),
  });

  const intialValue = {
    group_Type: GroupTypes.Secondary,
  };

  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <>
      {label}
      {/* {required ? (
        <Tag color="error">Required</Tag>
      ) : (
        <Tag color="warning">optional</Tag>
      )} */}
    </>
  );
  return (
    <React.Fragment>
      <Modal
        open={props.open}
        title={props.editData?.id ? "Update Group" : "Add Group"}
        maskClosable={false}
        width={435}
        style={{ top: 44 }}
        onCancel={() => props.onCancel({})}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel({});
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveGroup()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {props.editData?.id ? "Update" : "Save"}
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={loading}
        >
          <Form
            form={form}
            name="Account"
            colon={false}
            initialValues={intialValue}
            scrollToFirstError
            autoComplete="off"
            labelAlign="left"
            labelWrap={true}
            labelCol={{ xs: 7, sm: 8 }}
            wrapperCol={{ xs: 16, sm: 16 }}
            // requiredMark={customizeRequiredMark}
          >
            <Divider className="ca-model-css" />
            <Form.Item
              name="group_Name"
              label="Group Name"
              className="mb-14"
              rules={[
                {
                  required: true,
                  message:
                    "Group name is required. Max length is 50. You can include alphanumeric characters & some special characters like '_', '(', ')', '&', '-' and '.' also.",
                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                  max: 50,
                },
              ]}
            >
              <Input autoFocus={true} ref={inputRef} />
            </Form.Item>
            <Form.Item
              name="aliasName"
              label="Alias/Short Name"
              className="mb-12"
              rules={[
                {
                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                  max: 20,
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              name="group_Type"
              label="Type"
              className="mb-14"
              rules={[{ required: true, message: "Account Type is required" }]}
            >
              <Select
                options={[
                  { value: GroupTypes.Primary, label: "Primary" },
                  { value: GroupTypes.Secondary, label: "Secondary" },
                ]}
                onChange={(e) => {
                  // setGroupType(e);
                }}
              />
            </Form.Item> */}

            {/* {groupType === GroupTypes.Secondary ? ( */}
            <Form.Item
              name="parent"
              label="Parent Group"
              className="mb-14"
              rules={[{ required: true, message: "Parent group is required" }]}
            >
              <Select
                placeholder="Select Parent Group"
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={props.groupData?.map((value: any) => ({
                  value: value?.id,
                  label: value?.group_Name.concat(
                    value?.aliasName ? `${" (" + value?.aliasName + ")"}` : ""
                  ),
                }))}
                showSearch
              />
            </Form.Item>
            {/* ) : (
              <>
                <Form.Item
                  name="nature"
                  label="Nature"
                  className="mb-14"
                  rules={[{ required: true, message: "Nature is required" }]}
                >
                  <Select
                    options={[
                      {
                        value: Nature_new.DirectExpenses,
                        label: "Direct Expenses",
                      },
                      {
                        value: Nature_new.DirectIncome,
                        label: "Direct Income",
                      },
                      {
                        value: Nature_new.IndirectExpenses,
                        label: "Indirect Expenses",
                      },
                      {
                        value: Nature_new.IndirectIncome,
                        label: "Indirect Income",
                      },
                      { value: Nature_new.Assets, label: "Assets" },
                      { value: Nature_new.Liabilities, label: "Liabilities" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name="statement"
                  label="Statement"
                  className="mb-14"
                  rules={[
                    { required: true, message: "Statement  is required" },
                  ]}
                >
                  <Select
                    options={[
                      { value: Statements.Trading, label: "Trading" },
                      {
                        value: Statements.ProfitAndLoss,
                        label: "Profit & Loss",
                      },
                      {
                        value: Statements.BalanceSheet,
                        label: "Balance Sheet",
                      },
                    ]}
                  />
                </Form.Item>
              </>
            )} */}

            {/* <Form.Item name="order" label="Sort Order" className="mb-14">
              <InputNumber
                style={{ width: "100%" }}
                min={28}
                max={500}
                controls={false}
              />
            </Form.Item> */}
          </Form>
          <Divider className="divider-account" />
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

export default AddGroup;
